<template>
  <v-app>
    <v-card tile flat :height="getViewportHeight">
      <v-app-bar
        app
        flat
        >
        <div class="pt-1 text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                text
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-card tile flat>
              <v-card-subtitle
                v-for="(item, index) in titlePresets"
                  :key="index"
                  @click="getTitlePreset(item)"
                  class="pb-0"
              >{{ item.title }}
              </v-card-subtitle>
            </v-card>
          </v-menu>
        </div>
        
        <catalog-search></catalog-search>
        <v-switch
          flat
          v-model="dataMode"
          label="Data"
          class="pt-7 pl-4"
        ></v-switch>
        <v-switch
          flat
          v-model="simplifiedMode"
          label="Simplified Map"
          class="pt-7 pl-4"
        ></v-switch>
        <v-spacer></v-spacer>
        <!-- this is for development only -->
        <v-btn v-if="1 === 2" icon @click="onSettingsClicked">
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn @click="onSignOut()">
          <v-card-text>Sign Out</v-card-text>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>

      <heatmap-settings></heatmap-settings>
      <map-details></map-details>
      <v-row no-gutters style="flex-wrap: nowrap;">
        <v-col
          cols="3"
          class="flex-grow-0 flex-shrink-0"
        >
          <v-card
            class=""
            flat
            tile
            outlined
          >
            <map-title-list></map-title-list>
          </v-card>
        </v-col>          
        <v-col 
          style="min-width: 100px; max-width: 100%;" 
          class="flex-grow-1 flex-shrink-0"
        >
          <nanogenre-heatmap></nanogenre-heatmap>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>

<script>
  import { getAuth, signOut } from 'firebase/auth'

  import CatalogSearch from './CatalogSearch'
  import HeatmapSettings from './HeatmapSettings'
  import MapDetails from './MapDetails'
  import MapTitleList from './MapTitleList'
  import NanogenreHeatmap from './NanogenreHeatmap'

  export default {
    props: ['id'],
    components: {
      CatalogSearch,
      HeatmapSettings,
      MapDetails,
      MapTitleList,
      NanogenreHeatmap,
    },
    data: () => ({
      titleModel: null,
      currentTab: '',
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
    }),
    computed: {
      getViewportHeight() {
        return this.viewportHeight - 130
      },
      titlePresets() {
        const result = this.$store.getters['titlePresets/getTitlePresets']()
        return result
      },
      dataMode: {
        // eslint-disable-next-line no-unused-vars
        set(value) {
          this.$store.dispatch('mapDetails/toggleDrawer')
        },
        get() {
          return this.$store.getters['mapDetails/getDrawer']()
        }
      },
      simplifiedMode: {
        // eslint-disable-next-line no-unused-vars
        set(value) {
          this.$store.dispatch('settings/toggleSimplifiedMode')
        },
        get() {
          return this.$store.getters['settings/getSimplifiedMode']()
        }
      },
    },
    methods: {
      setViewportDimensions() {
        this.viewportWidth = window.innerWidth
        this.viewportHeight = window.innerHeight
      },
      onSettingsClicked() {
        this.$store.dispatch('settingsSliders/toggleDrawer')
      },
      onSignOut() {
        const auth = getAuth()
        signOut(auth)
          .then(() => {
            this.$store.dispatch('auth/setAccessToken', null)
            this.$store.dispatch('auth/setEmailAddress')
            this.$router.push('/login')
          })
          .catch((error) => {
            console.log(error)
          })
      },
      removeUrlParams(url) {
        const currentUrl = new URL(url);
        const searchParams = currentUrl.searchParams;
        searchParams.forEach((value, key) => searchParams.delete(key));
        const newUrl = currentUrl.toString();
        history.replaceState({}, document.title, newUrl);
      },
      async getTitlePreset(item) {
        console.log(`title preset:`, item)
        await this.processTitleList(item.movieIdList)
      },
      async processTitleList(titleListString) {
        // set the mapTitleList
        this.$store.dispatch('heatmapProgressIndicator/show')
        const mapTitleString = titleListString.split(',').map(item => parseInt(decodeURIComponent(item).trim()))
        const mapTitleList = mapTitleString.map((item) => ({...item, ncTitleId: item, enabled: true}))

        this.$store.dispatch('mapTitleList/setMapTitleList', mapTitleList)

        await this.$store.dispatch('server/fetchTitleListHeatmapData', mapTitleList)
        const titleHeatmapData = this.$store.getters['server/getTitleListHeatmapData']()

        // get the map coordinate list

        const nanogenreList = titleHeatmapData.nanogenres.map(item => { 
          return { 
            nanogenreId: item.nanogenreId, nanogenreCount: item.nanogenreCount 
          }
        })

        this.$store.dispatch('server/setDisplayCoordinates', nanogenreList)

        const detailsNanogenreList = this.$store.getters['server/getNanogenres']()
        const nanogenreTitleList = this.$store.getters['server/getNanogenreTitlesByNgId'](nanogenreList[0].nanogenreId)
        const nanogenreKeywordList = this.$store.getters['server/getNanogenreKeywordsByNgId'](nanogenreList[0].nanogenreId)

        this.$store.dispatch('mapDetails/setNanogenreList', detailsNanogenreList)
        this.$store.dispatch('mapDetails/setTitleList', nanogenreTitleList.titleIdList)
        this.$store.dispatch('mapDetails/setKeywordList', nanogenreKeywordList)
        const formattedDescription = detailsNanogenreList[0].description.join(' • ')
        this.$store.dispatch('selectedNanogenre/setSelectedNanogenre', formattedDescription)
      }      
    },
    watch: {},
    async mounted() {
      this.setViewportDimensions()
      window.addEventListener('resize', this.setViewportDimensions)
      if (this.$route.query && this.$route.query.titleIdList) {
        await this.processTitleList(this.$route.query.titleIdList)
        this.removeUrlParams(window.location.href)
      }
    }
  }
</script>

<style> 
  /* main.v-main {
    padding: 100px 0px 0px !important;
  } */
</style>