import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.store'
import catalogSearch from './modules/catalogSearch.store'
import heatmapProgressIndicator from './modules/heatmapProgressIndicator.store'
import mapDetails from './modules/mapDetails.store'
import mapDetailsNanogenres from './modules/mapDetailsNanogenres.store'
import mapTitleList from './modules/mapTitleList.store'
import progressIndicator from './modules/progressIndicator.store'
import selectedNanogenre from './modules/selectedNanogenre.store'
import selectedNanogenreTitles from './modules/selectedNanogenreTitles.store'
import server from './modules/server.store'
import settings from './modules/settings.store'
import settingsSliders from './modules/settingsSliders.store'
import titlePresets from './modules/titlePresets.store'
import titles from './modules/titles.store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    catalogSearch,
    heatmapProgressIndicator,
    mapDetails,
    mapDetailsNanogenres,
    mapTitleList,
    progressIndicator,
    selectedNanogenre,
    selectedNanogenreTitles,
    server,
    settings,
    settingsSliders,
    titlePresets,
    titles,
  },
})
