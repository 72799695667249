<template>
  <v-card tile flat outlined>
    <v-row justify="center">
      <v-expansion-panels class="pt-3" focusable v-model="openedPanel">
        <v-expansion-panel
          :ref="`map-details-nanogenre-${index}`" 
          @click="onClick(index)" 
          v-for="(nanogenre, index) in getNanogenreList" :key="index"
          v-model="nanogenre.panel"
        >
          <v-expansion-panel-header>
            <span>
              <v-badge
                color="green"
                :content="nanogenre.nanogenreCount"
                inline
              ></v-badge>
              <v-icon class="mr-4" color="green">mdi-movie-open-plus</v-icon>
              {{nanogenre.description}}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <selected-nanogenre-titles></selected-nanogenre-titles>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-card>
  <!-- <v-row>
    <v-col cols="12">{{ nanogenre.nanogenreCount }}, {{ nanogenre.titleCount }}, {{ nanogenre.nanogenreId }}, {{ nanogenre.titleAverageDistance.toFixed(2) }}</v-col>
  </v-row> -->
</template>

<script>
import SelectedNanogenreTitles from './SelectedNanogenreTitles'

export default {
  components: { SelectedNanogenreTitles },
  props: ['nanogenres'],
  data: () => ({    
    openedPanel: null,
    titleChanged: null,
    panelList: [],
  }),
  methods: {
    onClick(index) {
      const commonTitleList = []
      for (let x = 0; x < this.nanogenres[index].commonTitleList.length; x++) {
        const item = this.$store.getters['titles/getTitle'](this.nanogenres[index].commonTitleList[x])
        commonTitleList.push(`${item.title} ${item.type === '01-Movie' ? '' : '[TV]'} (${item.year})`)
      }
      this.$store.dispatch('selectedNanogenreTitles/setSelectedNanogenreTitles', commonTitleList)
      const nanogenreTitleList = this.$store.getters['server/getNanogenreTitlesByNgId'](this.nanogenres[index].nanogenreId)
      const nanogenreKeywordList = this.$store.getters['server/getNanogenreKeywordsByNgId'](this.nanogenres[index].nanogenreId)
      if (nanogenreTitleList) {
        this.$store.dispatch('mapDetails/setTitleList', nanogenreTitleList.titleIdList)
        this.$store.dispatch('mapDetails/setKeywordList', nanogenreKeywordList)
        this.$store.dispatch('selectedNanogenre/setSelectedNanogenre', this.nanogenres[index].description)
      } 
    },
  },
  computed: {
    getNanogenreList() {
      const result = []
      for (let x = 0; x < this.nanogenres.length; x++) {
        const obj = this.nanogenres[x]
        obj.panel = false
        result.push(obj)
      }
      return result
    },
    heatmapTitleToggled: {
      get() {
        const titleChanged = this.$store.getters['mapDetailsNanogenres/getTitleChanged']()
        return titleChanged
      }
    }
  },
  watch: {
    heatmapTitleToggled() {
      // console.log('titleChanged detected')
      const currentPanel = this.openedPanel
      this.openedPanel = null

      if (currentPanel !== null) {
        const commonTitleList = []
        if (this.nanogenres[currentPanel].commonTitleList) {
          for (let x = 0; x < this.nanogenres[currentPanel].commonTitleList.length; x++) {
            const item = this.$store.getters['titles/getTitle'](this.nanogenres[currentPanel].commonTitleList[x])
            commonTitleList.push(`${item.title} ${item.type === '01-Movie' ? '' : '[TV]'} (${item.year})`)
          }
        }

        this.$store.dispatch('selectedNanogenreTitles/setSelectedNanogenreTitles', commonTitleList)
        const nanogenreTitleList = this.$store.getters['server/getNanogenreTitlesByNgId'](this.nanogenres[0].nanogenreId)
        const nanogenreKeywordList = this.$store.getters['server/getNanogenreKeywordsByNgId'](this.nanogenres[0].nanogenreId)
        if (nanogenreTitleList) {
          this.$store.dispatch('mapDetails/setTitleList', nanogenreTitleList.titleIdList)
          this.$store.dispatch('mapDetails/setKeywordList', nanogenreKeywordList)
          this.$store.dispatch('selectedNanogenre/setSelectedNanogenre', this.nanogenres[0].description)
        } 
      }
    }
  },
  mounted() {
    // console.log('MapDetailsNanogenres -> mounted')
  }
}
</script>

<style scoped></style>
