import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../components/HomePage'
import UserLogin from '../components/UserLogin'
import PasswordReset from '../components/PasswordReset'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    props: true,
    meta: { transition: 'none', requiresAuth: true },
  },
  {
    path: '/titles/:idList',
    name: 'HeatmapPage',
    component: HomePage,
    props: { default: true },
    meta: { transition: 'none', requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: UserLogin,
    meta: { transition: 'zoom' },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { transition: 'zoom' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  // console.log(`router: ${to} ${from}`)
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  await store.dispatch('auth/setAuth')
  const auth = store.getters['auth/getAuth']()
  const currentUser = auth?.currentUser

  if (requiresAuth && !currentUser) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  }
  else { 
    next()
  }
})

export default router
