<template>
  <v-card flat :height="getViewportHeight" class="scrollable-card" style="margin-top: 128px">
    <v-app-bar fixed flat class="mt-16" width="25%">
      <show-map-url></show-map-url>
      <v-switch
        flat
        v-model="showTitleImages"
        label="Images" 
        class="pt-6"
      ></v-switch>

      <v-spacer></v-spacer>

      <v-btn icon @click="enableTitleList()">
        <v-icon>mdi-folder-check</v-icon>
      </v-btn>

      <v-btn icon @click="disableTitleList()">
        <v-icon>mdi-folder-cancel</v-icon>
      </v-btn>

      <v-btn icon @click="clearTitleList()">
        <v-icon>mdi-delete-sweep</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
    <v-card v-for="item, index in getTitleList" :key="index" @click="toggleTitle(item)" tile outlined>
      <v-card-text :class="{ 'background-red': item.isCommonTitle }">
        <v-icon v-if="item.enabled" color="green">mdi-check-circle-outline</v-icon>
        <v-icon v-else color="yellow">mdi-cancel</v-icon>
        {{ item.title }} {{ item.type === '01-Movie' ? '' : '[TV]'}} ({{item.year}})
      </v-card-text>
      <v-lazy v-if="showTitleImages" min-height="92" transition="fade-transition">
        <v-img
          :src="`https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/medium/${item.ncTitleId}.jpg`"
          min-height="92"
          min-width="65"
          max-height="92"
          max-width="65"
          class="rounded ml-10 pt-2 mb-2"
        ></v-img>
      </v-lazy>        
      <v-text-field v-model="getNanogenreTitleDistance" v-if="getNanogenreTitleDistance">{{ getNanogenreTitleDistance }}</v-text-field>
    </v-card>
    </v-card>
  </v-card>
</template>

<script>
import ShowMapUrl from './ShowMapUrl.vue'
export default {
  name: 'TitleList',
  components: {
    ShowMapUrl
  },
  data: () => ({
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  }),
  methods: {
    async toggleTitle(title) {
      this.$store.dispatch('heatmapProgressIndicator/show')
      this.$store.dispatch('mapTitleList/toggleTitle', title)
      const titleList = this.$store.getters['mapTitleList/getTitleList']()
      await this.$store.dispatch('server/fetchTitleListHeatmapData', titleList)
      const titleHeatmapData = this.$store.getters['server/getTitleListHeatmapData']()

      // get the map coordinate list
      const nanogenreList = titleHeatmapData.nanogenres.map(item => { 
        return { 
          nanogenreId: item.nanogenreId, nanogenreCount: item.nanogenreCount 
        }
      })


      if (nanogenreList.length > 0) {
        const nanogenres = this.$store.getters['server/getNanogenres']()
        const nanogenreTitleList = this.$store.getters['server/getNanogenreTitlesByNgId'](nanogenreList[0].nanogenreId)
        const nanogenreKeywordList = this.$store.getters['server/getNanogenreKeywordsByNgId'](nanogenreList[0].nanogenreId)
        this.$store.dispatch('mapDetails/setNanogenreList', nanogenres)
        this.$store.dispatch('mapDetails/setTitleList', nanogenreTitleList.titleIdList)
        this.$store.dispatch('mapDetails/setKeywordList', nanogenreKeywordList)
      } else {
        this.$store.dispatch('mapDetails/setNanogenreList', [])
        this.$store.dispatch('mapDetails/setTitleList', [])
        this.$store.dispatch('mapDetails/setKeywordList', [])
      }

      this.$store.dispatch('server/clearTitleCoordinates')
      this.$store.dispatch('server/setDisplayCoordinates', nanogenreList)

      // increment "title changed flag" to force a click event on the first details nanogenre item
      this.$store.dispatch('mapDetailsNanogenres/incrementTitleChanged')
    },
    setViewportDimensions() {
      // console.log(`MapTitleList.setViewportDimensions: h${window.innerHeight} X w${window.innerWidth}`)

      this.viewportWidth = window.innerWidth
      this.viewportHeight = window.innerHeight
    },
    async showMapUrl() {

    },
    async enableTitleList() {
      this.$store.dispatch('heatmapProgressIndicator/show')
      const currentTitleList = this.$store.getters['mapTitleList/getTitleList']()

      const titleList = currentTitleList.map(item => {
        return {
          enabled: true,
          ncTitleId: item.ncTitleId
        }
      })

      await this.$store.dispatch('server/fetchTitleListHeatmapData', titleList)
      this.$store.dispatch('mapTitleList/setMapTitleList', titleList)

      const titleHeatmapData = this.$store.getters['server/getTitleListHeatmapData']()

      // get the map coordinate list

      const nanogenreList = titleHeatmapData.nanogenres.map(item => { 
        return { 
          nanogenreId: item.nanogenreId, nanogenreCount: item.nanogenreCount 
        }
      })

      this.$store.dispatch('server/setDisplayCoordinates', nanogenreList)

      const nanogenres = this.$store.getters['server/getNanogenres']()


      const nanogenreTitleList = this.$store.getters['server/getNanogenreTitlesByNgId'](nanogenreList[0].nanogenreId)
      const nanogenreKeywordList = this.$store.getters['server/getNanogenreKeywordsByNgId'](nanogenreList[0].nanogenreId)
      // const nanogenreCommonTitleList = this.$store.getters['server/getNanogenreCommonTitleList'](nanogenreList[0].nanogenreId)

      this.$store.dispatch('mapDetails/setNanogenreList', nanogenres)
      this.$store.dispatch('mapDetails/setTitleList', nanogenreTitleList.titleIdList)
      this.$store.dispatch('mapDetails/setKeywordList', nanogenreKeywordList)
    },
    disableTitleList() {
      // disable each title in the map title list
      const currentTitleList = this.$store.getters['mapTitleList/getTitleList']()

      const titleList = currentTitleList.map(item => {
        return {
          enabled: false,
          ncTitleId: item.ncTitleId
        }
      })

      this.$store.dispatch('mapTitleList/setMapTitleList', titleList)

      // clear the title coordinates
      // clear the title details
      this.$store.dispatch('server/clearTitleListHeatmapData')
      this.$store.dispatch('server/clearTitleCoordinates')
      this.$store.dispatch('server/setDisplayCoordinates', [])
      this.$store.dispatch('mapDetails/setNanogenreList', [])
      this.$store.dispatch('mapDetails/setTitleList', [])
      this.$store.dispatch('mapDetails/setKeywordList', [])
    },
    clearTitleList() {
      this.$store.dispatch('server/clearTitleListHeatmapData')
      this.$store.dispatch('server/clearTitleCoordinates')
      this.$store.dispatch('server/setDisplayCoordinates', [])
      this.$store.dispatch('mapTitleList/setMapTitleList', [])
      this.$store.dispatch('mapDetails/setNanogenreList', [])
      this.$store.dispatch('mapDetails/setTitleList', [])
      this.$store.dispatch('mapDetails/setKeywordList', [])
      this.$store.dispatch('mapDetails/setCommonNanogenreList')
      this.$store.dispatch('catalogSearch/clearIgnoreList')
    }
  },
  computed: {
    getViewportHeight() {
      // console.log(`MapTitleList.getViewportHeight: ${this.viewportHeight - 130}`)
      return this.viewportHeight - 130
    },
    getTitleList() {
      const result = []
      const data = this.$store.getters['mapTitleList/getTitleList']()

      for (let x = 0; x < data.length; x++) {
        const item = this.$store.getters['titles/getTitle'](data[x].ncTitleId)
        if (item) {
          result.push( {
            ncTitleId: item.ncTitleId, 
            title: item.title, 
            year: item.year, 
            type: item.type, 
            enabled: data[x].enabled,
            isCommonTitle: data[x].isCommonTitle,
          } )
        }
      }
      return result
    },
    getNanogenreTitleDistance: {
      get: function() {
        return this.$store.getters['mapTitleList/getNanogenreTitleDistance']()
      },
      set: function(ncTitleId) {
        this.$store.dispatch('mapTitleList/setDistance', ncTitleId)
      }
    },
    showTitleImages: {
      get() {
        return this.$store.getters['mapTitleList/showTitleImages']()
      },
      set() {
        this.$store.dispatch('mapTitleList/toggleShowTitleImages')
      }
    },
  },
  watch: {},
  mounted() {
    this.setViewportDimensions()
    window.addEventListener('resize', this.setViewportDimensions)

    // window.onresize = this.setViewportDimensions
  }
}
</script>

<style scoped>
  .scrollable-card {
    flex-grow: 1;
    overflow: auto;
    overflow-x: hidden;
  }

  .background-red {
    background-color: red !important;
  }
</style>
