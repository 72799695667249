export default {
  namespaced: true,
  state: {
    selectedNanogenre: null,
  },
  getters: {
    getSelectedNanogenre: (state) => () => {
      return state.selectedNanogenre
    },
  },
  mutations: {    
    SET_SELECTED_NANOGENRE(state, data) {
      state.selectedNanogenre = data
    },
  },
  actions: {
    setSelectedNanogenre( { commit }, data) {
      commit('SET_SELECTED_NANOGENRE', data)
    },
  },
}
