export default {
  namespaced: true,
  state: {
    titleList: [],
  },
  getters: {
    getTitleList: (state) => () => {
      return state.titleList
    },
    getTitle: (state) => (ncTitleId) => {
      if (!ncTitleId) {
        return null;
      }
      return state.titleList.find((item) => item.ncTitleId === ncTitleId)
    },
  },
  mutations: {
    SET_TITLE_LIST(state, data) {
      data = data.map((item, index) => {
        item.id = index + 1;
        return item;
      })

      state.titleList = data
    },
  },
  actions: {
    setTitleList({commit}, titleList) {
      commit('SET_TITLE_LIST', titleList)
    }
  },
}
