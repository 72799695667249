import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    titles: [],
    ignoreList: {
      titles: [],
      actors: [],
      directors: [],
    },
  },
  getters: {
    getTitles: (state) => () => {
      return state.titles
    },
    getIgnoreList: (state) => () => {
      return state.ignoreList
    },
    getNcTitleId: (state) => (id) => {
      if (!id) {
        return null;
      }
      return state.titles.find((item) => item.id === id)
    },
  },
  mutations: {
    CLEAR_TITLES(state) {
      state.titles = []
    },
    CLEAR_IGNORE_LIST(state) {
      state.ignoreList = {
        titles: [],
        actors: [],
        directors: [],
      }
    },
    ADD_IGNORE_ITEM(state, data) {
      if (data.type === 'title') {
        state.ignoreList.titles.push(data.ncTitleId)
      } else if (data.type === 'actor') {
        state.ignoreList.actors.push(data.ncActorId)
      } else if (data.type === 'director') {
        state.ignoreList.directors.push(data.ncDirectorId)
      }
    },
    SET_TITLES(state, data) {
      const result = []
      // go through the list and set up a transposition id
      if (data?.titleResult) {
        for (let x = 0; x < data.titleResult.length; x++) {
          result.push({
            id: result.length + 1, 
            ncTitleId: data.titleResult[x].TableRowId,
            title: `Title • ${data.titleResult[x].SearchTerm}`,
            type: 'title',
          })
        }  
      }

      if (data?.actorResult) {
        for (let x = 0; x < data.actorResult.length; x++) {
          result.push({
            id: result.length + 1,
            ncActorId: data.actorResult[x].TableRowId,
            titleIdList: data.actorResult[x].titles,
            title: `Actor • ${data.actorResult[x].SearchTerm} • Title Count: ${data.actorResult[x].titleCount}`,
            type: 'actor',
          })
        }  
      }

      if (data?.directorResult) {
        for (let x = 0; x < data.directorResult.length; x++) {
          result.push({
            id: result.length + 1,
            ncDirectorId: data.directorResult[x].TableRowId,
            titleIdList: data.directorResult[x].titles,
            title: `Director • ${data.directorResult[x].SearchTerm} • Title Count: ${data.directorResult[x].titleCount}`,
            type: 'director',
          })
        }  
      }

      state.titles = result
    },
  },
  actions: {
    async fetchTitle({ commit }, id) {
      let response = await dataApi.getTitle(id)
      if (response.data) {
        commit('SET_TITLES', response.data)
      }
    },
    addIgnoreItem({ commit }, data) {
      commit('ADD_IGNORE_ITEM', data)
    },
    clearIgnoreList({ commit }) { 
      commit('CLEAR_IGNORE_LIST')
    },
    clearTitles({ commit }) {
      commit('CLEAR_TITLES')
    },
    setTitles({ commit }, data) {
      commit('SET_TITLES', data)
    }
  },
}
