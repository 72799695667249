export default {
  namespaced: true,
  state: {
    titlePresetsProgress: true,
    titleProgress: true,
    nanogenreProgress: true,
    nanogenreTitlesProgress: true,
    coordinatesProgress: true,
  },
  getters: {
    showTitlePresetsProgress: (state) => () => {
      return state.titlePresetsProgress
    },
    showTitlesProgress: (state) => () => {
      return state.titleProgress
    },
    showNanogenresProgress: (state) => () => {
      return state.nanogenreProgress
    },
    showNanogenreTitlesProgress: (state) => () => {
      return state.nanogenreTitlesProgress
    },
    showCoordinatesProgress: (state) => () => {
      return state.coordinatesProgress
    },
  },
  mutations: {
    SET_SHOW_TITLE_PRESETS(state, data) {
      state.titlePresetsProgress = data
    },
    SET_SHOW_TITLES(state, data) {
      state.titleProgress = data
    },
    SET_SHOW_NANOGENRES(state, data) {
      state.nanogenreProgress = data
    },
    SET_SHOW_NANOGENRE_TITLES(state, data) {
      state.nanogenreTitlesProgress = data
    },
    SET_SHOW_COORDINATES(state, data) {
      state.coordinatesProgress = data
    },
  },
  actions: {
    setShowTitlePresets({ commit }) {
      commit('SET_SHOW_TITLE_PRESETS', false)
    },
    setShowTitles({ commit }) {
      commit('SET_SHOW_TITLES', false)
    },
    setShowNanogenres({ commit }) {
      commit('SET_SHOW_NANOGENRES', false)
    },
    setShowNanogenreTitles({ commit }) {
      commit('SET_SHOW_NANOGENRE_TITLES', false)
    },
    setShowCoordinates({ commit }) {
      commit('SET_SHOW_COORDINATES', false)
    },
  },
}
