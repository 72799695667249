<template>
  <v-card>
    <v-card tile flat color="green">
      <v-card-text class="white--text">
        <v-icon color="white">mdi-arrow-left-bold</v-icon> Heatmap Titles
      </v-card-text>
    </v-card>
    <v-card v-for="(title, i) in commonTitleList" :key="i" tile flat outlined>
      <span>        
        <v-card-text>
          {{ title }}
        </v-card-text>
      </span>
    </v-card>
  </v-card>
</template>

<script>

export default {
  props: [],
  data: () => ({    
  }),
  methods: {
  },
  computed: {
    commonTitleList: {
      get() {
        return this.$store.getters['selectedNanogenreTitles/getSelectedNanogenreTitles']()
      },
      set() {} 
    },
  },
  mounted() {
  }
}
</script>

<style scoped></style>
