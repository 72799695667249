export default {
  namespaced: true,
  state: {
    drawer: false,
    commonNanogenreList: false,
    nanogenreList: [],
    titleList: [],
    keywordList: [],
    showTitleImages: false,
  },
  getters: {
    getDrawer: (state) => () => {
      return state.drawer
    },
    getCommonNanogenreList: (state) => () => {
      return state.commonNanogenreList
    },
    getNanogenreList: (state) => () => {
      return state.nanogenreList
    },
    getTitleList: (state) => () => {
      return state.titleList
    },
    getKeywordList: (state) => () => {
      return state.keywordList
    },
    showTitleImages: (state) => () => {
      return state.showTitleImages
    },
  },
  mutations: {
    TOGGLE_DRAWER(state) {
      state.drawer = !state.drawer
    },
    SET_COMMON_NANOGENRE_LIST(state) {
      state.commonNanogenreList = false
    },
    TOGGLE_COMMON_NANOGENRE_LIST(state) {
      state.commonNanogenreList = !state.commonNanogenreList
    },
    SET_NANOGENRE_LIST(state, nanogenreList) {
      state.nanogenreList = []

      if (!nanogenreList) {
        return
      }

      if (state.commonNanogenreList) {
        state.nanogenreList = nanogenreList
          .filter(item => item.nanogenreCount > 1)
          .map(item => {
            return { 
              nanogenreId: item.nanogenreId, 
              description: item.description.join(' • '), 
              nanogenreCount: item.nanogenreCount, 
              titleCount: item.titleCount,
              commonTitleList: item.commonTitleList,
              titleAverageDistance: item.titleAverageDistance,
              titleDistances: item.titleDistances,
            }
        })
      } else {
        state.nanogenreList = nanogenreList
          .map(item => { 
            return { 
              nanogenreId: item.nanogenreId, 
              description: item.description.join(' • '), 
              nanogenreCount: item.nanogenreCount, 
              titleCount: item.titleCount,
              commonTitleList: item.commonTitleList,
              titleAverageDistance: item.titleAverageDistance,
              titleDistances: item.titleDistances,
            }
        })
      }
    },
    SET_TITLE_LIST(state, data) {
      state.titleList = data
    },
    SET_KEYWORD_LIST(state, data) {
      state.keywordList = data
    },
    TOGGLE_SHOW_TITLE_IMAGES(state) {
      state.showTitleImages = !state.showTitleImages
    },
  },
  actions: {
    toggleDrawer({ commit }) {
      commit('TOGGLE_DRAWER')
    },
    setCommonNanogenreList({ commit }) {
      commit('SET_COMMON_NANOGENRE_LIST')
    },
    toggleCommonNanogenreList({ commit }) {
      commit('TOGGLE_COMMON_NANOGENRE_LIST')
    },
    setNanogenreList({ commit }, data) {
      commit('SET_NANOGENRE_LIST', data)
    },
    setTitleList({ commit }, data) {
      commit('SET_TITLE_LIST', data)
    },
    setKeywordList({ commit }, data) {
      commit('SET_KEYWORD_LIST',  data)
    },
    toggleShowTitleImages({ commit }) {
      commit('TOGGLE_SHOW_TITLE_IMAGES')
    },
  },
}
