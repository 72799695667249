<template>
  <div>
    <v-navigation-drawer
      v-model="getDrawer"
      hide-overlay
      absolute
      width="40%"
      class="offset-top"
    >
      <v-card tile outlined class="">
        <v-card-title>Heatmap Settings</v-card-title>
        <div class="pt-0">
          <v-row>
            <v-col cols="4" class="pt-0">
              <v-text-field
                v-model="radius"
                label="Radius"
                outlined
                type="number"
                dense
                @input="setRadius"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pt-0">
              <v-text-field
                v-model="blur"
                label="Blur"
                outlined
                type="number"
                dense
                @input="setBlur"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pt-0">
              <v-text-field
                v-model="opacity"
                label="Opacity"
                outlined
                type="number"
                step="0.1"
                dense
                @input="setOpacity"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="4" class="pt-0">
              <v-text-field
                v-model="zoomRadiusMultiplier"
                label="Zoom Radius Multiplier"
                outlined
                dense
                type="number"
                step="0.1"
                @input="setZoomRadiusMultiplier"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pt-0">
              <v-text-field
                v-model="zoomBlurMultiplier"
                label="Zoom Blur Multiplier"
                outlined
                dense
                type="number"
                step="0.1"
                @input="setZoomBlurMultiplier"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pt-0">
              <v-text-field
                v-model="zoomOpacityMultiplier"
                label="Zoom Opacity Multiplier"
                outlined
                dense
                type="number"
                step="0.1"
                @input="setZoomOpacityMultiplier"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pt-0">
              <v-text-field 
                v-model="weightPower"
                label="Weight Power"
                outlined
                dense
                type="number"
                step="0.1"
                @input="setWeightPower"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="pt-0">
              <v-text-field 
                v-model="maxNanogenres"
                label="Max Nanogenres"
                outlined
                dense
                type="number"
                step="50"
                @input="setMaxNanogenres"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <v-card height="65">
        <v-card-text class="pt-4 pb-4">
          <v-row>
            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop1.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 1" 
                hint="ColorStop 1"
                v-model="colorstop1.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop1.val"
              >
              </v-btn>              
            </v-col>

            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop2.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 2" 
                hint="ColorStop 2"
                v-model="colorstop2.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop2.val"
              >
              </v-btn>              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card height="65">
        <v-card-text class="pt-4 pb-4">
          <v-row>
            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop3.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 3" 
                hint="ColorStop 3"
                v-model="colorstop3.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop3.val"
              >
              </v-btn>              
            </v-col>

            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop4.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 4" 
                hint="ColorStop 4"
                v-model="colorstop4.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop4.val"
              >
              </v-btn>              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card height="65">
        <v-card-text class="pt-4 pb-4">
          <v-row>
            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop5.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 5" 
                hint="ColorStop 5"
                v-model="colorstop5.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop5.val"
              >
              </v-btn>              
            </v-col>

            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop6.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 6" 
                hint="ColorStop 6"
                v-model="colorstop6.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop6.val"
              >
              </v-btn>              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card height="65">
        <v-card-text class="pt-4 pb-4">
          <v-row>
            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop7.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 7" 
                hint="ColorStop 7"
                v-model="colorstop7.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop7.val"
              >
              </v-btn>              
            </v-col>

            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop8.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 8" 
                hint="ColorStop 8"
                v-model="colorstop8.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop8.val"
              >
              </v-btn>              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card height="65">
        <v-card-text class="pt-4 pb-4">
          <v-row>
            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop9.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 9" 
                hint="ColorStop 9"
                v-model="colorstop9.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop9.val"
              >
              </v-btn>              
            </v-col>

            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop10.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 10" 
                hint="ColorStop 10"
                v-model="colorstop10.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop10.val"
              >
              </v-btn>              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card height="65">
        <v-card-text class="pt-4 pb-4">
          <v-row>
            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop11.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 11" 
                hint="ColorStop 11"
                v-model="colorstop11.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop11.val"
              >
              </v-btn>              
            </v-col>

            <v-col cols="1" class="pt-0 pb-4">
              <v-checkbox
                v-model="colorstop12.enabled"
                @change="getColorstops"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="pl-0 pt-0 pb-4">
              <v-text-field 
                class="pl-0"
                filled
                single-line
                outlined 
                label="Colorstop 12" 
                hint="ColorStop 12"
                v-model="colorstop12.val"
                @keyup.native="getColorstops"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pt-0 pb-4">
              <v-btn
                class="mt-2 mb-8"
                fab
                small
                :color="colorstop12.val"
              >
              </v-btn>              
            </v-col>
          </v-row>
          <v-row class="pt-0 pb-0 mt-0 mb-0">
            <v-col cols="12" class="pt-0 pb-0">
                <v-textarea
                  disabled
                  height="75"
                  class="pl-0 colorstops-height"
                  filled
                  outlined 
                  label="Colorstop list" 
                  hint="ColorStop list"
                  v-model="getGradientList"
                ></v-textarea>
            </v-col>
          </v-row>
          <!-- <v-row class="pt-0 pb-0 mt-0 mb-0">
            <v-col cols="12" class="pt-0 pb-0">
              <v-card class="d-flex justify-end" flat tile>
                <v-btn
                  text
                  small
                  color="green"
                  outlined
                >Apply Colorstops</v-btn>                
              </v-card>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>

    </v-navigation-drawer>
  </div>
</template>

<script>

export default {
  name: 'HeatmapSettings',
  data: () => ({
      colorstop1: { enabled: true, val: '#ff000000' },
      colorstop2: { enabled: true, val: '#003804ff' },
      colorstop3: { enabled: true, val: '#004905ff' },
      colorstop4: { enabled: true, val: '#005506ff' },
      colorstop5: { enabled: true, val: '#006107ff' },
      colorstop6: { enabled: true, val: '#007108ff' },
      colorstop7: { enabled: true, val: '#808000' },
      colorstop8: { enabled: true, val: '#cccc00' },
      colorstop9: { enabled: true, val: '#ffcc00' },
      colorstop10: { enabled: true, val: '#FF9435ff' },
      colorstop11: { enabled: true, val: '#e62e00' },
      colorstop12: { enabled: true, val: '#990000' },
      gradientList: [],
    }),
  methods: {
    setRadius(val) {
      this.$store.dispatch('settings/setRadius', val)
    },
    setBlur(val) {
      this.$store.dispatch('settings/setBlur', val)
    },
    setOpacity(val) {
      this.$store.dispatch('settings/setOpacity', val)
    },
    setZoomRadiusMultiplier(val) {
      this.$store.dispatch('server/setZoomRadiusMultiplier', val)
    },
    setZoomBlurMultiplier(val) {
      this.$store.dispatch('server/setZoomBlurMultiplier', val)
    },
    setZoomOpacityMultiplier(val) {
      this.$store.dispatch('server/setZoomOpacityMultiplier', val)
    },
    setWeightPower(val) {
      this.$store.dispatch('server/setWeightPower', val)
    },
    setMaxNanogenres(val) {
      this.$store.dispatch('server/setMaxNanogenres', val)
    },
    getColorstops() {
      this.gradientList = []
      if (this.colorstop1.enabled === true) {
        this.gradientList.push(this.colorstop1.val)
      }
      if (this.colorstop2.enabled === true) {
        this.gradientList.push(this.colorstop2.val)
      }
      if (this.colorstop3.enabled === true) {
        this.gradientList.push(this.colorstop3.val)
      }
      if (this.colorstop4.enabled === true) {
        this.gradientList.push(this.colorstop4.val)
      }
      if (this.colorstop5.enabled === true) {
        this.gradientList.push(this.colorstop5.val)
      }
      if (this.colorstop6.enabled === true) {
        this.gradientList.push(this.colorstop6.val)
      }
      if (this.colorstop7.enabled === true) {
        this.gradientList.push(this.colorstop7.val)
      }
      if (this.colorstop8.enabled === true) {
        this.gradientList.push(this.colorstop8.val)
      }
      if (this.colorstop9.enabled === true) {
        this.gradientList.push(this.colorstop9.val)
      }
      if (this.colorstop10.enabled === true) {
        this.gradientList.push(this.colorstop10.val)
      }
      if (this.colorstop11.enabled === true) {
        this.gradientList.push(this.colorstop11.val)
      }
      if (this.colorstop12.enabled === true) {
        this.gradientList.push(this.colorstop12.val)
      }
      this.$store.dispatch('server/setColorstopList', this.gradientList)
    }
  },
  computed: {
    getDrawer: {
      get() {
        return this.$store.getters['settingsSliders/getDrawer']()
      },
      set() {}
    },
    radius: {
      get() {
        return this.$store.getters['settings/getRadius']()
      },
      set() {}
    },
    blur: {
      get() {
        return this.$store.getters['settings/getBlur']()
      },
      set() {}
    },
    opacity: {
      get() {
        return this.$store.getters['settings/getOpacity']()
      },
      set() {}
    },
    zoomRadiusMultiplier: {
      get() {
        return this.$store.getters['server/getZoomRadiusMultiplier']()
      },
      set() {}
    },
    zoomOpacityMultiplier: {
      get() {
        return this.$store.getters['server/getZoomOpacityMultiplier']()
      },
      set() {}
    },
    zoomBlurMultiplier: {
      get() {
        return this.$store.getters['server/getZoomBlurMultiplier']()
      },
      set() {}
    },
    weightPower: {
      get() {
        return this.$store.getters['server/getWeightPower']()
      },
      set() {}
    },
    maxNanogenres: {
      get() {
        return this.$store.getters['server/getMaxNanogenres']()
      },
      set() {}
    },
    getGradientList: {
      get() {
        return this.gradientList.join(' • ')
      },
      set() {}
    },
    zoomLevel: {
      get() {
        return this.$store.getters['settings/getZoomLevel']()
      }
    }
  },
  watch: {
  },
  mounted() {
    this.gradientList = []
    if (this.colorstop1.enabled  === true) {
      this.gradientList.push(this.colorstop1.val)
    }
    if (this.colorstop2.enabled  === true) {
      this.gradientList.push(this.colorstop2.val)
    }
    if (this.colorstop3.enabled  === true) {
      this.gradientList.push(this.colorstop3.val)
    }
    if (this.colorstop4.enabled  === true) {
      this.gradientList.push(this.colorstop4.val)
    }
    if (this.colorstop5.enabled  === true) {
      this.gradientList.push(this.colorstop5.val)
    }
    if (this.colorstop6.enabled  === true) {
      this.gradientList.push(this.colorstop6.val)
    }
    if (this.colorstop7.enabled  === true) {
      this.gradientList.push(this.colorstop7.val)
    }
    if (this.colorstop8.enabled  === true) {
      this.gradientList.push(this.colorstop8.val)
    }
    if (this.colorstop9.enabled  === true) {
      this.gradientList.push(this.colorstop9.val)
    }
    if (this.colorstop10.enabled  === true) {
      this.gradientList.push(this.colorstop10.val)
    }
    if (this.colorstop11.enabled  === true) {
      this.gradientList.push(this.colorstop11.val)
    }
    if (this.colorstop12.enabled  === true) {
      this.gradientList.push(this.colorstop12.val)
    }
    this.$store.dispatch('server/setColorstopList', this.gradientList)
  }
}
</script>

<style scoped>
.nanocrowd-settings {
  justify-content: left !important;
}

.colorstops-height {
  height: 75px !important;
}

.offset-top {
  top: 130px !important;
}

</style>
