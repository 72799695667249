<template>
  <v-row justify="start">
    <v-col cols="auto" class="pl-0">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>mdi-link-box-variant</v-icon>
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
            >
              Copy Url and Share 
              <v-spacer></v-spacer> 
              <v-btn icon  @click="copyToClipboard()">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div class="text pa-12">{{ linkUrl }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ShowMapUrl',
  methods: {
    copyToClipboard() {
      const protocol = window.location.protocol
      const host = window.location.host
      const titleList = this.$store.getters['mapTitleList/getTitleList']()
      const titleString = titleList
        .filter(item => item.enabled)
        .map(item => item.ncTitleId)
        .join(',')
      const textToCopy = `${protocol}//${host}/heatmap/titles/${titleString}`
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          // console.log("Text copied to clipboard successfully!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
        })      
    },
  },
  computed: {
    // https://why.nanocrowd.io/heatmap/titles/2716,4188,1818,2753,2769,2187,2792,2296,3086,3724,2704,2295,3190,2787,1888,2593,2862,1925,2061,2741,4272,11645,2041,2446,2780
    linkUrl() {
      const protocol = window.location.protocol
      const host = window.location.host
      const titleList = this.$store.getters['mapTitleList/getTitleList']()
      const titleString = titleList
        .filter(item => item.enabled)
        .map(item => item.ncTitleId)
        .join(',')
      return `${protocol}//${host}/heatmap/titles/${titleString}`
    },
  },
}
</script>

<style scoped>
</style>
