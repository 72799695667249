<template>
  <v-autocomplete
    flat
    dense
    solo-inverted
    v-model="selectedTitle"
    :loading="isLoading"
    :items="allTitles"
    :search-input.sync="search"
    item-text="title"
    item-value="ncTitleId"
    return-object
    label="Titles, Actors, Directors"
    placeholder="Start typing to search"
    class="search-padding-30"
    hide-no-data
    @change="getSelection"
    @click="selectedTitle = null; entries = null;"
  >
  </v-autocomplete>
</template>

<script>
import dataApi from '../services/dataApi'

export default {
  data: () => ({
    selectedTitle: '',
    entries: [],
    isLoading: false,
    search: null,
  }),
  computed: {
    allTitles() {
      const titles = this.$store.getters['catalogSearch/getTitles']()

      const searchList = titles.map((item) => {
        const result = {...item}
        result.titleIdList = item.type === 'actor' || item.type === 'director' ? item.titleIdList : [item.ncTitleId]
        result.title = `${item.title} ${item.type === '13-TVseries' ? ' [TV] ' : ''} ${item.year ? '(' + item.year + ')' : ''} `
        return result
      })

      return searchList
    },
  },
  methods: {
    async getSelection(item) {
      this.$store.dispatch('heatmapProgressIndicator/show')
      const currentTitleList = this.$store.getters['mapTitleList/getTitleList']()
      const titleIdList = item.titleIdList;

      const mapTitleList = [...currentTitleList, ...titleIdList.map((item) => ({...item, ncTitleId: item, enabled: true}))]

      this.$store.dispatch('mapTitleList/setMapTitleList', mapTitleList)

      await this.$store.dispatch('server/fetchTitleListHeatmapData', mapTitleList)

      const titleHeatmapData = this.$store.getters['server/getTitleListHeatmapData']()

      // get the map coordinate list

      const nanogenreList = titleHeatmapData.nanogenres.map(item => { 
        return { 
          nanogenreId: item.nanogenreId, nanogenreCount: item.nanogenreCount 
        }
      })

      this.$store.dispatch('server/setDisplayCoordinates', nanogenreList)

      const detailsNanogenreList = this.$store.getters['server/getNanogenres']()
      const detailsNanogenreTitleList = this.$store.getters['server/getNanogenreTitlesByNgId'](nanogenreList[0].nanogenreId)
      const detailsNanogenreKeywordList = this.$store.getters['server/getNanogenreKeywordsByNgId'](nanogenreList[0].nanogenreId)

      this.$store.dispatch('mapDetails/setNanogenreList', detailsNanogenreList)
      this.$store.dispatch('mapDetails/setTitleList', detailsNanogenreTitleList.titleIdList)
      this.$store.dispatch('mapDetails/setKeywordList', detailsNanogenreKeywordList)
      const formattedDescription = detailsNanogenreList[0].description.join(' • ')
      this.$store.dispatch('selectedNanogenre/setSelectedNanogenre', formattedDescription)
      // this.$store.dispatch('mapDetailsNanogenres/incrementTitleChanged')
      this.$store.dispatch('catalogSearch/addIgnoreItem', item)
      this.selectedTitle = null
    },
    clearEntries() {
      this.count = 0
      this.entries = []
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries()
      }, 500) /* 300ms throttle */
    },
    async fetchEntries() {
      // get the current mapTitleList items and make a list of items to not return
      const currentMapTitleList = this.$store.getters['mapTitleList/getTitleList']()
      const currentTitleList = currentMapTitleList.map(item => item.ncTitleId)
      const ignoreList = this.$store.getters['catalogSearch/getIgnoreList']()
      const ignoreTitleList = ignoreList.titles.concat(currentTitleList)
      ignoreList.titles = [...new Set(ignoreTitleList)]

      const response = await dataApi.search(this.val, ignoreList)
      this.isLoading = false
      if (response) {
        this.$store.dispatch('catalogSearch/setTitles', response.data)
      }
    }
  },
  watch: {
    search(val) {
      this.$store.dispatch('catalogSearch/clearTitles')
      if (!val) {
        return
      }
      this.val = val
      this.clearEntries()
      this.isLoading = true
      this.fetchEntriesDebounced()
    }
  }
}
</script>
<style scoped>
.search-padding-30 {
  padding-top: 30px !important;
}
</style>
