// eslint-disable-next-line no-unused-vars
// const zeropad = require('zeropad')

export default {
  namespaced: true,
  state: {
    drawer: false,
    radius: {
      label: 'Radius',
      value: 20,
      color: 'red',
    },
    weight: {
      label: 'weight',
      value: 0.17,
      color: 'red',
    },
    blur: {
      label: 'Blur',
      value: 18,
      color: 'red',
    },
    opacity: {
      label: 'Opacity',
      value: 0.7,
      color: 'red',
    },
    selectedColorstop: 1,
    colorstop1: '#ff000000',
    colorstop2: '#003804ff',
    colorstop3: '#004905ff',
    colorstop4: '#005506ff',
    colorstop5: '#006107ff',
    colorStop6: '#007108ff',
    colorStop7: '#808000',
    colorStop8: '#cccc00', 
    colorStop9: '#ffcc00', 
    colorStop10: '#FF9435ff', 
    colorStop11: '#e62e00',
    colorStop12: '#990000', 
    // colorstop1: '#ffffff00',
    // colorstop2: '#006837ff',
    // colorstop3: '#1a9850ff',
    // colorstop4: '#66bd63ff',
    // colorstop5: '#d9ef8bff',
    // colorStop6: '#fee08bff',
    // colorStop7: '#fdae61ff',
    // colorStop8: '#f46d43ff', 
    // colorStop9: '#d73027ff', 
    // colorStop10: '#a50026ff', 
    // colorStop11: '#aa0000ff',
    // colorStop12: '#000000ff', 

  // const DEFAULT_GRADIENT = ['#00f', '#0ff', '#0f0', '#ff0', '#f00'];
  },
  getters: {
    getDrawer: (state) => () => {
      return state.drawer
    },
    getRadius: (state) => () => {
      return state.radius.value
    },
    getWeight: (state) => () => {
      return state.weight.value
    },
    getBlur: (state) => () => {
      return state.blur.value
    },
    getOpacity: (state) => () => {
      return state.opacity.value
    },
    getSelectedColorstop: (state) => () => {
      return state.selectedColorstop
    },
    // getSelectedColorstopColor: (state) => () => {
    //   return state.colorStops[state.selectedColorstop].color
    // },
    getStop1: (state) => () => {
      return state.colorstop1
    },
    getStop2: (state) => () => {
      return state.colorstop2
    },
    getStop3: (state) => () => {
      return state.colorstop3
    },
    getStop4: (state) => () => {
      return state.colorstop4
    },
    getStop5: (state) => () => {
      return state.colorstop5
    },
    getStop6: (state) => () => {
      return state.colorstop6
    },
    getStop7: (state) => () => {
      return state.colorstop7
    },
    getStop8: (state) => () => {
      return state.colorstop8
    },
    getStop9: (state) => () => {
      return state.colorstop9
    },
    getStop10: (state) => () => {
      return state.colorstop10
    },
    getStop11: (state) => () => {
      return state.colorstop11
    },
    getStop12: (state) => () => {
      return state.colorstop12
    },
  },
  mutations: {
    SET_RADIUS(state, data) {
      state.radius.value = data
    },
    SET_WEIGHT(state, data) {
      state.weight.value = data
    },
    SET_BLUR(state, data) {
      state.blur.value = data
    },
    SET_OPACITY(state, data) {
      state.opacity.value = data
    },
    SET_SELECTED_COLORSTOP(state, data) {
      state.selectedColorstop = data
    },
    SET_SELECTED_COLORSTOP_COLOR(state, data) {
      // eslint-disable-next-line no-debugger
      // debugger
      switch (data.selectedColorstop - 1) {
        case 1:
          state.colorstop1 = data.color
          break
        case 2:
          state.colorstop2 = data.color
          break
        case 3: 
          state.colorstop3 = data.color
          break
        case 4:
          state.colorstop4 = data.color
          break
        case 5: 
          state.colorstop5 = data.color
          break
        case 6: 
          state.colorstop6 = data.color
          break
        case 7: 
          state.colorstop7 = data.color
          break
        case 8: 
          state.colorstop8 = data.color
          break
        case 9: 
          state.colorstop9 = data.color
          break
        case 10: 
          state.colorstop10 = data.color
          break
        case 11: 
          state.colorstop11 = data.color
          break
        case 12: 
          state.colorstop12 = data.color
          break
        default: 
          break
      }
    },
    TOGGLE_DRAWER(state) {
      state.drawer = !state.drawer
    },
  },
  actions: {
    setRadius({commit}, data) {
      commit('SET_RADIUS', data)
    },
    setWeight({commit}, data) {
      commit('SET_WEIGHT', data)
    },
    setBlur({commit}, data) {
      commit('SET_BLUR', data)
    },
    setOpacity({commit}, data) {
      commit('SET_OPACITY', data)
    },
    setSelectedColorstop({commit}, data) {
      commit('SET_SELECTED_COLORSTOP', data)
    },
    setSelectedColorstopColor({commit}, data) {
      commit('SET_SELECTED_COLORSTOP_COLOR', data)
    },
    toggleDrawer({ commit }) {
      commit('TOGGLE_DRAWER')
    }
  },
}
