<template>
  <v-app>
    <!-- <v-main> -->
      <progress-indicator v-if="showProgressIndicator"></progress-indicator>
      <heatmap-progress v-if="heatmapProgress"></heatmap-progress>
      <router-view></router-view>
    <!-- </v-main> -->
  </v-app>
</template>

<script>

import ProgressIndicator from './components/ProgressIndicator'
import HeatmapProgress from './components/HeatmapProgressIndicator'

export default {
  name: 'App',

  components: {
    ProgressIndicator,
    HeatmapProgress,
  },
  data: () => ({}),
  computed: {
    showProgressIndicator() {
      const result = this.$store.getters['progressIndicator/showTitlesProgress']() || 
        this.$store.getters['progressIndicator/showNanogenreTitlesProgress']() ||
        this.$store.getters['progressIndicator/showNanogenresProgress']()
      return result
    },
    heatmapProgress: {
      // eslint-disable-next-line no-unused-vars
      set(value) {
        // this.$store.dispatch('heatmapProgressIndicator/toggle')
      },
      get() {
        return this.$store.getters['heatmapProgressIndicator/isSet']()
      }
    },
  },
  methods: {},  
  async created() {
    // this.$vuetify.theme.dark = true
  },
  async mounted() { 
    const [
      titlePresetsResult,
      titleListResult,
      nanogenreTitleListResult, 
      titleNanogenreListResult, 
    ] = await Promise.all([
      this.$store.dispatch('server/fetchTitlePresets'),
      this.$store.dispatch('server/fetchTitleList'),
      this.$store.dispatch('server/fetchNanogenreTitleList'),
      this.$store.dispatch('server/fetchTitleNanogenreList'),
    ])

    if (titlePresetsResult) {
      const titlePresets = this.$store.getters['server/getTitlePresets']()
      this.$store.dispatch('titlePresets/setTitlePresets', titlePresets)
      this.$store.dispatch('progressIndicator/setShowTitlePresets')
    }

    if (titleListResult) {
      const titleList = this.$store.getters['server/getTitleList']()
      this.$store.dispatch('titles/setTitleList', titleList)
      this.$store.dispatch('progressIndicator/setShowTitles')
    }
    if (nanogenreTitleListResult) {
      this.$store.dispatch('progressIndicator/setShowNanogenreTitles')
    }
    if (titleNanogenreListResult) {
      this.$store.dispatch('progressIndicator/setShowNanogenres')
    }
    // get the route and look for title ids. forward the request if there's a route to the heatmaps
    const url = this.$route.fullPath
    if (url.indexOf('/heatmap/titles') !== -1) {
      // get the list of titles
      this.$router.push({path: '/', query: {titleIdList: url.replace('/heatmap/titles/', '')}})
    }

    // adjust the heatmap.data coordinates to dial in the proper values
    // const originalCoordinates = this.$store.getters['server/getOriginalCoordinates']()

    // await this.$store.dispatch('server/adjustCoordinates', originalCoordinates)
  }
};
</script>
<style>
body {
  background-color: #272727;
  /* overflow-x: hidden; */
  overflow: hidden;
}
</style>