export default {
  data: [
    {
      nanogenreId: 1,
      x: 7629210,
      y: -15208980
    },
    {
      nanogenreId: 2,
      x: 10005420,
      y: 812670
    },
    {
      nanogenreId: 3,
      x: 9316350,
      y: -10518360
    },
    {
      nanogenreId: 4,
      x: -4514490,
      y: -15925860
    },
    {
      nanogenreId: 5,
      x: 8525310,
      y: -17189670
    },
    {
      nanogenreId: 6,
      x: 11704920,
      y: -10255710
    },
    {
      nanogenreId: 7,
      x: -15830070,
      y: -7323300
    },
    {
      nanogenreId: 8,
      x: 9486300,
      y: -10200090
    },
    {
      nanogenreId: 9,
      x: 18271170,
      y: -8182320
    },
    {
      nanogenreId: 10,
      x: 14214000,
      y: -3952110
    },
    {
      nanogenreId: 11,
      x: 8602560,
      y: -10969500
    },
    {
      nanogenreId: 12,
      x: -5392050,
      y: -8920830
    },
    {
      nanogenreId: 13,
      x: 8309010,
      y: 12088080
    },
    {
      nanogenreId: 14,
      x: -2821170,
      y: -14918520
    },
    {
      nanogenreId: 15,
      x: -16525320,
      y: -3850140
    },
    {
      nanogenreId: 16,
      x: 3161070,
      y: -14726940
    },
    {
      nanogenreId: 17,
      x: -12937830,
      y: -4106610
    },
    {
      nanogenreId: 18,
      x: 15650850,
      y: 7483980
    },
    {
      nanogenreId: 19,
      x: -1727310,
      y: -11760540
    },
    {
      nanogenreId: 20,
      x: 11936670,
      y: 4152960
    },
    {
      nanogenreId: 21,
      x: -234840,
      y: 13342620
    },
    {
      nanogenreId: 22,
      x: 17381250,
      y: -1798380
    },
    {
      nanogenreId: 23,
      x: 9066060,
      y: -10243350
    },
    {
      nanogenreId: 24,
      x: 10175370,
      y: 19297050
    },
    {
      nanogenreId: 25,
      x: -15329490,
      y: -7295490
    },
    {
      nanogenreId: 26,
      x: -19284690,
      y: 5293170
    },
    {
      nanogenreId: 27,
      x: -14909250,
      y: -553110
    },
    {
      nanogenreId: 28,
      x: -1387410,
      y: -12143700
    },
    {
      nanogenreId: 29,
      x: 15360390,
      y: -580920
    },
    {
      nanogenreId: 30,
      x: 2144460,
      y: -10370040
    },
    {
      nanogenreId: 31,
      x: -2036310,
      y: -12993450
    },
    {
      nanogenreId: 32,
      x: 1928160,
      y: -19760550
    },
    {
      nanogenreId: 33,
      x: 13336440,
      y: -10703760
    },
    {
      nanogenreId: 34,
      x: -13682520,
      y: -8967180
    },
    {
      nanogenreId: 35,
      x: 16667460,
      y: 14365410
    },
    {
      nanogenreId: 36,
      x: 11871780,
      y: -11485530
    },
    {
      nanogenreId: 37,
      x: 2011590,
      y: -2487450
    },
    {
      nanogenreId: 38,
      x: -11995380,
      y: -13333350
    },
    {
      nanogenreId: 39,
      x: -18042510,
      y: 6325230
    },
    {
      nanogenreId: 40,
      x: 10193910,
      y: -6263430
    },
    {
      nanogenreId: 41,
      x: 5608350,
      y: 11423730
    },
    {
      nanogenreId: 42,
      x: 1770570,
      y: 13583640
    },
    {
      nanogenreId: 43,
      x: 7011210,
      y: 11670930
    },
    {
      nanogenreId: 44,
      x: 13219020,
      y: 7854780
    },
    {
      nanogenreId: 45,
      x: -15703380,
      y: -6822720
    },
    {
      nanogenreId: 46,
      x: -2614140,
      y: 8985720
    },
    {
      nanogenreId: 47,
      x: -13905000,
      y: 13957530
    },
    {
      nanogenreId: 48,
      x: -2082660,
      y: -9786030
    },
    {
      nanogenreId: 49,
      x: 15972210,
      y: -7823880
    },
    {
      nanogenreId: 50,
      x: 7777530,
      y: 8525310
    },
    {
      nanogenreId: 51,
      x: 4421790,
      y: 10709940
    },
    {
      nanogenreId: 52,
      x: 10005420,
      y: -10685220
    },
    {
      nanogenreId: 53,
      x: 432600,
      y: -7292400
    },
    {
      nanogenreId: 54,
      x: -2453460,
      y: -15246060
    },
    {
      nanogenreId: 55,
      x: -7548870,
      y: 5231370
    },
    {
      nanogenreId: 56,
      x: -3482430,
      y: -9362700
    },
    {
      nanogenreId: 57,
      x: -3300120,
      y: 13188120
    },
    {
      nanogenreId: 58,
      x: 8636550,
      y: -9477030
    },
    {
      nanogenreId: 59,
      x: -3674010,
      y: 9767490
    },
    {
      nanogenreId: 60,
      x: 3371190,
      y: -11232150
    },
    {
      nanogenreId: 61,
      x: 9232920,
      y: 18907710
    },
    {
      nanogenreId: 62,
      x: -13642350,
      y: -4514490
    },
    {
      nanogenreId: 63,
      x: 2011590,
      y: 13858650
    },
    {
      nanogenreId: 64,
      x: -1962150,
      y: -16880670
    },
    {
      nanogenreId: 65,
      x: 15344940,
      y: 6686760
    },
    {
      nanogenreId: 66,
      x: 3059100,
      y: 14207820
    },
    {
      nanogenreId: 67,
      x: -2363850,
      y: 16383180
    },
    {
      nanogenreId: 68,
      x: -4854390,
      y: -12322920
    },
    {
      nanogenreId: 69,
      x: -8000010,
      y: 7168800
    },
    {
      nanogenreId: 70,
      x: -17038260,
      y: 6000780
    },
    {
      nanogenreId: 71,
      x: -3049830,
      y: -14927790
    },
    {
      nanogenreId: 72,
      x: -9742770,
      y: -3219780
    },
    {
      nanogenreId: 73,
      x: 9724230,
      y: -5898810
    },
    {
      nanogenreId: 74,
      x: -11698740,
      y: 8237940
    },
    {
      nanogenreId: 75,
      x: -3423720,
      y: -14158380
    },
    {
      nanogenreId: 76,
      x: -8395530,
      y: -16105080
    },
    {
      nanogenreId: 77,
      x: -8880660,
      y: -6353040
    },
    {
      nanogenreId: 78,
      x: -5083050,
      y: -13948260
    },
    {
      nanogenreId: 79,
      x: 13061430,
      y: 6603330
    },
    {
      nanogenreId: 80,
      x: -19945950,
      y: 4183860
    },
    {
      nanogenreId: 81,
      x: -16414080,
      y: -787950
    },
    {
      nanogenreId: 82,
      x: -2268060,
      y: 14090400
    },
    {
      nanogenreId: 83,
      x: 8302830,
      y: -12007740
    },
    {
      nanogenreId: 84,
      x: 6813450,
      y: 10512180
    },
    {
      nanogenreId: 85,
      x: 6278880,
      y: 12057180
    },
    {
      nanogenreId: 86,
      x: -11198160,
      y: -710700
    },
    {
      nanogenreId: 87,
      x: 8546940,
      y: -12764790
    },
    {
      nanogenreId: 88,
      x: -2027040,
      y: -12533040
    },
    {
      nanogenreId: 89,
      x: 10104300,
      y: -9075330
    },
    {
      nanogenreId: 90,
      x: -15045210,
      y: -7270770
    },
    {
      nanogenreId: 91,
      x: 17399790,
      y: -5951340
    },
    {
      nanogenreId: 92,
      x: 13024350,
      y: 4842030
    },
    {
      nanogenreId: 93,
      x: 7184250,
      y: -10833540
    },
    {
      nanogenreId: 94,
      x: -12301290,
      y: 11732730
    },
    {
      nanogenreId: 95,
      x: -15613770,
      y: -6074940
    },
    {
      nanogenreId: 96,
      x: 13562010,
      y: -9347250
    },
    {
      nanogenreId: 97,
      x: -2756280,
      y: -13957530
    },
    {
      nanogenreId: 98,
      x: -3448440,
      y: -9504840
    },
    {
      nanogenreId: 99,
      x: -13945170,
      y: 2351490
    },
    {
      nanogenreId: 100,
      x: -1303980,
      y: -11009670
    },
    {
      nanogenreId: 101,
      x: 123600,
      y: -14189280
    },
    {
      nanogenreId: 102,
      x: -16071090,
      y: -7369650
    },
    {
      nanogenreId: 103,
      x: 9050610,
      y: -13759770
    },
    {
      nanogenreId: 104,
      x: 16080360,
      y: 7406730
    },
    {
      nanogenreId: 105,
      x: -2388570,
      y: -14680590
    },
    {
      nanogenreId: 106,
      x: 5608350,
      y: 10815000
    },
    {
      nanogenreId: 107,
      x: -14152200,
      y: -4563930
    },
    {
      nanogenreId: 108,
      x: 14903070,
      y: 185400
    },
    {
      nanogenreId: 109,
      x: 16077270,
      y: 8655090
    },
    {
      nanogenreId: 110,
      x: -2691390,
      y: -16386270
    },
    {
      nanogenreId: 111,
      x: 5843190,
      y: 11504070
    },
    {
      nanogenreId: 112,
      x: 349170,
      y: 15193530
    },
    {
      nanogenreId: 113,
      x: -2512170,
      y: -12193140
    },
    {
      nanogenreId: 114,
      x: 17140230,
      y: 14158380
    },
    {
      nanogenreId: 115,
      x: 18898440,
      y: 2861340
    },
    {
      nanogenreId: 116,
      x: -3915030,
      y: -14492100
    },
    {
      nanogenreId: 117,
      x: -6353040,
      y: -825030
    },
    {
      nanogenreId: 118,
      x: 10166100,
      y: -7867140
    },
    {
      nanogenreId: 119,
      x: 12171510,
      y: -10713030
    },
    {
      nanogenreId: 120,
      x: -5942070,
      y: 17807670
    },
    {
      nanogenreId: 121,
      x: -8049450,
      y: 13892640
    },
    {
      nanogenreId: 122,
      x: -9059880,
      y: 5460030
    },
    {
      nanogenreId: 123,
      x: 10283520,
      y: 18932430
    },
    {
      nanogenreId: 124,
      x: 13132500,
      y: 8948640
    },
    {
      nanogenreId: 125,
      x: 6201630,
      y: -13546560
    },
    {
      nanogenreId: 126,
      x: 4508310,
      y: 11229060
    },
    {
      nanogenreId: 127,
      x: -8562390,
      y: -14291250
    },
    {
      nanogenreId: 128,
      x: -1415220,
      y: 11677110
    },
    {
      nanogenreId: 129,
      x: 9486300,
      y: 12360000
    },
    {
      nanogenreId: 130,
      x: 7619940,
      y: -7619940
    },
    {
      nanogenreId: 131,
      x: -852840,
      y: -4236390
    },
    {
      nanogenreId: 132,
      x: 9730410,
      y: -4431060
    },
    {
      nanogenreId: 133,
      x: 13317900,
      y: 4190040
    },
    {
      nanogenreId: 134,
      x: 10694490,
      y: -7687920
    },
    {
      nanogenreId: 135,
      x: 8148330,
      y: -10740840
    },
    {
      nanogenreId: 136,
      x: 6652770,
      y: 10277340
    },
    {
      nanogenreId: 137,
      x: -6489000,
      y: -5518740
    },
    {
      nanogenreId: 138,
      x: 14232540,
      y: 2298960
    },
    {
      nanogenreId: 139,
      x: -15545790,
      y: 540750
    },
    {
      nanogenreId: 140,
      x: 16716900,
      y: 5243730
    },
    {
      nanogenreId: 141,
      x: 5963700,
      y: 11482440
    },
    {
      nanogenreId: 142,
      x: 15261510,
      y: -9739680
    },
    {
      nanogenreId: 143,
      x: -1526460,
      y: -3346470
    },
    {
      nanogenreId: 144,
      x: 10521450,
      y: -9514110
    },
    {
      nanogenreId: 145,
      x: 13076880,
      y: 3269220
    },
    {
      nanogenreId: 146,
      x: 1977600,
      y: -12292020
    },
    {
      nanogenreId: 147,
      x: -6850530,
      y: 8797230
    },
    {
      nanogenreId: 148,
      x: -17189670,
      y: 1103130
    },
    {
      nanogenreId: 149,
      x: -3120900,
      y: 14717670
    },
    {
      nanogenreId: 150,
      x: -14794920,
      y: 1792200
    },
    {
      nanogenreId: 151,
      x: 9653160,
      y: -5803020
    },
    {
      nanogenreId: 152,
      x: 13410600,
      y: 18045600
    },
    {
      nanogenreId: 153,
      x: 10258800,
      y: 8670540
    },
    {
      nanogenreId: 154,
      x: -9115500,
      y: -10703760
    },
    {
      nanogenreId: 155,
      x: -11043660,
      y: -8058720
    },
    {
      nanogenreId: 156,
      x: -9504840,
      y: -17684070
    },
    {
      nanogenreId: 157,
      x: -12372360,
      y: 15230610
    },
    {
      nanogenreId: 158,
      x: -11340300,
      y: -6207810
    },
    {
      nanogenreId: 159,
      x: -2255700,
      y: -11760540
    },
    {
      nanogenreId: 160,
      x: -7508700,
      y: -15354210
    },
    {
      nanogenreId: 161,
      x: 12199320,
      y: 1863270
    },
    {
      nanogenreId: 162,
      x: 6485910,
      y: 11343390
    },
    {
      nanogenreId: 163,
      x: 12499050,
      y: 14006970
    },
    {
      nanogenreId: 164,
      x: 7382010,
      y: 12940920
    },
    {
      nanogenreId: 165,
      x: 710700,
      y: 15521070
    },
    {
      nanogenreId: 166,
      x: 5972970,
      y: 11090010
    },
    {
      nanogenreId: 167,
      x: 1285440,
      y: 10292790
    },
    {
      nanogenreId: 168,
      x: 11476260,
      y: -10654320
    },
    {
      nanogenreId: 169,
      x: -6899970,
      y: -15657030
    },
    {
      nanogenreId: 170,
      x: -10645050,
      y: 17090790
    },
    {
      nanogenreId: 171,
      x: -13775220,
      y: -5327160
    },
    {
      nanogenreId: 172,
      x: 5614530,
      y: 11096190
    },
    {
      nanogenreId: 173,
      x: -12353820,
      y: 14899980
    },
    {
      nanogenreId: 174,
      x: 10870620,
      y: -8009280
    },
    {
      nanogenreId: 175,
      x: 13197390,
      y: 8719980
    },
    {
      nanogenreId: 176,
      x: -13194300,
      y: -8197770
    },
    {
      nanogenreId: 177,
      x: 17446140,
      y: -5481660
    },
    {
      nanogenreId: 178,
      x: -6226350,
      y: 8321370
    },
    {
      nanogenreId: 179,
      x: -15344940,
      y: -9152580
    },
    {
      nanogenreId: 180,
      x: 11127090,
      y: -4193130
    },
    {
      nanogenreId: 181,
      x: -5497110,
      y: -2255700
    },
    {
      nanogenreId: 182,
      x: 3884130,
      y: 12097350
    },
    {
      nanogenreId: 183,
      x: -9507930,
      y: 6628050
    },
    {
      nanogenreId: 184,
      x: 3850140,
      y: 15320220
    },
    {
      nanogenreId: 185,
      x: -16021650,
      y: -407880
    },
    {
      nanogenreId: 186,
      x: 10027050,
      y: 11680200
    },
    {
      nanogenreId: 187,
      x: 9585180,
      y: -2422560
    },
    {
      nanogenreId: 188,
      x: 9918900,
      y: -7545780
    },
    {
      nanogenreId: 189,
      x: -9421410,
      y: 7839330
    },
    {
      nanogenreId: 190,
      x: 7227510,
      y: 10583250
    },
    {
      nanogenreId: 191,
      x: 11748180,
      y: -11059110
    },
    {
      nanogenreId: 192,
      x: 14257260,
      y: 6903060
    },
    {
      nanogenreId: 193,
      x: -9752040,
      y: 9056790
    },
    {
      nanogenreId: 194,
      x: 8129790,
      y: 9180390
    },
    {
      nanogenreId: 195,
      x: 6964860,
      y: 11479350
    },
    {
      nanogenreId: 196,
      x: -3828510,
      y: -14421030
    },
    {
      nanogenreId: 197,
      x: -4205490,
      y: 13976070
    },
    {
      nanogenreId: 198,
      x: -8741610,
      y: -13234470
    },
    {
      nanogenreId: 199,
      x: -1875630,
      y: -14550810
    },
    {
      nanogenreId: 200,
      x: -642720,
      y: -11921220
    },
    {
      nanogenreId: 201,
      x: -1616070,
      y: -14742390
    },
    {
      nanogenreId: 202,
      x: 8516040,
      y: -6578610
    },
    {
      nanogenreId: 203,
      x: -1359600,
      y: -17520300
    },
    {
      nanogenreId: 204,
      x: 12635010,
      y: -3636930
    },
    {
      nanogenreId: 205,
      x: 1103130,
      y: 7860960
    },
    {
      nanogenreId: 206,
      x: 8661270,
      y: -10268070
    },
    {
      nanogenreId: 207,
      x: 13917360,
      y: 7227510
    },
    {
      nanogenreId: 208,
      x: 9625350,
      y: -8370810
    },
    {
      nanogenreId: 209,
      x: 6748560,
      y: -13979160
    },
    {
      nanogenreId: 210,
      x: -281190,
      y: -8976450
    },
    {
      nanogenreId: 211,
      x: -2870610,
      y: 11979930
    },
    {
      nanogenreId: 212,
      x: 19606050,
      y: 74160
    },
    {
      nanogenreId: 213,
      x: 10011600,
      y: -9974520
    },
    {
      nanogenreId: 214,
      x: -7762080,
      y: 1937430
    },
    {
      nanogenreId: 215,
      x: 19018950,
      y: -2771730
    },
    {
      nanogenreId: 216,
      x: -13463130,
      y: -6782550
    },
    {
      nanogenreId: 217,
      x: 10852080,
      y: -9183480
    },
    {
      nanogenreId: 218,
      x: 6405570,
      y: 11460810
    },
    {
      nanogenreId: 219,
      x: 8608740,
      y: 12066450
    },
    {
      nanogenreId: 220,
      x: -14266530,
      y: -1718040
    },
    {
      nanogenreId: 221,
      x: 9341070,
      y: 12965640
    },
    {
      nanogenreId: 222,
      x: -17134050,
      y: -4965630
    },
    {
      nanogenreId: 223,
      x: 9387420,
      y: 17056800
    },
    {
      nanogenreId: 224,
      x: -2187720,
      y: -10691400
    },
    {
      nanogenreId: 225,
      x: 7317120,
      y: -8395530
    },
    {
      nanogenreId: 226,
      x: 6853620,
      y: 9371970
    },
    {
      nanogenreId: 227,
      x: -9637710,
      y: -11204340
    },
    {
      nanogenreId: 228,
      x: -16018560,
      y: 5651610
    },
    {
      nanogenreId: 229,
      x: -3006570,
      y: -11519520
    },
    {
      nanogenreId: 230,
      x: 16973370,
      y: 14164560
    },
    {
      nanogenreId: 231,
      x: 7684830,
      y: -9659340
    },
    {
      nanogenreId: 232,
      x: 8670540,
      y: -11933580
    },
    {
      nanogenreId: 233,
      x: -5444580,
      y: 10039410
    },
    {
      nanogenreId: 234,
      x: 12687540,
      y: 3708000
    },
    {
      nanogenreId: 235,
      x: 11572050,
      y: -8222490
    },
    {
      nanogenreId: 236,
      x: 6433380,
      y: -9619170
    },
    {
      nanogenreId: 237,
      x: -15326400,
      y: -6677490
    },
    {
      nanogenreId: 238,
      x: 16417170,
      y: -5966790
    },
    {
      nanogenreId: 239,
      x: 15805350,
      y: 3185790
    },
    {
      nanogenreId: 240,
      x: 1770570,
      y: -10799550
    },
    {
      nanogenreId: 241,
      x: 18978780,
      y: -7885680
    },
    {
      nanogenreId: 242,
      x: 9934350,
      y: -9477030
    },
    {
      nanogenreId: 243,
      x: 11306310,
      y: -4650450
    },
    {
      nanogenreId: 244,
      x: -15950580,
      y: -5682510
    },
    {
      nanogenreId: 245,
      x: -2438010,
      y: -10428750
    },
    {
      nanogenreId: 246,
      x: -4786410,
      y: -14223270
    },
    {
      nanogenreId: 247,
      x: -5784480,
      y: 6337590
    },
    {
      nanogenreId: 248,
      x: -17294730,
      y: -176130
    },
    {
      nanogenreId: 249,
      x: 8404800,
      y: -9291630
    },
    {
      nanogenreId: 250,
      x: 6059490,
      y: 8370810
    },
    {
      nanogenreId: 251,
      x: 10577070,
      y: -10172280
    },
    {
      nanogenreId: 252,
      x: 4063350,
      y: -14062590
    },
    {
      nanogenreId: 253,
      x: -685980,
      y: 11531880
    },
    {
      nanogenreId: 254,
      x: 10864440,
      y: 5883360
    },
    {
      nanogenreId: 255,
      x: -5926620,
      y: 10129020
    },
    {
      nanogenreId: 256,
      x: 17616090,
      y: -380070
    },
    {
      nanogenreId: 257,
      x: -16200870,
      y: -7060650
    },
    {
      nanogenreId: 258,
      x: -4335270,
      y: -16389360
    },
    {
      nanogenreId: 259,
      x: 3655470,
      y: 12678270
    },
    {
      nanogenreId: 260,
      x: 8040180,
      y: 5324070
    },
    {
      nanogenreId: 261,
      x: -9446130,
      y: -18209370
    },
    {
      nanogenreId: 262,
      x: -9711870,
      y: -3887220
    },
    {
      nanogenreId: 263,
      x: 8614920,
      y: 9921990
    },
    {
      nanogenreId: 264,
      x: 10419480,
      y: -9993060
    },
    {
      nanogenreId: 265,
      x: 7409820,
      y: 11442270
    },
    {
      nanogenreId: 266,
      x: 1353420,
      y: -2431830
    },
    {
      nanogenreId: 267,
      x: -5033610,
      y: 6912330
    },
    {
      nanogenreId: 268,
      x: 16988820,
      y: 3538050
    },
    {
      nanogenreId: 269,
      x: -9402870,
      y: 4202400
    },
    {
      nanogenreId: 270,
      x: -16951740,
      y: -4118970
    },
    {
      nanogenreId: 271,
      x: 9208200,
      y: -11967570
    },
    {
      nanogenreId: 272,
      x: 10632690,
      y: -8404800
    },
    {
      nanogenreId: 273,
      x: -15174990,
      y: -5765940
    },
    {
      nanogenreId: 274,
      x: -3154890,
      y: -13348800
    },
    {
      nanogenreId: 275,
      x: -3825420,
      y: -1569720
    },
    {
      nanogenreId: 276,
      x: 1866360,
      y: -6671310
    },
    {
      nanogenreId: 277,
      x: 6828900,
      y: 2326770
    },
    {
      nanogenreId: 278,
      x: 5840100,
      y: -14442660
    },
    {
      nanogenreId: 279,
      x: 18743940,
      y: -7088460
    },
    {
      nanogenreId: 280,
      x: 11531880,
      y: -14489010
    },
    {
      nanogenreId: 281,
      x: -14164560,
      y: 12514500
    },
    {
      nanogenreId: 282,
      x: -8676720,
      y: -16132890
    },
    {
      nanogenreId: 283,
      x: 14396310,
      y: 5305530
    },
    {
      nanogenreId: 284,
      x: 7978380,
      y: -886830
    },
    {
      nanogenreId: 285,
      x: 2907690,
      y: 10601790
    },
    {
      nanogenreId: 286,
      x: 5154120,
      y: 12947100
    },
    {
      nanogenreId: 287,
      x: 9489390,
      y: -6084210
    },
    {
      nanogenreId: 288,
      x: -14226360,
      y: 2252610
    },
    {
      nanogenreId: 289,
      x: 3803790,
      y: 12452700
    },
    {
      nanogenreId: 290,
      x: 17325630,
      y: 633450
    },
    {
      nanogenreId: 291,
      x: 2657400,
      y: 10771740
    },
    {
      nanogenreId: 292,
      x: 1690230,
      y: -18895350
    },
    {
      nanogenreId: 293,
      x: -14377770,
      y: -6662040
    },
    {
      nanogenreId: 294,
      x: -15363480,
      y: 2830440
    },
    {
      nanogenreId: 295,
      x: 12341460,
      y: -10530720
    },
    {
      nanogenreId: 296,
      x: -6541530,
      y: -580920
    },
    {
      nanogenreId: 297,
      x: -13957530,
      y: 10258800
    },
    {
      nanogenreId: 298,
      x: -7276950,
      y: 12273480
    },
    {
      nanogenreId: 299,
      x: 9242190,
      y: -9625350
    },
    {
      nanogenreId: 300,
      x: 6281970,
      y: -11043660
    },
    {
      nanogenreId: 301,
      x: 9180390,
      y: -8757060
    },
    {
      nanogenreId: 302,
      x: 5144850,
      y: 11028210
    },
    {
      nanogenreId: 303,
      x: -435690,
      y: -9857100
    },
    {
      nanogenreId: 304,
      x: -2963310,
      y: 13367340
    },
    {
      nanogenreId: 305,
      x: 15783720,
      y: 2848980
    },
    {
      nanogenreId: 306,
      x: 4424880,
      y: 11114730
    },
    {
      nanogenreId: 307,
      x: -10147560,
      y: 3096180
    },
    {
      nanogenreId: 308,
      x: 1137120,
      y: -11145630
    },
    {
      nanogenreId: 309,
      x: -10558530,
      y: 2453460
    },
    {
      nanogenreId: 310,
      x: 423330,
      y: 10855170
    },
    {
      nanogenreId: 311,
      x: 10790280,
      y: -2354580
    },
    {
      nanogenreId: 312,
      x: -8859030,
      y: -8855940
    },
    {
      nanogenreId: 313,
      x: 7576680,
      y: 11321760
    },
    {
      nanogenreId: 314,
      x: -11692560,
      y: 6374670
    },
    {
      nanogenreId: 315,
      x: 3182700,
      y: -13812300
    },
    {
      nanogenreId: 316,
      x: -6421020,
      y: 7669380
    },
    {
      nanogenreId: 317,
      x: 5120130,
      y: 11454630
    },
    {
      nanogenreId: 318,
      x: -7585950,
      y: -11658570
    },
    {
      nanogenreId: 319,
      x: 8383170,
      y: -11022030
    },
    {
      nanogenreId: 320,
      x: -4925460,
      y: 11989200
    },
    {
      nanogenreId: 321,
      x: 8420250,
      y: 11173440
    },
    {
      nanogenreId: 322,
      x: 13435320,
      y: 383160
    },
    {
      nanogenreId: 323,
      x: -8401710,
      y: 8642730
    },
    {
      nanogenreId: 324,
      x: -11278500,
      y: -12638100
    },
    {
      nanogenreId: 325,
      x: -12480510,
      y: 10413300
    },
    {
      nanogenreId: 326,
      x: -12273480,
      y: 846660
    },
    {
      nanogenreId: 327,
      x: 3677100,
      y: 11281590
    },
    {
      nanogenreId: 328,
      x: 4758600,
      y: 12424890
    },
    {
      nanogenreId: 329,
      x: 482040,
      y: -9115500
    },
    {
      nanogenreId: 330,
      x: 7499430,
      y: -13302450
    },
    {
      nanogenreId: 331,
      x: -3439170,
      y: -6099660
    },
    {
      nanogenreId: 332,
      x: 17066070,
      y: 14726940
    },
    {
      nanogenreId: 333,
      x: 16491330,
      y: 6872160
    },
    {
      nanogenreId: 334,
      x: 10020870,
      y: -8512950
    },
    {
      nanogenreId: 335,
      x: -7879500,
      y: -10502910
    },
    {
      nanogenreId: 336,
      x: -6906150,
      y: -5799930
    },
    {
      nanogenreId: 337,
      x: 3627660,
      y: 10404030
    },
    {
      nanogenreId: 338,
      x: 2410200,
      y: -9597540
    },
    {
      nanogenreId: 339,
      x: 8160690,
      y: 8605650
    },
    {
      nanogenreId: 340,
      x: -6260340,
      y: 4131330
    },
    {
      nanogenreId: 341,
      x: -2882970,
      y: -14081130
    },
    {
      nanogenreId: 342,
      x: -16003110,
      y: 3847050
    },
    {
      nanogenreId: 343,
      x: 13435320,
      y: 6275790
    },
    {
      nanogenreId: 344,
      x: -4684440,
      y: -9566640
    },
    {
      nanogenreId: 345,
      x: -13200480,
      y: 2119740
    },
    {
      nanogenreId: 346,
      x: -5385870,
      y: -3806880
    },
    {
      nanogenreId: 347,
      x: 429510,
      y: 13809210
    },
    {
      nanogenreId: 348,
      x: -9832380,
      y: 14924700
    },
    {
      nanogenreId: 349,
      x: -15103920,
      y: -3865590
    },
    {
      nanogenreId: 350,
      x: 11062200,
      y: -9594450
    },
    {
      nanogenreId: 351,
      x: -2808810,
      y: -12545400
    },
    {
      nanogenreId: 352,
      x: 2422560,
      y: 14288160
    },
    {
      nanogenreId: 353,
      x: -7283130,
      y: 4906920
    },
    {
      nanogenreId: 354,
      x: 14705310,
      y: -4879110
    },
    {
      nanogenreId: 355,
      x: -15468540,
      y: -4505220
    },
    {
      nanogenreId: 356,
      x: 11235240,
      y: -9322530
    },
    {
      nanogenreId: 357,
      x: -14396310,
      y: -8698350
    },
    {
      nanogenreId: 358,
      x: -9282360,
      y: -4026270
    },
    {
      nanogenreId: 359,
      x: -1127850,
      y: -13354980
    },
    {
      nanogenreId: 360,
      x: 6600240,
      y: 6563160
    },
    {
      nanogenreId: 361,
      x: 2234070,
      y: 6142920
    },
    {
      nanogenreId: 362,
      x: 8309010,
      y: 19615320
    },
    {
      nanogenreId: 363,
      x: -11120910,
      y: 16163790
    },
    {
      nanogenreId: 364,
      x: -10796460,
      y: 7675560
    },
    {
      nanogenreId: 365,
      x: -435690,
      y: -16064910
    },
    {
      nanogenreId: 366,
      x: 614910,
      y: 12981090
    },
    {
      nanogenreId: 367,
      x: -6606420,
      y: -12582480
    },
    {
      nanogenreId: 368,
      x: 5500200,
      y: 12103530
    },
    {
      nanogenreId: 369,
      x: 6439560,
      y: -8954820
    },
    {
      nanogenreId: 370,
      x: -11399010,
      y: -12026280
    },
    {
      nanogenreId: 371,
      x: 5679420,
      y: 11161080
    },
    {
      nanogenreId: 372,
      x: -10360770,
      y: -9273090
    },
    {
      nanogenreId: 373,
      x: 11575140,
      y: -10020870
    },
    {
      nanogenreId: 374,
      x: -15450,
      y: 13290090
    },
    {
      nanogenreId: 375,
      x: 12180780,
      y: 8704530
    },
    {
      nanogenreId: 376,
      x: -9912720,
      y: 8923920
    },
    {
      nanogenreId: 377,
      x: -2302050,
      y: 13144860
    },
    {
      nanogenreId: 378,
      x: 923910,
      y: 9035160
    },
    {
      nanogenreId: 379,
      x: -17310180,
      y: -2070300
    },
    {
      nanogenreId: 380,
      x: 9838560,
      y: -10966410
    },
    {
      nanogenreId: 381,
      x: -7428360,
      y: 16444980
    },
    {
      nanogenreId: 382,
      x: 8148330,
      y: 6492090
    },
    {
      nanogenreId: 383,
      x: -5531100,
      y: 14510640
    },
    {
      nanogenreId: 384,
      x: -7249140,
      y: 8101980
    },
    {
      nanogenreId: 385,
      x: 10546170,
      y: -8868300
    },
    {
      nanogenreId: 386,
      x: 9171120,
      y: -10008510
    },
    {
      nanogenreId: 387,
      x: 6915420,
      y: 11674020
    },
    {
      nanogenreId: 388,
      x: -6281970,
      y: 9112410
    },
    {
      nanogenreId: 389,
      x: -10175370,
      y: 9980700
    },
    {
      nanogenreId: 390,
      x: -10793370,
      y: -6575520
    },
    {
      nanogenreId: 391,
      x: 2991120,
      y: 664350
    },
    {
      nanogenreId: 392,
      x: 18944790,
      y: 2425650
    },
    {
      nanogenreId: 393,
      x: -7530330,
      y: 14467380
    },
    {
      nanogenreId: 394,
      x: -14801100,
      y: -9684060
    },
    {
      nanogenreId: 395,
      x: -6615690,
      y: 3090
    },
    {
      nanogenreId: 396,
      x: 16648920,
      y: -4193130
    },
    {
      nanogenreId: 397,
      x: -1980690,
      y: -12010830
    },
    {
      nanogenreId: 398,
      x: 9316350,
      y: 123600
    },
    {
      nanogenreId: 399,
      x: -2008500,
      y: -10332960
    },
    {
      nanogenreId: 400,
      x: -3850140,
      y: 9903450
    },
    {
      nanogenreId: 401,
      x: 5512560,
      y: 10848990
    },
    {
      nanogenreId: 402,
      x: -10249530,
      y: 5991510
    },
    {
      nanogenreId: 403,
      x: 7502520,
      y: -2852070
    },
    {
      nanogenreId: 404,
      x: -4032450,
      y: -12696810
    },
    {
      nanogenreId: 405,
      x: 10781010,
      y: -7854780
    },
    {
      nanogenreId: 406,
      x: -3479340,
      y: -15487080
    },
    {
      nanogenreId: 407,
      x: 17501760,
      y: 10855170
    },
    {
      nanogenreId: 408,
      x: -12789510,
      y: 3658560
    },
    {
      nanogenreId: 409,
      x: -14952510,
      y: -6624960
    },
    {
      nanogenreId: 410,
      x: 9130950,
      y: -8197770
    },
    {
      nanogenreId: 411,
      x: 9084600,
      y: -6340680
    },
    {
      nanogenreId: 412,
      x: 676710,
      y: 16831230
    },
    {
      nanogenreId: 413,
      x: -3748170,
      y: -460410
    },
    {
      nanogenreId: 414,
      x: -13024350,
      y: -1993050
    },
    {
      nanogenreId: 415,
      x: 11272320,
      y: 3843960
    },
    {
      nanogenreId: 416,
      x: 8939370,
      y: -1483200
    },
    {
      nanogenreId: 417,
      x: -512940,
      y: 11541150
    },
    {
      nanogenreId: 418,
      x: 2882970,
      y: -9198930
    },
    {
      nanogenreId: 419,
      x: 14383950,
      y: 7898040
    },
    {
      nanogenreId: 420,
      x: -19433010,
      y: 4165320
    },
    {
      nanogenreId: 421,
      x: -1854000,
      y: -9612990
    },
    {
      nanogenreId: 422,
      x: 11374290,
      y: -10246440
    },
    {
      nanogenreId: 423,
      x: -2536890,
      y: 10178460
    },
    {
      nanogenreId: 424,
      x: 5948250,
      y: 11170350
    },
    {
      nanogenreId: 425,
      x: -10805730,
      y: 5883360
    },
    {
      nanogenreId: 426,
      x: 8973360,
      y: -259560
    },
    {
      nanogenreId: 427,
      x: -7258410,
      y: -9977610
    },
    {
      nanogenreId: 428,
      x: 6658950,
      y: 10370040
    },
    {
      nanogenreId: 429,
      x: 7314030,
      y: 9801480
    },
    {
      nanogenreId: 430,
      x: 744690,
      y: -5843190
    },
    {
      nanogenreId: 431,
      x: 6312870,
      y: 5185020
    },
    {
      nanogenreId: 432,
      x: -8278110,
      y: 4987260
    },
    {
      nanogenreId: 433,
      x: 9548100,
      y: -13240650
    },
    {
      nanogenreId: 434,
      x: 11309400,
      y: -9217470
    },
    {
      nanogenreId: 435,
      x: -10842810,
      y: 5636160
    },
    {
      nanogenreId: 436,
      x: -7338750,
      y: -3714180
    },
    {
      nanogenreId: 437,
      x: 8197770,
      y: -10113570
    },
    {
      nanogenreId: 438,
      x: 10271160,
      y: -6643500
    },
    {
      nanogenreId: 439,
      x: 5682510,
      y: 10308240
    },
    {
      nanogenreId: 440,
      x: -1850910,
      y: -10991130
    },
    {
      nanogenreId: 441,
      x: 4245660,
      y: 10425660
    },
    {
      nanogenreId: 442,
      x: -4010820,
      y: -14584800
    },
    {
      nanogenreId: 443,
      x: 6878340,
      y: 19766730
    },
    {
      nanogenreId: 444,
      x: 9996150,
      y: 12879120
    },
    {
      nanogenreId: 445,
      x: 17124780,
      y: 2802630
    },
    {
      nanogenreId: 446,
      x: -15604500,
      y: -2434920
    },
    {
      nanogenreId: 447,
      x: -12356910,
      y: 8061810
    },
    {
      nanogenreId: 448,
      x: -10277340,
      y: 8599470
    },
    {
      nanogenreId: 449,
      x: -3263040,
      y: 8723070
    },
    {
      nanogenreId: 450,
      x: 4672080,
      y: 13249920
    },
    {
      nanogenreId: 451,
      x: 14356140,
      y: 5543460
    },
    {
      nanogenreId: 452,
      x: -5132490,
      y: 6628050
    },
    {
      nanogenreId: 453,
      x: 11952120,
      y: -5348790
    },
    {
      nanogenreId: 454,
      x: -3083820,
      y: 8343000
    },
    {
      nanogenreId: 455,
      x: 10382400,
      y: -9035160
    },
    {
      nanogenreId: 456,
      x: -4044810,
      y: 7978380
    },
    {
      nanogenreId: 457,
      x: 10271160,
      y: -10268070
    },
    {
      nanogenreId: 458,
      x: 18125940,
      y: 3361920
    },
    {
      nanogenreId: 459,
      x: -13979160,
      y: 942450
    },
    {
      nanogenreId: 460,
      x: 5330250,
      y: 11312490
    },
    {
      nanogenreId: 461,
      x: -1857090,
      y: -9881820
    },
    {
      nanogenreId: 462,
      x: -13942080,
      y: -8482050
    },
    {
      nanogenreId: 463,
      x: 9931260,
      y: -6510630
    },
    {
      nanogenreId: 464,
      x: 2428740,
      y: -16806510
    },
    {
      nanogenreId: 465,
      x: -6652770,
      y: -14563170
    },
    {
      nanogenreId: 466,
      x: 5914260,
      y: 11334120
    },
    {
      nanogenreId: 467,
      x: 2079570,
      y: 11068380
    },
    {
      nanogenreId: 468,
      x: -4285830,
      y: -6167640
    },
    {
      nanogenreId: 469,
      x: -8584020,
      y: 7675560
    },
    {
      nanogenreId: 470,
      x: -7326390,
      y: -9628440
    },
    {
      nanogenreId: 471,
      x: -543840,
      y: -12335280
    },
    {
      nanogenreId: 472,
      x: 3905760,
      y: -8556210
    },
    {
      nanogenreId: 473,
      x: 12563940,
      y: -5759760
    },
    {
      nanogenreId: 474,
      x: 7888770,
      y: 11127090
    },
    {
      nanogenreId: 475,
      x: 11402100,
      y: -4984170
    },
    {
      nanogenreId: 476,
      x: -9356520,
      y: -14541540
    },
    {
      nanogenreId: 477,
      x: -11083830,
      y: 15085380
    },
    {
      nanogenreId: 478,
      x: 7073010,
      y: 10966410
    },
    {
      nanogenreId: 479,
      x: 3945930,
      y: -7678650
    },
    {
      nanogenreId: 480,
      x: -1186560,
      y: 12900750
    },
    {
      nanogenreId: 481,
      x: -11590590,
      y: -13738140
    },
    {
      nanogenreId: 482,
      x: 8009280,
      y: -5487840
    },
    {
      nanogenreId: 483,
      x: -3111630,
      y: -11405190
    },
    {
      nanogenreId: 484,
      x: 10947870,
      y: -8939370
    },
    {
      nanogenreId: 485,
      x: 17823120,
      y: 3769800
    },
    {
      nanogenreId: 486,
      x: -12301290,
      y: -3071460
    },
    {
      nanogenreId: 487,
      x: -9745860,
      y: 3504060
    },
    {
      nanogenreId: 488,
      x: 8741610,
      y: 9436860
    },
    {
      nanogenreId: 489,
      x: 6442650,
      y: 10302060
    },
    {
      nanogenreId: 490,
      x: 12557760,
      y: -5419860
    },
    {
      nanogenreId: 491,
      x: -11238330,
      y: -4081890
    },
    {
      nanogenreId: 492,
      x: 2524530,
      y: -7811520
    },
    {
      nanogenreId: 493,
      x: -1168020,
      y: 16550040
    },
    {
      nanogenreId: 494,
      x: 3664740,
      y: 11436090
    },
    {
      nanogenreId: 495,
      x: -1458480,
      y: -14621880
    },
    {
      nanogenreId: 496,
      x: 4761690,
      y: -15592140
    },
    {
      nanogenreId: 497,
      x: -2734650,
      y: 5305530
    },
    {
      nanogenreId: 498,
      x: -4690620,
      y: 6461190
    },
    {
      nanogenreId: 499,
      x: 10379310,
      y: -6087300
    },
    {
      nanogenreId: 500,
      x: 14736210,
      y: -4190040
    },
    {
      nanogenreId: 501,
      x: 13886460,
      y: 528390
    },
    {
      nanogenreId: 502,
      x: 16339920,
      y: 1767480
    },
    {
      nanogenreId: 503,
      x: 6022410,
      y: 7919670
    },
    {
      nanogenreId: 504,
      x: 10620330,
      y: -10777920
    },
    {
      nanogenreId: 505,
      x: 2669760,
      y: 11012760
    },
    {
      nanogenreId: 506,
      x: -5160300,
      y: -10697580
    },
    {
      nanogenreId: 507,
      x: 8775600,
      y: -17118600
    },
    {
      nanogenreId: 508,
      x: 8466600,
      y: -10598700
    },
    {
      nanogenreId: 509,
      x: -3432990,
      y: 4854390
    },
    {
      nanogenreId: 510,
      x: 12533040,
      y: -2753190
    },
    {
      nanogenreId: 511,
      x: 5880270,
      y: 10904610
    },
    {
      nanogenreId: 512,
      x: 1606800,
      y: -10512180
    },
    {
      nanogenreId: 513,
      x: 10005420,
      y: -3602940
    },
    {
      nanogenreId: 514,
      x: 13583640,
      y: -6519900
    },
    {
      nanogenreId: 515,
      x: 7660110,
      y: 11198160
    },
    {
      nanogenreId: 516,
      x: 769410,
      y: -15731190
    },
    {
      nanogenreId: 517,
      x: -3556590,
      y: -13818480
    },
    {
      nanogenreId: 518,
      x: 9396690,
      y: -7496340
    },
    {
      nanogenreId: 519,
      x: 6387030,
      y: 13253010
    },
    {
      nanogenreId: 520,
      x: -14380860,
      y: -7375830
    },
    {
      nanogenreId: 521,
      x: 6609510,
      y: 11093100
    },
    {
      nanogenreId: 522,
      x: -312090,
      y: 9467760
    },
    {
      nanogenreId: 523,
      x: 676710,
      y: -8553120
    },
    {
      nanogenreId: 524,
      x: -13586730,
      y: -2821170
    },
    {
      nanogenreId: 525,
      x: 9415230,
      y: 16108170
    },
    {
      nanogenreId: 526,
      x: 14760930,
      y: 9399780
    },
    {
      nanogenreId: 527,
      x: 9168030,
      y: -8914650
    },
    {
      nanogenreId: 528,
      x: -15792990,
      y: 3105450
    },
    {
      nanogenreId: 529,
      x: 12369270,
      y: 9813840
    },
    {
      nanogenreId: 530,
      x: -4298190,
      y: 14149110
    },
    {
      nanogenreId: 531,
      x: 10153740,
      y: 15085380
    },
    {
      nanogenreId: 532,
      x: -5178840,
      y: 1263810
    },
    {
      nanogenreId: 533,
      x: 12520680,
      y: 1909620
    },
    {
      nanogenreId: 534,
      x: 6254160,
      y: -11738910
    },
    {
      nanogenreId: 535,
      x: -10289700,
      y: -1718040
    },
    {
      nanogenreId: 536,
      x: 6081120,
      y: 10762470
    },
    {
      nanogenreId: 537,
      x: -6757830,
      y: -9124770
    },
    {
      nanogenreId: 538,
      x: -3300120,
      y: 6600240
    },
    {
      nanogenreId: 539,
      x: -12220950,
      y: -12292020
    },
    {
      nanogenreId: 540,
      x: 5827740,
      y: 4721520
    },
    {
      nanogenreId: 541,
      x: -8540760,
      y: 5237550
    },
    {
      nanogenreId: 542,
      x: -4761690,
      y: 12446520
    },
    {
      nanogenreId: 543,
      x: 16219410,
      y: 8865210
    },
    {
      nanogenreId: 544,
      x: 4940910,
      y: 6850530
    },
    {
      nanogenreId: 545,
      x: 3955200,
      y: 12656640
    },
    {
      nanogenreId: 546,
      x: 11170350,
      y: -9461580
    },
    {
      nanogenreId: 547,
      x: 4777140,
      y: 11562780
    },
    {
      nanogenreId: 548,
      x: -4712250,
      y: 9844740
    },
    {
      nanogenreId: 549,
      x: 5982240,
      y: 10654320
    },
    {
      nanogenreId: 550,
      x: -16596390,
      y: 395520
    },
    {
      nanogenreId: 551,
      x: 18997320,
      y: 1183470
    },
    {
      nanogenreId: 552,
      x: 16621110,
      y: 225570
    },
    {
      nanogenreId: 553,
      x: 15326400,
      y: -4523760
    },
    {
      nanogenreId: 554,
      x: 5141760,
      y: 12017010
    },
    {
      nanogenreId: 555,
      x: -12885300,
      y: -80340
    },
    {
      nanogenreId: 556,
      x: 9467760,
      y: -5580540
    },
    {
      nanogenreId: 557,
      x: 4310550,
      y: 10082670
    },
    {
      nanogenreId: 558,
      x: -6269610,
      y: -12236400
    },
    {
      nanogenreId: 559,
      x: 8930100,
      y: -12282750
    },
    {
      nanogenreId: 560,
      x: -5256090,
      y: -12437250
    },
    {
      nanogenreId: 561,
      x: -3151800,
      y: -14065680
    },
    {
      nanogenreId: 562,
      x: 9965250,
      y: -7073010
    },
    {
      nanogenreId: 563,
      x: 7103910,
      y: 4363080
    },
    {
      nanogenreId: 564,
      x: -15264600,
      y: -7669380
    },
    {
      nanogenreId: 565,
      x: 9263820,
      y: -6124380
    },
    {
      nanogenreId: 566,
      x: 8744700,
      y: -9316350
    },
    {
      nanogenreId: 567,
      x: -9409050,
      y: 8750880
    },
    {
      nanogenreId: 568,
      x: 16151430,
      y: -4322910
    },
    {
      nanogenreId: 569,
      x: -2944770,
      y: -14226360
    },
    {
      nanogenreId: 570,
      x: -4968720,
      y: 407880
    },
    {
      nanogenreId: 571,
      x: 8957910,
      y: -7752810
    },
    {
      nanogenreId: 572,
      x: 18540000,
      y: -716880
    },
    {
      nanogenreId: 573,
      x: 2357670,
      y: -930090
    },
    {
      nanogenreId: 574,
      x: 9677880,
      y: -9032070
    },
    {
      nanogenreId: 575,
      x: -2768640,
      y: -11816160
    },
    {
      nanogenreId: 576,
      x: -9220560,
      y: -11884140
    },
    {
      nanogenreId: 577,
      x: 139050,
      y: -4888380
    },
    {
      nanogenreId: 578,
      x: 3915030,
      y: 10091940
    },
    {
      nanogenreId: 579,
      x: -8843580,
      y: 17059890
    },
    {
      nanogenreId: 580,
      x: -3034380,
      y: -12570120
    },
    {
      nanogenreId: 581,
      x: 6019320,
      y: -7199700
    },
    {
      nanogenreId: 582,
      x: 9906540,
      y: 3899580
    },
    {
      nanogenreId: 583,
      x: 3365010,
      y: 10512180
    },
    {
      nanogenreId: 584,
      x: -10518360,
      y: 3834690
    },
    {
      nanogenreId: 585,
      x: -710700,
      y: 15258420
    },
    {
      nanogenreId: 586,
      x: 1473930,
      y: 11167260
    },
    {
      nanogenreId: 587,
      x: 6124380,
      y: 10994220
    },
    {
      nanogenreId: 588,
      x: 7456170,
      y: -12217860
    },
    {
      nanogenreId: 589,
      x: -13929720,
      y: -74160
    },
    {
      nanogenreId: 590,
      x: -12848220,
      y: -10221720
    },
    {
      nanogenreId: 591,
      x: 2271150,
      y: -5710320
    },
    {
      nanogenreId: 592,
      x: 7966020,
      y: -10824270
    },
    {
      nanogenreId: 593,
      x: 5265360,
      y: 9789120
    },
    {
      nanogenreId: 594,
      x: 9100050,
      y: -4804950
    },
    {
      nanogenreId: 595,
      x: 4517580,
      y: 8608740
    },
    {
      nanogenreId: 596,
      x: 8803410,
      y: 3565860
    },
    {
      nanogenreId: 597,
      x: -6563160,
      y: 6158370
    },
    {
      nanogenreId: 598,
      x: -7181160,
      y: 10005420
    },
    {
      nanogenreId: 599,
      x: 4335270,
      y: -13113960
    },
    {
      nanogenreId: 600,
      x: -2759370,
      y: -4699890
    },
    {
      nanogenreId: 601,
      x: -4193130,
      y: -831210
    },
    {
      nanogenreId: 602,
      x: -4733880,
      y: -1755120
    },
    {
      nanogenreId: 603,
      x: 9934350,
      y: -10490550
    },
    {
      nanogenreId: 604,
      x: 3995370,
      y: -14807280
    },
    {
      nanogenreId: 605,
      x: 4607190,
      y: 11170350
    },
    {
      nanogenreId: 606,
      x: -4869840,
      y: 7196610
    },
    {
      nanogenreId: 607,
      x: -15159540,
      y: -1449210
    },
    {
      nanogenreId: 608,
      x: 3451530,
      y: -9198930
    },
    {
      nanogenreId: 609,
      x: 6865980,
      y: -13769040
    },
    {
      nanogenreId: 610,
      x: 6479730,
      y: 12465060
    },
    {
      nanogenreId: 611,
      x: 8719980,
      y: 19547340
    },
    {
      nanogenreId: 612,
      x: 7644660,
      y: 4826580
    },
    {
      nanogenreId: 613,
      x: -2722290,
      y: -7996920
    },
    {
      nanogenreId: 614,
      x: 16343010,
      y: 1288530
    },
    {
      nanogenreId: 615,
      x: -6399390,
      y: -12434160
    },
    {
      nanogenreId: 616,
      x: 7338750,
      y: -9350340
    },
    {
      nanogenreId: 617,
      x: 2119740,
      y: 11689470
    },
    {
      nanogenreId: 618,
      x: 6878340,
      y: 10957140
    },
    {
      nanogenreId: 619,
      x: -5055240,
      y: -5299350
    },
    {
      nanogenreId: 620,
      x: -10740840,
      y: -13905000
    },
    {
      nanogenreId: 621,
      x: -6640410,
      y: -9606810
    },
    {
      nanogenreId: 622,
      x: 3368100,
      y: 12406350
    },
    {
      nanogenreId: 623,
      x: 1495560,
      y: -14195460
    },
    {
      nanogenreId: 624,
      x: -200850,
      y: 11553510
    },
    {
      nanogenreId: 625,
      x: 8553120,
      y: -6794910
    },
    {
      nanogenreId: 626,
      x: -9921990,
      y: 14615700
    },
    {
      nanogenreId: 627,
      x: -4224030,
      y: -16902300
    },
    {
      nanogenreId: 628,
      x: 1436850,
      y: -19541160
    },
    {
      nanogenreId: 629,
      x: -7626120,
      y: 8148330
    },
    {
      nanogenreId: 630,
      x: 15351120,
      y: 16058730
    },
    {
      nanogenreId: 631,
      x: -10944780,
      y: 98880
    },
    {
      nanogenreId: 632,
      x: 5246820,
      y: 10323690
    },
    {
      nanogenreId: 633,
      x: -12641190,
      y: 7962930
    },
    {
      nanogenreId: 634,
      x: 1013520,
      y: 14000790
    },
    {
      nanogenreId: 635,
      x: 16509870,
      y: 10506000
    },
    {
      nanogenreId: 636,
      x: 571650,
      y: 9888000
    },
    {
      nanogenreId: 637,
      x: -9504840,
      y: -2206260
    },
    {
      nanogenreId: 638,
      x: -8781780,
      y: 4335270
    },
    {
      nanogenreId: 639,
      x: 13404420,
      y: 10679040
    },
    {
      nanogenreId: 640,
      x: 707610,
      y: -6680580
    },
    {
      nanogenreId: 641,
      x: -7453080,
      y: 1952880
    },
    {
      nanogenreId: 642,
      x: 1269990,
      y: 8726160
    },
    {
      nanogenreId: 643,
      x: -3040560,
      y: -1226730
    },
    {
      nanogenreId: 644,
      x: 1959060,
      y: 5660880
    },
    {
      nanogenreId: 645,
      x: 7888770,
      y: 7891860
    },
    {
      nanogenreId: 646,
      x: 7660110,
      y: 10935510
    },
    {
      nanogenreId: 647,
      x: 10589430,
      y: 4804950
    },
    {
      nanogenreId: 648,
      x: -1081500,
      y: -7675560
    },
    {
      nanogenreId: 649,
      x: 8957910,
      y: -9557370
    },
    {
      nanogenreId: 650,
      x: -1232910,
      y: -8896110
    },
    {
      nanogenreId: 651,
      x: 1918890,
      y: 8228670
    },
    {
      nanogenreId: 652,
      x: -3377370,
      y: -10870620
    },
    {
      nanogenreId: 653,
      x: -3955200,
      y: 11742000
    },
    {
      nanogenreId: 654,
      x: 8760150,
      y: -4826580
    },
    {
      nanogenreId: 655,
      x: 8546940,
      y: 4959450
    },
    {
      nanogenreId: 656,
      x: -1535730,
      y: -5290080
    },
    {
      nanogenreId: 657,
      x: 7233690,
      y: 9609900
    },
    {
      nanogenreId: 658,
      x: 11504070,
      y: -9912720
    },
    {
      nanogenreId: 659,
      x: 10314420,
      y: -7721910
    },
    {
      nanogenreId: 660,
      x: 6291240,
      y: 10740840
    },
    {
      nanogenreId: 661,
      x: 9582090,
      y: -8964090
    },
    {
      nanogenreId: 662,
      x: 8985720,
      y: -10521450
    },
    {
      nanogenreId: 663,
      x: 435690,
      y: 10181550
    },
    {
      nanogenreId: 664,
      x: 4854390,
      y: -10373130
    },
    {
      nanogenreId: 665,
      x: 5994600,
      y: 11235240
    },
    {
      nanogenreId: 666,
      x: 4758600,
      y: -5629980
    },
    {
      nanogenreId: 667,
      x: 6040950,
      y: 11340300
    },
    {
      nanogenreId: 668,
      x: -8701440,
      y: -10790280
    },
    {
      nanogenreId: 669,
      x: 7981470,
      y: -9303990
    },
    {
      nanogenreId: 670,
      x: 4872930,
      y: -3831600
    },
    {
      nanogenreId: 671,
      x: -7888770,
      y: 6634230
    },
    {
      nanogenreId: 672,
      x: -5972970,
      y: 9362700
    },
    {
      nanogenreId: 673,
      x: -599460,
      y: 10988040
    },
    {
      nanogenreId: 674,
      x: -5181930,
      y: 6961770
    },
    {
      nanogenreId: 675,
      x: -9294720,
      y: 5963700
    },
    {
      nanogenreId: 676,
      x: -5064510,
      y: 14417940
    },
    {
      nanogenreId: 677,
      x: -6600240,
      y: 12220950
    },
    {
      nanogenreId: 678,
      x: -905370,
      y: -11757450
    },
    {
      nanogenreId: 679,
      x: 7601400,
      y: -3371190
    },
    {
      nanogenreId: 680,
      x: 203940,
      y: 8821950
    },
    {
      nanogenreId: 681,
      x: -13030530,
      y: 8432610
    },
    {
      nanogenreId: 682,
      x: 12730800,
      y: 1075320
    },
    {
      nanogenreId: 683,
      x: -3340290,
      y: -1560450
    },
    {
      nanogenreId: 684,
      x: 1755120,
      y: 14819640
    },
    {
      nanogenreId: 685,
      x: 6816540,
      y: 10379310
    },
    {
      nanogenreId: 686,
      x: 10848990,
      y: -8744700
    },
    {
      nanogenreId: 687,
      x: 7873320,
      y: 10842810
    },
    {
      nanogenreId: 688,
      x: -12718440,
      y: 10172280
    },
    {
      nanogenreId: 689,
      x: -6544620,
      y: 13682520
    },
    {
      nanogenreId: 690,
      x: 13871010,
      y: -11346480
    },
    {
      nanogenreId: 691,
      x: 12733890,
      y: 2292780
    },
    {
      nanogenreId: 692,
      x: -7487070,
      y: 5833920
    },
    {
      nanogenreId: 693,
      x: -2848980,
      y: -9307080
    },
    {
      nanogenreId: 694,
      x: 8296650,
      y: -9257640
    },
    {
      nanogenreId: 695,
      x: 12761700,
      y: 18641970
    },
    {
      nanogenreId: 696,
      x: -3414450,
      y: -11998470
    },
    {
      nanogenreId: 697,
      x: -7796070,
      y: -9137130
    },
    {
      nanogenreId: 698,
      x: 14717670,
      y: 2620320
    },
    {
      nanogenreId: 699,
      x: 3769800,
      y: 10747020
    },
    {
      nanogenreId: 700,
      x: 8664360,
      y: -8556210
    },
    {
      nanogenreId: 701,
      x: 15691020,
      y: 13849380
    },
    {
      nanogenreId: 702,
      x: 6485910,
      y: 11624580
    },
    {
      nanogenreId: 703,
      x: -13327170,
      y: 4934730
    },
    {
      nanogenreId: 704,
      x: 7462350,
      y: 5123220
    },
    {
      nanogenreId: 705,
      x: 5840100,
      y: 10230990
    },
    {
      nanogenreId: 706,
      x: -2988030,
      y: -10305150
    },
    {
      nanogenreId: 707,
      x: 10434930,
      y: -11065290
    },
    {
      nanogenreId: 708,
      x: 3658560,
      y: 10988040
    },
    {
      nanogenreId: 709,
      x: -3355740,
      y: -12335280
    },
    {
      nanogenreId: 710,
      x: -15641580,
      y: -8852850
    },
    {
      nanogenreId: 711,
      x: -9612990,
      y: 8191590
    },
    {
      nanogenreId: 712,
      x: -4588650,
      y: -11025120
    },
    {
      nanogenreId: 713,
      x: 6739290,
      y: -7941300
    },
    {
      nanogenreId: 714,
      x: -4616460,
      y: -8386260
    },
    {
      nanogenreId: 715,
      x: 2462730,
      y: 9730410
    },
    {
      nanogenreId: 716,
      x: 8852850,
      y: 8988810
    },
    {
      nanogenreId: 717,
      x: 5710320,
      y: 10848990
    },
    {
      nanogenreId: 718,
      x: 3266130,
      y: 11695650
    },
    {
      nanogenreId: 719,
      x: -3541140,
      y: 621090
    },
    {
      nanogenreId: 720,
      x: -11012760,
      y: 11782170
    },
    {
      nanogenreId: 721,
      x: 14115120,
      y: 1356510
    },
    {
      nanogenreId: 722,
      x: 4217850,
      y: -10719210
    },
    {
      nanogenreId: 723,
      x: 1903440,
      y: -624180
    },
    {
      nanogenreId: 724,
      x: -13324080,
      y: 3939750
    },
    {
      nanogenreId: 725,
      x: 5151030,
      y: -1752030
    },
    {
      nanogenreId: 726,
      x: 5793750,
      y: 11102370
    },
    {
      nanogenreId: 727,
      x: -1517190,
      y: 13682520
    },
    {
      nanogenreId: 728,
      x: -4520670,
      y: -15156450
    },
    {
      nanogenreId: 729,
      x: -8197770,
      y: 10172280
    },
    {
      nanogenreId: 730,
      x: -7416000,
      y: 7962930
    },
    {
      nanogenreId: 731,
      x: -11225970,
      y: 3921210
    },
    {
      nanogenreId: 732,
      x: 8494410,
      y: -9705690
    },
    {
      nanogenreId: 733,
      x: 11556600,
      y: 5509470
    },
    {
      nanogenreId: 734,
      x: 8123610,
      y: 4622640
    },
    {
      nanogenreId: 735,
      x: 6112020,
      y: 10848990
    },
    {
      nanogenreId: 736,
      x: 4842030,
      y: 6569340
    },
    {
      nanogenreId: 737,
      x: -12115890,
      y: -4616460
    },
    {
      nanogenreId: 738,
      x: -432600,
      y: -1081500
    },
    {
      nanogenreId: 739,
      x: 2555430,
      y: -13274640
    },
    {
      nanogenreId: 740,
      x: -2317500,
      y: 11544240
    },
    {
      nanogenreId: 741,
      x: -1823100,
      y: -12681360
    },
    {
      nanogenreId: 742,
      x: 11259960,
      y: -6566250
    },
    {
      nanogenreId: 743,
      x: -6130560,
      y: 3479340
    },
    {
      nanogenreId: 744,
      x: 4310550,
      y: 10085760
    },
    {
      nanogenreId: 745,
      x: 460410,
      y: 13497120
    },
    {
      nanogenreId: 746,
      x: 8522220,
      y: -52530
    },
    {
      nanogenreId: 747,
      x: 9331800,
      y: -5753580
    },
    {
      nanogenreId: 748,
      x: 9093870,
      y: 11562780
    },
    {
      nanogenreId: 749,
      x: 5982240,
      y: 10601790
    },
    {
      nanogenreId: 750,
      x: -2286600,
      y: 16364640
    },
    {
      nanogenreId: 751,
      x: -15450000,
      y: -5568180
    },
    {
      nanogenreId: 752,
      x: -14204730,
      y: -2459640
    },
    {
      nanogenreId: 753,
      x: 10091940,
      y: -4412520
    },
    {
      nanogenreId: 754,
      x: -16463520,
      y: -2567790
    },
    {
      nanogenreId: 755,
      x: -2793360,
      y: 11661660
    },
    {
      nanogenreId: 756,
      x: 10085760,
      y: -9421410
    },
    {
      nanogenreId: 757,
      x: -658170,
      y: 13759770
    },
    {
      nanogenreId: 758,
      x: 5682510,
      y: 11250690
    },
    {
      nanogenreId: 759,
      x: 5055240,
      y: 10326780
    },
    {
      nanogenreId: 760,
      x: 911550,
      y: 11973750
    },
    {
      nanogenreId: 761,
      x: 17909640,
      y: -3303210
    },
    {
      nanogenreId: 762,
      x: 9634620,
      y: -8009280
    },
    {
      nanogenreId: 763,
      x: -16225590,
      y: -5812290
    },
    {
      nanogenreId: 764,
      x: -6263430,
      y: -3185790
    },
    {
      nanogenreId: 765,
      x: -11392830,
      y: -10230990
    },
    {
      nanogenreId: 766,
      x: 17211300,
      y: 6359220
    },
    {
      nanogenreId: 767,
      x: 9013530,
      y: -8750880
    },
    {
      nanogenreId: 768,
      x: 4230210,
      y: -12520680
    },
    {
      nanogenreId: 769,
      x: -4882200,
      y: -7799160
    },
    {
      nanogenreId: 770,
      x: 17934360,
      y: -2252610
    },
    {
      nanogenreId: 771,
      x: -1714950,
      y: -8231760
    },
    {
      nanogenreId: 772,
      x: -5966790,
      y: -14683680
    },
    {
      nanogenreId: 773,
      x: 5021250,
      y: 11235240
    },
    {
      nanogenreId: 774,
      x: 927000,
      y: 10781010
    },
    {
      nanogenreId: 775,
      x: 4295100,
      y: 10261890
    },
    {
      nanogenreId: 776,
      x: -12010830,
      y: 550020
    },
    {
      nanogenreId: 777,
      x: 2264970,
      y: 9857100
    },
    {
      nanogenreId: 778,
      x: -5673240,
      y: 2663580
    },
    {
      nanogenreId: 779,
      x: -3241410,
      y: -11717280
    },
    {
      nanogenreId: 780,
      x: -9115500,
      y: 1551180
    },
    {
      nanogenreId: 781,
      x: -5648520,
      y: -6930870
    },
    {
      nanogenreId: 782,
      x: 2073390,
      y: -13524930
    },
    {
      nanogenreId: 783,
      x: 3080730,
      y: 8973360
    },
    {
      nanogenreId: 784,
      x: 4047900,
      y: -6349950
    },
    {
      nanogenreId: 785,
      x: 6776370,
      y: 10064130
    },
    {
      nanogenreId: 786,
      x: -8089620,
      y: 8333730
    },
    {
      nanogenreId: 787,
      x: 3887220,
      y: 11198160
    },
    {
      nanogenreId: 788,
      x: 4610280,
      y: 9801480
    },
    {
      nanogenreId: 789,
      x: -8976450,
      y: -3868680
    },
    {
      nanogenreId: 790,
      x: 4823490,
      y: 10475100
    },
    {
      nanogenreId: 791,
      x: -3952110,
      y: 9047520
    },
    {
      nanogenreId: 792,
      x: 8985720,
      y: -8438790
    },
    {
      nanogenreId: 793,
      x: 6146010,
      y: 9532650
    },
    {
      nanogenreId: 794,
      x: -8667450,
      y: 9548100
    },
    {
      nanogenreId: 795,
      x: 9010440,
      y: 4607190
    },
    {
      nanogenreId: 796,
      x: 2425650,
      y: 4347630
    },
    {
      nanogenreId: 797,
      x: 9952890,
      y: 1912710
    },
    {
      nanogenreId: 798,
      x: 5364240,
      y: 10988040
    },
    {
      nanogenreId: 799,
      x: -11609130,
      y: -10848990
    },
    {
      nanogenreId: 800,
      x: 9078420,
      y: -766320
    },
    {
      nanogenreId: 801,
      x: 10002330,
      y: 3037470
    },
    {
      nanogenreId: 802,
      x: -766320,
      y: -6637320
    },
    {
      nanogenreId: 803,
      x: -11361930,
      y: 4264200
    },
    {
      nanogenreId: 804,
      x: -7548870,
      y: -2808810
    },
    {
      nanogenreId: 805,
      x: 7440720,
      y: -10073400
    },
    {
      nanogenreId: 806,
      x: -9118590,
      y: 9149490
    },
    {
      nanogenreId: 807,
      x: -2326770,
      y: 12288930
    },
    {
      nanogenreId: 808,
      x: -16775610,
      y: -6309780
    },
    {
      nanogenreId: 809,
      x: 6309780,
      y: 11173440
    },
    {
      nanogenreId: 810,
      x: -1035150,
      y: 12483600
    },
    {
      nanogenreId: 811,
      x: 5549640,
      y: -4431060
    },
    {
      nanogenreId: 812,
      x: -5036700,
      y: -2088840
    },
    {
      nanogenreId: 813,
      x: 2882970,
      y: 11565870
    },
    {
      nanogenreId: 814,
      x: 12146790,
      y: 2348400
    },
    {
      nanogenreId: 815,
      x: 10023960,
      y: -6788730
    },
    {
      nanogenreId: 816,
      x: 8203950,
      y: 4196220
    },
    {
      nanogenreId: 817,
      x: 15857880,
      y: 13191210
    },
    {
      nanogenreId: 818,
      x: -6507540,
      y: 7125540
    },
    {
      nanogenreId: 819,
      x: 6334500,
      y: 8692170
    },
    {
      nanogenreId: 820,
      x: 4492860,
      y: -11352660
    },
    {
      nanogenreId: 821,
      x: 2784090,
      y: 5738130
    },
    {
      nanogenreId: 822,
      x: -3612210,
      y: -4137510
    },
    {
      nanogenreId: 823,
      x: 12978000,
      y: -522210
    },
    {
      nanogenreId: 824,
      x: -9495570,
      y: -10737750
    },
    {
      nanogenreId: 825,
      x: 6813450,
      y: 9081510
    },
    {
      nanogenreId: 826,
      x: -5908080,
      y: 834300
    },
    {
      nanogenreId: 827,
      x: -7882590,
      y: 1177290
    },
    {
      nanogenreId: 828,
      x: 11911950,
      y: -9424500
    },
    {
      nanogenreId: 829,
      x: -10771740,
      y: 6764010
    },
    {
      nanogenreId: 830,
      x: -6850530,
      y: 9934350
    },
    {
      nanogenreId: 831,
      x: 2734650,
      y: -95790
    },
    {
      nanogenreId: 832,
      x: -9214380,
      y: 9949800
    },
    {
      nanogenreId: 833,
      x: 9248370,
      y: -633450
    },
    {
      nanogenreId: 834,
      x: -12091170,
      y: 58710
    },
    {
      nanogenreId: 835,
      x: 5070690,
      y: 10750110
    },
    {
      nanogenreId: 836,
      x: -8599470,
      y: 6180000
    },
    {
      nanogenreId: 837,
      x: -13299360,
      y: 4511400
    },
    {
      nanogenreId: 838,
      x: 17263830,
      y: -9390510
    },
    {
      nanogenreId: 839,
      x: -15131730,
      y: -7548870
    },
    {
      nanogenreId: 840,
      x: 5302440,
      y: 11816160
    },
    {
      nanogenreId: 841,
      x: 4959450,
      y: 10669770
    },
    {
      nanogenreId: 842,
      x: 3123990,
      y: 12106620
    },
    {
      nanogenreId: 843,
      x: 5988420,
      y: -4505220
    },
    {
      nanogenreId: 844,
      x: -9035160,
      y: 7700280
    },
    {
      nanogenreId: 845,
      x: -5701050,
      y: -9307080
    },
    {
      nanogenreId: 846,
      x: -1375050,
      y: -9560460
    },
    {
      nanogenreId: 847,
      x: 10830450,
      y: 7372740
    },
    {
      nanogenreId: 848,
      x: 8330640,
      y: -6368490
    },
    {
      nanogenreId: 849,
      x: 30900,
      y: -15613770
    },
    {
      nanogenreId: 850,
      x: 10172280,
      y: -7678650
    },
    {
      nanogenreId: 851,
      x: 5657790,
      y: -5475480
    },
    {
      nanogenreId: 852,
      x: 2858250,
      y: 14195460
    },
    {
      nanogenreId: 853,
      x: 7601400,
      y: 6810360
    },
    {
      nanogenreId: 854,
      x: 7771350,
      y: -7598310
    },
    {
      nanogenreId: 855,
      x: 7771350,
      y: 8145240
    },
    {
      nanogenreId: 856,
      x: -8466600,
      y: -13725780
    },
    {
      nanogenreId: 857,
      x: -2042490,
      y: 12165330
    },
    {
      nanogenreId: 858,
      x: 4452690,
      y: 13280820
    },
    {
      nanogenreId: 859,
      x: 9687150,
      y: -6371580
    },
    {
      nanogenreId: 860,
      x: 4100430,
      y: 9526470
    },
    {
      nanogenreId: 861,
      x: 6096570,
      y: 14983410
    },
    {
      nanogenreId: 862,
      x: -4826580,
      y: -3658560
    },
    {
      nanogenreId: 863,
      x: -14044050,
      y: 13762860
    },
    {
      nanogenreId: 864,
      x: -12808050,
      y: -1421400
    },
    {
      nanogenreId: 865,
      x: 9940530,
      y: -8889930
    },
    {
      nanogenreId: 866,
      x: -8222490,
      y: 2475090
    },
    {
      nanogenreId: 867,
      x: 14254170,
      y: 3853230
    },
    {
      nanogenreId: 868,
      x: -11751270,
      y: 9134040
    },
    {
      nanogenreId: 869,
      x: 3222870,
      y: 12307470
    },
    {
      nanogenreId: 870,
      x: -7882590,
      y: -6859800
    },
    {
      nanogenreId: 871,
      x: 4168410,
      y: 7292400
    },
    {
      nanogenreId: 872,
      x: -8466600,
      y: -228660
    },
    {
      nanogenreId: 873,
      x: -7490160,
      y: -13283910
    },
    {
      nanogenreId: 874,
      x: 4835850,
      y: 10119750
    },
    {
      nanogenreId: 875,
      x: -5021250,
      y: 10079580
    },
    {
      nanogenreId: 876,
      x: -9328710,
      y: 8219400
    },
    {
      nanogenreId: 877,
      x: 5240640,
      y: 10929330
    },
    {
      nanogenreId: 878,
      x: 6516810,
      y: 11062200
    },
    {
      nanogenreId: 879,
      x: 16692180,
      y: 6510630
    },
    {
      nanogenreId: 880,
      x: 7542690,
      y: -5642340
    },
    {
      nanogenreId: 881,
      x: 6306690,
      y: -9205110
    },
    {
      nanogenreId: 882,
      x: 9582090,
      y: -6513720
    },
    {
      nanogenreId: 883,
      x: -7366560,
      y: 4156050
    },
    {
      nanogenreId: 884,
      x: -6958680,
      y: 4721520
    },
    {
      nanogenreId: 885,
      x: 7762080,
      y: -7057560
    },
    {
      nanogenreId: 886,
      x: 3012750,
      y: 10125930
    },
    {
      nanogenreId: 887,
      x: 6000780,
      y: 1056780
    },
    {
      nanogenreId: 888,
      x: 1140210,
      y: 9588270
    },
    {
      nanogenreId: 889,
      x: -750870,
      y: 1066050
    },
    {
      nanogenreId: 890,
      x: -11062200,
      y: 6631140
    },
    {
      nanogenreId: 891,
      x: -3287760,
      y: -13432230
    },
    {
      nanogenreId: 892,
      x: 766320,
      y: -16883760
    },
    {
      nanogenreId: 893,
      x: -1307070,
      y: -11451540
    },
    {
      nanogenreId: 894,
      x: 3896490,
      y: 747780
    },
    {
      nanogenreId: 895,
      x: 1010430,
      y: 10515270
    },
    {
      nanogenreId: 896,
      x: 3031290,
      y: -7505610
    },
    {
      nanogenreId: 897,
      x: 10332960,
      y: -7323300
    },
    {
      nanogenreId: 898,
      x: 1659330,
      y: 9300900
    },
    {
      nanogenreId: 899,
      x: 17387430,
      y: -4573200
    },
    {
      nanogenreId: 900,
      x: -4906920,
      y: 13605270
    },
    {
      nanogenreId: 901,
      x: 10125930,
      y: -3769800
    },
    {
      nanogenreId: 902,
      x: -6102750,
      y: 7400550
    },
    {
      nanogenreId: 903,
      x: -3117810,
      y: -3989190
    },
    {
      nanogenreId: 904,
      x: 5756670,
      y: 11377380
    },
    {
      nanogenreId: 905,
      x: 6124380,
      y: 10203180
    },
    {
      nanogenreId: 906,
      x: -837390,
      y: -8692170
    },
    {
      nanogenreId: 907,
      x: 12205500,
      y: -8710710
    },
    {
      nanogenreId: 908,
      x: 7845510,
      y: 19791450
    },
    {
      nanogenreId: 909,
      x: -12764790,
      y: -4273470
    },
    {
      nanogenreId: 910,
      x: 13113960,
      y: 10169190
    },
    {
      nanogenreId: 911,
      x: 6037860,
      y: 10688310
    },
    {
      nanogenreId: 912,
      x: 324450,
      y: 9801480
    },
    {
      nanogenreId: 913,
      x: 5107770,
      y: 10468920
    },
    {
      nanogenreId: 914,
      x: -5883360,
      y: 7097730
    },
    {
      nanogenreId: 915,
      x: 7499430,
      y: 9356520
    },
    {
      nanogenreId: 916,
      x: 10206270,
      y: -8834310
    },
    {
      nanogenreId: 917,
      x: -5605260,
      y: 11485530
    },
    {
      nanogenreId: 918,
      x: 5169570,
      y: 10805730
    },
    {
      nanogenreId: 919,
      x: 11451540,
      y: -8463510
    },
    {
      nanogenreId: 920,
      x: -8475870,
      y: 9341070
    },
    {
      nanogenreId: 921,
      x: 1473930,
      y: 5002710
    },
    {
      nanogenreId: 922,
      x: 6291240,
      y: 11059110
    },
    {
      nanogenreId: 923,
      x: -15267690,
      y: 8976450
    },
    {
      nanogenreId: 924,
      x: 4903830,
      y: 11259960
    },
    {
      nanogenreId: 925,
      x: -1643880,
      y: 3541140
    },
    {
      nanogenreId: 926,
      x: 9266910,
      y: -11476260
    },
    {
      nanogenreId: 927,
      x: 5534190,
      y: 10709940
    },
    {
      nanogenreId: 928,
      x: 4100430,
      y: 10564710
    },
    {
      nanogenreId: 929,
      x: 11454630,
      y: -7956750
    },
    {
      nanogenreId: 930,
      x: 2814990,
      y: -6662040
    },
    {
      nanogenreId: 931,
      x: 5228280,
      y: -3278490
    },
    {
      nanogenreId: 932,
      x: -14535360,
      y: -12301290
    },
    {
      nanogenreId: 933,
      x: 590190,
      y: -862110
    },
    {
      nanogenreId: 934,
      x: 4557750,
      y: -14356140
    },
    {
      nanogenreId: 935,
      x: -2830440,
      y: 6451920
    },
    {
      nanogenreId: 936,
      x: -10048680,
      y: -1078410
    },
    {
      nanogenreId: 937,
      x: 9640800,
      y: -8463510
    },
    {
      nanogenreId: 938,
      x: 1801470,
      y: 10536900
    },
    {
      nanogenreId: 939,
      x: -1622250,
      y: -9705690
    },
    {
      nanogenreId: 940,
      x: 14427210,
      y: 216300
    },
    {
      nanogenreId: 941,
      x: 2688300,
      y: 10441110
    },
    {
      nanogenreId: 942,
      x: 8973360,
      y: -6841260
    },
    {
      nanogenreId: 943,
      x: -7233690,
      y: 7150260
    },
    {
      nanogenreId: 944,
      x: 4857480,
      y: 11816160
    },
    {
      nanogenreId: 945,
      x: -5506380,
      y: 6655860
    },
    {
      nanogenreId: 946,
      x: -14479740,
      y: -5710320
    },
    {
      nanogenreId: 947,
      x: -14569350,
      y: -7057560
    },
    {
      nanogenreId: 948,
      x: 5305530,
      y: 10592520
    },
    {
      nanogenreId: 949,
      x: -5453850,
      y: -5089230
    },
    {
      nanogenreId: 950,
      x: 5194290,
      y: 11343390
    },
    {
      nanogenreId: 951,
      x: -1952880,
      y: 8101980
    },
    {
      nanogenreId: 952,
      x: 12285840,
      y: 5960610
    },
    {
      nanogenreId: 953,
      x: -6600240,
      y: 1693320
    },
    {
      nanogenreId: 954,
      x: -12780240,
      y: 2385480
    },
    {
      nanogenreId: 955,
      x: 2407110,
      y: 9109320
    },
    {
      nanogenreId: 956,
      x: 5524920,
      y: 12783330
    },
    {
      nanogenreId: 957,
      x: 9081510,
      y: -7224420
    },
    {
      nanogenreId: 958,
      x: -4761690,
      y: 2119740
    },
    {
      nanogenreId: 959,
      x: 2845890,
      y: -10209360
    },
    {
      nanogenreId: 960,
      x: -546930,
      y: 5123220
    },
    {
      nanogenreId: 961,
      x: -296640,
      y: 9714960
    },
    {
      nanogenreId: 962,
      x: -7468530,
      y: 7619940
    },
    {
      nanogenreId: 963,
      x: 5608350,
      y: 6757830
    },
    {
      nanogenreId: 964,
      x: -6628050,
      y: 5030520
    },
    {
      nanogenreId: 965,
      x: -5923530,
      y: 9872550
    },
    {
      nanogenreId: 966,
      x: -9807660,
      y: -982620
    },
    {
      nanogenreId: 967,
      x: -12224040,
      y: -10573980
    },
    {
      nanogenreId: 968,
      x: 10740840,
      y: -6736200
    },
    {
      nanogenreId: 969,
      x: 8117430,
      y: 3241410
    },
    {
      nanogenreId: 970,
      x: 8469690,
      y: -2366940
    },
    {
      nanogenreId: 971,
      x: 6958680,
      y: -7156440
    },
    {
      nanogenreId: 972,
      x: -4675170,
      y: -2230980
    },
    {
      nanogenreId: 973,
      x: 5707230,
      y: 10088850
    },
    {
      nanogenreId: 974,
      x: 9446130,
      y: -8203950
    },
    {
      nanogenreId: 975,
      x: 8775600,
      y: -7657020
    },
    {
      nanogenreId: 976,
      x: 10716120,
      y: -8135970
    },
    {
      nanogenreId: 977,
      x: 9347250,
      y: -5280810
    },
    {
      nanogenreId: 978,
      x: 803400,
      y: -1588260
    },
    {
      nanogenreId: 979,
      x: 1705680,
      y: -15585960
    },
    {
      nanogenreId: 980,
      x: 6223260,
      y: 9934350
    },
    {
      nanogenreId: 981,
      x: 5497110,
      y: -4109700
    },
    {
      nanogenreId: 982,
      x: -6244890,
      y: -2400930
    },
    {
      nanogenreId: 983,
      x: 2533800,
      y: 10342230
    },
    {
      nanogenreId: 984,
      x: -5052150,
      y: 10833540
    },
    {
      nanogenreId: 985,
      x: 11346480,
      y: 407880
    },
    {
      nanogenreId: 986,
      x: -200850,
      y: -13784490
    },
    {
      nanogenreId: 987,
      x: 9511020,
      y: 3584400
    },
    {
      nanogenreId: 988,
      x: 12183870,
      y: 16775610
    },
    {
      nanogenreId: 989,
      x: -5113950,
      y: 8420250
    },
    {
      nanogenreId: 990,
      x: -5623800,
      y: -2944770
    },
    {
      nanogenreId: 991,
      x: 7147170,
      y: -1168020
    },
    {
      nanogenreId: 992,
      x: 339900,
      y: -2595600
    },
    {
      nanogenreId: 993,
      x: 15320220,
      y: 5843190
    },
    {
      nanogenreId: 994,
      x: 6909240,
      y: -8757060
    },
    {
      nanogenreId: 995,
      x: 4789500,
      y: -7023570
    },
    {
      nanogenreId: 996,
      x: -13830840,
      y: -4029360
    },
    {
      nanogenreId: 997,
      x: 2425650,
      y: 10691400
    },
    {
      nanogenreId: 998,
      x: -4202400,
      y: -1269990
    },
    {
      nanogenreId: 999,
      x: -14587890,
      y: 3738900
    },
    {
      nanogenreId: 1000,
      x: 9711870,
      y: -7258410
    },
    {
      nanogenreId: 1001,
      x: -15416010,
      y: 3321750
    },
    {
      nanogenreId: 1002,
      x: 4044810,
      y: -8420250
    },
    {
      nanogenreId: 1003,
      x: -2867520,
      y: -4424880
    },
    {
      nanogenreId: 1004,
      x: -7765170,
      y: -8117430
    },
    {
      nanogenreId: 1005,
      x: -3754350,
      y: -11813070
    },
    {
      nanogenreId: 1006,
      x: 12764790,
      y: 17554290
    },
    {
      nanogenreId: 1007,
      x: -3182700,
      y: 3751260
    },
    {
      nanogenreId: 1008,
      x: -12066450,
      y: -1236000
    },
    {
      nanogenreId: 1009,
      x: 6331410,
      y: 10700670
    },
    {
      nanogenreId: 1010,
      x: -9878730,
      y: 4326000
    },
    {
      nanogenreId: 1011,
      x: -7888770,
      y: -9807660
    },
    {
      nanogenreId: 1012,
      x: 8343000,
      y: -3735810
    },
    {
      nanogenreId: 1013,
      x: 4267290,
      y: 8095800
    },
    {
      nanogenreId: 1014,
      x: -7817700,
      y: 108150
    },
    {
      nanogenreId: 1015,
      x: -5339520,
      y: 281190
    },
    {
      nanogenreId: 1016,
      x: 9529560,
      y: -9483210
    },
    {
      nanogenreId: 1017,
      x: -8846670,
      y: 4953270
    },
    {
      nanogenreId: 1018,
      x: -7184250,
      y: -11708010
    },
    {
      nanogenreId: 1019,
      x: 8475870,
      y: -10076490
    },
    {
      nanogenreId: 1020,
      x: 7289310,
      y: 14479740
    },
    {
      nanogenreId: 1021,
      x: -7833150,
      y: 8055630
    },
    {
      nanogenreId: 1022,
      x: 9872550,
      y: -8503680
    },
    {
      nanogenreId: 1023,
      x: -4736970,
      y: -8840490
    },
    {
      nanogenreId: 1024,
      x: -8281200,
      y: -2030130
    },
    {
      nanogenreId: 1025,
      x: 4125150,
      y: -590190
    },
    {
      nanogenreId: 1026,
      x: -8750880,
      y: 2190810
    },
    {
      nanogenreId: 1027,
      x: -8194680,
      y: 475860
    },
    {
      nanogenreId: 1028,
      x: 7555050,
      y: -10462740
    },
    {
      nanogenreId: 1029,
      x: 2706840,
      y: 185400
    },
    {
      nanogenreId: 1030,
      x: 1059870,
      y: 13388970
    },
    {
      nanogenreId: 1031,
      x: 10645050,
      y: -7551960
    },
    {
      nanogenreId: 1032,
      x: 3068370,
      y: -16154520
    },
    {
      nanogenreId: 1033,
      x: 6757830,
      y: 7168800
    },
    {
      nanogenreId: 1034,
      x: -15403650,
      y: -2240250
    },
    {
      nanogenreId: 1035,
      x: 5132490,
      y: -6507540
    },
    {
      nanogenreId: 1036,
      x: 2555430,
      y: 10864440
    },
    {
      nanogenreId: 1037,
      x: -10064130,
      y: 9640800
    },
    {
      nanogenreId: 1038,
      x: 404790,
      y: 10703760
    },
    {
      nanogenreId: 1039,
      x: 148320,
      y: -13438410
    },
    {
      nanogenreId: 1040,
      x: -877560,
      y: -7672470
    },
    {
      nanogenreId: 1041,
      x: 840480,
      y: -231750
    },
    {
      nanogenreId: 1042,
      x: -14263440,
      y: -290460
    },
    {
      nanogenreId: 1043,
      x: 2975670,
      y: 2453460
    },
    {
      nanogenreId: 1044,
      x: 4193130,
      y: 7508700
    },
    {
      nanogenreId: 1045,
      x: -1733490,
      y: -10014690
    },
    {
      nanogenreId: 1046,
      x: 3383550,
      y: 8849760
    },
    {
      nanogenreId: 1047,
      x: 2845890,
      y: 10076490
    },
    {
      nanogenreId: 1048,
      x: 5731950,
      y: -9356520
    },
    {
      nanogenreId: 1049,
      x: 3290850,
      y: 11201250
    },
    {
      nanogenreId: 1050,
      x: 9189660,
      y: -7474710
    },
    {
      nanogenreId: 1051,
      x: -1776750,
      y: -7107000
    },
    {
      nanogenreId: 1052,
      x: 902280,
      y: -8753970
    },
    {
      nanogenreId: 1053,
      x: -7514880,
      y: -8550030
    },
    {
      nanogenreId: 1054,
      x: 5175750,
      y: 9004260
    },
    {
      nanogenreId: 1055,
      x: 6458100,
      y: 4567020
    },
    {
      nanogenreId: 1056,
      x: 3504060,
      y: 11905770
    },
    {
      nanogenreId: 1057,
      x: 8846670,
      y: -9696420
    },
    {
      nanogenreId: 1058,
      x: 8957910,
      y: -4947090
    },
    {
      nanogenreId: 1059,
      x: 4397070,
      y: 11927400
    },
    {
      nanogenreId: 1060,
      x: 1217460,
      y: 11170350
    },
    {
      nanogenreId: 1061,
      x: 6210900,
      y: 11148720
    },
    {
      nanogenreId: 1062,
      x: 571650,
      y: -1718040
    },
    {
      nanogenreId: 1063,
      x: 13605270,
      y: 3528780
    },
    {
      nanogenreId: 1064,
      x: 4570110,
      y: 10555440
    },
    {
      nanogenreId: 1065,
      x: 9949800,
      y: -8253390
    },
    {
      nanogenreId: 1066,
      x: 3225960,
      y: 9878730
    },
    {
      nanogenreId: 1067,
      x: 9745860,
      y: -241020
    },
    {
      nanogenreId: 1068,
      x: -1303980,
      y: 6090390
    },
    {
      nanogenreId: 1069,
      x: -6665130,
      y: -5741220
    },
    {
      nanogenreId: 1070,
      x: 11022030,
      y: 6417930
    },
    {
      nanogenreId: 1071,
      x: -7947480,
      y: 5710320
    },
    {
      nanogenreId: 1072,
      x: -6649680,
      y: 8988810
    },
    {
      nanogenreId: 1073,
      x: 16673640,
      y: 5395140
    },
    {
      nanogenreId: 1074,
      x: 4118970,
      y: 9770580
    },
    {
      nanogenreId: 1075,
      x: 4415610,
      y: 5555820
    },
    {
      nanogenreId: 1076,
      x: -2095020,
      y: -11266140
    },
    {
      nanogenreId: 1077,
      x: 4746240,
      y: 10061040
    },
    {
      nanogenreId: 1078,
      x: 3460800,
      y: -7267680
    },
    {
      nanogenreId: 1079,
      x: 18629610,
      y: -1582080
    },
    {
      nanogenreId: 1080,
      x: 2719200,
      y: 10598700
    },
    {
      nanogenreId: 1081,
      x: 4298190,
      y: 11383560
    },
    {
      nanogenreId: 1082,
      x: 5793750,
      y: -8417160
    },
    {
      nanogenreId: 1083,
      x: 12857490,
      y: -11077650
    },
    {
      nanogenreId: 1084,
      x: 55620,
      y: 336810
    },
    {
      nanogenreId: 1085,
      x: -2490540,
      y: -10855170
    },
    {
      nanogenreId: 1086,
      x: 10280430,
      y: -5265360
    },
    {
      nanogenreId: 1087,
      x: -7867140,
      y: 7329480
    },
    {
      nanogenreId: 1088,
      x: 8744700,
      y: -3865590
    },
    {
      nanogenreId: 1089,
      x: 1322520,
      y: 2836620
    },
    {
      nanogenreId: 1090,
      x: 9665520,
      y: -8639640
    },
    {
      nanogenreId: 1091,
      x: 6167640,
      y: 11083830
    },
    {
      nanogenreId: 1092,
      x: -6430290,
      y: 4956360
    },
    {
      nanogenreId: 1093,
      x: 4078800,
      y: 10487460
    },
    {
      nanogenreId: 1094,
      x: -4143690,
      y: 11683290
    },
    {
      nanogenreId: 1095,
      x: -5169570,
      y: 10434930
    },
    {
      nanogenreId: 1096,
      x: 9047520,
      y: -8852850
    },
    {
      nanogenreId: 1097,
      x: -1816920,
      y: 6479730
    },
    {
      nanogenreId: 1098,
      x: -10611060,
      y: 7301670
    },
    {
      nanogenreId: 1099,
      x: 15629220,
      y: 6702210
    },
    {
      nanogenreId: 1100,
      x: 10580160,
      y: -9931260
    },
    {
      nanogenreId: 1101,
      x: -3572040,
      y: 14770200
    },
    {
      nanogenreId: 1102,
      x: 9341070,
      y: -9254550
    },
    {
      nanogenreId: 1103,
      x: -2944770,
      y: 11120910
    },
    {
      nanogenreId: 1104,
      x: 4916190,
      y: 10502910
    },
    {
      nanogenreId: 1105,
      x: 840480,
      y: 627270
    },
    {
      nanogenreId: 1106,
      x: 3528780,
      y: 9399780
    },
    {
      nanogenreId: 1107,
      x: 10530720,
      y: 3837780
    },
    {
      nanogenreId: 1108,
      x: 9888000,
      y: -8043270
    },
    {
      nanogenreId: 1109,
      x: 5626890,
      y: 10864440
    },
    {
      nanogenreId: 1110,
      x: 13398240,
      y: -4363080
    },
    {
      nanogenreId: 1111,
      x: 3961380,
      y: 8145240
    },
    {
      nanogenreId: 1112,
      x: -4288920,
      y: 8126700
    },
    {
      nanogenreId: 1113,
      x: -1161840,
      y: -9974520
    },
    {
      nanogenreId: 1114,
      x: 256470,
      y: 3874860
    },
    {
      nanogenreId: 1115,
      x: 7721910,
      y: -8315190
    },
    {
      nanogenreId: 1116,
      x: 7647750,
      y: -4406340
    },
    {
      nanogenreId: 1117,
      x: -7369650,
      y: -9350340
    },
    {
      nanogenreId: 1118,
      x: -1344150,
      y: 9217470
    },
    {
      nanogenreId: 1119,
      x: -5299350,
      y: 8880660
    },
    {
      nanogenreId: 1120,
      x: -899190,
      y: 10836630
    },
    {
      nanogenreId: 1121,
      x: 1881810,
      y: -12869850
    },
    {
      nanogenreId: 1122,
      x: 1266900,
      y: 9217470
    },
    {
      nanogenreId: 1123,
      x: 5772120,
      y: 10570890
    },
    {
      nanogenreId: 1124,
      x: -4659720,
      y: 5450760
    },
    {
      nanogenreId: 1125,
      x: 37080,
      y: 6686760
    },
    {
      nanogenreId: 1126,
      x: 10913880,
      y: -9371970
    },
    {
      nanogenreId: 1127,
      x: -6612600,
      y: 7808430
    },
    {
      nanogenreId: 1128,
      x: 5948250,
      y: 6581700
    },
    {
      nanogenreId: 1129,
      x: -8877570,
      y: 8642730
    },
    {
      nanogenreId: 1130,
      x: 6377760,
      y: 11052930
    },
    {
      nanogenreId: 1131,
      x: -2472000,
      y: -11130180
    },
    {
      nanogenreId: 1132,
      x: -1823100,
      y: -5194290
    },
    {
      nanogenreId: 1133,
      x: 3297030,
      y: -2079570
    },
    {
      nanogenreId: 1134,
      x: 4563930,
      y: 10679040
    },
    {
      nanogenreId: 1135,
      x: 7666290,
      y: 10138290
    },
    {
      nanogenreId: 1136,
      x: 4143690,
      y: -10385490
    },
    {
      nanogenreId: 1137,
      x: 10543080,
      y: -8723070
    },
    {
      nanogenreId: 1138,
      x: 129780,
      y: 8145240
    },
    {
      nanogenreId: 1139,
      x: -5985330,
      y: -4023180
    },
    {
      nanogenreId: 1140,
      x: -1637700,
      y: -1761300
    },
    {
      nanogenreId: 1141,
      x: -5487840,
      y: 8003100
    },
    {
      nanogenreId: 1142,
      x: -11652390,
      y: 12903840
    },
    {
      nanogenreId: 1143,
      x: -896100,
      y: 10101210
    },
    {
      nanogenreId: 1144,
      x: -11470080,
      y: 1718040
    },
    {
      nanogenreId: 1145,
      x: -16225590,
      y: 95790
    },
    {
      nanogenreId: 1146,
      x: -7215150,
      y: 3389730
    },
    {
      nanogenreId: 1147,
      x: 8797230,
      y: -8602560
    },
    {
      nanogenreId: 1148,
      x: 7904220,
      y: 19136370
    },
    {
      nanogenreId: 1149,
      x: -2524530,
      y: 8713800
    },
    {
      nanogenreId: 1150,
      x: 1891080,
      y: -3485520
    },
    {
      nanogenreId: 1151,
      x: 14992680,
      y: 6782550
    },
    {
      nanogenreId: 1152,
      x: -11232150,
      y: -5691780
    },
    {
      nanogenreId: 1153,
      x: 4171500,
      y: 9801480
    },
    {
      nanogenreId: 1154,
      x: -5015070,
      y: 15242970
    },
    {
      nanogenreId: 1155,
      x: 5929710,
      y: 9421410
    },
    {
      nanogenreId: 1156,
      x: -11253780,
      y: 3238320
    },
    {
      nanogenreId: 1157,
      x: 3531870,
      y: 10512180
    },
    {
      nanogenreId: 1158,
      x: 3222870,
      y: 5716500
    },
    {
      nanogenreId: 1159,
      x: 3337200,
      y: 8163780
    },
    {
      nanogenreId: 1160,
      x: 7091550,
      y: 11157990
    },
    {
      nanogenreId: 1161,
      x: 10694490,
      y: 6504450
    },
    {
      nanogenreId: 1162,
      x: -4338360,
      y: 1192740
    },
    {
      nanogenreId: 1163,
      x: 213210,
      y: -10014690
    },
    {
      nanogenreId: 1164,
      x: -9121680,
      y: 4393980
    },
    {
      nanogenreId: 1165,
      x: -265740,
      y: -10703760
    },
    {
      nanogenreId: 1166,
      x: 6798000,
      y: 9609900
    },
    {
      nanogenreId: 1167,
      x: 8275020,
      y: -9619170
    },
    {
      nanogenreId: 1168,
      x: -2848980,
      y: -7505610
    },
    {
      nanogenreId: 1169,
      x: -8723070,
      y: 6853620
    },
    {
      nanogenreId: 1170,
      x: 4183860,
      y: -2920050
    },
    {
      nanogenreId: 1171,
      x: 7796070,
      y: 4635000
    },
    {
      nanogenreId: 1172,
      x: 5775210,
      y: 10907700
    },
    {
      nanogenreId: 1173,
      x: 4835850,
      y: 11460810
    },
    {
      nanogenreId: 1174,
      x: 10663590,
      y: -12319830
    },
    {
      nanogenreId: 1175,
      x: 49440,
      y: -1223640
    },
    {
      nanogenreId: 1176,
      x: 5163390,
      y: 10224810
    },
    {
      nanogenreId: 1177,
      x: 5546550,
      y: 10107390
    },
    {
      nanogenreId: 1178,
      x: -1480110,
      y: -9782940
    },
    {
      nanogenreId: 1179,
      x: -4458870,
      y: 6464280
    },
    {
      nanogenreId: 1180,
      x: 5478570,
      y: 9866370
    },
    {
      nanogenreId: 1181,
      x: -3238320,
      y: 5243730
    },
    {
      nanogenreId: 1182,
      x: -14356140,
      y: -4369260
    },
    {
      nanogenreId: 1183,
      x: -5373510,
      y: 4097340
    },
    {
      nanogenreId: 1184,
      x: -2141370,
      y: 9594450
    },
    {
      nanogenreId: 1185,
      x: -2354580,
      y: -3507150
    },
    {
      nanogenreId: 1186,
      x: -4764780,
      y: 7023570
    },
    {
      nanogenreId: 1187,
      x: 6149100,
      y: -5181930
    },
    {
      nanogenreId: 1188,
      x: 1866360,
      y: -15100830
    },
    {
      nanogenreId: 1189,
      x: 3766710,
      y: 9551190
    },
    {
      nanogenreId: 1190,
      x: -9937440,
      y: 5117040
    },
    {
      nanogenreId: 1191,
      x: -8173050,
      y: 6535350
    },
    {
      nanogenreId: 1192,
      x: 11896500,
      y: 6983400
    },
    {
      nanogenreId: 1193,
      x: 7561230,
      y: 9195840
    },
    {
      nanogenreId: 1194,
      x: 9891090,
      y: -8710710
    },
    {
      nanogenreId: 1195,
      x: -1356510,
      y: -7530330
    },
    {
      nanogenreId: 1196,
      x: 9758220,
      y: -6788730
    },
    {
      nanogenreId: 1197,
      x: 3497880,
      y: -290460
    },
    {
      nanogenreId: 1198,
      x: -1495560,
      y: 12202410
    },
    {
      nanogenreId: 1199,
      x: -9146400,
      y: 7060650
    },
    {
      nanogenreId: 1200,
      x: 9662430,
      y: -8234850
    },
    {
      nanogenreId: 1201,
      x: -15932040,
      y: -5972970
    },
    {
      nanogenreId: 1202,
      x: -17112420,
      y: 7737360
    },
    {
      nanogenreId: 1203,
      x: -1211280,
      y: -8185410
    },
    {
      nanogenreId: 1204,
      x: 8333730,
      y: -4758600
    },
    {
      nanogenreId: 1205,
      x: -4619550,
      y: 8284290
    },
    {
      nanogenreId: 1206,
      x: 10404030,
      y: -5892630
    },
    {
      nanogenreId: 1207,
      x: 14977230,
      y: 8139060
    },
    {
      nanogenreId: 1208,
      x: 10450380,
      y: 3099270
    },
    {
      nanogenreId: 1209,
      x: -2663580,
      y: 11479350
    },
    {
      nanogenreId: 1210,
      x: 4483590,
      y: 9690240
    },
    {
      nanogenreId: 1211,
      x: -6399390,
      y: 5611440
    },
    {
      nanogenreId: 1212,
      x: 8420250,
      y: -1396680
    },
    {
      nanogenreId: 1213,
      x: -6680580,
      y: 8256480
    },
    {
      nanogenreId: 1214,
      x: -5185020,
      y: -4730790
    },
    {
      nanogenreId: 1215,
      x: 12301290,
      y: -6801090
    },
    {
      nanogenreId: 1216,
      x: -3803790,
      y: -3161070
    },
    {
      nanogenreId: 1217,
      x: -6387030,
      y: -284280
    },
    {
      nanogenreId: 1218,
      x: -9594450,
      y: 6856710
    },
    {
      nanogenreId: 1219,
      x: 10064130,
      y: -8139060
    },
    {
      nanogenreId: 1220,
      x: 12474330,
      y: -9640800
    },
    {
      nanogenreId: 1221,
      x: -5413680,
      y: 10329870
    },
    {
      nanogenreId: 1222,
      x: 3204330,
      y: -6974130
    },
    {
      nanogenreId: 1223,
      x: 5784480,
      y: -10125930
    },
    {
      nanogenreId: 1224,
      x: 2910780,
      y: -6455010
    },
    {
      nanogenreId: 1225,
      x: -5976060,
      y: -7060650
    },
    {
      nanogenreId: 1226,
      x: 6195450,
      y: 10635780
    },
    {
      nanogenreId: 1227,
      x: -2382390,
      y: -11989200
    },
    {
      nanogenreId: 1228,
      x: 11111640,
      y: 6093480
    },
    {
      nanogenreId: 1229,
      x: -9841650,
      y: 3911940
    },
    {
      nanogenreId: 1230,
      x: 3368100,
      y: 8472780
    },
    {
      nanogenreId: 1231,
      x: -4619550,
      y: 10641960
    },
    {
      nanogenreId: 1232,
      x: 4916190,
      y: 8339910
    },
    {
      nanogenreId: 1233,
      x: -8723070,
      y: 5756670
    },
    {
      nanogenreId: 1234,
      x: 3958290,
      y: -868290
    },
    {
      nanogenreId: 1235,
      x: 8976450,
      y: -7956750
    },
    {
      nanogenreId: 1236,
      x: 7487070,
      y: 19365030
    },
    {
      nanogenreId: 1237,
      x: 5880270,
      y: 10777920
    },
    {
      nanogenreId: 1238,
      x: -7094640,
      y: -3086910
    },
    {
      nanogenreId: 1239,
      x: -5725770,
      y: 5861730
    },
    {
      nanogenreId: 1240,
      x: 6458100,
      y: 8463510
    },
    {
      nanogenreId: 1241,
      x: -7595220,
      y: 13673250
    },
    {
      nanogenreId: 1242,
      x: 1229820,
      y: 13160310
    },
    {
      nanogenreId: 1243,
      x: 4285830,
      y: 11361930
    },
    {
      nanogenreId: 1244,
      x: -7894950,
      y: -92700
    },
    {
      nanogenreId: 1245,
      x: 8268840,
      y: -8234850
    },
    {
      nanogenreId: 1246,
      x: -5178840,
      y: -14516820
    },
    {
      nanogenreId: 1247,
      x: 3964470,
      y: 11423730
    },
    {
      nanogenreId: 1248,
      x: 1198920,
      y: -8741610
    },
    {
      nanogenreId: 1249,
      x: -3133260,
      y: -12978000
    },
    {
      nanogenreId: 1250,
      x: -14421030,
      y: -562380
    },
    {
      nanogenreId: 1251,
      x: -1918890,
      y: -11609130
    },
    {
      nanogenreId: 1252,
      x: 7094640,
      y: 2527620
    },
    {
      nanogenreId: 1253,
      x: 6822720,
      y: -10184640
    },
    {
      nanogenreId: 1254,
      x: 8123610,
      y: -8701440
    },
    {
      nanogenreId: 1255,
      x: 10323690,
      y: -5537280
    },
    {
      nanogenreId: 1256,
      x: 5901900,
      y: 7310940
    },
    {
      nanogenreId: 1257,
      x: -2620320,
      y: 8435700
    },
    {
      nanogenreId: 1258,
      x: 1217460,
      y: 7721910
    },
    {
      nanogenreId: 1259,
      x: 5144850,
      y: -8077260
    },
    {
      nanogenreId: 1260,
      x: -2067210,
      y: 6507540
    },
    {
      nanogenreId: 1261,
      x: 5061420,
      y: 10657410
    },
    {
      nanogenreId: 1262,
      x: 6921600,
      y: 4233300
    },
    {
      nanogenreId: 1263,
      x: -695250,
      y: -13596000
    },
    {
      nanogenreId: 1264,
      x: 6522990,
      y: 7959840
    },
    {
      nanogenreId: 1265,
      x: 7530330,
      y: -11117820
    },
    {
      nanogenreId: 1266,
      x: 8457330,
      y: -8534580
    },
    {
      nanogenreId: 1267,
      x: -4879110,
      y: -2317500
    },
    {
      nanogenreId: 1268,
      x: 2784090,
      y: 10728480
    },
    {
      nanogenreId: 1269,
      x: 4434150,
      y: 11652390
    },
    {
      nanogenreId: 1270,
      x: 5379690,
      y: -4882200
    },
    {
      nanogenreId: 1271,
      x: -1427580,
      y: 8092710
    },
    {
      nanogenreId: 1272,
      x: 11538060,
      y: -3519510
    },
    {
      nanogenreId: 1273,
      x: 8821950,
      y: -5185020
    },
    {
      nanogenreId: 1274,
      x: -8290470,
      y: 5460030
    },
    {
      nanogenreId: 1275,
      x: 6838170,
      y: -9517200
    },
    {
      nanogenreId: 1276,
      x: 6865980,
      y: 10348410
    },
    {
      nanogenreId: 1277,
      x: 5877180,
      y: 10506000
    },
    {
      nanogenreId: 1278,
      x: 905370,
      y: -7437630
    },
    {
      nanogenreId: 1279,
      x: -12789510,
      y: -5793750
    },
    {
      nanogenreId: 1280,
      x: -14078040,
      y: -6362310
    },
    {
      nanogenreId: 1281,
      x: 9665520,
      y: -7820790
    },
    {
      nanogenreId: 1282,
      x: 5997690,
      y: -3507150
    },
    {
      nanogenreId: 1283,
      x: 4835850,
      y: 10666680
    },
    {
      nanogenreId: 1284,
      x: 9925080,
      y: -9693330
    },
    {
      nanogenreId: 1285,
      x: -10753200,
      y: 3022020
    },
    {
      nanogenreId: 1286,
      x: -7230600,
      y: -8948640
    },
    {
      nanogenreId: 1287,
      x: -1801470,
      y: 7048290
    },
    {
      nanogenreId: 1288,
      x: 1714950,
      y: 9106230
    },
    {
      nanogenreId: 1289,
      x: 5407500,
      y: 10530720
    },
    {
      nanogenreId: 1290,
      x: -1943610,
      y: 14040960
    },
    {
      nanogenreId: 1291,
      x: 5277720,
      y: 10141380
    },
    {
      nanogenreId: 1292,
      x: -9786030,
      y: 5929710
    },
    {
      nanogenreId: 1293,
      x: -6238710,
      y: 13144860
    },
    {
      nanogenreId: 1294,
      x: 6022410,
      y: -8825040
    },
    {
      nanogenreId: 1295,
      x: 8667450,
      y: -4702980
    },
    {
      nanogenreId: 1296,
      x: -7792980,
      y: 9912720
    },
    {
      nanogenreId: 1297,
      x: 3365010,
      y: -7629210
    },
    {
      nanogenreId: 1298,
      x: 1202010,
      y: -11408280
    },
    {
      nanogenreId: 1299,
      x: -4332180,
      y: 2957130
    },
    {
      nanogenreId: 1300,
      x: 6356130,
      y: -10064130
    },
    {
      nanogenreId: 1301,
      x: 12437250,
      y: 2598690
    },
    {
      nanogenreId: 1302,
      x: -4953270,
      y: -5617620
    },
    {
      nanogenreId: 1303,
      x: 9554280,
      y: -9399780
    },
    {
      nanogenreId: 1304,
      x: -1560450,
      y: 1631520
    },
    {
      nanogenreId: 1305,
      x: -2444190,
      y: -11609130
    },
    {
      nanogenreId: 1306,
      x: -10713030,
      y: -5685600
    },
    {
      nanogenreId: 1307,
      x: 7734270,
      y: -10193910
    },
    {
      nanogenreId: 1308,
      x: 11513340,
      y: -11970660
    },
    {
      nanogenreId: 1309,
      x: -815760,
      y: 3729630
    },
    {
      nanogenreId: 1310,
      x: 12017010,
      y: -6705300
    },
    {
      nanogenreId: 1311,
      x: -4217850,
      y: 1668600
    },
    {
      nanogenreId: 1312,
      x: -633450,
      y: 8599470
    },
    {
      nanogenreId: 1313,
      x: 5280810,
      y: 9606810
    },
    {
      nanogenreId: 1314,
      x: -6325230,
      y: 8587110
    },
    {
      nanogenreId: 1315,
      x: 9022800,
      y: -7474710
    },
    {
      nanogenreId: 1316,
      x: 2641950,
      y: 12057180
    },
    {
      nanogenreId: 1317,
      x: 7551960,
      y: -5070690
    },
    {
      nanogenreId: 1318,
      x: 1177290,
      y: 5583630
    },
    {
      nanogenreId: 1319,
      x: 1412130,
      y: -13197390
    },
    {
      nanogenreId: 1320,
      x: 843570,
      y: -1912710
    },
    {
      nanogenreId: 1321,
      x: 7517970,
      y: 608730
    },
    {
      nanogenreId: 1322,
      x: 14723850,
      y: -812670
    },
    {
      nanogenreId: 1323,
      x: -1412130,
      y: -5463120
    },
    {
      nanogenreId: 1324,
      x: -6671310,
      y: 14006970
    },
    {
      nanogenreId: 1325,
      x: -3924300,
      y: -7239870
    },
    {
      nanogenreId: 1326,
      x: -4140600,
      y: 5818470
    },
    {
      nanogenreId: 1327,
      x: 2187720,
      y: 3488610
    },
    {
      nanogenreId: 1328,
      x: -11411370,
      y: -1980690
    },
    {
      nanogenreId: 1329,
      x: -6507540,
      y: -9838560
    },
    {
      nanogenreId: 1330,
      x: -10135200,
      y: 7524150
    },
    {
      nanogenreId: 1331,
      x: 2104290,
      y: 6791820
    },
    {
      nanogenreId: 1332,
      x: 10889160,
      y: 2648130
    },
    {
      nanogenreId: 1333,
      x: 3534960,
      y: -5521830
    },
    {
      nanogenreId: 1334,
      x: 1875630,
      y: -14303610
    },
    {
      nanogenreId: 1335,
      x: -12125160,
      y: -13719600
    },
    {
      nanogenreId: 1336,
      x: 2289690,
      y: -11130180
    },
    {
      nanogenreId: 1337,
      x: -2719200,
      y: 7944390
    },
    {
      nanogenreId: 1338,
      x: -2515260,
      y: 7904220
    },
    {
      nanogenreId: 1339,
      x: 8784870,
      y: -8485140
    },
    {
      nanogenreId: 1340,
      x: 6958680,
      y: -6278880
    },
    {
      nanogenreId: 1341,
      x: 8701440,
      y: -6810360
    },
    {
      nanogenreId: 1342,
      x: 1242180,
      y: 6247980
    },
    {
      nanogenreId: 1343,
      x: 7808430,
      y: 7406730
    },
    {
      nanogenreId: 1344,
      x: 6770190,
      y: 8105070
    },
    {
      nanogenreId: 1345,
      x: -3358830,
      y: -4783320
    },
    {
      nanogenreId: 1346,
      x: 6433380,
      y: -7731180
    },
    {
      nanogenreId: 1347,
      x: 7323300,
      y: 7598310
    },
    {
      nanogenreId: 1348,
      x: -4063350,
      y: -7765170
    },
    {
      nanogenreId: 1349,
      x: 2122830,
      y: -880650
    },
    {
      nanogenreId: 1350,
      x: 11201250,
      y: -8577840
    },
    {
      nanogenreId: 1351,
      x: -7771350,
      y: -7570500
    },
    {
      nanogenreId: 1352,
      x: -6566250,
      y: 7978380
    },
    {
      nanogenreId: 1353,
      x: -3448440,
      y: -14686770
    },
    {
      nanogenreId: 1354,
      x: -3426810,
      y: 2212440
    },
    {
      nanogenreId: 1355,
      x: -2329860,
      y: -8994990
    },
    {
      nanogenreId: 1356,
      x: 9579000,
      y: -8775600
    },
    {
      nanogenreId: 1357,
      x: 367710,
      y: -4106610
    },
    {
      nanogenreId: 1358,
      x: 2583240,
      y: -4928550
    },
    {
      nanogenreId: 1359,
      x: -9585180,
      y: -11451540
    },
    {
      nanogenreId: 1360,
      x: 17019720,
      y: -3278490
    },
    {
      nanogenreId: 1361,
      x: 10230990,
      y: -8169960
    },
    {
      nanogenreId: 1362,
      x: -5691780,
      y: -6133650
    },
    {
      nanogenreId: 1363,
      x: 7805340,
      y: -7870230
    },
    {
      nanogenreId: 1364,
      x: 13518750,
      y: 15749730
    },
    {
      nanogenreId: 1365,
      x: 794130,
      y: 7672470
    },
    {
      nanogenreId: 1366,
      x: -1554270,
      y: 5769030
    },
    {
      nanogenreId: 1367,
      x: 472770,
      y: -3173430
    },
    {
      nanogenreId: 1368,
      x: 6430290,
      y: 9668610
    },
    {
      nanogenreId: 1369,
      x: 9053700,
      y: -6442650
    },
    {
      nanogenreId: 1370,
      x: 4412520,
      y: 2688300
    },
    {
      nanogenreId: 1371,
      x: -5595990,
      y: -8129790
    },
    {
      nanogenreId: 1372,
      x: -7493250,
      y: 8757060
    },
    {
      nanogenreId: 1373,
      x: 6492090,
      y: 10410210
    },
    {
      nanogenreId: 1374,
      x: -2824260,
      y: 2821170
    },
    {
      nanogenreId: 1375,
      x: -5157210,
      y: -12749340
    },
    {
      nanogenreId: 1376,
      x: -8630370,
      y: -1782930
    },
    {
      nanogenreId: 1377,
      x: -1810740,
      y: 6686760
    },
    {
      nanogenreId: 1378,
      x: 6974130,
      y: 9341070
    },
    {
      nanogenreId: 1379,
      x: -8939370,
      y: 7975290
    },
    {
      nanogenreId: 1380,
      x: 4162230,
      y: 11259960
    },
    {
      nanogenreId: 1381,
      x: -2935500,
      y: 6776370
    },
    {
      nanogenreId: 1382,
      x: 18428760,
      y: -1291620
    },
    {
      nanogenreId: 1383,
      x: 4455780,
      y: 10558530
    },
    {
      nanogenreId: 1384,
      x: 685980,
      y: 11034390
    },
    {
      nanogenreId: 1385,
      x: 6065670,
      y: 10552350
    },
    {
      nanogenreId: 1386,
      x: -9050610,
      y: 6322140
    },
    {
      nanogenreId: 1387,
      x: -4520670,
      y: 10796460
    },
    {
      nanogenreId: 1388,
      x: 15060660,
      y: 5506380
    },
    {
      nanogenreId: 1389,
      x: 1563540,
      y: -5117040
    },
    {
      nanogenreId: 1390,
      x: 837390,
      y: 8101980
    },
    {
      nanogenreId: 1391,
      x: -1359600,
      y: 6467370
    },
    {
      nanogenreId: 1392,
      x: -14742390,
      y: -3633840
    },
    {
      nanogenreId: 1393,
      x: 6087300,
      y: 9439950
    },
    {
      nanogenreId: 1394,
      x: 3065280,
      y: 7678650
    },
    {
      nanogenreId: 1395,
      x: 8509860,
      y: -8930100
    },
    {
      nanogenreId: 1396,
      x: 3386640,
      y: 9569730
    },
    {
      nanogenreId: 1397,
      x: -5824650,
      y: -11377380
    },
    {
      nanogenreId: 1398,
      x: -16392450,
      y: 1974510
    },
    {
      nanogenreId: 1399,
      x: -8089620,
      y: 1464660
    },
    {
      nanogenreId: 1400,
      x: -6714570,
      y: 5688690
    },
    {
      nanogenreId: 1401,
      x: 6445740,
      y: 10654320
    },
    {
      nanogenreId: 1402,
      x: -5781390,
      y: 7641570
    },
    {
      nanogenreId: 1403,
      x: -11000400,
      y: 991890
    },
    {
      nanogenreId: 1404,
      x: 7276950,
      y: 3568950
    },
    {
      nanogenreId: 1405,
      x: -2067210,
      y: 11429910
    },
    {
      nanogenreId: 1406,
      x: 3794520,
      y: 11646210
    },
    {
      nanogenreId: 1407,
      x: 2490540,
      y: 9483210
    },
    {
      nanogenreId: 1408,
      x: -4378530,
      y: 4977990
    },
    {
      nanogenreId: 1409,
      x: 3726540,
      y: 9409050
    },
    {
      nanogenreId: 1410,
      x: -9545010,
      y: 5639250
    },
    {
      nanogenreId: 1411,
      x: 14183100,
      y: -5741220
    },
    {
      nanogenreId: 1412,
      x: -10098120,
      y: 1134030
    },
    {
      nanogenreId: 1413,
      x: 4706070,
      y: -6578610
    },
    {
      nanogenreId: 1414,
      x: 5138670,
      y: -126690
    },
    {
      nanogenreId: 1415,
      x: -259560,
      y: 10799550
    },
    {
      nanogenreId: 1416,
      x: 488220,
      y: 2274240
    },
    {
      nanogenreId: 1417,
      x: -1622250,
      y: 216300
    },
    {
      nanogenreId: 1418,
      x: 7786800,
      y: -8698350
    },
    {
      nanogenreId: 1419,
      x: -1582080,
      y: -7026660
    },
    {
      nanogenreId: 1420,
      x: -2286600,
      y: 3636930
    },
    {
      nanogenreId: 1421,
      x: -1127850,
      y: 7805340
    },
    {
      nanogenreId: 1422,
      x: 11485530,
      y: -2645040
    },
    {
      nanogenreId: 1423,
      x: -2132100,
      y: -12360
    },
    {
      nanogenreId: 1424,
      x: -10929330,
      y: 3327930
    },
    {
      nanogenreId: 1425,
      x: -8284290,
      y: 9696420
    },
    {
      nanogenreId: 1426,
      x: 6702210,
      y: -3102360
    },
    {
      nanogenreId: 1427,
      x: 6016230,
      y: 9365790
    },
    {
      nanogenreId: 1428,
      x: 5280810,
      y: -11556600
    },
    {
      nanogenreId: 1429,
      x: 7635390,
      y: 8883750
    },
    {
      nanogenreId: 1430,
      x: 8685990,
      y: -7456170
    },
    {
      nanogenreId: 1431,
      x: 7672470,
      y: -6167640
    },
    {
      nanogenreId: 1432,
      x: -17232930,
      y: -3179610
    },
    {
      nanogenreId: 1433,
      x: 8840490,
      y: -7527240
    },
    {
      nanogenreId: 1434,
      x: 3090000,
      y: -10144470
    },
    {
      nanogenreId: 1435,
      x: 4517580,
      y: -2555430
    },
    {
      nanogenreId: 1436,
      x: 5302440,
      y: 4838940
    },
    {
      nanogenreId: 1437,
      x: 4492860,
      y: 7125540
    },
    {
      nanogenreId: 1438,
      x: -2098110,
      y: 5861730
    },
    {
      nanogenreId: 1439,
      x: 10886070,
      y: -6516810
    },
    {
      nanogenreId: 1440,
      x: 5265360,
      y: 9965250
    },
    {
      nanogenreId: 1441,
      x: -639630,
      y: -5030520
    },
    {
      nanogenreId: 1442,
      x: 8241030,
      y: 9427590
    },
    {
      nanogenreId: 1443,
      x: 2447280,
      y: 7292400
    },
    {
      nanogenreId: 1444,
      x: -1007340,
      y: 7029750
    },
    {
      nanogenreId: 1445,
      x: -2237160,
      y: 9103140
    },
    {
      nanogenreId: 1446,
      x: 8083440,
      y: -9168030
    },
    {
      nanogenreId: 1447,
      x: 4894560,
      y: -2166090
    },
    {
      nanogenreId: 1448,
      x: 5209740,
      y: 9881820
    },
    {
      nanogenreId: 1449,
      x: -4162230,
      y: 2533800
    },
    {
      nanogenreId: 1450,
      x: 1418310,
      y: -4804950
    },
    {
      nanogenreId: 1451,
      x: 6201630,
      y: 10827360
    },
    {
      nanogenreId: 1452,
      x: -5141760,
      y: 1826190
    },
    {
      nanogenreId: 1453,
      x: -8865210,
      y: 10061040
    },
    {
      nanogenreId: 1454,
      x: 10839720,
      y: 234840
    },
    {
      nanogenreId: 1455,
      x: -5589810,
      y: 4020090
    },
    {
      nanogenreId: 1456,
      x: 9693330,
      y: -7502520
    },
    {
      nanogenreId: 1457,
      x: 2861340,
      y: 10249530
    },
    {
      nanogenreId: 1458,
      x: 1433760,
      y: 8784870
    },
    {
      nanogenreId: 1459,
      x: 1319430,
      y: -188490
    },
    {
      nanogenreId: 1460,
      x: -1752030,
      y: -2082660
    },
    {
      nanogenreId: 1461,
      x: 5602170,
      y: 9022800
    },
    {
      nanogenreId: 1462,
      x: -2221710,
      y: -8899200
    },
    {
      nanogenreId: 1463,
      x: -10629600,
      y: -7026660
    },
    {
      nanogenreId: 1464,
      x: 2505990,
      y: -8278110
    },
    {
      nanogenreId: 1465,
      x: 5055240,
      y: 7437630
    },
    {
      nanogenreId: 1466,
      x: -9025890,
      y: -8936280
    },
    {
      nanogenreId: 1467,
      x: 1650060,
      y: 9705690
    },
    {
      nanogenreId: 1468,
      x: 2487450,
      y: 8821950
    },
    {
      nanogenreId: 1469,
      x: 10227900,
      y: -7261500
    },
    {
      nanogenreId: 1470,
      x: -5342610,
      y: -3278490
    },
    {
      nanogenreId: 1471,
      x: -500580,
      y: 12452700
    },
    {
      nanogenreId: 1472,
      x: 5320980,
      y: -49440
    },
    {
      nanogenreId: 1473,
      x: -5055240,
      y: -6340680
    },
    {
      nanogenreId: 1474,
      x: 14467380,
      y: -5265360
    },
    {
      nanogenreId: 1475,
      x: -15431460,
      y: -2997300
    },
    {
      nanogenreId: 1476,
      x: 4162230,
      y: 14869080
    },
    {
      nanogenreId: 1477,
      x: -14733120,
      y: 1344150
    },
    {
      nanogenreId: 1478,
      x: -9078420,
      y: -2839710
    },
    {
      nanogenreId: 1479,
      x: -3176520,
      y: 8957910
    },
    {
      nanogenreId: 1480,
      x: 741600,
      y: 8478960
    },
    {
      nanogenreId: 1481,
      x: -6371580,
      y: 4044810
    },
    {
      nanogenreId: 1482,
      x: 6013140,
      y: 9310170
    },
    {
      nanogenreId: 1483,
      x: -4777140,
      y: -6856710
    },
    {
      nanogenreId: 1484,
      x: -6192360,
      y: -11065290
    },
    {
      nanogenreId: 1485,
      x: -7672470,
      y: 5765940
    },
    {
      nanogenreId: 1486,
      x: -1517190,
      y: -5728860
    },
    {
      nanogenreId: 1487,
      x: 4109700,
      y: -5358060
    },
    {
      nanogenreId: 1488,
      x: 5803020,
      y: 10536900
    },
    {
      nanogenreId: 1489,
      x: 4292010,
      y: 8645820
    },
    {
      nanogenreId: 1490,
      x: 5203560,
      y: -9137130
    },
    {
      nanogenreId: 1491,
      x: 7885680,
      y: 7076100
    },
    {
      nanogenreId: 1492,
      x: -7604490,
      y: -8821950
    },
    {
      nanogenreId: 1493,
      x: 4641180,
      y: 6102750
    },
    {
      nanogenreId: 1494,
      x: 2978760,
      y: 11445360
    },
    {
      nanogenreId: 1495,
      x: 4292010,
      y: -3677100
    },
    {
      nanogenreId: 1496,
      x: -7100820,
      y: 506760
    },
    {
      nanogenreId: 1497,
      x: 2672850,
      y: 1452300
    },
    {
      nanogenreId: 1498,
      x: 2082660,
      y: 89610
    },
    {
      nanogenreId: 1499,
      x: 9251460,
      y: 17656260
    },
    {
      nanogenreId: 1500,
      x: -4347630,
      y: 6312870
    },
    {
      nanogenreId: 1501,
      x: -327540,
      y: 8846670
    },
    {
      nanogenreId: 1502,
      x: -2796450,
      y: -8472780
    },
    {
      nanogenreId: 1503,
      x: -1291620,
      y: 3655470
    },
    {
      nanogenreId: 1504,
      x: -7150260,
      y: 7561230
    },
    {
      nanogenreId: 1505,
      x: 7678650,
      y: -6346860
    },
    {
      nanogenreId: 1506,
      x: 3479340,
      y: -13234470
    },
    {
      nanogenreId: 1507,
      x: 6995760,
      y: 188490
    },
    {
      nanogenreId: 1508,
      x: -19643130,
      y: 4372350
    },
    {
      nanogenreId: 1509,
      x: -9857100,
      y: 8080350
    },
    {
      nanogenreId: 1510,
      x: -8580930,
      y: 6578610
    },
    {
      nanogenreId: 1511,
      x: -11670930,
      y: 9538830
    },
    {
      nanogenreId: 1512,
      x: 4712250,
      y: 6794910
    },
    {
      nanogenreId: 1513,
      x: -6275790,
      y: 2691390
    },
    {
      nanogenreId: 1514,
      x: -8899200,
      y: 4767870
    },
    {
      nanogenreId: 1515,
      x: -2583240,
      y: 2870610
    },
    {
      nanogenreId: 1516,
      x: 389340,
      y: -9523380
    },
    {
      nanogenreId: 1517,
      x: 395520,
      y: -6587880
    },
    {
      nanogenreId: 1518,
      x: -9504840,
      y: -12743160
    },
    {
      nanogenreId: 1519,
      x: 3587490,
      y: 9875640
    },
    {
      nanogenreId: 1520,
      x: 10246440,
      y: -9517200
    },
    {
      nanogenreId: 1521,
      x: 4122060,
      y: 10252620
    },
    {
      nanogenreId: 1522,
      x: 2234070,
      y: -12134430
    },
    {
      nanogenreId: 1523,
      x: 4993440,
      y: 9341070
    },
    {
      nanogenreId: 1524,
      x: -4224030,
      y: -3955200
    },
    {
      nanogenreId: 1525,
      x: -8071080,
      y: 1758210
    },
    {
      nanogenreId: 1526,
      x: 3874860,
      y: 9909630
    },
    {
      nanogenreId: 1527,
      x: 7672470,
      y: -9467760
    },
    {
      nanogenreId: 1528,
      x: -7140990,
      y: -265740
    },
    {
      nanogenreId: 1529,
      x: 5904990,
      y: -2777910
    },
    {
      nanogenreId: 1530,
      x: 5546550,
      y: 6878340
    },
    {
      nanogenreId: 1531,
      x: 3176520,
      y: 4851300
    },
    {
      nanogenreId: 1532,
      x: 5342610,
      y: 9752040
    },
    {
      nanogenreId: 1533,
      x: -3837780,
      y: 8611830
    },
    {
      nanogenreId: 1534,
      x: -4171500,
      y: 7140990
    },
    {
      nanogenreId: 1535,
      x: 1470840,
      y: 4872930
    },
    {
      nanogenreId: 1536,
      x: 5067600,
      y: -4529940
    },
    {
      nanogenreId: 1537,
      x: 9650070,
      y: -11093100
    },
    {
      nanogenreId: 1538,
      x: 5663970,
      y: 10580160
    },
    {
      nanogenreId: 1539,
      x: 5280810,
      y: 10855170
    },
    {
      nanogenreId: 1540,
      x: 1690230,
      y: 9857100
    },
    {
      nanogenreId: 1541,
      x: -3544230,
      y: -2138280
    },
    {
      nanogenreId: 1542,
      x: 6937050,
      y: -3822330
    },
    {
      nanogenreId: 1543,
      x: -7752810,
      y: 6662040
    },
    {
      nanogenreId: 1544,
      x: 4622640,
      y: -6909240
    },
    {
      nanogenreId: 1545,
      x: -698340,
      y: -10302060
    },
    {
      nanogenreId: 1546,
      x: 9659340,
      y: -6624960
    },
    {
      nanogenreId: 1547,
      x: 10122840,
      y: -5079960
    },
    {
      nanogenreId: 1548,
      x: 194670,
      y: 10753200
    },
    {
      nanogenreId: 1549,
      x: -1690230,
      y: -2805720
    },
    {
      nanogenreId: 1550,
      x: -142140,
      y: 1612980
    },
    {
      nanogenreId: 1551,
      x: -6461190,
      y: 5725770
    },
    {
      nanogenreId: 1552,
      x: -16509870,
      y: -4869840
    },
    {
      nanogenreId: 1553,
      x: -9739680,
      y: 10181550
    },
    {
      nanogenreId: 1554,
      x: -9520290,
      y: -10091940
    },
    {
      nanogenreId: 1555,
      x: 11275410,
      y: 9773670
    },
    {
      nanogenreId: 1556,
      x: 8868300,
      y: -4678260
    },
    {
      nanogenreId: 1557,
      x: 6856710,
      y: 8843580
    },
    {
      nanogenreId: 1558,
      x: 14322150,
      y: 6801090
    },
    {
      nanogenreId: 1559,
      x: -9137130,
      y: 1989960
    },
    {
      nanogenreId: 1560,
      x: -6365400,
      y: -5898810
    },
    {
      nanogenreId: 1561,
      x: -5976060,
      y: -676710
    },
    {
      nanogenreId: 1562,
      x: 8713800,
      y: -8244120
    },
    {
      nanogenreId: 1563,
      x: -8896110,
      y: 12007740
    },
    {
      nanogenreId: 1564,
      x: -3568950,
      y: -8429520
    },
    {
      nanogenreId: 1565,
      x: 5240640,
      y: -420240
    },
    {
      nanogenreId: 1566,
      x: -3108540,
      y: -8101980
    },
    {
      nanogenreId: 1567,
      x: 16806510,
      y: -1384320
    },
    {
      nanogenreId: 1568,
      x: 6872160,
      y: 7749720
    },
    {
      nanogenreId: 1569,
      x: 9736590,
      y: -2957130
    },
    {
      nanogenreId: 1570,
      x: -9368880,
      y: 1140210
    },
    {
      nanogenreId: 1571,
      x: 5225190,
      y: 9563550
    },
    {
      nanogenreId: 1572,
      x: 9415230,
      y: -2308230
    },
    {
      nanogenreId: 1573,
      x: 787950,
      y: 8089620
    },
    {
      nanogenreId: 1574,
      x: -2950950,
      y: 4236390
    },
    {
      nanogenreId: 1575,
      x: -24720,
      y: 10487460
    },
    {
      nanogenreId: 1576,
      x: -1059870,
      y: 9356520
    },
    {
      nanogenreId: 1577,
      x: 6671310,
      y: 9486300
    },
    {
      nanogenreId: 1578,
      x: 4740060,
      y: -5830830
    },
    {
      nanogenreId: 1579,
      x: 8806500,
      y: 3806880
    },
    {
      nanogenreId: 1580,
      x: -6967950,
      y: 8271930
    },
    {
      nanogenreId: 1581,
      x: 3596760,
      y: -9637710
    },
    {
      nanogenreId: 1582,
      x: -8006190,
      y: 6801090
    },
    {
      nanogenreId: 1583,
      x: 7935120,
      y: -9563550
    },
    {
      nanogenreId: 1584,
      x: -5342610,
      y: 3547320
    },
    {
      nanogenreId: 1585,
      x: 8154510,
      y: -7035930
    },
    {
      nanogenreId: 1586,
      x: 16182330,
      y: 13002720
    },
    {
      nanogenreId: 1587,
      x: -6838170,
      y: 1477020
    },
    {
      nanogenreId: 1588,
      x: 2280420,
      y: 10818090
    },
    {
      nanogenreId: 1589,
      x: -1192740,
      y: 6819630
    },
    {
      nanogenreId: 1590,
      x: 9640800,
      y: 18645060
    },
    {
      nanogenreId: 1591,
      x: -10981860,
      y: -12400170
    },
    {
      nanogenreId: 1592,
      x: 1056780,
      y: 9328710
    },
    {
      nanogenreId: 1593,
      x: -1483200,
      y: -4755510
    },
    {
      nanogenreId: 1594,
      x: 5234460,
      y: 10082670
    },
    {
      nanogenreId: 1595,
      x: 6995760,
      y: -5694870
    },
    {
      nanogenreId: 1596,
      x: 8444970,
      y: 1514100
    },
    {
      nanogenreId: 1597,
      x: 4699890,
      y: 10218630
    },
    {
      nanogenreId: 1598,
      x: -4032450,
      y: 9674790
    },
    {
      nanogenreId: 1599,
      x: -8559300,
      y: 4017000
    },
    {
      nanogenreId: 1600,
      x: 14665140,
      y: -3837780
    },
    {
      nanogenreId: 1601,
      x: -6146010,
      y: -392430
    },
    {
      nanogenreId: 1602,
      x: 2870610,
      y: 9350340
    },
    {
      nanogenreId: 1603,
      x: -5416770,
      y: 3127080
    },
    {
      nanogenreId: 1604,
      x: -12326010,
      y: -8015460
    },
    {
      nanogenreId: 1605,
      x: -6285060,
      y: 3825420
    },
    {
      nanogenreId: 1606,
      x: 8166870,
      y: -6924690
    },
    {
      nanogenreId: 1607,
      x: 4156050,
      y: -4545390
    },
    {
      nanogenreId: 1608,
      x: 3457710,
      y: -14337600
    },
    {
      nanogenreId: 1609,
      x: 7799160,
      y: 1517190
    },
    {
      nanogenreId: 1610,
      x: -4570110,
      y: -4471230
    },
    {
      nanogenreId: 1611,
      x: 6016230,
      y: 10286610
    },
    {
      nanogenreId: 1612,
      x: 2969490,
      y: -7826970
    },
    {
      nanogenreId: 1613,
      x: 7860960,
      y: 2709930
    },
    {
      nanogenreId: 1614,
      x: 10954050,
      y: 5521830
    },
    {
      nanogenreId: 1615,
      x: -9313260,
      y: 5246820
    },
    {
      nanogenreId: 1616,
      x: 1239090,
      y: 1232910
    },
    {
      nanogenreId: 1617,
      x: 8825040,
      y: -8713800
    },
    {
      nanogenreId: 1618,
      x: 834300,
      y: 8407890
    },
    {
      nanogenreId: 1619,
      x: -333720,
      y: 18913890
    },
    {
      nanogenreId: 1620,
      x: 1545000,
      y: -6170730
    },
    {
      nanogenreId: 1621,
      x: 4452690,
      y: 4795680
    },
    {
      nanogenreId: 1622,
      x: -12174600,
      y: -10079580
    },
    {
      nanogenreId: 1623,
      x: -15208980,
      y: -5441490
    },
    {
      nanogenreId: 1624,
      x: -2994210,
      y: 2561610
    },
    {
      nanogenreId: 1625,
      x: -7783710,
      y: 11507160
    },
    {
      nanogenreId: 1626,
      x: 3995370,
      y: 9560460
    },
    {
      nanogenreId: 1627,
      x: 2391660,
      y: -2468910
    },
    {
      nanogenreId: 1628,
      x: 2991120,
      y: 9786030
    },
    {
      nanogenreId: 1629,
      x: -10626510,
      y: 179220
    },
    {
      nanogenreId: 1630,
      x: -5147940,
      y: 5076870
    },
    {
      nanogenreId: 1631,
      x: -10252620,
      y: 7097730
    },
    {
      nanogenreId: 1632,
      x: -7125540,
      y: 12910020
    },
    {
      nanogenreId: 1633,
      x: 1844730,
      y: 3470070
    },
    {
      nanogenreId: 1634,
      x: -13487850,
      y: -1198920
    },
    {
      nanogenreId: 1635,
      x: -1016610,
      y: 6087300
    },
    {
      nanogenreId: 1636,
      x: -2533800,
      y: 16719990
    },
    {
      nanogenreId: 1637,
      x: 5049060,
      y: 11624580
    },
    {
      nanogenreId: 1638,
      x: 5589810,
      y: 11219790
    },
    {
      nanogenreId: 1639,
      x: -3686370,
      y: -713790
    },
    {
      nanogenreId: 1640,
      x: -3989190,
      y: 4347630
    },
    {
      nanogenreId: 1641,
      x: 6315960,
      y: -10354590
    },
    {
      nanogenreId: 1642,
      x: 203940,
      y: -8905380
    },
    {
      nanogenreId: 1643,
      x: -948630,
      y: -12536130
    },
    {
      nanogenreId: 1644,
      x: -7524150,
      y: 3071460
    },
    {
      nanogenreId: 1645,
      x: -13079970,
      y: 12724620
    },
    {
      nanogenreId: 1646,
      x: 6708390,
      y: -7171890
    },
    {
      nanogenreId: 1647,
      x: 4100430,
      y: -11300130
    },
    {
      nanogenreId: 1648,
      x: -5963700,
      y: -7904220
    },
    {
      nanogenreId: 1649,
      x: -2400930,
      y: -7765170
    },
    {
      nanogenreId: 1650,
      x: 6000780,
      y: 9582090
    },
    {
      nanogenreId: 1651,
      x: -10734660,
      y: 8806500
    },
    {
      nanogenreId: 1652,
      x: -618000,
      y: -12702990
    },
    {
      nanogenreId: 1653,
      x: 12548490,
      y: -7938210
    },
    {
      nanogenreId: 1654,
      x: 3927390,
      y: -3633840
    },
    {
      nanogenreId: 1655,
      x: 5163390,
      y: -8550030
    },
    {
      nanogenreId: 1656,
      x: 4795680,
      y: 10184640
    },
    {
      nanogenreId: 1657,
      x: -10910790,
      y: 2410200
    },
    {
      nanogenreId: 1658,
      x: -1829280,
      y: 5123220
    },
    {
      nanogenreId: 1659,
      x: -4143690,
      y: 5021250
    },
    {
      nanogenreId: 1660,
      x: 6291240,
      y: 10104300
    },
    {
      nanogenreId: 1661,
      x: -244110,
      y: 6068760
    },
    {
      nanogenreId: 1662,
      x: 6195450,
      y: -1532640
    },
    {
      nanogenreId: 1663,
      x: -15826980,
      y: 1211280
    },
    {
      nanogenreId: 1664,
      x: -3519510,
      y: 6621870
    },
    {
      nanogenreId: 1665,
      x: -3723450,
      y: 8225580
    },
    {
      nanogenreId: 1666,
      x: 6532260,
      y: 1217460
    },
    {
      nanogenreId: 1667,
      x: 8346090,
      y: -8550030
    },
    {
      nanogenreId: 1668,
      x: -15122460,
      y: 7005030
    },
    {
      nanogenreId: 1669,
      x: 1789110,
      y: 5370420
    },
    {
      nanogenreId: 1670,
      x: 5086140,
      y: 10249530
    },
    {
      nanogenreId: 1671,
      x: -6819630,
      y: 6037860
    },
    {
      nanogenreId: 1672,
      x: 3297030,
      y: -4084980
    },
    {
      nanogenreId: 1673,
      x: 4316730,
      y: 9486300
    },
    {
      nanogenreId: 1674,
      x: -14751660,
      y: 7409820
    },
    {
      nanogenreId: 1675,
      x: -9028980,
      y: 410970
    },
    {
      nanogenreId: 1676,
      x: -1313250,
      y: -5203560
    },
    {
      nanogenreId: 1677,
      x: -865200,
      y: 5197380
    },
    {
      nanogenreId: 1678,
      x: -6550800,
      y: -11541150
    },
    {
      nanogenreId: 1679,
      x: -469680,
      y: 9674790
    },
    {
      nanogenreId: 1680,
      x: 9115500,
      y: -9035160
    },
    {
      nanogenreId: 1681,
      x: 4397070,
      y: 9415230
    },
    {
      nanogenreId: 1682,
      x: -2864430,
      y: -3161070
    },
    {
      nanogenreId: 1683,
      x: -2419470,
      y: -2317500
    },
    {
      nanogenreId: 1684,
      x: -3093090,
      y: 16979550
    },
    {
      nanogenreId: 1685,
      x: -13018170,
      y: -6442650
    },
    {
      nanogenreId: 1686,
      x: -8160690,
      y: 2064120
    },
    {
      nanogenreId: 1687,
      x: -5410590,
      y: -4295100
    },
    {
      nanogenreId: 1688,
      x: -16030920,
      y: 4511400
    },
    {
      nanogenreId: 1689,
      x: 6798000,
      y: 8247210
    },
    {
      nanogenreId: 1690,
      x: 7406730,
      y: -8203950
    },
    {
      nanogenreId: 1691,
      x: -577830,
      y: -14791830
    },
    {
      nanogenreId: 1692,
      x: -8126700,
      y: 3837780
    },
    {
      nanogenreId: 1693,
      x: 1066050,
      y: -5586720
    },
    {
      nanogenreId: 1694,
      x: -3331020,
      y: 9894180
    },
    {
      nanogenreId: 1695,
      x: 3219780,
      y: 9140220
    },
    {
      nanogenreId: 1696,
      x: 4761690,
      y: 9297810
    },
    {
      nanogenreId: 1697,
      x: 5595990,
      y: -8472780
    },
    {
      nanogenreId: 1698,
      x: 61800,
      y: 6501360
    },
    {
      nanogenreId: 1699,
      x: -621090,
      y: 3785250
    },
    {
      nanogenreId: 1700,
      x: 4783320,
      y: 9928170
    },
    {
      nanogenreId: 1701,
      x: -6628050,
      y: 509850
    },
    {
      nanogenreId: 1702,
      x: -4672080,
      y: -1328700
    },
    {
      nanogenreId: 1703,
      x: -4038630,
      y: -10264980
    },
    {
      nanogenreId: 1704,
      x: 5512560,
      y: 10175370
    },
    {
      nanogenreId: 1705,
      x: 7721910,
      y: -7734270
    },
    {
      nanogenreId: 1706,
      x: -4458870,
      y: -9705690
    },
    {
      nanogenreId: 1707,
      x: -911550,
      y: 1291620
    },
    {
      nanogenreId: 1708,
      x: -8284290,
      y: 7011210
    },
    {
      nanogenreId: 1709,
      x: -2663580,
      y: 769410
    },
    {
      nanogenreId: 1710,
      x: 6013140,
      y: 10366950
    },
    {
      nanogenreId: 1711,
      x: -12758610,
      y: -6424110
    },
    {
      nanogenreId: 1712,
      x: -4480500,
      y: 2193900
    },
    {
      nanogenreId: 1713,
      x: 6529170,
      y: -3905760
    },
    {
      nanogenreId: 1714,
      x: 6220170,
      y: 10363860
    },
    {
      nanogenreId: 1715,
      x: -8905380,
      y: 7221330
    },
    {
      nanogenreId: 1716,
      x: 5385870,
      y: -9798390
    },
    {
      nanogenreId: 1717,
      x: 1439940,
      y: 3636930
    },
    {
      nanogenreId: 1718,
      x: 4125150,
      y: 5342610
    },
    {
      nanogenreId: 1719,
      x: 9427590,
      y: -7962930
    },
    {
      nanogenreId: 1720,
      x: 222480,
      y: 11507160
    },
    {
      nanogenreId: 1721,
      x: -1714950,
      y: 6223260
    },
    {
      nanogenreId: 1722,
      x: 6223260,
      y: -7388190
    },
    {
      nanogenreId: 1723,
      x: -964080,
      y: 3201240
    },
    {
      nanogenreId: 1724,
      x: -15450,
      y: 8352270
    },
    {
      nanogenreId: 1725,
      x: -3686370,
      y: 9001170
    },
    {
      nanogenreId: 1726,
      x: 5021250,
      y: 10506000
    },
    {
      nanogenreId: 1727,
      x: 6108930,
      y: 6720750
    },
    {
      nanogenreId: 1728,
      x: 10289700,
      y: -7527240
    },
    {
      nanogenreId: 1729,
      x: 8988810,
      y: -7039020
    },
    {
      nanogenreId: 1730,
      x: -7270770,
      y: -2586330
    },
    {
      nanogenreId: 1731,
      x: 2329860,
      y: 370800
    },
    {
      nanogenreId: 1732,
      x: 1989960,
      y: 9949800
    },
    {
      nanogenreId: 1733,
      x: 8880660,
      y: -5494020
    },
    {
      nanogenreId: 1734,
      x: 9949800,
      y: -4644270
    },
    {
      nanogenreId: 1735,
      x: 5673240,
      y: -2416380
    },
    {
      nanogenreId: 1736,
      x: -2292780,
      y: 9727320
    },
    {
      nanogenreId: 1737,
      x: -5395140,
      y: 4338360
    },
    {
      nanogenreId: 1738,
      x: -383160,
      y: -11522610
    },
    {
      nanogenreId: 1739,
      x: 3389730,
      y: 3182700
    },
    {
      nanogenreId: 1740,
      x: 1826190,
      y: -6390120
    },
    {
      nanogenreId: 1741,
      x: 3074550,
      y: 290460
    },
    {
      nanogenreId: 1742,
      x: 5799930,
      y: 9507930
    },
    {
      nanogenreId: 1743,
      x: 3022020,
      y: -9659340
    },
    {
      nanogenreId: 1744,
      x: 957900,
      y: 4740060
    },
    {
      nanogenreId: 1745,
      x: 4517580,
      y: 8534580
    },
    {
      nanogenreId: 1746,
      x: -148320,
      y: 16160700
    },
    {
      nanogenreId: 1747,
      x: 3408270,
      y: 10030140
    },
    {
      nanogenreId: 1748,
      x: 9331800,
      y: -8176140
    },
    {
      nanogenreId: 1749,
      x: -1925070,
      y: -11216700
    },
    {
      nanogenreId: 1750,
      x: 3609120,
      y: -6779460
    },
    {
      nanogenreId: 1751,
      x: -6192360,
      y: 10212450
    },
    {
      nanogenreId: 1752,
      x: 3751260,
      y: -6226350
    },
    {
      nanogenreId: 1753,
      x: 5960610,
      y: 3139440
    },
    {
      nanogenreId: 1754,
      x: -5172660,
      y: 5827740
    },
    {
      nanogenreId: 1755,
      x: 2122830,
      y: 8917740
    },
    {
      nanogenreId: 1756,
      x: -2889150,
      y: -4159140
    },
    {
      nanogenreId: 1757,
      x: 2138280,
      y: 8719980
    },
    {
      nanogenreId: 1758,
      x: -3936660,
      y: -7694100
    },
    {
      nanogenreId: 1759,
      x: 8778690,
      y: -7273860
    },
    {
      nanogenreId: 1760,
      x: -2345310,
      y: 5735040
    },
    {
      nanogenreId: 1761,
      x: 4740060,
      y: 15465450
    },
    {
      nanogenreId: 1762,
      x: 5629980,
      y: 902280
    },
    {
      nanogenreId: 1763,
      x: -13132500,
      y: -5194290
    },
    {
      nanogenreId: 1764,
      x: 3269220,
      y: -4604100
    },
    {
      nanogenreId: 1765,
      x: 4452690,
      y: -2419470
    },
    {
      nanogenreId: 1766,
      x: 627270,
      y: 8336820
    },
    {
      nanogenreId: 1767,
      x: 10709940,
      y: -6445740
    },
    {
      nanogenreId: 1768,
      x: 7301670,
      y: -6152190
    },
    {
      nanogenreId: 1769,
      x: -2601780,
      y: 4897650
    },
    {
      nanogenreId: 1770,
      x: 2765550,
      y: 9523380
    },
    {
      nanogenreId: 1771,
      x: 967170,
      y: -3732720
    },
    {
      nanogenreId: 1772,
      x: -13188120,
      y: -4857480
    },
    {
      nanogenreId: 1773,
      x: -3905760,
      y: 1294710
    },
    {
      nanogenreId: 1774,
      x: 3615300,
      y: -3059100
    },
    {
      nanogenreId: 1775,
      x: -11751270,
      y: -7694100
    },
    {
      nanogenreId: 1776,
      x: 9804570,
      y: -9619170
    },
    {
      nanogenreId: 1777,
      x: 6084210,
      y: -8655090
    },
    {
      nanogenreId: 1778,
      x: -6297420,
      y: 1313250
    },
    {
      nanogenreId: 1779,
      x: -8030910,
      y: 4335270
    },
    {
      nanogenreId: 1780,
      x: 6476640,
      y: 9971430
    },
    {
      nanogenreId: 1781,
      x: 3037470,
      y: 3748170
    },
    {
      nanogenreId: 1782,
      x: -5388960,
      y: -11355750
    },
    {
      nanogenreId: 1783,
      x: -3225960,
      y: 7246050
    },
    {
      nanogenreId: 1784,
      x: -1693320,
      y: 7190430
    },
    {
      nanogenreId: 1785,
      x: 1443030,
      y: -12273480
    },
    {
      nanogenreId: 1786,
      x: 3259950,
      y: 5500200
    },
    {
      nanogenreId: 1787,
      x: -1390500,
      y: -228660
    },
    {
      nanogenreId: 1788,
      x: -1303980,
      y: -9130950
    },
    {
      nanogenreId: 1789,
      x: 10051770,
      y: -7721910
    },
    {
      nanogenreId: 1790,
      x: 4641180,
      y: -2722290
    },
    {
      nanogenreId: 1791,
      x: 7706460,
      y: -7255320
    },
    {
      nanogenreId: 1792,
      x: -2061030,
      y: -7610670
    },
    {
      nanogenreId: 1793,
      x: -738510,
      y: 6201630
    },
    {
      nanogenreId: 1794,
      x: -98880,
      y: 5401320
    },
    {
      nanogenreId: 1795,
      x: 9520290,
      y: -8287380
    },
    {
      nanogenreId: 1796,
      x: -11167260,
      y: 12551580
    },
    {
      nanogenreId: 1797,
      x: -1532640,
      y: -6550800
    },
    {
      nanogenreId: 1798,
      x: 5744310,
      y: 10793370
    },
    {
      nanogenreId: 1799,
      x: -5799930,
      y: -4165320
    },
    {
      nanogenreId: 1800,
      x: 3417540,
      y: 17600640
    },
    {
      nanogenreId: 1801,
      x: -2456550,
      y: -9634620
    },
    {
      nanogenreId: 1802,
      x: 2889150,
      y: 9730410
    },
    {
      nanogenreId: 1803,
      x: -2147550,
      y: 10061040
    },
    {
      nanogenreId: 1804,
      x: 15511800,
      y: -4134420
    },
    {
      nanogenreId: 1805,
      x: 2774820,
      y: -1563540
    },
    {
      nanogenreId: 1806,
      x: -5562000,
      y: 7357290
    },
    {
      nanogenreId: 1807,
      x: -6362310,
      y: 6176910
    },
    {
      nanogenreId: 1808,
      x: -3463890,
      y: 13045980
    },
    {
      nanogenreId: 1809,
      x: 1850910,
      y: 7910400
    },
    {
      nanogenreId: 1810,
      x: 4400160,
      y: 9004260
    },
    {
      nanogenreId: 1811,
      x: 8126700,
      y: -10468920
    },
    {
      nanogenreId: 1812,
      x: 1032060,
      y: -13268460
    },
    {
      nanogenreId: 1813,
      x: -3544230,
      y: 4687530
    },
    {
      nanogenreId: 1814,
      x: -2660490,
      y: 469680
    },
    {
      nanogenreId: 1815,
      x: 6875250,
      y: 1653150
    },
    {
      nanogenreId: 1816,
      x: 7348020,
      y: -8052540
    },
    {
      nanogenreId: 1817,
      x: -3717270,
      y: -13330260
    },
    {
      nanogenreId: 1818,
      x: -1659330,
      y: 6526080
    },
    {
      nanogenreId: 1819,
      x: 3188880,
      y: 9798390
    },
    {
      nanogenreId: 1820,
      x: -3661650,
      y: 2784090
    },
    {
      nanogenreId: 1821,
      x: -5735040,
      y: 2011590
    },
    {
      nanogenreId: 1822,
      x: 6396300,
      y: 2573970
    },
    {
      nanogenreId: 1823,
      x: -642720,
      y: 7252230
    },
    {
      nanogenreId: 1824,
      x: -3590580,
      y: 3939750
    },
    {
      nanogenreId: 1825,
      x: -5895720,
      y: -9455400
    },
    {
      nanogenreId: 1826,
      x: 4736970,
      y: 9066060
    },
    {
      nanogenreId: 1827,
      x: 5901900,
      y: 8488230
    },
    {
      nanogenreId: 1828,
      x: -4295100,
      y: 2549250
    },
    {
      nanogenreId: 1829,
      x: -3068370,
      y: -9566640
    },
    {
      nanogenreId: 1830,
      x: 3596760,
      y: 9217470
    },
    {
      nanogenreId: 1831,
      x: -17455410,
      y: -1483200
    },
    {
      nanogenreId: 1832,
      x: -2490540,
      y: 4533030
    },
    {
      nanogenreId: 1833,
      x: -6955590,
      y: 5778300
    },
    {
      nanogenreId: 1834,
      x: -7153350,
      y: -4146780
    },
    {
      nanogenreId: 1835,
      x: 488220,
      y: 2144460
    },
    {
      nanogenreId: 1836,
      x: 596370,
      y: -1226730
    },
    {
      nanogenreId: 1837,
      x: 11056020,
      y: -6504450
    },
    {
      nanogenreId: 1838,
      x: -6662040,
      y: 11534970
    },
    {
      nanogenreId: 1839,
      x: -3142530,
      y: 9693330
    },
    {
      nanogenreId: 1840,
      x: 1124760,
      y: -6893790
    },
    {
      nanogenreId: 1841,
      x: 6278880,
      y: -4764780
    },
    {
      nanogenreId: 1842,
      x: -8568570,
      y: -4162230
    },
    {
      nanogenreId: 1843,
      x: 5546550,
      y: 8621100
    },
    {
      nanogenreId: 1844,
      x: 3964470,
      y: 10444200
    },
    {
      nanogenreId: 1845,
      x: -3059100,
      y: -7193520
    },
    {
      nanogenreId: 1846,
      x: -3856320,
      y: -5027430
    },
    {
      nanogenreId: 1847,
      x: 4641180,
      y: -1514100
    },
    {
      nanogenreId: 1848,
      x: 6065670,
      y: 8472780
    },
    {
      nanogenreId: 1849,
      x: -574740,
      y: 9517200
    },
    {
      nanogenreId: 1850,
      x: 1563540,
      y: -3238320
    },
    {
      nanogenreId: 1851,
      x: 9044430,
      y: -9161850
    },
    {
      nanogenreId: 1852,
      x: -6238710,
      y: -4017000
    },
    {
      nanogenreId: 1853,
      x: -6816540,
      y: -318270
    },
    {
      nanogenreId: 1854,
      x: 1455390,
      y: 6340680
    },
    {
      nanogenreId: 1855,
      x: -7060650,
      y: 6825810
    },
    {
      nanogenreId: 1856,
      x: 15876420,
      y: 3822330
    },
    {
      nanogenreId: 1857,
      x: 3504060,
      y: -2614140
    },
    {
      nanogenreId: 1858,
      x: 11590590,
      y: 5762850
    },
    {
      nanogenreId: 1859,
      x: 1705680,
      y: -4001550
    },
    {
      nanogenreId: 1860,
      x: 11961390,
      y: 707610
    },
    {
      nanogenreId: 1861,
      x: 6068760,
      y: 10076490
    },
    {
      nanogenreId: 1862,
      x: -6504450,
      y: -11760540
    },
    {
      nanogenreId: 1863,
      x: 6547710,
      y: -2916960
    },
    {
      nanogenreId: 1864,
      x: 2283510,
      y: 8327550
    },
    {
      nanogenreId: 1865,
      x: 1134030,
      y: 15601410
    },
    {
      nanogenreId: 1866,
      x: -3837780,
      y: 2505990
    },
    {
      nanogenreId: 1867,
      x: -6402480,
      y: 1192740
    },
    {
      nanogenreId: 1868,
      x: 5617620,
      y: -1202010
    },
    {
      nanogenreId: 1869,
      x: 534570,
      y: 9554280
    },
    {
      nanogenreId: 1870,
      x: -9748950,
      y: 2348400
    },
    {
      nanogenreId: 1871,
      x: 4066440,
      y: -4109700
    },
    {
      nanogenreId: 1872,
      x: -12560850,
      y: -1767480
    },
    {
      nanogenreId: 1873,
      x: -3368100,
      y: 1563540
    },
    {
      nanogenreId: 1874,
      x: 342990,
      y: 7032840
    },
    {
      nanogenreId: 1875,
      x: 1677870,
      y: 12078810
    },
    {
      nanogenreId: 1876,
      x: -1384320,
      y: -1897260
    },
    {
      nanogenreId: 1877,
      x: 16386270,
      y: 12628830
    },
    {
      nanogenreId: 1878,
      x: 423330,
      y: 4947090
    },
    {
      nanogenreId: 1879,
      x: -14714580,
      y: -6189270
    },
    {
      nanogenreId: 1880,
      x: 7314030,
      y: -7456170
    },
    {
      nanogenreId: 1881,
      x: 3649290,
      y: -4928550
    },
    {
      nanogenreId: 1882,
      x: -3466980,
      y: -590190
    },
    {
      nanogenreId: 1883,
      x: 8574750,
      y: -7703370
    },
    {
      nanogenreId: 1884,
      x: 4591740,
      y: 6590970
    },
    {
      nanogenreId: 1885,
      x: 151410,
      y: -10163010
    },
    {
      nanogenreId: 1886,
      x: 2129010,
      y: 8633460
    },
    {
      nanogenreId: 1887,
      x: 1195830,
      y: 12350730
    },
    {
      nanogenreId: 1888,
      x: 3689460,
      y: -5138670
    },
    {
      nanogenreId: 1889,
      x: -5901900,
      y: 5833920
    },
    {
      nanogenreId: 1890,
      x: 1866360,
      y: 8404800
    },
    {
      nanogenreId: 1891,
      x: 5954430,
      y: 9174210
    },
    {
      nanogenreId: 1892,
      x: 1405950,
      y: 9498660
    },
    {
      nanogenreId: 1893,
      x: 24720,
      y: 10969500
    },
    {
      nanogenreId: 1894,
      x: 2370030,
      y: 9270000
    },
    {
      nanogenreId: 1895,
      x: 883740,
      y: -4761690
    },
    {
      nanogenreId: 1896,
      x: -4656630,
      y: 7632300
    },
    {
      nanogenreId: 1897,
      x: -7168800,
      y: -6995760
    },
    {
      nanogenreId: 1898,
      x: 4480500,
      y: 10781010
    },
    {
      nanogenreId: 1899,
      x: 4641180,
      y: 8271930
    },
    {
      nanogenreId: 1900,
      x: -3528780,
      y: 132870
    },
    {
      nanogenreId: 1901,
      x: 432600,
      y: 855930
    },
    {
      nanogenreId: 1902,
      x: -3306300,
      y: 7715730
    },
    {
      nanogenreId: 1903,
      x: -14726940,
      y: -6442650
    },
    {
      nanogenreId: 1904,
      x: 7956750,
      y: -10129020
    },
    {
      nanogenreId: 1905,
      x: -13296270,
      y: -6362310
    },
    {
      nanogenreId: 1906,
      x: -750870,
      y: -3655470
    },
    {
      nanogenreId: 1907,
      x: -5871000,
      y: 7314030
    },
    {
      nanogenreId: 1908,
      x: 6646590,
      y: 11201250
    },
    {
      nanogenreId: 1909,
      x: 2994210,
      y: 8834310
    },
    {
      nanogenreId: 1910,
      x: 4393980,
      y: 3853230
    },
    {
      nanogenreId: 1911,
      x: -1007340,
      y: 8744700
    },
    {
      nanogenreId: 1912,
      x: 4403250,
      y: 2873700
    },
    {
      nanogenreId: 1913,
      x: 1545000,
      y: 5772120
    },
    {
      nanogenreId: 1914,
      x: -9612990,
      y: 6278880
    },
    {
      nanogenreId: 1915,
      x: -7706460,
      y: 10274250
    },
    {
      nanogenreId: 1916,
      x: 4959450,
      y: 40170
    },
    {
      nanogenreId: 1917,
      x: 5673240,
      y: 10614150
    },
    {
      nanogenreId: 1918,
      x: -5537280,
      y: 5914260
    },
    {
      nanogenreId: 1919,
      x: -2472000,
      y: 1832370
    },
    {
      nanogenreId: 1920,
      x: -2527620,
      y: -7403640
    },
    {
      nanogenreId: 1921,
      x: 4276560,
      y: 9755130
    },
    {
      nanogenreId: 1922,
      x: 6899970,
      y: -7326390
    },
    {
      nanogenreId: 1923,
      x: 210120,
      y: 18540
    },
    {
      nanogenreId: 1924,
      x: -7471620,
      y: 3905760
    },
    {
      nanogenreId: 1925,
      x: 4919280,
      y: 7076100
    },
    {
      nanogenreId: 1926,
      x: 6547710,
      y: -1399770
    },
    {
      nanogenreId: 1927,
      x: 6899970,
      y: 939360
    },
    {
      nanogenreId: 1928,
      x: -7814610,
      y: 9477030
    },
    {
      nanogenreId: 1929,
      x: 1989960,
      y: 7289310
    },
    {
      nanogenreId: 1930,
      x: 6921600,
      y: 9514110
    },
    {
      nanogenreId: 1931,
      x: -6439560,
      y: 6464280
    },
    {
      nanogenreId: 1932,
      x: 16843590,
      y: -3572040
    },
    {
      nanogenreId: 1933,
      x: -5951340,
      y: 6322140
    },
    {
      nanogenreId: 1934,
      x: -2407110,
      y: 4044810
    },
    {
      nanogenreId: 1935,
      x: 3976830,
      y: 8247210
    },
    {
      nanogenreId: 1936,
      x: -834300,
      y: 7564320
    },
    {
      nanogenreId: 1937,
      x: -4573200,
      y: 401700
    },
    {
      nanogenreId: 1938,
      x: 12199320,
      y: -6232530
    },
    {
      nanogenreId: 1939,
      x: -6875250,
      y: 1050600
    },
    {
      nanogenreId: 1940,
      x: -8163780,
      y: -5129400
    },
    {
      nanogenreId: 1941,
      x: 1711860,
      y: 7925850
    },
    {
      nanogenreId: 1942,
      x: 6526080,
      y: 9294720
    },
    {
      nanogenreId: 1943,
      x: 13188120,
      y: -5098500
    },
    {
      nanogenreId: 1944,
      x: -77250,
      y: 2709930
    },
    {
      nanogenreId: 1945,
      x: 5478570,
      y: 10215540
    },
    {
      nanogenreId: 1946,
      x: 8225580,
      y: -6594060
    },
    {
      nanogenreId: 1947,
      x: 3819240,
      y: 9452310
    },
    {
      nanogenreId: 1948,
      x: -8092710,
      y: 7913490
    },
    {
      nanogenreId: 1949,
      x: 3636930,
      y: -8457330
    },
    {
      nanogenreId: 1950,
      x: -11973750,
      y: -9010440
    },
    {
      nanogenreId: 1951,
      x: 12381630,
      y: 6121290
    },
    {
      nanogenreId: 1952,
      x: -5027430,
      y: -11417550
    },
    {
      nanogenreId: 1953,
      x: 5969880,
      y: 10725390
    },
    {
      nanogenreId: 1954,
      x: 40170,
      y: 8472780
    },
    {
      nanogenreId: 1955,
      x: -2855160,
      y: -7786800
    },
    {
      nanogenreId: 1956,
      x: -3269220,
      y: 1226730
    },
    {
      nanogenreId: 1957,
      x: 4258020,
      y: 7351110
    },
    {
      nanogenreId: 1958,
      x: 2685210,
      y: -10539990
    },
    {
      nanogenreId: 1959,
      x: 3961380,
      y: 7073010
    },
    {
      nanogenreId: 1960,
      x: 9140220,
      y: -7910400
    },
    {
      nanogenreId: 1961,
      x: 11241420,
      y: 7178070
    },
    {
      nanogenreId: 1962,
      x: 3256860,
      y: 8528400
    },
    {
      nanogenreId: 1963,
      x: -10502910,
      y: 9022800
    },
    {
      nanogenreId: 1964,
      x: -1693320,
      y: 485130
    },
    {
      nanogenreId: 1965,
      x: -16129800,
      y: 2904600
    },
    {
      nanogenreId: 1966,
      x: 2308230,
      y: 7382010
    },
    {
      nanogenreId: 1967,
      x: 2431830,
      y: 6708390
    },
    {
      nanogenreId: 1968,
      x: -1473930,
      y: -4415610
    },
    {
      nanogenreId: 1969,
      x: -1436850,
      y: 7162620
    },
    {
      nanogenreId: 1970,
      x: -679800,
      y: -1226730
    },
    {
      nanogenreId: 1971,
      x: 2864430,
      y: 2187720
    },
    {
      nanogenreId: 1972,
      x: -4536120,
      y: -4270380
    },
    {
      nanogenreId: 1973,
      x: 5812290,
      y: 10790280
    },
    {
      nanogenreId: 1974,
      x: 4078800,
      y: 8982630
    },
    {
      nanogenreId: 1975,
      x: 2243340,
      y: 7545780
    },
    {
      nanogenreId: 1976,
      x: 166860,
      y: -4625730
    },
    {
      nanogenreId: 1977,
      x: -1974510,
      y: -9430680
    },
    {
      nanogenreId: 1978,
      x: 4919280,
      y: 10651230
    },
    {
      nanogenreId: 1979,
      x: 7499430,
      y: -8339910
    },
    {
      nanogenreId: 1980,
      x: -3494790,
      y: -1653150
    },
    {
      nanogenreId: 1981,
      x: -2091930,
      y: 7517970
    },
    {
      nanogenreId: 1982,
      x: -1727310,
      y: 5929710
    },
    {
      nanogenreId: 1983,
      x: -6974130,
      y: 5562000
    },
    {
      nanogenreId: 1984,
      x: 3049830,
      y: -574740
    },
    {
      nanogenreId: 1985,
      x: 6356130,
      y: 2014680
    },
    {
      nanogenreId: 1986,
      x: -1857090,
      y: 6000780
    },
    {
      nanogenreId: 1987,
      x: -2379300,
      y: 3862500
    },
    {
      nanogenreId: 1988,
      x: 4440330,
      y: 27810
    },
    {
      nanogenreId: 1989,
      x: -13769040,
      y: -5889540
    },
    {
      nanogenreId: 1990,
      x: 2382390,
      y: -3986100
    },
    {
      nanogenreId: 1991,
      x: 4792590,
      y: -8927010
    },
    {
      nanogenreId: 1992,
      x: -9761310,
      y: -6739290
    },
    {
      nanogenreId: 1993,
      x: -12749340,
      y: -5058330
    },
    {
      nanogenreId: 1994,
      x: 15898050,
      y: 4489770
    },
    {
      nanogenreId: 1995,
      x: -2039400,
      y: -6164550
    },
    {
      nanogenreId: 1996,
      x: 4295100,
      y: 10493640
    },
    {
      nanogenreId: 1997,
      x: -2784090,
      y: 2546160
    },
    {
      nanogenreId: 1998,
      x: 695250,
      y: 7193520
    },
    {
      nanogenreId: 1999,
      x: -6254160,
      y: 5398230
    },
    {
      nanogenreId: 2000,
      x: -8321370,
      y: 753960
    },
    {
      nanogenreId: 2001,
      x: -843570,
      y: 11201250
    },
    {
      nanogenreId: 2002,
      x: 2626500,
      y: 5775210
    },
    {
      nanogenreId: 2003,
      x: -15329490,
      y: 1881810
    },
    {
      nanogenreId: 2004,
      x: 4842030,
      y: -3488610
    },
    {
      nanogenreId: 2005,
      x: -5197380,
      y: 2898420
    },
    {
      nanogenreId: 2006,
      x: 4230210,
      y: 2774820
    },
    {
      nanogenreId: 2007,
      x: 4359990,
      y: 10805730
    },
    {
      nanogenreId: 2008,
      x: 8055630,
      y: 18246450
    },
    {
      nanogenreId: 2009,
      x: 3837780,
      y: 6025500
    },
    {
      nanogenreId: 2010,
      x: 2468910,
      y: -5277720
    },
    {
      nanogenreId: 2011,
      x: -2023950,
      y: 1019700
    },
    {
      nanogenreId: 2012,
      x: 8009280,
      y: 1758210
    },
    {
      nanogenreId: 2013,
      x: -6232530,
      y: 3355740
    },
    {
      nanogenreId: 2014,
      x: 5528010,
      y: 8661270
    },
    {
      nanogenreId: 2015,
      x: -3479340,
      y: 12273480
    },
    {
      nanogenreId: 2016,
      x: 5580540,
      y: 9888000
    },
    {
      nanogenreId: 2017,
      x: 6405570,
      y: -3386640
    },
    {
      nanogenreId: 2018,
      x: -1566630,
      y: -834300
    },
    {
      nanogenreId: 2019,
      x: 5574360,
      y: 8030910
    },
    {
      nanogenreId: 2020,
      x: 2363850,
      y: 596370
    },
    {
      nanogenreId: 2021,
      x: 4539210,
      y: 9619170
    },
    {
      nanogenreId: 2022,
      x: -1656240,
      y: 4245660
    },
    {
      nanogenreId: 2023,
      x: -5765940,
      y: -14828910
    },
    {
      nanogenreId: 2024,
      x: -11936670,
      y: 2651220
    },
    {
      nanogenreId: 2025,
      x: 1251450,
      y: 296640
    },
    {
      nanogenreId: 2026,
      x: 86520,
      y: 3025110
    },
    {
      nanogenreId: 2027,
      x: 438780,
      y: -10691400
    },
    {
      nanogenreId: 2028,
      x: -358440,
      y: 5713410
    },
    {
      nanogenreId: 2029,
      x: 13259190,
      y: 1878720
    },
    {
      nanogenreId: 2030,
      x: 3389730,
      y: 9359610
    },
    {
      nanogenreId: 2031,
      x: -9928170,
      y: -3704910
    },
    {
      nanogenreId: 2032,
      x: 2048670,
      y: 3105450
    },
    {
      nanogenreId: 2033,
      x: 741600,
      y: 3457710
    },
    {
      nanogenreId: 2034,
      x: 9251460,
      y: -7969110
    },
    {
      nanogenreId: 2035,
      x: 117420,
      y: -8809590
    },
    {
      nanogenreId: 2036,
      x: 407880,
      y: 5886450
    },
    {
      nanogenreId: 2037,
      x: 2416380,
      y: -6213990
    },
    {
      nanogenreId: 2038,
      x: 2666670,
      y: -4208580
    },
    {
      nanogenreId: 2039,
      x: 17748960,
      y: 4449600
    },
    {
      nanogenreId: 2040,
      x: 3664740,
      y: 8964090
    },
    {
      nanogenreId: 2041,
      x: 9143310,
      y: 667440
    },
    {
      nanogenreId: 2042,
      x: 1285440,
      y: -2750100
    },
    {
      nanogenreId: 2043,
      x: 13373520,
      y: 17486310
    },
    {
      nanogenreId: 2044,
      x: 11009670,
      y: 1205100
    },
    {
      nanogenreId: 2045,
      x: 948630,
      y: 8237940
    },
    {
      nanogenreId: 2046,
      x: -6278880,
      y: 2814990
    },
    {
      nanogenreId: 2047,
      x: 4137510,
      y: 1010430
    },
    {
      nanogenreId: 2048,
      x: 5354970,
      y: 9069150
    },
    {
      nanogenreId: 2049,
      x: 9171120,
      y: -7657020
    },
    {
      nanogenreId: 2050,
      x: 1545000,
      y: 10033230
    },
    {
      nanogenreId: 2051,
      x: -15768270,
      y: -1461570
    },
    {
      nanogenreId: 2052,
      x: 8346090,
      y: -7366560
    },
    {
      nanogenreId: 2053,
      x: -7539600,
      y: -6782550
    },
    {
      nanogenreId: 2054,
      x: 13926630,
      y: 6612600
    },
    {
      nanogenreId: 2055,
      x: 2061030,
      y: 10567800
    },
    {
      nanogenreId: 2056,
      x: 2978760,
      y: -11297040
    },
    {
      nanogenreId: 2057,
      x: 12947100,
      y: -2502900
    },
    {
      nanogenreId: 2058,
      x: 6532260,
      y: 9816930
    },
    {
      nanogenreId: 2059,
      x: -3052920,
      y: -11809980
    },
    {
      nanogenreId: 2060,
      x: -11040570,
      y: -4937820
    },
    {
      nanogenreId: 2061,
      x: -12091170,
      y: 3133260
    },
    {
      nanogenreId: 2062,
      x: 10101210,
      y: -15879510
    },
    {
      nanogenreId: 2063,
      x: 6513720,
      y: 7604490
    },
    {
      nanogenreId: 2064,
      x: 15490170,
      y: -4959450
    },
    {
      nanogenreId: 2065,
      x: 16568580,
      y: -8030910
    },
    {
      nanogenreId: 2066,
      x: 9708780,
      y: -16855950
    },
    {
      nanogenreId: 2067,
      x: -2592510,
      y: -13518750
    },
    {
      nanogenreId: 2068,
      x: 7344930,
      y: 10972590
    },
    {
      nanogenreId: 2069,
      x: 6773280,
      y: 10926240
    },
    {
      nanogenreId: 2070,
      x: 17495580,
      y: -132870
    },
    {
      nanogenreId: 2071,
      x: 818850,
      y: 15029760
    },
    {
      nanogenreId: 2072,
      x: 18889170,
      y: 3757440
    },
    {
      nanogenreId: 2073,
      x: -6430290,
      y: 11853240
    },
    {
      nanogenreId: 2074,
      x: -4665900,
      y: 11346480
    },
    {
      nanogenreId: 2075,
      x: 6720750,
      y: 4387800
    },
    {
      nanogenreId: 2076,
      x: 7326390,
      y: -9742770
    },
    {
      nanogenreId: 2077,
      x: 5948250,
      y: 12406350
    },
    {
      nanogenreId: 2078,
      x: 5540370,
      y: 9232920
    },
    {
      nanogenreId: 2079,
      x: 8339910,
      y: -7125540
    },
    {
      nanogenreId: 2080,
      x: 13620720,
      y: 1313250
    },
    {
      nanogenreId: 2081,
      x: 14251080,
      y: -7990740
    },
    {
      nanogenreId: 2082,
      x: 976440,
      y: 11244510
    },
    {
      nanogenreId: 2083,
      x: 15335670,
      y: 5920440
    },
    {
      nanogenreId: 2084,
      x: 11077650,
      y: -5824650
    },
    {
      nanogenreId: 2085,
      x: 12644280,
      y: -12678270
    },
    {
      nanogenreId: 2086,
      x: 1480110,
      y: 13704150
    },
    {
      nanogenreId: 2087,
      x: 1480110,
      y: 12387810
    },
    {
      nanogenreId: 2088,
      x: 10478190,
      y: -8410980
    },
    {
      nanogenreId: 2089,
      x: 1711860,
      y: -5942070
    },
    {
      nanogenreId: 2090,
      x: 11843970,
      y: 3806880
    },
    {
      nanogenreId: 2091,
      x: 6529170,
      y: 8695260
    },
    {
      nanogenreId: 2092,
      x: -3263040,
      y: -12758610
    },
    {
      nanogenreId: 2093,
      x: 10329870,
      y: -9195840
    },
    {
      nanogenreId: 2094,
      x: 5444580,
      y: 5858640
    },
    {
      nanogenreId: 2095,
      x: 7873320,
      y: 5280810
    },
    {
      nanogenreId: 2096,
      x: -5481660,
      y: 13490940
    },
    {
      nanogenreId: 2097,
      x: 15731190,
      y: 6146010
    },
    {
      nanogenreId: 2098,
      x: 10614150,
      y: -7116270
    },
    {
      nanogenreId: 2099,
      x: 2172270,
      y: 12350730
    },
    {
      nanogenreId: 2100,
      x: -5874090,
      y: -10638870
    },
    {
      nanogenreId: 2101,
      x: 5376600,
      y: 10743930
    },
    {
      nanogenreId: 2102,
      x: 8271930,
      y: -9925080
    },
    {
      nanogenreId: 2103,
      x: -8080350,
      y: -11658570
    },
    {
      nanogenreId: 2104,
      x: 12588660,
      y: 648900
    },
    {
      nanogenreId: 2105,
      x: -2091930,
      y: 9334890
    },
    {
      nanogenreId: 2106,
      x: 10342230,
      y: -8512950
    },
    {
      nanogenreId: 2107,
      x: -4752420,
      y: -2947860
    },
    {
      nanogenreId: 2108,
      x: -5178840,
      y: 14658960
    },
    {
      nanogenreId: 2109,
      x: 8769420,
      y: -6507540
    },
    {
      nanogenreId: 2110,
      x: 5441490,
      y: 11584410
    },
    {
      nanogenreId: 2111,
      x: -2570880,
      y: -8970270
    },
    {
      nanogenreId: 2112,
      x: -11259960,
      y: -11429910
    },
    {
      nanogenreId: 2113,
      x: 10258800,
      y: 18651240
    },
    {
      nanogenreId: 2114,
      x: -1050600,
      y: -8191590
    },
    {
      nanogenreId: 2115,
      x: 14028600,
      y: 7771350
    },
    {
      nanogenreId: 2116,
      x: -10425660,
      y: -7774440
    },
    {
      nanogenreId: 2117,
      x: -3062190,
      y: -3766710
    },
    {
      nanogenreId: 2118,
      x: 3714180,
      y: -5972970
    },
    {
      nanogenreId: 2119,
      x: -2011590,
      y: -7783710
    },
    {
      nanogenreId: 2120,
      x: -3062190,
      y: -9980700
    },
    {
      nanogenreId: 2121,
      x: -11096190,
      y: -3451530
    },
    {
      nanogenreId: 2122,
      x: 2156820,
      y: 13212840
    },
    {
      nanogenreId: 2123,
      x: -13271550,
      y: -9291630
    },
    {
      nanogenreId: 2124,
      x: -14408670,
      y: -3327930
    },
    {
      nanogenreId: 2125,
      x: 9189660,
      y: -2892240
    },
    {
      nanogenreId: 2126,
      x: 7048290,
      y: 9041340
    },
    {
      nanogenreId: 2127,
      x: -2212440,
      y: -13654710
    },
    {
      nanogenreId: 2128,
      x: -3423720,
      y: -15230610
    },
    {
      nanogenreId: 2129,
      x: 14757840,
      y: 15966030
    },
    {
      nanogenreId: 2130,
      x: 11371200,
      y: 2808810
    },
    {
      nanogenreId: 2131,
      x: -531480,
      y: -9069150
    },
    {
      nanogenreId: 2132,
      x: -10113570,
      y: 2091930
    },
    {
      nanogenreId: 2133,
      x: 10787190,
      y: -6974130
    },
    {
      nanogenreId: 2134,
      x: 3324840,
      y: 9427590
    },
    {
      nanogenreId: 2135,
      x: -2224800,
      y: 12730800
    },
    {
      nanogenreId: 2136,
      x: 5367330,
      y: 7480890
    },
    {
      nanogenreId: 2137,
      x: 7771350,
      y: -11658570
    },
    {
      nanogenreId: 2138,
      x: -4103520,
      y: 5531100
    },
    {
      nanogenreId: 2139,
      x: -13098510,
      y: -6955590
    },
    {
      nanogenreId: 2140,
      x: 9350340,
      y: 2292780
    },
    {
      nanogenreId: 2141,
      x: 265740,
      y: 10178460
    },
    {
      nanogenreId: 2142,
      x: -5676330,
      y: 8800320
    },
    {
      nanogenreId: 2143,
      x: -4505220,
      y: 5324070
    },
    {
      nanogenreId: 2144,
      x: -3720360,
      y: -6297420
    },
    {
      nanogenreId: 2145,
      x: 3365010,
      y: 10499820
    },
    {
      nanogenreId: 2146,
      x: 1983780,
      y: 818850
    },
    {
      nanogenreId: 2147,
      x: 3896490,
      y: 10193910
    },
    {
      nanogenreId: 2148,
      x: 868290,
      y: 10271160
    },
    {
      nanogenreId: 2149,
      x: 587100,
      y: -3683280
    },
    {
      nanogenreId: 2150,
      x: 3609120,
      y: -7085370
    },
    {
      nanogenreId: 2151,
      x: -7035930,
      y: 9640800
    },
    {
      nanogenreId: 2152,
      x: -4122060,
      y: -4922370
    },
    {
      nanogenreId: 2153,
      x: 4987260,
      y: 7246050
    },
    {
      nanogenreId: 2154,
      x: -4007730,
      y: 5929710
    },
    {
      nanogenreId: 2155,
      x: 4944000,
      y: 10425660
    },
    {
      nanogenreId: 2156,
      x: 4699890,
      y: 9424500
    },
    {
      nanogenreId: 2157,
      x: -2734650,
      y: -11612220
    },
    {
      nanogenreId: 2158,
      x: -3476250,
      y: 1072230
    },
    {
      nanogenreId: 2159,
      x: 6649680,
      y: 9168030
    },
    {
      nanogenreId: 2160,
      x: -11652390,
      y: -9591360
    },
    {
      nanogenreId: 2161,
      x: -13259190,
      y: -7882590
    },
    {
      nanogenreId: 2162,
      x: -6714570,
      y: 2144460
    },
    {
      nanogenreId: 2163,
      x: 10132110,
      y: -8633460
    },
    {
      nanogenreId: 2164,
      x: 7935120,
      y: -5877180
    },
    {
      nanogenreId: 2165,
      x: -7008120,
      y: 16343010
    },
    {
      nanogenreId: 2166,
      x: 4811130,
      y: 9653160
    },
    {
      nanogenreId: 2167,
      x: 11643120,
      y: 3303210
    },
    {
      nanogenreId: 2168,
      x: 2740830,
      y: -4412520
    },
    {
      nanogenreId: 2169,
      x: -3195060,
      y: 11361930
    },
    {
      nanogenreId: 2170,
      x: -2502900,
      y: 8126700
    },
    {
      nanogenreId: 2171,
      x: -2635770,
      y: 14869080
    },
    {
      nanogenreId: 2172,
      x: -9464670,
      y: 3371190
    },
    {
      nanogenreId: 2173,
      x: -4733880,
      y: -9433770
    },
    {
      nanogenreId: 2174,
      x: 12551580,
      y: -145230
    },
    {
      nanogenreId: 2175,
      x: 9310170,
      y: -7338750
    },
    {
      nanogenreId: 2176,
      x: -2200080,
      y: -4641180
    },
    {
      nanogenreId: 2177,
      x: 5438400,
      y: -6974130
    },
    {
      nanogenreId: 2178,
      x: 12063360,
      y: -5883360
    },
    {
      nanogenreId: 2179,
      x: 8642730,
      y: -7820790
    },
    {
      nanogenreId: 2180,
      x: 13716510,
      y: -667440
    },
    {
      nanogenreId: 2181,
      x: 3188880,
      y: 10629600
    },
    {
      nanogenreId: 2182,
      x: -7666290,
      y: -12347640
    },
    {
      nanogenreId: 2183,
      x: 4529940,
      y: -9137130
    },
    {
      nanogenreId: 2184,
      x: 6831990,
      y: 9736590
    },
    {
      nanogenreId: 2185,
      x: -806490,
      y: -10753200
    },
    {
      nanogenreId: 2186,
      x: 9093870,
      y: 16738530
    },
    {
      nanogenreId: 2187,
      x: 5759760,
      y: 8049450
    },
    {
      nanogenreId: 2188,
      x: 7555050,
      y: 8608740
    },
    {
      nanogenreId: 2189,
      x: -3584400,
      y: -12091170
    },
    {
      nanogenreId: 2190,
      x: 117420,
      y: 12965640
    },
    {
      nanogenreId: 2191,
      x: 10944780,
      y: -7171890
    },
    {
      nanogenreId: 2192,
      x: 5874090,
      y: 9211290
    },
    {
      nanogenreId: 2193,
      x: 5460030,
      y: 10666680
    },
    {
      nanogenreId: 2194,
      x: 12613380,
      y: 3986100
    },
    {
      nanogenreId: 2195,
      x: -5426040,
      y: -15833160
    },
    {
      nanogenreId: 2196,
      x: -5144850,
      y: -15517980
    },
    {
      nanogenreId: 2197,
      x: -8707620,
      y: 16753980
    },
    {
      nanogenreId: 2198,
      x: 5592900,
      y: 5997690
    },
    {
      nanogenreId: 2199,
      x: 9993060,
      y: -7585950
    },
    {
      nanogenreId: 2200,
      x: 10110480,
      y: -7119360
    },
    {
      nanogenreId: 2201,
      x: -2255700,
      y: 5101590
    },
    {
      nanogenreId: 2202,
      x: -5583630,
      y: -5747400
    },
    {
      nanogenreId: 2203,
      x: -3859410,
      y: -6130560
    },
    {
      nanogenreId: 2204,
      x: -7270770,
      y: 6451920
    },
    {
      nanogenreId: 2205,
      x: 11300130,
      y: -6356130
    },
    {
      nanogenreId: 2206,
      x: 4770960,
      y: 9365790
    },
    {
      nanogenreId: 2207,
      x: -12925470,
      y: -4653540
    },
    {
      nanogenreId: 2208,
      x: 954810,
      y: -14572440
    },
    {
      nanogenreId: 2209,
      x: 4659720,
      y: 9702600
    },
    {
      nanogenreId: 2210,
      x: -5725770,
      y: -7289310
    },
    {
      nanogenreId: 2211,
      x: 9347250,
      y: -8682900
    },
    {
      nanogenreId: 2212,
      x: -2549250,
      y: 10379310
    },
    {
      nanogenreId: 2213,
      x: 2910780,
      y: 13083060
    },
    {
      nanogenreId: 2214,
      x: -5061420,
      y: 9217470
    },
    {
      nanogenreId: 2215,
      x: -4359990,
      y: -13036710
    },
    {
      nanogenreId: 2216,
      x: 8407890,
      y: 2675940
    },
    {
      nanogenreId: 2217,
      x: 9449220,
      y: -8488230
    },
    {
      nanogenreId: 2218,
      x: -1140210,
      y: 7493250
    },
    {
      nanogenreId: 2219,
      x: -346080,
      y: 9303990
    },
    {
      nanogenreId: 2220,
      x: 2740830,
      y: -8874480
    },
    {
      nanogenreId: 2221,
      x: 6152190,
      y: 11324850
    },
    {
      nanogenreId: 2222,
      x: 3432990,
      y: 10759380
    },
    {
      nanogenreId: 2223,
      x: -3911940,
      y: 13045980
    },
    {
      nanogenreId: 2224,
      x: 290460,
      y: 3454620
    },
    {
      nanogenreId: 2225,
      x: -7901130,
      y: 12508320
    },
    {
      nanogenreId: 2226,
      x: 7845510,
      y: -9174210
    },
    {
      nanogenreId: 2227,
      x: -7978380,
      y: -12217860
    },
    {
      nanogenreId: 2228,
      x: -2651220,
      y: -11219790
    },
    {
      nanogenreId: 2229,
      x: 577830,
      y: 10481280
    },
    {
      nanogenreId: 2230,
      x: -6856710,
      y: -4990350
    },
    {
      nanogenreId: 2231,
      x: -3281580,
      y: -11466990
    },
    {
      nanogenreId: 2232,
      x: -12285840,
      y: -4987260
    },
    {
      nanogenreId: 2233,
      x: -2660490,
      y: -11525700
    },
    {
      nanogenreId: 2234,
      x: -9072240,
      y: 15851700
    },
    {
      nanogenreId: 2235,
      x: -3534960,
      y: 5175750
    },
    {
      nanogenreId: 2236,
      x: 4063350,
      y: -8862120
    },
    {
      nanogenreId: 2237,
      x: -7335660,
      y: 9041340
    },
    {
      nanogenreId: 2238,
      x: 5651610,
      y: 8559300
    },
    {
      nanogenreId: 2239,
      x: 16064910,
      y: 5667060
    },
    {
      nanogenreId: 2240,
      x: -14442660,
      y: -4041720
    },
    {
      nanogenreId: 2241,
      x: -5379690,
      y: -10447290
    },
    {
      nanogenreId: 2242,
      x: 4310550,
      y: -7289310
    },
    {
      nanogenreId: 2243,
      x: -2808810,
      y: -11494800
    },
    {
      nanogenreId: 2244,
      x: -7573590,
      y: -1804560
    },
    {
      nanogenreId: 2245,
      x: 10017780,
      y: -6628050
    },
    {
      nanogenreId: 2246,
      x: -7344930,
      y: 6176910
    },
    {
      nanogenreId: 2247,
      x: 8809590,
      y: -5852460
    },
    {
      nanogenreId: 2248,
      x: -11528790,
      y: 2814990
    },
    {
      nanogenreId: 2249,
      x: 1001160,
      y: -11936670
    },
    {
      nanogenreId: 2250,
      x: -1254540,
      y: -12638100
    },
    {
      nanogenreId: 2251,
      x: -9591360,
      y: -2935500
    },
    {
      nanogenreId: 2252,
      x: 3525690,
      y: -7919670
    },
    {
      nanogenreId: 2253,
      x: -7947480,
      y: -5991510
    },
    {
      nanogenreId: 2254,
      x: 7864050,
      y: -7956750
    },
    {
      nanogenreId: 2255,
      x: 12134430,
      y: 352260
    },
    {
      nanogenreId: 2256,
      x: -12990360,
      y: 633450
    },
    {
      nanogenreId: 2257,
      x: -1659330,
      y: -9192750
    },
    {
      nanogenreId: 2258,
      x: -1915800,
      y: 12978000
    },
    {
      nanogenreId: 2259,
      x: 16670550,
      y: -540750
    },
    {
      nanogenreId: 2260,
      x: 7725000,
      y: 296640
    },
    {
      nanogenreId: 2261,
      x: -4047900,
      y: 13348800
    },
    {
      nanogenreId: 2262,
      x: 6887610,
      y: 10113570
    },
    {
      nanogenreId: 2263,
      x: -3436080,
      y: 6844350
    },
    {
      nanogenreId: 2264,
      x: 9588270,
      y: 5018160
    },
    {
      nanogenreId: 2265,
      x: -6078030,
      y: 4412520
    },
    {
      nanogenreId: 2266,
      x: -4393980,
      y: -1319430
    },
    {
      nanogenreId: 2267,
      x: -1612980,
      y: -7440720
    },
    {
      nanogenreId: 2268,
      x: 9548100,
      y: -8593290
    },
    {
      nanogenreId: 2269,
      x: -1795290,
      y: -10506000
    },
    {
      nanogenreId: 2270,
      x: 6479730,
      y: 10150650
    },
    {
      nanogenreId: 2271,
      x: 4594830,
      y: 1928160
    },
    {
      nanogenreId: 2272,
      x: 10082670,
      y: -7369650
    },
    {
      nanogenreId: 2273,
      x: -6671310,
      y: 5240640
    },
    {
      nanogenreId: 2274,
      x: -1863270,
      y: -8849760
    },
    {
      nanogenreId: 2275,
      x: -1242180,
      y: -2212440
    },
    {
      nanogenreId: 2276,
      x: -6473550,
      y: 6674400
    },
    {
      nanogenreId: 2277,
      x: 5852460,
      y: 10391670
    },
    {
      nanogenreId: 2278,
      x: 4122060,
      y: 4823490
    },
    {
      nanogenreId: 2279,
      x: 4910010,
      y: 1004250
    },
    {
      nanogenreId: 2280,
      x: 8760150,
      y: 6526080
    },
    {
      nanogenreId: 2281,
      x: -4316730,
      y: 11686380
    },
    {
      nanogenreId: 2282,
      x: -6708390,
      y: -6977220
    },
    {
      nanogenreId: 2283,
      x: -12749340,
      y: -6229440
    },
    {
      nanogenreId: 2284,
      x: -3708000,
      y: -5793750
    },
    {
      nanogenreId: 2285,
      x: 2913870,
      y: 10434930
    },
    {
      nanogenreId: 2286,
      x: 330630,
      y: -12644280
    },
    {
      nanogenreId: 2287,
      x: 4965630,
      y: 5741220
    },
    {
      nanogenreId: 2288,
      x: -6748560,
      y: -2580150
    },
    {
      nanogenreId: 2289,
      x: -2125920,
      y: -10499820
    },
    {
      nanogenreId: 2290,
      x: 15536520,
      y: -1115490
    },
    {
      nanogenreId: 2291,
      x: -1239090,
      y: -13898820
    },
    {
      nanogenreId: 2292,
      x: 10184640,
      y: -6785640
    },
    {
      nanogenreId: 2293,
      x: 9965250,
      y: 565470
    },
    {
      nanogenreId: 2294,
      x: 945540,
      y: 6677490
    },
    {
      nanogenreId: 2295,
      x: 6566250,
      y: -8312100
    },
    {
      nanogenreId: 2296,
      x: -4699890,
      y: 8463510
    },
    {
      nanogenreId: 2297,
      x: -4199310,
      y: 12390900
    },
    {
      nanogenreId: 2298,
      x: 8420250,
      y: 6807270
    },
    {
      nanogenreId: 2299,
      x: 7867140,
      y: -8642730
    },
    {
      nanogenreId: 2300,
      x: 4217850,
      y: 10002330
    },
    {
      nanogenreId: 2301,
      x: -2533800,
      y: -12523770
    },
    {
      nanogenreId: 2302,
      x: -5509470,
      y: 5416770
    },
    {
      nanogenreId: 2303,
      x: 3568950,
      y: -7502520
    },
    {
      nanogenreId: 2304,
      x: -2243340,
      y: 13636170
    },
    {
      nanogenreId: 2305,
      x: -3090000,
      y: -9841650
    },
    {
      nanogenreId: 2306,
      x: 11297040,
      y: -10441110
    },
    {
      nanogenreId: 2307,
      x: -3729630,
      y: -9826200
    },
    {
      nanogenreId: 2308,
      x: 3145620,
      y: 8231760
    },
    {
      nanogenreId: 2309,
      x: 6717660,
      y: -1149480
    },
    {
      nanogenreId: 2310,
      x: 5157210,
      y: 10382400
    },
    {
      nanogenreId: 2311,
      x: 185400,
      y: 1041330
    },
    {
      nanogenreId: 2312,
      x: 1501740,
      y: -8994990
    },
    {
      nanogenreId: 2313,
      x: -6485910,
      y: -10175370
    },
    {
      nanogenreId: 2314,
      x: 9903450,
      y: -7242960
    },
    {
      nanogenreId: 2315,
      x: -5308620,
      y: 6498270
    },
    {
      nanogenreId: 2316,
      x: -3272310,
      y: 9507930
    },
    {
      nanogenreId: 2317,
      x: -2308230,
      y: -8401710
    },
    {
      nanogenreId: 2318,
      x: 3392820,
      y: 7743540
    },
    {
      nanogenreId: 2319,
      x: 11068380,
      y: -4984170
    },
    {
      nanogenreId: 2320,
      x: 5497110,
      y: 9004260
    },
    {
      nanogenreId: 2321,
      x: 7094640,
      y: 10030140
    },
    {
      nanogenreId: 2322,
      x: 14028600,
      y: 9504840
    },
    {
      nanogenreId: 2323,
      x: -3538050,
      y: -5605260
    },
    {
      nanogenreId: 2324,
      x: -4903830,
      y: 3334110
    },
    {
      nanogenreId: 2325,
      x: 4369260,
      y: 7174980
    },
    {
      nanogenreId: 2326,
      x: 1551180,
      y: -942450
    },
    {
      nanogenreId: 2327,
      x: 1449210,
      y: 9276180
    },
    {
      nanogenreId: 2328,
      x: -1059870,
      y: -6220170
    },
    {
      nanogenreId: 2329,
      x: 13521840,
      y: -8488230
    },
    {
      nanogenreId: 2330,
      x: -7901130,
      y: 5407500
    },
    {
      nanogenreId: 2331,
      x: 9242190,
      y: -6841260
    },
    {
      nanogenreId: 2332,
      x: 1687140,
      y: -11105460
    },
    {
      nanogenreId: 2333,
      x: 2373120,
      y: 10317510
    },
    {
      nanogenreId: 2334,
      x: -1801470,
      y: 67980
    },
    {
      nanogenreId: 2335,
      x: -689070,
      y: 7412910
    },
    {
      nanogenreId: 2336,
      x: -1684050,
      y: -8661270
    },
    {
      nanogenreId: 2337,
      x: 9140220,
      y: -9458490
    },
    {
      nanogenreId: 2338,
      x: 3059100,
      y: 491310
    },
    {
      nanogenreId: 2339,
      x: 3287760,
      y: 951720
    },
    {
      nanogenreId: 2340,
      x: 8509860,
      y: -2073390
    },
    {
      nanogenreId: 2341,
      x: 8778690,
      y: -6192360
    },
    {
      nanogenreId: 2342,
      x: 299730,
      y: 4860570
    },
    {
      nanogenreId: 2343,
      x: 2360760,
      y: -1918890
    },
    {
      nanogenreId: 2344,
      x: 3871770,
      y: 247200
    },
    {
      nanogenreId: 2345,
      x: 2447280,
      y: 10138290
    },
    {
      nanogenreId: 2346,
      x: 5512560,
      y: 10305150
    },
    {
      nanogenreId: 2347,
      x: -14031690,
      y: -2051760
    },
    {
      nanogenreId: 2348,
      x: 8611830,
      y: -5178840
    },
    {
      nanogenreId: 2349,
      x: -10558530,
      y: 8219400
    },
    {
      nanogenreId: 2350,
      x: 9619170,
      y: -763230
    },
    {
      nanogenreId: 2351,
      x: 4239480,
      y: 18660510
    },
    {
      nanogenreId: 2352,
      x: 5107770,
      y: 4903830
    },
    {
      nanogenreId: 2353,
      x: 10555440,
      y: -6019320
    },
    {
      nanogenreId: 2354,
      x: -2020860,
      y: 4044810
    },
    {
      nanogenreId: 2355,
      x: 3859410,
      y: -1965240
    },
    {
      nanogenreId: 2356,
      x: 6872160,
      y: -4468140
    },
    {
      nanogenreId: 2357,
      x: 5070690,
      y: 10156830
    },
    {
      nanogenreId: 2358,
      x: 645810,
      y: 9013530
    },
    {
      nanogenreId: 2359,
      x: -3102360,
      y: -12078810
    },
    {
      nanogenreId: 2360,
      x: 2536890,
      y: -11488620
    },
    {
      nanogenreId: 2361,
      x: 5302440,
      y: -5957520
    },
    {
      nanogenreId: 2362,
      x: 5855550,
      y: 9418320
    },
    {
      nanogenreId: 2363,
      x: 9461580,
      y: -7017390
    },
    {
      nanogenreId: 2364,
      x: -364620,
      y: 10181550
    },
    {
      nanogenreId: 2365,
      x: 5972970,
      y: -14149110
    },
    {
      nanogenreId: 2366,
      x: 10305150,
      y: -1501740
    },
    {
      nanogenreId: 2367,
      x: 2095020,
      y: 8006190
    },
    {
      nanogenreId: 2368,
      x: 3578220,
      y: 9124770
    },
    {
      nanogenreId: 2369,
      x: 12631920,
      y: -4270380
    },
    {
      nanogenreId: 2370,
      x: -7653930,
      y: 896100
    },
    {
      nanogenreId: 2371,
      x: -5694870,
      y: -14062590
    },
    {
      nanogenreId: 2372,
      x: -7765170,
      y: 6146010
    },
    {
      nanogenreId: 2373,
      x: 358440,
      y: 4397070
    },
    {
      nanogenreId: 2374,
      x: 10499820,
      y: -6300510
    },
    {
      nanogenreId: 2375,
      x: -7035930,
      y: 10397850
    },
    {
      nanogenreId: 2376,
      x: -9563550,
      y: 3080730
    },
    {
      nanogenreId: 2377,
      x: 8584020,
      y: -7378920
    },
    {
      nanogenreId: 2378,
      x: 7657020,
      y: -7891860
    },
    {
      nanogenreId: 2379,
      x: -2027040,
      y: -9041340
    },
    {
      nanogenreId: 2380,
      x: 11145630,
      y: 3241410
    },
    {
      nanogenreId: 2381,
      x: -7527240,
      y: -7944390
    },
    {
      nanogenreId: 2382,
      x: -250290,
      y: -12650460
    },
    {
      nanogenreId: 2383,
      x: -142140,
      y: -5914260
    },
    {
      nanogenreId: 2384,
      x: -11198160,
      y: -2227890
    },
    {
      nanogenreId: 2385,
      x: -4659720,
      y: -6603330
    },
    {
      nanogenreId: 2386,
      x: 5475480,
      y: 9903450
    },
    {
      nanogenreId: 2387,
      x: 6201630,
      y: 8911560
    },
    {
      nanogenreId: 2388,
      x: -6720750,
      y: 7536510
    },
    {
      nanogenreId: 2389,
      x: -4653540,
      y: 18153750
    },
    {
      nanogenreId: 2390,
      x: 7517970,
      y: -8914650
    },
    {
      nanogenreId: 2391,
      x: -818850,
      y: -1038240
    },
    {
      nanogenreId: 2392,
      x: 3463890,
      y: 8957910
    },
    {
      nanogenreId: 2393,
      x: 3201240,
      y: 6186180
    },
    {
      nanogenreId: 2394,
      x: -1875630,
      y: -7391280
    },
    {
      nanogenreId: 2395,
      x: -3142530,
      y: -7860960
    },
    {
      nanogenreId: 2396,
      x: -2916960,
      y: -1810740
    },
    {
      nanogenreId: 2397,
      x: 4282740,
      y: -6637320
    },
    {
      nanogenreId: 2398,
      x: -8346090,
      y: 3201240
    },
    {
      nanogenreId: 2399,
      x: 8438790,
      y: 8330640
    },
    {
      nanogenreId: 2400,
      x: 4004640,
      y: 10181550
    },
    {
      nanogenreId: 2401,
      x: -3080730,
      y: 5806110
    },
    {
      nanogenreId: 2402,
      x: 8880660,
      y: -7307850
    },
    {
      nanogenreId: 2403,
      x: 9915810,
      y: -7783710
    },
    {
      nanogenreId: 2404,
      x: -4508310,
      y: 9325620
    },
    {
      nanogenreId: 2405,
      x: 3426810,
      y: 10391670
    },
    {
      nanogenreId: 2406,
      x: 4162230,
      y: 13577460
    },
    {
      nanogenreId: 2407,
      x: 148320,
      y: 5540370
    },
    {
      nanogenreId: 2408,
      x: 4007730,
      y: -8021640
    },
    {
      nanogenreId: 2409,
      x: -6482820,
      y: -8664360
    },
    {
      nanogenreId: 2410,
      x: 9050610,
      y: 55620
    },
    {
      nanogenreId: 2411,
      x: -2496720,
      y: 3420630
    },
    {
      nanogenreId: 2412,
      x: -11757450,
      y: 5571270
    },
    {
      nanogenreId: 2413,
      x: -482040,
      y: -5419860
    },
    {
      nanogenreId: 2414,
      x: 10521450,
      y: -5592900
    },
    {
      nanogenreId: 2415,
      x: 15792990,
      y: 5543460
    },
    {
      nanogenreId: 2416,
      x: 4811130,
      y: -7524150
    },
    {
      nanogenreId: 2417,
      x: -1164930,
      y: 970260
    },
    {
      nanogenreId: 2418,
      x: -14801100,
      y: -6053310
    },
    {
      nanogenreId: 2419,
      x: -89610,
      y: -11170350
    },
    {
      nanogenreId: 2420,
      x: 10771740,
      y: -8880660
    },
    {
      nanogenreId: 2421,
      x: -15925860,
      y: -1028970
    },
    {
      nanogenreId: 2422,
      x: 3865590,
      y: -2558520
    },
    {
      nanogenreId: 2423,
      x: 4495950,
      y: 7857870
    },
    {
      nanogenreId: 2424,
      x: 3297030,
      y: 9223650
    },
    {
      nanogenreId: 2425,
      x: -11068380,
      y: -7178070
    },
    {
      nanogenreId: 2426,
      x: 3222870,
      y: -13274640
    },
    {
      nanogenreId: 2427,
      x: -1823100,
      y: -8444970
    },
    {
      nanogenreId: 2428,
      x: -917730,
      y: 4326000
    },
    {
      nanogenreId: 2429,
      x: 6288150,
      y: 5957520
    },
    {
      nanogenreId: 2430,
      x: -1739670,
      y: -5549640
    },
    {
      nanogenreId: 2431,
      x: -13330260,
      y: 386250
    },
    {
      nanogenreId: 2432,
      x: -5123220,
      y: 3516420
    },
    {
      nanogenreId: 2433,
      x: 10373130,
      y: -7088460
    },
    {
      nanogenreId: 2434,
      x: -7326390,
      y: -7440720
    },
    {
      nanogenreId: 2435,
      x: 15307860,
      y: 4876020
    },
    {
      nanogenreId: 2436,
      x: -4137510,
      y: 10394760
    },
    {
      nanogenreId: 2437,
      x: -6146010,
      y: 6411750
    },
    {
      nanogenreId: 2438,
      x: 6118200,
      y: 10645050
    },
    {
      nanogenreId: 2439,
      x: 14624970,
      y: 11760540
    },
    {
      nanogenreId: 2440,
      x: 3420630,
      y: 1841640
    },
    {
      nanogenreId: 2441,
      x: 8488230,
      y: -6442650
    },
    {
      nanogenreId: 2442,
      x: -1857090,
      y: 12505230
    },
    {
      nanogenreId: 2443,
      x: -4152960,
      y: -3544230
    },
    {
      nanogenreId: 2444,
      x: 5453850,
      y: 8253390
    },
    {
      nanogenreId: 2445,
      x: 8587110,
      y: -7178070
    },
    {
      nanogenreId: 2446,
      x: -14745480,
      y: 4369260
    },
    {
      nanogenreId: 2447,
      x: -4282740,
      y: -6492090
    },
    {
      nanogenreId: 2448,
      x: 6943230,
      y: 7029750
    },
    {
      nanogenreId: 2449,
      x: 5796840,
      y: -10679040
    },
    {
      nanogenreId: 2450,
      x: 4378530,
      y: -5444580
    },
    {
      nanogenreId: 2451,
      x: -982620,
      y: 14485920
    },
    {
      nanogenreId: 2452,
      x: -5098500,
      y: 9001170
    },
    {
      nanogenreId: 2453,
      x: -383160,
      y: 5070690
    },
    {
      nanogenreId: 2454,
      x: 6390120,
      y: 37080
    },
    {
      nanogenreId: 2455,
      x: 2876790,
      y: 6016230
    },
    {
      nanogenreId: 2456,
      x: -4227120,
      y: -5308620
    },
    {
      nanogenreId: 2457,
      x: -3590580,
      y: 2329860
    },
    {
      nanogenreId: 2458,
      x: 2302050,
      y: -7295490
    },
    {
      nanogenreId: 2459,
      x: 5537280,
      y: 10410210
    },
    {
      nanogenreId: 2460,
      x: -13259190,
      y: 14065680
    },
    {
      nanogenreId: 2461,
      x: -1458480,
      y: 12724620
    },
    {
      nanogenreId: 2462,
      x: -2879880,
      y: 8210130
    },
    {
      nanogenreId: 2463,
      x: 1594440,
      y: -2450370
    },
    {
      nanogenreId: 2464,
      x: -8225580,
      y: 5280810
    },
    {
      nanogenreId: 2465,
      x: -6918510,
      y: -11949030
    },
    {
      nanogenreId: 2466,
      x: -1897260,
      y: -6730020
    },
    {
      nanogenreId: 2467,
      x: 2450370,
      y: 9390510
    },
    {
      nanogenreId: 2468,
      x: -8009280,
      y: -16546950
    },
    {
      nanogenreId: 2469,
      x: -4647360,
      y: 6671310
    },
    {
      nanogenreId: 2470,
      x: -5688690,
      y: 14247990
    },
    {
      nanogenreId: 2471,
      x: -11191980,
      y: -12372360
    },
    {
      nanogenreId: 2472,
      x: 1826190,
      y: 9968340
    },
    {
      nanogenreId: 2473,
      x: -8426430,
      y: 10886070
    },
    {
      nanogenreId: 2474,
      x: 3896490,
      y: 7854780
    },
    {
      nanogenreId: 2475,
      x: 1375050,
      y: -1714950
    },
    {
      nanogenreId: 2476,
      x: 1334880,
      y: 5682510
    },
    {
      nanogenreId: 2477,
      x: -3056010,
      y: 1575900
    },
    {
      nanogenreId: 2478,
      x: -2954040,
      y: -9820020
    },
    {
      nanogenreId: 2479,
      x: 1409040,
      y: -2629590
    },
    {
      nanogenreId: 2480,
      x: -2950950,
      y: 380070
    },
    {
      nanogenreId: 2481,
      x: 5339520,
      y: 10567800
    },
    {
      nanogenreId: 2482,
      x: -7706460,
      y: -3769800
    },
    {
      nanogenreId: 2483,
      x: 1538820,
      y: 15428370
    },
    {
      nanogenreId: 2484,
      x: 9334890,
      y: -6000780
    },
    {
      nanogenreId: 2485,
      x: -8902290,
      y: 3649290
    },
    {
      nanogenreId: 2486,
      x: -13852470,
      y: 1597530
    },
    {
      nanogenreId: 2487,
      x: -3983010,
      y: 13663980
    },
    {
      nanogenreId: 2488,
      x: -4622640,
      y: 6980310
    },
    {
      nanogenreId: 2489,
      x: 2781000,
      y: -9557370
    },
    {
      nanogenreId: 2490,
      x: 6921600,
      y: -3661650
    },
    {
      nanogenreId: 2491,
      x: -3365010,
      y: 5126310
    },
    {
      nanogenreId: 2492,
      x: 4851300,
      y: 10558530
    },
    {
      nanogenreId: 2493,
      x: 6526080,
      y: -10648140
    },
    {
      nanogenreId: 2494,
      x: -2830440,
      y: -9656250
    },
    {
      nanogenreId: 2495,
      x: 1993050,
      y: 8086530
    },
    {
      nanogenreId: 2496,
      x: 5843190,
      y: 10345320
    },
    {
      nanogenreId: 2497,
      x: -6714570,
      y: 1937430
    },
    {
      nanogenreId: 2498,
      x: -3154890,
      y: -6603330
    },
    {
      nanogenreId: 2499,
      x: 6180000,
      y: 7301670
    },
    {
      nanogenreId: 2500,
      x: 6331410,
      y: 10858260
    },
    {
      nanogenreId: 2501,
      x: 3794520,
      y: 954810
    },
    {
      nanogenreId: 2502,
      x: 5589810,
      y: 3426810
    },
    {
      nanogenreId: 2503,
      x: -4047900,
      y: 3071460
    },
    {
      nanogenreId: 2504,
      x: -14702220,
      y: -5113950
    },
    {
      nanogenreId: 2505,
      x: 6173820,
      y: 10181550
    },
    {
      nanogenreId: 2506,
      x: 5988420,
      y: 10045590
    },
    {
      nanogenreId: 2507,
      x: 11742000,
      y: -4675170
    },
    {
      nanogenreId: 2508,
      x: -3145620,
      y: -10302060
    },
    {
      nanogenreId: 2509,
      x: 7666290,
      y: 18268080
    },
    {
      nanogenreId: 2510,
      x: 12366180,
      y: -4573200
    },
    {
      nanogenreId: 2511,
      x: -5432220,
      y: -6241800
    },
    {
      nanogenreId: 2512,
      x: 577830,
      y: 9130950
    },
    {
      nanogenreId: 2513,
      x: 7786800,
      y: -6634230
    },
    {
      nanogenreId: 2514,
      x: 775590,
      y: 395520
    },
    {
      nanogenreId: 2515,
      x: -7332570,
      y: -13021260
    },
    {
      nanogenreId: 2516,
      x: -843570,
      y: -194670
    },
    {
      nanogenreId: 2517,
      x: -4542300,
      y: 4468140
    },
    {
      nanogenreId: 2518,
      x: -13209750,
      y: -7107000
    },
    {
      nanogenreId: 2519,
      x: 4162230,
      y: 9464670
    },
    {
      nanogenreId: 2520,
      x: -58710,
      y: -584010
    },
    {
      nanogenreId: 2521,
      x: 9433770,
      y: -8358450
    },
    {
      nanogenreId: 2522,
      x: 4761690,
      y: 8432610
    },
    {
      nanogenreId: 2523,
      x: -225570,
      y: 7854780
    },
    {
      nanogenreId: 2524,
      x: -1100040,
      y: 10555440
    },
    {
      nanogenreId: 2525,
      x: 16466610,
      y: 4017000
    },
    {
      nanogenreId: 2526,
      x: -7249140,
      y: -1764390
    },
    {
      nanogenreId: 2527,
      x: 2311320,
      y: 8126700
    },
    {
      nanogenreId: 2528,
      x: -8191590,
      y: -12650460
    },
    {
      nanogenreId: 2529,
      x: 6164550,
      y: -6986490
    },
    {
      nanogenreId: 2530,
      x: -951720,
      y: -5370420
    },
    {
      nanogenreId: 2531,
      x: 8355360,
      y: -5972970
    },
    {
      nanogenreId: 2532,
      x: 1495560,
      y: 9081510
    },
    {
      nanogenreId: 2533,
      x: -874470,
      y: -8101980
    },
    {
      nanogenreId: 2534,
      x: -3145620,
      y: -1850910
    },
    {
      nanogenreId: 2535,
      x: 7483980,
      y: -6186180
    },
    {
      nanogenreId: 2536,
      x: 5528010,
      y: 10237170
    },
    {
      nanogenreId: 2537,
      x: 7737360,
      y: -10561620
    },
    {
      nanogenreId: 2538,
      x: -5249910,
      y: 9421410
    },
    {
      nanogenreId: 2539,
      x: 7737360,
      y: 713790
    },
    {
      nanogenreId: 2540,
      x: 1752030,
      y: 2876790
    },
    {
      nanogenreId: 2541,
      x: -6374670,
      y: 17575920
    },
    {
      nanogenreId: 2542,
      x: 4733880,
      y: -11624580
    },
    {
      nanogenreId: 2543,
      x: 6961770,
      y: 10289700
    },
    {
      nanogenreId: 2544,
      x: -3729630,
      y: 10855170
    },
    {
      nanogenreId: 2545,
      x: 1857090,
      y: 2286600
    },
    {
      nanogenreId: 2546,
      x: -1171110,
      y: 9628440
    },
    {
      nanogenreId: 2547,
      x: 2462730,
      y: 1696410
    },
    {
      nanogenreId: 2548,
      x: -4872930,
      y: 8355360
    },
    {
      nanogenreId: 2549,
      x: -2246430,
      y: 8546940
    },
    {
      nanogenreId: 2550,
      x: -18540,
      y: 6028590
    },
    {
      nanogenreId: 2551,
      x: -5633070,
      y: 4353810
    },
    {
      nanogenreId: 2552,
      x: -1047510,
      y: 1495560
    },
    {
      nanogenreId: 2553,
      x: -3760530,
      y: -8936280
    },
    {
      nanogenreId: 2554,
      x: -1096950,
      y: -6464280
    },
    {
      nanogenreId: 2555,
      x: 6791820,
      y: -6303600
    },
    {
      nanogenreId: 2556,
      x: -12013920,
      y: -772500
    },
    {
      nanogenreId: 2557,
      x: -4999620,
      y: 5132490
    },
    {
      nanogenreId: 2558,
      x: -448050,
      y: 5855550
    },
    {
      nanogenreId: 2559,
      x: -11871780,
      y: -11151810
    },
    {
      nanogenreId: 2560,
      x: 8849760,
      y: -8009280
    },
    {
      nanogenreId: 2561,
      x: 6028590,
      y: -3760530
    },
    {
      nanogenreId: 2562,
      x: 710700,
      y: 5132490
    },
    {
      nanogenreId: 2563,
      x: 4678260,
      y: 11059110
    },
    {
      nanogenreId: 2564,
      x: 2771730,
      y: -7360380
    },
    {
      nanogenreId: 2565,
      x: -302820,
      y: 6272700
    },
    {
      nanogenreId: 2566,
      x: 5716500,
      y: 9538830
    },
    {
      nanogenreId: 2567,
      x: -9702600,
      y: -5728860
    },
    {
      nanogenreId: 2568,
      x: -4489770,
      y: -655080
    },
    {
      nanogenreId: 2569,
      x: 8213220,
      y: 7039020
    },
    {
      nanogenreId: 2570,
      x: -12733890,
      y: 472770
    },
    {
      nanogenreId: 2571,
      x: 7286220,
      y: 9421410
    },
    {
      nanogenreId: 2572,
      x: 2366940,
      y: -157590
    },
    {
      nanogenreId: 2573,
      x: 8182320,
      y: -6251070
    },
    {
      nanogenreId: 2574,
      x: 9761310,
      y: -8191590
    },
    {
      nanogenreId: 2575,
      x: 5447670,
      y: -2221710
    },
    {
      nanogenreId: 2576,
      x: 3769800,
      y: -3077640
    },
    {
      nanogenreId: 2577,
      x: 1174200,
      y: 10076490
    },
    {
      nanogenreId: 2578,
      x: -6198540,
      y: -6121290
    },
    {
      nanogenreId: 2579,
      x: -1164930,
      y: 386250
    },
    {
      nanogenreId: 2580,
      x: 781770,
      y: 9331800
    },
    {
      nanogenreId: 2581,
      x: -10920060,
      y: 4100430
    },
    {
      nanogenreId: 2582,
      x: 5954430,
      y: -9616080
    },
    {
      nanogenreId: 2583,
      x: -3049830,
      y: 3337200
    },
    {
      nanogenreId: 2584,
      x: 5806110,
      y: 8911560
    },
    {
      nanogenreId: 2585,
      x: -519120,
      y: -3120900
    },
    {
      nanogenreId: 2586,
      x: 10821180,
      y: -991890
    },
    {
      nanogenreId: 2587,
      x: -11309400,
      y: 2091930
    },
    {
      nanogenreId: 2588,
      x: 10422570,
      y: -7391280
    },
    {
      nanogenreId: 2589,
      x: 1866360,
      y: 10209360
    },
    {
      nanogenreId: 2590,
      x: -290460,
      y: 1467750
    },
    {
      nanogenreId: 2591,
      x: -3942840,
      y: -6674400
    },
    {
      nanogenreId: 2592,
      x: -1996140,
      y: -392430
    },
    {
      nanogenreId: 2593,
      x: 4706070,
      y: -4934730
    },
    {
      nanogenreId: 2594,
      x: -7224420,
      y: 1007340
    },
    {
      nanogenreId: 2595,
      x: -10963320,
      y: 4715340
    },
    {
      nanogenreId: 2596,
      x: -9211290,
      y: 4659720
    },
    {
      nanogenreId: 2597,
      x: 1245270,
      y: 4702980
    },
    {
      nanogenreId: 2598,
      x: 8305920,
      y: -435690
    },
    {
      nanogenreId: 2599,
      x: -2777910,
      y: 6958680
    },
    {
      nanogenreId: 2600,
      x: -6180,
      y: 1232910
    },
    {
      nanogenreId: 2601,
      x: 2014680,
      y: 6090390
    },
    {
      nanogenreId: 2602,
      x: -4610280,
      y: -185400
    },
    {
      nanogenreId: 2603,
      x: 8503680,
      y: -7224420
    },
    {
      nanogenreId: 2604,
      x: -1059870,
      y: -14380860
    },
    {
      nanogenreId: 2605,
      x: 14615700,
      y: -1480110
    },
    {
      nanogenreId: 2606,
      x: 2691390,
      y: 6146010
    },
    {
      nanogenreId: 2607,
      x: 5388960,
      y: 9526470
    },
    {
      nanogenreId: 2608,
      x: 448050,
      y: 8757060
    },
    {
      nanogenreId: 2609,
      x: 37080,
      y: 4326000
    },
    {
      nanogenreId: 2610,
      x: 5694870,
      y: 8982630
    },
    {
      nanogenreId: 2611,
      x: -3247590,
      y: -6396300
    },
    {
      nanogenreId: 2612,
      x: 6034770,
      y: -9155670
    },
    {
      nanogenreId: 2613,
      x: 16083450,
      y: -574740
    },
    {
      nanogenreId: 2614,
      x: -5543460,
      y: -2651220
    },
    {
      nanogenreId: 2615,
      x: -3695640,
      y: 5185020
    },
    {
      nanogenreId: 2616,
      x: 2181540,
      y: -346080
    },
    {
      nanogenreId: 2617,
      x: 7725000,
      y: 3640020
    },
    {
      nanogenreId: 2618,
      x: 3921210,
      y: 9748950
    },
    {
      nanogenreId: 2619,
      x: -4301280,
      y: 923910
    },
    {
      nanogenreId: 2620,
      x: -2033220,
      y: 5738130
    },
    {
      nanogenreId: 2621,
      x: -302820,
      y: 927000
    },
    {
      nanogenreId: 2622,
      x: 3256860,
      y: -5299350
    },
    {
      nanogenreId: 2623,
      x: 7860960,
      y: -7564320
    },
    {
      nanogenreId: 2624,
      x: 4956360,
      y: 6615690
    },
    {
      nanogenreId: 2625,
      x: 3185790,
      y: 9291630
    },
    {
      nanogenreId: 2626,
      x: -13456950,
      y: 10580160
    },
    {
      nanogenreId: 2627,
      x: 14275800,
      y: 744690
    },
    {
      nanogenreId: 2628,
      x: 1946700,
      y: -5042880
    },
    {
      nanogenreId: 2629,
      x: -4499040,
      y: -10413300
    },
    {
      nanogenreId: 2630,
      x: -4616460,
      y: 3195060
    },
    {
      nanogenreId: 2631,
      x: 5917350,
      y: 188490
    },
    {
      nanogenreId: 2632,
      x: -4094250,
      y: -3170340
    },
    {
      nanogenreId: 2633,
      x: 6396300,
      y: 7731180
    },
    {
      nanogenreId: 2634,
      x: 4224030,
      y: 10268070
    },
    {
      nanogenreId: 2635,
      x: 7582860,
      y: -4900740
    },
    {
      nanogenreId: 2636,
      x: -15332580,
      y: -614910
    },
    {
      nanogenreId: 2637,
      x: 5558910,
      y: 10954050
    },
    {
      nanogenreId: 2638,
      x: -6838170,
      y: 6785640
    },
    {
      nanogenreId: 2639,
      x: 6606420,
      y: 6068760
    },
    {
      nanogenreId: 2640,
      x: 4616460,
      y: 9955980
    },
    {
      nanogenreId: 2641,
      x: -4888380,
      y: -5880270
    },
    {
      nanogenreId: 2642,
      x: -8343000,
      y: 3482430
    },
    {
      nanogenreId: 2643,
      x: 2592510,
      y: -6090390
    },
    {
      nanogenreId: 2644,
      x: -6940140,
      y: 7595220
    },
    {
      nanogenreId: 2645,
      x: 7094640,
      y: 9220560
    },
    {
      nanogenreId: 2646,
      x: -11958300,
      y: -12675180
    },
    {
      nanogenreId: 2647,
      x: 7100820,
      y: 11429910
    },
    {
      nanogenreId: 2648,
      x: -825030,
      y: -333720
    },
    {
      nanogenreId: 2649,
      x: -8855940,
      y: -6992670
    },
    {
      nanogenreId: 2650,
      x: -12563940,
      y: -3253770
    },
    {
      nanogenreId: 2651,
      x: 5132490,
      y: 4483590
    },
    {
      nanogenreId: 2652,
      x: 5586720,
      y: -7842420
    },
    {
      nanogenreId: 2653,
      x: 1755120,
      y: 6455010
    },
    {
      nanogenreId: 2654,
      x: 5979150,
      y: 7758990
    },
    {
      nanogenreId: 2655,
      x: 55620,
      y: -9881820
    },
    {
      nanogenreId: 2656,
      x: 1606800,
      y: -1096950
    },
    {
      nanogenreId: 2657,
      x: 6677490,
      y: -5469300
    },
    {
      nanogenreId: 2658,
      x: 6504450,
      y: -2462730
    },
    {
      nanogenreId: 2659,
      x: -5901900,
      y: 4644270
    },
    {
      nanogenreId: 2660,
      x: 3099270,
      y: -5005800
    },
    {
      nanogenreId: 2661,
      x: 5605260,
      y: -9242190
    },
    {
      nanogenreId: 2662,
      x: 5512560,
      y: 9699510
    },
    {
      nanogenreId: 2663,
      x: -6056400,
      y: -8893020
    },
    {
      nanogenreId: 2664,
      x: -516030,
      y: 9195840
    },
    {
      nanogenreId: 2665,
      x: 2203170,
      y: 899190
    },
    {
      nanogenreId: 2666,
      x: -4477410,
      y: 8197770
    },
    {
      nanogenreId: 2667,
      x: 4746240,
      y: 2740830
    },
    {
      nanogenreId: 2668,
      x: -4326000,
      y: 8516040
    },
    {
      nanogenreId: 2669,
      x: 7329480,
      y: -5929710
    },
    {
      nanogenreId: 2670,
      x: 3210510,
      y: -2719200
    },
    {
      nanogenreId: 2671,
      x: 7830060,
      y: -3664740
    },
    {
      nanogenreId: 2672,
      x: -7555050,
      y: 7208970
    },
    {
      nanogenreId: 2673,
      x: -309000,
      y: 4270380
    },
    {
      nanogenreId: 2674,
      x: 5707230,
      y: 10005420
    },
    {
      nanogenreId: 2675,
      x: 2462730,
      y: 5843190
    },
    {
      nanogenreId: 2676,
      x: -1532640,
      y: -7898040
    },
    {
      nanogenreId: 2677,
      x: 5960610,
      y: 10876800
    },
    {
      nanogenreId: 2678,
      x: 4545390,
      y: -5904990
    },
    {
      nanogenreId: 2679,
      x: -7629210,
      y: 6331410
    },
    {
      nanogenreId: 2680,
      x: 661260,
      y: 12134430
    },
    {
      nanogenreId: 2681,
      x: -7867140,
      y: 3905760
    },
    {
      nanogenreId: 2682,
      x: 5651610,
      y: 9381240
    },
    {
      nanogenreId: 2683,
      x: 2178450,
      y: -1470840
    },
    {
      nanogenreId: 2684,
      x: -5234460,
      y: 8614920
    },
    {
      nanogenreId: 2685,
      x: -3148710,
      y: 7851690
    },
    {
      nanogenreId: 2686,
      x: -8753970,
      y: -5546550
    },
    {
      nanogenreId: 2687,
      x: 6624960,
      y: 9943620
    },
    {
      nanogenreId: 2688,
      x: -1149480,
      y: -590190
    },
    {
      nanogenreId: 2689,
      x: 11216700,
      y: -188490
    },
    {
      nanogenreId: 2690,
      x: 5268450,
      y: 9918900
    },
    {
      nanogenreId: 2691,
      x: -4010820,
      y: 15450
    },
    {
      nanogenreId: 2692,
      x: 4397070,
      y: 9656250
    },
    {
      nanogenreId: 2693,
      x: 4514490,
      y: 10224810
    },
    {
      nanogenreId: 2694,
      x: 5126310,
      y: 1198920
    },
    {
      nanogenreId: 2695,
      x: -1996140,
      y: 4242570
    },
    {
      nanogenreId: 2696,
      x: 2428740,
      y: 6927780
    },
    {
      nanogenreId: 2697,
      x: 5568180,
      y: 9183480
    },
    {
      nanogenreId: 2698,
      x: -3327930,
      y: -7678650
    },
    {
      nanogenreId: 2699,
      x: 5469300,
      y: -7221330
    },
    {
      nanogenreId: 2700,
      x: 8877570,
      y: 17909640
    },
    {
      nanogenreId: 2701,
      x: -7045200,
      y: 4517580
    },
    {
      nanogenreId: 2702,
      x: -3343380,
      y: -3738900
    },
    {
      nanogenreId: 2703,
      x: -14776380,
      y: 370800
    },
    {
      nanogenreId: 2704,
      x: -10243350,
      y: -5015070
    },
    {
      nanogenreId: 2705,
      x: 4727700,
      y: 10638870
    },
    {
      nanogenreId: 2706,
      x: -1350330,
      y: 7317120
    },
    {
      nanogenreId: 2707,
      x: -6470460,
      y: 6878340
    },
    {
      nanogenreId: 2708,
      x: 12502140,
      y: 4610280
    },
    {
      nanogenreId: 2709,
      x: 12832770,
      y: 1739670
    },
    {
      nanogenreId: 2710,
      x: -8021640,
      y: 3439170
    },
    {
      nanogenreId: 2711,
      x: 10017780,
      y: -8837400
    },
    {
      nanogenreId: 2712,
      x: -2258790,
      y: -7051380
    },
    {
      nanogenreId: 2713,
      x: 8964090,
      y: -8067990
    },
    {
      nanogenreId: 2714,
      x: 5753580,
      y: 10110480
    },
    {
      nanogenreId: 2715,
      x: 9007350,
      y: -5487840
    },
    {
      nanogenreId: 2716,
      x: -9878730,
      y: -1492470
    },
    {
      nanogenreId: 2717,
      x: 7273860,
      y: -7060650
    },
    {
      nanogenreId: 2718,
      x: 5589810,
      y: 8361540
    },
    {
      nanogenreId: 2719,
      x: 3930480,
      y: -4690620
    },
    {
      nanogenreId: 2720,
      x: 4193130,
      y: -1396680
    },
    {
      nanogenreId: 2721,
      x: -222480,
      y: 543840
    },
    {
      nanogenreId: 2722,
      x: 9538830,
      y: -9146400
    },
    {
      nanogenreId: 2723,
      x: -1035150,
      y: 142140
    },
    {
      nanogenreId: 2724,
      x: -10110480,
      y: 3513330
    },
    {
      nanogenreId: 2725,
      x: -6730020,
      y: -3136350
    },
    {
      nanogenreId: 2726,
      x: -18039420,
      y: 4610280
    },
    {
      nanogenreId: 2727,
      x: 4548480,
      y: 4131330
    },
    {
      nanogenreId: 2728,
      x: 5852460,
      y: 19550430
    },
    {
      nanogenreId: 2729,
      x: -10675950,
      y: 6044040
    },
    {
      nanogenreId: 2730,
      x: -2135190,
      y: 2713020
    },
    {
      nanogenreId: 2731,
      x: -7416000,
      y: 2635770
    },
    {
      nanogenreId: 2732,
      x: 9047520,
      y: -3556590
    },
    {
      nanogenreId: 2733,
      x: -1733490,
      y: 2490540
    },
    {
      nanogenreId: 2734,
      x: -9279270,
      y: -5651610
    },
    {
      nanogenreId: 2735,
      x: 6538440,
      y: 9532650
    },
    {
      nanogenreId: 2736,
      x: 1032060,
      y: -2706840
    },
    {
      nanogenreId: 2737,
      x: 9498660,
      y: -4808040
    },
    {
      nanogenreId: 2738,
      x: -7805340,
      y: 5079960
    },
    {
      nanogenreId: 2739,
      x: 8930100,
      y: -9096960
    },
    {
      nanogenreId: 2740,
      x: 11902680,
      y: 4996530
    },
    {
      nanogenreId: 2741,
      x: 3596760,
      y: -1545000
    },
    {
      nanogenreId: 2742,
      x: 7425270,
      y: 4026270
    },
    {
      nanogenreId: 2743,
      x: 6989580,
      y: -8407890
    },
    {
      nanogenreId: 2744,
      x: -14597160,
      y: -7545780
    },
    {
      nanogenreId: 2745,
      x: -5206650,
      y: 7604490
    },
    {
      nanogenreId: 2746,
      x: -512940,
      y: 3479340
    },
    {
      nanogenreId: 2747,
      x: -4628820,
      y: 309000
    },
    {
      nanogenreId: 2748,
      x: -7978380,
      y: -14195460
    },
    {
      nanogenreId: 2749,
      x: 67980,
      y: 8744700
    },
    {
      nanogenreId: 2750,
      x: 4523760,
      y: 9684060
    },
    {
      nanogenreId: 2751,
      x: 1334880,
      y: 7919670
    },
    {
      nanogenreId: 2752,
      x: 9714960,
      y: -7706460
    },
    {
      nanogenreId: 2753,
      x: -5117040,
      y: -6720750
    },
    {
      nanogenreId: 2754,
      x: 1087680,
      y: 8988810
    },
    {
      nanogenreId: 2755,
      x: 9013530,
      y: -6575520
    },
    {
      nanogenreId: 2756,
      x: 4332180,
      y: 9164940
    },
    {
      nanogenreId: 2757,
      x: 7215150,
      y: 315180
    },
    {
      nanogenreId: 2758,
      x: 7239870,
      y: -7558140
    },
    {
      nanogenreId: 2759,
      x: 4480500,
      y: -95790
    },
    {
      nanogenreId: 2760,
      x: 5086140,
      y: 10558530
    },
    {
      nanogenreId: 2761,
      x: 6180,
      y: -1078410
    },
    {
      nanogenreId: 2762,
      x: 4007730,
      y: 1974510
    },
    {
      nanogenreId: 2763,
      x: -3232140,
      y: -1983780
    },
    {
      nanogenreId: 2764,
      x: 5036700,
      y: 10892250
    },
    {
      nanogenreId: 2765,
      x: 3680190,
      y: -543840
    },
    {
      nanogenreId: 2766,
      x: 1483200,
      y: -2876790
    },
    {
      nanogenreId: 2767,
      x: 3408270,
      y: 6192360
    },
    {
      nanogenreId: 2768,
      x: 1548090,
      y: 8927010
    },
    {
      nanogenreId: 2769,
      x: 627270,
      y: -10104300
    },
    {
      nanogenreId: 2770,
      x: -3256860,
      y: 3924300
    },
    {
      nanogenreId: 2771,
      x: 5731950,
      y: 10385490
    },
    {
      nanogenreId: 2772,
      x: 8991900,
      y: -7876410
    },
    {
      nanogenreId: 2773,
      x: -1634610,
      y: 5188110
    },
    {
      nanogenreId: 2774,
      x: -7020480,
      y: 2048670
    },
    {
      nanogenreId: 2775,
      x: -685980,
      y: 4740060
    },
    {
      nanogenreId: 2776,
      x: 3188880,
      y: -4495950
    },
    {
      nanogenreId: 2777,
      x: 11494800,
      y: 18234090
    },
    {
      nanogenreId: 2778,
      x: 10842810,
      y: -4613370
    },
    {
      nanogenreId: 2779,
      x: -3417540,
      y: -203940
    },
    {
      nanogenreId: 2780,
      x: 3751260,
      y: 8624190
    },
    {
      nanogenreId: 2781,
      x: -2376210,
      y: -9884910
    },
    {
      nanogenreId: 2782,
      x: -352260,
      y: -4032450
    },
    {
      nanogenreId: 2783,
      x: 899190,
      y: -7932030
    },
    {
      nanogenreId: 2784,
      x: -10005420,
      y: 1650060
    },
    {
      nanogenreId: 2785,
      x: 1446120,
      y: -5623800
    },
    {
      nanogenreId: 2786,
      x: 9575910,
      y: -6229440
    },
    {
      nanogenreId: 2787,
      x: -6408660,
      y: 3392820
    },
    {
      nanogenreId: 2788,
      x: -809580,
      y: 2481270
    },
    {
      nanogenreId: 2789,
      x: 4214760,
      y: 9532650
    },
    {
      nanogenreId: 2790,
      x: 8614920,
      y: -8135970
    },
    {
      nanogenreId: 2791,
      x: -2175360,
      y: -5969880
    },
    {
      nanogenreId: 2792,
      x: 6349950,
      y: -6884520
    },
    {
      nanogenreId: 2793,
      x: 5772120,
      y: 8491320
    },
    {
      nanogenreId: 2794,
      x: 2975670,
      y: -2431830
    },
    {
      nanogenreId: 2795,
      x: -2493630,
      y: 4177680
    },
    {
      nanogenreId: 2796,
      x: -664350,
      y: 2132100
    },
    {
      nanogenreId: 2797,
      x: -1918890,
      y: -8135970
    },
    {
      nanogenreId: 2798,
      x: 1925070,
      y: -3723450
    },
    {
      nanogenreId: 2799,
      x: 5898810,
      y: 8092710
    },
    {
      nanogenreId: 2800,
      x: 12304380,
      y: 1362690
    },
    {
      nanogenreId: 2801,
      x: -6961770,
      y: 6195450
    },
    {
      nanogenreId: 2802,
      x: 16809600,
      y: 8037090
    },
    {
      nanogenreId: 2803,
      x: 8423340,
      y: -8364630
    },
    {
      nanogenreId: 2804,
      x: -247200,
      y: 2805720
    },
    {
      nanogenreId: 2805,
      x: 27810,
      y: 7366560
    },
    {
      nanogenreId: 2806,
      x: 7657020,
      y: 7110090
    },
    {
      nanogenreId: 2807,
      x: -1443030,
      y: 4298190
    },
    {
      nanogenreId: 2808,
      x: 9022800,
      y: -8197770
    },
    {
      nanogenreId: 2809,
      x: 4100430,
      y: 10941690
    },
    {
      nanogenreId: 2810,
      x: 1560450,
      y: 3528780
    },
    {
      nanogenreId: 2811,
      x: 8753970,
      y: -2530710
    },
    {
      nanogenreId: 2812,
      x: 2339130,
      y: 6417930
    },
    {
      nanogenreId: 2813,
      x: -534570,
      y: 5657790
    },
    {
      nanogenreId: 2814,
      x: 4091160,
      y: 3547320
    },
    {
      nanogenreId: 2815,
      x: -5095410,
      y: 6090390
    },
    {
      nanogenreId: 2816,
      x: 8157600,
      y: -7156440
    },
    {
      nanogenreId: 2817,
      x: 3952110,
      y: 6764010
    },
    {
      nanogenreId: 2818,
      x: 3062190,
      y: 6618780
    },
    {
      nanogenreId: 2819,
      x: 5178840,
      y: 8865210
    },
    {
      nanogenreId: 2820,
      x: -13299360,
      y: 593280
    },
    {
      nanogenreId: 2821,
      x: -5484750,
      y: 5271540
    },
    {
      nanogenreId: 2822,
      x: 200850,
      y: 9708780
    },
    {
      nanogenreId: 2823,
      x: 5942070,
      y: 9801480
    },
    {
      nanogenreId: 2824,
      x: 6754740,
      y: -4817310
    },
    {
      nanogenreId: 2825,
      x: 247200,
      y: -1483200
    },
    {
      nanogenreId: 2826,
      x: -2558520,
      y: -2212440
    },
    {
      nanogenreId: 2827,
      x: -2422560,
      y: 3253770
    },
    {
      nanogenreId: 2828,
      x: 7922760,
      y: -6631140
    },
    {
      nanogenreId: 2829,
      x: -6161460,
      y: -9279270
    },
    {
      nanogenreId: 2830,
      x: 8191590,
      y: -8336820
    },
    {
      nanogenreId: 2831,
      x: -4647360,
      y: -5670150
    },
    {
      nanogenreId: 2832,
      x: 4468140,
      y: -8667450
    },
    {
      nanogenreId: 2833,
      x: -8596380,
      y: 1606800
    },
    {
      nanogenreId: 2834,
      x: -8095800,
      y: 11327940
    },
    {
      nanogenreId: 2835,
      x: -3139440,
      y: -10907700
    },
    {
      nanogenreId: 2836,
      x: 9032070,
      y: -7276950
    },
    {
      nanogenreId: 2837,
      x: -908460,
      y: 9353430
    },
    {
      nanogenreId: 2838,
      x: 3507150,
      y: 11139450
    },
    {
      nanogenreId: 2839,
      x: 5388960,
      y: 10329870
    },
    {
      nanogenreId: 2840,
      x: 5450760,
      y: -8067990
    },
    {
      nanogenreId: 2841,
      x: 4372350,
      y: 8343000
    },
    {
      nanogenreId: 2842,
      x: 8828130,
      y: -2354580
    },
    {
      nanogenreId: 2843,
      x: -7397460,
      y: 4823490
    },
    {
      nanogenreId: 2844,
      x: 778680,
      y: 2907690
    },
    {
      nanogenreId: 2845,
      x: 7320210,
      y: 985710
    },
    {
      nanogenreId: 2846,
      x: -3843960,
      y: 1708770
    },
    {
      nanogenreId: 2847,
      x: 9087690,
      y: -6702210
    },
    {
      nanogenreId: 2848,
      x: 1776750,
      y: 6093480
    },
    {
      nanogenreId: 2849,
      x: -3939750,
      y: 4950180
    },
    {
      nanogenreId: 2850,
      x: -5271540,
      y: -333720
    },
    {
      nanogenreId: 2851,
      x: 698340,
      y: 293550
    },
    {
      nanogenreId: 2852,
      x: -2651220,
      y: 6597150
    },
    {
      nanogenreId: 2853,
      x: -5181930,
      y: 3244500
    },
    {
      nanogenreId: 2854,
      x: -8003100,
      y: 4761690
    },
    {
      nanogenreId: 2855,
      x: 4495950,
      y: -4409430
    },
    {
      nanogenreId: 2856,
      x: 3581310,
      y: 10660500
    },
    {
      nanogenreId: 2857,
      x: -13849380,
      y: 4322910
    },
    {
      nanogenreId: 2858,
      x: -2459640,
      y: -1016610
    },
    {
      nanogenreId: 2859,
      x: 3525690,
      y: 27810
    },
    {
      nanogenreId: 2860,
      x: 4635000,
      y: 7434540
    },
    {
      nanogenreId: 2861,
      x: -2963310,
      y: 2332950
    },
    {
      nanogenreId: 2862,
      x: -991890,
      y: 5972970
    },
    {
      nanogenreId: 2863,
      x: -324450,
      y: 1708770
    },
    {
      nanogenreId: 2864,
      x: -5821560,
      y: -9659340
    },
    {
      nanogenreId: 2865,
      x: -4869840,
      y: -395520
    },
    {
      nanogenreId: 2866,
      x: 7283130,
      y: -9606810
    },
    {
      nanogenreId: 2867,
      x: 3939750,
      y: -4069530
    },
    {
      nanogenreId: 2868,
      x: 3167250,
      y: -6399390
    },
    {
      nanogenreId: 2869,
      x: 1946700,
      y: 8380080
    },
    {
      nanogenreId: 2870,
      x: -6300510,
      y: -2802630
    },
    {
      nanogenreId: 2871,
      x: -713790,
      y: 10057950
    },
    {
      nanogenreId: 2872,
      x: 5642340,
      y: 7706460
    },
    {
      nanogenreId: 2873,
      x: 6285060,
      y: 9424500
    },
    {
      nanogenreId: 2874,
      x: 1662420,
      y: 6031680
    },
    {
      nanogenreId: 2875,
      x: 6788730,
      y: -6473550
    },
    {
      nanogenreId: 2876,
      x: -5314800,
      y: 3012750
    },
    {
      nanogenreId: 2877,
      x: -5926620,
      y: -1399770
    },
    {
      nanogenreId: 2878,
      x: -9847830,
      y: -43260
    },
    {
      nanogenreId: 2879,
      x: 1959060,
      y: 265740
    },
    {
      nanogenreId: 2880,
      x: -3516420,
      y: 5314800
    },
    {
      nanogenreId: 2881,
      x: 2224800,
      y: -1118580
    },
    {
      nanogenreId: 2882,
      x: 9455400,
      y: -6813450
    },
    {
      nanogenreId: 2883,
      x: -3531870,
      y: 1857090
    },
    {
      nanogenreId: 2884,
      x: -6918510,
      y: 9443040
    },
    {
      nanogenreId: 2885,
      x: -4974900,
      y: 11531880
    },
    {
      nanogenreId: 2886,
      x: 12292020,
      y: -2845890
    },
    {
      nanogenreId: 2887,
      x: 1545000,
      y: -6031680
    },
    {
      nanogenreId: 2888,
      x: 9504840,
      y: -7283130
    },
    {
      nanogenreId: 2889,
      x: 182310,
      y: 7187340
    },
    {
      nanogenreId: 2890,
      x: 1415220,
      y: -621090
    },
    {
      nanogenreId: 2891,
      x: 1260720,
      y: 9545010
    },
    {
      nanogenreId: 2892,
      x: -1140210,
      y: -2567790
    },
    {
      nanogenreId: 2893,
      x: 3142530,
      y: 8849760
    },
    {
      nanogenreId: 2894,
      x: -1347240,
      y: 6643500
    },
    {
      nanogenreId: 2895,
      x: 1869450,
      y: -318270
    },
    {
      nanogenreId: 2896,
      x: -2706840,
      y: 4347630
    },
    {
      nanogenreId: 2897,
      x: 3327930,
      y: 9730410
    },
    {
      nanogenreId: 2898,
      x: -1322520,
      y: -7057560
    },
    {
      nanogenreId: 2899,
      x: 4208580,
      y: -5775210
    },
    {
      nanogenreId: 2900,
      x: 2212440,
      y: 7069920
    },
    {
      nanogenreId: 2901,
      x: 11408280,
      y: 1028970
    },
    {
      nanogenreId: 2902,
      x: -7641570,
      y: -2076480
    },
    {
      nanogenreId: 2903,
      x: 5185020,
      y: 9347250
    },
    {
      nanogenreId: 2904,
      x: -9260730,
      y: 6281970
    },
    {
      nanogenreId: 2905,
      x: -12183870,
      y: -1164930
    },
    {
      nanogenreId: 2906,
      x: 3157980,
      y: -9198930
    },
    {
      nanogenreId: 2907,
      x: 3139440,
      y: 9010440
    },
    {
      nanogenreId: 2908,
      x: -14288160,
      y: -4149870
    },
    {
      nanogenreId: 2909,
      x: -945540,
      y: 1980690
    },
    {
      nanogenreId: 2910,
      x: 775590,
      y: -2799540
    },
    {
      nanogenreId: 2911,
      x: 17143320,
      y: 14346870
    },
    {
      nanogenreId: 2912,
      x: 3287760,
      y: -6668220
    },
    {
      nanogenreId: 2913,
      x: 7431450,
      y: -7162620
    },
    {
      nanogenreId: 2914,
      x: 9801480,
      y: -6093480
    },
    {
      nanogenreId: 2915,
      x: 10206270,
      y: 1412130
    },
    {
      nanogenreId: 2916,
      x: -200850,
      y: -732330
    },
    {
      nanogenreId: 2917,
      x: 4477410,
      y: 8213220
    },
    {
      nanogenreId: 2918,
      x: 772500,
      y: 2184630
    },
    {
      nanogenreId: 2919,
      x: -4542300,
      y: 3680190
    },
    {
      nanogenreId: 2920,
      x: 296640,
      y: 4635000
    },
    {
      nanogenreId: 2921,
      x: -4795680,
      y: 3584400
    },
    {
      nanogenreId: 2922,
      x: 1662420,
      y: -296640
    },
    {
      nanogenreId: 2923,
      x: 3281580,
      y: 8046360
    },
    {
      nanogenreId: 2924,
      x: 4316730,
      y: -5058330
    },
    {
      nanogenreId: 2925,
      x: 6074940,
      y: 10416390
    },
    {
      nanogenreId: 2926,
      x: 10858260,
      y: -9739680
    },
    {
      nanogenreId: 2927,
      x: -1180380,
      y: 2200080
    },
    {
      nanogenreId: 2928,
      x: -7981470,
      y: 5957520
    },
    {
      nanogenreId: 2929,
      x: 3105450,
      y: -3840870
    },
    {
      nanogenreId: 2930,
      x: 1254540,
      y: 7545780
    },
    {
      nanogenreId: 2931,
      x: 8843580,
      y: -8967180
    },
    {
      nanogenreId: 2932,
      x: 6044040,
      y: 10805730
    },
    {
      nanogenreId: 2933,
      x: 8290470,
      y: -8355360
    },
    {
      nanogenreId: 2934,
      x: 5898810,
      y: -10889160
    },
    {
      nanogenreId: 2935,
      x: 5614530,
      y: 4962540
    },
    {
      nanogenreId: 2936,
      x: 846660,
      y: 6322140
    },
    {
      nanogenreId: 2937,
      x: 1121670,
      y: 4159140
    },
    {
      nanogenreId: 2938,
      x: -4097340,
      y: -5219010
    },
    {
      nanogenreId: 2939,
      x: -435690,
      y: 7150260
    },
    {
      nanogenreId: 2940,
      x: 522210,
      y: 7286220
    },
    {
      nanogenreId: 2941,
      x: -3420630,
      y: 5608350
    },
    {
      nanogenreId: 2942,
      x: 6173820,
      y: 10472010
    },
    {
      nanogenreId: 2943,
      x: 4156050,
      y: 9322530
    },
    {
      nanogenreId: 2944,
      x: -2240250,
      y: 4288920
    },
    {
      nanogenreId: 2945,
      x: 4220940,
      y: 9554280
    },
    {
      nanogenreId: 2946,
      x: -6940140,
      y: 4919280
    },
    {
      nanogenreId: 2947,
      x: -5911170,
      y: 5388960
    },
    {
      nanogenreId: 2948,
      x: 4582470,
      y: -6238710
    },
    {
      nanogenreId: 2949,
      x: -1538820,
      y: -361530
    },
    {
      nanogenreId: 2950,
      x: -8089620,
      y: 874470
    },
    {
      nanogenreId: 2951,
      x: 10227900,
      y: 4647360
    },
    {
      nanogenreId: 2952,
      x: -2363850,
      y: 6896880
    },
    {
      nanogenreId: 2953,
      x: 7323300,
      y: -6810360
    },
    {
      nanogenreId: 2954,
      x: 5540370,
      y: 9668610
    },
    {
      nanogenreId: 2955,
      x: 3402090,
      y: 11049840
    },
    {
      nanogenreId: 2956,
      x: 3698730,
      y: 9282360
    },
    {
      nanogenreId: 2957,
      x: 2808810,
      y: -5308620
    },
    {
      nanogenreId: 2958,
      x: 3788340,
      y: 9189660
    },
    {
      nanogenreId: 2959,
      x: -6977220,
      y: -6068760
    },
    {
      nanogenreId: 2960,
      x: -4984170,
      y: 1906530
    },
    {
      nanogenreId: 2961,
      x: 7653930,
      y: -6801090
    },
    {
      nanogenreId: 2962,
      x: -6257250,
      y: -95790
    },
    {
      nanogenreId: 2963,
      x: -11229060,
      y: 5132490
    },
    {
      nanogenreId: 2964,
      x: 4084980,
      y: 5796840
    },
    {
      nanogenreId: 2965,
      x: -4999620,
      y: -101970
    },
    {
      nanogenreId: 2966,
      x: 8145240,
      y: -5772120
    },
    {
      nanogenreId: 2967,
      x: 4672080,
      y: 2079570
    },
    {
      nanogenreId: 2968,
      x: 6606420,
      y: 10509090
    },
    {
      nanogenreId: 2969,
      x: -3711090,
      y: 6649680
    },
    {
      nanogenreId: 2970,
      x: 2614140,
      y: 9795300
    },
    {
      nanogenreId: 2971,
      x: -8080350,
      y: 5314800
    },
    {
      nanogenreId: 2972,
      x: -1900350,
      y: 1208190
    },
    {
      nanogenreId: 2973,
      x: -475860,
      y: 13929720
    },
    {
      nanogenreId: 2974,
      x: -1511010,
      y: 9164940
    },
    {
      nanogenreId: 2975,
      x: 16534590,
      y: -3457710
    },
    {
      nanogenreId: 2976,
      x: -1650060,
      y: 15910410
    },
    {
      nanogenreId: 2977,
      x: -13283910,
      y: 7187340
    },
    {
      nanogenreId: 2978,
      x: -5444580,
      y: 4505220
    },
    {
      nanogenreId: 2979,
      x: -6390120,
      y: 7431450
    },
    {
      nanogenreId: 2980,
      x: 846660,
      y: -2397840
    },
    {
      nanogenreId: 2981,
      x: -8389350,
      y: -1257630
    },
    {
      nanogenreId: 2982,
      x: 2085750,
      y: 5494020
    },
    {
      nanogenreId: 2983,
      x: -1838550,
      y: 7545780
    },
    {
      nanogenreId: 2984,
      x: 4715340,
      y: 9134040
    },
    {
      nanogenreId: 2985,
      x: -3837780,
      y: -1779840
    },
    {
      nanogenreId: 2986,
      x: -12495960,
      y: 13246830
    },
    {
      nanogenreId: 2987,
      x: 2994210,
      y: -4480500
    },
    {
      nanogenreId: 2988,
      x: 10200090,
      y: -6430290
    },
    {
      nanogenreId: 2989,
      x: -3201240,
      y: 6275790
    },
    {
      nanogenreId: 2990,
      x: 9931260,
      y: 6207810
    },
    {
      nanogenreId: 2991,
      x: 2709930,
      y: -13432230
    },
    {
      nanogenreId: 2992,
      x: -1767480,
      y: 1996140
    },
    {
      nanogenreId: 2993,
      x: 1066050,
      y: -4687530
    },
    {
      nanogenreId: 2994,
      x: 2651220,
      y: 7508700
    },
    {
      nanogenreId: 2995,
      x: -4903830,
      y: -692160
    },
    {
      nanogenreId: 2996,
      x: 4542300,
      y: 11436090
    },
    {
      nanogenreId: 2997,
      x: -5942070,
      y: -4452690
    },
    {
      nanogenreId: 2998,
      x: -8698350,
      y: -4607190
    },
    {
      nanogenreId: 2999,
      x: -6331410,
      y: -4727700
    },
    {
      nanogenreId: 3000,
      x: -4261110,
      y: 5413680
    },
    {
      nanogenreId: 3001,
      x: -3451530,
      y: 5871000
    },
    {
      nanogenreId: 3002,
      x: -618000,
      y: 10805730
    },
    {
      nanogenreId: 3003,
      x: 1223640,
      y: -3884130
    },
    {
      nanogenreId: 3004,
      x: -6714570,
      y: 2595600
    },
    {
      nanogenreId: 3005,
      x: 3417540,
      y: 9900360
    },
    {
      nanogenreId: 3006,
      x: 4579380,
      y: 1158750
    },
    {
      nanogenreId: 3007,
      x: 3689460,
      y: -2672850
    },
    {
      nanogenreId: 3008,
      x: 8064900,
      y: -6161460
    },
    {
      nanogenreId: 3009,
      x: -11392830,
      y: 7011210
    },
    {
      nanogenreId: 3010,
      x: 17316360,
      y: -1353420
    },
    {
      nanogenreId: 3011,
      x: -8071080,
      y: -4495950
    },
    {
      nanogenreId: 3012,
      x: -797220,
      y: -3775980
    },
    {
      nanogenreId: 3013,
      x: 1168020,
      y: -10577070
    },
    {
      nanogenreId: 3014,
      x: -5299350,
      y: -7150260
    },
    {
      nanogenreId: 3015,
      x: -1375050,
      y: 4999620
    },
    {
      nanogenreId: 3016,
      x: 5747400,
      y: 10295880
    },
    {
      nanogenreId: 3017,
      x: 2737740,
      y: 6414840
    },
    {
      nanogenreId: 3018,
      x: -4900740,
      y: -8994990
    },
    {
      nanogenreId: 3019,
      x: -661260,
      y: -321360
    },
    {
      nanogenreId: 3020,
      x: 11609130,
      y: -902280
    },
    {
      nanogenreId: 3021,
      x: -1937430,
      y: -5976060
    },
    {
      nanogenreId: 3022,
      x: -4199310,
      y: 7851690
    },
    {
      nanogenreId: 3023,
      x: -1121670,
      y: 6529170
    },
    {
      nanogenreId: 3024,
      x: 3816150,
      y: -4804950
    },
    {
      nanogenreId: 3025,
      x: -11708010,
      y: -4520670
    },
    {
      nanogenreId: 3026,
      x: -2478180,
      y: 7499430
    },
    {
      nanogenreId: 3027,
      x: 1001160,
      y: 4563930
    },
    {
      nanogenreId: 3028,
      x: 17693340,
      y: 1016610
    },
    {
      nanogenreId: 3029,
      x: -4906920,
      y: 4233300
    },
    {
      nanogenreId: 3030,
      x: -7842420,
      y: -1955970
    },
    {
      nanogenreId: 3031,
      x: -593280,
      y: 692160
    },
    {
      nanogenreId: 3032,
      x: 2916960,
      y: 1875630
    },
    {
      nanogenreId: 3033,
      x: 2438010,
      y: 5268450
    },
    {
      nanogenreId: 3034,
      x: 5830830,
      y: 10030140
    },
    {
      nanogenreId: 3035,
      x: 2972580,
      y: 7131720
    },
    {
      nanogenreId: 3036,
      x: -3324840,
      y: -5262270
    },
    {
      nanogenreId: 3037,
      x: 3238320,
      y: 7032840
    },
    {
      nanogenreId: 3038,
      x: -6856710,
      y: 4165320
    },
    {
      nanogenreId: 3039,
      x: -1205100,
      y: 5911170
    },
    {
      nanogenreId: 3040,
      x: 9952890,
      y: -9205110
    },
    {
      nanogenreId: 3041,
      x: 6278880,
      y: 9214380
    },
    {
      nanogenreId: 3042,
      x: -98880,
      y: 7045200
    },
    {
      nanogenreId: 3043,
      x: 4872930,
      y: 7842420
    },
    {
      nanogenreId: 3044,
      x: 4427970,
      y: 370800
    },
    {
      nanogenreId: 3045,
      x: -3970650,
      y: 5333340
    },
    {
      nanogenreId: 3046,
      x: 621090,
      y: 8089620
    },
    {
      nanogenreId: 3047,
      x: -1504830,
      y: 8237940
    },
    {
      nanogenreId: 3048,
      x: 4545390,
      y: 7962930
    },
    {
      nanogenreId: 3049,
      x: 135960,
      y: 8584020
    },
    {
      nanogenreId: 3050,
      x: 2743920,
      y: 565470
    },
    {
      nanogenreId: 3051,
      x: 4288920,
      y: 825030
    },
    {
      nanogenreId: 3052,
      x: -1776750,
      y: 3575130
    },
    {
      nanogenreId: 3053,
      x: -4845120,
      y: 8114340
    },
    {
      nanogenreId: 3054,
      x: -463500,
      y: 1307070
    },
    {
      nanogenreId: 3055,
      x: 7369650,
      y: -8781780
    },
    {
      nanogenreId: 3056,
      x: -2125920,
      y: -3624570
    },
    {
      nanogenreId: 3057,
      x: -3612210,
      y: -3448440
    },
    {
      nanogenreId: 3058,
      x: 3708000,
      y: 8222490
    },
    {
      nanogenreId: 3059,
      x: 5972970,
      y: 10400940
    },
    {
      nanogenreId: 3060,
      x: -4712250,
      y: 5651610
    },
    {
      nanogenreId: 3061,
      x: -9504840,
      y: 4770960
    },
    {
      nanogenreId: 3062,
      x: 5586720,
      y: -3806880
    },
    {
      nanogenreId: 3063,
      x: -2252610,
      y: 6124380
    },
    {
      nanogenreId: 3064,
      x: -2821170,
      y: -5759760
    },
    {
      nanogenreId: 3065,
      x: 7932030,
      y: -7820790
    },
    {
      nanogenreId: 3066,
      x: -4001550,
      y: 8679810
    },
    {
      nanogenreId: 3067,
      x: -704520,
      y: 9891090
    },
    {
      nanogenreId: 3068,
      x: -2234070,
      y: 3989190
    },
    {
      nanogenreId: 3069,
      x: 2413290,
      y: 7635390
    },
    {
      nanogenreId: 3070,
      x: -5589810,
      y: 3633840
    },
    {
      nanogenreId: 3071,
      x: -651990,
      y: 6835080
    },
    {
      nanogenreId: 3072,
      x: -139050,
      y: 1100040
    },
    {
      nanogenreId: 3073,
      x: 1878720,
      y: -11553510
    },
    {
      nanogenreId: 3074,
      x: 4851300,
      y: 9415230
    },
    {
      nanogenreId: 3075,
      x: 7833150,
      y: -7740450
    },
    {
      nanogenreId: 3076,
      x: -2376210,
      y: 8565480
    },
    {
      nanogenreId: 3077,
      x: -5141760,
      y: 4520670
    },
    {
      nanogenreId: 3078,
      x: -2175360,
      y: 6708390
    },
    {
      nanogenreId: 3079,
      x: -12795690,
      y: -1130940
    },
    {
      nanogenreId: 3080,
      x: 8673630,
      y: -763230
    },
    {
      nanogenreId: 3081,
      x: 2428740,
      y: -12761700
    },
    {
      nanogenreId: 3082,
      x: -7956750,
      y: 2478180
    },
    {
      nanogenreId: 3083,
      x: 7854780,
      y: 2311320
    },
    {
      nanogenreId: 3084,
      x: 10747020,
      y: 1424490
    },
    {
      nanogenreId: 3085,
      x: -1016610,
      y: -2397840
    },
    {
      nanogenreId: 3086,
      x: 8633460,
      y: -6878340
    },
    {
      nanogenreId: 3087,
      x: -7366560,
      y: -380070
    },
    {
      nanogenreId: 3088,
      x: -7650840,
      y: -2555430
    },
    {
      nanogenreId: 3089,
      x: -11247600,
      y: 5475480
    },
    {
      nanogenreId: 3090,
      x: -383160,
      y: 7860960
    },
    {
      nanogenreId: 3091,
      x: -1662420,
      y: 5413680
    },
    {
      nanogenreId: 3092,
      x: 5667060,
      y: 9443040
    },
    {
      nanogenreId: 3093,
      x: 5546550,
      y: 6421020
    },
    {
      nanogenreId: 3094,
      x: 7085370,
      y: -5920440
    },
    {
      nanogenreId: 3095,
      x: 1032060,
      y: 5809200
    },
    {
      nanogenreId: 3096,
      x: 5586720,
      y: -9721140
    },
    {
      nanogenreId: 3097,
      x: 2561610,
      y: -7125540
    },
    {
      nanogenreId: 3098,
      x: 3272310,
      y: 4245660
    },
    {
      nanogenreId: 3099,
      x: -5571270,
      y: 3516420
    },
    {
      nanogenreId: 3100,
      x: 6371580,
      y: 9418320
    },
    {
      nanogenreId: 3101,
      x: -3185790,
      y: 6433380
    },
    {
      nanogenreId: 3102,
      x: 4919280,
      y: 10295880
    },
    {
      nanogenreId: 3103,
      x: -741600,
      y: -6402480
    },
    {
      nanogenreId: 3104,
      x: -6683670,
      y: -1696410
    },
    {
      nanogenreId: 3105,
      x: -14065680,
      y: 8475870
    },
    {
      nanogenreId: 3106,
      x: 2499810,
      y: 8670540
    },
    {
      nanogenreId: 3107,
      x: -2808810,
      y: 5812290
    },
    {
      nanogenreId: 3108,
      x: 129780,
      y: 3664740
    },
    {
      nanogenreId: 3109,
      x: 3513330,
      y: 8383170
    },
    {
      nanogenreId: 3110,
      x: 9443040,
      y: -7165710
    },
    {
      nanogenreId: 3111,
      x: -4477410,
      y: 250290
    },
    {
      nanogenreId: 3112,
      x: -5209740,
      y: -3599850
    },
    {
      nanogenreId: 3113,
      x: -284280,
      y: -3275400
    },
    {
      nanogenreId: 3114,
      x: 1427580,
      y: 8395530
    },
    {
      nanogenreId: 3115,
      x: -4211670,
      y: -3000390
    },
    {
      nanogenreId: 3116,
      x: -4341450,
      y: 3732720
    },
    {
      nanogenreId: 3117,
      x: -8580930,
      y: 1022790
    },
    {
      nanogenreId: 3118,
      x: -1968330,
      y: 3670920
    },
    {
      nanogenreId: 3119,
      x: -621090,
      y: -7341840
    },
    {
      nanogenreId: 3120,
      x: 7008120,
      y: 5330250
    },
    {
      nanogenreId: 3121,
      x: 11905770,
      y: -9195840
    },
    {
      nanogenreId: 3122,
      x: 11602950,
      y: 10805730
    },
    {
      nanogenreId: 3123,
      x: -2014680,
      y: -2818080
    },
    {
      nanogenreId: 3124,
      x: 8945550,
      y: -3775980
    },
    {
      nanogenreId: 3125,
      x: 6424110,
      y: 9575910
    },
    {
      nanogenreId: 3126,
      x: -3911940,
      y: 4752420
    },
    {
      nanogenreId: 3127,
      x: 3090000,
      y: -213210
    },
    {
      nanogenreId: 3128,
      x: 5113950,
      y: -7857870
    },
    {
      nanogenreId: 3129,
      x: -1473930,
      y: -6164550
    },
    {
      nanogenreId: 3130,
      x: 6838170,
      y: -2907690
    },
    {
      nanogenreId: 3131,
      x: -4245660,
      y: 4709160
    },
    {
      nanogenreId: 3132,
      x: -6556980,
      y: -1560450
    },
    {
      nanogenreId: 3133,
      x: -12736980,
      y: 5382780
    },
    {
      nanogenreId: 3134,
      x: 2697570,
      y: 8849760
    },
    {
      nanogenreId: 3135,
      x: -3661650,
      y: -5203560
    },
    {
      nanogenreId: 3136,
      x: -10879890,
      y: -6146010
    },
    {
      nanogenreId: 3137,
      x: -5892630,
      y: 3105450
    },
    {
      nanogenreId: 3138,
      x: 1674780,
      y: 7202790
    },
    {
      nanogenreId: 3139,
      x: 689070,
      y: -12224040
    },
    {
      nanogenreId: 3140,
      x: -12471240,
      y: 2641950
    },
    {
      nanogenreId: 3141,
      x: 1625340,
      y: 5453850
    },
    {
      nanogenreId: 3142,
      x: 5290080,
      y: 9387420
    },
    {
      nanogenreId: 3143,
      x: -10339140,
      y: -8543850
    },
    {
      nanogenreId: 3144,
      x: 12891480,
      y: -3513330
    },
    {
      nanogenreId: 3145,
      x: 4421790,
      y: 7026660
    },
    {
      nanogenreId: 3146,
      x: 775590,
      y: -2592510
    },
    {
      nanogenreId: 3147,
      x: -241020,
      y: -6013140
    },
    {
      nanogenreId: 3148,
      x: 4916190,
      y: 10342230
    },
    {
      nanogenreId: 3149,
      x: 8828130,
      y: -6798000
    },
    {
      nanogenreId: 3150,
      x: 6946320,
      y: -5259180
    },
    {
      nanogenreId: 3151,
      x: 1266900,
      y: 7147170
    },
    {
      nanogenreId: 3152,
      x: -9405960,
      y: -8284290
    },
    {
      nanogenreId: 3153,
      x: -3117810,
      y: 373890
    },
    {
      nanogenreId: 3154,
      x: 1100040,
      y: 17520300
    },
    {
      nanogenreId: 3155,
      x: -5898810,
      y: 4774050
    },
    {
      nanogenreId: 3156,
      x: 1931250,
      y: -457320
    },
    {
      nanogenreId: 3157,
      x: 4115880,
      y: 8837400
    },
    {
      nanogenreId: 3158,
      x: 960990,
      y: -1952880
    },
    {
      nanogenreId: 3159,
      x: 3782160,
      y: 3522600
    },
    {
      nanogenreId: 3160,
      x: 5688690,
      y: 9653160
    },
    {
      nanogenreId: 3161,
      x: 4412520,
      y: 4254930
    },
    {
      nanogenreId: 3162,
      x: -2017770,
      y: 13487850
    },
    {
      nanogenreId: 3163,
      x: 3377370,
      y: -8129790
    },
    {
      nanogenreId: 3164,
      x: 5203560,
      y: -5370420
    },
    {
      nanogenreId: 3165,
      x: -4159140,
      y: -3769800
    },
    {
      nanogenreId: 3166,
      x: -6643500,
      y: 4693710
    },
    {
      nanogenreId: 3167,
      x: 2147550,
      y: 5095410
    },
    {
      nanogenreId: 3168,
      x: 1313250,
      y: 1066050
    },
    {
      nanogenreId: 3169,
      x: 5657790,
      y: 9548100
    },
    {
      nanogenreId: 3170,
      x: -2107380,
      y: 2997300
    },
    {
      nanogenreId: 3171,
      x: 4749330,
      y: -3924300
    },
    {
      nanogenreId: 3172,
      x: -12971820,
      y: 1684050
    },
    {
      nanogenreId: 3173,
      x: -1996140,
      y: 4638090
    },
    {
      nanogenreId: 3174,
      x: -5336430,
      y: 5617620
    },
    {
      nanogenreId: 3175,
      x: 281190,
      y: 6492090
    },
    {
      nanogenreId: 3176,
      x: 4937820,
      y: -12492870
    },
    {
      nanogenreId: 3177,
      x: 6714570,
      y: -5092320
    },
    {
      nanogenreId: 3178,
      x: 2472000,
      y: 2218620
    },
    {
      nanogenreId: 3179,
      x: 4307460,
      y: 8519130
    },
    {
      nanogenreId: 3180,
      x: 5586720,
      y: 7159530
    },
    {
      nanogenreId: 3181,
      x: 3726540,
      y: 5441490
    },
    {
      nanogenreId: 3182,
      x: 4461960,
      y: 9223650
    },
    {
      nanogenreId: 3183,
      x: -5407500,
      y: 7660110
    },
    {
      nanogenreId: 3184,
      x: -10966410,
      y: -1121670
    },
    {
      nanogenreId: 3185,
      x: 880650,
      y: 9260730
    },
    {
      nanogenreId: 3186,
      x: 5920440,
      y: 3640020
    },
    {
      nanogenreId: 3187,
      x: 6315960,
      y: 8265750
    },
    {
      nanogenreId: 3188,
      x: 3043650,
      y: -4690620
    },
    {
      nanogenreId: 3189,
      x: 5181930,
      y: 10472010
    },
    {
      nanogenreId: 3190,
      x: 3627660,
      y: 9112410
    },
    {
      nanogenreId: 3191,
      x: 3559680,
      y: -4341450
    },
    {
      nanogenreId: 3192,
      x: 4668990,
      y: -5558910
    },
    {
      nanogenreId: 3193,
      x: 1498650,
      y: 8129790
    },
    {
      nanogenreId: 3194,
      x: -8250300,
      y: -3667830
    },
    {
      nanogenreId: 3195,
      x: -11161080,
      y: -13395150
    },
    {
      nanogenreId: 3196,
      x: -4743150,
      y: 2799540
    },
    {
      nanogenreId: 3197,
      x: 6590970,
      y: -3538050
    },
    {
      nanogenreId: 3198,
      x: -441870,
      y: 4817310
    },
    {
      nanogenreId: 3199,
      x: -4393980,
      y: 3105450
    },
    {
      nanogenreId: 3200,
      x: -8667450,
      y: 5469300
    },
    {
      nanogenreId: 3201,
      x: -2274240,
      y: -1761300
    },
    {
      nanogenreId: 3202,
      x: -729240,
      y: -2824260
    },
    {
      nanogenreId: 3203,
      x: -237930,
      y: 10580160
    },
    {
      nanogenreId: 3204,
      x: -6220170,
      y: -1646970
    },
    {
      nanogenreId: 3205,
      x: -5481660,
      y: 4752420
    },
    {
      nanogenreId: 3206,
      x: 6522990,
      y: -4752420
    },
    {
      nanogenreId: 3207,
      x: -3022020,
      y: 4440330
    },
    {
      nanogenreId: 3208,
      x: 4035540,
      y: 8034000
    },
    {
      nanogenreId: 3209,
      x: -9307080,
      y: -9409050
    },
    {
      nanogenreId: 3210,
      x: -7060650,
      y: -1962150
    },
    {
      nanogenreId: 3211,
      x: -2654310,
      y: -117420
    },
    {
      nanogenreId: 3212,
      x: 6995760,
      y: -2691390
    },
    {
      nanogenreId: 3213,
      x: -1257630,
      y: 5441490
    },
    {
      nanogenreId: 3214,
      x: 1955970,
      y: 5339520
    },
    {
      nanogenreId: 3215,
      x: 6933960,
      y: -7762080
    },
    {
      nanogenreId: 3216,
      x: -5049060,
      y: -2543070
    },
    {
      nanogenreId: 3217,
      x: 3127080,
      y: 2991120
    },
    {
      nanogenreId: 3218,
      x: 3046740,
      y: 7375830
    },
    {
      nanogenreId: 3219,
      x: 2030130,
      y: -3297030
    },
    {
      nanogenreId: 3220,
      x: 2373120,
      y: 7839330
    },
    {
      nanogenreId: 3221,
      x: 1220550,
      y: -1498650
    },
    {
      nanogenreId: 3222,
      x: -441870,
      y: -1906530
    },
    {
      nanogenreId: 3223,
      x: -5330250,
      y: -7610670
    },
    {
      nanogenreId: 3224,
      x: 7879500,
      y: -8967180
    },
    {
      nanogenreId: 3225,
      x: -7116270,
      y: -2787180
    },
    {
      nanogenreId: 3226,
      x: -738510,
      y: -1776750
    },
    {
      nanogenreId: 3227,
      x: 4937820,
      y: 9934350
    },
    {
      nanogenreId: 3228,
      x: -6878340,
      y: 750870
    },
    {
      nanogenreId: 3229,
      x: 2008500,
      y: 1198920
    },
    {
      nanogenreId: 3230,
      x: 4811130,
      y: 457320
    },
    {
      nanogenreId: 3231,
      x: -4449600,
      y: 4709160
    },
    {
      nanogenreId: 3232,
      x: 5852460,
      y: -6634230
    },
    {
      nanogenreId: 3233,
      x: 2261880,
      y: -3565860
    },
    {
      nanogenreId: 3234,
      x: -8293560,
      y: -4069530
    },
    {
      nanogenreId: 3235,
      x: 17310180,
      y: 2138280
    },
    {
      nanogenreId: 3236,
      x: 3859410,
      y: -4363080
    },
    {
      nanogenreId: 3237,
      x: 2737740,
      y: 4749330
    },
    {
      nanogenreId: 3238,
      x: -259560,
      y: -1168020
    },
    {
      nanogenreId: 3239,
      x: 4338360,
      y: 6050220
    },
    {
      nanogenreId: 3240,
      x: -5716500,
      y: 12360
    },
    {
      nanogenreId: 3241,
      x: -2734650,
      y: 4845120
    },
    {
      nanogenreId: 3242,
      x: -2617230,
      y: 1303980
    },
    {
      nanogenreId: 3243,
      x: 7922760,
      y: -6124380
    },
    {
      nanogenreId: 3244,
      x: 6325230,
      y: -5330250
    },
    {
      nanogenreId: 3245,
      x: 5073780,
      y: 6692940
    },
    {
      nanogenreId: 3246,
      x: 2400930,
      y: 13629990
    },
    {
      nanogenreId: 3247,
      x: 4112790,
      y: 5979150
    },
    {
      nanogenreId: 3248,
      x: 8896110,
      y: -8380080
    },
    {
      nanogenreId: 3249,
      x: 3102360,
      y: 8648910
    },
    {
      nanogenreId: 3250,
      x: -71070,
      y: 9010440
    },
    {
      nanogenreId: 3251,
      x: -757050,
      y: -5413680
    },
    {
      nanogenreId: 3252,
      x: 194670,
      y: -6414840
    },
    {
      nanogenreId: 3253,
      x: 4245660,
      y: -2672850
    },
    {
      nanogenreId: 3254,
      x: 9600630,
      y: 15017400
    },
    {
      nanogenreId: 3255,
      x: -1174200,
      y: -4693710
    },
    {
      nanogenreId: 3256,
      x: 3065280,
      y: 2839710
    },
    {
      nanogenreId: 3257,
      x: 3068370,
      y: -1378140
    },
    {
      nanogenreId: 3258,
      x: 2268060,
      y: 3139440
    },
    {
      nanogenreId: 3259,
      x: -9888000,
      y: 14872170
    },
    {
      nanogenreId: 3260,
      x: 12974910,
      y: -129780
    },
    {
      nanogenreId: 3261,
      x: 9634620,
      y: 7276950
    },
    {
      nanogenreId: 3262,
      x: 15499440,
      y: -5704140
    },
    {
      nanogenreId: 3263,
      x: 5419860,
      y: 10858260
    },
    {
      nanogenreId: 3264,
      x: 3176520,
      y: 9650070
    },
    {
      nanogenreId: 3265,
      x: -13027440,
      y: -917730
    },
    {
      nanogenreId: 3266,
      x: 13419870,
      y: -1498650
    },
    {
      nanogenreId: 3267,
      x: -11124000,
      y: -11701830
    },
    {
      nanogenreId: 3268,
      x: 13438410,
      y: -9044430
    },
    {
      nanogenreId: 3269,
      x: 6850530,
      y: -8250300
    },
    {
      nanogenreId: 3270,
      x: -2861340,
      y: -11117820
    },
    {
      nanogenreId: 3271,
      x: 574740,
      y: 10802640
    },
    {
      nanogenreId: 3272,
      x: 16429530,
      y: -7737360
    },
    {
      nanogenreId: 3273,
      x: -12526860,
      y: -682890
    },
    {
      nanogenreId: 3274,
      x: 6359220,
      y: -8293560
    },
    {
      nanogenreId: 3275,
      x: 4804950,
      y: 11096190
    },
    {
      nanogenreId: 3276,
      x: -4397070,
      y: -1968330
    },
    {
      nanogenreId: 3277,
      x: -3578220,
      y: -11507160
    },
    {
      nanogenreId: 3278,
      x: -4523760,
      y: 1294710
    },
    {
      nanogenreId: 3279,
      x: -3930480,
      y: -11352660
    },
    {
      nanogenreId: 3280,
      x: 278100,
      y: -8250300
    },
    {
      nanogenreId: 3281,
      x: -2119740,
      y: 8225580
    },
    {
      nanogenreId: 3282,
      x: 15051390,
      y: 5175750
    },
    {
      nanogenreId: 3283,
      x: 14832000,
      y: 908460
    },
    {
      nanogenreId: 3284,
      x: 15385110,
      y: -9270
    },
    {
      nanogenreId: 3285,
      x: -16058730,
      y: -4594830
    },
    {
      nanogenreId: 3286,
      x: 5138670,
      y: 3204330
    },
    {
      nanogenreId: 3287,
      x: 13361160,
      y: -7947480
    },
    {
      nanogenreId: 3288,
      x: 7388190,
      y: 7134810
    },
    {
      nanogenreId: 3289,
      x: 355350,
      y: 13042890
    },
    {
      nanogenreId: 3290,
      x: -9937440,
      y: 13169580
    },
    {
      nanogenreId: 3291,
      x: -2317500,
      y: 2342220
    },
    {
      nanogenreId: 3292,
      x: 10527630,
      y: -769410
    },
    {
      nanogenreId: 3293,
      x: -11979930,
      y: -3476250
    },
    {
      nanogenreId: 3294,
      x: -13469310,
      y: -3287760
    },
    {
      nanogenreId: 3295,
      x: -8132880,
      y: -8645820
    },
    {
      nanogenreId: 3296,
      x: 9699510,
      y: -4715340
    },
    {
      nanogenreId: 3297,
      x: -13518750,
      y: -7100820
    },
    {
      nanogenreId: 3298,
      x: 176130,
      y: -7276950
    },
    {
      nanogenreId: 3299,
      x: 6458100,
      y: 10870620
    },
    {
      nanogenreId: 3300,
      x: -6940140,
      y: 5175750
    },
    {
      nanogenreId: 3301,
      x: -6566250,
      y: -7042110
    },
    {
      nanogenreId: 3302,
      x: 5045970,
      y: 9650070
    },
    {
      nanogenreId: 3303,
      x: 1288530,
      y: -12857490
    },
    {
      nanogenreId: 3304,
      x: 5286990,
      y: 5753580
    },
    {
      nanogenreId: 3305,
      x: 4971810,
      y: 10209360
    },
    {
      nanogenreId: 3306,
      x: 7171890,
      y: -6084210
    },
    {
      nanogenreId: 3307,
      x: 1857090,
      y: 11488620
    },
    {
      nanogenreId: 3308,
      x: -2926230,
      y: -10005420
    },
    {
      nanogenreId: 3309,
      x: 2784090,
      y: 14597160
    },
    {
      nanogenreId: 3310,
      x: 8914650,
      y: -6288150
    },
    {
      nanogenreId: 3311,
      x: 7298580,
      y: -7314030
    },
    {
      nanogenreId: 3312,
      x: 7867140,
      y: -6445740
    },
    {
      nanogenreId: 3313,
      x: -12947100,
      y: 6489000
    },
    {
      nanogenreId: 3314,
      x: -2836620,
      y: -9983790
    },
    {
      nanogenreId: 3315,
      x: 11018940,
      y: 571650
    },
    {
      nanogenreId: 3316,
      x: -2620320,
      y: -8324460
    },
    {
      nanogenreId: 3317,
      x: 5608350,
      y: -2957130
    },
    {
      nanogenreId: 3318,
      x: 1742760,
      y: -3698730
    },
    {
      nanogenreId: 3319,
      x: 1665510,
      y: 8506770
    },
    {
      nanogenreId: 3320,
      x: 7310940,
      y: 2740830
    },
    {
      nanogenreId: 3321,
      x: -4057170,
      y: -14881440
    },
    {
      nanogenreId: 3322,
      x: 6136740,
      y: 10033230
    },
    {
      nanogenreId: 3323,
      x: 9699510,
      y: -10651230
    },
    {
      nanogenreId: 3324,
      x: -8305920,
      y: -6692940
    },
    {
      nanogenreId: 3325,
      x: 3955200,
      y: 2808810
    },
    {
      nanogenreId: 3326,
      x: -4146780,
      y: -12397080
    },
    {
      nanogenreId: 3327,
      x: -15440730,
      y: -3908850
    },
    {
      nanogenreId: 3328,
      x: -7465440,
      y: 3306300
    },
    {
      nanogenreId: 3329,
      x: 1495560,
      y: 15953670
    },
    {
      nanogenreId: 3330,
      x: -2122830,
      y: -4934730
    },
    {
      nanogenreId: 3331,
      x: 8899200,
      y: -4427970
    },
    {
      nanogenreId: 3332,
      x: 5738130,
      y: 6816540
    },
    {
      nanogenreId: 3333,
      x: 710700,
      y: 10818090
    },
    {
      nanogenreId: 3334,
      x: 1625340,
      y: -15450
    },
    {
      nanogenreId: 3335,
      x: 8256480,
      y: -5935890
    },
    {
      nanogenreId: 3336,
      x: -5809200,
      y: 12647370
    },
    {
      nanogenreId: 3337,
      x: 9968340,
      y: -6210900
    },
    {
      nanogenreId: 3338,
      x: -7116270,
      y: -10762470
    },
    {
      nanogenreId: 3339,
      x: 3828510,
      y: 9545010
    },
    {
      nanogenreId: 3340,
      x: 13827750,
      y: -8290470
    },
    {
      nanogenreId: 3341,
      x: 766320,
      y: -7619940
    },
    {
      nanogenreId: 3342,
      x: -5812290,
      y: -14550810
    },
    {
      nanogenreId: 3343,
      x: -11318670,
      y: -12159150
    },
    {
      nanogenreId: 3344,
      x: 18061050,
      y: -5011980
    },
    {
      nanogenreId: 3345,
      x: -2110470,
      y: -5219010
    },
    {
      nanogenreId: 3346,
      x: 3272310,
      y: -9025890
    },
    {
      nanogenreId: 3347,
      x: -1878720,
      y: 3717270
    },
    {
      nanogenreId: 3348,
      x: 7412910,
      y: 13929720
    },
    {
      nanogenreId: 3349,
      x: 8797230,
      y: 2570880
    },
    {
      nanogenreId: 3350,
      x: 6136740,
      y: -8404800
    },
    {
      nanogenreId: 3351,
      x: 8676720,
      y: 1025880
    },
    {
      nanogenreId: 3352,
      x: 8753970,
      y: -8852850
    },
    {
      nanogenreId: 3353,
      x: 11426820,
      y: 17254560
    },
    {
      nanogenreId: 3354,
      x: -5141760,
      y: 4230210
    },
    {
      nanogenreId: 3355,
      x: 5463120,
      y: 10258800
    },
    {
      nanogenreId: 3356,
      x: -12437250,
      y: -4557750
    },
    {
      nanogenreId: 3357,
      x: 4916190,
      y: 8339910
    },
    {
      nanogenreId: 3358,
      x: -6180,
      y: -6946320
    },
    {
      nanogenreId: 3359,
      x: -1127850,
      y: -7236780
    },
    {
      nanogenreId: 3360,
      x: -12838950,
      y: -3142530
    },
    {
      nanogenreId: 3361,
      x: 1016610,
      y: -7289310
    },
    {
      nanogenreId: 3362,
      x: 11405190,
      y: -5392050
    },
    {
      nanogenreId: 3363,
      x: 12276570,
      y: -7477800
    },
    {
      nanogenreId: 3364,
      x: -1186560,
      y: -4224030
    },
    {
      nanogenreId: 3365,
      x: -1585170,
      y: 7650840
    },
    {
      nanogenreId: 3366,
      x: 2042490,
      y: 9816930
    },
    {
      nanogenreId: 3367,
      x: 9356520,
      y: -6760920
    },
    {
      nanogenreId: 3368,
      x: -13240650,
      y: -5453850
    },
    {
      nanogenreId: 3369,
      x: -9356520,
      y: -1319430
    },
    {
      nanogenreId: 3370,
      x: -3544230,
      y: -9841650
    },
    {
      nanogenreId: 3371,
      x: 9344160,
      y: -7545780
    },
    {
      nanogenreId: 3372,
      x: 2243340,
      y: 3890310
    },
    {
      nanogenreId: 3373,
      x: -4888380,
      y: -9965250
    },
    {
      nanogenreId: 3374,
      x: 5799930,
      y: 9730410
    },
    {
      nanogenreId: 3375,
      x: -8636550,
      y: 2873700
    },
    {
      nanogenreId: 3376,
      x: -8531490,
      y: 7131720
    },
    {
      nanogenreId: 3377,
      x: -4418700,
      y: 9758220
    },
    {
      nanogenreId: 3378,
      x: 16550040,
      y: -3979920
    },
    {
      nanogenreId: 3379,
      x: 1986870,
      y: -6980310
    },
    {
      nanogenreId: 3380,
      x: 16098900,
      y: 4474320
    },
    {
      nanogenreId: 3381,
      x: -3346470,
      y: -3290850
    },
    {
      nanogenreId: 3382,
      x: 8216310,
      y: 846660
    },
    {
      nanogenreId: 3383,
      x: 10064130,
      y: -8071080
    },
    {
      nanogenreId: 3384,
      x: -9112410,
      y: 3670920
    },
    {
      nanogenreId: 3385,
      x: 4610280,
      y: -3587490
    },
    {
      nanogenreId: 3386,
      x: 6081120,
      y: -3099270
    },
    {
      nanogenreId: 3387,
      x: 8685990,
      y: -7718820
    },
    {
      nanogenreId: 3388,
      x: 5935890,
      y: 8753970
    },
    {
      nanogenreId: 3389,
      x: -4109700,
      y: 4826580
    },
    {
      nanogenreId: 3390,
      x: -3587490,
      y: 3318660
    },
    {
      nanogenreId: 3391,
      x: 5123220,
      y: 9928170
    },
    {
      nanogenreId: 3392,
      x: 6983400,
      y: -8905380
    },
    {
      nanogenreId: 3393,
      x: -4851300,
      y: 3930480
    },
    {
      nanogenreId: 3394,
      x: 10913880,
      y: -7514880
    },
    {
      nanogenreId: 3395,
      x: -2830440,
      y: -10663590
    },
    {
      nanogenreId: 3396,
      x: 1619160,
      y: 12183870
    },
    {
      nanogenreId: 3397,
      x: -1739670,
      y: -5809200
    },
    {
      nanogenreId: 3398,
      x: 6176910,
      y: 10722300
    },
    {
      nanogenreId: 3399,
      x: 11433000,
      y: 7941300
    },
    {
      nanogenreId: 3400,
      x: 7935120,
      y: -6266520
    },
    {
      nanogenreId: 3401,
      x: 6244890,
      y: -6649680
    },
    {
      nanogenreId: 3402,
      x: 3009660,
      y: -3763620
    },
    {
      nanogenreId: 3403,
      x: -3374280,
      y: -4245660
    },
    {
      nanogenreId: 3404,
      x: -3405180,
      y: 9081510
    },
    {
      nanogenreId: 3405,
      x: 7990740,
      y: -7332570
    },
    {
      nanogenreId: 3406,
      x: -5169570,
      y: -13392060
    },
    {
      nanogenreId: 3407,
      x: -5815380,
      y: 9084600
    },
    {
      nanogenreId: 3408,
      x: 7341840,
      y: -9146400
    },
    {
      nanogenreId: 3409,
      x: 9767490,
      y: -8710710
    },
    {
      nanogenreId: 3410,
      x: 5524920,
      y: -1600620
    },
    {
      nanogenreId: 3411,
      x: 16077270,
      y: -8491320
    },
    {
      nanogenreId: 3412,
      x: -908460,
      y: -1628430
    },
    {
      nanogenreId: 3413,
      x: 3368100,
      y: 8506770
    },
    {
      nanogenreId: 3414,
      x: 2663580,
      y: 9702600
    },
    {
      nanogenreId: 3415,
      x: 3439170,
      y: -5704140
    },
    {
      nanogenreId: 3416,
      x: 3559680,
      y: -1906530
    },
    {
      nanogenreId: 3417,
      x: -778680,
      y: 8200860
    },
    {
      nanogenreId: 3418,
      x: -1127850,
      y: 10119750
    },
    {
      nanogenreId: 3419,
      x: 1643880,
      y: 11043660
    },
    {
      nanogenreId: 3420,
      x: -543840,
      y: -9711870
    },
    {
      nanogenreId: 3421,
      x: 5592900,
      y: 10323690
    },
    {
      nanogenreId: 3422,
      x: 2595600,
      y: 3522600
    },
    {
      nanogenreId: 3423,
      x: 9591360,
      y: -7613760
    },
    {
      nanogenreId: 3424,
      x: 13005810,
      y: 8154510
    },
    {
      nanogenreId: 3425,
      x: 386250,
      y: -614910
    },
    {
      nanogenreId: 3426,
      x: -970260,
      y: 7397460
    },
    {
      nanogenreId: 3427,
      x: -7298580,
      y: 8590200
    },
    {
      nanogenreId: 3428,
      x: -1903440,
      y: 9588270
    },
    {
      nanogenreId: 3429,
      x: 794130,
      y: 15842430
    },
    {
      nanogenreId: 3430,
      x: -2904600,
      y: -10947870
    },
    {
      nanogenreId: 3431,
      x: -2484360,
      y: 13611450
    },
    {
      nanogenreId: 3432,
      x: -7867140,
      y: -1269990
    },
    {
      nanogenreId: 3433,
      x: -4032450,
      y: 6325230
    },
    {
      nanogenreId: 3434,
      x: 13212840,
      y: 5892630
    },
    {
      nanogenreId: 3435,
      x: 8531490,
      y: -5392050
    },
    {
      nanogenreId: 3436,
      x: -7975290,
      y: -392430
    },
    {
      nanogenreId: 3437,
      x: -4406340,
      y: -364620
    },
    {
      nanogenreId: 3438,
      x: 5948250,
      y: 10400940
    },
    {
      nanogenreId: 3439,
      x: 7171890,
      y: 8825040
    },
    {
      nanogenreId: 3440,
      x: 2845890,
      y: -4594830
    },
    {
      nanogenreId: 3441,
      x: 4282740,
      y: 7725000
    },
    {
      nanogenreId: 3442,
      x: -9155670,
      y: -3439170
    },
    {
      nanogenreId: 3443,
      x: -4329090,
      y: -296640
    },
    {
      nanogenreId: 3444,
      x: -13361160,
      y: -7610670
    },
    {
      nanogenreId: 3445,
      x: -2886060,
      y: -10518360
    },
    {
      nanogenreId: 3446,
      x: 2777910,
      y: 7567410
    },
    {
      nanogenreId: 3447,
      x: 1004250,
      y: 9705690
    },
    {
      nanogenreId: 3448,
      x: 9739680,
      y: -7366560
    },
    {
      nanogenreId: 3449,
      x: -2839710,
      y: 14751660
    },
    {
      nanogenreId: 3450,
      x: -1894170,
      y: 13725780
    },
    {
      nanogenreId: 3451,
      x: -6686760,
      y: 3729630
    },
    {
      nanogenreId: 3452,
      x: 5339520,
      y: 8920830
    },
    {
      nanogenreId: 3453,
      x: -13376610,
      y: -3516420
    },
    {
      nanogenreId: 3454,
      x: -14241810,
      y: -4953270
    },
    {
      nanogenreId: 3455,
      x: 5460030,
      y: 9758220
    },
    {
      nanogenreId: 3456,
      x: 3646200,
      y: 1832370
    },
    {
      nanogenreId: 3457,
      x: 30900,
      y: 3544230
    },
    {
      nanogenreId: 3458,
      x: -188490,
      y: -1415220
    },
    {
      nanogenreId: 3459,
      x: 3040560,
      y: 9446130
    },
    {
      nanogenreId: 3460,
      x: -3874860,
      y: 4035540
    },
    {
      nanogenreId: 3461,
      x: -5818470,
      y: 5249910
    },
    {
      nanogenreId: 3462,
      x: 852840,
      y: -6566250
    },
    {
      nanogenreId: 3463,
      x: 4041720,
      y: 10811910
    },
    {
      nanogenreId: 3464,
      x: -1143300,
      y: -9724230
    },
    {
      nanogenreId: 3465,
      x: -1495560,
      y: 10323690
    },
    {
      nanogenreId: 3466,
      x: -2879880,
      y: -10852080
    },
    {
      nanogenreId: 3467,
      x: 7140990,
      y: -6609510
    },
    {
      nanogenreId: 3468,
      x: 4533030,
      y: 12372360
    },
    {
      nanogenreId: 3469,
      x: -8667450,
      y: 5070690
    },
    {
      nanogenreId: 3470,
      x: 9149490,
      y: -8037090
    },
    {
      nanogenreId: 3471,
      x: 12505230,
      y: -6044040
    },
    {
      nanogenreId: 3472,
      x: 4397070,
      y: 7935120
    },
    {
      nanogenreId: 3473,
      x: 7035930,
      y: -10506000
    },
    {
      nanogenreId: 3474,
      x: 6628050,
      y: 7925850
    },
    {
      nanogenreId: 3475,
      x: 8203950,
      y: 2827350
    },
    {
      nanogenreId: 3476,
      x: 6210900,
      y: 4307460
    },
    {
      nanogenreId: 3477,
      x: 1897260,
      y: -13324080
    },
    {
      nanogenreId: 3478,
      x: -1236000,
      y: -6600240
    },
    {
      nanogenreId: 3479,
      x: 6550800,
      y: 376980
    },
    {
      nanogenreId: 3480,
      x: -3612210,
      y: -3918120
    },
    {
      nanogenreId: 3481,
      x: -11831610,
      y: 4647360
    },
    {
      nanogenreId: 3482,
      x: -2756280,
      y: 10385490
    },
    {
      nanogenreId: 3483,
      x: -10657410,
      y: -132870
    },
    {
      nanogenreId: 3484,
      x: 512940,
      y: 12814230
    },
    {
      nanogenreId: 3485,
      x: 3374280,
      y: 7922760
    },
    {
      nanogenreId: 3486,
      x: -120510,
      y: -8429520
    },
    {
      nanogenreId: 3487,
      x: 9220560,
      y: -7845510
    },
    {
      nanogenreId: 3488,
      x: 7789890,
      y: -8194680
    },
    {
      nanogenreId: 3489,
      x: 1823100,
      y: 8772510
    },
    {
      nanogenreId: 3490,
      x: 1968330,
      y: 7508700
    },
    {
      nanogenreId: 3491,
      x: -432600,
      y: -3488610
    },
    {
      nanogenreId: 3492,
      x: 13673250,
      y: -3269220
    },
    {
      nanogenreId: 3493,
      x: -6034770,
      y: 9062970
    },
    {
      nanogenreId: 3494,
      x: -7261500,
      y: 3738900
    },
    {
      nanogenreId: 3495,
      x: 6590970,
      y: -6692940
    },
    {
      nanogenreId: 3496,
      x: 3408270,
      y: -4137510
    },
    {
      nanogenreId: 3497,
      x: -5098500,
      y: -3151800
    },
    {
      nanogenreId: 3498,
      x: 4245660,
      y: 9263820
    },
    {
      nanogenreId: 3499,
      x: -444960,
      y: -5904990
    },
    {
      nanogenreId: 3500,
      x: 2317500,
      y: -6498270
    },
    {
      nanogenreId: 3501,
      x: -1591350,
      y: 4913100
    },
    {
      nanogenreId: 3502,
      x: 6065670,
      y: 9541920
    },
    {
      nanogenreId: 3503,
      x: 3955200,
      y: 6504450
    },
    {
      nanogenreId: 3504,
      x: 6003870,
      y: -5942070
    },
    {
      nanogenreId: 3505,
      x: 4842030,
      y: -5249910
    },
    {
      nanogenreId: 3506,
      x: 9164940,
      y: 18743940
    },
    {
      nanogenreId: 3507,
      x: 1313250,
      y: 18212460
    },
    {
      nanogenreId: 3508,
      x: -10163010,
      y: 2839710
    },
    {
      nanogenreId: 3509,
      x: -2051760,
      y: -8389350
    },
    {
      nanogenreId: 3510,
      x: 1053690,
      y: 11482440
    },
    {
      nanogenreId: 3511,
      x: -3071460,
      y: -6161460
    },
    {
      nanogenreId: 3512,
      x: -3615300,
      y: 6581700
    },
    {
      nanogenreId: 3513,
      x: 5222100,
      y: 10181550
    },
    {
      nanogenreId: 3514,
      x: -4131330,
      y: 9986880
    },
    {
      nanogenreId: 3515,
      x: -14436480,
      y: -5209740
    },
    {
      nanogenreId: 3516,
      x: -4916190,
      y: -5045970
    },
    {
      nanogenreId: 3517,
      x: 12397080,
      y: -1789110
    },
    {
      nanogenreId: 3518,
      x: 9202020,
      y: -9341070
    },
    {
      nanogenreId: 3519,
      x: 1714950,
      y: -2261880
    },
    {
      nanogenreId: 3520,
      x: 3173430,
      y: 2351490
    },
    {
      nanogenreId: 3521,
      x: 6658950,
      y: -6414840
    },
    {
      nanogenreId: 3522,
      x: -7728090,
      y: -10116660
    },
    {
      nanogenreId: 3523,
      x: 4168410,
      y: -6108930
    },
    {
      nanogenreId: 3524,
      x: 1297800,
      y: -10125930
    },
    {
      nanogenreId: 3525,
      x: -8633460,
      y: 1820010
    },
    {
      nanogenreId: 3526,
      x: 154500,
      y: -5151030
    },
    {
      nanogenreId: 3527,
      x: -7264590,
      y: -10719210
    },
    {
      nanogenreId: 3528,
      x: 8778690,
      y: -6624960
    },
    {
      nanogenreId: 3529,
      x: 5450760,
      y: -5565090
    },
    {
      nanogenreId: 3530,
      x: 791040,
      y: 11096190
    },
    {
      nanogenreId: 3531,
      x: -5926620,
      y: -6433380
    },
    {
      nanogenreId: 3532,
      x: -618000,
      y: 2771730
    },
    {
      nanogenreId: 3533,
      x: -2441100,
      y: 15956760
    },
    {
      nanogenreId: 3534,
      x: -5494020,
      y: 661260
    },
    {
      nanogenreId: 3535,
      x: 11500980,
      y: -5963700
    },
    {
      nanogenreId: 3536,
      x: -2144460,
      y: 386250
    },
    {
      nanogenreId: 3537,
      x: 4789500,
      y: 7601400
    },
    {
      nanogenreId: 3538,
      x: -2237160,
      y: -9427590
    },
    {
      nanogenreId: 3539,
      x: -5302440,
      y: -1730400
    },
    {
      nanogenreId: 3540,
      x: 435690,
      y: 6173820
    },
    {
      nanogenreId: 3541,
      x: -1266900,
      y: 2975670
    },
    {
      nanogenreId: 3542,
      x: 4066440,
      y: 1764390
    },
    {
      nanogenreId: 3543,
      x: 8933190,
      y: -6535350
    },
    {
      nanogenreId: 3544,
      x: -2592510,
      y: -8571660
    },
    {
      nanogenreId: 3545,
      x: 12804960,
      y: 7907310
    },
    {
      nanogenreId: 3546,
      x: -5691780,
      y: 4650450
    },
    {
      nanogenreId: 3547,
      x: -9711870,
      y: -7650840
    },
    {
      nanogenreId: 3548,
      x: -7320210,
      y: 13908090
    },
    {
      nanogenreId: 3549,
      x: 4258020,
      y: 7944390
    },
    {
      nanogenreId: 3550,
      x: -10960230,
      y: -237930
    },
    {
      nanogenreId: 3551,
      x: 6405570,
      y: 846660
    },
    {
      nanogenreId: 3552,
      x: 2960220,
      y: 4316730
    },
    {
      nanogenreId: 3553,
      x: -5373510,
      y: 9257640
    },
    {
      nanogenreId: 3554,
      x: -10339140,
      y: -2570880
    },
    {
      nanogenreId: 3555,
      x: 5432220,
      y: 9093870
    },
    {
      nanogenreId: 3556,
      x: -2422560,
      y: -8478960
    },
    {
      nanogenreId: 3557,
      x: -12094260,
      y: -6427200
    },
    {
      nanogenreId: 3558,
      x: 2991120,
      y: -9059880
    },
    {
      nanogenreId: 3559,
      x: 3955200,
      y: 9829290
    },
    {
      nanogenreId: 3560,
      x: -3979920,
      y: -10546170
    },
    {
      nanogenreId: 3561,
      x: 794130,
      y: -3139440
    },
    {
      nanogenreId: 3562,
      x: -1538820,
      y: 1446120
    },
    {
      nanogenreId: 3563,
      x: 6003870,
      y: 1659330
    },
    {
      nanogenreId: 3564,
      x: 4947090,
      y: 9149490
    },
    {
      nanogenreId: 3565,
      x: -1381230,
      y: 6890700
    },
    {
      nanogenreId: 3566,
      x: -3287760,
      y: 1866360
    },
    {
      nanogenreId: 3567,
      x: 4495950,
      y: -9677880
    },
    {
      nanogenreId: 3568,
      x: 83430,
      y: -4431060
    },
    {
      nanogenreId: 3569,
      x: -7598310,
      y: 12356910
    },
    {
      nanogenreId: 3570,
      x: 5515650,
      y: 9940530
    },
    {
      nanogenreId: 3571,
      x: 17538840,
      y: -1674780
    },
    {
      nanogenreId: 3572,
      x: 448050,
      y: -6417930
    },
    {
      nanogenreId: 3573,
      x: 843570,
      y: 7344930
    },
    {
      nanogenreId: 3574,
      x: 3263040,
      y: 10119750
    },
    {
      nanogenreId: 3575,
      x: 9412140,
      y: -7771350
    },
    {
      nanogenreId: 3576,
      x: 1989960,
      y: 3349560
    },
    {
      nanogenreId: 3577,
      x: -4094250,
      y: -2502900
    },
    {
      nanogenreId: 3578,
      x: -2305140,
      y: -6146010
    },
    {
      nanogenreId: 3579,
      x: -4495950,
      y: -12193140
    },
    {
      nanogenreId: 3580,
      x: -2339130,
      y: 2045580
    },
    {
      nanogenreId: 3581,
      x: 2203170,
      y: -3006570
    },
    {
      nanogenreId: 3582,
      x: -1307070,
      y: -7891860
    },
    {
      nanogenreId: 3583,
      x: 3334110,
      y: 8682900
    },
    {
      nanogenreId: 3584,
      x: -5317890,
      y: -5521830
    },
    {
      nanogenreId: 3585,
      x: -1835460,
      y: 6146010
    },
    {
      nanogenreId: 3586,
      x: 52530,
      y: 7765170
    },
    {
      nanogenreId: 3587,
      x: 2515260,
      y: 17511030
    },
    {
      nanogenreId: 3588,
      x: 7159530,
      y: -4977990
    },
    {
      nanogenreId: 3589,
      x: 5765940,
      y: 9396690
    },
    {
      nanogenreId: 3590,
      x: 5988420,
      y: -5469300
    },
    {
      nanogenreId: 3591,
      x: 5500200,
      y: 163770
    },
    {
      nanogenreId: 3592,
      x: 9758220,
      y: 809580
    },
    {
      nanogenreId: 3593,
      x: 3983010,
      y: -6606420
    },
    {
      nanogenreId: 3594,
      x: -2249520,
      y: -9949800
    },
    {
      nanogenreId: 3595,
      x: 8135970,
      y: -5648520
    },
    {
      nanogenreId: 3596,
      x: -9152580,
      y: -4258020
    },
    {
      nanogenreId: 3597,
      x: -2638860,
      y: -6831990
    },
    {
      nanogenreId: 3598,
      x: -2200080,
      y: -7208970
    },
    {
      nanogenreId: 3599,
      x: 460410,
      y: -11479350
    },
    {
      nanogenreId: 3600,
      x: 10645050,
      y: -6071850
    },
    {
      nanogenreId: 3601,
      x: 5994600,
      y: 9823110
    },
    {
      nanogenreId: 3602,
      x: 5892630,
      y: 10098120
    },
    {
      nanogenreId: 3603,
      x: 5138670,
      y: 8478960
    },
    {
      nanogenreId: 3604,
      x: 4684440,
      y: -10694490
    },
    {
      nanogenreId: 3605,
      x: -15610680,
      y: -1269990
    },
    {
      nanogenreId: 3606,
      x: 10363860,
      y: -2054850
    },
    {
      nanogenreId: 3607,
      x: 4276560,
      y: 8457330
    },
    {
      nanogenreId: 3608,
      x: -4808040,
      y: 3179610
    },
    {
      nanogenreId: 3609,
      x: -6921600,
      y: -6411750
    },
    {
      nanogenreId: 3610,
      x: 5833920,
      y: 11232150
    },
    {
      nanogenreId: 3611,
      x: 5283900,
      y: 9446130
    },
    {
      nanogenreId: 3612,
      x: 12789510,
      y: -2166090
    },
    {
      nanogenreId: 3613,
      x: 3587490,
      y: 4397070
    },
    {
      nanogenreId: 3614,
      x: 5762850,
      y: 1826190
    },
    {
      nanogenreId: 3615,
      x: -3003480,
      y: -8491320
    },
    {
      nanogenreId: 3616,
      x: 5222100,
      y: -5052150
    },
    {
      nanogenreId: 3617,
      x: 5234460,
      y: 8398620
    },
    {
      nanogenreId: 3618,
      x: -3853230,
      y: -809580
    },
    {
      nanogenreId: 3619,
      x: 1180380,
      y: 1464660
    },
    {
      nanogenreId: 3620,
      x: -3120900,
      y: -4400160
    },
    {
      nanogenreId: 3621,
      x: 5475480,
      y: 9270
    },
    {
      nanogenreId: 3622,
      x: -12919290,
      y: -318270
    },
    {
      nanogenreId: 3623,
      x: -8769420,
      y: 917730
    },
    {
      nanogenreId: 3624,
      x: 4848210,
      y: 8979540
    },
    {
      nanogenreId: 3625,
      x: 15224430,
      y: 1137120
    },
    {
      nanogenreId: 3626,
      x: -1137120,
      y: 9909630
    },
    {
      nanogenreId: 3627,
      x: -4962540,
      y: 2215530
    },
    {
      nanogenreId: 3628,
      x: -5472390,
      y: 10873710
    },
    {
      nanogenreId: 3629,
      x: -923910,
      y: -9668610
    },
    {
      nanogenreId: 3630,
      x: 1421400,
      y: -6331410
    },
    {
      nanogenreId: 3631,
      x: -5117040,
      y: -4953270
    },
    {
      nanogenreId: 3632,
      x: 8593290,
      y: -5049060
    },
    {
      nanogenreId: 3633,
      x: 775590,
      y: -3562770
    },
    {
      nanogenreId: 3634,
      x: -6949410,
      y: 2264970
    },
    {
      nanogenreId: 3635,
      x: 5926620,
      y: 10935510
    },
    {
      nanogenreId: 3636,
      x: 7156440,
      y: -689070
    },
    {
      nanogenreId: 3637,
      x: -6078030,
      y: 1344150
    },
    {
      nanogenreId: 3638,
      x: 15450,
      y: 8636550
    },
    {
      nanogenreId: 3639,
      x: 6587880,
      y: -3238320
    },
    {
      nanogenreId: 3640,
      x: -5110860,
      y: -8089620
    },
    {
      nanogenreId: 3641,
      x: 1171110,
      y: -2227890
    },
    {
      nanogenreId: 3642,
      x: -5339520,
      y: -10057950
    },
    {
      nanogenreId: 3643,
      x: 3766710,
      y: 8815770
    },
    {
      nanogenreId: 3644,
      x: -2129010,
      y: 4635000
    },
    {
      nanogenreId: 3645,
      x: 13033620,
      y: 3708000
    },
    {
      nanogenreId: 3646,
      x: 5642340,
      y: -6618780
    },
    {
      nanogenreId: 3647,
      x: -1727310,
      y: 3887220
    },
    {
      nanogenreId: 3648,
      x: 5280810,
      y: 7962930
    },
    {
      nanogenreId: 3649,
      x: 5327160,
      y: 5015070
    },
    {
      nanogenreId: 3650,
      x: -973350,
      y: 13719600
    },
    {
      nanogenreId: 3651,
      x: -5691780,
      y: -2400930
    },
    {
      nanogenreId: 3652,
      x: 216300,
      y: -10478190
    },
    {
      nanogenreId: 3653,
      x: 889920,
      y: -3924300
    },
    {
      nanogenreId: 3654,
      x: 6257250,
      y: 9347250
    },
    {
      nanogenreId: 3655,
      x: 1792200,
      y: -3241410
    },
    {
      nanogenreId: 3656,
      x: 5225190,
      y: 7811520
    },
    {
      nanogenreId: 3657,
      x: -13932810,
      y: 5475480
    },
    {
      nanogenreId: 3658,
      x: 726150,
      y: 9854010
    },
    {
      nanogenreId: 3659,
      x: -2913870,
      y: 10892250
    },
    {
      nanogenreId: 3660,
      x: -3803790,
      y: 6232530
    },
    {
      nanogenreId: 3661,
      x: 4208580,
      y: 9254550
    },
    {
      nanogenreId: 3662,
      x: -364620,
      y: 5401320
    },
    {
      nanogenreId: 3663,
      x: 5824650,
      y: 9066060
    },
    {
      nanogenreId: 3664,
      x: -2660490,
      y: -5490930
    },
    {
      nanogenreId: 3665,
      x: 8621100,
      y: -7076100
    },
    {
      nanogenreId: 3666,
      x: -6285060,
      y: -8704530
    },
    {
      nanogenreId: 3667,
      x: -11290860,
      y: -3568950
    },
    {
      nanogenreId: 3668,
      x: -3979920,
      y: 8438790
    },
    {
      nanogenreId: 3669,
      x: 2947860,
      y: 1013520
    },
    {
      nanogenreId: 3670,
      x: 52530,
      y: 7502520
    },
    {
      nanogenreId: 3671,
      x: 9229830,
      y: -8330640
    },
    {
      nanogenreId: 3672,
      x: 2657400,
      y: -5809200
    },
    {
      nanogenreId: 3673,
      x: 4063350,
      y: 207030
    },
    {
      nanogenreId: 3674,
      x: -1328700,
      y: 8321370
    },
    {
      nanogenreId: 3675,
      x: 1127850,
      y: -5129400
    },
    {
      nanogenreId: 3676,
      x: 435690,
      y: -11970660
    },
    {
      nanogenreId: 3677,
      x: 3408270,
      y: 9492480
    },
    {
      nanogenreId: 3678,
      x: -11213610,
      y: -6730020
    },
    {
      nanogenreId: 3679,
      x: 11433000,
      y: 1903440
    },
    {
      nanogenreId: 3680,
      x: 8930100,
      y: 1368870
    },
    {
      nanogenreId: 3681,
      x: -3667830,
      y: 5716500
    },
    {
      nanogenreId: 3682,
      x: 1072230,
      y: 6862890
    },
    {
      nanogenreId: 3683,
      x: 4656630,
      y: 9483210
    },
    {
      nanogenreId: 3684,
      x: -1439940,
      y: 8994990
    },
    {
      nanogenreId: 3685,
      x: 7227510,
      y: -7205880
    },
    {
      nanogenreId: 3686,
      x: -12604110,
      y: -8945550
    },
    {
      nanogenreId: 3687,
      x: -6325230,
      y: 6349950
    },
    {
      nanogenreId: 3688,
      x: 5945160,
      y: -4826580
    },
    {
      nanogenreId: 3689,
      x: -4962540,
      y: 4091160
    },
    {
      nanogenreId: 3690,
      x: 2907690,
      y: -11989200
    },
    {
      nanogenreId: 3691,
      x: -3442260,
      y: 6442650
    },
    {
      nanogenreId: 3692,
      x: 2264970,
      y: 10462740
    },
    {
      nanogenreId: 3693,
      x: 2512170,
      y: 9860190
    },
    {
      nanogenreId: 3694,
      x: 4601010,
      y: 5410590
    },
    {
      nanogenreId: 3695,
      x: 1118580,
      y: -9854010
    },
    {
      nanogenreId: 3696,
      x: 71070,
      y: -3210510
    },
    {
      nanogenreId: 3697,
      x: 11148720,
      y: -1872540
    },
    {
      nanogenreId: 3698,
      x: -4032450,
      y: -9792210
    },
    {
      nanogenreId: 3699,
      x: 3720360,
      y: -6430290
    },
    {
      nanogenreId: 3700,
      x: 9158760,
      y: -5422950
    },
    {
      nanogenreId: 3701,
      x: -8027820,
      y: 4122060
    },
    {
      nanogenreId: 3702,
      x: 8105070,
      y: -4984170
    },
    {
      nanogenreId: 3703,
      x: -2277330,
      y: -2916960
    },
    {
      nanogenreId: 3704,
      x: 8432610,
      y: -6831990
    },
    {
      nanogenreId: 3705,
      x: -645810,
      y: 5469300
    },
    {
      nanogenreId: 3706,
      x: 4137510,
      y: 10363860
    },
    {
      nanogenreId: 3707,
      x: 3127080,
      y: 9344160
    },
    {
      nanogenreId: 3708,
      x: -275010,
      y: -407880
    },
    {
      nanogenreId: 3709,
      x: 6544620,
      y: -8679810
    },
    {
      nanogenreId: 3710,
      x: -6967950,
      y: -7483980
    },
    {
      nanogenreId: 3711,
      x: -10277340,
      y: -6019320
    },
    {
      nanogenreId: 3712,
      x: 3729630,
      y: 5027430
    },
    {
      nanogenreId: 3713,
      x: -11581320,
      y: -1344150
    },
    {
      nanogenreId: 3714,
      x: 2747010,
      y: 9662430
    },
    {
      nanogenreId: 3715,
      x: 392430,
      y: -4616460
    },
    {
      nanogenreId: 3716,
      x: 3850140,
      y: -10169190
    },
    {
      nanogenreId: 3717,
      x: 6603330,
      y: 2357670
    },
    {
      nanogenreId: 3718,
      x: 9303990,
      y: -8784870
    },
    {
      nanogenreId: 3719,
      x: 3179610,
      y: 10172280
    },
    {
      nanogenreId: 3720,
      x: 3476250,
      y: 9841650
    },
    {
      nanogenreId: 3721,
      x: 6297420,
      y: -4567020
    },
    {
      nanogenreId: 3722,
      x: 7743540,
      y: 7857870
    },
    {
      nanogenreId: 3723,
      x: 3293940,
      y: -6455010
    },
    {
      nanogenreId: 3724,
      x: -3636930,
      y: -8284290
    },
    {
      nanogenreId: 3725,
      x: -8318280,
      y: 1093860
    },
    {
      nanogenreId: 3726,
      x: 5123220,
      y: -5731950
    },
    {
      nanogenreId: 3727,
      x: -8309010,
      y: 4743150
    },
    {
      nanogenreId: 3728,
      x: -849750,
      y: -1294710
    },
    {
      nanogenreId: 3729,
      x: 923910,
      y: 3633840
    },
    {
      nanogenreId: 3730,
      x: 3349560,
      y: -12461970
    },
    {
      nanogenreId: 3731,
      x: 11043660,
      y: -8516040
    },
    {
      nanogenreId: 3732,
      x: 4072620,
      y: 9189660
    },
    {
      nanogenreId: 3733,
      x: 960990,
      y: -556200
    },
    {
      nanogenreId: 3734,
      x: 6167640,
      y: -2345310
    },
    {
      nanogenreId: 3735,
      x: -7672470,
      y: 11049840
    },
    {
      nanogenreId: 3736,
      x: 5259180,
      y: 8852850
    },
    {
      nanogenreId: 3737,
      x: -519120,
      y: -7864050
    },
    {
      nanogenreId: 3738,
      x: -948630,
      y: 1813830
    },
    {
      nanogenreId: 3739,
      x: -1739670,
      y: 10694490
    },
    {
      nanogenreId: 3740,
      x: 13410600,
      y: -747780
    },
    {
      nanogenreId: 3741,
      x: 5982240,
      y: -8123610
    },
    {
      nanogenreId: 3742,
      x: -760140,
      y: 10536900
    },
    {
      nanogenreId: 3743,
      x: 4125150,
      y: 3890310
    },
    {
      nanogenreId: 3744,
      x: 3701820,
      y: -11059110
    },
    {
      nanogenreId: 3745,
      x: 3167250,
      y: 9449220
    },
    {
      nanogenreId: 3746,
      x: -1075320,
      y: 8886840
    },
    {
      nanogenreId: 3747,
      x: 3117810,
      y: -5392050
    },
    {
      nanogenreId: 3748,
      x: 5209740,
      y: 4638090
    },
    {
      nanogenreId: 3749,
      x: -1412130,
      y: 370800
    },
    {
      nanogenreId: 3750,
      x: -719970,
      y: 148320
    },
    {
      nanogenreId: 3751,
      x: 5320980,
      y: 460410
    },
    {
      nanogenreId: 3752,
      x: 10771740,
      y: -1371960
    },
    {
      nanogenreId: 3753,
      x: 6492090,
      y: -5827740
    },
    {
      nanogenreId: 3754,
      x: -2159910,
      y: -6278880
    },
    {
      nanogenreId: 3755,
      x: 4968720,
      y: 10818090
    },
    {
      nanogenreId: 3756,
      x: 9430680,
      y: -4044810
    },
    {
      nanogenreId: 3757,
      x: 655080,
      y: 7830060
    },
    {
      nanogenreId: 3758,
      x: -451140,
      y: 8058720
    },
    {
      nanogenreId: 3759,
      x: 6019320,
      y: 10197000
    },
    {
      nanogenreId: 3760,
      x: 71070,
      y: -5404410
    },
    {
      nanogenreId: 3761,
      x: -803400,
      y: 7005030
    },
    {
      nanogenreId: 3762,
      x: -2589420,
      y: -6272700
    },
    {
      nanogenreId: 3763,
      x: -4826580,
      y: 4767870
    },
    {
      nanogenreId: 3764,
      x: 6099660,
      y: 10332960
    },
    {
      nanogenreId: 3765,
      x: -2808810,
      y: -6118200
    },
    {
      nanogenreId: 3766,
      x: -5160300,
      y: 2410200
    },
    {
      nanogenreId: 3767,
      x: -6010050,
      y: -9850920
    },
    {
      nanogenreId: 3768,
      x: 6346860,
      y: 9949800
    },
    {
      nanogenreId: 3769,
      x: 4749330,
      y: -8237940
    },
    {
      nanogenreId: 3770,
      x: 7382010,
      y: -5744310
    },
    {
      nanogenreId: 3771,
      x: 10067220,
      y: -9245280
    },
    {
      nanogenreId: 3772,
      x: 7119360,
      y: -7740450
    },
    {
      nanogenreId: 3773,
      x: 6025500,
      y: 8753970
    },
    {
      nanogenreId: 3774,
      x: 2824260,
      y: 8698350
    },
    {
      nanogenreId: 3775,
      x: -2898420,
      y: 7230600
    },
    {
      nanogenreId: 3776,
      x: 5543460,
      y: 10048680
    },
    {
      nanogenreId: 3777,
      x: 5815380,
      y: 9832380
    },
    {
      nanogenreId: 3778,
      x: -9714960,
      y: 4406340
    },
    {
      nanogenreId: 3779,
      x: 3871770,
      y: 8123610
    },
    {
      nanogenreId: 3780,
      x: -14050230,
      y: 3791430
    },
    {
      nanogenreId: 3781,
      x: 478950,
      y: 472770
    },
    {
      nanogenreId: 3782,
      x: 4953270,
      y: -791040
    },
    {
      nanogenreId: 3783,
      x: 12792600,
      y: 5324070
    },
    {
      nanogenreId: 3784,
      x: 5432220,
      y: 7848600
    },
    {
      nanogenreId: 3785,
      x: -2901510,
      y: 1637700
    },
    {
      nanogenreId: 3786,
      x: 7938210,
      y: 9962160
    },
    {
      nanogenreId: 3787,
      x: -10234080,
      y: -716880
    },
    {
      nanogenreId: 3788,
      x: 4699890,
      y: 9668610
    },
    {
      nanogenreId: 3789,
      x: 5364240,
      y: 10230990
    },
    {
      nanogenreId: 3790,
      x: -6278880,
      y: 5976060
    },
    {
      nanogenreId: 3791,
      x: 4026270,
      y: 8645820
    },
    {
      nanogenreId: 3792,
      x: 6393210,
      y: 10419480
    },
    {
      nanogenreId: 3793,
      x: 8126700,
      y: -6831990
    },
    {
      nanogenreId: 3794,
      x: -244110,
      y: 3556590
    },
    {
      nanogenreId: 3795,
      x: 5268450,
      y: 2438010
    },
    {
      nanogenreId: 3796,
      x: -12412530,
      y: -6371580
    },
    {
      nanogenreId: 3797,
      x: -3958290,
      y: 5181930
    },
    {
      nanogenreId: 3798,
      x: 3417540,
      y: 4091160
    },
    {
      nanogenreId: 3799,
      x: -639630,
      y: 4486680
    },
    {
      nanogenreId: 3800,
      x: 8528400,
      y: -8324460
    },
    {
      nanogenreId: 3801,
      x: 8482050,
      y: -8089620
    },
    {
      nanogenreId: 3802,
      x: 4944000,
      y: 9718050
    },
    {
      nanogenreId: 3803,
      x: -6152190,
      y: 5667060
    },
    {
      nanogenreId: 3804,
      x: 2419470,
      y: 14986500
    },
    {
      nanogenreId: 3805,
      x: 8420250,
      y: -7972200
    },
    {
      nanogenreId: 3806,
      x: -14872170,
      y: -5392050
    },
    {
      nanogenreId: 3807,
      x: 5089230,
      y: -889920
    },
    {
      nanogenreId: 3808,
      x: -11819250,
      y: -11408280
    },
    {
      nanogenreId: 3809,
      x: 8173050,
      y: 7907310
    },
    {
      nanogenreId: 3810,
      x: 3516420,
      y: -562380
    },
    {
      nanogenreId: 3811,
      x: -2484360,
      y: 5388960
    },
    {
      nanogenreId: 3812,
      x: 1526460,
      y: 5880270
    },
    {
      nanogenreId: 3813,
      x: -3074550,
      y: -2725380
    },
    {
      nanogenreId: 3814,
      x: 6238710,
      y: 8627280
    },
    {
      nanogenreId: 3815,
      x: 2472000,
      y: 8191590
    },
    {
      nanogenreId: 3816,
      x: -3930480,
      y: -8244120
    },
    {
      nanogenreId: 3817,
      x: 3429900,
      y: -9860190
    },
    {
      nanogenreId: 3818,
      x: 4523760,
      y: 7536510
    },
    {
      nanogenreId: 3819,
      x: 2317500,
      y: -8723070
    },
    {
      nanogenreId: 3820,
      x: -336810,
      y: -7953660
    },
    {
      nanogenreId: 3821,
      x: -5506380,
      y: 7066830
    },
    {
      nanogenreId: 3822,
      x: -7514880,
      y: -12758610
    },
    {
      nanogenreId: 3823,
      x: -3334110,
      y: -852840
    },
    {
      nanogenreId: 3824,
      x: 7901130,
      y: -7276950
    },
    {
      nanogenreId: 3825,
      x: -519120,
      y: 1028970
    },
    {
      nanogenreId: 3826,
      x: 466590,
      y: 6343770
    },
    {
      nanogenreId: 3827,
      x: 8478960,
      y: 5191200
    },
    {
      nanogenreId: 3828,
      x: -6658950,
      y: 4542300
    },
    {
      nanogenreId: 3829,
      x: 6473550,
      y: -6022410
    },
    {
      nanogenreId: 3830,
      x: -7242960,
      y: -7842420
    },
    {
      nanogenreId: 3831,
      x: 7530330,
      y: -4174590
    },
    {
      nanogenreId: 3832,
      x: 5673240,
      y: 10054860
    },
    {
      nanogenreId: 3833,
      x: -13435320,
      y: -43260
    },
    {
      nanogenreId: 3834,
      x: -673620,
      y: 6390120
    },
    {
      nanogenreId: 3835,
      x: 5154120,
      y: -3454620
    },
    {
      nanogenreId: 3836,
      x: 1921980,
      y: 1745850
    },
    {
      nanogenreId: 3837,
      x: 1711860,
      y: 12736980
    },
    {
      nanogenreId: 3838,
      x: -6433380,
      y: -7802250
    },
    {
      nanogenreId: 3839,
      x: 4162230,
      y: -3525690
    },
    {
      nanogenreId: 3840,
      x: 11544240,
      y: -6220170
    },
    {
      nanogenreId: 3841,
      x: 3606030,
      y: 8154510
    },
    {
      nanogenreId: 3842,
      x: 7598310,
      y: -7094640
    },
    {
      nanogenreId: 3843,
      x: -6575520,
      y: 1517190
    },
    {
      nanogenreId: 3844,
      x: -4094250,
      y: 1137120
    },
    {
      nanogenreId: 3845,
      x: -3902670,
      y: -2005410
    },
    {
      nanogenreId: 3846,
      x: -587100,
      y: -2203170
    },
    {
      nanogenreId: 3847,
      x: -3627660,
      y: -6436470
    },
    {
      nanogenreId: 3848,
      x: -3674010,
      y: 14467380
    },
    {
      nanogenreId: 3849,
      x: 5994600,
      y: -7830060
    },
    {
      nanogenreId: 3850,
      x: 8188500,
      y: -3871770
    },
    {
      nanogenreId: 3851,
      x: -1316340,
      y: 13163400
    },
    {
      nanogenreId: 3852,
      x: 4007730,
      y: 10067220
    },
    {
      nanogenreId: 3853,
      x: 8608740,
      y: -8723070
    },
    {
      nanogenreId: 3854,
      x: 6013140,
      y: 10543080
    },
    {
      nanogenreId: 3855,
      x: 6173820,
      y: 1251450
    },
    {
      nanogenreId: 3856,
      x: 710700,
      y: 8241030
    },
    {
      nanogenreId: 3857,
      x: 5744310,
      y: 7456170
    },
    {
      nanogenreId: 3858,
      x: 5772120,
      y: -11148720
    },
    {
      nanogenreId: 3859,
      x: 5067600,
      y: 8256480
    },
    {
      nanogenreId: 3860,
      x: -2234070,
      y: -4217850
    },
    {
      nanogenreId: 3861,
      x: 6303600,
      y: 4115880
    },
    {
      nanogenreId: 3862,
      x: -2623410,
      y: 5611440
    },
    {
      nanogenreId: 3863,
      x: 6930870,
      y: -6801090
    },
    {
      nanogenreId: 3864,
      x: 1863270,
      y: 7069920
    },
    {
      nanogenreId: 3865,
      x: 5042880,
      y: 8531490
    },
    {
      nanogenreId: 3866,
      x: 8420250,
      y: -8818860
    },
    {
      nanogenreId: 3867,
      x: -3621480,
      y: 15091560
    },
    {
      nanogenreId: 3868,
      x: -13945170,
      y: -4610280
    },
    {
      nanogenreId: 3869,
      x: 17214390,
      y: 13731960
    },
    {
      nanogenreId: 3870,
      x: 8250300,
      y: -7212060
    },
    {
      nanogenreId: 3871,
      x: 6149100,
      y: -5549640
    },
    {
      nanogenreId: 3872,
      x: 231750,
      y: 9900360
    },
    {
      nanogenreId: 3873,
      x: 9535740,
      y: -7378920
    },
    {
      nanogenreId: 3874,
      x: -2595600,
      y: -710700
    },
    {
      nanogenreId: 3875,
      x: 2314410,
      y: 1458480
    },
    {
      nanogenreId: 3876,
      x: -1146390,
      y: -5104680
    },
    {
      nanogenreId: 3877,
      x: -1906530,
      y: 9529560
    },
    {
      nanogenreId: 3878,
      x: 1820010,
      y: 10759380
    },
    {
      nanogenreId: 3879,
      x: -1405950,
      y: -7808430
    },
    {
      nanogenreId: 3880,
      x: 639630,
      y: 4279650
    },
    {
      nanogenreId: 3881,
      x: 129780,
      y: 8330640
    },
    {
      nanogenreId: 3882,
      x: -6547710,
      y: 7261500
    },
    {
      nanogenreId: 3883,
      x: 11732730,
      y: 2289690
    },
    {
      nanogenreId: 3884,
      x: 2876790,
      y: 7919670
    },
    {
      nanogenreId: 3885,
      x: 2771730,
      y: 16880670
    },
    {
      nanogenreId: 3886,
      x: 2163000,
      y: 2302050
    },
    {
      nanogenreId: 3887,
      x: -3253770,
      y: 3429900
    },
    {
      nanogenreId: 3888,
      x: 7443810,
      y: -6476640
    },
    {
      nanogenreId: 3889,
      x: -1303980,
      y: 5274630
    },
    {
      nanogenreId: 3890,
      x: 2277330,
      y: 9637710
    },
    {
      nanogenreId: 3891,
      x: -3225960,
      y: 5874090
    },
    {
      nanogenreId: 3892,
      x: -5435310,
      y: -11052930
    },
    {
      nanogenreId: 3893,
      x: -4041720,
      y: 4146780
    },
    {
      nanogenreId: 3894,
      x: 5120130,
      y: -6000780
    },
    {
      nanogenreId: 3895,
      x: -3479340,
      y: 11229060
    },
    {
      nanogenreId: 3896,
      x: 13055250,
      y: 2929320
    },
    {
      nanogenreId: 3897,
      x: 1220550,
      y: 6421020
    },
    {
      nanogenreId: 3898,
      x: -6930870,
      y: 7057560
    },
    {
      nanogenreId: 3899,
      x: 5401320,
      y: 10632690
    },
    {
      nanogenreId: 3900,
      x: 14266530,
      y: 16497510
    },
    {
      nanogenreId: 3901,
      x: -4298190,
      y: 5994600
    },
    {
      nanogenreId: 3902,
      x: 9946710,
      y: -7891860
    },
    {
      nanogenreId: 3903,
      x: 8902290,
      y: -3003480
    },
    {
      nanogenreId: 3904,
      x: -2941680,
      y: -4916190
    },
    {
      nanogenreId: 3905,
      x: -3127080,
      y: -4668990
    },
    {
      nanogenreId: 3906,
      x: -4044810,
      y: 3899580
    },
    {
      nanogenreId: 3907,
      x: -10462740,
      y: -3958290
    },
    {
      nanogenreId: 3908,
      x: -6059490,
      y: 7950570
    },
    {
      nanogenreId: 3909,
      x: 1439940,
      y: 6056400
    },
    {
      nanogenreId: 3910,
      x: 7119360,
      y: -6421020
    },
    {
      nanogenreId: 3911,
      x: 8077260,
      y: -7870230
    },
    {
      nanogenreId: 3912,
      x: -8525310,
      y: 86520
    },
    {
      nanogenreId: 3913,
      x: -1347240,
      y: -2592510
    },
    {
      nanogenreId: 3914,
      x: -1477020,
      y: 8843580
    },
    {
      nanogenreId: 3915,
      x: -327540,
      y: 9177300
    },
    {
      nanogenreId: 3916,
      x: 1171110,
      y: -5660880
    },
    {
      nanogenreId: 3917,
      x: -726150,
      y: 3142530
    },
    {
      nanogenreId: 3918,
      x: 3822330,
      y: 10268070
    },
    {
      nanogenreId: 3919,
      x: -8253390,
      y: 5815380
    },
    {
      nanogenreId: 3920,
      x: -6084210,
      y: 10737750
    },
    {
      nanogenreId: 3921,
      x: 676710,
      y: 1548090
    },
    {
      nanogenreId: 3922,
      x: -420240,
      y: -4350720
    },
    {
      nanogenreId: 3923,
      x: 11142540,
      y: -7351110
    },
    {
      nanogenreId: 3924,
      x: -7511790,
      y: -6424110
    },
    {
      nanogenreId: 3925,
      x: -5351880,
      y: -2978760
    },
    {
      nanogenreId: 3926,
      x: -5030520,
      y: 732330
    },
    {
      nanogenreId: 3927,
      x: -5432220,
      y: 546930
    },
    {
      nanogenreId: 3928,
      x: -9449220,
      y: 3779070
    },
    {
      nanogenreId: 3929,
      x: -5620710,
      y: 1708770
    },
    {
      nanogenreId: 3930,
      x: 2734650,
      y: 9313260
    },
    {
      nanogenreId: 3931,
      x: -74160,
      y: 797220
    },
    {
      nanogenreId: 3932,
      x: -1180380,
      y: 4718430
    },
    {
      nanogenreId: 3933,
      x: 2713020,
      y: -2509080
    },
    {
      nanogenreId: 3934,
      x: -4369260,
      y: 6615690
    },
    {
      nanogenreId: 3935,
      x: 9140220,
      y: 7811520
    },
    {
      nanogenreId: 3936,
      x: -4542300,
      y: -1498650
    },
    {
      nanogenreId: 3937,
      x: 448050,
      y: 2601780
    },
    {
      nanogenreId: 3938,
      x: 469680,
      y: 7487070
    },
    {
      nanogenreId: 3939,
      x: 2407110,
      y: 8429520
    },
    {
      nanogenreId: 3940,
      x: 8911560,
      y: -7613760
    },
    {
      nanogenreId: 3941,
      x: -3117810,
      y: -7607580
    },
    {
      nanogenreId: 3942,
      x: 6455010,
      y: -7422180
    },
    {
      nanogenreId: 3943,
      x: -3460800,
      y: -11207430
    },
    {
      nanogenreId: 3944,
      x: 11775990,
      y: 1903440
    },
    {
      nanogenreId: 3945,
      x: 3921210,
      y: 9399780
    },
    {
      nanogenreId: 3946,
      x: 2611050,
      y: -3028200
    },
    {
      nanogenreId: 3947,
      x: -336810,
      y: 10947870
    },
    {
      nanogenreId: 3948,
      x: -7403640,
      y: 6040950
    },
    {
      nanogenreId: 3949,
      x: -4298190,
      y: 126690
    },
    {
      nanogenreId: 3950,
      x: 5314800,
      y: 8859030
    },
    {
      nanogenreId: 3951,
      x: 4702980,
      y: 9563550
    },
    {
      nanogenreId: 3952,
      x: -5225190,
      y: -1093860
    },
    {
      nanogenreId: 3953,
      x: 11015850,
      y: -420240
    },
    {
      nanogenreId: 3954,
      x: -3850140,
      y: 1137120
    },
    {
      nanogenreId: 3955,
      x: -781770,
      y: -4004640
    },
    {
      nanogenreId: 3956,
      x: -2546160,
      y: 6232530
    },
    {
      nanogenreId: 3957,
      x: -1161840,
      y: -6918510
    },
    {
      nanogenreId: 3958,
      x: -991890,
      y: 392430
    },
    {
      nanogenreId: 3959,
      x: -1693320,
      y: 9881820
    },
    {
      nanogenreId: 3960,
      x: -12001560,
      y: -5521830
    },
    {
      nanogenreId: 3961,
      x: 4449600,
      y: 8361540
    },
    {
      nanogenreId: 3962,
      x: 5617620,
      y: 9835470
    },
    {
      nanogenreId: 3963,
      x: 2651220,
      y: -2255700
    },
    {
      nanogenreId: 3964,
      x: 2617230,
      y: 9566640
    },
    {
      nanogenreId: 3965,
      x: 10280430,
      y: 9551190
    },
    {
      nanogenreId: 3966,
      x: -2784090,
      y: -3961380
    },
    {
      nanogenreId: 3967,
      x: -469680,
      y: 2645040
    },
    {
      nanogenreId: 3968,
      x: 15480900,
      y: 4353810
    },
    {
      nanogenreId: 3969,
      x: -2518350,
      y: -4075710
    },
    {
      nanogenreId: 3970,
      x: 9789120,
      y: -9297810
    },
    {
      nanogenreId: 3971,
      x: -4808040,
      y: 14183100
    },
    {
      nanogenreId: 3972,
      x: 7326390,
      y: 5830830
    },
    {
      nanogenreId: 3973,
      x: 1121670,
      y: -6288150
    },
    {
      nanogenreId: 3974,
      x: 1795290,
      y: 2110470
    },
    {
      nanogenreId: 3975,
      x: -1260720,
      y: 4190040
    },
    {
      nanogenreId: 3976,
      x: 5772120,
      y: -4910010
    },
    {
      nanogenreId: 3977,
      x: -6677490,
      y: -1118580
    },
    {
      nanogenreId: 3978,
      x: 6411750,
      y: 8994990
    },
    {
      nanogenreId: 3979,
      x: -9925080,
      y: -5293170
    },
    {
      nanogenreId: 3980,
      x: -1443030,
      y: 800310
    },
    {
      nanogenreId: 3981,
      x: -10268070,
      y: -7029750
    },
    {
      nanogenreId: 3982,
      x: -8478960,
      y: 5855550
    },
    {
      nanogenreId: 3983,
      x: -3102360,
      y: -1059870
    },
    {
      nanogenreId: 3984,
      x: 8401710,
      y: -3213600
    },
    {
      nanogenreId: 3985,
      x: -1022790,
      y: -2799540
    },
    {
      nanogenreId: 3986,
      x: 126690,
      y: -466590
    },
    {
      nanogenreId: 3987,
      x: -1656240,
      y: -3429900
    },
    {
      nanogenreId: 3988,
      x: -871380,
      y: 4103520
    },
    {
      nanogenreId: 3989,
      x: 7289310,
      y: -5667060
    },
    {
      nanogenreId: 3990,
      x: 2978760,
      y: -3541140
    },
    {
      nanogenreId: 3991,
      x: 4149870,
      y: 9612990
    },
    {
      nanogenreId: 3992,
      x: -9705690,
      y: -1668600
    },
    {
      nanogenreId: 3993,
      x: 5429130,
      y: 4631910
    },
    {
      nanogenreId: 3994,
      x: 3022020,
      y: 8009280
    },
    {
      nanogenreId: 3995,
      x: -4159140,
      y: -11553510
    },
    {
      nanogenreId: 3996,
      x: 7199700,
      y: -8933190
    },
    {
      nanogenreId: 3997,
      x: 3627660,
      y: 9628440
    },
    {
      nanogenreId: 3998,
      x: 11337210,
      y: 3374280
    },
    {
      nanogenreId: 3999,
      x: -1415220,
      y: 5256090
    },
    {
      nanogenreId: 4000,
      x: -13528020,
      y: -1887990
    },
    {
      nanogenreId: 4001,
      x: 6606420,
      y: -4486680
    },
    {
      nanogenreId: 4002,
      x: -3185790,
      y: -10713030
    },
    {
      nanogenreId: 4003,
      x: -10632690,
      y: 2573970
    },
    {
      nanogenreId: 4004,
      x: 2688300,
      y: -976440
    },
    {
      nanogenreId: 4005,
      x: 2561610,
      y: 6560070
    },
    {
      nanogenreId: 4006,
      x: 460410,
      y: -7548870
    },
    {
      nanogenreId: 4007,
      x: -1931250,
      y: 7088460
    },
    {
      nanogenreId: 4008,
      x: -7907310,
      y: 3751260
    },
    {
      nanogenreId: 4009,
      x: 12054090,
      y: -7140990
    },
    {
      nanogenreId: 4010,
      x: -4919280,
      y: -1266900
    },
    {
      nanogenreId: 4011,
      x: 3806880,
      y: -10360770
    },
    {
      nanogenreId: 4012,
      x: 6535350,
      y: 3222870
    },
    {
      nanogenreId: 4013,
      x: -6464280,
      y: 2913870
    },
    {
      nanogenreId: 4014,
      x: 3077640,
      y: -2048670
    },
    {
      nanogenreId: 4015,
      x: -6662040,
      y: -3806880
    },
    {
      nanogenreId: 4016,
      x: -13302450,
      y: -4761690
    },
    {
      nanogenreId: 4017,
      x: -10873710,
      y: 9662430
    },
    {
      nanogenreId: 4018,
      x: 2858250,
      y: 5558910
    },
    {
      nanogenreId: 4019,
      x: -1183470,
      y: 8432610
    },
    {
      nanogenreId: 4020,
      x: 2203170,
      y: 7703370
    },
    {
      nanogenreId: 4021,
      x: 1455390,
      y: 10036320
    },
    {
      nanogenreId: 4022,
      x: 10308240,
      y: 3612210
    },
    {
      nanogenreId: 4023,
      x: 3269220,
      y: -3405180
    },
    {
      nanogenreId: 4024,
      x: -13268460,
      y: -3976830
    },
    {
      nanogenreId: 4025,
      x: 16639650,
      y: 13141770
    },
    {
      nanogenreId: 4026,
      x: 2376210,
      y: -3757440
    },
    {
      nanogenreId: 4027,
      x: 1245270,
      y: 466590
    },
    {
      nanogenreId: 4028,
      x: 5361150,
      y: 8806500
    },
    {
      nanogenreId: 4029,
      x: -1594440,
      y: 3139440
    },
    {
      nanogenreId: 4030,
      x: 9371970,
      y: -9124770
    },
    {
      nanogenreId: 4031,
      x: -2771730,
      y: 210120
    },
    {
      nanogenreId: 4032,
      x: -899190,
      y: -871380
    },
    {
      nanogenreId: 4033,
      x: 1081500,
      y: 6590970
    },
    {
      nanogenreId: 4034,
      x: 237930,
      y: 5985330
    },
    {
      nanogenreId: 4035,
      x: -3185790,
      y: -772500
    },
    {
      nanogenreId: 4036,
      x: -14414850,
      y: -2858250
    },
    {
      nanogenreId: 4037,
      x: -12483600,
      y: 3164160
    },
    {
      nanogenreId: 4038,
      x: 4730790,
      y: 8284290
    },
    {
      nanogenreId: 4039,
      x: 2283510,
      y: 8491320
    },
    {
      nanogenreId: 4040,
      x: 3627660,
      y: -5874090
    },
    {
      nanogenreId: 4041,
      x: 11006580,
      y: -5339520
    },
    {
      nanogenreId: 4042,
      x: -6884520,
      y: 2719200
    },
    {
      nanogenreId: 4043,
      x: 3640020,
      y: -256470
    },
    {
      nanogenreId: 4044,
      x: 1844730,
      y: 9183480
    },
    {
      nanogenreId: 4045,
      x: -5846280,
      y: 352260
    },
    {
      nanogenreId: 4046,
      x: -426420,
      y: 3782160
    },
    {
      nanogenreId: 4047,
      x: 3117810,
      y: 11871780
    },
    {
      nanogenreId: 4048,
      x: -3003480,
      y: 6615690
    },
    {
      nanogenreId: 4049,
      x: 7465440,
      y: -9127860
    },
    {
      nanogenreId: 4050,
      x: -5503290,
      y: 6084210
    },
    {
      nanogenreId: 4051,
      x: 862110,
      y: 8679810
    },
    {
      nanogenreId: 4052,
      x: -6241800,
      y: 5055240
    },
    {
      nanogenreId: 4053,
      x: 5799930,
      y: 9680970
    },
    {
      nanogenreId: 4054,
      x: -8988810,
      y: 1285440
    },
    {
      nanogenreId: 4055,
      x: -8812680,
      y: 6244890
    },
    {
      nanogenreId: 4056,
      x: -1770570,
      y: 237930
    },
    {
      nanogenreId: 4057,
      x: -528390,
      y: -8726160
    },
    {
      nanogenreId: 4058,
      x: -1303980,
      y: 488220
    },
    {
      nanogenreId: 4059,
      x: -7619940,
      y: -1439940
    },
    {
      nanogenreId: 4060,
      x: 7471620,
      y: -8685990
    },
    {
      nanogenreId: 4061,
      x: -1959060,
      y: 1650060
    },
    {
      nanogenreId: 4062,
      x: 4900740,
      y: 9900360
    },
    {
      nanogenreId: 4063,
      x: -268830,
      y: 5574360
    },
    {
      nanogenreId: 4064,
      x: -1628430,
      y: 1236000
    },
    {
      nanogenreId: 4065,
      x: 5497110,
      y: -6495180
    },
    {
      nanogenreId: 4066,
      x: -4292010,
      y: 8945550
    },
    {
      nanogenreId: 4067,
      x: 5382780,
      y: 9996150
    },
    {
      nanogenreId: 4068,
      x: -3843960,
      y: 1464660
    },
    {
      nanogenreId: 4069,
      x: -11541150,
      y: -2280420
    },
    {
      nanogenreId: 4070,
      x: 5089230,
      y: 10057950
    },
    {
      nanogenreId: 4071,
      x: 4480500,
      y: 10484370
    },
    {
      nanogenreId: 4072,
      x: 3392820,
      y: 3887220
    },
    {
      nanogenreId: 4073,
      x: 188490,
      y: 7796070
    },
    {
      nanogenreId: 4074,
      x: 3701820,
      y: 6686760
    },
    {
      nanogenreId: 4075,
      x: 11742000,
      y: 6210900
    },
    {
      nanogenreId: 4076,
      x: 8139060,
      y: -4684440
    },
    {
      nanogenreId: 4077,
      x: -6377760,
      y: -1378140
    },
    {
      nanogenreId: 4078,
      x: -4903830,
      y: 4971810
    },
    {
      nanogenreId: 4079,
      x: -2861340,
      y: 7471620
    },
    {
      nanogenreId: 4080,
      x: -275010,
      y: 3942840
    },
    {
      nanogenreId: 4081,
      x: -4026270,
      y: -346080
    },
    {
      nanogenreId: 4082,
      x: -2648130,
      y: 1464660
    },
    {
      nanogenreId: 4083,
      x: -658170,
      y: -9310170
    },
    {
      nanogenreId: 4084,
      x: -1415220,
      y: 2036310
    },
    {
      nanogenreId: 4085,
      x: -7094640,
      y: 7894950
    },
    {
      nanogenreId: 4086,
      x: 234840,
      y: -11998470
    },
    {
      nanogenreId: 4087,
      x: 6448830,
      y: 6899970
    },
    {
      nanogenreId: 4088,
      x: 9517200,
      y: 5784480
    },
    {
      nanogenreId: 4089,
      x: -1996140,
      y: 1492470
    },
    {
      nanogenreId: 4090,
      x: -4897650,
      y: 4492860
    },
    {
      nanogenreId: 4091,
      x: 4335270,
      y: 9449220
    },
    {
      nanogenreId: 4092,
      x: 4940910,
      y: 8661270
    },
    {
      nanogenreId: 4093,
      x: 2014680,
      y: 7082280
    },
    {
      nanogenreId: 4094,
      x: -4777140,
      y: 3723450
    },
    {
      nanogenreId: 4095,
      x: 16964100,
      y: -673620
    },
    {
      nanogenreId: 4096,
      x: -6804180,
      y: -5518740
    },
    {
      nanogenreId: 4097,
      x: -6142920,
      y: 4641180
    },
    {
      nanogenreId: 4098,
      x: 917730,
      y: 4279650
    },
    {
      nanogenreId: 4099,
      x: -6241800,
      y: 380070
    },
    {
      nanogenreId: 4100,
      x: 8398620,
      y: -4579380
    },
    {
      nanogenreId: 4101,
      x: 7734270,
      y: -5642340
    },
    {
      nanogenreId: 4102,
      x: 3185790,
      y: -5123220
    },
    {
      nanogenreId: 4103,
      x: 1115490,
      y: 5314800
    },
    {
      nanogenreId: 4104,
      x: 4706070,
      y: 9940530
    },
    {
      nanogenreId: 4105,
      x: 4903830,
      y: 9297810
    },
    {
      nanogenreId: 4106,
      x: -4384710,
      y: 5376600
    },
    {
      nanogenreId: 4107,
      x: 6229440,
      y: -5722680
    },
    {
      nanogenreId: 4108,
      x: -237930,
      y: -6294330
    },
    {
      nanogenreId: 4109,
      x: 1378140,
      y: 7675560
    },
    {
      nanogenreId: 4110,
      x: 6278880,
      y: 231750
    },
    {
      nanogenreId: 4111,
      x: -942450,
      y: 4786410
    },
    {
      nanogenreId: 4112,
      x: 614910,
      y: -9705690
    },
    {
      nanogenreId: 4113,
      x: -7310940,
      y: 5484750
    },
    {
      nanogenreId: 4114,
      x: 1396680,
      y: 4597920
    },
    {
      nanogenreId: 4115,
      x: -3173430,
      y: 1578990
    },
    {
      nanogenreId: 4116,
      x: 5864820,
      y: 5812290
    },
    {
      nanogenreId: 4117,
      x: -1371960,
      y: 4835850
    },
    {
      nanogenreId: 4118,
      x: -2793360,
      y: -1674780
    },
    {
      nanogenreId: 4119,
      x: 2814990,
      y: -3392820
    },
    {
      nanogenreId: 4120,
      x: -4554660,
      y: 5067600
    },
    {
      nanogenreId: 4121,
      x: 1436850,
      y: -7839330
    },
    {
      nanogenreId: 4122,
      x: 3698730,
      y: 8599470
    },
    {
      nanogenreId: 4123,
      x: -886830,
      y: -5898810
    },
    {
      nanogenreId: 4124,
      x: -4156050,
      y: 3636930
    },
    {
      nanogenreId: 4125,
      x: -6634230,
      y: 6977220
    },
    {
      nanogenreId: 4126,
      x: 448050,
      y: 5648520
    },
    {
      nanogenreId: 4127,
      x: 3065280,
      y: 3476250
    },
    {
      nanogenreId: 4128,
      x: 9436860,
      y: -3463890
    },
    {
      nanogenreId: 4129,
      x: 5042880,
      y: 10030140
    },
    {
      nanogenreId: 4130,
      x: -967170,
      y: -8982630
    },
    {
      nanogenreId: 4131,
      x: 3680190,
      y: 8735430
    },
    {
      nanogenreId: 4132,
      x: 6887610,
      y: 18484380
    },
    {
      nanogenreId: 4133,
      x: 2740830,
      y: -2808810
    },
    {
      nanogenreId: 4134,
      x: 7734270,
      y: -6093480
    },
    {
      nanogenreId: 4135,
      x: 4749330,
      y: 9677880
    },
    {
      nanogenreId: 4136,
      x: 4075710,
      y: -234840
    },
    {
      nanogenreId: 4137,
      x: -5311710,
      y: -2252610
    },
    {
      nanogenreId: 4138,
      x: -4495950,
      y: -543840
    },
    {
      nanogenreId: 4139,
      x: -6825810,
      y: -1776750
    },
    {
      nanogenreId: 4140,
      x: -11420640,
      y: 911550
    },
    {
      nanogenreId: 4141,
      x: -10221720,
      y: -2277330
    },
    {
      nanogenreId: 4142,
      x: -704520,
      y: 8855940
    },
    {
      nanogenreId: 4143,
      x: 6266520,
      y: 10264980
    },
    {
      nanogenreId: 4144,
      x: -611820,
      y: 2261880
    },
    {
      nanogenreId: 4145,
      x: -6473550,
      y: 401700
    },
    {
      nanogenreId: 4146,
      x: 237930,
      y: 5135580
    },
    {
      nanogenreId: 4147,
      x: 5320980,
      y: 8976450
    },
    {
      nanogenreId: 4148,
      x: 9786030,
      y: -7792980
    },
    {
      nanogenreId: 4149,
      x: 361530,
      y: 7354200
    },
    {
      nanogenreId: 4150,
      x: -2410200,
      y: -10580160
    },
    {
      nanogenreId: 4151,
      x: -2960220,
      y: -618000
    },
    {
      nanogenreId: 4152,
      x: 9337980,
      y: -8441880
    },
    {
      nanogenreId: 4153,
      x: -5240640,
      y: -7378920
    },
    {
      nanogenreId: 4154,
      x: 10536900,
      y: -1696410
    },
    {
      nanogenreId: 4155,
      x: 4829670,
      y: -1001160
    },
    {
      nanogenreId: 4156,
      x: 8893020,
      y: -5716500
    },
    {
      nanogenreId: 4157,
      x: -342990,
      y: -2061030
    },
    {
      nanogenreId: 4158,
      x: 5617620,
      y: -8052540
    },
    {
      nanogenreId: 4159,
      x: -10317510,
      y: -10743930
    },
    {
      nanogenreId: 4160,
      x: -4804950,
      y: 1371960
    },
    {
      nanogenreId: 4161,
      x: 1980690,
      y: -1090770
    },
    {
      nanogenreId: 4162,
      x: 330630,
      y: 590190
    },
    {
      nanogenreId: 4163,
      x: -12752430,
      y: -4508310
    },
    {
      nanogenreId: 4164,
      x: -4940910,
      y: -3195060
    },
    {
      nanogenreId: 4165,
      x: -4233300,
      y: 6526080
    },
    {
      nanogenreId: 4166,
      x: 11973750,
      y: 6349950
    },
    {
      nanogenreId: 4167,
      x: 791040,
      y: 6084210
    },
    {
      nanogenreId: 4168,
      x: -5364240,
      y: 9560460
    },
    {
      nanogenreId: 4169,
      x: -1371960,
      y: 1393590
    },
    {
      nanogenreId: 4170,
      x: -8735430,
      y: -2527620
    },
    {
      nanogenreId: 4171,
      x: 933180,
      y: 7894950
    },
    {
      nanogenreId: 4172,
      x: -3769800,
      y: 2675940
    },
    {
      nanogenreId: 4173,
      x: 2561610,
      y: -1291620
    },
    {
      nanogenreId: 4174,
      x: 4465050,
      y: 8719980
    },
    {
      nanogenreId: 4175,
      x: -4081890,
      y: -6714570
    },
    {
      nanogenreId: 4176,
      x: -2963310,
      y: 6519900
    },
    {
      nanogenreId: 4177,
      x: -6065670,
      y: 6112020
    },
    {
      nanogenreId: 4178,
      x: -4347630,
      y: -5756670
    },
    {
      nanogenreId: 4179,
      x: -13719600,
      y: -2994210
    },
    {
      nanogenreId: 4180,
      x: -9059880,
      y: -129780
    },
    {
      nanogenreId: 4181,
      x: 6451920,
      y: 16855950
    },
    {
      nanogenreId: 4182,
      x: -3408270,
      y: 9362700
    },
    {
      nanogenreId: 4183,
      x: 3046740,
      y: 8361540
    },
    {
      nanogenreId: 4184,
      x: 1897260,
      y: -4542300
    },
    {
      nanogenreId: 4185,
      x: 1792200,
      y: 834300
    },
    {
      nanogenreId: 4186,
      x: 71070,
      y: 7091550
    },
    {
      nanogenreId: 4187,
      x: 3896490,
      y: 6220170
    },
    {
      nanogenreId: 4188,
      x: 8636550,
      y: -6247980
    },
    {
      nanogenreId: 4189,
      x: -3297030,
      y: -2472000
    },
    {
      nanogenreId: 4190,
      x: -5410590,
      y: 1198920
    },
    {
      nanogenreId: 4191,
      x: 1106220,
      y: -1375050
    },
    {
      nanogenreId: 4192,
      x: 2975670,
      y: 9223650
    },
    {
      nanogenreId: 4193,
      x: -1276170,
      y: 6281970
    },
    {
      nanogenreId: 4194,
      x: -9872550,
      y: 7740450
    },
    {
      nanogenreId: 4195,
      x: -3955200,
      y: -7017390
    },
    {
      nanogenreId: 4196,
      x: 3785250,
      y: 4029360
    },
    {
      nanogenreId: 4197,
      x: -4017000,
      y: 10842810
    },
    {
      nanogenreId: 4198,
      x: 5685600,
      y: 9918900
    },
    {
      nanogenreId: 4199,
      x: 6680580,
      y: -6918510
    },
    {
      nanogenreId: 4200,
      x: 2938590,
      y: 8098890
    },
    {
      nanogenreId: 4201,
      x: -1875630,
      y: 7320210
    },
    {
      nanogenreId: 4202,
      x: 5945160,
      y: -2147550
    },
    {
      nanogenreId: 4203,
      x: -4421790,
      y: 1538820
    },
    {
      nanogenreId: 4204,
      x: 8234850,
      y: 3584400
    },
    {
      nanogenreId: 4205,
      x: 11633850,
      y: 14074950
    },
    {
      nanogenreId: 4206,
      x: -9282360,
      y: 4004640
    },
    {
      nanogenreId: 4207,
      x: 2654310,
      y: 8194680
    },
    {
      nanogenreId: 4208,
      x: -5487840,
      y: -1860180
    },
    {
      nanogenreId: 4209,
      x: 8012370,
      y: -7140990
    },
    {
      nanogenreId: 4210,
      x: -8488230,
      y: -5524920
    },
    {
      nanogenreId: 4211,
      x: -5339520,
      y: -12242580
    },
    {
      nanogenreId: 4212,
      x: -5110860,
      y: 12258030
    },
    {
      nanogenreId: 4213,
      x: 5920440,
      y: 9072240
    },
    {
      nanogenreId: 4214,
      x: 12876030,
      y: -4230210
    },
    {
      nanogenreId: 4215,
      x: 4128240,
      y: -5197380
    },
    {
      nanogenreId: 4216,
      x: 4857480,
      y: 7017390
    },
    {
      nanogenreId: 4217,
      x: -4675170,
      y: -3952110
    },
    {
      nanogenreId: 4218,
      x: -1112400,
      y: 7205880
    },
    {
      nanogenreId: 4219,
      x: -6668220,
      y: -7545780
    },
    {
      nanogenreId: 4220,
      x: -10984950,
      y: -3123990
    },
    {
      nanogenreId: 4221,
      x: -1711860,
      y: 7755900
    },
    {
      nanogenreId: 4222,
      x: -5688690,
      y: 5503290
    },
    {
      nanogenreId: 4223,
      x: 982620,
      y: 18540
    },
    {
      nanogenreId: 4224,
      x: -11541150,
      y: 8568570
    },
    {
      nanogenreId: 4225,
      x: 1773660,
      y: 4798770
    },
    {
      nanogenreId: 4226,
      x: 3704910,
      y: 10200090
    },
    {
      nanogenreId: 4227,
      x: -7774440,
      y: 1628430
    },
    {
      nanogenreId: 4228,
      x: -3822330,
      y: -10932420
    },
    {
      nanogenreId: 4229,
      x: -4427970,
      y: -8973360
    },
    {
      nanogenreId: 4230,
      x: 4458870,
      y: 8797230
    },
    {
      nanogenreId: 4231,
      x: 9714960,
      y: 1217460
    },
    {
      nanogenreId: 4232,
      x: -420240,
      y: 3167250
    },
    {
      nanogenreId: 4233,
      x: -9134040,
      y: -5935890
    },
    {
      nanogenreId: 4234,
      x: -1884900,
      y: 3587490
    },
    {
      nanogenreId: 4235,
      x: -355350,
      y: -6609510
    },
    {
      nanogenreId: 4236,
      x: 6946320,
      y: -5382780
    },
    {
      nanogenreId: 4237,
      x: -10972590,
      y: 1347240
    },
    {
      nanogenreId: 4238,
      x: -3414450,
      y: -4007730
    },
    {
      nanogenreId: 4239,
      x: -5531100,
      y: 2234070
    },
    {
      nanogenreId: 4240,
      x: 9257640,
      y: -6263430
    },
    {
      nanogenreId: 4241,
      x: 5478570,
      y: 10135200
    },
    {
      nanogenreId: 4242,
      x: 8139060,
      y: -8401710
    },
    {
      nanogenreId: 4243,
      x: 3890310,
      y: 5274630
    },
    {
      nanogenreId: 4244,
      x: -11630760,
      y: -296640
    },
    {
      nanogenreId: 4245,
      x: 3340290,
      y: -970260
    },
    {
      nanogenreId: 4246,
      x: -3510240,
      y: -3584400
    },
    {
      nanogenreId: 4247,
      x: -1059870,
      y: 5228280
    },
    {
      nanogenreId: 4248,
      x: -9195840,
      y: -516030
    },
    {
      nanogenreId: 4249,
      x: -531480,
      y: 4202400
    },
    {
      nanogenreId: 4250,
      x: -3256860,
      y: 5608350
    },
    {
      nanogenreId: 4251,
      x: 1711860,
      y: 7786800
    },
    {
      nanogenreId: 4252,
      x: 16494420,
      y: -2190810
    },
    {
      nanogenreId: 4253,
      x: 1415220,
      y: -4353810
    },
    {
      nanogenreId: 4254,
      x: 6000780,
      y: 9696420
    },
    {
      nanogenreId: 4255,
      x: 855930,
      y: -942450
    },
    {
      nanogenreId: 4256,
      x: -1443030,
      y: -4953270
    },
    {
      nanogenreId: 4257,
      x: -630360,
      y: -4054080
    },
    {
      nanogenreId: 4258,
      x: 3735810,
      y: 9829290
    },
    {
      nanogenreId: 4259,
      x: 5042880,
      y: 9881820
    },
    {
      nanogenreId: 4260,
      x: 5562000,
      y: 9888000
    },
    {
      nanogenreId: 4261,
      x: -2592510,
      y: 5803020
    },
    {
      nanogenreId: 4262,
      x: 383160,
      y: 6742380
    },
    {
      nanogenreId: 4263,
      x: -10700670,
      y: -3809970
    },
    {
      nanogenreId: 4264,
      x: 8330640,
      y: 16998090
    },
    {
      nanogenreId: 4265,
      x: -5892630,
      y: -24720
    },
    {
      nanogenreId: 4266,
      x: -5370420,
      y: -5920440
    },
    {
      nanogenreId: 4267,
      x: 435690,
      y: 3349560
    },
    {
      nanogenreId: 4268,
      x: 4134420,
      y: 10073400
    },
    {
      nanogenreId: 4269,
      x: -5172660,
      y: -763230
    },
    {
      nanogenreId: 4270,
      x: 4084980,
      y: 5178840
    },
    {
      nanogenreId: 4271,
      x: 4270380,
      y: -4755510
    },
    {
      nanogenreId: 4272,
      x: 6068760,
      y: -5796840
    },
    {
      nanogenreId: 4273,
      x: 1965240,
      y: 6396300
    },
    {
      nanogenreId: 4274,
      x: 3516420,
      y: 1127850
    },
    {
      nanogenreId: 4275,
      x: 546930,
      y: 5225190
    },
    {
      nanogenreId: 4276,
      x: -4727700,
      y: -4563930
    },
    {
      nanogenreId: 4277,
      x: -6872160,
      y: 1724220
    },
    {
      nanogenreId: 4278,
      x: -4804950,
      y: 6152190
    },
    {
      nanogenreId: 4279,
      x: 1103130,
      y: -1792200
    },
    {
      nanogenreId: 4280,
      x: 685980,
      y: 9433770
    },
    {
      nanogenreId: 4281,
      x: 2672850,
      y: 7020480
    },
    {
      nanogenreId: 4282,
      x: -6609510,
      y: 4344540
    },
    {
      nanogenreId: 4283,
      x: -3102360,
      y: 5163390
    },
    {
      nanogenreId: 4284,
      x: -1612980,
      y: 15137910
    },
    {
      nanogenreId: 4285,
      x: 3871770,
      y: 10549260
    },
    {
      nanogenreId: 4286,
      x: -2617230,
      y: -7153350
    },
    {
      nanogenreId: 4287,
      x: -6742380,
      y: 871380
    },
    {
      nanogenreId: 4288,
      x: 812670,
      y: -1368870
    },
    {
      nanogenreId: 4289,
      x: -4761690,
      y: 3383550
    },
    {
      nanogenreId: 4290,
      x: 4879110,
      y: 4097340
    },
    {
      nanogenreId: 4291,
      x: -6186180,
      y: -4322910
    },
    {
      nanogenreId: 4292,
      x: 3504060,
      y: 3584400
    },
    {
      nanogenreId: 4293,
      x: -5296260,
      y: 3785250
    },
    {
      nanogenreId: 4294,
      x: 4248750,
      y: 528390
    },
    {
      nanogenreId: 4295,
      x: -2339130,
      y: 448050
    },
    {
      nanogenreId: 4296,
      x: 12360,
      y: 3930480
    },
    {
      nanogenreId: 4297,
      x: -1980690,
      y: 8729250
    },
    {
      nanogenreId: 4298,
      x: 12872940,
      y: 14047140
    },
    {
      nanogenreId: 4299,
      x: 2629590,
      y: 6714570
    },
    {
      nanogenreId: 4300,
      x: -2280420,
      y: -5651610
    },
    {
      nanogenreId: 4301,
      x: 5481660,
      y: 9764400
    },
    {
      nanogenreId: 4302,
      x: -6461190,
      y: 704520
    },
    {
      nanogenreId: 4303,
      x: -3161070,
      y: -485130
    },
    {
      nanogenreId: 4304,
      x: 3470070,
      y: 9078420
    },
    {
      nanogenreId: 4305,
      x: 8828130,
      y: 8673630
    },
    {
      nanogenreId: 4306,
      x: -7758990,
      y: -4576290
    },
    {
      nanogenreId: 4307,
      x: -7029750,
      y: -3723450
    },
    {
      nanogenreId: 4308,
      x: -4199310,
      y: 9171120
    },
    {
      nanogenreId: 4309,
      x: 4171500,
      y: -7786800
    },
    {
      nanogenreId: 4310,
      x: -11482440,
      y: 4814220
    },
    {
      nanogenreId: 4311,
      x: 2036310,
      y: 4455780
    },
    {
      nanogenreId: 4312,
      x: 4650450,
      y: -5045970
    },
    {
      nanogenreId: 4313,
      x: 1245270,
      y: -7901130
    },
    {
      nanogenreId: 4314,
      x: -6696030,
      y: -4662810
    },
    {
      nanogenreId: 4315,
      x: -5895720,
      y: -10033230
    },
    {
      nanogenreId: 4316,
      x: 6275790,
      y: -1208190
    },
    {
      nanogenreId: 4317,
      x: -2459640,
      y: -9294720
    },
    {
      nanogenreId: 4318,
      x: 8163780,
      y: 1464660
    },
    {
      nanogenreId: 4319,
      x: -5710320,
      y: -13348800
    },
    {
      nanogenreId: 4320,
      x: 4384710,
      y: 8614920
    },
    {
      nanogenreId: 4321,
      x: -3346470,
      y: 5521830
    },
    {
      nanogenreId: 4322,
      x: 3161070,
      y: 3961380
    },
    {
      nanogenreId: 4323,
      x: 3259950,
      y: -207030
    },
    {
      nanogenreId: 4324,
      x: 3371190,
      y: 4418700
    },
    {
      nanogenreId: 4325,
      x: 12220950,
      y: -3436080
    },
    {
      nanogenreId: 4326,
      x: 7901130,
      y: -410970
    },
    {
      nanogenreId: 4327,
      x: 4885290,
      y: 9782940
    },
    {
      nanogenreId: 4328,
      x: 3500970,
      y: -4613370
    },
    {
      nanogenreId: 4329,
      x: -4947090,
      y: -1560450
    },
    {
      nanogenreId: 4330,
      x: -7944390,
      y: 2703750
    },
    {
      nanogenreId: 4331,
      x: 2218620,
      y: -4925460
    },
    {
      nanogenreId: 4332,
      x: -15128640,
      y: 994980
    },
    {
      nanogenreId: 4333,
      x: -13633080,
      y: 3658560
    },
    {
      nanogenreId: 4334,
      x: -11198160,
      y: -1096950
    },
    {
      nanogenreId: 4335,
      x: -6081120,
      y: 4974900
    },
    {
      nanogenreId: 4336,
      x: 5438400,
      y: -3711090
    },
    {
      nanogenreId: 4337,
      x: -1100040,
      y: -886830
    },
    {
      nanogenreId: 4338,
      x: -6319050,
      y: 4579380
    },
    {
      nanogenreId: 4339,
      x: -2611050,
      y: 2657400
    },
    {
      nanogenreId: 4340,
      x: 2326770,
      y: 10085760
    },
    {
      nanogenreId: 4341,
      x: -2271150,
      y: -460410
    },
    {
      nanogenreId: 4342,
      x: -11513340,
      y: -673620
    },
    {
      nanogenreId: 4343,
      x: -3976830,
      y: 769410
    },
    {
      nanogenreId: 4344,
      x: 5212830,
      y: -1341060
    },
    {
      nanogenreId: 4345,
      x: 6875250,
      y: -7483980
    },
    {
      nanogenreId: 4346,
      x: 2870610,
      y: 6433380
    },
    {
      nanogenreId: 4347,
      x: 1971420,
      y: 8849760
    },
    {
      nanogenreId: 4348,
      x: -1140210,
      y: -1625340
    },
    {
      nanogenreId: 4349,
      x: 4647360,
      y: 9047520
    },
    {
      nanogenreId: 4350,
      x: -15153360,
      y: 11399010
    },
    {
      nanogenreId: 4351,
      x: 8639640,
      y: -5722680
    },
    {
      nanogenreId: 4352,
      x: 6989580,
      y: -5055240
    },
    {
      nanogenreId: 4353,
      x: 5228280,
      y: 5293170
    },
    {
      nanogenreId: 4354,
      x: 1019700,
      y: 7706460
    },
    {
      nanogenreId: 4355,
      x: 4684440,
      y: 908460
    },
    {
      nanogenreId: 4356,
      x: 6482820,
      y: -2014680
    },
    {
      nanogenreId: 4357,
      x: -2475090,
      y: -2472000
    },
    {
      nanogenreId: 4358,
      x: -4808040,
      y: -8543850
    },
    {
      nanogenreId: 4359,
      x: -5750490,
      y: -482040
    },
    {
      nanogenreId: 4360,
      x: 766320,
      y: 5283900
    },
    {
      nanogenreId: 4361,
      x: 2533800,
      y: 5101590
    },
    {
      nanogenreId: 4362,
      x: 1470840,
      y: -7517970
    },
    {
      nanogenreId: 4363,
      x: 139050,
      y: 5694870
    },
    {
      nanogenreId: 4364,
      x: -2172270,
      y: 1928160
    },
    {
      nanogenreId: 4365,
      x: 2190810,
      y: 12044820
    },
    {
      nanogenreId: 4366,
      x: -3865590,
      y: -8024730
    },
    {
      nanogenreId: 4367,
      x: -1140210,
      y: 1198920
    },
    {
      nanogenreId: 4368,
      x: 7830060,
      y: -6899970
    },
    {
      nanogenreId: 4369,
      x: -874470,
      y: 5935890
    },
    {
      nanogenreId: 4370,
      x: 3031290,
      y: -5642340
    },
    {
      nanogenreId: 4371,
      x: 83430,
      y: 2629590
    },
    {
      nanogenreId: 4372,
      x: 6155280,
      y: -4542300
    },
    {
      nanogenreId: 4373,
      x: -1038240,
      y: 4103520
    },
    {
      nanogenreId: 4374,
      x: 883740,
      y: 7199700
    },
    {
      nanogenreId: 4375,
      x: -8945550,
      y: 14380860
    },
    {
      nanogenreId: 4376,
      x: -2255700,
      y: -10317510
    },
    {
      nanogenreId: 4377,
      x: 2400930,
      y: -4208580
    },
    {
      nanogenreId: 4378,
      x: 4857480,
      y: 5948250
    },
    {
      nanogenreId: 4379,
      x: -2740830,
      y: -11266140
    },
    {
      nanogenreId: 4380,
      x: -14254170,
      y: -7558140
    },
    {
      nanogenreId: 4381,
      x: 7205880,
      y: -9925080
    },
    {
      nanogenreId: 4382,
      x: 10187730,
      y: 18546180
    },
    {
      nanogenreId: 4383,
      x: -12360,
      y: 9501750
    },
    {
      nanogenreId: 4384,
      x: -1625340,
      y: -3763620
    },
    {
      nanogenreId: 4385,
      x: -9220560,
      y: -951720
    },
    {
      nanogenreId: 4386,
      x: 15860970,
      y: -7014300
    },
    {
      nanogenreId: 4387,
      x: 10184640,
      y: -8466600
    },
    {
      nanogenreId: 4388,
      x: 6068760,
      y: 4406340
    },
    {
      nanogenreId: 4389,
      x: -3504060,
      y: -12601020
    },
    {
      nanogenreId: 4390,
      x: -6816540,
      y: 13874100
    },
    {
      nanogenreId: 4391,
      x: 13914270,
      y: -7360380
    },
    {
      nanogenreId: 4392,
      x: 4276560,
      y: 11120910
    },
    {
      nanogenreId: 4393,
      x: -3074550,
      y: -5830830
    },
    {
      nanogenreId: 4394,
      x: -9195840,
      y: 6804180
    },
    {
      nanogenreId: 4395,
      x: 10153740,
      y: -5676330
    },
    {
      nanogenreId: 4396,
      x: 5407500,
      y: 5602170
    },
    {
      nanogenreId: 4397,
      x: 9705690,
      y: -8865210
    },
    {
      nanogenreId: 4398,
      x: -4301280,
      y: -8009280
    },
    {
      nanogenreId: 4399,
      x: 4582470,
      y: 18759390
    },
    {
      nanogenreId: 4400,
      x: 11179620,
      y: -6875250
    },
    {
      nanogenreId: 4401,
      x: -13042890,
      y: -4529940
    },
    {
      nanogenreId: 4402,
      x: 14056410,
      y: 6031680
    },
    {
      nanogenreId: 4403,
      x: 5212830,
      y: 7885680
    },
    {
      nanogenreId: 4404,
      x: -16064910,
      y: -4375440
    },
    {
      nanogenreId: 4405,
      x: 8120520,
      y: -5364240
    },
    {
      nanogenreId: 4406,
      x: 7119360,
      y: 1999230
    },
    {
      nanogenreId: 4407,
      x: 13089240,
      y: 1038240
    },
    {
      nanogenreId: 4408,
      x: -3491700,
      y: -13172670
    },
    {
      nanogenreId: 4409,
      x: -10506000,
      y: 12903840
    },
    {
      nanogenreId: 4410,
      x: -5376600,
      y: -11708010
    },
    {
      nanogenreId: 4411,
      x: 8386260,
      y: -6937050
    },
    {
      nanogenreId: 4412,
      x: 2536890,
      y: 11365020
    },
    {
      nanogenreId: 4413,
      x: -2135190,
      y: -9189660
    },
    {
      nanogenreId: 4414,
      x: 10323690,
      y: -6442650
    },
    {
      nanogenreId: 4415,
      x: -2706840,
      y: -7363470
    },
    {
      nanogenreId: 4416,
      x: -237930,
      y: 3723450
    },
    {
      nanogenreId: 4417,
      x: -3219780,
      y: 10091940
    },
    {
      nanogenreId: 4418,
      x: 3915030,
      y: 10648140
    },
    {
      nanogenreId: 4419,
      x: 5837010,
      y: 6485910
    },
    {
      nanogenreId: 4420,
      x: 9149490,
      y: -6896880
    },
    {
      nanogenreId: 4421,
      x: -1548090,
      y: -10215540
    },
    {
      nanogenreId: 4422,
      x: 9359610,
      y: -8290470
    },
    {
      nanogenreId: 4423,
      x: -2061030,
      y: -5478570
    },
    {
      nanogenreId: 4424,
      x: -1993050,
      y: -6513720
    },
    {
      nanogenreId: 4425,
      x: 8145240,
      y: -6692940
    },
    {
      nanogenreId: 4426,
      x: -3868680,
      y: -10234080
    },
    {
      nanogenreId: 4427,
      x: 3522600,
      y: 9736590
    },
    {
      nanogenreId: 4428,
      x: -3414450,
      y: -11683290
    },
    {
      nanogenreId: 4429,
      x: 2206260,
      y: -5942070
    },
    {
      nanogenreId: 4430,
      x: 3877950,
      y: 10882980
    },
    {
      nanogenreId: 4431,
      x: -8778690,
      y: -5333340
    },
    {
      nanogenreId: 4432,
      x: 2311320,
      y: -6798000
    },
    {
      nanogenreId: 4433,
      x: 1134030,
      y: -4925460
    },
    {
      nanogenreId: 4434,
      x: -11803800,
      y: -5237550
    },
    {
      nanogenreId: 4435,
      x: 3065280,
      y: 9294720
    },
    {
      nanogenreId: 4436,
      x: 3754350,
      y: 6334500
    },
    {
      nanogenreId: 4437,
      x: 1319430,
      y: 77250
    },
    {
      nanogenreId: 4438,
      x: 7808430,
      y: -7128630
    },
    {
      nanogenreId: 4439,
      x: -1591350,
      y: -2926230
    },
    {
      nanogenreId: 4440,
      x: 14597160,
      y: -1807650
    },
    {
      nanogenreId: 4441,
      x: 3466980,
      y: 2994210
    },
    {
      nanogenreId: 4442,
      x: 11915040,
      y: 2907690
    },
    {
      nanogenreId: 4443,
      x: 2150640,
      y: 9041340
    },
    {
      nanogenreId: 4444,
      x: 3374280,
      y: -10509090
    },
    {
      nanogenreId: 4445,
      x: 9467760,
      y: -6899970
    },
    {
      nanogenreId: 4446,
      x: 7638480,
      y: -2478180
    },
    {
      nanogenreId: 4447,
      x: 5203560,
      y: 9449220
    },
    {
      nanogenreId: 4448,
      x: -4359990,
      y: -12653550
    },
    {
      nanogenreId: 4449,
      x: 4502130,
      y: 10910790
    },
    {
      nanogenreId: 4450,
      x: -985710,
      y: 9646980
    },
    {
      nanogenreId: 4451,
      x: 8726160,
      y: -7891860
    },
    {
      nanogenreId: 4452,
      x: 9541920,
      y: -7539600
    },
    {
      nanogenreId: 4453,
      x: -7641570,
      y: -11408280
    },
    {
      nanogenreId: 4454,
      x: -3266130,
      y: -13775220
    },
    {
      nanogenreId: 4455,
      x: -1900350,
      y: -10370040
    },
    {
      nanogenreId: 4456,
      x: 1368870,
      y: 7110090
    },
    {
      nanogenreId: 4457,
      x: -3198150,
      y: -12434160
    },
    {
      nanogenreId: 4458,
      x: 11618400,
      y: -3945930
    },
    {
      nanogenreId: 4459,
      x: 5704140,
      y: -5874090
    },
    {
      nanogenreId: 4460,
      x: -3775980,
      y: -9319440
    },
    {
      nanogenreId: 4461,
      x: 3599850,
      y: 18326790
    },
    {
      nanogenreId: 4462,
      x: -3077640,
      y: -9402870
    },
    {
      nanogenreId: 4463,
      x: -52530,
      y: -2181540
    },
    {
      nanogenreId: 4464,
      x: 1282350,
      y: -7140990
    },
    {
      nanogenreId: 4465,
      x: -1477020,
      y: -10546170
    },
    {
      nanogenreId: 4466,
      x: 4446510,
      y: 6003870
    },
    {
      nanogenreId: 4467,
      x: 15428370,
      y: 2061030
    },
    {
      nanogenreId: 4468,
      x: 7474710,
      y: -7382010
    },
    {
      nanogenreId: 4469,
      x: 1307070,
      y: -5972970
    },
    {
      nanogenreId: 4470,
      x: 3235230,
      y: 7826970
    },
    {
      nanogenreId: 4471,
      x: 6547710,
      y: -11457720
    },
    {
      nanogenreId: 4472,
      x: -7230600,
      y: -5259180
    },
    {
      nanogenreId: 4473,
      x: -3127080,
      y: -8781780
    },
    {
      nanogenreId: 4474,
      x: -3525690,
      y: 8741610
    },
    {
      nanogenreId: 4475,
      x: 250290,
      y: -5565090
    },
    {
      nanogenreId: 4476,
      x: 7981470,
      y: -6946320
    },
    {
      nanogenreId: 4477,
      x: -6838170,
      y: 5395140
    },
    {
      nanogenreId: 4478,
      x: 8169960,
      y: -2731560
    },
    {
      nanogenreId: 4479,
      x: -3130170,
      y: 1980690
    },
    {
      nanogenreId: 4480,
      x: 14108940,
      y: 4477410
    },
    {
      nanogenreId: 4481,
      x: -2444190,
      y: 936270
    },
    {
      nanogenreId: 4482,
      x: 8503680,
      y: -5840100
    },
    {
      nanogenreId: 4483,
      x: 5552730,
      y: 407880
    },
    {
      nanogenreId: 4484,
      x: -3071460,
      y: 15811530
    },
    {
      nanogenreId: 4485,
      x: -380070,
      y: 12131340
    },
    {
      nanogenreId: 4486,
      x: 2014680,
      y: -7153350
    },
    {
      nanogenreId: 4487,
      x: -3182700,
      y: 4740060
    },
    {
      nanogenreId: 4488,
      x: 5750490,
      y: 10178460
    },
    {
      nanogenreId: 4489,
      x: -5426040,
      y: 2481270
    },
    {
      nanogenreId: 4490,
      x: 4047900,
      y: -435690
    },
    {
      nanogenreId: 4491,
      x: 6081120,
      y: 9646980
    },
    {
      nanogenreId: 4492,
      x: 4378530,
      y: 3278490
    },
    {
      nanogenreId: 4493,
      x: 6566250,
      y: 7280040
    },
    {
      nanogenreId: 4494,
      x: 10521450,
      y: 5818470
    },
    {
      nanogenreId: 4495,
      x: 299730,
      y: 7468530
    },
    {
      nanogenreId: 4496,
      x: 3655470,
      y: 8457330
    },
    {
      nanogenreId: 4497,
      x: 15570510,
      y: 5960610
    },
    {
      nanogenreId: 4498,
      x: 11964480,
      y: 5419860
    },
    {
      nanogenreId: 4499,
      x: 312090,
      y: 6850530
    },
    {
      nanogenreId: 4500,
      x: 7406730,
      y: 10264980
    },
    {
      nanogenreId: 4501,
      x: -3312480,
      y: -10326780
    },
    {
      nanogenreId: 4502,
      x: -275010,
      y: -10438020
    },
    {
      nanogenreId: 4503,
      x: -373890,
      y: -5191200
    },
    {
      nanogenreId: 4504,
      x: -370800,
      y: 9955980
    },
    {
      nanogenreId: 4505,
      x: 4987260,
      y: 6365400
    },
    {
      nanogenreId: 4506,
      x: 4987260,
      y: 5997690
    },
    {
      nanogenreId: 4507,
      x: -930090,
      y: -11083830
    },
    {
      nanogenreId: 4508,
      x: 5163390,
      y: -7199700
    },
    {
      nanogenreId: 4509,
      x: 6319050,
      y: 3840870
    },
    {
      nanogenreId: 4510,
      x: 24720,
      y: -7382010
    },
    {
      nanogenreId: 4511,
      x: 5676330,
      y: 10558530
    },
    {
      nanogenreId: 4512,
      x: 6207810,
      y: 9860190
    },
    {
      nanogenreId: 4513,
      x: -685980,
      y: 10336050
    },
    {
      nanogenreId: 4514,
      x: -3763620,
      y: -2824260
    },
    {
      nanogenreId: 4515,
      x: -6383940,
      y: -9285450
    },
    {
      nanogenreId: 4516,
      x: 478950,
      y: -309000
    },
    {
      nanogenreId: 4517,
      x: -1609890,
      y: 4434150
    },
    {
      nanogenreId: 4518,
      x: 5531100,
      y: -2784090
    },
    {
      nanogenreId: 4519,
      x: -244110,
      y: -7564320
    },
    {
      nanogenreId: 4520,
      x: 4427970,
      y: 9402870
    },
    {
      nanogenreId: 4521,
      x: 9538830,
      y: 5271540
    },
    {
      nanogenreId: 4522,
      x: 6300510,
      y: -8194680
    },
    {
      nanogenreId: 4523,
      x: 11754360,
      y: 7453080
    },
    {
      nanogenreId: 4524,
      x: 1396680,
      y: 9826200
    },
    {
      nanogenreId: 4525,
      x: -1541910,
      y: 10468920
    },
    {
      nanogenreId: 4526,
      x: 2051760,
      y: 9303990
    },
    {
      nanogenreId: 4527,
      x: -3732720,
      y: -5450760
    },
    {
      nanogenreId: 4528,
      x: -7032840,
      y: 7335660
    },
    {
      nanogenreId: 4529,
      x: 4780230,
      y: 1770570
    },
    {
      nanogenreId: 4530,
      x: 6702210,
      y: -2030130
    },
    {
      nanogenreId: 4531,
      x: 10830450,
      y: 4298190
    },
    {
      nanogenreId: 4532,
      x: -3195060,
      y: 10920060
    },
    {
      nanogenreId: 4533,
      x: -86520,
      y: -2481270
    },
    {
      nanogenreId: 4534,
      x: 4653540,
      y: 9730410
    },
    {
      nanogenreId: 4535,
      x: 3788340,
      y: 18135210
    },
    {
      nanogenreId: 4536,
      x: -7817700,
      y: 9035160
    },
    {
      nanogenreId: 4537,
      x: -3340290,
      y: 1424490
    },
    {
      nanogenreId: 4538,
      x: -4690620,
      y: -6325230
    },
    {
      nanogenreId: 4539,
      x: 920820,
      y: 5633070
    },
    {
      nanogenreId: 4540,
      x: 9418320,
      y: -8871390
    },
    {
      nanogenreId: 4541,
      x: -7397460,
      y: 1183470
    },
    {
      nanogenreId: 4542,
      x: -1344150,
      y: 2156820
    },
    {
      nanogenreId: 4543,
      x: 4069530,
      y: 2765550
    },
    {
      nanogenreId: 4544,
      x: -11405190,
      y: -5837010
    },
    {
      nanogenreId: 4545,
      x: 4604100,
      y: -618000
    },
    {
      nanogenreId: 4546,
      x: 6254160,
      y: 10583250
    },
    {
      nanogenreId: 4547,
      x: 17013540,
      y: -7830060
    },
    {
      nanogenreId: 4548,
      x: -2407110,
      y: -9121680
    },
    {
      nanogenreId: 4549,
      x: 7959840,
      y: -8852850
    },
    {
      nanogenreId: 4550,
      x: -3392820,
      y: 6074940
    },
    {
      nanogenreId: 4551,
      x: 21630,
      y: -6383940
    },
    {
      nanogenreId: 4552,
      x: 8466600,
      y: -9016620
    },
    {
      nanogenreId: 4553,
      x: 1415220,
      y: 6519900
    },
    {
      nanogenreId: 4554,
      x: -679800,
      y: 2941680
    },
    {
      nanogenreId: 4555,
      x: 7428360,
      y: 1378140
    },
    {
      nanogenreId: 4556,
      x: -2203170,
      y: -9622260
    },
    {
      nanogenreId: 4557,
      x: 7499430,
      y: -7913490
    },
    {
      nanogenreId: 4558,
      x: -89610,
      y: 7255320
    },
    {
      nanogenreId: 4559,
      x: -1032060,
      y: 5626890
    },
    {
      nanogenreId: 4560,
      x: 8852850,
      y: -7187340
    },
    {
      nanogenreId: 4561,
      x: 3355740,
      y: -5379690
    },
    {
      nanogenreId: 4562,
      x: -3034380,
      y: -5305530
    },
    {
      nanogenreId: 4563,
      x: -12665910,
      y: -3541140
    },
    {
      nanogenreId: 4564,
      x: 3741990,
      y: -2249520
    },
    {
      nanogenreId: 4565,
      x: -4224030,
      y: -9180390
    },
    {
      nanogenreId: 4566,
      x: 4641180,
      y: -855930
    },
    {
      nanogenreId: 4567,
      x: 2438010,
      y: -10447290
    },
    {
      nanogenreId: 4568,
      x: 5923530,
      y: -3318660
    },
    {
      nanogenreId: 4569,
      x: -3331020,
      y: -1319430
    },
    {
      nanogenreId: 4570,
      x: 1705680,
      y: 4635000
    },
    {
      nanogenreId: 4571,
      x: 16358460,
      y: -3139440
    },
    {
      nanogenreId: 4572,
      x: -352260,
      y: 2274240
    },
    {
      nanogenreId: 4573,
      x: -1659330,
      y: -3111630
    },
    {
      nanogenreId: 4574,
      x: -13833930,
      y: -6924690
    },
    {
      nanogenreId: 4575,
      x: 5191200,
      y: 7184250
    },
    {
      nanogenreId: 4576,
      x: -1724220,
      y: 2672850
    },
    {
      nanogenreId: 4577,
      x: 3361920,
      y: 7091550
    },
    {
      nanogenreId: 4578,
      x: 5843190,
      y: 8716890
    },
    {
      nanogenreId: 4579,
      x: 5960610,
      y: 5129400
    },
    {
      nanogenreId: 4580,
      x: -3046740,
      y: -10163010
    },
    {
      nanogenreId: 4581,
      x: -6006960,
      y: -1884900
    },
    {
      nanogenreId: 4582,
      x: 7576680,
      y: 9711870
    },
    {
      nanogenreId: 4583,
      x: 8775600,
      y: 3290850
    },
    {
      nanogenreId: 4584,
      x: 8163780,
      y: -8225580
    },
    {
      nanogenreId: 4585,
      x: -14977230,
      y: -2663580
    },
    {
      nanogenreId: 4586,
      x: 2586330,
      y: 8466600
    },
    {
      nanogenreId: 4587,
      x: 5382780,
      y: 12823500
    },
    {
      nanogenreId: 4588,
      x: 15678660,
      y: -3062190
    },
    {
      nanogenreId: 4589,
      x: 11590590,
      y: -1591350
    },
    {
      nanogenreId: 4590,
      x: 2938590,
      y: -6016230
    },
    {
      nanogenreId: 4591,
      x: 15916590,
      y: -163770
    },
    {
      nanogenreId: 4592,
      x: 1025880,
      y: -8296650
    },
    {
      nanogenreId: 4593,
      x: -1477020,
      y: -8905380
    },
    {
      nanogenreId: 4594,
      x: 7941300,
      y: -3361920
    },
    {
      nanogenreId: 4595,
      x: 367710,
      y: 12310560
    },
    {
      nanogenreId: 4596,
      x: 1535730,
      y: 7567410
    },
    {
      nanogenreId: 4597,
      x: 3979920,
      y: 7604490
    },
    {
      nanogenreId: 4598,
      x: -4023180,
      y: 1662420
    },
    {
      nanogenreId: 4599,
      x: 6127470,
      y: 9143310
    },
    {
      nanogenreId: 4600,
      x: -5747400,
      y: 6921600
    },
    {
      nanogenreId: 4601,
      x: 1232910,
      y: -732330
    },
    {
      nanogenreId: 4602,
      x: -1634610,
      y: 1980690
    },
    {
      nanogenreId: 4603,
      x: -1282350,
      y: -2969490
    },
    {
      nanogenreId: 4604,
      x: -602550,
      y: 8271930
    },
    {
      nanogenreId: 4605,
      x: -6309780,
      y: 1832370
    },
    {
      nanogenreId: 4606,
      x: 4638090,
      y: -5209740
    },
    {
      nanogenreId: 4607,
      x: 9035160,
      y: -8355360
    },
    {
      nanogenreId: 4608,
      x: -1226730,
      y: -3782160
    },
    {
      nanogenreId: 4609,
      x: -4613370,
      y: -13123230
    },
    {
      nanogenreId: 4610,
      x: -3306300,
      y: -8534580
    },
    {
      nanogenreId: 4611,
      x: -6340680,
      y: -2091930
    },
    {
      nanogenreId: 4612,
      x: -4322910,
      y: 4326000
    },
    {
      nanogenreId: 4613,
      x: -346080,
      y: -5531100
    },
    {
      nanogenreId: 4614,
      x: 5110860,
      y: 7990740
    },
    {
      nanogenreId: 4615,
      x: 4400160,
      y: 16346100
    },
    {
      nanogenreId: 4616,
      x: 4560840,
      y: 5104680
    },
    {
      nanogenreId: 4617,
      x: -2929320,
      y: 5135580
    },
    {
      nanogenreId: 4618,
      x: 6044040,
      y: -4214760
    },
    {
      nanogenreId: 4619,
      x: 6553890,
      y: 10607970
    },
    {
      nanogenreId: 4620,
      x: -2898420,
      y: -2237160
    },
    {
      nanogenreId: 4621,
      x: 2073390,
      y: 9665520
    },
    {
      nanogenreId: 4622,
      x: -2876790,
      y: -8862120
    },
    {
      nanogenreId: 4623,
      x: -4026270,
      y: 219390
    },
    {
      nanogenreId: 4624,
      x: 309000,
      y: -3466980
    },
    {
      nanogenreId: 4625,
      x: 960990,
      y: 7959840
    },
    {
      nanogenreId: 4626,
      x: 404790,
      y: 10064130
    },
    {
      nanogenreId: 4627,
      x: 3182700,
      y: 7233690
    },
    {
      nanogenreId: 4628,
      x: 3911940,
      y: 9331800
    },
    {
      nanogenreId: 4629,
      x: -2453460,
      y: 7326390
    },
    {
      nanogenreId: 4630,
      x: -1130940,
      y: 231750
    },
    {
      nanogenreId: 4631,
      x: 5185020,
      y: 2119740
    },
    {
      nanogenreId: 4632,
      x: 5144850,
      y: 9699510
    },
    {
      nanogenreId: 4633,
      x: -4177680,
      y: 3182700
    },
    {
      nanogenreId: 4634,
      x: 3905760,
      y: 3099270
    },
    {
      nanogenreId: 4635,
      x: 5005800,
      y: -9841650
    },
    {
      nanogenreId: 4636,
      x: -4560840,
      y: 2737740
    },
    {
      nanogenreId: 4637,
      x: 8593290,
      y: -6025500
    },
    {
      nanogenreId: 4638,
      x: -6853620,
      y: -994980
    },
    {
      nanogenreId: 4639,
      x: 713790,
      y: -6235620
    },
    {
      nanogenreId: 4640,
      x: -1050600,
      y: 10688310
    },
    {
      nanogenreId: 4641,
      x: 5864820,
      y: 9566640
    },
    {
      nanogenreId: 4642,
      x: 5787570,
      y: 9582090
    },
    {
      nanogenreId: 4643,
      x: -1158750,
      y: -1115490
    },
    {
      nanogenreId: 4644,
      x: -2895330,
      y: 7734270
    },
    {
      nanogenreId: 4645,
      x: 5197380,
      y: 6065670
    },
    {
      nanogenreId: 4646,
      x: -14726940,
      y: -3219780
    },
    {
      nanogenreId: 4647,
      x: 6541530,
      y: 8540760
    },
    {
      nanogenreId: 4648,
      x: 10530720,
      y: -454230
    },
    {
      nanogenreId: 4649,
      x: -3334110,
      y: 11680200
    },
    {
      nanogenreId: 4650,
      x: 5518740,
      y: 8423340
    },
    {
      nanogenreId: 4651,
      x: -599460,
      y: -6847440
    },
    {
      nanogenreId: 4652,
      x: -787950,
      y: -9495570
    },
    {
      nanogenreId: 4653,
      x: -49440,
      y: -7663200
    },
    {
      nanogenreId: 4654,
      x: 13849380,
      y: -1016610
    },
    {
      nanogenreId: 4655,
      x: 1062960,
      y: -3825420
    },
    {
      nanogenreId: 4656,
      x: 6346860,
      y: 10178460
    },
    {
      nanogenreId: 4657,
      x: -3828510,
      y: 7573590
    },
    {
      nanogenreId: 4658,
      x: -6513720,
      y: 3559680
    },
    {
      nanogenreId: 4659,
      x: 16460430,
      y: -2682120
    },
    {
      nanogenreId: 4660,
      x: -1285440,
      y: -6241800
    },
    {
      nanogenreId: 4661,
      x: 9483210,
      y: -4276560
    },
    {
      nanogenreId: 4662,
      x: -3868680,
      y: 3856320
    },
    {
      nanogenreId: 4663,
      x: -1952880,
      y: 7394370
    },
    {
      nanogenreId: 4664,
      x: -12557760,
      y: -2750100
    },
    {
      nanogenreId: 4665,
      x: 5422950,
      y: 1962150
    },
    {
      nanogenreId: 4666,
      x: -1384320,
      y: -5979150
    },
    {
      nanogenreId: 4667,
      x: 9072240,
      y: -7014300
    },
    {
      nanogenreId: 4668,
      x: 1993050,
      y: -3979920
    },
    {
      nanogenreId: 4669,
      x: 1625340,
      y: 9004260
    },
    {
      nanogenreId: 4670,
      x: -862110,
      y: -3238320
    },
    {
      nanogenreId: 4671,
      x: 2811900,
      y: 8812680
    },
    {
      nanogenreId: 4672,
      x: -13966800,
      y: -3263040
    },
    {
      nanogenreId: 4673,
      x: 679800,
      y: -7153350
    },
    {
      nanogenreId: 4674,
      x: -1507920,
      y: 6742380
    },
    {
      nanogenreId: 4675,
      x: 2020860,
      y: -200850
    },
    {
      nanogenreId: 4676,
      x: 12276570,
      y: 4236390
    },
    {
      nanogenreId: 4677,
      x: 7607580,
      y: -8784870
    },
    {
      nanogenreId: 4678,
      x: -302820,
      y: -2357670
    },
    {
      nanogenreId: 4679,
      x: 5438400,
      y: 9671700
    },
    {
      nanogenreId: 4680,
      x: -5722680,
      y: 3899580
    },
    {
      nanogenreId: 4681,
      x: 3534960,
      y: -11031300
    },
    {
      nanogenreId: 4682,
      x: 2388570,
      y: 3964470
    },
    {
      nanogenreId: 4683,
      x: -1319430,
      y: -4987260
    },
    {
      nanogenreId: 4684,
      x: -4356900,
      y: -3395910
    },
    {
      nanogenreId: 4685,
      x: 1909620,
      y: 8920830
    },
    {
      nanogenreId: 4686,
      x: 12035550,
      y: -281190
    },
    {
      nanogenreId: 4687,
      x: -1010430,
      y: -2178450
    },
    {
      nanogenreId: 4688,
      x: -7140990,
      y: 5969880
    },
    {
      nanogenreId: 4689,
      x: -6000780,
      y: -982620
    },
    {
      nanogenreId: 4690,
      x: 451140,
      y: -8757060
    },
    {
      nanogenreId: 4691,
      x: -3717270,
      y: 12567030
    },
    {
      nanogenreId: 4692,
      x: -1702590,
      y: -6560070
    },
    {
      nanogenreId: 4693,
      x: -5932800,
      y: 10391670
    },
    {
      nanogenreId: 4694,
      x: 5731950,
      y: -3439170
    },
    {
      nanogenreId: 4695,
      x: 16920840,
      y: -3732720
    },
    {
      nanogenreId: 4696,
      x: -3003480,
      y: -8825040
    },
    {
      nanogenreId: 4697,
      x: -4959450,
      y: 4363080
    },
    {
      nanogenreId: 4698,
      x: 9730410,
      y: -8324460
    },
    {
      nanogenreId: 4699,
      x: 5175750,
      y: 5110860
    },
    {
      nanogenreId: 4700,
      x: 5163390,
      y: 9616080
    },
    {
      nanogenreId: 4701,
      x: 2530710,
      y: 9273090
    },
    {
      nanogenreId: 4702,
      x: 4786410,
      y: 4613370
    },
    {
      nanogenreId: 4703,
      x: 5673240,
      y: -6275790
    },
    {
      nanogenreId: 4704,
      x: 2005410,
      y: 3924300
    },
    {
      nanogenreId: 4705,
      x: 321360,
      y: 7613760
    },
    {
      nanogenreId: 4706,
      x: -11742000,
      y: 222480
    },
    {
      nanogenreId: 4707,
      x: 2311320,
      y: 9396690
    },
    {
      nanogenreId: 4708,
      x: 5243730,
      y: 611820
    },
    {
      nanogenreId: 4709,
      x: -2298960,
      y: -10070310
    },
    {
      nanogenreId: 4710,
      x: 7190430,
      y: 5608350
    },
    {
      nanogenreId: 4711,
      x: 9232920,
      y: -5015070
    },
    {
      nanogenreId: 4712,
      x: -1900350,
      y: 791040
    },
    {
      nanogenreId: 4713,
      x: 7091550,
      y: -2027040
    },
    {
      nanogenreId: 4714,
      x: 8037090,
      y: -7595220
    },
    {
      nanogenreId: 4715,
      x: -3751260,
      y: 247200
    },
    {
      nanogenreId: 4716,
      x: 6782550,
      y: -8750880
    },
    {
      nanogenreId: 4717,
      x: -7205880,
      y: -4437240
    },
    {
      nanogenreId: 4718,
      x: 4638090,
      y: 4347630
    },
    {
      nanogenreId: 4719,
      x: -2407110,
      y: -13123230
    },
    {
      nanogenreId: 4720,
      x: -9192750,
      y: -5098500
    },
    {
      nanogenreId: 4721,
      x: 6492090,
      y: -6974130
    },
    {
      nanogenreId: 4722,
      x: 9232920,
      y: -5246820
    },
    {
      nanogenreId: 4723,
      x: -960990,
      y: -6482820
    },
    {
      nanogenreId: 4724,
      x: 4186950,
      y: 10178460
    },
    {
      nanogenreId: 4725,
      x: 4520670,
      y: 7607580
    },
    {
      nanogenreId: 4726,
      x: -4319820,
      y: 1378140
    },
    {
      nanogenreId: 4727,
      x: -4047900,
      y: 2675940
    },
    {
      nanogenreId: 4728,
      x: -1402860,
      y: -4103520
    },
    {
      nanogenreId: 4729,
      x: 4931640,
      y: 10017780
    },
    {
      nanogenreId: 4730,
      x: 6124380,
      y: -7882590
    },
    {
      nanogenreId: 4731,
      x: -4900740,
      y: 10907700
    },
    {
      nanogenreId: 4732,
      x: 3692550,
      y: 6924690
    },
    {
      nanogenreId: 4733,
      x: 2147550,
      y: 8095800
    },
    {
      nanogenreId: 4734,
      x: -9977610,
      y: -9396690
    },
    {
      nanogenreId: 4735,
      x: 1452300,
      y: -8695260
    },
    {
      nanogenreId: 4736,
      x: 3334110,
      y: -3049830
    },
    {
      nanogenreId: 4737,
      x: -10107390,
      y: -7984560
    },
    {
      nanogenreId: 4738,
      x: -5515650,
      y: 4177680
    },
    {
      nanogenreId: 4739,
      x: 16237950,
      y: -2107380
    },
    {
      nanogenreId: 4740,
      x: -1640790,
      y: 2901510
    },
    {
      nanogenreId: 4741,
      x: 6288150,
      y: 7366560
    },
    {
      nanogenreId: 4742,
      x: 12109710,
      y: -2459640
    },
    {
      nanogenreId: 4743,
      x: 3297030,
      y: 988800
    },
    {
      nanogenreId: 4744,
      x: 2317500,
      y: 5833920
    },
    {
      nanogenreId: 4745,
      x: -1103130,
      y: -1881810
    },
    {
      nanogenreId: 4746,
      x: 6662040,
      y: -4251840
    },
    {
      nanogenreId: 4747,
      x: -432600,
      y: 6436470
    },
    {
      nanogenreId: 4748,
      x: -10237170,
      y: -883740
    },
    {
      nanogenreId: 4749,
      x: 3318660,
      y: 7780620
    },
    {
      nanogenreId: 4750,
      x: 5444580,
      y: -7864050
    },
    {
      nanogenreId: 4751,
      x: 6501360,
      y: -6804180
    },
    {
      nanogenreId: 4752,
      x: 13305540,
      y: 173040
    },
    {
      nanogenreId: 4753,
      x: 7212060,
      y: -2737740
    },
    {
      nanogenreId: 4754,
      x: -9072240,
      y: -1752030
    },
    {
      nanogenreId: 4755,
      x: -1897260,
      y: 2663580
    },
    {
      nanogenreId: 4756,
      x: -1674780,
      y: 4551570
    },
    {
      nanogenreId: 4757,
      x: -6634230,
      y: -4381620
    },
    {
      nanogenreId: 4758,
      x: -330630,
      y: -726150
    },
    {
      nanogenreId: 4759,
      x: -9427590,
      y: -3674010
    },
    {
      nanogenreId: 4760,
      x: 6575520,
      y: 9041340
    },
    {
      nanogenreId: 4761,
      x: -3775980,
      y: 7743540
    },
    {
      nanogenreId: 4762,
      x: -973350,
      y: 8454240
    },
    {
      nanogenreId: 4763,
      x: 2150640,
      y: 13824660
    },
    {
      nanogenreId: 4764,
      x: -1665510,
      y: -4137510
    },
    {
      nanogenreId: 4765,
      x: -889920,
      y: 1149480
    },
    {
      nanogenreId: 4766,
      x: 247200,
      y: 7925850
    },
    {
      nanogenreId: 4767,
      x: 8562390,
      y: 1900350
    },
    {
      nanogenreId: 4768,
      x: 4502130,
      y: 8911560
    },
    {
      nanogenreId: 4769,
      x: 4242570,
      y: -4103520
    },
    {
      nanogenreId: 4770,
      x: 6652770,
      y: 3575130
    },
    {
      nanogenreId: 4771,
      x: -1659330,
      y: -2638860
    },
    {
      nanogenreId: 4772,
      x: 1748940,
      y: 4208580
    },
    {
      nanogenreId: 4773,
      x: -5274630,
      y: 2070300
    },
    {
      nanogenreId: 4774,
      x: 5852460,
      y: -7629210
    },
    {
      nanogenreId: 4775,
      x: 1711860,
      y: 4020090
    },
    {
      nanogenreId: 4776,
      x: 10342230,
      y: 10753200
    },
    {
      nanogenreId: 4777,
      x: 7338750,
      y: -7728090
    },
    {
      nanogenreId: 4778,
      x: -6331410,
      y: -8071080
    },
    {
      nanogenreId: 4779,
      x: -4668990,
      y: 3939750
    },
    {
      nanogenreId: 4780,
      x: 1433760,
      y: 1433760
    },
    {
      nanogenreId: 4781,
      x: 10166100,
      y: -6019320
    },
    {
      nanogenreId: 4782,
      x: 15110100,
      y: -1625340
    },
    {
      nanogenreId: 4783,
      x: -7307850,
      y: -2302050
    },
    {
      nanogenreId: 4784,
      x: -3157980,
      y: 3219780
    },
    {
      nanogenreId: 4785,
      x: -3698730,
      y: -2577060
    },
    {
      nanogenreId: 4786,
      x: 7301670,
      y: -568560
    },
    {
      nanogenreId: 4787,
      x: 5784480,
      y: 9300900
    },
    {
      nanogenreId: 4788,
      x: -9140220,
      y: -2187720
    },
    {
      nanogenreId: 4789,
      x: -8713800,
      y: 3090000
    },
    {
      nanogenreId: 4790,
      x: 9208200,
      y: -7196610
    },
    {
      nanogenreId: 4791,
      x: -6720750,
      y: 6603330
    },
    {
      nanogenreId: 4792,
      x: -13188120,
      y: -6040950
    },
    {
      nanogenreId: 4793,
      x: 5599080,
      y: 9446130
    },
    {
      nanogenreId: 4794,
      x: 10638870,
      y: -3813060
    },
    {
      nanogenreId: 4795,
      x: 4740060,
      y: 8074170
    },
    {
      nanogenreId: 4796,
      x: 7675560,
      y: -4653540
    },
    {
      nanogenreId: 4797,
      x: 2641950,
      y: -383160
    },
    {
      nanogenreId: 4798,
      x: -2614140,
      y: 12378540
    },
    {
      nanogenreId: 4799,
      x: 4721520,
      y: 7255320
    },
    {
      nanogenreId: 4800,
      x: 8784870,
      y: -9464670
    },
    {
      nanogenreId: 4801,
      x: 5583630,
      y: 8871390
    },
    {
      nanogenreId: 4802,
      x: 5419860,
      y: 5320980
    },
    {
      nanogenreId: 4803,
      x: 4393980,
      y: 1010430
    },
    {
      nanogenreId: 4804,
      x: 3167250,
      y: 1554270
    },
    {
      nanogenreId: 4805,
      x: 4542300,
      y: 9288540
    },
    {
      nanogenreId: 4806,
      x: -12962550,
      y: -5574360
    },
    {
      nanogenreId: 4807,
      x: 7005030,
      y: -7416000
    },
    {
      nanogenreId: 4808,
      x: 3998460,
      y: 9903450
    },
    {
      nanogenreId: 4809,
      x: 6646590,
      y: -6013140
    },
    {
      nanogenreId: 4810,
      x: 10129020,
      y: -2410200
    },
    {
      nanogenreId: 4811,
      x: 571650,
      y: 692160
    },
    {
      nanogenreId: 4812,
      x: 3782160,
      y: -5175750
    },
    {
      nanogenreId: 4813,
      x: 2777910,
      y: 8395530
    },
    {
      nanogenreId: 4814,
      x: -695250,
      y: -2833530
    },
    {
      nanogenreId: 4815,
      x: 7916580,
      y: -8432610
    },
    {
      nanogenreId: 4816,
      x: -2614140,
      y: -9591360
    },
    {
      nanogenreId: 4817,
      x: 1823100,
      y: 633450
    },
    {
      nanogenreId: 4818,
      x: 9337980,
      y: -4557750
    },
    {
      nanogenreId: 4819,
      x: 695250,
      y: 11973750
    },
    {
      nanogenreId: 4820,
      x: 2447280,
      y: 10845900
    },
    {
      nanogenreId: 4821,
      x: 9208200,
      y: -5546550
    },
    {
      nanogenreId: 4822,
      x: 5379690,
      y: 7721910
    },
    {
      nanogenreId: 4823,
      x: 2960220,
      y: 794130
    },
    {
      nanogenreId: 4824,
      x: -1866360,
      y: 4903830
    },
    {
      nanogenreId: 4825,
      x: -2298960,
      y: 1096950
    },
    {
      nanogenreId: 4826,
      x: -2178450,
      y: -207030
    },
    {
      nanogenreId: 4827,
      x: -3797610,
      y: 948630
    },
    {
      nanogenreId: 4828,
      x: 5809200,
      y: 10691400
    },
    {
      nanogenreId: 4829,
      x: 5972970,
      y: 9921990
    },
    {
      nanogenreId: 4830,
      x: 1424490,
      y: 8911560
    },
    {
      nanogenreId: 4831,
      x: -8105070,
      y: -2623410
    },
    {
      nanogenreId: 4832,
      x: -4057170,
      y: 2051760
    },
    {
      nanogenreId: 4833,
      x: 3915030,
      y: 4267290
    },
    {
      nanogenreId: 4834,
      x: 6606420,
      y: 2870610
    },
    {
      nanogenreId: 4835,
      x: 9056790,
      y: 18969510
    },
    {
      nanogenreId: 4836,
      x: -3386640,
      y: 7011210
    },
    {
      nanogenreId: 4837,
      x: -3253770,
      y: 12118980
    },
    {
      nanogenreId: 4838,
      x: 2397840,
      y: -9217470
    },
    {
      nanogenreId: 4839,
      x: 5330250,
      y: -3890310
    },
    {
      nanogenreId: 4840,
      x: -414060,
      y: 6553890
    },
    {
      nanogenreId: 4841,
      x: 5713410,
      y: 9816930
    },
    {
      nanogenreId: 4842,
      x: 4227120,
      y: -154500
    },
    {
      nanogenreId: 4843,
      x: -4406340,
      y: -13626900
    },
    {
      nanogenreId: 4844,
      x: -451140,
      y: 1551180
    },
    {
      nanogenreId: 4845,
      x: -2635770,
      y: -8843580
    },
    {
      nanogenreId: 4846,
      x: -259560,
      y: 735420
    },
    {
      nanogenreId: 4847,
      x: -2450370,
      y: 3945930
    },
    {
      nanogenreId: 4848,
      x: 1523370,
      y: -10107390
    },
    {
      nanogenreId: 4849,
      x: -5920440,
      y: -2363850
    },
    {
      nanogenreId: 4850,
      x: -556200,
      y: -6417930
    },
    {
      nanogenreId: 4851,
      x: -1183470,
      y: 13385880
    },
    {
      nanogenreId: 4852,
      x: 3077640,
      y: 7811520
    },
    {
      nanogenreId: 4853,
      x: -3924300,
      y: 2221710
    },
    {
      nanogenreId: 4854,
      x: 5117040,
      y: 9353430
    },
    {
      nanogenreId: 4855,
      x: -12628830,
      y: -5407500
    },
    {
      nanogenreId: 4856,
      x: -896100,
      y: -1977600
    },
    {
      nanogenreId: 4857,
      x: 86520,
      y: -11964480
    },
    {
      nanogenreId: 4858,
      x: 5475480,
      y: -6782550
    },
    {
      nanogenreId: 4859,
      x: -5342610,
      y: -9761310
    },
    {
      nanogenreId: 4860,
      x: 1894170,
      y: -2960220
    },
    {
      nanogenreId: 4861,
      x: -6047130,
      y: -5240640
    },
    {
      nanogenreId: 4862,
      x: 5691780,
      y: 9242190
    },
    {
      nanogenreId: 4863,
      x: 4613370,
      y: 10509090
    },
    {
      nanogenreId: 4864,
      x: 4047900,
      y: 4647360
    },
    {
      nanogenreId: 4865,
      x: -370800,
      y: -6921600
    },
    {
      nanogenreId: 4866,
      x: 123600,
      y: -2057940
    },
    {
      nanogenreId: 4867,
      x: 5991510,
      y: -837390
    },
    {
      nanogenreId: 4868,
      x: -1010430,
      y: -49440
    },
    {
      nanogenreId: 4869,
      x: 3581310,
      y: 10197000
    },
    {
      nanogenreId: 4870,
      x: -4319820,
      y: 3275400
    },
    {
      nanogenreId: 4871,
      x: 5620710,
      y: 6269610
    },
    {
      nanogenreId: 4872,
      x: 4038630,
      y: -4758600
    },
    {
      nanogenreId: 4873,
      x: -5385870,
      y: 7134810
    },
    {
      nanogenreId: 4874,
      x: 2404020,
      y: 8571660
    },
    {
      nanogenreId: 4875,
      x: -1168020,
      y: 7623030
    },
    {
      nanogenreId: 4876,
      x: 3213600,
      y: 10944780
    },
    {
      nanogenreId: 4877,
      x: -1582080,
      y: 2230980
    },
    {
      nanogenreId: 4878,
      x: 7153350,
      y: 9792210
    },
    {
      nanogenreId: 4879,
      x: -4322910,
      y: 1915800
    },
    {
      nanogenreId: 4880,
      x: -923910,
      y: 5364240
    },
    {
      nanogenreId: 4881,
      x: 571650,
      y: -5500200
    },
    {
      nanogenreId: 4882,
      x: 5608350,
      y: 4230210
    },
    {
      nanogenreId: 4883,
      x: 9257640,
      y: -8117430
    },
    {
      nanogenreId: 4884,
      x: 11071470,
      y: -2975670
    },
    {
      nanogenreId: 4885,
      x: 2716110,
      y: -12916200
    },
    {
      nanogenreId: 4886,
      x: 4712250,
      y: -12709170
    },
    {
      nanogenreId: 4887,
      x: -1310160,
      y: -2740830
    },
    {
      nanogenreId: 4888,
      x: -3899580,
      y: -4292010
    },
    {
      nanogenreId: 4889,
      x: 3837780,
      y: 8018550
    },
    {
      nanogenreId: 4890,
      x: -1764390,
      y: 10499820
    },
    {
      nanogenreId: 4891,
      x: 4743150,
      y: 1248360
    },
    {
      nanogenreId: 4892,
      x: -14720760,
      y: -5497110
    },
    {
      nanogenreId: 4893,
      x: 13824660,
      y: -4480500
    },
    {
      nanogenreId: 4894,
      x: 4307460,
      y: -7042110
    },
    {
      nanogenreId: 4895,
      x: 4412520,
      y: 7391280
    },
    {
      nanogenreId: 4896,
      x: 7762080,
      y: 6451920
    },
    {
      nanogenreId: 4897,
      x: -182310,
      y: 8611830
    },
    {
      nanogenreId: 4898,
      x: -10017780,
      y: 4885290
    },
    {
      nanogenreId: 4899,
      x: 4835850,
      y: 6303600
    },
    {
      nanogenreId: 4900,
      x: -466590,
      y: 9007350
    },
    {
      nanogenreId: 4901,
      x: 5620710,
      y: 8778690
    },
    {
      nanogenreId: 4902,
      x: -3247590,
      y: -5602170
    },
    {
      nanogenreId: 4903,
      x: -9745860,
      y: -4644270
    },
    {
      nanogenreId: 4904,
      x: 6714570,
      y: -6785640
    },
    {
      nanogenreId: 4905,
      x: 3460800,
      y: 7564320
    },
    {
      nanogenreId: 4906,
      x: -1399770,
      y: -8111250
    },
    {
      nanogenreId: 4907,
      x: -5225190,
      y: -2431830
    },
    {
      nanogenreId: 4908,
      x: 6040950,
      y: 7542690
    },
    {
      nanogenreId: 4909,
      x: 312090,
      y: 349170
    },
    {
      nanogenreId: 4910,
      x: 8188500,
      y: -7783710
    },
    {
      nanogenreId: 4911,
      x: -7499430,
      y: 8435700
    },
    {
      nanogenreId: 4912,
      x: 605640,
      y: -14155290
    },
    {
      nanogenreId: 4913,
      x: -5073780,
      y: -3578220
    },
    {
      nanogenreId: 4914,
      x: -2913870,
      y: 3837780
    },
    {
      nanogenreId: 4915,
      x: -1254540,
      y: 1606800
    },
    {
      nanogenreId: 4916,
      x: 1183470,
      y: -2577060
    },
    {
      nanogenreId: 4917,
      x: 664350,
      y: 6779460
    },
    {
      nanogenreId: 4918,
      x: -1857090,
      y: 8537670
    },
    {
      nanogenreId: 4919,
      x: 2617230,
      y: 2484360
    },
    {
      nanogenreId: 4920,
      x: 2054850,
      y: 7851690
    },
    {
      nanogenreId: 4921,
      x: -52530,
      y: -1965240
    },
    {
      nanogenreId: 4922,
      x: -8482050,
      y: -7910400
    },
    {
      nanogenreId: 4923,
      x: -3365010,
      y: 3083820
    },
    {
      nanogenreId: 4924,
      x: -7212060,
      y: 4465050
    },
    {
      nanogenreId: 4925,
      x: -2357670,
      y: -5354970
    },
    {
      nanogenreId: 4926,
      x: -512940,
      y: 10536900
    },
    {
      nanogenreId: 4927,
      x: 5166480,
      y: 10373130
    },
    {
      nanogenreId: 4928,
      x: 33990,
      y: 9300900
    },
    {
      nanogenreId: 4929,
      x: -5231370,
      y: -12950190
    },
    {
      nanogenreId: 4930,
      x: -12088080,
      y: 5426040
    },
    {
      nanogenreId: 4931,
      x: -4554660,
      y: 1115490
    },
    {
      nanogenreId: 4932,
      x: 8262660,
      y: -6096570
    },
    {
      nanogenreId: 4933,
      x: 3599850,
      y: -4128240
    },
    {
      nanogenreId: 4934,
      x: 3763620,
      y: 10886070
    },
    {
      nanogenreId: 4935,
      x: -4384710,
      y: 4050990
    },
    {
      nanogenreId: 4936,
      x: 6717660,
      y: -6186180
    },
    {
      nanogenreId: 4937,
      x: -12137520,
      y: 1588260
    },
    {
      nanogenreId: 4938,
      x: -2243340,
      y: 1473930
    },
    {
      nanogenreId: 4939,
      x: 2781000,
      y: 9100050
    },
    {
      nanogenreId: 4940,
      x: 4906920,
      y: -8516040
    },
    {
      nanogenreId: 4941,
      x: -10413300,
      y: -3482430
    },
    {
      nanogenreId: 4942,
      x: 6498270,
      y: 8994990
    },
    {
      nanogenreId: 4943,
      x: 6254160,
      y: 17937450
    },
    {
      nanogenreId: 4944,
      x: -8046360,
      y: -11077650
    },
    {
      nanogenreId: 4945,
      x: -3148710,
      y: 5404410
    },
    {
      nanogenreId: 4946,
      x: -10842810,
      y: -5509470
    },
    {
      nanogenreId: 4947,
      x: 8917740,
      y: -7088460
    },
    {
      nanogenreId: 4948,
      x: -948630,
      y: 942450
    },
    {
      nanogenreId: 4949,
      x: 10706850,
      y: -1949790
    },
    {
      nanogenreId: 4950,
      x: 5345700,
      y: 9600630
    },
    {
      nanogenreId: 4951,
      x: 6464280,
      y: -6430290
    },
    {
      nanogenreId: 4952,
      x: -7888770,
      y: 5197380
    },
    {
      nanogenreId: 4953,
      x: 3213600,
      y: 8899200
    },
    {
      nanogenreId: 4954,
      x: -157590,
      y: -2703750
    },
    {
      nanogenreId: 4955,
      x: -976440,
      y: 3334110
    },
    {
      nanogenreId: 4956,
      x: 3695640,
      y: -10679040
    },
    {
      nanogenreId: 4957,
      x: -8747790,
      y: -10280430
    },
    {
      nanogenreId: 4958,
      x: 4838940,
      y: 8454240
    },
    {
      nanogenreId: 4959,
      x: 6328320,
      y: -3253770
    },
    {
      nanogenreId: 4960,
      x: 6448830,
      y: -7097730
    },
    {
      nanogenreId: 4961,
      x: -11046750,
      y: 1770570
    },
    {
      nanogenreId: 4962,
      x: 7051380,
      y: 10768650
    },
    {
      nanogenreId: 4963,
      x: -3219780,
      y: 4449600
    },
    {
      nanogenreId: 4964,
      x: 2462730,
      y: 7193520
    },
    {
      nanogenreId: 4965,
      x: -1242180,
      y: 2731560
    },
    {
      nanogenreId: 4966,
      x: 4029360,
      y: 14816550
    },
    {
      nanogenreId: 4967,
      x: -964080,
      y: -525300
    },
    {
      nanogenreId: 4968,
      x: 12736980,
      y: -6603330
    },
    {
      nanogenreId: 4969,
      x: 4026270,
      y: 11071470
    },
    {
      nanogenreId: 4970,
      x: 9869460,
      y: -8182320
    },
    {
      nanogenreId: 4971,
      x: 991890,
      y: 8834310
    },
    {
      nanogenreId: 4972,
      x: -4465050,
      y: -3127080
    },
    {
      nanogenreId: 4973,
      x: 2617230,
      y: 2966400
    },
    {
      nanogenreId: 4974,
      x: -2672850,
      y: 2388570
    },
    {
      nanogenreId: 4975,
      x: 4060260,
      y: 9458490
    },
    {
      nanogenreId: 4976,
      x: -1711860,
      y: 8271930
    },
    {
      nanogenreId: 4977,
      x: -142140,
      y: -4066440
    },
    {
      nanogenreId: 4978,
      x: 2141370,
      y: -8083440
    },
    {
      nanogenreId: 4979,
      x: 5515650,
      y: 10051770
    },
    {
      nanogenreId: 4980,
      x: -1752030,
      y: -4665900
    },
    {
      nanogenreId: 4981,
      x: -92700,
      y: -9718050
    },
    {
      nanogenreId: 4982,
      x: 4940910,
      y: 3133260
    },
    {
      nanogenreId: 4983,
      x: -9659340,
      y: -4885290
    },
    {
      nanogenreId: 4984,
      x: 2404020,
      y: 7944390
    },
    {
      nanogenreId: 4985,
      x: 5824650,
      y: 10459650
    },
    {
      nanogenreId: 4986,
      x: -2317500,
      y: -2561610
    },
    {
      nanogenreId: 4987,
      x: -4894560,
      y: 2533800
    },
    {
      nanogenreId: 4988,
      x: -2169180,
      y: -3414450
    },
    {
      nanogenreId: 4989,
      x: 3541140,
      y: -5117040
    },
    {
      nanogenreId: 4990,
      x: 3899580,
      y: 9223650
    },
    {
      nanogenreId: 4991,
      x: -7530330,
      y: -4746240
    },
    {
      nanogenreId: 4992,
      x: -309000,
      y: -1597530
    },
    {
      nanogenreId: 4993,
      x: -5589810,
      y: -7570500
    },
    {
      nanogenreId: 4994,
      x: 9056790,
      y: -4273470
    },
    {
      nanogenreId: 4995,
      x: -3399000,
      y: 4619550
    },
    {
      nanogenreId: 4996,
      x: -3031290,
      y: 1143300
    },
    {
      nanogenreId: 4997,
      x: -1248360,
      y: -8750880
    },
    {
      nanogenreId: 4998,
      x: 1634610,
      y: 2317500
    },
    {
      nanogenreId: 4999,
      x: -8117430,
      y: -9266910
    },
    {
      nanogenreId: 5000,
      x: 1529550,
      y: -3581310
    },
    {
      nanogenreId: 5001,
      x: 7073010,
      y: -3915030
    },
    {
      nanogenreId: 5002,
      x: -3738900,
      y: 2351490
    },
    {
      nanogenreId: 5003,
      x: -3142530,
      y: 957900
    },
    {
      nanogenreId: 5004,
      x: 3704910,
      y: 7697190
    },
    {
      nanogenreId: 5005,
      x: -4588650,
      y: 6062580
    },
    {
      nanogenreId: 5006,
      x: -435690,
      y: 7567410
    },
    {
      nanogenreId: 5007,
      x: -1368870,
      y: 7814610
    },
    {
      nanogenreId: 5008,
      x: 10527630,
      y: -1390500
    },
    {
      nanogenreId: 5009,
      x: -7953660,
      y: -5466210
    },
    {
      nanogenreId: 5010,
      x: 4044810,
      y: -2910780
    },
    {
      nanogenreId: 5011,
      x: -2410200,
      y: 757050
    },
    {
      nanogenreId: 5012,
      x: 5339520,
      y: 10456560
    },
    {
      nanogenreId: 5013,
      x: -8503680,
      y: 11340300
    },
    {
      nanogenreId: 5014,
      x: 5095410,
      y: 9816930
    },
    {
      nanogenreId: 5015,
      x: 4944000,
      y: 9072240
    },
    {
      nanogenreId: 5016,
      x: 6315960,
      y: -3698730
    },
    {
      nanogenreId: 5017,
      x: -2879880,
      y: 15110100
    },
    {
      nanogenreId: 5018,
      x: 392430,
      y: -1776750
    },
    {
      nanogenreId: 5019,
      x: -9594450,
      y: 10651230
    },
    {
      nanogenreId: 5020,
      x: -4069530,
      y: 7409820
    },
    {
      nanogenreId: 5021,
      x: 4084980,
      y: 4220940
    },
    {
      nanogenreId: 5022,
      x: 7527240,
      y: -7771350
    },
    {
      nanogenreId: 5023,
      x: 5296260,
      y: 6686760
    },
    {
      nanogenreId: 5024,
      x: 5015070,
      y: 8166870
    },
    {
      nanogenreId: 5025,
      x: -509850,
      y: -3828510
    },
    {
      nanogenreId: 5026,
      x: 1467750,
      y: 4143690
    },
    {
      nanogenreId: 5027,
      x: -599460,
      y: 7048290
    },
    {
      nanogenreId: 5028,
      x: 4925460,
      y: 4990350
    },
    {
      nanogenreId: 5029,
      x: -12066450,
      y: -2598690
    },
    {
      nanogenreId: 5030,
      x: -8985720,
      y: 3170340
    },
    {
      nanogenreId: 5031,
      x: 4971810,
      y: 9807660
    },
    {
      nanogenreId: 5032,
      x: 3732720,
      y: 10617240
    },
    {
      nanogenreId: 5033,
      x: -1795290,
      y: 1433760
    },
    {
      nanogenreId: 5034,
      x: -3497880,
      y: 2886060
    },
    {
      nanogenreId: 5035,
      x: 9365790,
      y: 3881040
    },
    {
      nanogenreId: 5036,
      x: -8169960,
      y: 11711100
    },
    {
      nanogenreId: 5037,
      x: 753960,
      y: 2493630
    },
    {
      nanogenreId: 5038,
      x: 4743150,
      y: -4165320
    },
    {
      nanogenreId: 5039,
      x: 4258020,
      y: 10558530
    },
    {
      nanogenreId: 5040,
      x: 259560,
      y: 10521450
    },
    {
      nanogenreId: 5041,
      x: -1010430,
      y: 2295870
    },
    {
      nanogenreId: 5042,
      x: -4047900,
      y: 5673240
    },
    {
      nanogenreId: 5043,
      x: -3547320,
      y: 7110090
    },
    {
      nanogenreId: 5044,
      x: 908460,
      y: -5639250
    },
    {
      nanogenreId: 5045,
      x: 6133650,
      y: 9745860
    },
    {
      nanogenreId: 5046,
      x: -1121670,
      y: -7449990
    },
    {
      nanogenreId: 5047,
      x: -5642340,
      y: -2014680
    },
    {
      nanogenreId: 5048,
      x: 3040560,
      y: -8024730
    },
    {
      nanogenreId: 5049,
      x: 6297420,
      y: 8846670
    },
    {
      nanogenreId: 5050,
      x: -2889150,
      y: -6569340
    },
    {
      nanogenreId: 5051,
      x: -1236000,
      y: 3986100
    },
    {
      nanogenreId: 5052,
      x: -4341450,
      y: -11188890
    },
    {
      nanogenreId: 5053,
      x: -10855170,
      y: -2493630
    },
    {
      nanogenreId: 5054,
      x: 2385480,
      y: -414060
    },
    {
      nanogenreId: 5055,
      x: 7820790,
      y: 5518740
    },
    {
      nanogenreId: 5056,
      x: -9702600,
      y: -1887990
    },
    {
      nanogenreId: 5057,
      x: 4659720,
      y: 9424500
    },
    {
      nanogenreId: 5058,
      x: -5324070,
      y: 6201630
    },
    {
      nanogenreId: 5059,
      x: -5602170,
      y: -4570110
    },
    {
      nanogenreId: 5060,
      x: 2419470,
      y: -1421400
    },
    {
      nanogenreId: 5061,
      x: 6760920,
      y: -8432610
    },
    {
      nanogenreId: 5062,
      x: 1130940,
      y: 8630370
    },
    {
      nanogenreId: 5063,
      x: 3581310,
      y: 13067610
    },
    {
      nanogenreId: 5064,
      x: -12273480,
      y: -4267290
    },
    {
      nanogenreId: 5065,
      x: -10227900,
      y: 4387800
    },
    {
      nanogenreId: 5066,
      x: -10404030,
      y: 5104680
    },
    {
      nanogenreId: 5067,
      x: -5821560,
      y: 3263040
    },
    {
      nanogenreId: 5068,
      x: 6961770,
      y: -8166870
    },
    {
      nanogenreId: 5069,
      x: -6640410,
      y: -2033220
    },
    {
      nanogenreId: 5070,
      x: 8584020,
      y: -7956750
    },
    {
      nanogenreId: 5071,
      x: -5506380,
      y: 991890
    },
    {
      nanogenreId: 5072,
      x: 2734650,
      y: 8574750
    },
    {
      nanogenreId: 5073,
      x: -2546160,
      y: 3108540
    },
    {
      nanogenreId: 5074,
      x: 590190,
      y: 5704140
    },
    {
      nanogenreId: 5075,
      x: 4699890,
      y: -2076480
    },
    {
      nanogenreId: 5076,
      x: 17143320,
      y: 2456550
    },
    {
      nanogenreId: 5077,
      x: -5151030,
      y: -15626130
    },
    {
      nanogenreId: 5078,
      x: 7363470,
      y: -6374670
    },
    {
      nanogenreId: 5079,
      x: 4962540,
      y: 9504840
    },
    {
      nanogenreId: 5080,
      x: 14949420,
      y: 7144080
    },
    {
      nanogenreId: 5081,
      x: 5042880,
      y: 3899580
    },
    {
      nanogenreId: 5082,
      x: 4560840,
      y: 9121680
    },
    {
      nanogenreId: 5083,
      x: -1375050,
      y: 7456170
    },
    {
      nanogenreId: 5084,
      x: -7777530,
      y: 5861730
    },
    {
      nanogenreId: 5085,
      x: 4956360,
      y: -3989190
    },
    {
      nanogenreId: 5086,
      x: -2169180,
      y: -2283510
    },
    {
      nanogenreId: 5087,
      x: -194670,
      y: -911550
    },
    {
      nanogenreId: 5088,
      x: -7527240,
      y: -5942070
    },
    {
      nanogenreId: 5089,
      x: 7128630,
      y: -7440720
    },
    {
      nanogenreId: 5090,
      x: -2842800,
      y: 18540
    },
    {
      nanogenreId: 5091,
      x: 5286990,
      y: 3670920
    },
    {
      nanogenreId: 5092,
      x: 6108930,
      y: -6730020
    },
    {
      nanogenreId: 5093,
      x: 2481270,
      y: -3093090
    },
    {
      nanogenreId: 5094,
      x: 4977990,
      y: -5917350
    },
    {
      nanogenreId: 5095,
      x: 15777540,
      y: 15107010
    },
    {
      nanogenreId: 5096,
      x: -1480110,
      y: 593280
    },
    {
      nanogenreId: 5097,
      x: -7981470,
      y: 6451920
    },
    {
      nanogenreId: 5098,
      x: 4628820,
      y: 8160690
    },
    {
      nanogenreId: 5099,
      x: 4746240,
      y: 2555430
    },
    {
      nanogenreId: 5100,
      x: -7962930,
      y: 1801470
    },
    {
      nanogenreId: 5101,
      x: 8256480,
      y: -5543460
    },
    {
      nanogenreId: 5102,
      x: 3649290,
      y: 10002330
    },
    {
      nanogenreId: 5103,
      x: 1588260,
      y: 3136350
    },
    {
      nanogenreId: 5104,
      x: -435690,
      y: 6915420
    },
    {
      nanogenreId: 5105,
      x: 5348790,
      y: 10033230
    },
    {
      nanogenreId: 5106,
      x: 5679420,
      y: 5633070
    },
    {
      nanogenreId: 5107,
      x: 4066440,
      y: 6813450
    },
    {
      nanogenreId: 5108,
      x: 14214000,
      y: -2740830
    },
    {
      nanogenreId: 5109,
      x: -4628820,
      y: -3463890
    },
    {
      nanogenreId: 5110,
      x: -5354970,
      y: -7913490
    },
    {
      nanogenreId: 5111,
      x: -2641950,
      y: 6090390
    },
    {
      nanogenreId: 5112,
      x: 5052150,
      y: 9263820
    },
    {
      nanogenreId: 5113,
      x: 7981470,
      y: -1711860
    },
    {
      nanogenreId: 5114,
      x: 639630,
      y: 10079580
    },
    {
      nanogenreId: 5115,
      x: -3528780,
      y: 7332570
    },
    {
      nanogenreId: 5116,
      x: -3090000,
      y: 2836620
    },
    {
      nanogenreId: 5117,
      x: -3457710,
      y: 10960230
    },
    {
      nanogenreId: 5118,
      x: 8389350,
      y: -8197770
    },
    {
      nanogenreId: 5119,
      x: -893010,
      y: 4996530
    },
    {
      nanogenreId: 5120,
      x: 6059490,
      y: -7471620
    },
    {
      nanogenreId: 5121,
      x: 5546550,
      y: -5113950
    },
    {
      nanogenreId: 5122,
      x: -828120,
      y: 670530
    },
    {
      nanogenreId: 5123,
      x: 21630,
      y: -4279650
    },
    {
      nanogenreId: 5124,
      x: 6226350,
      y: -7808430
    },
    {
      nanogenreId: 5125,
      x: -3365010,
      y: -7332570
    },
    {
      nanogenreId: 5126,
      x: 8920830,
      y: -6952500
    },
    {
      nanogenreId: 5127,
      x: 2342220,
      y: -10765560
    },
    {
      nanogenreId: 5128,
      x: -1609890,
      y: -1578990
    },
    {
      nanogenreId: 5129,
      x: -386250,
      y: -160680
    },
    {
      nanogenreId: 5130,
      x: -531480,
      y: -1517190
    },
    {
      nanogenreId: 5131,
      x: -5812290,
      y: 5552730
    },
    {
      nanogenreId: 5132,
      x: -1841640,
      y: -1100040
    },
    {
      nanogenreId: 5133,
      x: 5552730,
      y: 3933570
    },
    {
      nanogenreId: 5134,
      x: -293550,
      y: 7721910
    },
    {
      nanogenreId: 5135,
      x: 9081510,
      y: 4820400
    },
    {
      nanogenreId: 5136,
      x: -4471230,
      y: 797220
    },
    {
      nanogenreId: 5137,
      x: -868290,
      y: 5806110
    },
    {
      nanogenreId: 5138,
      x: -4134420,
      y: 3513330
    },
    {
      nanogenreId: 5139,
      x: -7771350,
      y: 4891470
    },
    {
      nanogenreId: 5140,
      x: 1035150,
      y: 12619560
    },
    {
      nanogenreId: 5141,
      x: -3627660,
      y: 1177290
    },
    {
      nanogenreId: 5142,
      x: 417150,
      y: 1523370
    },
    {
      nanogenreId: 5143,
      x: 7212060,
      y: 4563930
    },
    {
      nanogenreId: 5144,
      x: -562380,
      y: -2397840
    },
    {
      nanogenreId: 5145,
      x: -2858250,
      y: 1149480
    },
    {
      nanogenreId: 5146,
      x: 4063350,
      y: 1300890
    },
    {
      nanogenreId: 5147,
      x: 1236000,
      y: -911550
    },
    {
      nanogenreId: 5148,
      x: 14390130,
      y: -797220
    },
    {
      nanogenreId: 5149,
      x: -1921980,
      y: -3188880
    },
    {
      nanogenreId: 5150,
      x: -5633070,
      y: 1341060
    },
    {
      nanogenreId: 5151,
      x: 4653540,
      y: 10614150
    },
    {
      nanogenreId: 5152,
      x: 509850,
      y: 234840
    },
    {
      nanogenreId: 5153,
      x: -231750,
      y: 3182700
    },
    {
      nanogenreId: 5154,
      x: 5358060,
      y: 8503680
    },
    {
      nanogenreId: 5155,
      x: -1541910,
      y: 3374280
    },
    {
      nanogenreId: 5156,
      x: 1903440,
      y: 9325620
    },
    {
      nanogenreId: 5157,
      x: 1523370,
      y: 9130950
    },
    {
      nanogenreId: 5158,
      x: 1850910,
      y: 7653930
    },
    {
      nanogenreId: 5159,
      x: 1671690,
      y: -7125540
    },
    {
      nanogenreId: 5160,
      x: 5243730,
      y: -6856710
    },
    {
      nanogenreId: 5161,
      x: 10073400,
      y: -7972200
    },
    {
      nanogenreId: 5162,
      x: 701430,
      y: 12678270
    },
    {
      nanogenreId: 5163,
      x: 429510,
      y: -2005410
    },
    {
      nanogenreId: 5164,
      x: -4159140,
      y: -8970270
    },
    {
      nanogenreId: 5165,
      x: 14294340,
      y: -2354580
    },
    {
      nanogenreId: 5166,
      x: 13209750,
      y: 11349570
    },
    {
      nanogenreId: 5167,
      x: -1430670,
      y: -3902670
    },
    {
      nanogenreId: 5168,
      x: 1693320,
      y: 7026660
    },
    {
      nanogenreId: 5169,
      x: 3785250,
      y: 8488230
    },
    {
      nanogenreId: 5170,
      x: -278100,
      y: 1866360
    },
    {
      nanogenreId: 5171,
      x: 2020860,
      y: -7728090
    },
    {
      nanogenreId: 5172,
      x: -9480120,
      y: 312090
    },
    {
      nanogenreId: 5173,
      x: 4570110,
      y: -4894560
    },
    {
      nanogenreId: 5174,
      x: 9761310,
      y: -8061810
    },
    {
      nanogenreId: 5175,
      x: 15267690,
      y: 8942460
    },
    {
      nanogenreId: 5176,
      x: 1035150,
      y: -7666290
    },
    {
      nanogenreId: 5177,
      x: -2555430,
      y: 2326770
    },
    {
      nanogenreId: 5178,
      x: -6068760,
      y: 2163000
    },
    {
      nanogenreId: 5179,
      x: -2178450,
      y: 3423720
    },
    {
      nanogenreId: 5180,
      x: -407880,
      y: 8429520
    },
    {
      nanogenreId: 5181,
      x: 3986100,
      y: 10271160
    },
    {
      nanogenreId: 5182,
      x: -2728470,
      y: -327540
    },
    {
      nanogenreId: 5183,
      x: -7323300,
      y: -3945930
    },
    {
      nanogenreId: 5184,
      x: 5036700,
      y: 9940530
    },
    {
      nanogenreId: 5185,
      x: 9171120,
      y: 8182320
    },
    {
      nanogenreId: 5186,
      x: -12304380,
      y: 1820010
    },
    {
      nanogenreId: 5187,
      x: -2098110,
      y: -234840
    },
    {
      nanogenreId: 5188,
      x: -1415220,
      y: 8571660
    },
    {
      nanogenreId: 5189,
      x: -6087300,
      y: 5960610
    },
    {
      nanogenreId: 5190,
      x: 3788340,
      y: -9223650
    },
    {
      nanogenreId: 5191,
      x: 135960,
      y: 849750
    },
    {
      nanogenreId: 5192,
      x: -2076480,
      y: 5382780
    },
    {
      nanogenreId: 5193,
      x: 772500,
      y: 5960610
    },
    {
      nanogenreId: 5194,
      x: -1816920,
      y: 5404410
    },
    {
      nanogenreId: 5195,
      x: 5039790,
      y: 707610
    },
    {
      nanogenreId: 5196,
      x: -2373120,
      y: 7119360
    },
    {
      nanogenreId: 5197,
      x: -13929720,
      y: -5691780
    },
    {
      nanogenreId: 5198,
      x: -2889150,
      y: 4001550
    },
    {
      nanogenreId: 5199,
      x: 3507150,
      y: 9554280
    },
    {
      nanogenreId: 5200,
      x: 3964470,
      y: 8432610
    },
    {
      nanogenreId: 5201,
      x: 8577840,
      y: -4276560
    },
    {
      nanogenreId: 5202,
      x: -13175760,
      y: 1736580
    },
    {
      nanogenreId: 5203,
      x: 2320590,
      y: 8936280
    },
    {
      nanogenreId: 5204,
      x: -1504830,
      y: 154500
    },
    {
      nanogenreId: 5205,
      x: 3630750,
      y: 794130
    },
    {
      nanogenreId: 5206,
      x: 5049060,
      y: 8954820
    },
    {
      nanogenreId: 5207,
      x: -4038630,
      y: 7712640
    },
    {
      nanogenreId: 5208,
      x: 4740060,
      y: 8670540
    },
    {
      nanogenreId: 5209,
      x: 1202010,
      y: -364620
    },
    {
      nanogenreId: 5210,
      x: 438780,
      y: -6943230
    },
    {
      nanogenreId: 5211,
      x: 6121290,
      y: 9239100
    },
    {
      nanogenreId: 5212,
      x: -6677490,
      y: 3179610
    },
    {
      nanogenreId: 5213,
      x: 5803020,
      y: -6207810
    },
    {
      nanogenreId: 5214,
      x: -13852470,
      y: -5568180
    },
    {
      nanogenreId: 5215,
      x: -2472000,
      y: 5562000
    },
    {
      nanogenreId: 5216,
      x: 982620,
      y: -2184630
    },
    {
      nanogenreId: 5217,
      x: 2796450,
      y: -6263430
    },
    {
      nanogenreId: 5218,
      x: 318270,
      y: 3596760
    },
    {
      nanogenreId: 5219,
      x: -2561610,
      y: -8197770
    },
    {
      nanogenreId: 5220,
      x: 6433380,
      y: -5636160
    },
    {
      nanogenreId: 5221,
      x: 4094250,
      y: 6674400
    },
    {
      nanogenreId: 5222,
      x: -2428740,
      y: -1696410
    },
    {
      nanogenreId: 5223,
      x: 5528010,
      y: -11009670
    },
    {
      nanogenreId: 5224,
      x: -3145620,
      y: 7483980
    },
    {
      nanogenreId: 5225,
      x: 2799540,
      y: -5138670
    },
    {
      nanogenreId: 5226,
      x: 682890,
      y: 7295490
    },
    {
      nanogenreId: 5227,
      x: 2107380,
      y: 7626120
    },
    {
      nanogenreId: 5228,
      x: -3457710,
      y: -10734660
    },
    {
      nanogenreId: 5229,
      x: -8923920,
      y: -6825810
    },
    {
      nanogenreId: 5230,
      x: 1838550,
      y: 5651610
    },
    {
      nanogenreId: 5231,
      x: -4730790,
      y: 7836240
    },
    {
      nanogenreId: 5232,
      x: 8250300,
      y: -6470460
    },
    {
      nanogenreId: 5233,
      x: -4128240,
      y: -5895720
    },
    {
      nanogenreId: 5234,
      x: 5160300,
      y: 9205110
    },
    {
      nanogenreId: 5235,
      x: 2960220,
      y: -2607960
    },
    {
      nanogenreId: 5236,
      x: -6149100,
      y: 1585170
    },
    {
      nanogenreId: 5237,
      x: 4381620,
      y: -3633840
    },
    {
      nanogenreId: 5238,
      x: 472770,
      y: -6183090
    },
    {
      nanogenreId: 5239,
      x: -4418700,
      y: 618000
    },
    {
      nanogenreId: 5240,
      x: 3303210,
      y: 10329870
    },
    {
      nanogenreId: 5241,
      x: -8590200,
      y: -970260
    },
    {
      nanogenreId: 5242,
      x: 135960,
      y: 6853620
    },
    {
      nanogenreId: 5243,
      x: 3049830,
      y: 6303600
    },
    {
      nanogenreId: 5244,
      x: -7156440,
      y: 3025110
    },
    {
      nanogenreId: 5245,
      x: 1158750,
      y: -9115500
    },
    {
      nanogenreId: 5246,
      x: 3352650,
      y: -2827350
    },
    {
      nanogenreId: 5247,
      x: -8537670,
      y: -562380
    },
    {
      nanogenreId: 5248,
      x: 5237550,
      y: 8577840
    },
    {
      nanogenreId: 5249,
      x: -5172660,
      y: 441870
    },
    {
      nanogenreId: 5250,
      x: -3877950,
      y: 4514490
    },
    {
      nanogenreId: 5251,
      x: -7301670,
      y: 166860
    },
    {
      nanogenreId: 5252,
      x: -8265750,
      y: 9149490
    },
    {
      nanogenreId: 5253,
      x: -9572820,
      y: 7715730
    },
    {
      nanogenreId: 5254,
      x: 1684050,
      y: 2716110
    },
    {
      nanogenreId: 5255,
      x: -6322140,
      y: 2184630
    },
    {
      nanogenreId: 5256,
      x: -9798390,
      y: 957900
    },
    {
      nanogenreId: 5257,
      x: -1668600,
      y: -410970
    },
    {
      nanogenreId: 5258,
      x: -917730,
      y: -10484370
    },
    {
      nanogenreId: 5259,
      x: -3865590,
      y: 10298970
    },
    {
      nanogenreId: 5260,
      x: -4081890,
      y: -6396300
    },
    {
      nanogenreId: 5261,
      x: -2187720,
      y: 4897650
    },
    {
      nanogenreId: 5262,
      x: -9409050,
      y: 553110
    },
    {
      nanogenreId: 5263,
      x: -2577060,
      y: -4248750
    },
    {
      nanogenreId: 5264,
      x: -5583630,
      y: -12851310
    },
    {
      nanogenreId: 5265,
      x: -4242570,
      y: 4529940
    },
    {
      nanogenreId: 5266,
      x: 8509860,
      y: -7845510
    },
    {
      nanogenreId: 5267,
      x: 3704910,
      y: 8988810
    },
    {
      nanogenreId: 5268,
      x: 4199310,
      y: 6933960
    },
    {
      nanogenreId: 5269,
      x: -10821180,
      y: -4387800
    },
    {
      nanogenreId: 5270,
      x: 3930480,
      y: 8828130
    },
    {
      nanogenreId: 5271,
      x: -12236400,
      y: -5286990
    },
    {
      nanogenreId: 5272,
      x: 2159910,
      y: 4205490
    },
    {
      nanogenreId: 5273,
      x: 3979920,
      y: 9285450
    },
    {
      nanogenreId: 5274,
      x: 7150260,
      y: 3714180
    },
    {
      nanogenreId: 5275,
      x: 5392050,
      y: 9931260
    },
    {
      nanogenreId: 5276,
      x: 16389360,
      y: 639630
    },
    {
      nanogenreId: 5277,
      x: 9263820,
      y: -1520280
    },
    {
      nanogenreId: 5278,
      x: -8985720,
      y: 126690
    },
    {
      nanogenreId: 5279,
      x: 4869840,
      y: 9551190
    },
    {
      nanogenreId: 5280,
      x: -8129790,
      y: -1239090
    },
    {
      nanogenreId: 5281,
      x: 1279260,
      y: 837390
    },
    {
      nanogenreId: 5282,
      x: 1850910,
      y: 8027820
    },
    {
      nanogenreId: 5283,
      x: -5589810,
      y: -933180
    },
    {
      nanogenreId: 5284,
      x: 2617230,
      y: 6921600
    },
    {
      nanogenreId: 5285,
      x: -3015840,
      y: -6853620
    },
    {
      nanogenreId: 5286,
      x: 2790270,
      y: 8225580
    },
    {
      nanogenreId: 5287,
      x: 3105450,
      y: 4279650
    },
    {
      nanogenreId: 5288,
      x: 3735810,
      y: 8058720
    },
    {
      nanogenreId: 5289,
      x: 4440330,
      y: 6813450
    },
    {
      nanogenreId: 5290,
      x: 4193130,
      y: 4227120
    },
    {
      nanogenreId: 5291,
      x: 893010,
      y: 3040560
    },
    {
      nanogenreId: 5292,
      x: 9334890,
      y: 4693710
    },
    {
      nanogenreId: 5293,
      x: 6207810,
      y: -6526080
    },
    {
      nanogenreId: 5294,
      x: -6331410,
      y: 5160300
    },
    {
      nanogenreId: 5295,
      x: -6594060,
      y: 8525310
    },
    {
      nanogenreId: 5296,
      x: 333720,
      y: 8271930
    },
    {
      nanogenreId: 5297,
      x: -1399770,
      y: 3942840
    },
    {
      nanogenreId: 5298,
      x: 4094250,
      y: 8420250
    },
    {
      nanogenreId: 5299,
      x: -3179610,
      y: 222480
    },
    {
      nanogenreId: 5300,
      x: 880650,
      y: 7493250
    },
    {
      nanogenreId: 5301,
      x: -976440,
      y: 6467370
    },
    {
      nanogenreId: 5302,
      x: 6059490,
      y: 4128240
    },
    {
      nanogenreId: 5303,
      x: 5172660,
      y: 6689850
    },
    {
      nanogenreId: 5304,
      x: -902280,
      y: 6835080
    },
    {
      nanogenreId: 5305,
      x: 1857090,
      y: -8837400
    },
    {
      nanogenreId: 5306,
      x: 5126310,
      y: 6210900
    },
    {
      nanogenreId: 5307,
      x: -9381240,
      y: -7178070
    },
    {
      nanogenreId: 5308,
      x: 6584790,
      y: -9402870
    },
    {
      nanogenreId: 5309,
      x: -3457710,
      y: -2814990
    },
    {
      nanogenreId: 5310,
      x: 13744320,
      y: -2119740
    },
    {
      nanogenreId: 5311,
      x: -4363080,
      y: -49440
    },
    {
      nanogenreId: 5312,
      x: 3012750,
      y: 8556210
    },
    {
      nanogenreId: 5313,
      x: 4499040,
      y: -5639250
    },
    {
      nanogenreId: 5314,
      x: -6312870,
      y: 4854390
    },
    {
      nanogenreId: 5315,
      x: -2110470,
      y: -8784870
    },
    {
      nanogenreId: 5316,
      x: -1646970,
      y: -8043270
    },
    {
      nanogenreId: 5317,
      x: 6078030,
      y: -6084210
    },
    {
      nanogenreId: 5318,
      x: -9081510,
      y: 2731560
    },
    {
      nanogenreId: 5319,
      x: 8123610,
      y: -6526080
    },
    {
      nanogenreId: 5320,
      x: 8667450,
      y: -7323300
    },
    {
      nanogenreId: 5321,
      x: 3704910,
      y: 4838940
    },
    {
      nanogenreId: 5322,
      x: 1081500,
      y: -6569340
    },
    {
      nanogenreId: 5323,
      x: -1541910,
      y: 6217080
    },
    {
      nanogenreId: 5324,
      x: 11040570,
      y: -8185410
    },
    {
      nanogenreId: 5325,
      x: 3992280,
      y: 8812680
    },
    {
      nanogenreId: 5326,
      x: -9371970,
      y: 2280420
    },
    {
      nanogenreId: 5327,
      x: -8000010,
      y: 4971810
    },
    {
      nanogenreId: 5328,
      x: -957900,
      y: -3479340
    },
    {
      nanogenreId: 5329,
      x: 5435310,
      y: 9841650
    },
    {
      nanogenreId: 5330,
      x: -207030,
      y: 7969110
    },
    {
      nanogenreId: 5331,
      x: 9579000,
      y: -7008120
    },
    {
      nanogenreId: 5332,
      x: -3402090,
      y: -3040560
    },
    {
      nanogenreId: 5333,
      x: 5327160,
      y: 9096960
    },
    {
      nanogenreId: 5334,
      x: -3457710,
      y: 3538050
    },
    {
      nanogenreId: 5335,
      x: -3534960,
      y: 6986490
    },
    {
      nanogenreId: 5336,
      x: 3000390,
      y: 4579380
    },
    {
      nanogenreId: 5337,
      x: -1433760,
      y: 12369270
    },
    {
      nanogenreId: 5338,
      x: -2169180,
      y: -1937430
    },
    {
      nanogenreId: 5339,
      x: -4557750,
      y: 12295110
    },
    {
      nanogenreId: 5340,
      x: 5008890,
      y: -3096180
    },
    {
      nanogenreId: 5341,
      x: 1798380,
      y: 8624190
    },
    {
      nanogenreId: 5342,
      x: 5212830,
      y: 14408670
    },
    {
      nanogenreId: 5343,
      x: -7137900,
      y: 5191200
    },
    {
      nanogenreId: 5344,
      x: -2552340,
      y: 2054850
    },
    {
      nanogenreId: 5345,
      x: -2051760,
      y: -1752030
    },
    {
      nanogenreId: 5346,
      x: -3275400,
      y: -4390890
    },
    {
      nanogenreId: 5347,
      x: 5571270,
      y: 3052920
    },
    {
      nanogenreId: 5348,
      x: 11093100,
      y: 16457340
    },
    {
      nanogenreId: 5349,
      x: -5049060,
      y: 5611440
    },
    {
      nanogenreId: 5350,
      x: 2944770,
      y: -4088070
    },
    {
      nanogenreId: 5351,
      x: 8321370,
      y: -8120520
    },
    {
      nanogenreId: 5352,
      x: -9755130,
      y: 10808820
    },
    {
      nanogenreId: 5353,
      x: 3803790,
      y: -9483210
    },
    {
      nanogenreId: 5354,
      x: 5614530,
      y: -979530
    },
    {
      nanogenreId: 5355,
      x: -2431830,
      y: 2484360
    },
    {
      nanogenreId: 5356,
      x: 775590,
      y: 3961380
    },
    {
      nanogenreId: 5357,
      x: -386250,
      y: 6745470
    },
    {
      nanogenreId: 5358,
      x: 1820010,
      y: 6791820
    },
    {
      nanogenreId: 5359,
      x: 4903830,
      y: 8550030
    },
    {
      nanogenreId: 5360,
      x: -4588650,
      y: 5265360
    },
    {
      nanogenreId: 5361,
      x: -760140,
      y: 3327930
    },
    {
      nanogenreId: 5362,
      x: -577830,
      y: 5265360
    },
    {
      nanogenreId: 5363,
      x: -5555820,
      y: 3018930
    },
    {
      nanogenreId: 5364,
      x: -7499430,
      y: -234840
    },
    {
      nanogenreId: 5365,
      x: -1708770,
      y: 9470850
    },
    {
      nanogenreId: 5366,
      x: 10601790,
      y: -5157210
    },
    {
      nanogenreId: 5367,
      x: 4322910,
      y: 1619160
    },
    {
      nanogenreId: 5368,
      x: -4227120,
      y: 5157210
    },
    {
      nanogenreId: 5369,
      x: -2669760,
      y: -2988030
    },
    {
      nanogenreId: 5370,
      x: 1940520,
      y: 2462730
    },
    {
      nanogenreId: 5371,
      x: -2095020,
      y: -8577840
    },
    {
      nanogenreId: 5372,
      x: -3612210,
      y: 3658560
    },
    {
      nanogenreId: 5373,
      x: 5528010,
      y: 7462350
    },
    {
      nanogenreId: 5374,
      x: 309000,
      y: 1826190
    },
    {
      nanogenreId: 5375,
      x: 4115880,
      y: 10632690
    },
    {
      nanogenreId: 5376,
      x: 451140,
      y: -3949020
    },
    {
      nanogenreId: 5377,
      x: 6121290,
      y: 7675560
    },
    {
      nanogenreId: 5378,
      x: 1656240,
      y: 8410980
    },
    {
      nanogenreId: 5379,
      x: 3086910,
      y: 10923150
    },
    {
      nanogenreId: 5380,
      x: -5509470,
      y: 1993050
    },
    {
      nanogenreId: 5381,
      x: -4758600,
      y: 1164930
    },
    {
      nanogenreId: 5382,
      x: -7913490,
      y: -4020090
    },
    {
      nanogenreId: 5383,
      x: 5358060,
      y: 1566630
    },
    {
      nanogenreId: 5384,
      x: -8948640,
      y: 10697580
    },
    {
      nanogenreId: 5385,
      x: -43260,
      y: -3874860
    },
    {
      nanogenreId: 5386,
      x: -7298580,
      y: -5787570
    },
    {
      nanogenreId: 5387,
      x: 191580,
      y: 2502900
    },
    {
      nanogenreId: 5388,
      x: 1164930,
      y: -5837010
    },
    {
      nanogenreId: 5389,
      x: 6689850,
      y: 825030
    },
    {
      nanogenreId: 5390,
      x: -5021250,
      y: 7375830
    },
    {
      nanogenreId: 5391,
      x: 8383170,
      y: -5812290
    },
    {
      nanogenreId: 5392,
      x: 4585560,
      y: -4619550
    },
    {
      nanogenreId: 5393,
      x: 9270,
      y: 8055630
    },
    {
      nanogenreId: 5394,
      x: -2184630,
      y: 3170340
    },
    {
      nanogenreId: 5395,
      x: 6835080,
      y: -6940140
    },
    {
      nanogenreId: 5396,
      x: -5101590,
      y: 7975290
    },
    {
      nanogenreId: 5397,
      x: -4650450,
      y: 590190
    },
    {
      nanogenreId: 5398,
      x: 4026270,
      y: 9650070
    },
    {
      nanogenreId: 5399,
      x: -3154890,
      y: -1010430
    },
    {
      nanogenreId: 5400,
      x: 10101210,
      y: -2734650
    },
    {
      nanogenreId: 5401,
      x: -2326770,
      y: -5039790
    },
    {
      nanogenreId: 5402,
      x: -4684440,
      y: -6025500
    },
    {
      nanogenreId: 5403,
      x: 7026660,
      y: -9266910
    },
    {
      nanogenreId: 5404,
      x: 135960,
      y: 6621870
    },
    {
      nanogenreId: 5405,
      x: 5821560,
      y: 2651220
    },
    {
      nanogenreId: 5406,
      x: 253380,
      y: -278100
    },
    {
      nanogenreId: 5407,
      x: 5237550,
      y: 17884920
    },
    {
      nanogenreId: 5408,
      x: -8735430,
      y: 287370
    },
    {
      nanogenreId: 5409,
      x: 4363080,
      y: 8769420
    },
    {
      nanogenreId: 5410,
      x: 231750,
      y: 5274630
    },
    {
      nanogenreId: 5411,
      x: 8083440,
      y: 2150640
    },
    {
      nanogenreId: 5412,
      x: -11686380,
      y: -3704910
    },
    {
      nanogenreId: 5413,
      x: -855930,
      y: 1572810
    },
    {
      nanogenreId: 5414,
      x: -3494790,
      y: 3040560
    },
    {
      nanogenreId: 5415,
      x: 4162230,
      y: 2431830
    },
    {
      nanogenreId: 5416,
      x: -5379690,
      y: 3395910
    },
    {
      nanogenreId: 5417,
      x: 4817310,
      y: 9143310
    },
    {
      nanogenreId: 5418,
      x: -2496720,
      y: -8058720
    },
    {
      nanogenreId: 5419,
      x: -64890,
      y: -5648520
    },
    {
      nanogenreId: 5420,
      x: -9112410,
      y: 568560
    },
    {
      nanogenreId: 5421,
      x: -5021250,
      y: 1220550
    },
    {
      nanogenreId: 5422,
      x: -2818080,
      y: 10666680
    },
    {
      nanogenreId: 5423,
      x: 1004250,
      y: -880650
    },
    {
      nanogenreId: 5424,
      x: 309000,
      y: 6328320
    },
    {
      nanogenreId: 5425,
      x: -7873320,
      y: -4922370
    },
    {
      nanogenreId: 5426,
      x: -6980310,
      y: 1548090
    },
    {
      nanogenreId: 5427,
      x: -148320,
      y: 5039790
    },
    {
      nanogenreId: 5428,
      x: -10163010,
      y: -6606420
    },
    {
      nanogenreId: 5429,
      x: 2601780,
      y: -2821170
    },
    {
      nanogenreId: 5430,
      x: -651990,
      y: 1384320
    },
    {
      nanogenreId: 5431,
      x: 4233300,
      y: 4335270
    },
    {
      nanogenreId: 5432,
      x: -8401710,
      y: -2719200
    },
    {
      nanogenreId: 5433,
      x: -3148710,
      y: -2360760
    },
    {
      nanogenreId: 5434,
      x: -2159910,
      y: 2431830
    },
    {
      nanogenreId: 5435,
      x: -1974510,
      y: -896100
    },
    {
      nanogenreId: 5436,
      x: -917730,
      y: 7969110
    },
    {
      nanogenreId: 5437,
      x: 1007340,
      y: -5351880
    },
    {
      nanogenreId: 5438,
      x: 2505990,
      y: 2864430
    },
    {
      nanogenreId: 5439,
      x: 1718040,
      y: 6300510
    },
    {
      nanogenreId: 5440,
      x: 1820010,
      y: 6989580
    },
    {
      nanogenreId: 5441,
      x: 6547710,
      y: -6553890
    },
    {
      nanogenreId: 5442,
      x: 8794140,
      y: -6315960
    },
    {
      nanogenreId: 5443,
      x: 1266900,
      y: 8250300
    },
    {
      nanogenreId: 5444,
      x: -2419470,
      y: 6337590
    },
    {
      nanogenreId: 5445,
      x: 4304370,
      y: -3850140
    },
    {
      nanogenreId: 5446,
      x: 2802630,
      y: 3779070
    },
    {
      nanogenreId: 5447,
      x: 5222100,
      y: 8244120
    },
    {
      nanogenreId: 5448,
      x: -4233300,
      y: 4091160
    },
    {
      nanogenreId: 5449,
      x: 4477410,
      y: 8030910
    },
    {
      nanogenreId: 5450,
      x: 10243350,
      y: 1640790
    },
    {
      nanogenreId: 5451,
      x: -6025500,
      y: 6791820
    },
    {
      nanogenreId: 5452,
      x: 225570,
      y: 6142920
    },
    {
      nanogenreId: 5453,
      x: 5419860,
      y: -5336430
    },
    {
      nanogenreId: 5454,
      x: 169950,
      y: 14220180
    },
    {
      nanogenreId: 5455,
      x: 4684440,
      y: 8037090
    },
    {
      nanogenreId: 5456,
      x: 553110,
      y: 3667830
    },
    {
      nanogenreId: 5457,
      x: 6726930,
      y: -7573590
    },
    {
      nanogenreId: 5458,
      x: 2447280,
      y: 828120
    },
    {
      nanogenreId: 5459,
      x: 14609520,
      y: 5277720
    },
    {
      nanogenreId: 5460,
      x: 3995370,
      y: -3908850
    },
    {
      nanogenreId: 5461,
      x: 6473550,
      y: -4888380
    },
    {
      nanogenreId: 5462,
      x: -4017000,
      y: -917730
    },
    {
      nanogenreId: 5463,
      x: -6167640,
      y: -6992670
    },
    {
      nanogenreId: 5464,
      x: 3757440,
      y: -6974130
    },
    {
      nanogenreId: 5465,
      x: 1677870,
      y: -8213220
    },
    {
      nanogenreId: 5466,
      x: -8587110,
      y: 2561610
    },
    {
      nanogenreId: 5467,
      x: 3365010,
      y: 9072240
    },
    {
      nanogenreId: 5468,
      x: 6266520,
      y: 6816540
    },
    {
      nanogenreId: 5469,
      x: -3358830,
      y: 8015460
    },
    {
      nanogenreId: 5470,
      x: 4418700,
      y: 8948640
    },
    {
      nanogenreId: 5471,
      x: -1622250,
      y: 2577060
    },
    {
      nanogenreId: 5472,
      x: 7932030,
      y: -7517970
    },
    {
      nanogenreId: 5473,
      x: -10515270,
      y: -2363850
    },
    {
      nanogenreId: 5474,
      x: -7703370,
      y: 3315570
    },
    {
      nanogenreId: 5475,
      x: 5833920,
      y: -4619550
    },
    {
      nanogenreId: 5476,
      x: -5268450,
      y: -1384320
    },
    {
      nanogenreId: 5477,
      x: -1498650,
      y: -1192740
    },
    {
      nanogenreId: 5478,
      x: -7490160,
      y: 6396300
    },
    {
      nanogenreId: 5479,
      x: 1585170,
      y: -11992290
    },
    {
      nanogenreId: 5480,
      x: -2777910,
      y: 1928160
    },
    {
      nanogenreId: 5481,
      x: -1909620,
      y: 8231760
    },
    {
      nanogenreId: 5482,
      x: -16624200,
      y: 855930
    },
    {
      nanogenreId: 5483,
      x: 5750490,
      y: -3683280
    },
    {
      nanogenreId: 5484,
      x: 8460420,
      y: -7382010
    },
    {
      nanogenreId: 5485,
      x: -3986100,
      y: -5605260
    },
    {
      nanogenreId: 5486,
      x: -2122830,
      y: -1477020
    },
    {
      nanogenreId: 5487,
      x: 5775210,
      y: 8540760
    },
    {
      nanogenreId: 5488,
      x: -3670920,
      y: 6207810
    },
    {
      nanogenreId: 5489,
      x: -1504830,
      y: 8451150
    },
    {
      nanogenreId: 5490,
      x: 3643110,
      y: 7743540
    },
    {
      nanogenreId: 5491,
      x: -2373120,
      y: -723060
    },
    {
      nanogenreId: 5492,
      x: 2051760,
      y: -3031290
    },
    {
      nanogenreId: 5493,
      x: 3145620,
      y: 5317890
    },
    {
      nanogenreId: 5494,
      x: 2515260,
      y: -5503290
    },
    {
      nanogenreId: 5495,
      x: 4140600,
      y: 11664750
    },
    {
      nanogenreId: 5496,
      x: 241020,
      y: -4063350
    },
    {
      nanogenreId: 5497,
      x: -6522990,
      y: -3479340
    },
    {
      nanogenreId: 5498,
      x: 2638860,
      y: 9928170
    },
    {
      nanogenreId: 5499,
      x: 5327160,
      y: -5521830
    },
    {
      nanogenreId: 5500,
      x: 12282750,
      y: -4888380
    },
    {
      nanogenreId: 5501,
      x: -438780,
      y: 185400
    },
    {
      nanogenreId: 5502,
      x: 12449610,
      y: 10518360
    },
    {
      nanogenreId: 5503,
      x: -1980690,
      y: 2774820
    },
    {
      nanogenreId: 5504,
      x: 716880,
      y: -64890
    },
    {
      nanogenreId: 5505,
      x: -667440,
      y: -4233300
    },
    {
      nanogenreId: 5506,
      x: -6210900,
      y: 655080
    },
    {
      nanogenreId: 5507,
      x: -4363080,
      y: 5688690
    },
    {
      nanogenreId: 5508,
      x: 12298200,
      y: -1047510
    },
    {
      nanogenreId: 5509,
      x: 5367330,
      y: 3476250
    },
    {
      nanogenreId: 5510,
      x: 333720,
      y: -2898420
    },
    {
      nanogenreId: 5511,
      x: 4702980,
      y: 4789500
    },
    {
      nanogenreId: 5512,
      x: 5444580,
      y: -8889930
    },
    {
      nanogenreId: 5513,
      x: 6912330,
      y: -6495180
    },
    {
      nanogenreId: 5514,
      x: 4529940,
      y: -6108930
    },
    {
      nanogenreId: 5515,
      x: 13426050,
      y: -401700
    },
    {
      nanogenreId: 5516,
      x: 2969490,
      y: -2163000
    },
    {
      nanogenreId: 5517,
      x: 6000780,
      y: -6405570
    },
    {
      nanogenreId: 5518,
      x: 1177290,
      y: 4863660
    },
    {
      nanogenreId: 5519,
      x: 682890,
      y: -605640
    },
    {
      nanogenreId: 5520,
      x: 4665900,
      y: -4279650
    },
    {
      nanogenreId: 5521,
      x: -8256480,
      y: -1025880
    },
    {
      nanogenreId: 5522,
      x: 1276170,
      y: 3989190
    },
    {
      nanogenreId: 5523,
      x: -2753190,
      y: -2530710
    },
    {
      nanogenreId: 5524,
      x: -6770190,
      y: -8896110
    },
    {
      nanogenreId: 5525,
      x: 16089630,
      y: 14553900
    },
    {
      nanogenreId: 5526,
      x: 1575900,
      y: 8760150
    },
    {
      nanogenreId: 5527,
      x: -2023950,
      y: 5045970
    },
    {
      nanogenreId: 5528,
      x: -5855550,
      y: 13938990
    },
    {
      nanogenreId: 5529,
      x: -2632680,
      y: -3714180
    },
    {
      nanogenreId: 5530,
      x: -3148710,
      y: -2864430
    },
    {
      nanogenreId: 5531,
      x: 3967560,
      y: 9140220
    },
    {
      nanogenreId: 5532,
      x: -6257250,
      y: -5203560
    },
    {
      nanogenreId: 5533,
      x: 763230,
      y: 6995760
    },
    {
      nanogenreId: 5534,
      x: -12950190,
      y: -5837010
    },
    {
      nanogenreId: 5535,
      x: -3170340,
      y: -1690230
    },
    {
      nanogenreId: 5536,
      x: 3683280,
      y: 7119360
    },
    {
      nanogenreId: 5537,
      x: 11606040,
      y: -3049830
    },
    {
      nanogenreId: 5538,
      x: 5061420,
      y: -10614150
    },
    {
      nanogenreId: 5539,
      x: 3723450,
      y: 262650
    },
    {
      nanogenreId: 5540,
      x: -13608360,
      y: -6365400
    },
    {
      nanogenreId: 5541,
      x: 5460030,
      y: 10932420
    },
    {
      nanogenreId: 5542,
      x: -7935120,
      y: 380070
    },
    {
      nanogenreId: 5543,
      x: 4106610,
      y: 18651240
    },
    {
      nanogenreId: 5544,
      x: 6621870,
      y: 1480110
    },
    {
      nanogenreId: 5545,
      x: 10496730,
      y: 2227890
    },
    {
      nanogenreId: 5546,
      x: 4770960,
      y: 9813840
    },
    {
      nanogenreId: 5547,
      x: -2679030,
      y: -9112410
    },
    {
      nanogenreId: 5548,
      x: 404790,
      y: 4551570
    },
    {
      nanogenreId: 5549,
      x: -6328320,
      y: 4440330
    },
    {
      nanogenreId: 5550,
      x: 11986110,
      y: -7517970
    },
    {
      nanogenreId: 5551,
      x: 9606810,
      y: -7434540
    },
    {
      nanogenreId: 5552,
      x: -7069920,
      y: 11636940
    },
    {
      nanogenreId: 5553,
      x: -13969890,
      y: 5228280
    },
    {
      nanogenreId: 5554,
      x: 6853620,
      y: 6501360
    },
    {
      nanogenreId: 5555,
      x: -2601780,
      y: -6587880
    },
    {
      nanogenreId: 5556,
      x: -11862510,
      y: -6238710
    },
    {
      nanogenreId: 5557,
      x: -3368100,
      y: 2598690
    },
    {
      nanogenreId: 5558,
      x: -2332950,
      y: -6510630
    },
    {
      nanogenreId: 5559,
      x: -3970650,
      y: -14733120
    },
    {
      nanogenreId: 5560,
      x: 15369660,
      y: 15088470
    },
    {
      nanogenreId: 5561,
      x: 5259180,
      y: 10468920
    },
    {
      nanogenreId: 5562,
      x: 7740450,
      y: -6519900
    },
    {
      nanogenreId: 5563,
      x: 1752030,
      y: -9028980
    },
    {
      nanogenreId: 5564,
      x: 2147550,
      y: 18830460
    },
    {
      nanogenreId: 5565,
      x: -3791430,
      y: 4811130
    },
    {
      nanogenreId: 5566,
      x: 8438790,
      y: 19303230
    },
    {
      nanogenreId: 5567,
      x: -1962150,
      y: 10512180
    },
    {
      nanogenreId: 5568,
      x: 8095800,
      y: 7453080
    },
    {
      nanogenreId: 5569,
      x: -3399000,
      y: 163770
    },
    {
      nanogenreId: 5570,
      x: 1356510,
      y: -6930870
    },
    {
      nanogenreId: 5571,
      x: 12644280,
      y: 6068760
    },
    {
      nanogenreId: 5572,
      x: 834300,
      y: -11850150
    },
    {
      nanogenreId: 5573,
      x: 534570,
      y: -2376210
    },
    {
      nanogenreId: 5574,
      x: 7721910,
      y: -1044420
    },
    {
      nanogenreId: 5575,
      x: 8083440,
      y: -4876020
    },
    {
      nanogenreId: 5576,
      x: -979530,
      y: -9341070
    },
    {
      nanogenreId: 5577,
      x: 9690240,
      y: -5036700
    },
    {
      nanogenreId: 5578,
      x: -2957130,
      y: -7351110
    },
    {
      nanogenreId: 5579,
      x: -2876790,
      y: 4687530
    },
    {
      nanogenreId: 5580,
      x: -636540,
      y: -5203560
    },
    {
      nanogenreId: 5581,
      x: -12582480,
      y: -6535350
    },
    {
      nanogenreId: 5582,
      x: -5895720,
      y: 1557360
    },
    {
      nanogenreId: 5583,
      x: -1656240,
      y: -7292400
    },
    {
      nanogenreId: 5584,
      x: -1931250,
      y: -8355360
    },
    {
      nanogenreId: 5585,
      x: -5867910,
      y: -5154120
    },
    {
      nanogenreId: 5586,
      x: -9789120,
      y: -4384710
    },
    {
      nanogenreId: 5587,
      x: -8126700,
      y: -4832760
    },
    {
      nanogenreId: 5588,
      x: 11692560,
      y: -6720750
    },
    {
      nanogenreId: 5589,
      x: -4696800,
      y: 11043660
    },
    {
      nanogenreId: 5590,
      x: -1684050,
      y: -15459270
    },
    {
      nanogenreId: 5591,
      x: 10709940,
      y: 2027040
    },
    {
      nanogenreId: 5592,
      x: 2450370,
      y: 3287760
    },
    {
      nanogenreId: 5593,
      x: -18540,
      y: 3377370
    },
    {
      nanogenreId: 5594,
      x: 8667450,
      y: 5719590
    },
    {
      nanogenreId: 5595,
      x: 11065290,
      y: 11961390
    },
    {
      nanogenreId: 5596,
      x: 7137900,
      y: 4937820
    },
    {
      nanogenreId: 5597,
      x: -8744700,
      y: 3423720
    },
    {
      nanogenreId: 5598,
      x: 5911170,
      y: 8584020
    },
    {
      nanogenreId: 5599,
      x: 6312870,
      y: 9857100
    },
    {
      nanogenreId: 5600,
      x: 8444970,
      y: -7783710
    },
    {
      nanogenreId: 5601,
      x: -3596760,
      y: -7576680
    },
    {
      nanogenreId: 5602,
      x: 7811520,
      y: 4106610
    },
    {
      nanogenreId: 5603,
      x: 256470,
      y: 9260730
    },
    {
      nanogenreId: 5604,
      x: 7857870,
      y: -8037090
    },
    {
      nanogenreId: 5605,
      x: -4829670,
      y: -7326390
    },
    {
      nanogenreId: 5606,
      x: -6241800,
      y: 7286220
    },
    {
      nanogenreId: 5607,
      x: 7694100,
      y: -6952500
    },
    {
      nanogenreId: 5608,
      x: 14226360,
      y: 8475870
    },
    {
      nanogenreId: 5609,
      x: 800310,
      y: -5416770
    },
    {
      nanogenreId: 5610,
      x: 6572430,
      y: 7122450
    },
    {
      nanogenreId: 5611,
      x: 828120,
      y: -7042110
    },
    {
      nanogenreId: 5612,
      x: 4026270,
      y: -1158750
    },
    {
      nanogenreId: 5613,
      x: 3090,
      y: 6890700
    },
    {
      nanogenreId: 5614,
      x: -1149480,
      y: 4597920
    },
    {
      nanogenreId: 5615,
      x: 2431830,
      y: -3355740
    },
    {
      nanogenreId: 5616,
      x: 5370420,
      y: 11173440
    },
    {
      nanogenreId: 5617,
      x: -2573970,
      y: -5747400
    },
    {
      nanogenreId: 5618,
      x: 5225190,
      y: -3105450
    },
    {
      nanogenreId: 5619,
      x: -1640790,
      y: 14547720
    },
    {
      nanogenreId: 5620,
      x: -1782930,
      y: -7508700
    },
    {
      nanogenreId: 5621,
      x: -3525690,
      y: 4888380
    },
    {
      nanogenreId: 5622,
      x: -7740450,
      y: 7802250
    },
    {
      nanogenreId: 5623,
      x: 5713410,
      y: 17371980
    },
    {
      nanogenreId: 5624,
      x: 4186950,
      y: 8135970
    },
    {
      nanogenreId: 5625,
      x: 5815380,
      y: 10073400
    },
    {
      nanogenreId: 5626,
      x: -5320980,
      y: 5781390
    },
    {
      nanogenreId: 5627,
      x: 7026660,
      y: -4653540
    },
    {
      nanogenreId: 5628,
      x: -4619550,
      y: -10722300
    },
    {
      nanogenreId: 5629,
      x: 10855170,
      y: -5923530
    },
    {
      nanogenreId: 5630,
      x: -2166090,
      y: -5738130
    },
    {
      nanogenreId: 5631,
      x: 4891470,
      y: -6835080
    },
    {
      nanogenreId: 5632,
      x: -1680960,
      y: 6402480
    },
    {
      nanogenreId: 5633,
      x: -5045970,
      y: -9461580
    },
    {
      nanogenreId: 5634,
      x: 318270,
      y: 16516050
    },
    {
      nanogenreId: 5635,
      x: 6937050,
      y: -5531100
    },
    {
      nanogenreId: 5636,
      x: -1946700,
      y: 7725000
    },
    {
      nanogenreId: 5637,
      x: -614910,
      y: 8927010
    },
    {
      nanogenreId: 5638,
      x: 1477020,
      y: -9421410
    },
    {
      nanogenreId: 5639,
      x: 8747790,
      y: -7527240
    },
    {
      nanogenreId: 5640,
      x: -9044430,
      y: -1424490
    },
    {
      nanogenreId: 5641,
      x: 12202410,
      y: -5592900
    },
    {
      nanogenreId: 5642,
      x: 1007340,
      y: 6331410
    },
    {
      nanogenreId: 5643,
      x: 1013520,
      y: -5790660
    },
    {
      nanogenreId: 5644,
      x: 9365790,
      y: -6455010
    },
    {
      nanogenreId: 5645,
      x: 7443810,
      y: -6791820
    },
    {
      nanogenreId: 5646,
      x: 10036320,
      y: 2558520
    },
    {
      nanogenreId: 5647,
      x: 8855940,
      y: -6439560
    },
    {
      nanogenreId: 5648,
      x: 6084210,
      y: -4931640
    },
    {
      nanogenreId: 5649,
      x: 5555820,
      y: 2441100
    },
    {
      nanogenreId: 5650,
      x: 4567020,
      y: -7635390
    },
    {
      nanogenreId: 5651,
      x: 5098500,
      y: 9409050
    },
    {
      nanogenreId: 5652,
      x: -37080,
      y: -4780230
    },
    {
      nanogenreId: 5653,
      x: -1813830,
      y: -4916190
    },
    {
      nanogenreId: 5654,
      x: -1742760,
      y: -3136350
    },
    {
      nanogenreId: 5655,
      x: 438780,
      y: -5253000
    },
    {
      nanogenreId: 5656,
      x: 4860570,
      y: 7267680
    },
    {
      nanogenreId: 5657,
      x: 12684450,
      y: 6887610
    },
    {
      nanogenreId: 5658,
      x: 4279650,
      y: -1940520
    },
    {
      nanogenreId: 5659,
      x: 8652000,
      y: -6699120
    },
    {
      nanogenreId: 5660,
      x: -13531110,
      y: -2573970
    },
    {
      nanogenreId: 5661,
      x: -2886060,
      y: 3497880
    },
    {
      nanogenreId: 5662,
      x: -321360,
      y: -8268840
    },
    {
      nanogenreId: 5663,
      x: 3794520,
      y: -5024340
    },
    {
      nanogenreId: 5664,
      x: -2079570,
      y: 9829290
    },
    {
      nanogenreId: 5665,
      x: -3315570,
      y: -9127860
    },
    {
      nanogenreId: 5666,
      x: -5935890,
      y: 4366170
    },
    {
      nanogenreId: 5667,
      x: 4270380,
      y: -6281970
    },
    {
      nanogenreId: 5668,
      x: 5203560,
      y: -1118580
    },
    {
      nanogenreId: 5669,
      x: -5005800,
      y: 1634610
    },
    {
      nanogenreId: 5670,
      x: -8945550,
      y: 5750490
    },
    {
      nanogenreId: 5671,
      x: -5413680,
      y: 6912330
    },
    {
      nanogenreId: 5672,
      x: -169950,
      y: -14946330
    },
    {
      nanogenreId: 5673,
      x: -1909620,
      y: -7925850
    },
    {
      nanogenreId: 5674,
      x: -3847050,
      y: -3992280
    },
    {
      nanogenreId: 5675,
      x: -2373120,
      y: -5769030
    },
    {
      nanogenreId: 5676,
      x: 6213990,
      y: 9498660
    },
    {
      nanogenreId: 5677,
      x: -6090390,
      y: -11513340
    },
    {
      nanogenreId: 5678,
      x: 4529940,
      y: 9421410
    },
    {
      nanogenreId: 5679,
      x: -2818080,
      y: -9161850
    },
    {
      nanogenreId: 5680,
      x: -2963310,
      y: 1804560
    },
    {
      nanogenreId: 5681,
      x: -5935890,
      y: 5052150
    },
    {
      nanogenreId: 5682,
      x: -333720,
      y: 11358840
    },
    {
      nanogenreId: 5683,
      x: 11383560,
      y: 11222880
    },
    {
      nanogenreId: 5684,
      x: -9270,
      y: -6717660
    },
    {
      nanogenreId: 5685,
      x: 3942840,
      y: 18521460
    },
    {
      nanogenreId: 5686,
      x: -13432230,
      y: -5651610
    },
    {
      nanogenreId: 5687,
      x: 3510240,
      y: 7304760
    },
    {
      nanogenreId: 5688,
      x: 927000,
      y: -3127080
    },
    {
      nanogenreId: 5689,
      x: -4434150,
      y: 6884520
    },
    {
      nanogenreId: 5690,
      x: 3250680,
      y: 9520290
    },
    {
      nanogenreId: 5691,
      x: 8543850,
      y: 7882590
    },
    {
      nanogenreId: 5692,
      x: -2947860,
      y: 8825040
    },
    {
      nanogenreId: 5693,
      x: 8540760,
      y: -8225580
    },
    {
      nanogenreId: 5694,
      x: 4601010,
      y: 7227510
    },
    {
      nanogenreId: 5695,
      x: -3448440,
      y: -8954820
    },
    {
      nanogenreId: 5696,
      x: 6736200,
      y: 10203180
    },
    {
      nanogenreId: 5697,
      x: 3565860,
      y: 7184250
    },
    {
      nanogenreId: 5698,
      x: 1585170,
      y: 10326780
    },
    {
      nanogenreId: 5699,
      x: 10725390,
      y: -3553500
    },
    {
      nanogenreId: 5700,
      x: 4390890,
      y: 8679810
    },
    {
      nanogenreId: 5701,
      x: 2580150,
      y: 1337970
    },
    {
      nanogenreId: 5702,
      x: -3955200,
      y: 12023190
    },
    {
      nanogenreId: 5703,
      x: 2629590,
      y: 5979150
    },
    {
      nanogenreId: 5704,
      x: -10728480,
      y: -1464660
    },
    {
      nanogenreId: 5705,
      x: 4301280,
      y: 9402870
    },
    {
      nanogenreId: 5706,
      x: 9365790,
      y: -7190430
    },
    {
      nanogenreId: 5707,
      x: -5871000,
      y: 8420250
    },
    {
      nanogenreId: 5708,
      x: -9229830,
      y: -6433380
    },
    {
      nanogenreId: 5709,
      x: -4292010,
      y: 6708390
    },
    {
      nanogenreId: 5710,
      x: 10450380,
      y: -2824260
    },
    {
      nanogenreId: 5711,
      x: -262650,
      y: -3025110
    },
    {
      nanogenreId: 5712,
      x: 6887610,
      y: -417150
    },
    {
      nanogenreId: 5713,
      x: -244110,
      y: 4533030
    },
    {
      nanogenreId: 5714,
      x: 7626120,
      y: -6696030
    },
    {
      nanogenreId: 5715,
      x: -2524530,
      y: -5351880
    },
    {
      nanogenreId: 5716,
      x: -3278490,
      y: 2187720
    },
    {
      nanogenreId: 5717,
      x: 1786020,
      y: -5429130
    },
    {
      nanogenreId: 5718,
      x: 3229050,
      y: 3510240
    },
    {
      nanogenreId: 5719,
      x: 692160,
      y: -8309010
    },
    {
      nanogenreId: 5720,
      x: 6831990,
      y: -6711480
    },
    {
      nanogenreId: 5721,
      x: 10607970,
      y: 4220940
    },
    {
      nanogenreId: 5722,
      x: -1341060,
      y: 973350
    },
    {
      nanogenreId: 5723,
      x: 3028200,
      y: 5750490
    },
    {
      nanogenreId: 5724,
      x: -5076870,
      y: 7224420
    },
    {
      nanogenreId: 5725,
      x: -1903440,
      y: 3868680
    },
    {
      nanogenreId: 5726,
      x: -599460,
      y: -6232530
    },
    {
      nanogenreId: 5727,
      x: -6467370,
      y: -13361160
    },
    {
      nanogenreId: 5728,
      x: 3117810,
      y: 7224420
    },
    {
      nanogenreId: 5729,
      x: 4545390,
      y: 8355360
    },
    {
      nanogenreId: 5730,
      x: -7681740,
      y: 4245660
    },
    {
      nanogenreId: 5731,
      x: -1810740,
      y: -6053310
    },
    {
      nanogenreId: 5732,
      x: 1619160,
      y: 10200090
    },
    {
      nanogenreId: 5733,
      x: 3711090,
      y: -3374280
    },
    {
      nanogenreId: 5734,
      x: 3466980,
      y: -4795680
    },
    {
      nanogenreId: 5735,
      x: 3436080,
      y: -834300
    },
    {
      nanogenreId: 5736,
      x: 3445350,
      y: -5113950
    },
    {
      nanogenreId: 5737,
      x: -540750,
      y: 5398230
    },
    {
      nanogenreId: 5738,
      x: 12066450,
      y: 3120900
    },
    {
      nanogenreId: 5739,
      x: 16083450,
      y: 5756670
    },
    {
      nanogenreId: 5740,
      x: -4869840,
      y: 6343770
    },
    {
      nanogenreId: 5741,
      x: -778680,
      y: 8494410
    },
    {
      nanogenreId: 5742,
      x: 8231760,
      y: -8852850
    },
    {
      nanogenreId: 5743,
      x: -902280,
      y: 12545400
    },
    {
      nanogenreId: 5744,
      x: 2014680,
      y: -9371970
    },
    {
      nanogenreId: 5745,
      x: 3090,
      y: -5877180
    },
    {
      nanogenreId: 5746,
      x: 4665900,
      y: 6411750
    },
    {
      nanogenreId: 5747,
      x: -2830440,
      y: 9183480
    },
    {
      nanogenreId: 5748,
      x: -2552340,
      y: 5969880
    },
    {
      nanogenreId: 5749,
      x: -4693710,
      y: 2997300
    },
    {
      nanogenreId: 5750,
      x: 10400940,
      y: 11618400
    },
    {
      nanogenreId: 5751,
      x: -2246430,
      y: 5954430
    },
    {
      nanogenreId: 5752,
      x: 14600250,
      y: -497490
    },
    {
      nanogenreId: 5753,
      x: -3745080,
      y: 5620710
    },
    {
      nanogenreId: 5754,
      x: -1850910,
      y: 633450
    },
    {
      nanogenreId: 5755,
      x: -3241410,
      y: -2740830
    },
    {
      nanogenreId: 5756,
      x: -10991130,
      y: 8988810
    },
    {
      nanogenreId: 5757,
      x: 203940,
      y: 7666290
    },
    {
      nanogenreId: 5758,
      x: -979530,
      y: -5685600
    },
    {
      nanogenreId: 5759,
      x: 4131330,
      y: 1517190
    },
    {
      nanogenreId: 5760,
      x: 8086530,
      y: -5169570
    },
    {
      nanogenreId: 5761,
      x: 1297800,
      y: 12659730
    },
    {
      nanogenreId: 5762,
      x: 1344150,
      y: 8590200
    },
    {
      nanogenreId: 5763,
      x: 10379310,
      y: -6921600
    },
    {
      nanogenreId: 5764,
      x: -3046740,
      y: -13265370
    },
    {
      nanogenreId: 5765,
      x: 13079970,
      y: -3794520
    },
    {
      nanogenreId: 5766,
      x: -8148330,
      y: 5580540
    },
    {
      nanogenreId: 5767,
      x: -4554660,
      y: 8775600
    },
    {
      nanogenreId: 5768,
      x: -1897260,
      y: 3018930
    },
    {
      nanogenreId: 5769,
      x: -3111630,
      y: 769410
    },
    {
      nanogenreId: 5770,
      x: 7190430,
      y: -5265360
    },
    {
      nanogenreId: 5771,
      x: 1968330,
      y: -4869840
    },
    {
      nanogenreId: 5772,
      x: -4217850,
      y: 1100040
    },
    {
      nanogenreId: 5773,
      x: 7601400,
      y: -5741220
    },
    {
      nanogenreId: 5774,
      x: 2005410,
      y: 6643500
    },
    {
      nanogenreId: 5775,
      x: -15150270,
      y: -5972970
    },
    {
      nanogenreId: 5776,
      x: -13351890,
      y: 1022790
    },
    {
      nanogenreId: 5777,
      x: 4217850,
      y: 8312100
    },
    {
      nanogenreId: 5778,
      x: 1724220,
      y: -3473160
    },
    {
      nanogenreId: 5779,
      x: -8673630,
      y: -3769800
    },
    {
      nanogenreId: 5780,
      x: 2057940,
      y: 9918900
    },
    {
      nanogenreId: 5781,
      x: 9202020,
      y: -7264590
    },
    {
      nanogenreId: 5782,
      x: -2107380,
      y: -2502900
    },
    {
      nanogenreId: 5783,
      x: -985710,
      y: -10193910
    },
    {
      nanogenreId: 5784,
      x: -12653550,
      y: -6940140
    },
    {
      nanogenreId: 5785,
      x: -145230,
      y: 247200
    },
    {
      nanogenreId: 5786,
      x: 13280820,
      y: 6275790
    },
    {
      nanogenreId: 5787,
      x: 5163390,
      y: -8244120
    },
    {
      nanogenreId: 5788,
      x: -4570110,
      y: -1066050
    },
    {
      nanogenreId: 5789,
      x: 1109310,
      y: 5707230
    },
    {
      nanogenreId: 5790,
      x: -5867910,
      y: 3809970
    },
    {
      nanogenreId: 5791,
      x: -14553900,
      y: 6121290
    },
    {
      nanogenreId: 5792,
      x: -12013920,
      y: -5027430
    },
    {
      nanogenreId: 5793,
      x: 4981080,
      y: -5373510
    },
    {
      nanogenreId: 5794,
      x: 6309780,
      y: 7032840
    },
    {
      nanogenreId: 5795,
      x: -5144850,
      y: -8763240
    },
    {
      nanogenreId: 5796,
      x: 3809970,
      y: 2583240
    },
    {
      nanogenreId: 5797,
      x: 2073390,
      y: 6244890
    },
    {
      nanogenreId: 5798,
      x: 6084210,
      y: -10855170
    },
    {
      nanogenreId: 5799,
      x: 4783320,
      y: 5141760
    },
    {
      nanogenreId: 5800,
      x: 5070690,
      y: 1365780
    },
    {
      nanogenreId: 5801,
      x: -2753190,
      y: 1554270
    },
    {
      nanogenreId: 5802,
      x: 682890,
      y: 7400550
    },
    {
      nanogenreId: 5803,
      x: -1650060,
      y: 8750880
    },
    {
      nanogenreId: 5804,
      x: -1378140,
      y: -8460420
    },
    {
      nanogenreId: 5805,
      x: -3865590,
      y: 3497880
    },
    {
      nanogenreId: 5806,
      x: 12928560,
      y: -1980690
    },
    {
      nanogenreId: 5807,
      x: 37080,
      y: 5877180
    },
    {
      nanogenreId: 5808,
      x: 5626890,
      y: 10246440
    },
    {
      nanogenreId: 5809,
      x: 5225190,
      y: 8111250
    },
    {
      nanogenreId: 5810,
      x: 6714570,
      y: -3843960
    },
    {
      nanogenreId: 5811,
      x: -8077260,
      y: 2889150
    },
    {
      nanogenreId: 5812,
      x: -9755130,
      y: 5382780
    },
    {
      nanogenreId: 5813,
      x: -6016230,
      y: 1962150
    },
    {
      nanogenreId: 5814,
      x: -2536890,
      y: 4400160
    },
    {
      nanogenreId: 5815,
      x: -7360380,
      y: -14421030
    },
    {
      nanogenreId: 5816,
      x: -7947480,
      y: -2521440
    },
    {
      nanogenreId: 5817,
      x: -336810,
      y: -7270770
    },
    {
      nanogenreId: 5818,
      x: 12669000,
      y: -3992280
    },
    {
      nanogenreId: 5819,
      x: 2824260,
      y: -3729630
    },
    {
      nanogenreId: 5820,
      x: 2682120,
      y: -8361540
    },
    {
      nanogenreId: 5821,
      x: 3358830,
      y: -5951340
    },
    {
      nanogenreId: 5822,
      x: 2645040,
      y: 4465050
    },
    {
      nanogenreId: 5823,
      x: -1866360,
      y: 5673240
    },
    {
      nanogenreId: 5824,
      x: -800310,
      y: -6034770
    },
    {
      nanogenreId: 5825,
      x: -3862500,
      y: 6940140
    },
    {
      nanogenreId: 5826,
      x: 5531100,
      y: -7079190
    },
    {
      nanogenreId: 5827,
      x: -1798380,
      y: -4431060
    },
    {
      nanogenreId: 5828,
      x: 5960610,
      y: -3890310
    },
    {
      nanogenreId: 5829,
      x: -2283510,
      y: 11244510
    },
    {
      nanogenreId: 5830,
      x: 9047520,
      y: -8491320
    },
    {
      nanogenreId: 5831,
      x: 6223260,
      y: 2907690
    },
    {
      nanogenreId: 5832,
      x: 8710710,
      y: 7446900
    },
    {
      nanogenreId: 5833,
      x: -5738130,
      y: -4631910
    },
    {
      nanogenreId: 5834,
      x: 2385480,
      y: 6167640
    },
    {
      nanogenreId: 5835,
      x: 5512560,
      y: 9375060
    },
    {
      nanogenreId: 5836,
      x: -2546160,
      y: 6757830
    },
    {
      nanogenreId: 5837,
      x: 5104680,
      y: 11127090
    },
    {
      nanogenreId: 5838,
      x: 4631910,
      y: 8871390
    },
    {
      nanogenreId: 5839,
      x: -886830,
      y: -4699890
    },
    {
      nanogenreId: 5840,
      x: 10376220,
      y: -4208580
    },
    {
      nanogenreId: 5841,
      x: -145230,
      y: -7953660
    },
    {
      nanogenreId: 5842,
      x: -1572810,
      y: -10790280
    },
    {
      nanogenreId: 5843,
      x: 3745080,
      y: 5929710
    },
    {
      nanogenreId: 5844,
      x: -9983790,
      y: -9952890
    },
    {
      nanogenreId: 5845,
      x: 8080350,
      y: -7233690
    },
    {
      nanogenreId: 5846,
      x: 7783710,
      y: -4848210
    },
    {
      nanogenreId: 5847,
      x: 4795680,
      y: 3278490
    },
    {
      nanogenreId: 5848,
      x: 7691010,
      y: -9143310
    },
    {
      nanogenreId: 5849,
      x: -2611050,
      y: 15443820
    },
    {
      nanogenreId: 5850,
      x: 982620,
      y: 4993440
    },
    {
      nanogenreId: 5851,
      x: 1640790,
      y: 11287770
    },
    {
      nanogenreId: 5852,
      x: 1989960,
      y: -5784480
    },
    {
      nanogenreId: 5853,
      x: -8299740,
      y: -10700670
    },
    {
      nanogenreId: 5854,
      x: 7907310,
      y: -2675940
    },
    {
      nanogenreId: 5855,
      x: -9875640,
      y: -5092320
    },
    {
      nanogenreId: 5856,
      x: 5790660,
      y: 8352270
    },
    {
      nanogenreId: 5857,
      x: 2082660,
      y: 4820400
    },
    {
      nanogenreId: 5858,
      x: 5413680,
      y: 10379310
    },
    {
      nanogenreId: 5859,
      x: -3000390,
      y: 4736970
    },
    {
      nanogenreId: 5860,
      x: 4971810,
      y: 10336050
    },
    {
      nanogenreId: 5861,
      x: -5744310,
      y: -8825040
    },
    {
      nanogenreId: 5862,
      x: 3874860,
      y: -5849370
    },
    {
      nanogenreId: 5863,
      x: 10774830,
      y: -7406730
    },
    {
      nanogenreId: 5864,
      x: 7901130,
      y: -8330640
    },
    {
      nanogenreId: 5865,
      x: 11015850,
      y: -6065670
    },
    {
      nanogenreId: 5866,
      x: -5509470,
      y: 12804960
    },
    {
      nanogenreId: 5867,
      x: 6964860,
      y: -9891090
    },
    {
      nanogenreId: 5868,
      x: -1273080,
      y: 9477030
    },
    {
      nanogenreId: 5869,
      x: -2580150,
      y: -1514100
    },
    {
      nanogenreId: 5870,
      x: 4743150,
      y: 10848990
    },
    {
      nanogenreId: 5871,
      x: -3355740,
      y: 494400
    },
    {
      nanogenreId: 5872,
      x: -4709160,
      y: 9359610
    },
    {
      nanogenreId: 5873,
      x: 3995370,
      y: 5064510
    },
    {
      nanogenreId: 5874,
      x: -2465820,
      y: 2669760
    },
    {
      nanogenreId: 5875,
      x: 1501740,
      y: -9742770
    },
    {
      nanogenreId: 5876,
      x: 5858640,
      y: -6078030
    },
    {
      nanogenreId: 5877,
      x: 784860,
      y: 10150650
    },
    {
      nanogenreId: 5878,
      x: -4307460,
      y: 9239100
    },
    {
      nanogenreId: 5879,
      x: -5320980,
      y: 6025500
    },
    {
      nanogenreId: 5880,
      x: 8605650,
      y: 18422580
    },
    {
      nanogenreId: 5881,
      x: -11775990,
      y: 2385480
    },
    {
      nanogenreId: 5882,
      x: 4801860,
      y: 6501360
    },
    {
      nanogenreId: 5883,
      x: -4261110,
      y: 6844350
    },
    {
      nanogenreId: 5884,
      x: 8067990,
      y: -8296650
    },
    {
      nanogenreId: 5885,
      x: -4248750,
      y: -1761300
    },
    {
      nanogenreId: 5886,
      x: 3284670,
      y: -2326770
    },
    {
      nanogenreId: 5887,
      x: -4749330,
      y: 4962540
    },
    {
      nanogenreId: 5888,
      x: -1891080,
      y: -6343770
    },
    {
      nanogenreId: 5889,
      x: -1174200,
      y: 2462730
    },
    {
      nanogenreId: 5890,
      x: 8046360,
      y: 2632680
    },
    {
      nanogenreId: 5891,
      x: 812670,
      y: 5803020
    },
    {
      nanogenreId: 5892,
      x: -4526850,
      y: -7638480
    },
    {
      nanogenreId: 5893,
      x: -4644270,
      y: 4267290
    },
    {
      nanogenreId: 5894,
      x: -4035540,
      y: -9331800
    },
    {
      nanogenreId: 5895,
      x: -4177680,
      y: 2261880
    },
    {
      nanogenreId: 5896,
      x: -10181550,
      y: 6325230
    },
    {
      nanogenreId: 5897,
      x: -5796840,
      y: -4891470
    },
    {
      nanogenreId: 5898,
      x: 4826580,
      y: 8710710
    },
    {
      nanogenreId: 5899,
      x: -2064120,
      y: -9918900
    },
    {
      nanogenreId: 5900,
      x: 3979920,
      y: 8976450
    },
    {
      nanogenreId: 5901,
      x: -1714950,
      y: 4446510
    },
    {
      nanogenreId: 5902,
      x: -2311320,
      y: -4548480
    },
    {
      nanogenreId: 5903,
      x: -1996140,
      y: -5354970
    },
    {
      nanogenreId: 5904,
      x: -8652000,
      y: -9331800
    },
    {
      nanogenreId: 5905,
      x: -9038250,
      y: -4647360
    },
    {
      nanogenreId: 5906,
      x: 2944770,
      y: -6699120
    },
    {
      nanogenreId: 5907,
      x: 4134420,
      y: 8605650
    },
    {
      nanogenreId: 5908,
      x: 11782170,
      y: -3340290
    },
    {
      nanogenreId: 5909,
      x: -10975680,
      y: -3593670
    },
    {
      nanogenreId: 5910,
      x: 435690,
      y: -4483590
    },
    {
      nanogenreId: 5911,
      x: -1152570,
      y: -5821560
    },
    {
      nanogenreId: 5912,
      x: 4934730,
      y: -6340680
    },
    {
      nanogenreId: 5913,
      x: 4313640,
      y: -1303980
    },
    {
      nanogenreId: 5914,
      x: -13073790,
      y: -2277330
    },
    {
      nanogenreId: 5915,
      x: 10623420,
      y: 784860
    },
    {
      nanogenreId: 5916,
      x: -237930,
      y: 10320600
    },
    {
      nanogenreId: 5917,
      x: 7867140,
      y: -3216690
    },
    {
      nanogenreId: 5918,
      x: 5185020,
      y: 10657410
    },
    {
      nanogenreId: 5919,
      x: 6507540,
      y: -8129790
    },
    {
      nanogenreId: 5920,
      x: 3479340,
      y: 10703760
    },
    {
      nanogenreId: 5921,
      x: 15879510,
      y: -7564320
    },
    {
      nanogenreId: 5922,
      x: -11729640,
      y: 6078030
    },
    {
      nanogenreId: 5923,
      x: 21630,
      y: -2972580
    },
    {
      nanogenreId: 5924,
      x: 9477030,
      y: -6365400
    },
    {
      nanogenreId: 5925,
      x: -7635390,
      y: 7045200
    },
    {
      nanogenreId: 5926,
      x: 3983010,
      y: 16729260
    },
    {
      nanogenreId: 5927,
      x: -3726540,
      y: 2175360
    },
    {
      nanogenreId: 5928,
      x: -4492860,
      y: 3856320
    },
    {
      nanogenreId: 5929,
      x: 1489380,
      y: 7239870
    },
    {
      nanogenreId: 5930,
      x: -1189650,
      y: 8664360
    },
    {
      nanogenreId: 5931,
      x: -3788340,
      y: 6501360
    },
    {
      nanogenreId: 5932,
      x: 6594060,
      y: -5571270
    },
    {
      nanogenreId: 5933,
      x: 6899970,
      y: -7941300
    },
    {
      nanogenreId: 5934,
      x: 3865590,
      y: 6977220
    },
    {
      nanogenreId: 5935,
      x: -2660490,
      y: -4965630
    },
    {
      nanogenreId: 5936,
      x: -812670,
      y: 2709930
    },
    {
      nanogenreId: 5937,
      x: -4804950,
      y: -4736970
    },
    {
      nanogenreId: 5938,
      x: 8346090,
      y: -5339520
    },
    {
      nanogenreId: 5939,
      x: -14180010,
      y: -791040
    },
    {
      nanogenreId: 5940,
      x: -2246430,
      y: -9124770
    },
    {
      nanogenreId: 5941,
      x: -8324460,
      y: 4069530
    },
    {
      nanogenreId: 5942,
      x: -12585570,
      y: -2490540
    },
    {
      nanogenreId: 5943,
      x: 4149870,
      y: 6136740
    },
    {
      nanogenreId: 5944,
      x: -5095410,
      y: -7691010
    },
    {
      nanogenreId: 5945,
      x: 1915800,
      y: 7830060
    },
    {
      nanogenreId: 5946,
      x: 5725770,
      y: 9143310
    },
    {
      nanogenreId: 5947,
      x: 253380,
      y: -4613370
    },
    {
      nanogenreId: 5948,
      x: 5188110,
      y: 7286220
    },
    {
      nanogenreId: 5949,
      x: 6779460,
      y: 52530
    },
    {
      nanogenreId: 5950,
      x: 1792200,
      y: 1480110
    },
    {
      nanogenreId: 5951,
      x: -275010,
      y: -7715730
    },
    {
      nanogenreId: 5952,
      x: -108150,
      y: 5595990
    },
    {
      nanogenreId: 5953,
      x: 2614140,
      y: 5237550
    },
    {
      nanogenreId: 5954,
      x: -13586730,
      y: -3989190
    },
    {
      nanogenreId: 5955,
      x: 7981470,
      y: -7718820
    },
    {
      nanogenreId: 5956,
      x: 126690,
      y: 9093870
    },
    {
      nanogenreId: 5957,
      x: -7833150,
      y: -5206650
    },
    {
      nanogenreId: 5958,
      x: 4999620,
      y: 9591360
    },
    {
      nanogenreId: 5959,
      x: 9566640,
      y: -7728090
    },
    {
      nanogenreId: 5960,
      x: -2626500,
      y: -7589040
    },
    {
      nanogenreId: 5961,
      x: 5299350,
      y: 6380850
    },
    {
      nanogenreId: 5962,
      x: -886830,
      y: 6699120
    },
    {
      nanogenreId: 5963,
      x: 5617620,
      y: 10685220
    },
    {
      nanogenreId: 5964,
      x: -8883750,
      y: 2512170
    },
    {
      nanogenreId: 5965,
      x: -2230980,
      y: 10811910
    },
    {
      nanogenreId: 5966,
      x: -5917350,
      y: -5923530
    },
    {
      nanogenreId: 5967,
      x: 2184630,
      y: -6173820
    },
    {
      nanogenreId: 5968,
      x: 3392820,
      y: 9192750
    },
    {
      nanogenreId: 5969,
      x: -160680,
      y: 7626120
    },
    {
      nanogenreId: 5970,
      x: 5188110,
      y: 7091550
    },
    {
      nanogenreId: 5971,
      x: 3782160,
      y: 6575520
    },
    {
      nanogenreId: 5972,
      x: 7916580,
      y: -5110860
    },
    {
      nanogenreId: 5973,
      x: 4922370,
      y: 7403640
    },
    {
      nanogenreId: 5974,
      x: 1622250,
      y: 8216310
    },
    {
      nanogenreId: 5975,
      x: 8367720,
      y: -6674400
    },
    {
      nanogenreId: 5976,
      x: -1810740,
      y: 4557750
    },
    {
      nanogenreId: 5977,
      x: -3670920,
      y: 3136350
    },
    {
      nanogenreId: 5978,
      x: -3395910,
      y: 4041720
    },
    {
      nanogenreId: 5979,
      x: -5246820,
      y: 846660
    },
    {
      nanogenreId: 5980,
      x: 5141760,
      y: 9109320
    },
    {
      nanogenreId: 5981,
      x: 2920050,
      y: 9965250
    },
    {
      nanogenreId: 5982,
      x: 5614530,
      y: 10669770
    },
    {
      nanogenreId: 5983,
      x: 1578990,
      y: 1946700
    },
    {
      nanogenreId: 5984,
      x: 2286600,
      y: -2196990
    },
    {
      nanogenreId: 5985,
      x: -3395910,
      y: 3263040
    },
    {
      nanogenreId: 5986,
      x: 5744310,
      y: -7351110
    },
    {
      nanogenreId: 5987,
      x: -2620320,
      y: -769410
    },
    {
      nanogenreId: 5988,
      x: 6927780,
      y: -5988420
    },
    {
      nanogenreId: 5989,
      x: -2524530,
      y: -4591740
    },
    {
      nanogenreId: 5990,
      x: 6201630,
      y: 9050610
    },
    {
      nanogenreId: 5991,
      x: -4783320,
      y: 1807650
    },
    {
      nanogenreId: 5992,
      x: 9949800,
      y: -5595990
    },
    {
      nanogenreId: 5993,
      x: -2771730,
      y: -9764400
    },
    {
      nanogenreId: 5994,
      x: 5991510,
      y: 9498660
    },
    {
      nanogenreId: 5995,
      x: -10178460,
      y: -11723460
    },
    {
      nanogenreId: 5996,
      x: -4523760,
      y: 4910010
    },
    {
      nanogenreId: 5997,
      x: -4378530,
      y: -2193900
    },
    {
      nanogenreId: 5998,
      x: 2784090,
      y: -3930480
    },
    {
      nanogenreId: 5999,
      x: -244110,
      y: 7317120
    },
    {
      nanogenreId: 6000,
      x: 2150640,
      y: 7449990
    },
    {
      nanogenreId: 6001,
      x: 4125150,
      y: 367710
    },
    {
      nanogenreId: 6002,
      x: -1486290,
      y: -8633460
    },
    {
      nanogenreId: 6003,
      x: 3269220,
      y: 6658950
    },
    {
      nanogenreId: 6004,
      x: 4066440,
      y: -3213600
    },
    {
      nanogenreId: 6005,
      x: 4356900,
      y: -8108160
    },
    {
      nanogenreId: 6006,
      x: 2422560,
      y: 5960610
    },
    {
      nanogenreId: 6007,
      x: 271920,
      y: -3111630
    },
    {
      nanogenreId: 6008,
      x: 3510240,
      y: -2342220
    },
    {
      nanogenreId: 6009,
      x: 1356510,
      y: 10737750
    },
    {
      nanogenreId: 6010,
      x: 4792590,
      y: 3615300
    },
    {
      nanogenreId: 6011,
      x: 3108540,
      y: 10530720
    },
    {
      nanogenreId: 6012,
      x: 389340,
      y: -8435700
    },
    {
      nanogenreId: 6013,
      x: 5481660,
      y: 7765170
    },
    {
      nanogenreId: 6014,
      x: -10691400,
      y: -4977990
    },
    {
      nanogenreId: 6015,
      x: 8973360,
      y: -6078030
    },
    {
      nanogenreId: 6016,
      x: -2379300,
      y: 4248750
    },
    {
      nanogenreId: 6017,
      x: 4730790,
      y: 8936280
    },
    {
      nanogenreId: 6018,
      x: -4242570,
      y: 2054850
    },
    {
      nanogenreId: 6019,
      x: -4310550,
      y: 11911950
    },
    {
      nanogenreId: 6020,
      x: -6961770,
      y: 3318660
    },
    {
      nanogenreId: 6021,
      x: -1069140,
      y: -7854780
    },
    {
      nanogenreId: 6022,
      x: 3541140,
      y: 5469300
    },
    {
      nanogenreId: 6023,
      x: 7131720,
      y: -3473160
    },
    {
      nanogenreId: 6024,
      x: 13886460,
      y: -3161070
    },
    {
      nanogenreId: 6025,
      x: 3188880,
      y: 6421020
    },
    {
      nanogenreId: 6026,
      x: -6278880,
      y: 6600240
    },
    {
      nanogenreId: 6027,
      x: 6399390,
      y: 7888770
    },
    {
      nanogenreId: 6028,
      x: -11312490,
      y: -6535350
    },
    {
      nanogenreId: 6029,
      x: -2861340,
      y: -8213220
    },
    {
      nanogenreId: 6030,
      x: 519120,
      y: -4236390
    },
    {
      nanogenreId: 6031,
      x: -3757440,
      y: 6037860
    },
    {
      nanogenreId: 6032,
      x: 4149870,
      y: 9915810
    },
    {
      nanogenreId: 6033,
      x: 5509470,
      y: -6278880
    },
    {
      nanogenreId: 6034,
      x: -3853230,
      y: 7171890
    },
    {
      nanogenreId: 6035,
      x: -5509470,
      y: -3884130
    },
    {
      nanogenreId: 6036,
      x: 3429900,
      y: 6309780
    },
    {
      nanogenreId: 6037,
      x: 8089620,
      y: -5976060
    },
    {
      nanogenreId: 6038,
      x: 1025880,
      y: -4628820
    },
    {
      nanogenreId: 6039,
      x: -12499050,
      y: -4128240
    },
    {
      nanogenreId: 6040,
      x: -6485910,
      y: 5113950
    },
    {
      nanogenreId: 6041,
      x: 5660880,
      y: 8216310
    },
    {
      nanogenreId: 6042,
      x: 7480890,
      y: -7592130
    },
    {
      nanogenreId: 6043,
      x: -5602170,
      y: 6263430
    },
    {
      nanogenreId: 6044,
      x: -6183090,
      y: -6776370
    },
    {
      nanogenreId: 6045,
      x: -1569720,
      y: -994980
    },
    {
      nanogenreId: 6046,
      x: 3800700,
      y: -169950
    },
    {
      nanogenreId: 6047,
      x: -2209350,
      y: 71070
    },
    {
      nanogenreId: 6048,
      x: 5880270,
      y: -6875250
    },
    {
      nanogenreId: 6049,
      x: 9702600,
      y: -8509860
    },
    {
      nanogenreId: 6050,
      x: -1514100,
      y: 1032060
    },
    {
      nanogenreId: 6051,
      x: -1795290,
      y: 1684050
    },
    {
      nanogenreId: 6052,
      x: -9724230,
      y: -2691390
    },
    {
      nanogenreId: 6053,
      x: -9433770,
      y: -5243730
    },
    {
      nanogenreId: 6054,
      x: 1171110,
      y: 3071460
    },
    {
      nanogenreId: 6055,
      x: 5324070,
      y: -2994210
    },
    {
      nanogenreId: 6056,
      x: 423330,
      y: -12341460
    },
    {
      nanogenreId: 6057,
      x: -2731560,
      y: 7697190
    },
    {
      nanogenreId: 6058,
      x: 2190810,
      y: -1702590
    },
    {
      nanogenreId: 6059,
      x: -5435310,
      y: 8256480
    },
    {
      nanogenreId: 6060,
      x: 1557360,
      y: -6504450
    },
    {
      nanogenreId: 6061,
      x: 3012750,
      y: 5543460
    },
    {
      nanogenreId: 6062,
      x: 14044050,
      y: -2913870
    },
    {
      nanogenreId: 6063,
      x: -10981860,
      y: 324450
    },
    {
      nanogenreId: 6064,
      x: -2230980,
      y: 1776750
    },
    {
      nanogenreId: 6065,
      x: 8346090,
      y: -7728090
    },
    {
      nanogenreId: 6066,
      x: 5685600,
      y: -139050
    },
    {
      nanogenreId: 6067,
      x: -5580540,
      y: 2487450
    },
    {
      nanogenreId: 6068,
      x: 4770960,
      y: 8531490
    },
    {
      nanogenreId: 6069,
      x: 12984180,
      y: -6241800
    },
    {
      nanogenreId: 6070,
      x: -1980690,
      y: 2512170
    },
    {
      nanogenreId: 6071,
      x: -1514100,
      y: 6096570
    },
    {
      nanogenreId: 6072,
      x: -7351110,
      y: 5197380
    },
    {
      nanogenreId: 6073,
      x: 4860570,
      y: -6665130
    },
    {
      nanogenreId: 6074,
      x: -1449210,
      y: 6359220
    },
    {
      nanogenreId: 6075,
      x: -14887620,
      y: -1758210
    },
    {
      nanogenreId: 6076,
      x: -6575520,
      y: 1168020
    },
    {
      nanogenreId: 6077,
      x: -1702590,
      y: 716880
    },
    {
      nanogenreId: 6078,
      x: 49440,
      y: 1631520
    },
    {
      nanogenreId: 6079,
      x: -8710710,
      y: -3003480
    },
    {
      nanogenreId: 6080,
      x: -4461960,
      y: -2654310
    },
    {
      nanogenreId: 6081,
      x: -1356510,
      y: -6445740
    },
    {
      nanogenreId: 6082,
      x: -10005420,
      y: -701430
    },
    {
      nanogenreId: 6083,
      x: -13123230,
      y: -3680190
    },
    {
      nanogenreId: 6084,
      x: 1804560,
      y: 9548100
    },
    {
      nanogenreId: 6085,
      x: 5206650,
      y: 9782940
    },
    {
      nanogenreId: 6086,
      x: 877560,
      y: -4094250
    },
    {
      nanogenreId: 6087,
      x: 2882970,
      y: 5302440
    },
    {
      nanogenreId: 6088,
      x: -11553510,
      y: -3046740
    },
    {
      nanogenreId: 6089,
      x: 2586330,
      y: -8049450
    },
    {
      nanogenreId: 6090,
      x: -2070300,
      y: 268830
    },
    {
      nanogenreId: 6091,
      x: 5515650,
      y: 9523380
    },
    {
      nanogenreId: 6092,
      x: 1109310,
      y: -3565860
    },
    {
      nanogenreId: 6093,
      x: 5256090,
      y: 13608360
    },
    {
      nanogenreId: 6094,
      x: -3544230,
      y: -1025880
    },
    {
      nanogenreId: 6095,
      x: 4653540,
      y: 10101210
    },
    {
      nanogenreId: 6096,
      x: 14121300,
      y: 13030530
    },
    {
      nanogenreId: 6097,
      x: 7978380,
      y: -6482820
    },
    {
      nanogenreId: 6098,
      x: 5376600,
      y: 4548480
    },
    {
      nanogenreId: 6099,
      x: -5083050,
      y: 3145620
    },
    {
      nanogenreId: 6100,
      x: 3831600,
      y: 9792210
    },
    {
      nanogenreId: 6101,
      x: -1866360,
      y: -2295870
    },
    {
      nanogenreId: 6102,
      x: -1832370,
      y: -8624190
    },
    {
      nanogenreId: 6103,
      x: 4591740,
      y: 2200080
    },
    {
      nanogenreId: 6104,
      x: -10561620,
      y: -3408270
    },
    {
      nanogenreId: 6105,
      x: 5429130,
      y: 2944770
    },
    {
      nanogenreId: 6106,
      x: -5113950,
      y: 4959450
    },
    {
      nanogenreId: 6107,
      x: 905370,
      y: 1668600
    },
    {
      nanogenreId: 6108,
      x: 7916580,
      y: -6788730
    },
    {
      nanogenreId: 6109,
      x: 4307460,
      y: -9492480
    },
    {
      nanogenreId: 6110,
      x: -7174980,
      y: 4715340
    },
    {
      nanogenreId: 6111,
      x: 9152580,
      y: -3939750
    },
    {
      nanogenreId: 6112,
      x: 3887220,
      y: 8930100
    },
    {
      nanogenreId: 6113,
      x: 1955970,
      y: 6918510
    },
    {
      nanogenreId: 6114,
      x: -2416380,
      y: -6278880
    },
    {
      nanogenreId: 6115,
      x: 4879110,
      y: 10753200
    },
    {
      nanogenreId: 6116,
      x: 2085750,
      y: 6893790
    },
    {
      nanogenreId: 6117,
      x: 3071460,
      y: 71070
    },
    {
      nanogenreId: 6118,
      x: 4020090,
      y: -7323300
    },
    {
      nanogenreId: 6119,
      x: -3698730,
      y: -1439940
    },
    {
      nanogenreId: 6120,
      x: 5277720,
      y: 7669380
    },
    {
      nanogenreId: 6121,
      x: 1059870,
      y: 3859410
    },
    {
      nanogenreId: 6122,
      x: 2175360,
      y: 9248370
    },
    {
      nanogenreId: 6123,
      x: 5324070,
      y: 9294720
    },
    {
      nanogenreId: 6124,
      x: 151410,
      y: -2836620
    },
    {
      nanogenreId: 6125,
      x: -2073390,
      y: 1835460
    },
    {
      nanogenreId: 6126,
      x: 12072630,
      y: 8154510
    },
    {
      nanogenreId: 6127,
      x: 3859410,
      y: 9684060
    },
    {
      nanogenreId: 6128,
      x: 3074550,
      y: 9158760
    },
    {
      nanogenreId: 6129,
      x: 7283130,
      y: -6674400
    },
    {
      nanogenreId: 6130,
      x: 568560,
      y: -7752810
    },
    {
      nanogenreId: 6131,
      x: -2014680,
      y: -9229830
    },
    {
      nanogenreId: 6132,
      x: 1881810,
      y: 7357290
    },
    {
      nanogenreId: 6133,
      x: 4400160,
      y: 9365790
    },
    {
      nanogenreId: 6134,
      x: -3207420,
      y: -7363470
    },
    {
      nanogenreId: 6135,
      x: 5021250,
      y: 10091940
    },
    {
      nanogenreId: 6136,
      x: -4947090,
      y: -1041330
    },
    {
      nanogenreId: 6137,
      x: -3618390,
      y: 123600
    },
    {
      nanogenreId: 6138,
      x: 5942070,
      y: 7638480
    },
    {
      nanogenreId: 6139,
      x: 3191970,
      y: 10014690
    },
    {
      nanogenreId: 6140,
      x: 9164940,
      y: -6489000
    },
    {
      nanogenreId: 6141,
      x: 1616070,
      y: 4458870
    },
    {
      nanogenreId: 6142,
      x: -6201630,
      y: 3816150
    },
    {
      nanogenreId: 6143,
      x: 7314030,
      y: -4310550
    },
    {
      nanogenreId: 6144,
      x: 3621480,
      y: 8667450
    },
    {
      nanogenreId: 6145,
      x: 2277330,
      y: 6865980
    },
    {
      nanogenreId: 6146,
      x: -10268070,
      y: -5314800
    },
    {
      nanogenreId: 6147,
      x: -5988420,
      y: -2997300
    },
    {
      nanogenreId: 6148,
      x: 7589040,
      y: -8077260
    },
    {
      nanogenreId: 6149,
      x: 3216690,
      y: -1143300
    },
    {
      nanogenreId: 6150,
      x: 5030520,
      y: 7771350
    },
    {
      nanogenreId: 6151,
      x: -6553890,
      y: 3986100
    },
    {
      nanogenreId: 6152,
      x: -7406730,
      y: -3120900
    },
    {
      nanogenreId: 6153,
      x: -3902670,
      y: 262650
    },
    {
      nanogenreId: 6154,
      x: -1093860,
      y: 16726170
    },
    {
      nanogenreId: 6155,
      x: 4647360,
      y: 6723840
    },
    {
      nanogenreId: 6156,
      x: -1498650,
      y: -21630
    },
    {
      nanogenreId: 6157,
      x: -364620,
      y: -5830830
    },
    {
      nanogenreId: 6158,
      x: -302820,
      y: 8117430
    },
    {
      nanogenreId: 6159,
      x: 2570880,
      y: 7638480
    },
    {
      nanogenreId: 6160,
      x: -3689460,
      y: 1921980
    },
    {
      nanogenreId: 6161,
      x: -1341060,
      y: 5750490
    },
    {
      nanogenreId: 6162,
      x: 9081510,
      y: -5735040
    },
    {
      nanogenreId: 6163,
      x: -1983780,
      y: 2181540
    },
    {
      nanogenreId: 6164,
      x: -1739670,
      y: 3225960
    },
    {
      nanogenreId: 6165,
      x: 6047130,
      y: -2586330
    },
    {
      nanogenreId: 6166,
      x: -5367330,
      y: 1520280
    },
    {
      nanogenreId: 6167,
      x: -420240,
      y: 6183090
    },
    {
      nanogenreId: 6168,
      x: 6161460,
      y: 8058720
    },
    {
      nanogenreId: 6169,
      x: 7465440,
      y: -5935890
    },
    {
      nanogenreId: 6170,
      x: 2765550,
      y: -2076480
    },
    {
      nanogenreId: 6171,
      x: -3525690,
      y: 2524530
    },
    {
      nanogenreId: 6172,
      x: -6349950,
      y: -3779070
    },
    {
      nanogenreId: 6173,
      x: -74160,
      y: -5429130
    },
    {
      nanogenreId: 6174,
      x: -2104290,
      y: 8565480
    },
    {
      nanogenreId: 6175,
      x: 3695640,
      y: 6112020
    },
    {
      nanogenreId: 6176,
      x: 4650450,
      y: -7264590
    },
    {
      nanogenreId: 6177,
      x: 1949790,
      y: -7416000
    },
    {
      nanogenreId: 6178,
      x: 5262270,
      y: -6084210
    },
    {
      nanogenreId: 6179,
      x: -1038240,
      y: 8074170
    },
    {
      nanogenreId: 6180,
      x: -3238320,
      y: 8330640
    },
    {
      nanogenreId: 6181,
      x: 5110860,
      y: 3618390
    },
    {
      nanogenreId: 6182,
      x: 426420,
      y: 6878340
    },
    {
      nanogenreId: 6183,
      x: 9989970,
      y: -1446120
    },
    {
      nanogenreId: 6184,
      x: 3318660,
      y: 256470
    },
    {
      nanogenreId: 6185,
      x: 3516420,
      y: -1310160
    },
    {
      nanogenreId: 6186,
      x: 4248750,
      y: 6671310
    },
    {
      nanogenreId: 6187,
      x: 2747010,
      y: 7156440
    },
    {
      nanogenreId: 6188,
      x: 7039020,
      y: -4152960
    },
    {
      nanogenreId: 6189,
      x: -2716110,
      y: 1748940
    },
    {
      nanogenreId: 6190,
      x: 6455010,
      y: -3562770
    },
    {
      nanogenreId: 6191,
      x: -648900,
      y: -2493630
    },
    {
      nanogenreId: 6192,
      x: -3374280,
      y: -8203950
    },
    {
      nanogenreId: 6193,
      x: 5336430,
      y: 10397850
    },
    {
      nanogenreId: 6194,
      x: -4251840,
      y: -4273470
    },
    {
      nanogenreId: 6195,
      x: 1307070,
      y: -1186560
    },
    {
      nanogenreId: 6196,
      x: -8346090,
      y: 4375440
    },
    {
      nanogenreId: 6197,
      x: -2549250,
      y: 6399390
    },
    {
      nanogenreId: 6198,
      x: -5750490,
      y: -3565860
    },
    {
      nanogenreId: 6199,
      x: 6260340,
      y: -1869450
    },
    {
      nanogenreId: 6200,
      x: -5089230,
      y: 3896490
    },
    {
      nanogenreId: 6201,
      x: 7032840,
      y: -4842030
    },
    {
      nanogenreId: 6202,
      x: -6810360,
      y: -13503300
    },
    {
      nanogenreId: 6203,
      x: -2014680,
      y: 6213990
    },
    {
      nanogenreId: 6204,
      x: 8525310,
      y: -2805720
    },
    {
      nanogenreId: 6205,
      x: 342990,
      y: 7199700
    },
    {
      nanogenreId: 6206,
      x: 747780,
      y: -5008890
    },
    {
      nanogenreId: 6207,
      x: 13898820,
      y: -15450
    },
    {
      nanogenreId: 6208,
      x: 5141760,
      y: 17850930
    },
    {
      nanogenreId: 6209,
      x: 4950180,
      y: -5642340
    },
    {
      nanogenreId: 6210,
      x: 2824260,
      y: 9050610
    },
    {
      nanogenreId: 6211,
      x: -8250300,
      y: -4635000
    },
    {
      nanogenreId: 6212,
      x: 8621100,
      y: -6402480
    },
    {
      nanogenreId: 6213,
      x: 2385480,
      y: 1208190
    },
    {
      nanogenreId: 6214,
      x: 936270,
      y: -6269610
    },
    {
      nanogenreId: 6215,
      x: -664350,
      y: 2385480
    },
    {
      nanogenreId: 6216,
      x: 4706070,
      y: 4211670
    },
    {
      nanogenreId: 6217,
      x: 1603710,
      y: 1202010
    },
    {
      nanogenreId: 6218,
      x: -3080730,
      y: 3986100
    },
    {
      nanogenreId: 6219,
      x: 2654310,
      y: -6479730
    },
    {
      nanogenreId: 6220,
      x: 4236390,
      y: 5728860
    },
    {
      nanogenreId: 6221,
      x: -3086910,
      y: 1436850
    },
    {
      nanogenreId: 6222,
      x: 3213600,
      y: -8241030
    },
    {
      nanogenreId: 6223,
      x: 8386260,
      y: -6155280
    },
    {
      nanogenreId: 6224,
      x: 7542690,
      y: -4718430
    },
    {
      nanogenreId: 6225,
      x: 1232910,
      y: 5296260
    },
    {
      nanogenreId: 6226,
      x: 11043660,
      y: 13929720
    },
    {
      nanogenreId: 6227,
      x: -132870,
      y: 7091550
    },
    {
      nanogenreId: 6228,
      x: -2218620,
      y: 8794140
    },
    {
      nanogenreId: 6229,
      x: 5991510,
      y: 7005030
    },
    {
      nanogenreId: 6230,
      x: -5138670,
      y: -1356510
    },
    {
      nanogenreId: 6231,
      x: -4202400,
      y: 3942840
    },
    {
      nanogenreId: 6232,
      x: 2125920,
      y: 8367720
    },
    {
      nanogenreId: 6233,
      x: -630360,
      y: -92700
    },
    {
      nanogenreId: 6234,
      x: 8512950,
      y: -6933960
    },
    {
      nanogenreId: 6235,
      x: 2821170,
      y: 11695650
    },
    {
      nanogenreId: 6236,
      x: -4202400,
      y: -8540760
    },
    {
      nanogenreId: 6237,
      x: 1566630,
      y: -438780
    },
    {
      nanogenreId: 6238,
      x: -3884130,
      y: 3346470
    },
    {
      nanogenreId: 6239,
      x: -6087300,
      y: 914640
    },
    {
      nanogenreId: 6240,
      x: 9359610,
      y: 4987260
    },
    {
      nanogenreId: 6241,
      x: -1980690,
      y: -4461960
    },
    {
      nanogenreId: 6242,
      x: 0,
      y: -10743930
    },
    {
      nanogenreId: 6243,
      x: 7026660,
      y: 4690620
    },
    {
      nanogenreId: 6244,
      x: 6844350,
      y: 8389350
    },
    {
      nanogenreId: 6245,
      x: 998070,
      y: 3334110
    },
    {
      nanogenreId: 6246,
      x: 1455390,
      y: 6680580
    },
    {
      nanogenreId: 6247,
      x: -3463890,
      y: 4350720
    },
    {
      nanogenreId: 6248,
      x: -13639260,
      y: 605640
    },
    {
      nanogenreId: 6249,
      x: 2774820,
      y: 8979540
    },
    {
      nanogenreId: 6250,
      x: 2413290,
      y: 2468910
    },
    {
      nanogenreId: 6251,
      x: -1344150,
      y: 6983400
    },
    {
      nanogenreId: 6252,
      x: -6989580,
      y: -4647360
    },
    {
      nanogenreId: 6253,
      x: 5194290,
      y: -6736200
    },
    {
      nanogenreId: 6254,
      x: -2848980,
      y: -5490930
    },
    {
      nanogenreId: 6255,
      x: -967170,
      y: 6421020
    },
    {
      nanogenreId: 6256,
      x: -3034380,
      y: 3157980
    },
    {
      nanogenreId: 6257,
      x: 7419090,
      y: 6303600
    },
    {
      nanogenreId: 6258,
      x: 4190040,
      y: 8753970
    },
    {
      nanogenreId: 6259,
      x: 5503290,
      y: 10592520
    },
    {
      nanogenreId: 6260,
      x: -1495560,
      y: 5923530
    },
    {
      nanogenreId: 6261,
      x: -3269220,
      y: 889920
    },
    {
      nanogenreId: 6262,
      x: -651990,
      y: -954810
    },
    {
      nanogenreId: 6263,
      x: 3859410,
      y: 7684830
    },
    {
      nanogenreId: 6264,
      x: -6977220,
      y: -3488610
    },
    {
      nanogenreId: 6265,
      x: 2620320,
      y: 7514880
    },
    {
      nanogenreId: 6266,
      x: -5824650,
      y: 5151030
    },
    {
      nanogenreId: 6267,
      x: -2774820,
      y: -5147940
    },
    {
      nanogenreId: 6268,
      x: 324450,
      y: 2858250
    },
    {
      nanogenreId: 6269,
      x: -3602940,
      y: 7820790
    },
    {
      nanogenreId: 6270,
      x: -4427970,
      y: -7385100
    },
    {
      nanogenreId: 6271,
      x: 4987260,
      y: 8874480
    },
    {
      nanogenreId: 6272,
      x: 3278490,
      y: 3806880
    },
    {
      nanogenreId: 6273,
      x: -1745850,
      y: 2255700
    },
    {
      nanogenreId: 6274,
      x: 3701820,
      y: 1396680
    },
    {
      nanogenreId: 6275,
      x: 2011590,
      y: -1266900
    },
    {
      nanogenreId: 6276,
      x: -8241030,
      y: -3247590
    },
    {
      nanogenreId: 6277,
      x: 5735040,
      y: 9563550
    },
    {
      nanogenreId: 6278,
      x: 7508700,
      y: -6989580
    },
    {
      nanogenreId: 6279,
      x: -2848980,
      y: -2848980
    },
    {
      nanogenreId: 6280,
      x: 3843960,
      y: -3463890
    },
    {
      nanogenreId: 6281,
      x: -7792980,
      y: -685980
    },
    {
      nanogenreId: 6282,
      x: -3488610,
      y: -1925070
    },
    {
      nanogenreId: 6283,
      x: -4446510,
      y: 5895720
    },
    {
      nanogenreId: 6284,
      x: 2379300,
      y: -5058330
    },
    {
      nanogenreId: 6285,
      x: 1909620,
      y: -8052540
    },
    {
      nanogenreId: 6286,
      x: -8806500,
      y: -778680
    },
    {
      nanogenreId: 6287,
      x: -8451150,
      y: -5741220
    },
    {
      nanogenreId: 6288,
      x: 1477020,
      y: -4125150
    },
    {
      nanogenreId: 6289,
      x: 5228280,
      y: 8763240
    },
    {
      nanogenreId: 6290,
      x: -4925460,
      y: 6754740
    },
    {
      nanogenreId: 6291,
      x: 4777140,
      y: 8889930
    },
    {
      nanogenreId: 6292,
      x: 3411360,
      y: -6130560
    },
    {
      nanogenreId: 6293,
      x: 2657400,
      y: 2212440
    },
    {
      nanogenreId: 6294,
      x: -5422950,
      y: 1860180
    },
    {
      nanogenreId: 6295,
      x: -3822330,
      y: 5701050
    },
    {
      nanogenreId: 6296,
      x: 488220,
      y: 3967560
    },
    {
      nanogenreId: 6297,
      x: -4313640,
      y: 7001940
    },
    {
      nanogenreId: 6298,
      x: 8009280,
      y: -6773280
    },
    {
      nanogenreId: 6299,
      x: -874470,
      y: 7397460
    },
    {
      nanogenreId: 6300,
      x: 1891080,
      y: -2805720
    },
    {
      nanogenreId: 6301,
      x: -2240250,
      y: 5395140
    },
    {
      nanogenreId: 6302,
      x: 701430,
      y: 1742760
    },
    {
      nanogenreId: 6303,
      x: -5537280,
      y: -4134420
    },
    {
      nanogenreId: 6304,
      x: -2892240,
      y: -8652000
    },
    {
      nanogenreId: 6305,
      x: 4597920,
      y: 5846280
    },
    {
      nanogenreId: 6306,
      x: -10434930,
      y: 806490
    },
    {
      nanogenreId: 6307,
      x: 2181540,
      y: -1971420
    },
    {
      nanogenreId: 6308,
      x: -1158750,
      y: 4953270
    },
    {
      nanogenreId: 6309,
      x: 1075320,
      y: 2873700
    },
    {
      nanogenreId: 6310,
      x: 6093480,
      y: 2505990
    },
    {
      nanogenreId: 6311,
      x: -9773670,
      y: 407880
    },
    {
      nanogenreId: 6312,
      x: -4968720,
      y: 9498660
    },
    {
      nanogenreId: 6313,
      x: 5531100,
      y: 5441490
    },
    {
      nanogenreId: 6314,
      x: -5345700,
      y: 5237550
    },
    {
      nanogenreId: 6315,
      x: 5954430,
      y: 5555820
    },
    {
      nanogenreId: 6316,
      x: -1257630,
      y: 12183870
    },
    {
      nanogenreId: 6317,
      x: 8006190,
      y: -8083440
    },
    {
      nanogenreId: 6318,
      x: -3003480,
      y: -231750
    },
    {
      nanogenreId: 6319,
      x: -4681350,
      y: 778680
    },
    {
      nanogenreId: 6320,
      x: 4242570,
      y: 8905380
    },
    {
      nanogenreId: 6321,
      x: 5030520,
      y: -2314410
    },
    {
      nanogenreId: 6322,
      x: -3507150,
      y: 4183860
    },
    {
      nanogenreId: 6323,
      x: 2193900,
      y: -3377370
    },
    {
      nanogenreId: 6324,
      x: -7369650,
      y: 9872550
    },
    {
      nanogenreId: 6325,
      x: 618000,
      y: 8834310
    },
    {
      nanogenreId: 6326,
      x: 7820790,
      y: -7320210
    },
    {
      nanogenreId: 6327,
      x: 3556590,
      y: 5626890
    },
    {
      nanogenreId: 6328,
      x: -4057170,
      y: -8250300
    },
    {
      nanogenreId: 6329,
      x: 3624570,
      y: 2796450
    },
    {
      nanogenreId: 6330,
      x: 4233300,
      y: 6492090
    },
    {
      nanogenreId: 6331,
      x: -2042490,
      y: -10091940
    },
    {
      nanogenreId: 6332,
      x: -3426810,
      y: 1983780
    },
    {
      nanogenreId: 6333,
      x: -1452300,
      y: 2793360
    },
    {
      nanogenreId: 6334,
      x: 1245270,
      y: 6538440
    },
    {
      nanogenreId: 6335,
      x: -6658950,
      y: 6393210
    },
    {
      nanogenreId: 6336,
      x: -5160300,
      y: 3717270
    },
    {
      nanogenreId: 6337,
      x: -8392440,
      y: -6331410
    },
    {
      nanogenreId: 6338,
      x: 234840,
      y: -636540
    },
    {
      nanogenreId: 6339,
      x: 15851700,
      y: 5052150
    },
    {
      nanogenreId: 6340,
      x: -2784090,
      y: 8794140
    },
    {
      nanogenreId: 6341,
      x: -3621480,
      y: 4993440
    },
    {
      nanogenreId: 6342,
      x: -8472780,
      y: 8293560
    },
    {
      nanogenreId: 6343,
      x: 5772120,
      y: 815760
    },
    {
      nanogenreId: 6344,
      x: 1931250,
      y: -10166100
    },
    {
      nanogenreId: 6345,
      x: 4838940,
      y: 8414070
    },
    {
      nanogenreId: 6346,
      x: -988800,
      y: -7283130
    },
    {
      nanogenreId: 6347,
      x: 2873700,
      y: 9511020
    },
    {
      nanogenreId: 6348,
      x: -3025110,
      y: 3794520
    },
    {
      nanogenreId: 6349,
      x: -1257630,
      y: -3436080
    },
    {
      nanogenreId: 6350,
      x: -4730790,
      y: -40170
    },
    {
      nanogenreId: 6351,
      x: 4384710,
      y: 8123610
    },
    {
      nanogenreId: 6352,
      x: 4542300,
      y: -6726930
    },
    {
      nanogenreId: 6353,
      x: 3667830,
      y: 9733500
    },
    {
      nanogenreId: 6354,
      x: 457320,
      y: -2564700
    },
    {
      nanogenreId: 6355,
      x: 2234070,
      y: 7944390
    },
    {
      nanogenreId: 6356,
      x: 3748170,
      y: 1628430
    },
    {
      nanogenreId: 6357,
      x: 4214760,
      y: 6757830
    },
    {
      nanogenreId: 6358,
      x: -5027430,
      y: -4384710
    },
    {
      nanogenreId: 6359,
      x: -744690,
      y: 2014680
    },
    {
      nanogenreId: 6360,
      x: -10382400,
      y: -3068370
    },
    {
      nanogenreId: 6361,
      x: 7548870,
      y: -6563160
    },
    {
      nanogenreId: 6362,
      x: 2212440,
      y: 6572430
    },
    {
      nanogenreId: 6363,
      x: 14050230,
      y: 4801860
    },
    {
      nanogenreId: 6364,
      x: -139050,
      y: 6773280
    },
    {
      nanogenreId: 6365,
      x: 5716500,
      y: 9195840
    },
    {
      nanogenreId: 6366,
      x: -10175370,
      y: 219390
    },
    {
      nanogenreId: 6367,
      x: 1038240,
      y: 8225580
    },
    {
      nanogenreId: 6368,
      x: -1943610,
      y: 10169190
    },
    {
      nanogenreId: 6369,
      x: 5840100,
      y: -6424110
    },
    {
      nanogenreId: 6370,
      x: -6924690,
      y: 2855160
    },
    {
      nanogenreId: 6371,
      x: 676710,
      y: 4112790
    },
    {
      nanogenreId: 6372,
      x: -3096180,
      y: 3590580
    },
    {
      nanogenreId: 6373,
      x: 3504060,
      y: -6427200
    },
    {
      nanogenreId: 6374,
      x: 1214370,
      y: 8364630
    },
    {
      nanogenreId: 6375,
      x: 11936670,
      y: -1146390
    },
    {
      nanogenreId: 6376,
      x: -1050600,
      y: 6315960
    },
    {
      nanogenreId: 6377,
      x: -2527620,
      y: 1081500
    },
    {
      nanogenreId: 6378,
      x: 4505220,
      y: 3899580
    },
    {
      nanogenreId: 6379,
      x: -3664740,
      y: 4477410
    },
    {
      nanogenreId: 6380,
      x: 7159530,
      y: -6186180
    },
    {
      nanogenreId: 6381,
      x: -3964470,
      y: -2366940
    },
    {
      nanogenreId: 6382,
      x: -4573200,
      y: 7280040
    },
    {
      nanogenreId: 6383,
      x: 6213990,
      y: 10098120
    },
    {
      nanogenreId: 6384,
      x: -3550410,
      y: 12375450
    },
    {
      nanogenreId: 6385,
      x: 4443420,
      y: 9857100
    },
    {
      nanogenreId: 6386,
      x: 4934730,
      y: 1344150
    },
    {
      nanogenreId: 6387,
      x: 1761300,
      y: 5147940
    },
    {
      nanogenreId: 6388,
      x: 9671700,
      y: -1047510
    },
    {
      nanogenreId: 6389,
      x: 4514490,
      y: 9140220
    },
    {
      nanogenreId: 6390,
      x: 4777140,
      y: -3071460
    },
    {
      nanogenreId: 6391,
      x: -219390,
      y: 5231370
    },
    {
      nanogenreId: 6392,
      x: 6047130,
      y: 9059880
    },
    {
      nanogenreId: 6393,
      x: -1628430,
      y: -1894170
    },
    {
      nanogenreId: 6394,
      x: -4434150,
      y: -4740060
    },
    {
      nanogenreId: 6395,
      x: -11809980,
      y: -6998850
    },
    {
      nanogenreId: 6396,
      x: 8296650,
      y: -8027820
    },
    {
      nanogenreId: 6397,
      x: -2954040,
      y: 2159910
    },
    {
      nanogenreId: 6398,
      x: 4224030,
      y: 9121680
    },
    {
      nanogenreId: 6399,
      x: -169950,
      y: 10002330
    },
    {
      nanogenreId: 6400,
      x: -12094260,
      y: -364620
    },
    {
      nanogenreId: 6401,
      x: 21630,
      y: 2159910
    },
    {
      nanogenreId: 6402,
      x: -7576680,
      y: 2821170
    },
    {
      nanogenreId: 6403,
      x: 3411360,
      y: 6921600
    },
    {
      nanogenreId: 6404,
      x: -4322910,
      y: 3862500
    },
    {
      nanogenreId: 6405,
      x: -3868680,
      y: 6726930
    },
    {
      nanogenreId: 6406,
      x: 3173430,
      y: 7474710
    },
    {
      nanogenreId: 6407,
      x: 5484750,
      y: 6272700
    },
    {
      nanogenreId: 6408,
      x: -9115500,
      y: -8241030
    },
    {
      nanogenreId: 6409,
      x: -2175360,
      y: 6359220
    },
    {
      nanogenreId: 6410,
      x: 3890310,
      y: 8482050
    },
    {
      nanogenreId: 6411,
      x: -6176910,
      y: 1124760
    },
    {
      nanogenreId: 6412,
      x: -12122070,
      y: 4344540
    },
    {
      nanogenreId: 6413,
      x: -1127850,
      y: 6665130
    },
    {
      nanogenreId: 6414,
      x: -5151030,
      y: -9115500
    },
    {
      nanogenreId: 6415,
      x: -10527630,
      y: -4313640
    },
    {
      nanogenreId: 6416,
      x: -1041330,
      y: -7162620
    },
    {
      nanogenreId: 6417,
      x: 5778300,
      y: 6566250
    },
    {
      nanogenreId: 6418,
      x: 2289690,
      y: -7440720
    },
    {
      nanogenreId: 6419,
      x: 4477410,
      y: 5772120
    },
    {
      nanogenreId: 6420,
      x: 321360,
      y: 3071460
    },
    {
      nanogenreId: 6421,
      x: -3049830,
      y: -10549260
    },
    {
      nanogenreId: 6422,
      x: 5954430,
      y: -5222100
    },
    {
      nanogenreId: 6423,
      x: -1177290,
      y: -4486680
    },
    {
      nanogenreId: 6424,
      x: -1171110,
      y: 3497880
    },
    {
      nanogenreId: 6425,
      x: -4597920,
      y: -7913490
    },
    {
      nanogenreId: 6426,
      x: -1918890,
      y: -4736970
    },
    {
      nanogenreId: 6427,
      x: -6467370,
      y: 3692550
    },
    {
      nanogenreId: 6428,
      x: 4715340,
      y: -404790
    },
    {
      nanogenreId: 6429,
      x: 4678260,
      y: 8429520
    },
    {
      nanogenreId: 6430,
      x: -12489780,
      y: -6006960
    },
    {
      nanogenreId: 6431,
      x: 4585560,
      y: 10323690
    },
    {
      nanogenreId: 6432,
      x: -7508700,
      y: 4570110
    },
    {
      nanogenreId: 6433,
      x: -698340,
      y: 6624960
    },
    {
      nanogenreId: 6434,
      x: -522210,
      y: -293550
    },
    {
      nanogenreId: 6435,
      x: 5537280,
      y: -7533420
    },
    {
      nanogenreId: 6436,
      x: -7579770,
      y: -3290850
    },
    {
      nanogenreId: 6437,
      x: 7820790,
      y: -3065280
    },
    {
      nanogenreId: 6438,
      x: -2583240,
      y: 10651230
    },
    {
      nanogenreId: 6439,
      x: -3908850,
      y: -3327930
    },
    {
      nanogenreId: 6440,
      x: 2886060,
      y: -2669760
    },
    {
      nanogenreId: 6441,
      x: 12557760,
      y: 1582080
    },
    {
      nanogenreId: 6442,
      x: 4091160,
      y: 7675560
    },
    {
      nanogenreId: 6443,
      x: -3661650,
      y: 2614140
    },
    {
      nanogenreId: 6444,
      x: -2848980,
      y: 6022410
    },
    {
      nanogenreId: 6445,
      x: -7555050,
      y: -485130
    },
    {
      nanogenreId: 6446,
      x: -4845120,
      y: 596370
    },
    {
      nanogenreId: 6447,
      x: -2898420,
      y: -1498650
    },
    {
      nanogenreId: 6448,
      x: -2221710,
      y: 4764780
    },
    {
      nanogenreId: 6449,
      x: -4925460,
      y: 166860
    },
    {
      nanogenreId: 6450,
      x: -871380,
      y: 3056010
    },
    {
      nanogenreId: 6451,
      x: 10697580,
      y: -2787180
    },
    {
      nanogenreId: 6452,
      x: -4505220,
      y: -13358070
    },
    {
      nanogenreId: 6453,
      x: 5404410,
      y: -5855550
    },
    {
      nanogenreId: 6454,
      x: -5503290,
      y: 12029370
    },
    {
      nanogenreId: 6455,
      x: 4298190,
      y: -4409430
    },
    {
      nanogenreId: 6456,
      x: 5042880,
      y: 7854780
    },
    {
      nanogenreId: 6457,
      x: 834300,
      y: 9680970
    },
    {
      nanogenreId: 6458,
      x: 1418310,
      y: 7808430
    },
    {
      nanogenreId: 6459,
      x: 2533800,
      y: -3973740
    },
    {
      nanogenreId: 6460,
      x: 9560460,
      y: -6387030
    },
    {
      nanogenreId: 6461,
      x: 1378140,
      y: 6980310
    },
    {
      nanogenreId: 6462,
      x: 1202010,
      y: -8327550
    },
    {
      nanogenreId: 6463,
      x: 2512170,
      y: 4681350
    },
    {
      nanogenreId: 6464,
      x: -905370,
      y: 9239100
    },
    {
      nanogenreId: 6465,
      x: -438780,
      y: -1347240
    },
    {
      nanogenreId: 6466,
      x: 2657400,
      y: -12276570
    },
    {
      nanogenreId: 6467,
      x: -2271150,
      y: 8268840
    },
    {
      nanogenreId: 6468,
      x: -105060,
      y: 13917360
    },
    {
      nanogenreId: 6469,
      x: 5286990,
      y: -4582470
    },
    {
      nanogenreId: 6470,
      x: 4971810,
      y: 7969110
    },
    {
      nanogenreId: 6471,
      x: -4230210,
      y: 2765550
    },
    {
      nanogenreId: 6472,
      x: -8920830,
      y: -3077640
    },
    {
      nanogenreId: 6473,
      x: -1430670,
      y: 1730400
    },
    {
      nanogenreId: 6474,
      x: -5194290,
      y: 4047900
    },
    {
      nanogenreId: 6475,
      x: 460410,
      y: 7100820
    },
    {
      nanogenreId: 6476,
      x: 3871770,
      y: 7168800
    },
    {
      nanogenreId: 6477,
      x: -256470,
      y: 7551960
    },
    {
      nanogenreId: 6478,
      x: 525300,
      y: -454230
    },
    {
      nanogenreId: 6479,
      x: 6180,
      y: 4944000
    },
    {
      nanogenreId: 6480,
      x: 4066440,
      y: 8543850
    },
    {
      nanogenreId: 6481,
      x: 7966020,
      y: -8534580
    },
    {
      nanogenreId: 6482,
      x: -139050,
      y: 4826580
    },
    {
      nanogenreId: 6483,
      x: 3618390,
      y: 8157600
    },
    {
      nanogenreId: 6484,
      x: -1396680,
      y: -13212840
    },
    {
      nanogenreId: 6485,
      x: -6115110,
      y: 4084980
    },
    {
      nanogenreId: 6486,
      x: 4687530,
      y: 8553120
    },
    {
      nanogenreId: 6487,
      x: -4483590,
      y: 2410200
    },
    {
      nanogenreId: 6488,
      x: -5367330,
      y: 2317500
    },
    {
      nanogenreId: 6489,
      x: -3748170,
      y: 7267680
    },
    {
      nanogenreId: 6490,
      x: 5555820,
      y: 1504830
    },
    {
      nanogenreId: 6491,
      x: 5954430,
      y: 7419090
    },
    {
      nanogenreId: 6492,
      x: -2354580,
      y: 1297800
    },
    {
      nanogenreId: 6493,
      x: -6047130,
      y: 5540370
    },
    {
      nanogenreId: 6494,
      x: -7694100,
      y: 312090
    },
    {
      nanogenreId: 6495,
      x: 2620320,
      y: 4004640
    },
    {
      nanogenreId: 6496,
      x: 293550,
      y: 8525310
    },
    {
      nanogenreId: 6497,
      x: 2728470,
      y: 6541530
    },
    {
      nanogenreId: 6498,
      x: -2280420,
      y: 7483980
    },
    {
      nanogenreId: 6499,
      x: 1934340,
      y: 3596760
    },
    {
      nanogenreId: 6500,
      x: 1183470,
      y: 5172660
    },
    {
      nanogenreId: 6501,
      x: 2867520,
      y: 9600630
    },
    {
      nanogenreId: 6502,
      x: 3732720,
      y: 3046740
    },
    {
      nanogenreId: 6503,
      x: 4977990,
      y: 8052540
    },
    {
      nanogenreId: 6504,
      x: -4084980,
      y: 2882970
    },
    {
      nanogenreId: 6505,
      x: -1390500,
      y: 3201240
    },
    {
      nanogenreId: 6506,
      x: -821940,
      y: 8092710
    },
    {
      nanogenreId: 6507,
      x: 3470070,
      y: 383160
    },
    {
      nanogenreId: 6508,
      x: -2453460,
      y: -6658950
    },
    {
      nanogenreId: 6509,
      x: -1646970,
      y: -8383170
    },
    {
      nanogenreId: 6510,
      x: -111240,
      y: 6590970
    },
    {
      nanogenreId: 6511,
      x: 4427970,
      y: 10147560
    },
    {
      nanogenreId: 6512,
      x: -8645820,
      y: 1266900
    },
    {
      nanogenreId: 6513,
      x: 5416770,
      y: 8525310
    },
    {
      nanogenreId: 6514,
      x: 475860,
      y: 6504450
    },
    {
      nanogenreId: 6515,
      x: -580920,
      y: 3191970
    },
    {
      nanogenreId: 6516,
      x: -4536120,
      y: -3748170
    },
    {
      nanogenreId: 6517,
      x: 676710,
      y: 2314410
    },
    {
      nanogenreId: 6518,
      x: 10543080,
      y: -3643110
    },
    {
      nanogenreId: 6519,
      x: 1671690,
      y: 6637320
    },
    {
      nanogenreId: 6520,
      x: -2039400,
      y: 642720
    },
    {
      nanogenreId: 6521,
      x: -3822330,
      y: 4230210
    },
    {
      nanogenreId: 6522,
      x: 13775220,
      y: -3482430
    },
    {
      nanogenreId: 6523,
      x: 4477410,
      y: 6312870
    },
    {
      nanogenreId: 6524,
      x: 6022410,
      y: -52530
    },
    {
      nanogenreId: 6525,
      x: -5429130,
      y: -985710
    },
    {
      nanogenreId: 6526,
      x: 9236010,
      y: 6736200
    },
    {
      nanogenreId: 6527,
      x: 3071460,
      y: -2910780
    },
    {
      nanogenreId: 6528,
      x: 1381230,
      y: -3995370
    },
    {
      nanogenreId: 6529,
      x: -3034380,
      y: 633450
    },
    {
      nanogenreId: 6530,
      x: 1687140,
      y: 1792200
    },
    {
      nanogenreId: 6531,
      x: -7845510,
      y: 3130170
    },
    {
      nanogenreId: 6532,
      x: 769410,
      y: -4458870
    },
    {
      nanogenreId: 6533,
      x: -8318280,
      y: -2416380
    },
    {
      nanogenreId: 6534,
      x: 7227510,
      y: -6862890
    },
    {
      nanogenreId: 6535,
      x: -8525310,
      y: 10163010
    },
    {
      nanogenreId: 6536,
      x: -849750,
      y: -6816540
    },
    {
      nanogenreId: 6537,
      x: 4471230,
      y: -1183470
    },
    {
      nanogenreId: 6538,
      x: 339900,
      y: 5824650
    },
    {
      nanogenreId: 6539,
      x: -2703750,
      y: 2765550
    },
    {
      nanogenreId: 6540,
      x: 4397070,
      y: -7582860
    },
    {
      nanogenreId: 6541,
      x: -435690,
      y: 2938590
    },
    {
      nanogenreId: 6542,
      x: 3377370,
      y: -3655470
    },
    {
      nanogenreId: 6543,
      x: 509850,
      y: 2904600
    },
    {
      nanogenreId: 6544,
      x: 5095410,
      y: -444960
    },
    {
      nanogenreId: 6545,
      x: -2697570,
      y: 7125540
    },
    {
      nanogenreId: 6546,
      x: -3519510,
      y: -11584410
    },
    {
      nanogenreId: 6547,
      x: -2954040,
      y: 3015840
    },
    {
      nanogenreId: 6548,
      x: 4656630,
      y: -7975290
    },
    {
      nanogenreId: 6549,
      x: -5098500,
      y: -3803790
    },
    {
      nanogenreId: 6550,
      x: -5852460,
      y: 2784090
    },
    {
      nanogenreId: 6551,
      x: 2901510,
      y: 7255320
    },
    {
      nanogenreId: 6552,
      x: 2648130,
      y: -1925070
    },
    {
      nanogenreId: 6553,
      x: 2054850,
      y: -5364240
    },
    {
      nanogenreId: 6554,
      x: -7561230,
      y: 5444580
    },
    {
      nanogenreId: 6555,
      x: 1155660,
      y: 16173060
    },
    {
      nanogenreId: 6556,
      x: -2833530,
      y: 6238710
    },
    {
      nanogenreId: 6557,
      x: -7403640,
      y: 466590
    },
    {
      nanogenreId: 6558,
      x: -970260,
      y: 2691390
    },
    {
      nanogenreId: 6559,
      x: -3293940,
      y: -6844350
    },
    {
      nanogenreId: 6560,
      x: -2728470,
      y: -998070
    },
    {
      nanogenreId: 6561,
      x: -3507150,
      y: 7610670
    },
    {
      nanogenreId: 6562,
      x: -2675940,
      y: 4115880
    },
    {
      nanogenreId: 6563,
      x: -3522600,
      y: 3850140
    },
    {
      nanogenreId: 6564,
      x: -3816150,
      y: 2969490
    },
    {
      nanogenreId: 6565,
      x: 4140600,
      y: 8185410
    },
    {
      nanogenreId: 6566,
      x: -2864430,
      y: 4526850
    },
    {
      nanogenreId: 6567,
      x: 2629590,
      y: 8052540
    },
    {
      nanogenreId: 6568,
      x: 3522600,
      y: -3185790
    },
    {
      nanogenreId: 6569,
      x: -15156450,
      y: 5290080
    },
    {
      nanogenreId: 6570,
      x: 6149100,
      y: 5648520
    },
    {
      nanogenreId: 6571,
      x: 2685210,
      y: -9100050
    },
    {
      nanogenreId: 6572,
      x: 6235620,
      y: -4029360
    },
    {
      nanogenreId: 6573,
      x: -4712250,
      y: -3306300
    },
    {
      nanogenreId: 6574,
      x: 2422560,
      y: 7762080
    },
    {
      nanogenreId: 6575,
      x: -3321750,
      y: 1718040
    },
    {
      nanogenreId: 6576,
      x: -2654310,
      y: -553110
    },
    {
      nanogenreId: 6577,
      x: 6544620,
      y: 8169960
    },
    {
      nanogenreId: 6578,
      x: 4517580,
      y: 9693330
    },
    {
      nanogenreId: 6579,
      x: -1486290,
      y: -7317120
    },
    {
      nanogenreId: 6580,
      x: -2530710,
      y: 5132490
    },
    {
      nanogenreId: 6581,
      x: -8784870,
      y: -4443420
    },
    {
      nanogenreId: 6582,
      x: 2904600,
      y: 2743920
    },
    {
      nanogenreId: 6583,
      x: 1776750,
      y: 6918510
    },
    {
      nanogenreId: 6584,
      x: -5583630,
      y: -3306300
    },
    {
      nanogenreId: 6585,
      x: 5636160,
      y: -7264590
    },
    {
      nanogenreId: 6586,
      x: 3167250,
      y: -8614920
    },
    {
      nanogenreId: 6587,
      x: -10020870,
      y: 2611050
    },
    {
      nanogenreId: 6588,
      x: -1514100,
      y: 7048290
    },
    {
      nanogenreId: 6589,
      x: 506760,
      y: 11287770
    },
    {
      nanogenreId: 6590,
      x: -2308230,
      y: 3432990
    },
    {
      nanogenreId: 6591,
      x: -3949020,
      y: 6062580
    },
    {
      nanogenreId: 6592,
      x: 10784100,
      y: 3429900
    },
    {
      nanogenreId: 6593,
      x: 4125150,
      y: 9313260
    },
    {
      nanogenreId: 6594,
      x: 1606800,
      y: 3738900
    },
    {
      nanogenreId: 6595,
      x: -2070300,
      y: -7388190
    },
    {
      nanogenreId: 6596,
      x: 9541920,
      y: 1436850
    },
    {
      nanogenreId: 6597,
      x: 8222490,
      y: -7437630
    },
    {
      nanogenreId: 6598,
      x: -2184630,
      y: 951720
    },
    {
      nanogenreId: 6599,
      x: 4326000,
      y: 9711870
    },
    {
      nanogenreId: 6600,
      x: -608730,
      y: -2073390
    },
    {
      nanogenreId: 6601,
      x: 6544620,
      y: -11766720
    },
    {
      nanogenreId: 6602,
      x: -2774820,
      y: -4267290
    },
    {
      nanogenreId: 6603,
      x: -5018160,
      y: 5314800
    },
    {
      nanogenreId: 6604,
      x: 979530,
      y: 8463510
    },
    {
      nanogenreId: 6605,
      x: 1183470,
      y: 2595600
    },
    {
      nanogenreId: 6606,
      x: -6535350,
      y: -1940520
    },
    {
      nanogenreId: 6607,
      x: 1748940,
      y: 15477810
    },
    {
      nanogenreId: 6608,
      x: 584010,
      y: 8522220
    },
    {
      nanogenreId: 6609,
      x: 1146390,
      y: -4177680
    },
    {
      nanogenreId: 6610,
      x: -3732720,
      y: 5877180
    },
    {
      nanogenreId: 6611,
      x: 5867910,
      y: -4069530
    },
    {
      nanogenreId: 6612,
      x: 2271150,
      y: 2586330
    },
    {
      nanogenreId: 6613,
      x: 2360760,
      y: 7023570
    },
    {
      nanogenreId: 6614,
      x: 5676330,
      y: 10175370
    },
    {
      nanogenreId: 6615,
      x: 3609120,
      y: 8825040
    },
    {
      nanogenreId: 6616,
      x: 7008120,
      y: -5886450
    },
    {
      nanogenreId: 6617,
      x: 8685990,
      y: -6044040
    },
    {
      nanogenreId: 6618,
      x: 4940910,
      y: 2450370
    },
    {
      nanogenreId: 6619,
      x: 2493630,
      y: 441870
    },
    {
      nanogenreId: 6620,
      x: 2570880,
      y: 10221720
    },
    {
      nanogenreId: 6621,
      x: 5348790,
      y: -3578220
    },
    {
      nanogenreId: 6622,
      x: 367710,
      y: -5070690
    },
    {
      nanogenreId: 6623,
      x: -7199700,
      y: -1421400
    },
    {
      nanogenreId: 6624,
      x: 954810,
      y: 6152190
    },
    {
      nanogenreId: 6625,
      x: -2336040,
      y: 3105450
    },
    {
      nanogenreId: 6626,
      x: 5305530,
      y: 9137130
    },
    {
      nanogenreId: 6627,
      x: 4390890,
      y: 3022020
    },
    {
      nanogenreId: 6628,
      x: -2110470,
      y: 2156820
    },
    {
      nanogenreId: 6629,
      x: 1291620,
      y: 7344930
    },
    {
      nanogenreId: 6630,
      x: -3868680,
      y: -3720360
    },
    {
      nanogenreId: 6631,
      x: -2468910,
      y: -1418310
    },
    {
      nanogenreId: 6632,
      x: -13524930,
      y: 7440720
    },
    {
      nanogenreId: 6633,
      x: -10549260,
      y: 1257630
    },
    {
      nanogenreId: 6634,
      x: -3346470,
      y: 6349950
    },
    {
      nanogenreId: 6635,
      x: -1773660,
      y: -608730
    },
    {
      nanogenreId: 6636,
      x: -2419470,
      y: -6865980
    },
    {
      nanogenreId: 6637,
      x: 3488610,
      y: 7107000
    },
    {
      nanogenreId: 6638,
      x: -6964860,
      y: -2960220
    },
    {
      nanogenreId: 6639,
      x: 5540370,
      y: -1418310
    },
    {
      nanogenreId: 6640,
      x: -105060,
      y: -5030520
    },
    {
      nanogenreId: 6641,
      x: 6587880,
      y: -7876410
    },
    {
      nanogenreId: 6642,
      x: 1711860,
      y: -2759370
    },
    {
      nanogenreId: 6643,
      x: 1486290,
      y: 5317890
    },
    {
      nanogenreId: 6644,
      x: 6788730,
      y: -6624960
    },
    {
      nanogenreId: 6645,
      x: 5333340,
      y: -2008500
    },
    {
      nanogenreId: 6646,
      x: -7456170,
      y: 3741990
    },
    {
      nanogenreId: 6647,
      x: 3949020,
      y: -1501740
    },
    {
      nanogenreId: 6648,
      x: -176130,
      y: -9492480
    },
    {
      nanogenreId: 6649,
      x: 1214370,
      y: -1931250
    },
    {
      nanogenreId: 6650,
      x: 2187720,
      y: 4968720
    },
    {
      nanogenreId: 6651,
      x: 4251840,
      y: 6291240
    },
    {
      nanogenreId: 6652,
      x: -13287000,
      y: 6167640
    },
    {
      nanogenreId: 6653,
      x: -12403260,
      y: -2113560
    },
    {
      nanogenreId: 6654,
      x: 1758210,
      y: 5818470
    },
    {
      nanogenreId: 6655,
      x: 8503680,
      y: -9121680
    },
    {
      nanogenreId: 6656,
      x: -8543850,
      y: -3643110
    },
    {
      nanogenreId: 6657,
      x: -15085380,
      y: -6149100
    },
    {
      nanogenreId: 6658,
      x: 7907310,
      y: 1050600
    },
    {
      nanogenreId: 6659,
      x: 3584400,
      y: 8478960
    },
    {
      nanogenreId: 6660,
      x: -10617240,
      y: 1733490
    },
    {
      nanogenreId: 6661,
      x: -9146400,
      y: 5076870
    },
    {
      nanogenreId: 6662,
      x: -5568180,
      y: 8435700
    },
    {
      nanogenreId: 6663,
      x: 2966400,
      y: 3259950
    },
    {
      nanogenreId: 6664,
      x: -8173050,
      y: 7700280
    },
    {
      nanogenreId: 6665,
      x: 6408660,
      y: 9857100
    },
    {
      nanogenreId: 6666,
      x: -2688300,
      y: -10981860
    },
    {
      nanogenreId: 6667,
      x: -6115110,
      y: -3630750
    },
    {
      nanogenreId: 6668,
      x: 14785650,
      y: 12959460
    },
    {
      nanogenreId: 6669,
      x: -3377370,
      y: -10407120
    },
    {
      nanogenreId: 6670,
      x: -5787570,
      y: 4440330
    },
    {
      nanogenreId: 6671,
      x: 2935500,
      y: 12174600
    },
    {
      nanogenreId: 6672,
      x: 13333350,
      y: 3646200
    },
    {
      nanogenreId: 6673,
      x: 2119740,
      y: 6448830
    },
    {
      nanogenreId: 6674,
      x: 10836630,
      y: -5772120
    },
    {
      nanogenreId: 6675,
      x: -2518350,
      y: -8809590
    },
    {
      nanogenreId: 6676,
      x: 7026660,
      y: 1786020
    },
    {
      nanogenreId: 6677,
      x: 6760920,
      y: 6933960
    },
    {
      nanogenreId: 6678,
      x: -3859410,
      y: -1303980
    },
    {
      nanogenreId: 6679,
      x: -1962150,
      y: 11059110
    },
    {
      nanogenreId: 6680,
      x: -12526860,
      y: -7029750
    },
    {
      nanogenreId: 6681,
      x: -2215530,
      y: 716880
    },
    {
      nanogenreId: 6682,
      x: 3714180,
      y: 7857870
    },
    {
      nanogenreId: 6683,
      x: 11698740,
      y: -4162230
    },
    {
      nanogenreId: 6684,
      x: 13840110,
      y: 5228280
    },
    {
      nanogenreId: 6685,
      x: -5620710,
      y: 11102370
    },
    {
      nanogenreId: 6686,
      x: 3114720,
      y: -457320
    },
    {
      nanogenreId: 6687,
      x: -1390500,
      y: -9316350
    },
    {
      nanogenreId: 6688,
      x: -1075320,
      y: 9103140
    },
    {
      nanogenreId: 6689,
      x: -7758990,
      y: -5670150
    },
    {
      nanogenreId: 6690,
      x: -11640030,
      y: -5864820
    },
    {
      nanogenreId: 6691,
      x: 2478180,
      y: 14099670
    },
    {
      nanogenreId: 6692,
      x: 2895330,
      y: 6943230
    },
    {
      nanogenreId: 6693,
      x: -1022790,
      y: -3992280
    },
    {
      nanogenreId: 6694,
      x: 6479730,
      y: -633450
    },
    {
      nanogenreId: 6695,
      x: -8587110,
      y: -5030520
    },
    {
      nanogenreId: 6696,
      x: 4103520,
      y: 7496340
    },
    {
      nanogenreId: 6697,
      x: 6847440,
      y: 9850920
    },
    {
      nanogenreId: 6698,
      x: -3278490,
      y: -10085760
    },
    {
      nanogenreId: 6699,
      x: 11837790,
      y: 1096950
    },
    {
      nanogenreId: 6700,
      x: -4143690,
      y: -1458480
    },
    {
      nanogenreId: 6701,
      x: 6183090,
      y: 4876020
    },
    {
      nanogenreId: 6702,
      x: 4554660,
      y: 8089620
    },
    {
      nanogenreId: 6703,
      x: 8763240,
      y: -8018550
    },
    {
      nanogenreId: 6704,
      x: -2620320,
      y: -12081900
    },
    {
      nanogenreId: 6705,
      x: 7771350,
      y: -7425270
    },
    {
      nanogenreId: 6706,
      x: 7725000,
      y: -8089620
    },
    {
      nanogenreId: 6707,
      x: 1350330,
      y: 8114340
    },
    {
      nanogenreId: 6708,
      x: 8994990,
      y: -7601400
    },
    {
      nanogenreId: 6709,
      x: -1161840,
      y: -9356520
    },
    {
      nanogenreId: 6710,
      x: 234840,
      y: -5932800
    },
    {
      nanogenreId: 6711,
      x: -7774440,
      y: -6498270
    },
    {
      nanogenreId: 6712,
      x: 11568960,
      y: 9980700
    },
    {
      nanogenreId: 6713,
      x: 3457710,
      y: 8259570
    },
    {
      nanogenreId: 6714,
      x: -1319430,
      y: -5651610
    },
    {
      nanogenreId: 6715,
      x: -2240250,
      y: 5599080
    },
    {
      nanogenreId: 6716,
      x: 1989960,
      y: -4675170
    },
    {
      nanogenreId: 6717,
      x: 1640790,
      y: -7613760
    },
    {
      nanogenreId: 6718,
      x: -4647360,
      y: 1578990
    },
    {
      nanogenreId: 6719,
      x: 1449210,
      y: 11006580
    },
    {
      nanogenreId: 6720,
      x: 6544620,
      y: 1798380
    },
    {
      nanogenreId: 6721,
      x: 704520,
      y: -4551570
    },
    {
      nanogenreId: 6722,
      x: 9251460,
      y: 9628440
    },
    {
      nanogenreId: 6723,
      x: 852840,
      y: 6909240
    },
    {
      nanogenreId: 6724,
      x: 15746640,
      y: -2586330
    },
    {
      nanogenreId: 6725,
      x: 7236780,
      y: -3191970
    },
    {
      nanogenreId: 6726,
      x: -2292780,
      y: 4542300
    },
    {
      nanogenreId: 6727,
      x: 5490930,
      y: -6612600
    },
    {
      nanogenreId: 6728,
      x: 8216310,
      y: 2329860
    },
    {
      nanogenreId: 6729,
      x: 5840100,
      y: -2465820
    },
    {
      nanogenreId: 6730,
      x: 8954820,
      y: -8571660
    },
    {
      nanogenreId: 6731,
      x: 6260340,
      y: 9622260
    },
    {
      nanogenreId: 6732,
      x: 9792210,
      y: 4183860
    },
    {
      nanogenreId: 6733,
      x: 5954430,
      y: 8979540
    },
    {
      nanogenreId: 6734,
      x: 5938980,
      y: -7391280
    },
    {
      nanogenreId: 6735,
      x: -383160,
      y: -10141380
    },
    {
      nanogenreId: 6736,
      x: 930090,
      y: -9242190
    },
    {
      nanogenreId: 6737,
      x: -420240,
      y: 2465820
    },
    {
      nanogenreId: 6738,
      x: 9854010,
      y: -6943230
    },
    {
      nanogenreId: 6739,
      x: -2583240,
      y: 5011980
    },
    {
      nanogenreId: 6740,
      x: 5160300,
      y: -2564700
    },
    {
      nanogenreId: 6741,
      x: -1591350,
      y: 4761690
    },
    {
      nanogenreId: 6742,
      x: -256470,
      y: 4678260
    },
    {
      nanogenreId: 6743,
      x: 2580150,
      y: -491310
    },
    {
      nanogenreId: 6744,
      x: -33990,
      y: 5132490
    },
    {
      nanogenreId: 6745,
      x: -4443420,
      y: 5503290
    },
    {
      nanogenreId: 6746,
      x: 7320210,
      y: -5407500
    },
    {
      nanogenreId: 6747,
      x: -1220550,
      y: -8534580
    },
    {
      nanogenreId: 6748,
      x: 7589040,
      y: -7446900
    },
    {
      nanogenreId: 6749,
      x: 2107380,
      y: 10095030
    },
    {
      nanogenreId: 6750,
      x: 5716500,
      y: -4288920
    },
    {
      nanogenreId: 6751,
      x: 6714570,
      y: -8862120
    },
    {
      nanogenreId: 6752,
      x: 9353430,
      y: -6643500
    },
    {
      nanogenreId: 6753,
      x: -4872930,
      y: -4134420
    },
    {
      nanogenreId: 6754,
      x: -305910,
      y: -4353810
    },
    {
      nanogenreId: 6755,
      x: 2459640,
      y: 8988810
    },
    {
      nanogenreId: 6756,
      x: -3355740,
      y: -5830830
    },
    {
      nanogenreId: 6757,
      x: 5166480,
      y: 8707620
    },
    {
      nanogenreId: 6758,
      x: -8389350,
      y: 2175360
    },
    {
      nanogenreId: 6759,
      x: 1980690,
      y: -6445740
    },
    {
      nanogenreId: 6760,
      x: 710700,
      y: -5663970
    },
    {
      nanogenreId: 6761,
      x: 3871770,
      y: -5546550
    },
    {
      nanogenreId: 6762,
      x: 2085750,
      y: -2787180
    },
    {
      nanogenreId: 6763,
      x: 3238320,
      y: -4879110
    },
    {
      nanogenreId: 6764,
      x: 3216690,
      y: -3170340
    },
    {
      nanogenreId: 6765,
      x: -2166090,
      y: -6544620
    },
    {
      nanogenreId: 6766,
      x: -6139830,
      y: -10549260
    },
    {
      nanogenreId: 6767,
      x: -988800,
      y: -4424880
    },
    {
      nanogenreId: 6768,
      x: -2543070,
      y: -10153740
    },
    {
      nanogenreId: 6769,
      x: -2079570,
      y: 9056790
    },
    {
      nanogenreId: 6770,
      x: 11918130,
      y: -4712250
    },
    {
      nanogenreId: 6771,
      x: 7687920,
      y: 2713020
    },
    {
      nanogenreId: 6772,
      x: 2209350,
      y: -9810750
    },
    {
      nanogenreId: 6773,
      x: 8256480,
      y: -5098500
    },
    {
      nanogenreId: 6774,
      x: 825030,
      y: 1473930
    },
    {
      nanogenreId: 6775,
      x: 10833540,
      y: 16423350
    },
    {
      nanogenreId: 6776,
      x: -2008500,
      y: -4156050
    },
    {
      nanogenreId: 6777,
      x: 3677100,
      y: 7477800
    },
    {
      nanogenreId: 6778,
      x: 5722680,
      y: -5667060
    },
    {
      nanogenreId: 6779,
      x: 2855160,
      y: -710700
    },
    {
      nanogenreId: 6780,
      x: -3704910,
      y: -6726930
    },
    {
      nanogenreId: 6781,
      x: 6377760,
      y: 9168030
    },
    {
      nanogenreId: 6782,
      x: 6652770,
      y: 4801860
    },
    {
      nanogenreId: 6783,
      x: 4424880,
      y: 9971430
    },
    {
      nanogenreId: 6784,
      x: -593280,
      y: 7796070
    },
    {
      nanogenreId: 6785,
      x: 4285830,
      y: 8732340
    },
    {
      nanogenreId: 6786,
      x: -6556980,
      y: -11086920
    },
    {
      nanogenreId: 6787,
      x: 10422570,
      y: -8083440
    },
    {
      nanogenreId: 6788,
      x: -3052920,
      y: 6896880
    },
    {
      nanogenreId: 6789,
      x: 1677870,
      y: -8574750
    },
    {
      nanogenreId: 6790,
      x: -4480500,
      y: 6204720
    },
    {
      nanogenreId: 6791,
      x: -4421790,
      y: -5268450
    },
    {
      nanogenreId: 6792,
      x: 8423340,
      y: 713790
    },
    {
      nanogenreId: 6793,
      x: 9229830,
      y: -7079190
    },
    {
      nanogenreId: 6794,
      x: 7369650,
      y: -2002320
    },
    {
      nanogenreId: 6795,
      x: 3028200,
      y: -3318660
    },
    {
      nanogenreId: 6796,
      x: -5648520,
      y: -1100040
    },
    {
      nanogenreId: 6797,
      x: -4434150,
      y: 2802630
    },
    {
      nanogenreId: 6798,
      x: -3000390,
      y: 6059490
    },
    {
      nanogenreId: 6799,
      x: 2178450,
      y: 293550
    },
    {
      nanogenreId: 6800,
      x: -4455780,
      y: -6807270
    },
    {
      nanogenreId: 6801,
      x: 11884140,
      y: -3868680
    },
    {
      nanogenreId: 6802,
      x: 5169570,
      y: 10101210
    },
    {
      nanogenreId: 6803,
      x: -1251450,
      y: -1291620
    },
    {
      nanogenreId: 6804,
      x: 2947860,
      y: 8664360
    },
    {
      nanogenreId: 6805,
      x: -1727310,
      y: -163770
    },
    {
      nanogenreId: 6806,
      x: -213210,
      y: -16126710
    },
    {
      nanogenreId: 6807,
      x: 5055240,
      y: 9066060
    },
    {
      nanogenreId: 6808,
      x: 9307080,
      y: 2833530
    },
    {
      nanogenreId: 6809,
      x: 7672470,
      y: -8454240
    },
    {
      nanogenreId: 6810,
      x: 815760,
      y: 7941300
    },
    {
      nanogenreId: 6811,
      x: -4332180,
      y: -1594440
    },
    {
      nanogenreId: 6812,
      x: 9526470,
      y: 2607960
    },
    {
      nanogenreId: 6813,
      x: 3544230,
      y: 7894950
    },
    {
      nanogenreId: 6814,
      x: 531480,
      y: 4730790
    },
    {
      nanogenreId: 6815,
      x: 2799540,
      y: 7385100
    },
    {
      nanogenreId: 6816,
      x: -775590,
      y: -7174980
    },
    {
      nanogenreId: 6817,
      x: 3531870,
      y: 8899200
    },
    {
      nanogenreId: 6818,
      x: -6810360,
      y: 2397840
    },
    {
      nanogenreId: 6819,
      x: -7851690,
      y: -3340290
    },
    {
      nanogenreId: 6820,
      x: 1202010,
      y: 6844350
    },
    {
      nanogenreId: 6821,
      x: -3099270,
      y: 2472000
    },
    {
      nanogenreId: 6822,
      x: 3123990,
      y: -6074940
    },
    {
      nanogenreId: 6823,
      x: 9365790,
      y: 1090770
    },
    {
      nanogenreId: 6824,
      x: 5623800,
      y: 18067230
    },
    {
      nanogenreId: 6825,
      x: 1205100,
      y: -3383550
    },
    {
      nanogenreId: 6826,
      x: -10116660,
      y: 13772130
    },
    {
      nanogenreId: 6827,
      x: 9526470,
      y: -7898040
    },
    {
      nanogenreId: 6828,
      x: -5945160,
      y: -8135970
    },
    {
      nanogenreId: 6829,
      x: 1087680,
      y: 8565480
    },
    {
      nanogenreId: 6830,
      x: -6421020,
      y: 2617230
    },
    {
      nanogenreId: 6831,
      x: 8373900,
      y: -4965630
    },
    {
      nanogenreId: 6832,
      x: 7162620,
      y: 6251070
    },
    {
      nanogenreId: 6833,
      x: 6899970,
      y: 4882200
    },
    {
      nanogenreId: 6834,
      x: -185400,
      y: 6260340
    },
    {
      nanogenreId: 6835,
      x: -2957130,
      y: 10181550
    },
    {
      nanogenreId: 6836,
      x: -4412520,
      y: 4489770
    },
    {
      nanogenreId: 6837,
      x: -624180,
      y: 333720
    },
    {
      nanogenreId: 6838,
      x: 8111250,
      y: -8018550
    },
    {
      nanogenreId: 6839,
      x: 3083820,
      y: 6890700
    },
    {
      nanogenreId: 6840,
      x: 3553500,
      y: 6798000
    },
    {
      nanogenreId: 6841,
      x: -7641570,
      y: 516030
    },
    {
      nanogenreId: 6842,
      x: 803400,
      y: -4267290
    },
    {
      nanogenreId: 6843,
      x: 1158750,
      y: 7354200
    },
    {
      nanogenreId: 6844,
      x: -15450,
      y: 4084980
    },
    {
      nanogenreId: 6845,
      x: -6918510,
      y: 10536900
    },
    {
      nanogenreId: 6846,
      x: 7094640,
      y: -7950570
    },
    {
      nanogenreId: 6847,
      x: 475860,
      y: 10994220
    },
    {
      nanogenreId: 6848,
      x: 6124380,
      y: 9891090
    },
    {
      nanogenreId: 6849,
      x: -14102760,
      y: 7162620
    },
    {
      nanogenreId: 6850,
      x: 6723840,
      y: 10648140
    },
    {
      nanogenreId: 6851,
      x: -3983010,
      y: -1093860
    },
    {
      nanogenreId: 6852,
      x: 5793750,
      y: -8166870
    },
    {
      nanogenreId: 6853,
      x: 1232910,
      y: 3565860
    },
    {
      nanogenreId: 6854,
      x: 1421400,
      y: -2224800
    },
    {
      nanogenreId: 6855,
      x: 6396300,
      y: -4038630
    },
    {
      nanogenreId: 6856,
      x: 2691390,
      y: -834300
    },
    {
      nanogenreId: 6857,
      x: -58710,
      y: 8197770
    },
    {
      nanogenreId: 6858,
      x: -3145620,
      y: -9106230
    },
    {
      nanogenreId: 6859,
      x: -9310170,
      y: 3040560
    },
    {
      nanogenreId: 6860,
      x: 2777910,
      y: 905370
    },
    {
      nanogenreId: 6861,
      x: 2138280,
      y: 2852070
    },
    {
      nanogenreId: 6862,
      x: -618000,
      y: 1736580
    },
    {
      nanogenreId: 6863,
      x: -1903440,
      y: -5787570
    },
    {
      nanogenreId: 6864,
      x: 5945160,
      y: 2141370
    },
    {
      nanogenreId: 6865,
      x: 4295100,
      y: -3034380
    },
    {
      nanogenreId: 6866,
      x: 3402090,
      y: 1081500
    },
    {
      nanogenreId: 6867,
      x: 14535360,
      y: 3130170
    },
    {
      nanogenreId: 6868,
      x: 4208580,
      y: -8262660
    },
    {
      nanogenreId: 6869,
      x: -1897260,
      y: 3327930
    },
    {
      nanogenreId: 6870,
      x: -825030,
      y: 2261880
    },
    {
      nanogenreId: 6871,
      x: 3544230,
      y: 8565480
    },
    {
      nanogenreId: 6872,
      x: -185400,
      y: -6918510
    },
    {
      nanogenreId: 6873,
      x: 1103130,
      y: 3213600
    },
    {
      nanogenreId: 6874,
      x: 3278490,
      y: 8268840
    },
    {
      nanogenreId: 6875,
      x: -11593680,
      y: -2645040
    },
    {
      nanogenreId: 6876,
      x: 8049450,
      y: -1532640
    },
    {
      nanogenreId: 6877,
      x: -9680970,
      y: -5466210
    },
    {
      nanogenreId: 6878,
      x: -2821170,
      y: 5052150
    },
    {
      nanogenreId: 6879,
      x: 92700,
      y: 8982630
    },
    {
      nanogenreId: 6880,
      x: 4934730,
      y: -7375830
    },
    {
      nanogenreId: 6881,
      x: 2095020,
      y: -7882590
    },
    {
      nanogenreId: 6882,
      x: -849750,
      y: -5787570
    },
    {
      nanogenreId: 6883,
      x: 6921600,
      y: 2827350
    },
    {
      nanogenreId: 6884,
      x: 8287380,
      y: -6903060
    },
    {
      nanogenreId: 6885,
      x: -4619550,
      y: 6794910
    },
    {
      nanogenreId: 6886,
      x: -182310,
      y: -2830440
    },
    {
      nanogenreId: 6887,
      x: 2641950,
      y: -3575130
    },
    {
      nanogenreId: 6888,
      x: -8849760,
      y: 2249520
    },
    {
      nanogenreId: 6889,
      x: -8460420,
      y: -9668610
    },
    {
      nanogenreId: 6890,
      x: 3562770,
      y: 4971810
    },
    {
      nanogenreId: 6891,
      x: 8275020,
      y: -7894950
    },
    {
      nanogenreId: 6892,
      x: -203940,
      y: -6544620
    },
    {
      nanogenreId: 6893,
      x: 12557760,
      y: -1214370
    },
    {
      nanogenreId: 6894,
      x: -2416380,
      y: -7947480
    },
    {
      nanogenreId: 6895,
      x: 5107770,
      y: -7446900
    },
    {
      nanogenreId: 6896,
      x: -5515650,
      y: 3881040
    },
    {
      nanogenreId: 6897,
      x: 5948250,
      y: -1183470
    },
    {
      nanogenreId: 6898,
      x: 7616850,
      y: -1745850
    },
    {
      nanogenreId: 6899,
      x: 6949410,
      y: 9866370
    },
    {
      nanogenreId: 6900,
      x: 392430,
      y: -930090
    },
    {
      nanogenreId: 6901,
      x: -302820,
      y: -8726160
    },
    {
      nanogenreId: 6902,
      x: -10957140,
      y: -713790
    },
    {
      nanogenreId: 6903,
      x: 3550410,
      y: 6609510
    },
    {
      nanogenreId: 6904,
      x: 1702590,
      y: -2984940
    },
    {
      nanogenreId: 6905,
      x: 1096950,
      y: -8531490
    },
    {
      nanogenreId: 6906,
      x: 4363080,
      y: 9934350
    },
    {
      nanogenreId: 6907,
      x: 1458480,
      y: -5453850
    },
    {
      nanogenreId: 6908,
      x: 1656240,
      y: -636540
    },
    {
      nanogenreId: 6909,
      x: -1409040,
      y: -10039410
    },
    {
      nanogenreId: 6910,
      x: -6192360,
      y: 9699510
    },
    {
      nanogenreId: 6911,
      x: -1168020,
      y: -6025500
    },
    {
      nanogenreId: 6912,
      x: 6260340,
      y: -7663200
    },
    {
      nanogenreId: 6913,
      x: 2873700,
      y: -4987260
    },
    {
      nanogenreId: 6914,
      x: 4356900,
      y: -4041720
    },
    {
      nanogenreId: 6915,
      x: -6257250,
      y: -1013520
    },
    {
      nanogenreId: 6916,
      x: -2400930,
      y: 9415230
    },
    {
      nanogenreId: 6917,
      x: 8055630,
      y: -6325230
    },
    {
      nanogenreId: 6918,
      x: -8726160,
      y: -12533040
    },
    {
      nanogenreId: 6919,
      x: 8525310,
      y: -7530330
    },
    {
      nanogenreId: 6920,
      x: -4375440,
      y: -8528400
    },
    {
      nanogenreId: 6921,
      x: -5629980,
      y: -10947870
    },
    {
      nanogenreId: 6922,
      x: 2181540,
      y: 599460
    },
    {
      nanogenreId: 6923,
      x: 4279650,
      y: -9186570
    },
    {
      nanogenreId: 6924,
      x: -2496720,
      y: -3229050
    },
    {
      nanogenreId: 6925,
      x: 6600240,
      y: -7499430
    },
    {
      nanogenreId: 6926,
      x: -5333340,
      y: 5049060
    },
    {
      nanogenreId: 6927,
      x: 3766710,
      y: -945540
    },
    {
      nanogenreId: 6928,
      x: -339900,
      y: 3479340
    },
    {
      nanogenreId: 6929,
      x: 10867530,
      y: -135960
    },
    {
      nanogenreId: 6930,
      x: -1714950,
      y: -3581310
    },
    {
      nanogenreId: 6931,
      x: 1881810,
      y: -8559300
    },
    {
      nanogenreId: 6932,
      x: 12783330,
      y: -3170340
    },
    {
      nanogenreId: 6933,
      x: 1714950,
      y: 8809590
    },
    {
      nanogenreId: 6934,
      x: 9396690,
      y: -4987260
    },
    {
      nanogenreId: 6935,
      x: 8775600,
      y: -6912330
    },
    {
      nanogenreId: 6936,
      x: -7545780,
      y: 2413290
    },
    {
      nanogenreId: 6937,
      x: 7697190,
      y: -5478570
    },
    {
      nanogenreId: 6938,
      x: 2314410,
      y: -7623030
    },
    {
      nanogenreId: 6939,
      x: -10796460,
      y: -2750100
    },
    {
      nanogenreId: 6940,
      x: 5657790,
      y: 9752040
    },
    {
      nanogenreId: 6941,
      x: -2227890,
      y: -6816540
    },
    {
      nanogenreId: 6942,
      x: 1807650,
      y: 3825420
    },
    {
      nanogenreId: 6943,
      x: -497490,
      y: -7641570
    },
    {
      nanogenreId: 6944,
      x: -10348410,
      y: -1452300
    },
    {
      nanogenreId: 6945,
      x: -5645430,
      y: 6726930
    },
    {
      nanogenreId: 6946,
      x: -3933570,
      y: -8627280
    },
    {
      nanogenreId: 6947,
      x: 5639250,
      y: 9504840
    },
    {
      nanogenreId: 6948,
      x: -15450,
      y: -6587880
    },
    {
      nanogenreId: 6949,
      x: 4316730,
      y: 4953270
    },
    {
      nanogenreId: 6950,
      x: 8120520,
      y: 3680190
    },
    {
      nanogenreId: 6951,
      x: 4347630,
      y: -5271540
    },
    {
      nanogenreId: 6952,
      x: 13265370,
      y: 5589810
    },
    {
      nanogenreId: 6953,
      x: 4996530,
      y: -10867530
    },
    {
      nanogenreId: 6954,
      x: 5293170,
      y: 8129790
    },
    {
      nanogenreId: 6955,
      x: -1402860,
      y: 2277330
    },
    {
      nanogenreId: 6956,
      x: 2536890,
      y: -98880
    },
    {
      nanogenreId: 6957,
      x: 2654310,
      y: -3788340
    },
    {
      nanogenreId: 6958,
      x: -2787180,
      y: 9594450
    },
    {
      nanogenreId: 6959,
      x: 4789500,
      y: 10209360
    },
    {
      nanogenreId: 6960,
      x: 14597160,
      y: -3139440
    },
    {
      nanogenreId: 6961,
      x: 8015460,
      y: 444960
    },
    {
      nanogenreId: 6962,
      x: -11164170,
      y: -4641180
    },
    {
      nanogenreId: 6963,
      x: 8383170,
      y: -4251840
    },
    {
      nanogenreId: 6964,
      x: -6451920,
      y: 2351490
    },
    {
      nanogenreId: 6965,
      x: 3371190,
      y: -4276560
    },
    {
      nanogenreId: 6966,
      x: 1838550,
      y: -2493630
    },
    {
      nanogenreId: 6967,
      x: 8503680,
      y: -6183090
    },
    {
      nanogenreId: 6968,
      x: 4350720,
      y: 1368870
    },
    {
      nanogenreId: 6969,
      x: 6489000,
      y: -4625730
    },
    {
      nanogenreId: 6970,
      x: 2675940,
      y: 3148710
    },
    {
      nanogenreId: 6971,
      x: 14832000,
      y: -2289690
    },
    {
      nanogenreId: 6972,
      x: 9903450,
      y: -5904990
    },
    {
      nanogenreId: 6973,
      x: 6433380,
      y: 7959840
    },
    {
      nanogenreId: 6974,
      x: 571650,
      y: -4913100
    },
    {
      nanogenreId: 6975,
      x: -3958290,
      y: 6498270
    },
    {
      nanogenreId: 6976,
      x: 5657790,
      y: -8621100
    },
    {
      nanogenreId: 6977,
      x: 3318660,
      y: 9022800
    },
    {
      nanogenreId: 6978,
      x: 6421020,
      y: 6374670
    },
    {
      nanogenreId: 6979,
      x: 8352270,
      y: -4403250
    },
    {
      nanogenreId: 6980,
      x: 500580,
      y: -2994210
    },
    {
      nanogenreId: 6981,
      x: 2129010,
      y: -4495950
    },
    {
      nanogenreId: 6982,
      x: -3442260,
      y: -4563930
    },
    {
      nanogenreId: 6983,
      x: 1050600,
      y: -9044430
    },
    {
      nanogenreId: 6984,
      x: 2042490,
      y: -2138280
    },
    {
      nanogenreId: 6985,
      x: 6158370,
      y: 6387030
    },
    {
      nanogenreId: 6986,
      x: 1331790,
      y: 5039790
    },
    {
      nanogenreId: 6987,
      x: 12007740,
      y: -6331410
    },
    {
      nanogenreId: 6988,
      x: 1273080,
      y: -6584790
    },
    {
      nanogenreId: 6989,
      x: 729240,
      y: 1260720
    },
    {
      nanogenreId: 6990,
      x: 86520,
      y: 2824260
    },
    {
      nanogenreId: 6991,
      x: -1578990,
      y: 3386640
    },
    {
      nanogenreId: 6992,
      x: 2777910,
      y: -5562000
    },
    {
      nanogenreId: 6993,
      x: 6992670,
      y: -6668220
    },
    {
      nanogenreId: 6994,
      x: -11732730,
      y: -12740070
    },
    {
      nanogenreId: 6995,
      x: -5058330,
      y: 2116650
    },
    {
      nanogenreId: 6996,
      x: 2781000,
      y: 8769420
    },
    {
      nanogenreId: 6997,
      x: -9359610,
      y: -5793750
    },
    {
      nanogenreId: 6998,
      x: -837390,
      y: 8602560
    },
    {
      nanogenreId: 6999,
      x: 2546160,
      y: -4539210
    },
    {
      nanogenreId: 7000,
      x: -67980,
      y: 11933580
    },
    {
      nanogenreId: 7001,
      x: -46350,
      y: -89610
    },
    {
      nanogenreId: 7002,
      x: 3531870,
      y: 10054860
    },
    {
      nanogenreId: 7003,
      x: -10672860,
      y: -4084980
    },
    {
      nanogenreId: 7004,
      x: 960990,
      y: 6464280
    },
    {
      nanogenreId: 7005,
      x: 4363080,
      y: -1523370
    },
    {
      nanogenreId: 7006,
      x: -98880,
      y: 3142530
    },
    {
      nanogenreId: 7007,
      x: 475860,
      y: 9186570
    },
    {
      nanogenreId: 7008,
      x: 3748170,
      y: 8908470
    },
    {
      nanogenreId: 7009,
      x: 6161460,
      y: -8037090
    },
    {
      nanogenreId: 7010,
      x: 815760,
      y: -10645050
    },
    {
      nanogenreId: 7011,
      x: 10438020,
      y: 154500
    },
    {
      nanogenreId: 7012,
      x: -8318280,
      y: -3083820
    },
    {
      nanogenreId: 7013,
      x: 5460030,
      y: -8237940
    },
    {
      nanogenreId: 7014,
      x: -577830,
      y: -4499040
    },
    {
      nanogenreId: 7015,
      x: -2629590,
      y: 3698730
    },
    {
      nanogenreId: 7016,
      x: -8571660,
      y: -4891470
    },
    {
      nanogenreId: 7017,
      x: -3936660,
      y: -191580
    },
    {
      nanogenreId: 7018,
      x: 6708390,
      y: 9807660
    },
    {
      nanogenreId: 7019,
      x: 4390890,
      y: -608730
    },
    {
      nanogenreId: 7020,
      x: -1711860,
      y: -5422950
    },
    {
      nanogenreId: 7021,
      x: -831210,
      y: -5129400
    },
    {
      nanogenreId: 7022,
      x: -11062200,
      y: -4211670
    },
    {
      nanogenreId: 7023,
      x: -14581710,
      y: -6405570
    },
    {
      nanogenreId: 7024,
      x: 5506380,
      y: 7925850
    },
    {
      nanogenreId: 7025,
      x: -4770960,
      y: 5861730
    },
    {
      nanogenreId: 7026,
      x: 3825420,
      y: 8324460
    },
    {
      nanogenreId: 7027,
      x: 197760,
      y: -6637320
    },
    {
      nanogenreId: 7028,
      x: 2864430,
      y: -1829280
    },
    {
      nanogenreId: 7029,
      x: -1730400,
      y: -7786800
    },
    {
      nanogenreId: 7030,
      x: -2697570,
      y: 3779070
    },
    {
      nanogenreId: 7031,
      x: 6739290,
      y: -7743540
    },
    {
      nanogenreId: 7032,
      x: 5506380,
      y: 10573980
    },
    {
      nanogenreId: 7033,
      x: -9473940,
      y: -9010440
    },
    {
      nanogenreId: 7034,
      x: 8373900,
      y: -7465440
    },
    {
      nanogenreId: 7035,
      x: -3331020,
      y: 8253390
    },
    {
      nanogenreId: 7036,
      x: 6822720,
      y: -4199310
    },
    {
      nanogenreId: 7037,
      x: -4010820,
      y: 11161080
    },
    {
      nanogenreId: 7038,
      x: -3093090,
      y: 3012750
    },
    {
      nanogenreId: 7039,
      x: 241020,
      y: 1473930
    },
    {
      nanogenreId: 7040,
      x: 4397070,
      y: 10150650
    },
    {
      nanogenreId: 7041,
      x: 2669760,
      y: 10339140
    },
    {
      nanogenreId: 7042,
      x: -14158380,
      y: 5293170
    },
    {
      nanogenreId: 7043,
      x: 580920,
      y: -10336050
    },
    {
      nanogenreId: 7044,
      x: -83430,
      y: -6213990
    },
    {
      nanogenreId: 7045,
      x: 3056010,
      y: 11046750
    },
    {
      nanogenreId: 7046,
      x: 6037860,
      y: 5963700
    },
    {
      nanogenreId: 7047,
      x: 4820400,
      y: -4616460
    },
    {
      nanogenreId: 7048,
      x: 942450,
      y: 8043270
    },
    {
      nanogenreId: 7049,
      x: 5228280,
      y: 10011600
    },
    {
      nanogenreId: 7050,
      x: 330630,
      y: -6779460
    },
    {
      nanogenreId: 7051,
      x: 852840,
      y: 985710
    },
    {
      nanogenreId: 7052,
      x: 6034770,
      y: -10129020
    },
    {
      nanogenreId: 7053,
      x: 2057940,
      y: 10311330
    },
    {
      nanogenreId: 7054,
      x: 3989190,
      y: -4326000
    },
    {
      nanogenreId: 7055,
      x: 3704910,
      y: 398610
    },
    {
      nanogenreId: 7056,
      x: 8794140,
      y: -7073010
    },
    {
      nanogenreId: 7057,
      x: -5799930,
      y: 2237160
    },
    {
      nanogenreId: 7058,
      x: -9643890,
      y: -2518350
    },
    {
      nanogenreId: 7059,
      x: -2348400,
      y: 6702210
    },
    {
      nanogenreId: 7060,
      x: -3952110,
      y: -4579380
    },
    {
      nanogenreId: 7061,
      x: 1810740,
      y: 151410
    },
    {
      nanogenreId: 7062,
      x: -3225960,
      y: 4607190
    },
    {
      nanogenreId: 7063,
      x: -2663580,
      y: 7280040
    },
    {
      nanogenreId: 7064,
      x: 5886450,
      y: 10657410
    },
    {
      nanogenreId: 7065,
      x: 5827740,
      y: 8265750
    },
    {
      nanogenreId: 7066,
      x: 1965240,
      y: 8546940
    },
    {
      nanogenreId: 7067,
      x: 6099660,
      y: 8982630
    },
    {
      nanogenreId: 7068,
      x: 139050,
      y: -7823880
    },
    {
      nanogenreId: 7069,
      x: -546930,
      y: 6300510
    },
    {
      nanogenreId: 7070,
      x: 5395140,
      y: -4375440
    },
    {
      nanogenreId: 7071,
      x: -4017000,
      y: -1767480
    },
    {
      nanogenreId: 7072,
      x: -2524530,
      y: -2833530
    },
    {
      nanogenreId: 7073,
      x: 5024340,
      y: 4233300
    },
    {
      nanogenreId: 7074,
      x: -6640410,
      y: -6742380
    },
    {
      nanogenreId: 7075,
      x: -2141370,
      y: 1189650
    },
    {
      nanogenreId: 7076,
      x: -3386640,
      y: 985710
    },
    {
      nanogenreId: 7077,
      x: 5280810,
      y: 8336820
    },
    {
      nanogenreId: 7078,
      x: 5151030,
      y: 8800320
    },
    {
      nanogenreId: 7079,
      x: 10425660,
      y: -6723840
    },
    {
      nanogenreId: 7080,
      x: 580920,
      y: 8682900
    },
    {
      nanogenreId: 7081,
      x: -2960220,
      y: 8503680
    },
    {
      nanogenreId: 7082,
      x: 8976450,
      y: -5228280
    },
    {
      nanogenreId: 7083,
      x: 12072630,
      y: -559290
    },
    {
      nanogenreId: 7084,
      x: -945540,
      y: 3813060
    },
    {
      nanogenreId: 7085,
      x: -1461570,
      y: 10135200
    },
    {
      nanogenreId: 7086,
      x: 2413290,
      y: -5889540
    },
    {
      nanogenreId: 7087,
      x: 4928550,
      y: 8933190
    },
    {
      nanogenreId: 7088,
      x: 8182320,
      y: -1520280
    },
    {
      nanogenreId: 7089,
      x: -8380080,
      y: 1606800
    },
    {
      nanogenreId: 7090,
      x: -5302440,
      y: -531480
    },
    {
      nanogenreId: 7091,
      x: -324450,
      y: 6791820
    },
    {
      nanogenreId: 7092,
      x: -6964860,
      y: -4038630
    },
    {
      nanogenreId: 7093,
      x: -2181540,
      y: 5206650
    },
    {
      nanogenreId: 7094,
      x: -4156050,
      y: -5528010
    },
    {
      nanogenreId: 7095,
      x: -3331020,
      y: 4050990
    },
    {
      nanogenreId: 7096,
      x: 3114720,
      y: 9650070
    },
    {
      nanogenreId: 7097,
      x: 5633070,
      y: -4814220
    },
    {
      nanogenreId: 7098,
      x: -2119740,
      y: -8111250
    },
    {
      nanogenreId: 7099,
      x: -3627660,
      y: -2323680
    },
    {
      nanogenreId: 7100,
      x: -2005410,
      y: 5419860
    },
    {
      nanogenreId: 7101,
      x: -4511400,
      y: -2348400
    },
    {
      nanogenreId: 7102,
      x: 5583630,
      y: 10422570
    },
    {
      nanogenreId: 7103,
      x: -1980690,
      y: 8893020
    },
    {
      nanogenreId: 7104,
      x: -1826190,
      y: -9035160
    },
    {
      nanogenreId: 7105,
      x: 4916190,
      y: -1470840
    },
    {
      nanogenreId: 7106,
      x: 4687530,
      y: 10419480
    },
    {
      nanogenreId: 7107,
      x: 398610,
      y: 5286990
    },
    {
      nanogenreId: 7108,
      x: -4440330,
      y: -6408660
    },
    {
      nanogenreId: 7109,
      x: 3992280,
      y: 18450390
    },
    {
      nanogenreId: 7110,
      x: 4879110,
      y: -6192360
    },
    {
      nanogenreId: 7111,
      x: 8772510,
      y: 4502130
    },
    {
      nanogenreId: 7112,
      x: 5413680,
      y: 8135970
    },
    {
      nanogenreId: 7113,
      x: 6807270,
      y: -3442260
    },
    {
      nanogenreId: 7114,
      x: -11674020,
      y: -12091170
    },
    {
      nanogenreId: 7115,
      x: 5540370,
      y: -5401320
    },
    {
      nanogenreId: 7116,
      x: 4876020,
      y: 5562000
    },
    {
      nanogenreId: 7117,
      x: 4174590,
      y: 8485140
    },
    {
      nanogenreId: 7118,
      x: -1974510,
      y: -4288920
    },
    {
      nanogenreId: 7119,
      x: -3562770,
      y: 834300
    },
    {
      nanogenreId: 7120,
      x: 12965640,
      y: 2583240
    },
    {
      nanogenreId: 7121,
      x: -5104680,
      y: -1687140
    },
    {
      nanogenreId: 7122,
      x: -5271540,
      y: 4622640
    },
    {
      nanogenreId: 7123,
      x: 10036320,
      y: 278100
    },
    {
      nanogenreId: 7124,
      x: 2413290,
      y: -6609510
    },
    {
      nanogenreId: 7125,
      x: -2700660,
      y: 3485520
    },
    {
      nanogenreId: 7126,
      x: 6464280,
      y: -5154120
    },
    {
      nanogenreId: 7127,
      x: 3408270,
      y: 4792590
    },
    {
      nanogenreId: 7128,
      x: -1866360,
      y: 457320
    },
    {
      nanogenreId: 7129,
      x: -4944000,
      y: 5982240
    },
    {
      nanogenreId: 7130,
      x: 8083440,
      y: -4465050
    },
    {
      nanogenreId: 7131,
      x: -4128240,
      y: -2212440
    },
    {
      nanogenreId: 7132,
      x: 3334110,
      y: 509850
    },
    {
      nanogenreId: 7133,
      x: 352260,
      y: 9684060
    },
    {
      nanogenreId: 7134,
      x: -2496720,
      y: 509850
    },
    {
      nanogenreId: 7135,
      x: -1733490,
      y: -7913490
    },
    {
      nanogenreId: 7136,
      x: 9733500,
      y: 1711860
    },
    {
      nanogenreId: 7137,
      x: 5886450,
      y: 8364630
    },
    {
      nanogenreId: 7138,
      x: -4171500,
      y: 645810
    },
    {
      nanogenreId: 7139,
      x: -2836620,
      y: 766320
    },
    {
      nanogenreId: 7140,
      x: 1174200,
      y: -5426040
    },
    {
      nanogenreId: 7141,
      x: 2206260,
      y: 4709160
    },
    {
      nanogenreId: 7142,
      x: -1050600,
      y: -3698730
    },
    {
      nanogenreId: 7143,
      x: 4993440,
      y: 9680970
    },
    {
      nanogenreId: 7144,
      x: -5639250,
      y: 5243730
    },
    {
      nanogenreId: 7145,
      x: 7629210,
      y: -7344930
    },
    {
      nanogenreId: 7146,
      x: -2666670,
      y: 2169180
    },
    {
      nanogenreId: 7147,
      x: -1362690,
      y: -1112400
    },
    {
      nanogenreId: 7148,
      x: -1646970,
      y: 13744320
    },
    {
      nanogenreId: 7149,
      x: -10339140,
      y: -4384710
    },
    {
      nanogenreId: 7150,
      x: 3303210,
      y: 2410200
    },
    {
      nanogenreId: 7151,
      x: 1269990,
      y: 6708390
    },
    {
      nanogenreId: 7152,
      x: 5824650,
      y: 3337200
    },
    {
      nanogenreId: 7153,
      x: 6146010,
      y: 10274250
    },
    {
      nanogenreId: 7154,
      x: 6294330,
      y: 17341080
    },
    {
      nanogenreId: 7155,
      x: -3253770,
      y: 4971810
    },
    {
      nanogenreId: 7156,
      x: -3216690,
      y: 2623410
    },
    {
      nanogenreId: 7157,
      x: 5997690,
      y: 8556210
    },
    {
      nanogenreId: 7158,
      x: -4054080,
      y: 4594830
    },
    {
      nanogenreId: 7159,
      x: 8516040,
      y: -9316350
    },
    {
      nanogenreId: 7160,
      x: -105060,
      y: -1594440
    },
    {
      nanogenreId: 7161,
      x: -13466220,
      y: -5058330
    },
    {
      nanogenreId: 7162,
      x: 1761300,
      y: -2326770
    },
    {
      nanogenreId: 7163,
      x: -3649290,
      y: 1443030
    },
    {
      nanogenreId: 7164,
      x: 531480,
      y: 6696030
    },
    {
      nanogenreId: 7165,
      x: -6068760,
      y: 497490
    },
    {
      nanogenreId: 7166,
      x: -2524530,
      y: 105060
    },
    {
      nanogenreId: 7167,
      x: -1140210,
      y: -3090000
    },
    {
      nanogenreId: 7168,
      x: -7023570,
      y: 3544230
    },
    {
      nanogenreId: 7169,
      x: -3837780,
      y: 5432220
    },
    {
      nanogenreId: 7170,
      x: 2805720,
      y: 8160690
    },
    {
      nanogenreId: 7171,
      x: 4508310,
      y: 9906540
    },
    {
      nanogenreId: 7172,
      x: 7780620,
      y: -6241800
    },
    {
      nanogenreId: 7173,
      x: 2283510,
      y: 9093870
    },
    {
      nanogenreId: 7174,
      x: 6291240,
      y: 18564720
    },
    {
      nanogenreId: 7175,
      x: -1285440,
      y: -6813450
    },
    {
      nanogenreId: 7176,
      x: 5327160,
      y: -9452310
    },
    {
      nanogenreId: 7177,
      x: 3525690,
      y: -11581320
    },
    {
      nanogenreId: 7178,
      x: -9816930,
      y: -6374670
    },
    {
      nanogenreId: 7179,
      x: -222480,
      y: -3448440
    },
    {
      nanogenreId: 7180,
      x: -7823880,
      y: 4529940
    },
    {
      nanogenreId: 7181,
      x: 6343770,
      y: -7947480
    },
    {
      nanogenreId: 7182,
      x: -6739290,
      y: -2292780
    },
    {
      nanogenreId: 7183,
      x: -7496340,
      y: -945540
    },
    {
      nanogenreId: 7184,
      x: -4869840,
      y: 979530
    },
    {
      nanogenreId: 7185,
      x: 4962540,
      y: 8763240
    },
    {
      nanogenreId: 7186,
      x: 11599860,
      y: -7301670
    },
    {
      nanogenreId: 7187,
      x: 3887220,
      y: 7453080
    },
    {
      nanogenreId: 7188,
      x: 4443420,
      y: 9257640
    },
    {
      nanogenreId: 7189,
      x: 1096950,
      y: 5988420
    },
    {
      nanogenreId: 7190,
      x: -1980690,
      y: 5861730
    },
    {
      nanogenreId: 7191,
      x: 1371960,
      y: 6170730
    },
    {
      nanogenreId: 7192,
      x: 1881810,
      y: -4313640
    },
    {
      nanogenreId: 7193,
      x: 3834690,
      y: -4248750
    },
    {
      nanogenreId: 7194,
      x: 2070300,
      y: 1554270
    },
    {
      nanogenreId: 7195,
      x: 12258030,
      y: -5045970
    },
    {
      nanogenreId: 7196,
      x: -1915800,
      y: -58710
    },
    {
      nanogenreId: 7197,
      x: -639630,
      y: -8237940
    },
    {
      nanogenreId: 7198,
      x: 5002710,
      y: 9214380
    },
    {
      nanogenreId: 7199,
      x: -1974510,
      y: -1248360
    },
    {
      nanogenreId: 7200,
      x: 14099670,
      y: 3093090
    },
    {
      nanogenreId: 7201,
      x: 4140600,
      y: 3225960
    },
    {
      nanogenreId: 7202,
      x: 9690240,
      y: -4097340
    },
    {
      nanogenreId: 7203,
      x: 5249910,
      y: -8791050
    },
    {
      nanogenreId: 7204,
      x: -9270,
      y: -5172660
    },
    {
      nanogenreId: 7205,
      x: 6603330,
      y: 4208580
    },
    {
      nanogenreId: 7206,
      x: -5772120,
      y: 1198920
    },
    {
      nanogenreId: 7207,
      x: 3979920,
      y: 21630
    },
    {
      nanogenreId: 7208,
      x: -5824650,
      y: 4168410
    },
    {
      nanogenreId: 7209,
      x: -1195830,
      y: 5147940
    },
    {
      nanogenreId: 7210,
      x: 6152190,
      y: 9365790
    },
    {
      nanogenreId: 7211,
      x: -6575520,
      y: -3902670
    },
    {
      nanogenreId: 7212,
      x: 5286990,
      y: -10672860
    },
    {
      nanogenreId: 7213,
      x: -1826190,
      y: -1486290
    },
    {
      nanogenreId: 7214,
      x: 3899580,
      y: 9032070
    },
    {
      nanogenreId: 7215,
      x: 7545780,
      y: -3809970
    },
    {
      nanogenreId: 7216,
      x: 3448440,
      y: 1600620
    },
    {
      nanogenreId: 7217,
      x: 5049060,
      y: 8704530
    },
    {
      nanogenreId: 7218,
      x: -10073400,
      y: -222480
    },
    {
      nanogenreId: 7219,
      x: -2230980,
      y: -3967560
    },
    {
      nanogenreId: 7220,
      x: -1118580,
      y: -262650
    },
    {
      nanogenreId: 7221,
      x: 5738130,
      y: 7174980
    },
    {
      nanogenreId: 7222,
      x: 3633840,
      y: -2502900
    },
    {
      nanogenreId: 7223,
      x: 3831600,
      y: 4699890
    },
    {
      nanogenreId: 7224,
      x: -8268840,
      y: -4329090
    },
    {
      nanogenreId: 7225,
      x: 4668990,
      y: 1612980
    },
    {
      nanogenreId: 7226,
      x: -5982240,
      y: -8321370
    },
    {
      nanogenreId: 7227,
      x: -6847440,
      y: 4789500
    },
    {
      nanogenreId: 7228,
      x: 10382400,
      y: -7885680
    },
    {
      nanogenreId: 7229,
      x: 3658560,
      y: -3918120
    },
    {
      nanogenreId: 7230,
      x: -262650,
      y: 6451920
    },
    {
      nanogenreId: 7231,
      x: -89610,
      y: 7471620
    },
    {
      nanogenreId: 7232,
      x: 939360,
      y: 5441490
    },
    {
      nanogenreId: 7233,
      x: -8315190,
      y: 6275790
    },
    {
      nanogenreId: 7234,
      x: 2567790,
      y: 7082280
    },
    {
      nanogenreId: 7235,
      x: 4400160,
      y: 8444970
    },
    {
      nanogenreId: 7236,
      x: -4427970,
      y: 1789110
    },
    {
      nanogenreId: 7237,
      x: -6822720,
      y: 2932410
    },
    {
      nanogenreId: 7238,
      x: 2777910,
      y: -441870
    },
    {
      nanogenreId: 7239,
      x: 5481660,
      y: -4548480
    },
    {
      nanogenreId: 7240,
      x: 1313250,
      y: 9084600
    },
    {
      nanogenreId: 7241,
      x: 5351880,
      y: -994980
    },
    {
      nanogenreId: 7242,
      x: -4418700,
      y: 10360770
    },
    {
      nanogenreId: 7243,
      x: -2054850,
      y: 4869840
    },
    {
      nanogenreId: 7244,
      x: 200850,
      y: -905370
    },
    {
      nanogenreId: 7245,
      x: 543840,
      y: -2200080
    },
    {
      nanogenreId: 7246,
      x: 3513330,
      y: 837390
    },
    {
      nanogenreId: 7247,
      x: -3012750,
      y: 5373510
    },
    {
      nanogenreId: 7248,
      x: -3717270,
      y: 1300890
    },
    {
      nanogenreId: 7249,
      x: 2524530,
      y: 7440720
    },
    {
      nanogenreId: 7250,
      x: 661260,
      y: -4733880
    },
    {
      nanogenreId: 7251,
      x: 7508700,
      y: -5830830
    },
    {
      nanogenreId: 7252,
      x: 148320,
      y: -3942840
    },
    {
      nanogenreId: 7253,
      x: 3711090,
      y: -4594830
    },
    {
      nanogenreId: 7254,
      x: -4696800,
      y: 4579380
    },
    {
      nanogenreId: 7255,
      x: -6865980,
      y: 4329090
    },
    {
      nanogenreId: 7256,
      x: -1844730,
      y: 11454630
    },
    {
      nanogenreId: 7257,
      x: 1359600,
      y: 3794520
    },
    {
      nanogenreId: 7258,
      x: 3723450,
      y: 9634620
    },
    {
      nanogenreId: 7259,
      x: 1501740,
      y: 7412910
    },
    {
      nanogenreId: 7260,
      x: 4017000,
      y: -2292780
    },
    {
      nanogenreId: 7261,
      x: -7252230,
      y: 5799930
    },
    {
      nanogenreId: 7262,
      x: -6884520,
      y: -11470080
    },
    {
      nanogenreId: 7263,
      x: -4520670,
      y: -11324850
    },
    {
      nanogenreId: 7264,
      x: -14754750,
      y: -7728090
    },
    {
      nanogenreId: 7265,
      x: -2938590,
      y: 5546550
    },
    {
      nanogenreId: 7266,
      x: -4307460,
      y: 3454620
    },
    {
      nanogenreId: 7267,
      x: -10169190,
      y: 540750
    },
    {
      nanogenreId: 7268,
      x: -228660,
      y: -3680190
    },
    {
      nanogenreId: 7269,
      x: 8611830,
      y: -7607580
    },
    {
      nanogenreId: 7270,
      x: 5531100,
      y: 9625350
    },
    {
      nanogenreId: 7271,
      x: 6927780,
      y: -8580930
    },
    {
      nanogenreId: 7272,
      x: 3534960,
      y: 2465820
    },
    {
      nanogenreId: 7273,
      x: -5741220,
      y: -3748170
    },
    {
      nanogenreId: 7274,
      x: -6405570,
      y: 3232140
    },
    {
      nanogenreId: 7275,
      x: -2286600,
      y: -1541910
    },
    {
      nanogenreId: 7276,
      x: -8716890,
      y: 7854780
    },
    {
      nanogenreId: 7277,
      x: -8271930,
      y: -8361540
    },
    {
      nanogenreId: 7278,
      x: -556200,
      y: 5972970
    },
    {
      nanogenreId: 7279,
      x: 7542690,
      y: -383160
    },
    {
      nanogenreId: 7280,
      x: 4517580,
      y: -7230600
    },
    {
      nanogenreId: 7281,
      x: 1371960,
      y: -3114720
    },
    {
      nanogenreId: 7282,
      x: 10509090,
      y: -4900740
    },
    {
      nanogenreId: 7283,
      x: -7672470,
      y: -5262270
    },
    {
      nanogenreId: 7284,
      x: 7595220,
      y: -5305530
    },
    {
      nanogenreId: 7285,
      x: -2845890,
      y: -3516420
    },
    {
      nanogenreId: 7286,
      x: 52530,
      y: 417150
    },
    {
      nanogenreId: 7287,
      x: -2413290,
      y: 6056400
    },
    {
      nanogenreId: 7288,
      x: 4174590,
      y: 7848600
    },
    {
      nanogenreId: 7289,
      x: -3553500,
      y: 448050
    },
    {
      nanogenreId: 7290,
      x: -4597920,
      y: 3352650
    },
    {
      nanogenreId: 7291,
      x: 1616070,
      y: -4876020
    },
    {
      nanogenreId: 7292,
      x: 4066440,
      y: 7962930
    },
    {
      nanogenreId: 7293,
      x: -1477020,
      y: -2175360
    },
    {
      nanogenreId: 7294,
      x: 9903450,
      y: 16778700
    },
    {
      nanogenreId: 7295,
      x: -8695260,
      y: -271920
    },
    {
      nanogenreId: 7296,
      x: 633450,
      y: -5206650
    },
    {
      nanogenreId: 7297,
      x: -2295870,
      y: -991890
    },
    {
      nanogenreId: 7298,
      x: 4140600,
      y: 5036700
    },
    {
      nanogenreId: 7299,
      x: 9396690,
      y: -7660110
    },
    {
      nanogenreId: 7300,
      x: 2351490,
      y: 17137140
    },
    {
      nanogenreId: 7301,
      x: -701430,
      y: 3983010
    },
    {
      nanogenreId: 7302,
      x: 5704140,
      y: 7944390
    },
    {
      nanogenreId: 7303,
      x: 1356510,
      y: -5107770
    },
    {
      nanogenreId: 7304,
      x: 5385870,
      y: 8420250
    },
    {
      nanogenreId: 7305,
      x: 3334110,
      y: 11426820
    },
    {
      nanogenreId: 7306,
      x: -6387030,
      y: -6495180
    },
    {
      nanogenreId: 7307,
      x: 4017000,
      y: -6078030
    },
    {
      nanogenreId: 7308,
      x: -525300,
      y: -2929320
    },
    {
      nanogenreId: 7309,
      x: 565470,
      y: 7647750
    },
    {
      nanogenreId: 7310,
      x: 6652770,
      y: -426420
    },
    {
      nanogenreId: 7311,
      x: -9297810,
      y: -98880
    },
    {
      nanogenreId: 7312,
      x: 5685600,
      y: 6986490
    },
    {
      nanogenreId: 7313,
      x: 3568950,
      y: 11992290
    },
    {
      nanogenreId: 7314,
      x: -10023960,
      y: -2323680
    },
    {
      nanogenreId: 7315,
      x: -14424120,
      y: -5985330
    },
    {
      nanogenreId: 7316,
      x: 3497880,
      y: 7718820
    },
    {
      nanogenreId: 7317,
      x: 6340680,
      y: -4264200
    },
    {
      nanogenreId: 7318,
      x: -349170,
      y: -4900740
    },
    {
      nanogenreId: 7319,
      x: 862110,
      y: -1155660
    },
    {
      nanogenreId: 7320,
      x: 4755510,
      y: -6402480
    },
    {
      nanogenreId: 7321,
      x: 1288530,
      y: 3275400
    },
    {
      nanogenreId: 7322,
      x: 5151030,
      y: 9245280
    },
    {
      nanogenreId: 7323,
      x: -9928170,
      y: -2583240
    },
    {
      nanogenreId: 7324,
      x: -414060,
      y: 7026660
    },
    {
      nanogenreId: 7325,
      x: 5388960,
      y: 9384330
    },
    {
      nanogenreId: 7326,
      x: 7057560,
      y: 3939750
    },
    {
      nanogenreId: 7327,
      x: -11760540,
      y: 781770
    },
    {
      nanogenreId: 7328,
      x: 5531100,
      y: -1946700
    },
    {
      nanogenreId: 7329,
      x: 2268060,
      y: 8692170
    },
    {
      nanogenreId: 7330,
      x: -11874870,
      y: -4857480
    },
    {
      nanogenreId: 7331,
      x: 1075320,
      y: -2984940
    },
    {
      nanogenreId: 7332,
      x: 4780230,
      y: 5531100
    },
    {
      nanogenreId: 7333,
      x: 763230,
      y: -8794140
    },
    {
      nanogenreId: 7334,
      x: -6578610,
      y: 11034390
    },
    {
      nanogenreId: 7335,
      x: 5373510,
      y: 9220560
    },
    {
      nanogenreId: 7336,
      x: -3093090,
      y: 7094640
    },
    {
      nanogenreId: 7337,
      x: -5867910,
      y: -3269220
    },
    {
      nanogenreId: 7338,
      x: -7131720,
      y: 95790
    },
    {
      nanogenreId: 7339,
      x: 2688300,
      y: -3225960
    },
    {
      nanogenreId: 7340,
      x: -4582470,
      y: 1857090
    },
    {
      nanogenreId: 7341,
      x: 7808430,
      y: -4041720
    },
    {
      nanogenreId: 7342,
      x: 494400,
      y: 7740450
    },
    {
      nanogenreId: 7343,
      x: 1854000,
      y: 6649680
    },
    {
      nanogenreId: 7344,
      x: 101970,
      y: -2326770
    },
    {
      nanogenreId: 7345,
      x: -13750500,
      y: -6652770
    },
    {
      nanogenreId: 7346,
      x: -9001170,
      y: 4001550
    },
    {
      nanogenreId: 7347,
      x: -2144460,
      y: 3729630
    },
    {
      nanogenreId: 7348,
      x: 2994210,
      y: 5070690
    },
    {
      nanogenreId: 7349,
      x: -1659330,
      y: 880650
    },
    {
      nanogenreId: 7350,
      x: -11924310,
      y: -2728470
    },
    {
      nanogenreId: 7351,
      x: 4944000,
      y: -5144850
    },
    {
      nanogenreId: 7352,
      x: 1013520,
      y: 1959060
    },
    {
      nanogenreId: 7353,
      x: -4406340,
      y: 5228280
    },
    {
      nanogenreId: 7354,
      x: -3145620,
      y: 10370040
    },
    {
      nanogenreId: 7355,
      x: 3485520,
      y: 9288540
    },
    {
      nanogenreId: 7356,
      x: -5611440,
      y: 1044420
    },
    {
      nanogenreId: 7357,
      x: 3445350,
      y: -3324840
    },
    {
      nanogenreId: 7358,
      x: 3547320,
      y: 10289700
    },
    {
      nanogenreId: 7359,
      x: 667440,
      y: 5562000
    },
    {
      nanogenreId: 7360,
      x: -2833530,
      y: 3269220
    },
    {
      nanogenreId: 7361,
      x: 2178450,
      y: -4097340
    },
    {
      nanogenreId: 7362,
      x: 5113950,
      y: 9557370
    },
    {
      nanogenreId: 7363,
      x: 3794520,
      y: 10342230
    },
    {
      nanogenreId: 7364,
      x: -2373120,
      y: -1269990
    },
    {
      nanogenreId: 7365,
      x: -11652390,
      y: -1792200
    },
    {
      nanogenreId: 7366,
      x: -6451920,
      y: -8207040
    },
    {
      nanogenreId: 7367,
      x: -6121290,
      y: -1257630
    },
    {
      nanogenreId: 7368,
      x: -1878720,
      y: -2577060
    },
    {
      nanogenreId: 7369,
      x: 627270,
      y: 3513330
    },
    {
      nanogenreId: 7370,
      x: -5611440,
      y: 4962540
    },
    {
      nanogenreId: 7371,
      x: 6899970,
      y: 2611050
    },
    {
      nanogenreId: 7372,
      x: -750870,
      y: 4897650
    },
    {
      nanogenreId: 7373,
      x: 9223650,
      y: -7725000
    },
    {
      nanogenreId: 7374,
      x: 6869070,
      y: 14967960
    },
    {
      nanogenreId: 7375,
      x: -1226730,
      y: -5463120
    },
    {
      nanogenreId: 7376,
      x: 1693320,
      y: -1353420
    },
    {
      nanogenreId: 7377,
      x: -1928160,
      y: 2017770
    },
    {
      nanogenreId: 7378,
      x: -3924300,
      y: 546930
    },
    {
      nanogenreId: 7379,
      x: 10586340,
      y: -7301670
    },
    {
      nanogenreId: 7380,
      x: 7928940,
      y: -8173050
    },
    {
      nanogenreId: 7381,
      x: -4412520,
      y: 7626120
    },
    {
      nanogenreId: 7382,
      x: -6600240,
      y: -2904600
    },
    {
      nanogenreId: 7383,
      x: 2694480,
      y: 7678650
    },
    {
      nanogenreId: 7384,
      x: 1307070,
      y: 2478180
    },
    {
      nanogenreId: 7385,
      x: 1396680,
      y: 3046740
    },
    {
      nanogenreId: 7386,
      x: 633450,
      y: 6974130
    },
    {
      nanogenreId: 7387,
      x: 3964470,
      y: 3389730
    },
    {
      nanogenreId: 7388,
      x: -1557360,
      y: -2379300
    },
    {
      nanogenreId: 7389,
      x: 120510,
      y: 7357290
    },
    {
      nanogenreId: 7390,
      x: 92700,
      y: 1755120
    },
    {
      nanogenreId: 7391,
      x: -3884130,
      y: 3670920
    },
    {
      nanogenreId: 7392,
      x: -5827740,
      y: -843570
    },
    {
      nanogenreId: 7393,
      x: 2827350,
      y: 7996920
    },
    {
      nanogenreId: 7394,
      x: -3565860,
      y: -8071080
    },
    {
      nanogenreId: 7395,
      x: 3494790,
      y: 8676720
    },
    {
      nanogenreId: 7396,
      x: -5061420,
      y: 2382390
    },
    {
      nanogenreId: 7397,
      x: -11034390,
      y: -5194290
    },
    {
      nanogenreId: 7398,
      x: 1248360,
      y: 5843190
    },
    {
      nanogenreId: 7399,
      x: 2166090,
      y: 8389350
    },
    {
      nanogenreId: 7400,
      x: 1575900,
      y: 6340680
    },
    {
      nanogenreId: 7401,
      x: -11083830,
      y: -5812290
    },
    {
      nanogenreId: 7402,
      x: 2660490,
      y: 3772890
    },
    {
      nanogenreId: 7403,
      x: -5898810,
      y: 988800
    },
    {
      nanogenreId: 7404,
      x: 2385480,
      y: 11562780
    },
    {
      nanogenreId: 7405,
      x: 6461190,
      y: -7246050
    },
    {
      nanogenreId: 7406,
      x: 2601780,
      y: 9047520
    },
    {
      nanogenreId: 7407,
      x: -651990,
      y: 7953660
    },
    {
      nanogenreId: 7408,
      x: -2487450,
      y: 1554270
    },
    {
      nanogenreId: 7409,
      x: 2221710,
      y: 8865210
    },
    {
      nanogenreId: 7410,
      x: -4724610,
      y: -5354970
    },
    {
      nanogenreId: 7411,
      x: 324450,
      y: 4174590
    },
    {
      nanogenreId: 7412,
      x: 6006960,
      y: 828120
    },
    {
      nanogenreId: 7413,
      x: 4156050,
      y: 9121680
    },
    {
      nanogenreId: 7414,
      x: 3788340,
      y: 6751650
    },
    {
      nanogenreId: 7415,
      x: 5719590,
      y: 4091160
    },
    {
      nanogenreId: 7416,
      x: 3173430,
      y: 9195840
    },
    {
      nanogenreId: 7417,
      x: -5796840,
      y: -7660110
    },
    {
      nanogenreId: 7418,
      x: -1566630,
      y: -7740450
    },
    {
      nanogenreId: 7419,
      x: -16933200,
      y: -2311320
    },
    {
      nanogenreId: 7420,
      x: -5497110,
      y: -1316340
    },
    {
      nanogenreId: 7421,
      x: -4183860,
      y: 4347630
    },
    {
      nanogenreId: 7422,
      x: -4038630,
      y: 1795290
    },
    {
      nanogenreId: 7423,
      x: -2855160,
      y: 1384320
    },
    {
      nanogenreId: 7424,
      x: 9316350,
      y: -4795680
    },
    {
      nanogenreId: 7425,
      x: 942450,
      y: 2617230
    },
    {
      nanogenreId: 7426,
      x: -4044810,
      y: -4156050
    },
    {
      nanogenreId: 7427,
      x: 5113950,
      y: -5120130
    },
    {
      nanogenreId: 7428,
      x: 6220170,
      y: 2332950
    },
    {
      nanogenreId: 7429,
      x: 574740,
      y: 5997690
    },
    {
      nanogenreId: 7430,
      x: -7202790,
      y: 1730400
    },
    {
      nanogenreId: 7431,
      x: 8299740,
      y: -7545780
    },
    {
      nanogenreId: 7432,
      x: 7570500,
      y: 1897260
    },
    {
      nanogenreId: 7433,
      x: 1378140,
      y: 5472390
    },
    {
      nanogenreId: 7434,
      x: 5305530,
      y: -4248750
    },
    {
      nanogenreId: 7435,
      x: -2716110,
      y: -6974130
    },
    {
      nanogenreId: 7436,
      x: -5478570,
      y: 135960
    },
    {
      nanogenreId: 7437,
      x: 4152960,
      y: 7212060
    },
    {
      nanogenreId: 7438,
      x: 2898420,
      y: -1523370
    },
    {
      nanogenreId: 7439,
      x: 624180,
      y: 951720
    },
    {
      nanogenreId: 7440,
      x: 13030530,
      y: -7029750
    },
    {
      nanogenreId: 7441,
      x: -7270770,
      y: 701430
    },
    {
      nanogenreId: 7442,
      x: -8584020,
      y: -3244500
    },
    {
      nanogenreId: 7443,
      x: -4529940,
      y: 4165320
    },
    {
      nanogenreId: 7444,
      x: 370800,
      y: -3287760
    },
    {
      nanogenreId: 7445,
      x: 985710,
      y: -1069140
    },
    {
      nanogenreId: 7446,
      x: 6368490,
      y: -6306690
    },
    {
      nanogenreId: 7447,
      x: 4727700,
      y: 7449990
    },
    {
      nanogenreId: 7448,
      x: 2713020,
      y: 5018160
    },
    {
      nanogenreId: 7449,
      x: -15144090,
      y: 1980690
    },
    {
      nanogenreId: 7450,
      x: 497490,
      y: 4094250
    },
    {
      nanogenreId: 7451,
      x: 3794520,
      y: 8182320
    },
    {
      nanogenreId: 7452,
      x: -8386260,
      y: 2898420
    },
    {
      nanogenreId: 7453,
      x: 998070,
      y: 10101210
    },
    {
      nanogenreId: 7454,
      x: -4848210,
      y: 7687920
    },
    {
      nanogenreId: 7455,
      x: -1609890,
      y: -6081120
    },
    {
      nanogenreId: 7456,
      x: -4906920,
      y: 4631910
    },
    {
      nanogenreId: 7457,
      x: 2691390,
      y: 1854000
    },
    {
      nanogenreId: 7458,
      x: -2836620,
      y: -373890
    },
    {
      nanogenreId: 7459,
      x: -5562000,
      y: 2836620
    },
    {
      nanogenreId: 7460,
      x: 3556590,
      y: 8284290
    },
    {
      nanogenreId: 7461,
      x: -5914260,
      y: 1739670
    },
    {
      nanogenreId: 7462,
      x: 1665510,
      y: 4866750
    },
    {
      nanogenreId: 7463,
      x: -488220,
      y: -3324840
    },
    {
      nanogenreId: 7464,
      x: 4872930,
      y: 8012370
    },
    {
      nanogenreId: 7465,
      x: 1541910,
      y: -1266900
    },
    {
      nanogenreId: 7466,
      x: -655080,
      y: 5796840
    },
    {
      nanogenreId: 7467,
      x: 5790660,
      y: 9520290
    },
    {
      nanogenreId: 7468,
      x: 7619940,
      y: -6451920
    },
    {
      nanogenreId: 7469,
      x: -2512170,
      y: 9912720
    },
    {
      nanogenreId: 7470,
      x: 6016230,
      y: 18740850
    },
    {
      nanogenreId: 7471,
      x: -7925850,
      y: 655080
    },
    {
      nanogenreId: 7472,
      x: -8000010,
      y: -6278880
    },
    {
      nanogenreId: 7473,
      x: -5803020,
      y: -12761700
    },
    {
      nanogenreId: 7474,
      x: -6383940,
      y: 1625340
    },
    {
      nanogenreId: 7475,
      x: 4607190,
      y: 10085760
    },
    {
      nanogenreId: 7476,
      x: 577830,
      y: 4999620
    },
    {
      nanogenreId: 7477,
      x: -3674010,
      y: 4193130
    },
    {
      nanogenreId: 7478,
      x: 1705680,
      y: 10456560
    },
    {
      nanogenreId: 7479,
      x: -12959460,
      y: -3482430
    },
    {
      nanogenreId: 7480,
      x: -5172660,
      y: 6241800
    },
    {
      nanogenreId: 7481,
      x: -135960,
      y: -3185790
    },
    {
      nanogenreId: 7482,
      x: -7412910,
      y: -1239090
    },
    {
      nanogenreId: 7483,
      x: -7385100,
      y: -4857480
    },
    {
      nanogenreId: 7484,
      x: -1032060,
      y: -5942070
    },
    {
      nanogenreId: 7485,
      x: 8401710,
      y: -7629210
    },
    {
      nanogenreId: 7486,
      x: 1069140,
      y: 2280420
    },
    {
      nanogenreId: 7487,
      x: -435690,
      y: 448050
    },
    {
      nanogenreId: 7488,
      x: 716880,
      y: 12282750
    },
    {
      nanogenreId: 7489,
      x: 5354970,
      y: 7008120
    },
    {
      nanogenreId: 7490,
      x: -9687150,
      y: 7236780
    },
    {
      nanogenreId: 7491,
      x: 3729630,
      y: 5648520
    },
    {
      nanogenreId: 7492,
      x: -4180770,
      y: 7542690
    },
    {
      nanogenreId: 7493,
      x: 2796450,
      y: 4143690
    },
    {
      nanogenreId: 7494,
      x: 6631140,
      y: 8855940
    },
    {
      nanogenreId: 7495,
      x: -5160300,
      y: 5296260
    },
    {
      nanogenreId: 7496,
      x: 5877180,
      y: 9708780
    },
    {
      nanogenreId: 7497,
      x: 1032060,
      y: 7174980
    },
    {
      nanogenreId: 7498,
      x: -6513720,
      y: 5419860
    },
    {
      nanogenreId: 7499,
      x: -5098500,
      y: 1520280
    },
    {
      nanogenreId: 7500,
      x: -5117040,
      y: 8089620
    },
    {
      nanogenreId: 7501,
      x: 3859410,
      y: 2119740
    },
    {
      nanogenreId: 7502,
      x: 8998080,
      y: -5901900
    },
    {
      nanogenreId: 7503,
      x: -1532640,
      y: 5614530
    },
    {
      nanogenreId: 7504,
      x: 3908850,
      y: 8565480
    },
    {
      nanogenreId: 7505,
      x: 5373510,
      y: -1622250
    },
    {
      nanogenreId: 7506,
      x: 1714950,
      y: 8120520
    },
    {
      nanogenreId: 7507,
      x: -11945940,
      y: -5685600
    },
    {
      nanogenreId: 7508,
      x: -488220,
      y: -948630
    },
    {
      nanogenreId: 7509,
      x: -2067210,
      y: -3788340
    },
    {
      nanogenreId: 7510,
      x: 120510,
      y: 1322520
    },
    {
      nanogenreId: 7511,
      x: -7459260,
      y: 1402860
    },
    {
      nanogenreId: 7512,
      x: -89610,
      y: -4885290
    },
    {
      nanogenreId: 7513,
      x: 1705680,
      y: 8000010
    },
    {
      nanogenreId: 7514,
      x: 5042880,
      y: -4131330
    },
    {
      nanogenreId: 7515,
      x: -2039400,
      y: -636540
    },
    {
      nanogenreId: 7516,
      x: 1643880,
      y: 9433770
    },
    {
      nanogenreId: 7517,
      x: -13358070,
      y: -2601780
    },
    {
      nanogenreId: 7518,
      x: 1483200,
      y: -4570110
    },
    {
      nanogenreId: 7519,
      x: -6016230,
      y: 3170340
    },
    {
      nanogenreId: 7520,
      x: -7045200,
      y: 6408660
    },
    {
      nanogenreId: 7521,
      x: -2051760,
      y: 7873320
    },
    {
      nanogenreId: 7522,
      x: 4628820,
      y: 8772510
    },
    {
      nanogenreId: 7523,
      x: -1665510,
      y: -6340680
    },
    {
      nanogenreId: 7524,
      x: 5268450,
      y: 3216690
    },
    {
      nanogenreId: 7525,
      x: 957900,
      y: 4072620
    },
    {
      nanogenreId: 7526,
      x: 4860570,
      y: 8855940
    },
    {
      nanogenreId: 7527,
      x: 6745470,
      y: -7323300
    },
    {
      nanogenreId: 7528,
      x: 664350,
      y: 3117810
    },
    {
      nanogenreId: 7529,
      x: -4752420,
      y: 7344930
    },
    {
      nanogenreId: 7530,
      x: -8058720,
      y: 4486680
    },
    {
      nanogenreId: 7531,
      x: 2348400,
      y: 3621480
    },
    {
      nanogenreId: 7532,
      x: 2271150,
      y: 1776750
    },
    {
      nanogenreId: 7533,
      x: 5691780,
      y: 9860190
    },
    {
      nanogenreId: 7534,
      x: 432600,
      y: -5398230
    },
    {
      nanogenreId: 7535,
      x: 9177300,
      y: 5626890
    },
    {
      nanogenreId: 7536,
      x: 5203560,
      y: 6952500
    },
    {
      nanogenreId: 7537,
      x: -8389350,
      y: -1480110
    },
    {
      nanogenreId: 7538,
      x: -6696030,
      y: -797220
    },
    {
      nanogenreId: 7539,
      x: -1177290,
      y: 3312480
    },
    {
      nanogenreId: 7540,
      x: 7428360,
      y: -4838940
    },
    {
      nanogenreId: 7541,
      x: -6504450,
      y: 2020860
    },
    {
      nanogenreId: 7542,
      x: -2524530,
      y: -3896490
    },
    {
      nanogenreId: 7543,
      x: 398610,
      y: -8018550
    },
    {
      nanogenreId: 7544,
      x: 2218620,
      y: 5308620
    },
    {
      nanogenreId: 7545,
      x: -7536510,
      y: 2916960
    },
    {
      nanogenreId: 7546,
      x: 5534190,
      y: 2113560
    },
    {
      nanogenreId: 7547,
      x: 976440,
      y: 809580
    },
    {
      nanogenreId: 7548,
      x: -207030,
      y: 4149870
    },
    {
      nanogenreId: 7549,
      x: 5824650,
      y: -9001170
    },
    {
      nanogenreId: 7550,
      x: -1965240,
      y: 5265360
    },
    {
      nanogenreId: 7551,
      x: 729240,
      y: 4814220
    },
    {
      nanogenreId: 7552,
      x: 139050,
      y: -6220170
    },
    {
      nanogenreId: 7553,
      x: 1393590,
      y: 11890320
    },
    {
      nanogenreId: 7554,
      x: 3949020,
      y: 1560450
    },
    {
      nanogenreId: 7555,
      x: -126690,
      y: 2475090
    },
    {
      nanogenreId: 7556,
      x: 522210,
      y: 1220550
    },
    {
      nanogenreId: 7557,
      x: -8618010,
      y: 3630750
    },
    {
      nanogenreId: 7558,
      x: 3516420,
      y: 5871000
    },
    {
      nanogenreId: 7559,
      x: -9588270,
      y: 1396680
    },
    {
      nanogenreId: 7560,
      x: 5036700,
      y: -6176910
    },
    {
      nanogenreId: 7561,
      x: 494400,
      y: -1541910
    },
    {
      nanogenreId: 7562,
      x: -5117040,
      y: -11250690
    },
    {
      nanogenreId: 7563,
      x: -5308620,
      y: -6482820
    },
    {
      nanogenreId: 7564,
      x: -2360760,
      y: 2932410
    },
    {
      nanogenreId: 7565,
      x: 1640790,
      y: -5645430
    },
    {
      nanogenreId: 7566,
      x: -5277720,
      y: 4808040
    },
    {
      nanogenreId: 7567,
      x: -2382390,
      y: 253380
    },
    {
      nanogenreId: 7568,
      x: 5781390,
      y: 9888000
    },
    {
      nanogenreId: 7569,
      x: 9100050,
      y: 6204720
    },
    {
      nanogenreId: 7570,
      x: -1279260,
      y: -800310
    },
    {
      nanogenreId: 7571,
      x: 2567790,
      y: 7928940
    },
    {
      nanogenreId: 7572,
      x: 2759370,
      y: 7394370
    },
    {
      nanogenreId: 7573,
      x: -3562770,
      y: 6028590
    },
    {
      nanogenreId: 7574,
      x: -3507150,
      y: -367710
    },
    {
      nanogenreId: 7575,
      x: -3074550,
      y: 5611440
    },
    {
      nanogenreId: 7576,
      x: 2558520,
      y: 7762080
    },
    {
      nanogenreId: 7577,
      x: 2747010,
      y: 4344540
    },
    {
      nanogenreId: 7578,
      x: -4057170,
      y: 6646590
    },
    {
      nanogenreId: 7579,
      x: -7440720,
      y: 1674780
    },
    {
      nanogenreId: 7580,
      x: 4035540,
      y: 5528010
    },
    {
      nanogenreId: 7581,
      x: -3331020,
      y: 4202400
    },
    {
      nanogenreId: 7582,
      x: -58710,
      y: 6186180
    },
    {
      nanogenreId: 7583,
      x: 5778300,
      y: 7619940
    },
    {
      nanogenreId: 7584,
      x: -2759370,
      y: -1393590
    },
    {
      nanogenreId: 7585,
      x: 4761690,
      y: 7876410
    },
    {
      nanogenreId: 7586,
      x: -4054080,
      y: -7394370
    },
    {
      nanogenreId: 7587,
      x: -5667060,
      y: 3198150
    },
    {
      nanogenreId: 7588,
      x: -7048290,
      y: 3908850
    },
    {
      nanogenreId: 7589,
      x: -1532640,
      y: 3748170
    },
    {
      nanogenreId: 7590,
      x: -7928940,
      y: -2997300
    },
    {
      nanogenreId: 7591,
      x: -1455390,
      y: 3154890
    },
    {
      nanogenreId: 7592,
      x: -315180,
      y: -5327160
    },
    {
      nanogenreId: 7593,
      x: -5392050,
      y: -71070
    },
    {
      nanogenreId: 7594,
      x: 3525690,
      y: -3525690
    },
    {
      nanogenreId: 7595,
      x: -4208580,
      y: 6108930
    },
    {
      nanogenreId: 7596,
      x: -11763630,
      y: 3921210
    },
    {
      nanogenreId: 7597,
      x: 5262270,
      y: 954810
    },
    {
      nanogenreId: 7598,
      x: -451140,
      y: 7323300
    },
    {
      nanogenreId: 7599,
      x: 509850,
      y: -3803790
    },
    {
      nanogenreId: 7600,
      x: -3485520,
      y: -1313250
    },
    {
      nanogenreId: 7601,
      x: 5435310,
      y: 8936280
    },
    {
      nanogenreId: 7602,
      x: -2008500,
      y: 6884520
    },
    {
      nanogenreId: 7603,
      x: 4709160,
      y: 7088460
    },
    {
      nanogenreId: 7604,
      x: -9665520,
      y: -512940
    },
    {
      nanogenreId: 7605,
      x: 4384710,
      y: 7743540
    },
    {
      nanogenreId: 7606,
      x: 651990,
      y: 4601010
    },
    {
      nanogenreId: 7607,
      x: -3831600,
      y: 5240640
    },
    {
      nanogenreId: 7608,
      x: 2166090,
      y: -4650450
    },
    {
      nanogenreId: 7609,
      x: 8716890,
      y: -5370420
    },
    {
      nanogenreId: 7610,
      x: 1866360,
      y: 2607960
    },
    {
      nanogenreId: 7611,
      x: 3018930,
      y: 10005420
    },
    {
      nanogenreId: 7612,
      x: -5002710,
      y: 6455010
    },
    {
      nanogenreId: 7613,
      x: 5741220,
      y: -747780
    },
    {
      nanogenreId: 7614,
      x: 5336430,
      y: 8621100
    },
    {
      nanogenreId: 7615,
      x: 509850,
      y: -5951340
    },
    {
      nanogenreId: 7616,
      x: -1733490,
      y: 6933960
    },
    {
      nanogenreId: 7617,
      x: 3371190,
      y: 5166480
    },
    {
      nanogenreId: 7618,
      x: -5083050,
      y: 3000390
    },
    {
      nanogenreId: 7619,
      x: 2632680,
      y: -1594440
    },
    {
      nanogenreId: 7620,
      x: 2654310,
      y: 7369650
    },
    {
      nanogenreId: 7621,
      x: -7236780,
      y: 2351490
    },
    {
      nanogenreId: 7622,
      x: 10308240,
      y: -639630
    },
    {
      nanogenreId: 7623,
      x: -2357670,
      y: -2079570
    },
    {
      nanogenreId: 7624,
      x: -7122450,
      y: 4112790
    },
    {
      nanogenreId: 7625,
      x: -5534190,
      y: -281190
    },
    {
      nanogenreId: 7626,
      x: -2156820,
      y: 2577060
    },
    {
      nanogenreId: 7627,
      x: 7357290,
      y: -5231370
    },
    {
      nanogenreId: 7628,
      x: -4548480,
      y: 2524530
    },
    {
      nanogenreId: 7629,
      x: -3374280,
      y: 0
    },
    {
      nanogenreId: 7630,
      x: 1714950,
      y: 7280040
    },
    {
      nanogenreId: 7631,
      x: -5855550,
      y: 3432990
    },
    {
      nanogenreId: 7632,
      x: -7060650,
      y: 1328700
    },
    {
      nanogenreId: 7633,
      x: 3006570,
      y: 6489000
    },
    {
      nanogenreId: 7634,
      x: 5667060,
      y: -6983400
    },
    {
      nanogenreId: 7635,
      x: 4783320,
      y: 9866370
    },
    {
      nanogenreId: 7636,
      x: 380070,
      y: 11809980
    },
    {
      nanogenreId: 7637,
      x: 5110860,
      y: 6448830
    },
    {
      nanogenreId: 7638,
      x: -5744310,
      y: -5358060
    },
    {
      nanogenreId: 7639,
      x: -2988030,
      y: 3504060
    },
    {
      nanogenreId: 7640,
      x: 324450,
      y: 8120520
    },
    {
      nanogenreId: 7641,
      x: -9850920,
      y: 1974510
    },
    {
      nanogenreId: 7642,
      x: -957900,
      y: -3127080
    },
    {
      nanogenreId: 7643,
      x: -8454240,
      y: -7304760
    },
    {
      nanogenreId: 7644,
      x: 9291630,
      y: 5592900
    },
    {
      nanogenreId: 7645,
      x: 1016610,
      y: 3541140
    },
    {
      nanogenreId: 7646,
      x: 2057940,
      y: -4208580
    },
    {
      nanogenreId: 7647,
      x: 4956360,
      y: -7697190
    },
    {
      nanogenreId: 7648,
      x: -9974520,
      y: -4152960
    },
    {
      nanogenreId: 7649,
      x: 1205100,
      y: 1758210
    },
    {
      nanogenreId: 7650,
      x: 5728860,
      y: -5086140
    },
    {
      nanogenreId: 7651,
      x: 794130,
      y: 6547710
    },
    {
      nanogenreId: 7652,
      x: -8528400,
      y: -2122830
    },
    {
      nanogenreId: 7653,
      x: 2898420,
      y: -7233690
    },
    {
      nanogenreId: 7654,
      x: -7987650,
      y: -1684050
    },
    {
      nanogenreId: 7655,
      x: -1674780,
      y: -1038240
    },
    {
      nanogenreId: 7656,
      x: 10200090,
      y: 2552340
    },
    {
      nanogenreId: 7657,
      x: -4947090,
      y: 3686370
    },
    {
      nanogenreId: 7658,
      x: -5972970,
      y: 2453460
    },
    {
      nanogenreId: 7659,
      x: -7826970,
      y: 3525690
    },
    {
      nanogenreId: 7660,
      x: 3337200,
      y: 2706840
    },
    {
      nanogenreId: 7661,
      x: 1758210,
      y: 7493250
    },
    {
      nanogenreId: 7662,
      x: -574740,
      y: -4770960
    },
    {
      nanogenreId: 7663,
      x: -5021250,
      y: -7477800
    },
    {
      nanogenreId: 7664,
      x: -5086140,
      y: 2747010
    },
    {
      nanogenreId: 7665,
      x: 2833530,
      y: 9208200
    },
    {
      nanogenreId: 7666,
      x: -3596760,
      y: 2166090
    },
    {
      nanogenreId: 7667,
      x: -1897260,
      y: -1693320
    },
    {
      nanogenreId: 7668,
      x: -3723450,
      y: 3485520
    },
    {
      nanogenreId: 7669,
      x: 2119740,
      y: -6723840
    },
    {
      nanogenreId: 7670,
      x: -726150,
      y: -574740
    },
    {
      nanogenreId: 7671,
      x: -846660,
      y: 4619550
    },
    {
      nanogenreId: 7672,
      x: -10051770,
      y: 5453850
    },
    {
      nanogenreId: 7673,
      x: -2709930,
      y: -3432990
    },
    {
      nanogenreId: 7674,
      x: 4879110,
      y: 9446130
    },
    {
      nanogenreId: 7675,
      x: 2898420,
      y: 6674400
    },
    {
      nanogenreId: 7676,
      x: -2876790,
      y: 5280810
    },
    {
      nanogenreId: 7677,
      x: -10400940,
      y: -2085750
    },
    {
      nanogenreId: 7678,
      x: -3550410,
      y: 12675180
    },
    {
      nanogenreId: 7679,
      x: -6915420,
      y: -8284290
    },
    {
      nanogenreId: 7680,
      x: -2264970,
      y: -7925850
    },
    {
      nanogenreId: 7681,
      x: 4171500,
      y: -1829280
    },
    {
      nanogenreId: 7682,
      x: 142140,
      y: 15833160
    },
    {
      nanogenreId: 7683,
      x: -432600,
      y: -2598690
    },
    {
      nanogenreId: 7684,
      x: 1736580,
      y: -2076480
    },
    {
      nanogenreId: 7685,
      x: 4811130,
      y: 8200860
    },
    {
      nanogenreId: 7686,
      x: 4601010,
      y: 9569730
    },
    {
      nanogenreId: 7687,
      x: 4446510,
      y: -6501360
    },
    {
      nanogenreId: 7688,
      x: -1100040,
      y: 11510250
    },
    {
      nanogenreId: 7689,
      x: 15450,
      y: -367710
    },
    {
      nanogenreId: 7690,
      x: -1915800,
      y: -3905760
    },
    {
      nanogenreId: 7691,
      x: 7375830,
      y: -151410
    },
    {
      nanogenreId: 7692,
      x: -1983780,
      y: 3241410
    },
    {
      nanogenreId: 7693,
      x: 7156440,
      y: -7085370
    },
    {
      nanogenreId: 7694,
      x: 55620,
      y: -3408270
    },
    {
      nanogenreId: 7695,
      x: 4925460,
      y: -4329090
    },
    {
      nanogenreId: 7696,
      x: 6896880,
      y: -1504830
    },
    {
      nanogenreId: 7697,
      x: -6315960,
      y: -5361150
    },
    {
      nanogenreId: 7698,
      x: -5191200,
      y: 1637700
    },
    {
      nanogenreId: 7699,
      x: -1319430,
      y: 18540
    },
    {
      nanogenreId: 7700,
      x: 6303600,
      y: 8095800
    },
    {
      nanogenreId: 7701,
      x: -6006960,
      y: 2703750
    },
    {
      nanogenreId: 7702,
      x: 1671690,
      y: -1631520
    },
    {
      nanogenreId: 7703,
      x: -7026660,
      y: -1575900
    },
    {
      nanogenreId: 7704,
      x: -3667830,
      y: -126690
    },
    {
      nanogenreId: 7705,
      x: -4891470,
      y: 5518740
    },
    {
      nanogenreId: 7706,
      x: 5775210,
      y: 1405950
    },
    {
      nanogenreId: 7707,
      x: 8936280,
      y: -7388190
    },
    {
      nanogenreId: 7708,
      x: -444960,
      y: 2116650
    },
    {
      nanogenreId: 7709,
      x: -4740060,
      y: -293550
    },
    {
      nanogenreId: 7710,
      x: -1124760,
      y: 1996140
    },
    {
      nanogenreId: 7711,
      x: 3633840,
      y: 5120130
    },
    {
      nanogenreId: 7712,
      x: 4369260,
      y: 9195840
    },
    {
      nanogenreId: 7713,
      x: -2947860,
      y: -877560
    },
    {
      nanogenreId: 7714,
      x: 5503290,
      y: 3658560
    },
    {
      nanogenreId: 7715,
      x: -2617230,
      y: -1829280
    },
    {
      nanogenreId: 7716,
      x: -1158750,
      y: -10737750
    },
    {
      nanogenreId: 7717,
      x: 9844740,
      y: 7332570
    },
    {
      nanogenreId: 7718,
      x: 3757440,
      y: 2252610
    },
    {
      nanogenreId: 7719,
      x: 849750,
      y: 8877570
    },
    {
      nanogenreId: 7720,
      x: -3803790,
      y: 2005410
    },
    {
      nanogenreId: 7721,
      x: 2852070,
      y: -5846280
    },
    {
      nanogenreId: 7722,
      x: 4338360,
      y: 7487070
    },
    {
      nanogenreId: 7723,
      x: 1421400,
      y: -3847050
    },
    {
      nanogenreId: 7724,
      x: 9183480,
      y: 1266900
    },
    {
      nanogenreId: 7725,
      x: -1118580,
      y: 4326000
    },
    {
      nanogenreId: 7726,
      x: 6368490,
      y: 1792200
    },
    {
      nanogenreId: 7727,
      x: 6859800,
      y: -6133650
    },
    {
      nanogenreId: 7728,
      x: 2546160,
      y: 5629980
    },
    {
      nanogenreId: 7729,
      x: 4001550,
      y: 7304760
    },
    {
      nanogenreId: 7730,
      x: 4301280,
      y: 6353040
    },
    {
      nanogenreId: 7731,
      x: 13701060,
      y: -3701820
    },
    {
      nanogenreId: 7732,
      x: -6634230,
      y: 5904990
    },
    {
      nanogenreId: 7733,
      x: 6232530,
      y: -129780
    },
    {
      nanogenreId: 7734,
      x: -8865210,
      y: -1137120
    },
    {
      nanogenreId: 7735,
      x: 3216690,
      y: 8200860
    },
    {
      nanogenreId: 7736,
      x: 865200,
      y: 10583250
    },
    {
      nanogenreId: 7737,
      x: -1053690,
      y: 2814990
    },
    {
      nanogenreId: 7738,
      x: -6470460,
      y: 964080
    },
    {
      nanogenreId: 7739,
      x: -3738900,
      y: 593280
    },
    {
      nanogenreId: 7740,
      x: 7159530,
      y: -4421790
    },
    {
      nanogenreId: 7741,
      x: 4378530,
      y: 5376600
    },
    {
      nanogenreId: 7742,
      x: 655080,
      y: 6319050
    },
    {
      nanogenreId: 7743,
      x: 5432220,
      y: -7666290
    },
    {
      nanogenreId: 7744,
      x: 4384710,
      y: 6603330
    },
    {
      nanogenreId: 7745,
      x: 1035150,
      y: 7035930
    },
    {
      nanogenreId: 7746,
      x: 6044040,
      y: 7276950
    },
    {
      nanogenreId: 7747,
      x: 8095800,
      y: -2407110
    },
    {
      nanogenreId: 7748,
      x: -4625730,
      y: 5861730
    },
    {
      nanogenreId: 7749,
      x: 2882970,
      y: 12474330
    },
    {
      nanogenreId: 7750,
      x: -7224420,
      y: -834300
    },
    {
      nanogenreId: 7751,
      x: 951720,
      y: -3297030
    },
    {
      nanogenreId: 7752,
      x: 5877180,
      y: 6155280
    },
    {
      nanogenreId: 7753,
      x: 2626500,
      y: -4724610
    },
    {
      nanogenreId: 7754,
      x: 3040560,
      y: -957900
    },
    {
      nanogenreId: 7755,
      x: 794130,
      y: 4928550
    },
    {
      nanogenreId: 7756,
      x: -3911940,
      y: -330630
    },
    {
      nanogenreId: 7757,
      x: 1801470,
      y: -33990
    },
    {
      nanogenreId: 7758,
      x: -6084210,
      y: 5259180
    },
    {
      nanogenreId: 7759,
      x: 5846280,
      y: 7505610
    },
    {
      nanogenreId: 7760,
      x: -5972970,
      y: 4001550
    },
    {
      nanogenreId: 7761,
      x: 4069530,
      y: -2737740
    },
    {
      nanogenreId: 7762,
      x: -14739300,
      y: -1143300
    },
    {
      nanogenreId: 7763,
      x: -2061030,
      y: 4356900
    },
    {
      nanogenreId: 7764,
      x: 7088460,
      y: -5562000
    },
    {
      nanogenreId: 7765,
      x: -5738130,
      y: 2669760
    },
    {
      nanogenreId: 7766,
      x: -1835460,
      y: -1829280
    },
    {
      nanogenreId: 7767,
      x: 3578220,
      y: 6285060
    },
    {
      nanogenreId: 7768,
      x: -1752030,
      y: -6927780
    },
    {
      nanogenreId: 7769,
      x: -9937440,
      y: -8571660
    },
    {
      nanogenreId: 7770,
      x: -5772120,
      y: -1872540
    },
    {
      nanogenreId: 7771,
      x: -4928550,
      y: 2629590
    }
  ]
}