export default {
  namespaced: true,
  state: {
    heatmapProgress: false,
  },
  getters: {
    isSet: (state) => () => {
      return state.heatmapProgress
    },
  },
  mutations: {
    SHOW_HEATMAP_PROGRESS_INDICATOR(state) {
      state.heatmapProgress = true
    },
    HIDE_HEATMAP_PROGRESS_INDICATOR(state) {
      state.heatmapProgress = false
    },
  },
  actions: {
    show({ commit }) {
      commit('SHOW_HEATMAP_PROGRESS_INDICATOR')
    },
    hide({ commit }) {
      commit('HIDE_HEATMAP_PROGRESS_INDICATOR')
    },
  },
}
