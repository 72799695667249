export default {
  namespaced: true,
  state: {
    mapTitleList: [],
    nanogenreTitleList: [],
    distance: 0,
    showTitleImages: false
  },
  getters: {
    getTitleList: (state) => () => {
      return state.mapTitleList
    },
    getNanogenreTitleList: (state) => () => {
      return state.nanogenreTitleList
    },
    getNanogenreTitleDistance: (state) => () => {
      return state.distance
    },
    showTitleImages: (state) => () => {
      return state.showTitleImages
    }
  },
  mutations: {
    ADD_TITLE(state, data) {
      state.mapTitleList = [ ...state.mapTitleList, { ncTitleId: data.ncTitleId, enabled: true } ]
    },
    SET_TITLE_LIST(state, data) {
      state.mapTitleList = data
    },
    SET_DISTANCE(state, ncTitleId) {
      if (state.nanogenreTitleList && state.nanogenreTitleList.titleList) {
        state.distance = 0
      }
      state.distance = state.nanogenreTitleList.titleList.find(item => item.ncTitleId === ncTitleId)
    },
    TOGGLE_TITLE(state, data) {
      // find the title in the list
      const title = state.mapTitleList.find(item => item.ncTitleId === data.ncTitleId)
      title.enabled = !title.enabled
    },
    TOGGLE_SHOW_TITLE_IMAGES(state) {
      state.showTitleImages = !state.showTitleImages
    },
  },
  actions: {
    addTitle({ commit }, title) {
      commit('ADD_TITLE', title)
    },
    setMapTitleList({ commit }, data) {
      commit('SET_TITLE_LIST', data)
    },
    setDistance({ commit }, ncTitleId) {
      commit('SET_DISTANCE', ncTitleId)
    },
    toggleTitle({ commit }, title) {
      commit('TOGGLE_TITLE', title)
    },
    toggleShowTitleImages({ commit }) {
      commit('TOGGLE_SHOW_TITLE_IMAGES')
    },
  },
}
