<template>
  <v-row justify="center" class="progress-indicator">
    <v-dialog
      persistent
      v-model="showProgress"
      max-width="225"
    >
      <v-card class="progress-indicator" height="200" flat v-if="showProgress">
        <div class="text-center">
          <v-progress-circular
            :size="150"
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'HeatmapProgress',
  methods: {},
  computed: {
    showProgress() {
      return this.$store.getters['heatmapProgressIndicator/isSet']()
    },
  },
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.progress-indicator {
  background-color: transparent;
  opacity: 0.9;
}
</style>
