export default {
  namespaced: true,
  state: {
    selectedNanogenreId: null,
    selectedNanogenreTitles: [],
  },
  getters: {
    getSelectedNanogenreId: (state) => () => {
      return state.selectedNanogenreId
    },
    getSelectedNanogenreTitles: (state) => () => {
      return state.selectedNanogenreTitles
    },
  },
  mutations: {    
    SET_SELECTED_NANOGENRE_ID(state, data) {
      state.selectedNanogenreId = data
    },
    SET_SELECTED_NANOGENRE_TITLES(state, data) {
      state.selectedNanogenreTitles = data
    },
  },
  actions: {
    setSelectedNanogenreId( { commit }, data) {
      commit('SET_SELECTED_NANOGENRE_ID', data)
    },
    setSelectedNanogenreTitles( { commit }, data) {
      commit('SET_SELECTED_NANOGENRE_TITLES', data)
    },
  },
}
