export default {
  namespaced: true,
  state: {
    titleChanged: 0,
    titleList: [],
    commonTitleList: [],
  },
  getters: {
    getCommonTitleList: (state) => () => {
      return state.commonTitleList
    },
    getTitleChanged: (state) => () => {
      return state.titleChanged
    },
    getTitleList: (state) => () => {
      return state.titleList
    },
  },
  mutations: {
    SET_COMMON_TITLE_LIST(state, data) {
      state.commonTitleList = data
    },
    INCREMENT_TITLE_CHANGED(state) {
      state.titleChanged++
    },
    SET_TITLE_LIST(state, data) {
      state.titleList = data
    },
  },
  actions: {
    setCommonTitleList({commit}, commonTitleList) {
      commit('SET_COMMON_TITLE_LIST', commonTitleList)
    },
    setTitleList({commit}, titleList) {
      commit('SET_TITLE_LIST', titleList)
    },
    incrementTitleChanged({commit}) {
      commit('INCREMENT_TITLE_CHANGED')
    }
  },
}
