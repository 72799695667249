import heatmapCoordinates from '../data/heatmap.data'
import originalCoordinates from '../data/originalCoordinates.data'

import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    coordinatesChanged: 0,
    heatmapCoordinates: heatmapCoordinates.data,
    originalCoordinates: originalCoordinates.data,
    displayCoordinates: {
      type: "FeatureCollection",
      features: []
    },
    titlePresets: [],
    titleList: [],
    titleNanogenresList: [],
    titleListHeatmapData: [],
    mapDetailsTitleList: [], 
    mapDetailsKeywordList: [],
    nanogenreTitlesList: [], 
    colorstopList: [],
    colorToggled: false,
    zoomRadiusMultiplier: 0.6,
    zoomBlurMultiplier: 0.6,
    zoomOpacityMultiplier: 0.35,
    weightPower: 0.8,
    maxNanogenres: 1000,
  },
  getters: {
    getTitlePresets: (state) => () => {
      return state.titlePresets
    },
    getTitleList: (state) => () => {
      return state.titleList
    },
    getTitleListByNcTitleIdList: (state) => (ncTitleIdList) => {
      return ncTitleIdList.map(ncTitleId => {
          const item = state.titleList.find(item => item.ncTitleId === ncTitleId);
          if(item !== undefined) {
              return item
          }
      }).filter(Boolean)
    },
    getNanogenres: (state) => () => {
      return state.titleListHeatmapData.nanogenres
    },
    getNanogenreTitlesByNgId: (state) => (ngId) => {
      return state.nanogenreTitlesList.find(item => item.nanogenreId === ngId)
    },
    getNanogenreKeywordsByNgId: (state) => (ngId) => {
      const nanogenre = state.titleListHeatmapData.nanogenres.find(item => item.nanogenreId === ngId)
      if (nanogenre) {
        return nanogenre.keywords.map(item => item.keyword)
      }
      return []
    },
    getNanogenreCommonTitleList: (state) => (ngId) => {
      const nanogenre = state.titleListHeatmapData.nanogenres.find(item => item.nanogenreId === ngId)
      return nanogenre.commonTitleList
    },
    getTitleListHeatmapData: (state) => () => {
      return state.titleListHeatmapData
    },
    getMapDetailsTitleList: (state) => () => {
      return state.mapDetailsTitleList
    },
    getMapDetailsKeywordList: (state) => () => {
      return state.mapDetailsKeywordList
    },
    getTitleNanogenresList: (state) => () => {
      return state.titleNanogenresList
    },
    getDisplayCoordinates: (state) => () => {
      return state.displayCoordinates
    },
    getCoordinatesChanged: (state) => () => {
      return state.coordinatesChanged
    },
    getColorstopList: (state) => () => {
      return state.colorstopList
    },
    getColorToggledFlag: (state) => () => {
      return state.colorToggled
    },
    getNanogenreTitleList: (state) => () => {
      return state.titleListHeatmapData
    },
    getZoomRadiusMultiplier: (state) => () => {
      return state.zoomRadiusMultiplier
    },
    getZoomOpacityMultiplier: (state) => () => {
      return state.zoomOpacityMultiplier
    },
    getZoomBlurMultiplier: (state) => () => {
      return state.zoomBlurMultiplier
    },
    getWeightPower: (state) => () => {
      return state.weightPower
    },
    getMaxNanogenres: (state) => () => {
      return state.maxNanogenres
    },
    getHeatmapCoordinates: (state) => () => { 
      return state.heatmapCoordinates
    },
    getOriginalCoordinates: (state) => () => { 
      return state.originalCoordinates
    },
  },
  mutations: {
    SET_TITLE_PRESETS(state, data) {
      state.titlePresets = [ ...data ]
    },
    SET_TITLE_LIST(state, data) {
      state.titleList = data.map((item, index) => {
        return { ...item, id: index + 1 };
      })
    },
    SET_NANOGENRE_TITLES_LIST(state, data) {
      state.nanogenreTitlesList = [ ...data ]
    },
    SET_TITLE_NANOGENRES_LIST(state, data) {
      state.titleNanogenresList = [ ...data ]
    },
    SET_TITLE_HEATMAP_DATA(state, data) {
      state.titleListHeatmapData = data
    },
    SET_DISPLAY_COORDINATES(state, data) {
      state.displayCoordinates.features = [ ...data ]     
      state.coordinatesChanged += 1 
    },
    CLEAR_DISPLAY_COORDINATES(state) {
      state.displayCoordinates.features = []
      state.coordinatesChanged++
    },
    SET_MAP_DETAILS_TITLE_LIST(state, data) {
      state.mapDetailsTitleList = data
    },
    SET_MAP_DETAILS_KEYWORD_LIST(state, data) {
      state.mapDetailsKeywordList = data
    },
    SET_COLORSTOP_LIST(state, data) {
      state.colorstopList = data
    },
    SET_COLOR_TOGGLED_FLAG(state) {
      state.colorToggled = !state.colorToggled
    },
    CLEAR_TITLE_DETAILS_NANOGENRES(state) {
      state.titleNanogenresList = []
    },
    SET_ZOOM_RADIUS_MULTIPLIER(state, data) {
      state.zoomRadiusMultiplier = data
    }, 
    SET_ZOOM_BLUR_MULTIPLIER(state, data) {
      state.zoomBlurMultiplier = data
    }, 
    SET_ZOOM_OPACITY_MULTIPLIER(state, data) {
      state.zoomOpacityMultiplier = data
    }, 
    SET_WEIGHT_POWER(state, data) {
      state.weightPower = data
    },
    SET_MAX_NANOGENRES(state, data) {
      state.maxNanogenres = data
    },
    SET_HEATMAP_COORDINATES(state, data) {
      state.heatmapCoordinates = data
    },
  },
  actions: {
    async fetchTitlePresets({ commit }) {
      let response = await dataApi.getTitlePresets()
      if (response.data) {
        commit('SET_TITLE_PRESETS', response.data)
        return true
      }
      return false
    },
    async fetchTitleList({ commit }) {
      let response = await dataApi.getTitleList()
      if (response.data) {
        commit('SET_TITLE_LIST', response.data)
        return true
      }
      return false
    },
    async fetchNanogenreTitleList( {commit} ) {
      let response = await dataApi.fetchNanogenreTitleList()
      if (response.data) {
        // console.log(`fetchNanogenreTitleList -> ${response.data}`)
        commit('SET_NANOGENRE_TITLES_LIST', response.data)
        return true
      }
      return false
    },
    async fetchTitleNanogenreList( {commit} ) {
      let response = await dataApi.fetchTitleNanogenreList()
      if (response.data) {
        commit('SET_TITLE_NANOGENRES_LIST', response.data)
        return true
      }
      return false
    },
    async fetchTitleListHeatmapData({ commit }, titleIdList) {
      let response = await dataApi.fetchTitleHeatmapData(titleIdList)
      if (response.data) {
        commit('SET_TITLE_HEATMAP_DATA', response.data)
        // commit('SET_MAP_DETAILS_TITLE_LIST', response.data)
      }
    },
    setDisplayCoordinates({ commit, state }, nanogenreList) {
      /**
       * 
       * @param {Object} state - The state object containing the heatmapCoordinates
       * @param {Array} nanogenreList - List of nanogenres containing nanogenreId and nanogenreCount
       * 
       * @returns {Array} - Array of objects containing feature, geometry and properties of each nanogenre
       * 
       * This function takes in two parameters, state and nanogenreList. state is an object containing the heatmapCoordinates, 
       * nanogenreList is an array containing nanogenres with properties nanogenreId and nanogenreCount.
       * 
       * This code filters the nanogenreList by checking if the nanogenreIds are present in the heatmapCoordinates of state. 
       * Then it maps the filtered nanogenreList, in each iteration finds the respective coordinates data of heatmapCoordinates 
       * for a particular nanogenreId and then for each nanogenreCount creates a feature object with type as "Feature", geometry 
       * object with "Point" and coordinates and properties object with title, id and name. Then it flattens the nested array formed.
       * 
       * It returns an array of objects containing feature, geometry, and properties of each nanogenre.
       * 
       */

      const heatmapCoordinatesIds = new Set(state.heatmapCoordinates.map(item => item.nanogenreId))

      const mapNanogenreList = nanogenreList
        .filter(item => heatmapCoordinatesIds.has(item.nanogenreId))

      // dedupe the mapNanogenreList
      const mapNanogenreListDeduped = mapNanogenreList.reduce((acc, current) => {
        const x = acc.find(item => item.nanogenreId === current.nanogenreId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      console.log(mapNanogenreListDeduped)

      const alpha = 0.7
      function sigmoid(x,  alpha) {
        return 1 / (1 + Math.exp(-alpha * (x - 0.5)))
      }

      const defaultWeight = 0.25

      const result = []
      const maxNanogenres = state.maxNanogenres
      if (mapNanogenreList.length > 0) {
        const weightBase = Math.pow(mapNanogenreList[0].nanogenreCount, state.weightPower)
        for (let x = 0; x < Math.min(mapNanogenreList.length, maxNanogenres); x++) {
          const weightCalc = Math.pow(mapNanogenreList[x].nanogenreCount, state.weightPower)
          const weight = (weightCalc / weightBase) * defaultWeight
          const scaledWeight = sigmoid(weight, alpha) * 1.75
          const nanogenreCoordinate = state.heatmapCoordinates.find(heatmapCoordinate => heatmapCoordinate.nanogenreId === mapNanogenreList[x].nanogenreId)          
          result.push([nanogenreCoordinate.x, nanogenreCoordinate.y, weight, scaledWeight, mapNanogenreList[x].nanogenreId])
        }          
      }
      commit('SET_DISPLAY_COORDINATES', result)
    },
    clearTitleListHeatmapData({ commit }) {
      commit('SET_TITLE_HEATMAP_DATA', [])
    },
    clearTitleCoordinates({ commit }) {
      commit('CLEAR_DISPLAY_COORDINATES')
    },
    setMapDetailsTitleList({ commit }, data) {
      commit('SET_MAP_DETAILS_TITLE_LIST', data)
    },
    setMapDetailsKeywordList({ commit }, data) {
      commit('SET_MAP_DETAILS_KEYWORD_LIST', data)
    },
    setColorstopList({ commit }, data) {
      commit('SET_COLORSTOP_LIST', data)
      commit('SET_COLOR_TOGGLED_FLAG')
    },
    setZoomRadiusMultiplier({ commit }, radiusMultiplier) {
      commit('SET_ZOOM_RADIUS_MULTIPLIER', radiusMultiplier)
    },
    setZoomBlurMultiplier({ commit }, blurMultiplier) {
      commit('SET_ZOOM_BLUR_MULTIPLIER', blurMultiplier)
    },
    setZoomOpacityMultiplier({ commit }, opacityMultiplier) {
      commit('SET_ZOOM_OPACITY_MULTIPLIER', opacityMultiplier)
    },
    setWeightPower({ commit }, weightPower) {
      commit('SET_WEIGHT_POWER', weightPower)
    },
    setMaxNanogenres({ commit }, maxNanogenres) {
      commit('SET_MAX_NANOGENRES', maxNanogenres)
    },
    setHeatmapCoordinates({ commit }, heatmapCoordinates) {
      commit('SET_HEATMAP_COORDINATES', heatmapCoordinates)
    },
    // the following code is used to adjust the coordinates of the heatmap
    // the original coordinates are started at 0,0 (top left) and the new coordinates 0,0 is at the center.
    async adjustCoordinates({ commit }, data) {
      const offsetX = 8030 // original 7975
      const offsetY = 7980 // original 7975
      const scaleX = 3090 // original 3125
      const scaleY = 3090 // original 3125

      const adjustedCoordinates = data.map((item) => {
        return {
          nanogenreId: item.NanogenreId,
          x: (item.x - Math.abs(offsetX)) * scaleX,
          y: ((item.y * -1) + Math.abs(offsetY)) * scaleY,
        }
      })  

      // for (let x = 0; x < adjustedCoordinates.length; x++) {
      //   await dataApi.sendHeatmapCoordinates(adjustedCoordinates[x])
      // }

      commit('SET_HEATMAP_COORDINATES', adjustedCoordinates)
    }
  },
}
