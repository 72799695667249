<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="showProgress"
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5">
          Loading application data
        </v-card-title>
        <v-progress-linear
          v-if="showTitles"
          indeterminate
          color="green"
          size="64"
        ></v-progress-linear>
        <br>
        <v-progress-linear
          v-if="showTitlePresets"
          indeterminate
          color="blue"
          size="64"
        ></v-progress-linear>
        <br>
        <v-progress-linear
          v-if="showNanogenres"
          indeterminate
          color="teal"
          size="64"
        ></v-progress-linear>
        <br>
        <v-progress-linear
          v-if="showNanogenreTitles"
          indeterminate
          color="yellow"
          size="64"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ProgressIndicator',
  data: () => ({}),
  methods: {},
  computed: {
    showProgress() {
      return true
    },
    showTitlePresets() {
      return this.$store.getters['progressIndicator/showTitlePresetsProgress']()
    },
    showTitles() {
      return this.$store.getters['progressIndicator/showTitlesProgress']()
    },
    showNanogenres() {
      return this.$store.getters['progressIndicator/showNanogenresProgress']()
    },
    showNanogenreTitles() {
      return this.$store.getters['progressIndicator/showNanogenreTitlesProgress']()
    },
  }
}
</script>

<style scoped></style>
