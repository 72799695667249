<template>
  <v-card color="green">
    <v-card-text class="white--text">{{ selectedNanogenre }}</v-card-text>
  </v-card>
</template>

<script>

export default {
  props: [],
  data: () => ({    
  }),
  methods: {
  },
  computed: {
    selectedNanogenre: {
      get() {
        return this.$store.getters['selectedNanogenre/getSelectedNanogenre']()
      },
      set() {} 
    },
  },
  mounted() {
  }
}
</script>

<style scoped></style>
