<template>
  <div>
    <v-navigation-drawer
      v-model="getDrawer"
      hide-overlay
      absolute
      width="75%"
      :height="getViewportHeight * 2"
      right
      :stateless=stateless
      class="top-offset"
    >
      <!-- <v-card :height="getViewportHeight" flat tile outlined> -->
      <v-card flat tile outlined>

        <v-app-bar fixed flat>
          <v-row>
            <v-col cols="4">
              <v-row>
                <v-col cols="6" class="pl-0 ml-0">
                  <v-card-title class="subtitle-2 pl-0 pr-0">Nanogenres</v-card-title>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-switch
                    flat
                    v-model="getCommonTitlesMode"
                    label="Titles in Common"
                    class="pt-4"
                    @click="toggleCommonTitles"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="pl-0 ml-0">
              <v-row>
                <v-col cols="8">
                  <v-card-title class="subtitle-2">Nanogenre Titles</v-card-title>
                </v-col>
                <v-col cols="4">
                  <v-switch
                    flat
                    v-model="showTitleImages"
                    label="Images"
                    class="pt-4"
                  ></v-switch>
                </v-col>  
              </v-row>
            </v-col>
            <v-col cols="4" class="pl-0 ml-0">
              <v-card-title class="subtitle-2">Nanogenre Keywords</v-card-title>
            </v-col>
          </v-row>
        </v-app-bar>

        <v-row style="margin-top: 52px">
          <v-col cols="4" style="overflow: hidden !important" class="pr-0">
            <v-card :height="getViewportHeight" tile outlined class="scrollable-card no-border">     
              <map-details-nanogenres :nanogenres="getNanogenreList"></map-details-nanogenres>         
            </v-card>
          </v-col>
          <v-col cols="4" class="pl-0 pr-0">
            <v-card :height="getViewportHeight" tile outlined class="scrollable-card">
              <selected-nanogenre v-if="getMapDetailsTitleList.length > 0"></selected-nanogenre>
              <v-card 
                v-for="title, index in getMapDetailsTitleList" :key="index" 
                @click="selectNanogenreTitle(title.ncTitleId)"
                tile flat outlined
              >
                <v-card-text>
                  {{title.description}}
                </v-card-text> 
                <v-lazy v-if="showTitleImages" min-height="92" transition="fade-transition">
                  <v-img
                    :src="`https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/medium/${title.ncTitleId}.jpg`"
                    min-height="92"
                    min-width="65"
                    max-height="92"
                    max-width="65"
                    class="rounded ml-4 pt-2 mb-2"
                  ></v-img>
                </v-lazy>        
              </v-card>
            </v-card>
          </v-col>
          <v-col class="pl-0 pr-0 mr-5">
            <v-card :height="getViewportHeight" tile outlined class="scrollable-card">
              <selected-nanogenre v-if="getMapDetailsKeywordList.length > 0"></selected-nanogenre>
              <v-card 
                tile flat outlined
                v-for="keyword, index in getMapDetailsKeywordList" :key="index"
              >
                <v-card-text>
                  {{index + 1}}. {{keyword}}
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MapDetailsNanogenres from './MapDetailsNanogenres'
import SelectedNanogenre from './SelectedNanogenre'

export default {
  components: { 
    MapDetailsNanogenres, 
    SelectedNanogenre 
  },
  name: 'MapDetails',
  data: () => ({
    stateless: true,
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  }),
  methods: {
    setViewportDimensions() {
      this.viewportWidth = window.innerWidth
      this.viewportHeight = window.innerHeight
    },
    // eslint-disable-next-line no-unused-vars
    selectNanogenreTitle(index) {
      // console.log(`title selected: ${index}`)
    },
    toggleCommonTitles() {
      const nanogenreList = this.$store.getters['server/getNanogenres']()
      this.$store.dispatch('mapDetails/setNanogenreList', nanogenreList)
    }
  },
  computed: {
    showTitleImages: {
      get() {
        return this.$store.getters['mapDetails/showTitleImages']()
      },
      set() {
        this.$store.dispatch('mapDetails/toggleShowTitleImages')
      }
    },
    getDrawer: {
      get() {
        return this.$store.getters['mapDetails/getDrawer']()
      },
      set() {}
    },
    getCommonTitlesMode: {
      get() {
        return this.$store.getters['mapDetails/getCommonNanogenreList']()
      },
      set() {
        this.$store.dispatch('mapDetails/toggleCommonNanogenreList')
      }
    },
    getViewportHeight() {
      // console.log(`MapDetails.getViewportHeight: ${ this.viewportHeight }`)
      return this.viewportHeight - 130
    },
    getNanogenreList() {
      const nanogenreList = this.$store.getters['mapDetails/getNanogenreList']() 
      return nanogenreList

    }, 
    getMapDetailsTitleList() {
      const titleIdList = this.$store.getters['mapDetails/getTitleList']()
      const titleData = this.$store.getters['server/getTitleListByNcTitleIdList'](titleIdList)
      const mapDetailsTitleList = titleData.map(item => {
        return {
          ncTitleId: item.ncTitleId,
          description: `${item.title} ${item.type === '13-TVseries' ? '[TV]' : ''} (${item.year})`
        }
      })

      return mapDetailsTitleList
    },
    getMapDetailsKeywordList() {
      const data = this.$store.getters['mapDetails/getKeywordList']()
      return data
    },
  },
  watch: {
  },
  mounted() {
    this.setViewportDimensions()
    // window.onresize = this.setViewportDimensions
    window.addEventListener('resize', this.setViewportDimensions)  }
}
</script>

<style scoped>
.top-offset {
  top: 62px !important;
}
.scrollable-card {
    flex-grow: 1;
    overflow: auto;
    overflow-x: hidden;
}
.no-border {
  border: none;
}
</style>
