export default {
  data: [
    {
      NanogenreId: 1,
      x: 10499,
      y: 12902
    },{
      NanogenreId: 2,
      x: 11268,
      y: 7717
    },{
      NanogenreId: 3,
      x: 11045,
      y: 11384
    },{
      NanogenreId: 4,
      x: 6569,
      y: 13134
    },{
      NanogenreId: 5,
      x: 10789,
      y: 13543
    },{
      NanogenreId: 6,
      x: 11818,
      y: 11299
    },{
      NanogenreId: 7,
      x: 2907,
      y: 10350
    },{
      NanogenreId: 8,
      x: 11100,
      y: 11281
    },{
      NanogenreId: 9,
      x: 13943,
      y: 10628
    },{
      NanogenreId: 10,
      x: 12630,
      y: 9259
    },{
      NanogenreId: 11,
      x: 10814,
      y: 11530
    },{
      NanogenreId: 12,
      x: 6285,
      y: 10867
    },{
      NanogenreId: 13,
      x: 10719,
      y: 4068
    },{
      NanogenreId: 14,
      x: 7117,
      y: 12808
    },{
      NanogenreId: 15,
      x: 2682,
      y: 9226
    },{
      NanogenreId: 16,
      x: 9053,
      y: 12746
    },{
      NanogenreId: 17,
      x: 3843,
      y: 9309
    },{
      NanogenreId: 18,
      x: 13095,
      y: 5558
    },{
      NanogenreId: 19,
      x: 7471,
      y: 11786
    },{
      NanogenreId: 20,
      x: 11893,
      y: 6636
    },{
      NanogenreId: 21,
      x: 7954,
      y: 3662
    },{
      NanogenreId: 22,
      x: 13655,
      y: 8562
    },{
      NanogenreId: 23,
      x: 10964,
      y: 11295
    },{
      NanogenreId: 24,
      x: 11323,
      y: 1735
    },{
      NanogenreId: 25,
      x: 3069,
      y: 10341
    },{
      NanogenreId: 26,
      x: 1789,
      y: 6267
    },{
      NanogenreId: 27,
      x: 3205,
      y: 8159
    },{
      NanogenreId: 28,
      x: 7581,
      y: 11910
    },{
      NanogenreId: 29,
      x: 13001,
      y: 8168
    },{
      NanogenreId: 30,
      x: 8724,
      y: 11336
    },{
      NanogenreId: 31,
      x: 7371,
      y: 12185
    },{
      NanogenreId: 32,
      x: 8654,
      y: 14375
    },{
      NanogenreId: 33,
      x: 12346,
      y: 11444
    },{
      NanogenreId: 34,
      x: 3602,
      y: 10882
    },{
      NanogenreId: 35,
      x: 13424,
      y: 3331
    },{
      NanogenreId: 36,
      x: 11872,
      y: 11697
    },{
      NanogenreId: 37,
      x: 8681,
      y: 8785
    },{
      NanogenreId: 38,
      x: 4148,
      y: 12295
    },{
      NanogenreId: 39,
      x: 2191,
      y: 5933
    },{
      NanogenreId: 40,
      x: 11329,
      y: 10007
    },{
      NanogenreId: 41,
      x: 9845,
      y: 4283
    },{
      NanogenreId: 42,
      x: 8603,
      y: 3584
    },{
      NanogenreId: 43,
      x: 10299,
      y: 4203
    },{
      NanogenreId: 44,
      x: 12308,
      y: 5438
    },{
      NanogenreId: 45,
      x: 2948,
      y: 10188
    },{
      NanogenreId: 46,
      x: 7184,
      y: 5072
    },{
      NanogenreId: 47,
      x: 3530,
      y: 3463
    },{
      NanogenreId: 48,
      x: 7356,
      y: 11147
    },{
      NanogenreId: 49,
      x: 13199,
      y: 10512
    },{
      NanogenreId: 50,
      x: 10547,
      y: 5221
    },{
      NanogenreId: 51,
      x: 9461,
      y: 4514
    },{
      NanogenreId: 52,
      x: 11268,
      y: 11438
    },{
      NanogenreId: 53,
      x: 8170,
      y: 10340
    },{
      NanogenreId: 54,
      x: 7236,
      y: 12914
    },{
      NanogenreId: 55,
      x: 5587,
      y: 6287
    },{
      NanogenreId: 56,
      x: 6903,
      y: 11010
    },{
      NanogenreId: 57,
      x: 6962,
      y: 3712
    },{
      NanogenreId: 58,
      x: 10825,
      y: 11047
    },{
      NanogenreId: 59,
      x: 6841,
      y: 4819
    },{
      NanogenreId: 60,
      x: 9121,
      y: 11615
    },{
      NanogenreId: 61,
      x: 11018,
      y: 1861
    },{
      NanogenreId: 62,
      x: 3615,
      y: 9441
    },{
      NanogenreId: 63,
      x: 8681,
      y: 3495
    },{
      NanogenreId: 64,
      x: 7395,
      y: 13443
    },{
      NanogenreId: 65,
      x: 12996,
      y: 5816
    },{
      NanogenreId: 66,
      x: 9020,
      y: 3382
    },{
      NanogenreId: 67,
      x: 7265,
      y: 2678
    },{
      NanogenreId: 68,
      x: 6459,
      y: 11968
    },{
      NanogenreId: 69,
      x: 5441,
      y: 5660
    },{
      NanogenreId: 70,
      x: 2516,
      y: 6038
    },{
      NanogenreId: 71,
      x: 7043,
      y: 12811
    },{
      NanogenreId: 72,
      x: 4877,
      y: 9022
    },{
      NanogenreId: 73,
      x: 11177,
      y: 9889
    },{
      NanogenreId: 74,
      x: 4244,
      y: 5314
    },{
      NanogenreId: 75,
      x: 6922,
      y: 12562
    },{
      NanogenreId: 76,
      x: 5313,
      y: 13192
    },{
      NanogenreId: 77,
      x: 5156,
      y: 10036
    },{
      NanogenreId: 78,
      x: 6385,
      y: 12494
    },{
      NanogenreId: 79,
      x: 12257,
      y: 5843
    },{
      NanogenreId: 80,
      x: 1575,
      y: 6626
    },{
      NanogenreId: 81,
      x: 2718,
      y: 8235
    },{
      NanogenreId: 82,
      x: 7296,
      y: 3420
    },{
      NanogenreId: 83,
      x: 10717,
      y: 11866
    },{
      NanogenreId: 84,
      x: 10235,
      y: 4578
    },{
      NanogenreId: 85,
      x: 10062,
      y: 4078
    },{
      NanogenreId: 86,
      x: 4406,
      y: 8210
    },{
      NanogenreId: 87,
      x: 10796,
      y: 12111
    },{
      NanogenreId: 88,
      x: 7374,
      y: 12036
    },{
      NanogenreId: 89,
      x: 11300,
      y: 10917
    },{
      NanogenreId: 90,
      x: 3161,
      y: 10333
    },{
      NanogenreId: 91,
      x: 13661,
      y: 9906
    },{
      NanogenreId: 92,
      x: 12245,
      y: 6413
    },{
      NanogenreId: 93,
      x: 10355,
      y: 11486
    },{
      NanogenreId: 94,
      x: 4049,
      y: 4183
    },{
      NanogenreId: 95,
      x: 2977,
      y: 9946
    },{
      NanogenreId: 96,
      x: 12419,
      y: 11005
    },{
      NanogenreId: 97,
      x: 7138,
      y: 12497
    },{
      NanogenreId: 98,
      x: 6914,
      y: 11056
    },{
      NanogenreId: 99,
      x: 3517,
      y: 7219
    },{
      NanogenreId: 100,
      x: 7608,
      y: 11543
    },{
      NanogenreId: 101,
      x: 8070,
      y: 12572
    },{
      NanogenreId: 102,
      x: 2829,
      y: 10365
    },{
      NanogenreId: 103,
      x: 10959,
      y: 12433
    },{
      NanogenreId: 104,
      x: 13234,
      y: 5583
    },{
      NanogenreId: 105,
      x: 7257,
      y: 12731
    },{
      NanogenreId: 106,
      x: 9845,
      y: 4480
    },{
      NanogenreId: 107,
      x: 3450,
      y: 9457
    },{
      NanogenreId: 108,
      x: 12853,
      y: 7920
    },{
      NanogenreId: 109,
      x: 13233,
      y: 5179
    },{
      NanogenreId: 110,
      x: 7159,
      y: 13283
    },{
      NanogenreId: 111,
      x: 9921,
      y: 4257
    },{
      NanogenreId: 112,
      x: 8143,
      y: 3063
    },{
      NanogenreId: 113,
      x: 7217,
      y: 11926
    },{
      NanogenreId: 114,
      x: 13577,
      y: 3398
    },{
      NanogenreId: 115,
      x: 14146,
      y: 7054
    },{
      NanogenreId: 116,
      x: 6763,
      y: 12670
    },{
      NanogenreId: 117,
      x: 5974,
      y: 8247
    },{
      NanogenreId: 118,
      x: 11320,
      y: 10526
    },{
      NanogenreId: 119,
      x: 11969,
      y: 11447
    },{
      NanogenreId: 120,
      x: 6107,
      y: 2217
    },{
      NanogenreId: 121,
      x: 5425,
      y: 3484
    },{
      NanogenreId: 122,
      x: 5098,
      y: 6213
    },{
      NanogenreId: 123,
      x: 11358,
      y: 1853
    },{
      NanogenreId: 124,
      x: 12280,
      y: 5084
    },{
      NanogenreId: 125,
      x: 10037,
      y: 12364
    },{
      NanogenreId: 126,
      x: 9489,
      y: 4346
    },{
      NanogenreId: 127,
      x: 5259,
      y: 12605
    },{
      NanogenreId: 128,
      x: 7572,
      y: 4201
    },{
      NanogenreId: 129,
      x: 11100,
      y: 3980
    },{
      NanogenreId: 130,
      x: 10496,
      y: 10446
    },{
      NanogenreId: 131,
      x: 7754,
      y: 9351
    },{
      NanogenreId: 132,
      x: 11179,
      y: 9414
    },{
      NanogenreId: 133,
      x: 12340,
      y: 6624
    },{
      NanogenreId: 134,
      x: 11491,
      y: 10468
    },{
      NanogenreId: 135,
      x: 10667,
      y: 11456
    },{
      NanogenreId: 136,
      x: 10183,
      y: 4654
    },{
      NanogenreId: 137,
      x: 5930,
      y: 9766
    },{
      NanogenreId: 138,
      x: 12636,
      y: 7236
    },{
      NanogenreId: 139,
      x: 2999,
      y: 7805
    },{
      NanogenreId: 140,
      x: 13440,
      y: 6283
    },{
      NanogenreId: 141,
      x: 9960,
      y: 4264
    },{
      NanogenreId: 142,
      x: 12969,
      y: 11132
    },{
      NanogenreId: 143,
      x: 7536,
      y: 9063
    },{
      NanogenreId: 144,
      x: 11435,
      y: 11059
    },{
      NanogenreId: 145,
      x: 12262,
      y: 6922
    },{
      NanogenreId: 146,
      x: 8670,
      y: 11958
    },{
      NanogenreId: 147,
      x: 5813,
      y: 5133
    },{
      NanogenreId: 148,
      x: 2467,
      y: 7623
    },{
      NanogenreId: 149,
      x: 7020,
      y: 3217
    },{
      NanogenreId: 150,
      x: 3242,
      y: 7400
    },{
      NanogenreId: 151,
      x: 11154,
      y: 9858
    },{
      NanogenreId: 152,
      x: 12370,
      y: 2140
    },{
      NanogenreId: 153,
      x: 11350,
      y: 5174
    },{
      NanogenreId: 154,
      x: 5080,
      y: 11444
    },{
      NanogenreId: 155,
      x: 4456,
      y: 10588
    },{
      NanogenreId: 156,
      x: 4954,
      y: 13703
    },{
      NanogenreId: 157,
      x: 4026,
      y: 3051
    },{
      NanogenreId: 158,
      x: 4360,
      y: 9989
    },{
      NanogenreId: 159,
      x: 7300,
      y: 11786
    },{
      NanogenreId: 160,
      x: 5600,
      y: 12949
    },{
      NanogenreId: 161,
      x: 11978,
      y: 7377
    },{
      NanogenreId: 162,
      x: 10129,
      y: 4309
    },{
      NanogenreId: 163,
      x: 12075,
      y: 3447
    },{
      NanogenreId: 164,
      x: 10419,
      y: 3792
    },{
      NanogenreId: 165,
      x: 8260,
      y: 2957
    },{
      NanogenreId: 166,
      x: 9963,
      y: 4391
    },{
      NanogenreId: 167,
      x: 8446,
      y: 4649
    },{
      NanogenreId: 168,
      x: 11744,
      y: 11428
    },{
      NanogenreId: 169,
      x: 5797,
      y: 13047
    },{
      NanogenreId: 170,
      x: 4585,
      y: 2449
    },{
      NanogenreId: 171,
      x: 3572,
      y: 9704
    },{
      NanogenreId: 172,
      x: 9847,
      y: 4389
    },{
      NanogenreId: 173,
      x: 4032,
      y: 3158
    },{
      NanogenreId: 174,
      x: 11548,
      y: 10572
    },{
      NanogenreId: 175,
      x: 12301,
      y: 5158
    },{
      NanogenreId: 176,
      x: 3760,
      y: 10633
    },{
      NanogenreId: 177,
      x: 13676,
      y: 9754
    },{
      NanogenreId: 178,
      x: 6015,
      y: 5287
    },{
      NanogenreId: 179,
      x: 3064,
      y: 10942
    },{
      NanogenreId: 180,
      x: 11631,
      y: 9337
    },{
      NanogenreId: 181,
      x: 6251,
      y: 8710
    },{
      NanogenreId: 182,
      x: 9287,
      y: 4065
    },{
      NanogenreId: 183,
      x: 4953,
      y: 5835
    },{
      NanogenreId: 184,
      x: 9276,
      y: 3022
    },{
      NanogenreId: 185,
      x: 2845,
      y: 8112
    },{
      NanogenreId: 186,
      x: 11275,
      y: 4200
    },{
      NanogenreId: 187,
      x: 11132,
      y: 8764
    },{
      NanogenreId: 188,
      x: 11240,
      y: 10422
    },{
      NanogenreId: 189,
      x: 4981,
      y: 5443
    },{
      NanogenreId: 190,
      x: 10369,
      y: 4555
    },{
      NanogenreId: 191,
      x: 11832,
      y: 11559
    },{
      NanogenreId: 192,
      x: 12644,
      y: 5746
    },{
      NanogenreId: 193,
      x: 4874,
      y: 5049
    },{
      NanogenreId: 194,
      x: 10661,
      y: 5009
    },{
      NanogenreId: 195,
      x: 10284,
      y: 4265
    },{
      NanogenreId: 196,
      x: 6791,
      y: 12647
    },{
      NanogenreId: 197,
      x: 6669,
      y: 3457
    },{
      NanogenreId: 198,
      x: 5201,
      y: 12263
    },{
      NanogenreId: 199,
      x: 7423,
      y: 12689
    },{
      NanogenreId: 200,
      x: 7822,
      y: 11838
    },{
      NanogenreId: 201,
      x: 7507,
      y: 12751
    },{
      NanogenreId: 202,
      x: 10786,
      y: 10109
    },{
      NanogenreId: 203,
      x: 7590,
      y: 13650
    },{
      NanogenreId: 204,
      x: 12119,
      y: 9157
    },{
      NanogenreId: 205,
      x: 8387,
      y: 5436
    },{
      NanogenreId: 206,
      x: 10833,
      y: 11303
    },{
      NanogenreId: 207,
      x: 12534,
      y: 5641
    },{
      NanogenreId: 208,
      x: 11145,
      y: 10689
    },{
      NanogenreId: 209,
      x: 10214,
      y: 12504
    },{
      NanogenreId: 210,
      x: 7939,
      y: 10885
    },{
      NanogenreId: 211,
      x: 7101,
      y: 4103
    },{
      NanogenreId: 212,
      x: 14375,
      y: 7956
    },{
      NanogenreId: 213,
      x: 11270,
      y: 11208
    },{
      NanogenreId: 214,
      x: 5518,
      y: 7353
    },{
      NanogenreId: 215,
      x: 14185,
      y: 8877
    },{
      NanogenreId: 216,
      x: 3673,
      y: 10175
    },{
      NanogenreId: 217,
      x: 11542,
      y: 10952
    },{
      NanogenreId: 218,
      x: 10103,
      y: 4271
    },{
      NanogenreId: 219,
      x: 10816,
      y: 4075
    },{
      NanogenreId: 220,
      x: 3413,
      y: 8536
    },{
      NanogenreId: 221,
      x: 11053,
      y: 3784
    },{
      NanogenreId: 222,
      x: 2485,
      y: 9587
    },{
      NanogenreId: 223,
      x: 11068,
      y: 2460
    },{
      NanogenreId: 224,
      x: 7322,
      y: 11440
    },{
      NanogenreId: 225,
      x: 10398,
      y: 10697
    },{
      NanogenreId: 226,
      x: 10248,
      y: 4947
    },{
      NanogenreId: 227,
      x: 4911,
      y: 11606
    },{
      NanogenreId: 228,
      x: 2846,
      y: 6151
    },{
      NanogenreId: 229,
      x: 7057,
      y: 11708
    },{
      NanogenreId: 230,
      x: 13523,
      y: 3396
    },{
      NanogenreId: 231,
      x: 10517,
      y: 11106
    },{
      NanogenreId: 232,
      x: 10836,
      y: 11842
    },{
      NanogenreId: 233,
      x: 6268,
      y: 4731
    },{
      NanogenreId: 234,
      x: 12136,
      y: 6780
    },{
      NanogenreId: 235,
      x: 11775,
      y: 10641
    },{
      NanogenreId: 236,
      x: 10112,
      y: 11093
    },{
      NanogenreId: 237,
      x: 3070,
      y: 10141
    },{
      NanogenreId: 238,
      x: 13343,
      y: 9911
    },{
      NanogenreId: 239,
      x: 13145,
      y: 6949
    },{
      NanogenreId: 240,
      x: 8603,
      y: 11475
    },{
      NanogenreId: 241,
      x: 14172,
      y: 10532
    },{
      NanogenreId: 242,
      x: 11245,
      y: 11047
    },{
      NanogenreId: 243,
      x: 11689,
      y: 9485
    },{
      NanogenreId: 244,
      x: 2868,
      y: 9819
    },{
      NanogenreId: 245,
      x: 7241,
      y: 11355
    },{
      NanogenreId: 246,
      x: 6481,
      y: 12583
    },{
      NanogenreId: 247,
      x: 6158,
      y: 5929
    },{
      NanogenreId: 248,
      x: 2433,
      y: 8037
    },{
      NanogenreId: 249,
      x: 10750,
      y: 10987
    },{
      NanogenreId: 250,
      x: 9991,
      y: 5271
    },{
      NanogenreId: 251,
      x: 11453,
      y: 11272
    },{
      NanogenreId: 252,
      x: 9345,
      y: 12531
    },{
      NanogenreId: 253,
      x: 7808,
      y: 4248
    },{
      NanogenreId: 254,
      x: 11546,
      y: 6076
    },{
      NanogenreId: 255,
      x: 6112,
      y: 4702
    },{
      NanogenreId: 256,
      x: 13731,
      y: 8103
    },{
      NanogenreId: 257,
      x: 2787,
      y: 10265
    },{
      NanogenreId: 258,
      x: 6627,
      y: 13284
    },{
      NanogenreId: 259,
      x: 9213,
      y: 3877
    },{
      NanogenreId: 260,
      x: 10632,
      y: 6257
    },{
      NanogenreId: 261,
      x: 4973,
      y: 13873
    },{
      NanogenreId: 262,
      x: 4887,
      y: 9238
    },{
      NanogenreId: 263,
      x: 10818,
      y: 4769
    },{
      NanogenreId: 264,
      x: 11402,
      y: 11214
    },{
      NanogenreId: 265,
      x: 10428,
      y: 4277
    },{
      NanogenreId: 266,
      x: 8468,
      y: 8767
    },{
      NanogenreId: 267,
      x: 6401,
      y: 5743
    },{
      NanogenreId: 268,
      x: 13528,
      y: 6835
    },{
      NanogenreId: 269,
      x: 4987,
      y: 6620
    },{
      NanogenreId: 270,
      x: 2544,
      y: 9313
    },{
      NanogenreId: 271,
      x: 11010,
      y: 11853
    },{
      NanogenreId: 272,
      x: 11471,
      y: 10700
    },{
      NanogenreId: 273,
      x: 3119,
      y: 9846
    },{
      NanogenreId: 274,
      x: 7009,
      y: 12300
    },{
      NanogenreId: 275,
      x: 6792,
      y: 8488
    },{
      NanogenreId: 276,
      x: 8634,
      y: 10139
    },{
      NanogenreId: 277,
      x: 10240,
      y: 7227
    },{
      NanogenreId: 278,
      x: 9920,
      y: 12654
    },{
      NanogenreId: 279,
      x: 14096,
      y: 10274
    },{
      NanogenreId: 280,
      x: 11762,
      y: 12669
    },{
      NanogenreId: 281,
      x: 3446,
      y: 3930
    },{
      NanogenreId: 282,
      x: 5222,
      y: 13201
    },{
      NanogenreId: 283,
      x: 12689,
      y: 6263
    },{
      NanogenreId: 284,
      x: 10612,
      y: 8267
    },{
      NanogenreId: 285,
      x: 8971,
      y: 4549
    },{
      NanogenreId: 286,
      x: 9698,
      y: 3790
    },{
      NanogenreId: 287,
      x: 11101,
      y: 9949
    },{
      NanogenreId: 288,
      x: 3426,
      y: 7251
    },{
      NanogenreId: 289,
      x: 9261,
      y: 3950
    },{
      NanogenreId: 290,
      x: 13637,
      y: 7775
    },{
      NanogenreId: 291,
      x: 8890,
      y: 4494
    },{
      NanogenreId: 292,
      x: 8577,
      y: 14095
    },{
      NanogenreId: 293,
      x: 3377,
      y: 10136
    },{
      NanogenreId: 294,
      x: 3058,
      y: 7064
    },{
      NanogenreId: 295,
      x: 12024,
      y: 11388
    },{
      NanogenreId: 296,
      x: 5913,
      y: 8168
    },{
      NanogenreId: 297,
      x: 3513,
      y: 4660
    },{
      NanogenreId: 298,
      x: 5675,
      y: 4008
    },{
      NanogenreId: 299,
      x: 11021,
      y: 11095
    },{
      NanogenreId: 300,
      x: 10063,
      y: 11554
    },{
      NanogenreId: 301,
      x: 11001,
      y: 10814
    },{
      NanogenreId: 302,
      x: 9695,
      y: 4411
    },{
      NanogenreId: 303,
      x: 7889,
      y: 11170
    },{
      NanogenreId: 304,
      x: 7071,
      y: 3654
    },{
      NanogenreId: 305,
      x: 13138,
      y: 7058
    },{
      NanogenreId: 306,
      x: 9462,
      y: 4383
    },{
      NanogenreId: 307,
      x: 4746,
      y: 6978
    },{
      NanogenreId: 308,
      x: 8398,
      y: 11587
    },{
      NanogenreId: 309,
      x: 4613,
      y: 7186
    },{
      NanogenreId: 310,
      x: 8167,
      y: 4467
    },{
      NanogenreId: 311,
      x: 11522,
      y: 8742
    },{
      NanogenreId: 312,
      x: 5163,
      y: 10846
    },{
      NanogenreId: 313,
      x: 10482,
      y: 4316
    },{
      NanogenreId: 314,
      x: 4246,
      y: 5917
    },{
      NanogenreId: 315,
      x: 9060,
      y: 12450
    },{
      NanogenreId: 316,
      x: 5952,
      y: 5498
    },{
      NanogenreId: 317,
      x: 9687,
      y: 4273
    },{
      NanogenreId: 318,
      x: 5575,
      y: 11753
    },{
      NanogenreId: 319,
      x: 10743,
      y: 11547
    },{
      NanogenreId: 320,
      x: 6436,
      y: 4100
    },{
      NanogenreId: 321,
      x: 10755,
      y: 4364
    },{
      NanogenreId: 322,
      x: 12378,
      y: 7856
    },{
      NanogenreId: 323,
      x: 5311,
      y: 5183
    },{
      NanogenreId: 324,
      x: 4380,
      y: 12070
    },{
      NanogenreId: 325,
      x: 3991,
      y: 4610
    },{
      NanogenreId: 326,
      x: 4058,
      y: 7706
    },{
      NanogenreId: 327,
      x: 9220,
      y: 4329
    },{
      NanogenreId: 328,
      x: 9570,
      y: 3959
    },{
      NanogenreId: 329,
      x: 8186,
      y: 10930
    },{
      NanogenreId: 330,
      x: 10457,
      y: 12285
    },{
      NanogenreId: 331,
      x: 6917,
      y: 9954
    },{
      NanogenreId: 332,
      x: 13553,
      y: 3214
    },{
      NanogenreId: 333,
      x: 13367,
      y: 5756
    },{
      NanogenreId: 334,
      x: 11273,
      y: 10735
    },{
      NanogenreId: 335,
      x: 5480,
      y: 11379
    },{
      NanogenreId: 336,
      x: 5795,
      y: 9857
    },{
      NanogenreId: 337,
      x: 9204,
      y: 4613
    },{
      NanogenreId: 338,
      x: 8810,
      y: 11086
    },{
      NanogenreId: 339,
      x: 10671,
      y: 5195
    },{
      NanogenreId: 340,
      x: 6004,
      y: 6643
    },{
      NanogenreId: 341,
      x: 7097,
      y: 12537
    },{
      NanogenreId: 342,
      x: 2851,
      y: 6735
    },{
      NanogenreId: 343,
      x: 12378,
      y: 5949
    },{
      NanogenreId: 344,
      x: 6514,
      y: 11076
    },{
      NanogenreId: 345,
      x: 3758,
      y: 7294
    },{
      NanogenreId: 346,
      x: 6287,
      y: 9212
    },{
      NanogenreId: 347,
      x: 8169,
      y: 3511
    },{
      NanogenreId: 348,
      x: 4848,
      y: 3150
    },{
      NanogenreId: 349,
      x: 3142,
      y: 9231
    },{
      NanogenreId: 350,
      x: 11610,
      y: 11085
    },{
      NanogenreId: 351,
      x: 7121,
      y: 12040
    },{
      NanogenreId: 352,
      x: 8814,
      y: 3356
    },{
      NanogenreId: 353,
      x: 5673,
      y: 6392
    },{
      NanogenreId: 354,
      x: 12789,
      y: 9559
    },{
      NanogenreId: 355,
      x: 3024,
      y: 9438
    },{
      NanogenreId: 356,
      x: 11666,
      y: 10997
    },{
      NanogenreId: 357,
      x: 3371,
      y: 10795
    },{
      NanogenreId: 358,
      x: 5026,
      y: 9283
    },{
      NanogenreId: 359,
      x: 7665,
      y: 12302
    },{
      NanogenreId: 360,
      x: 10166,
      y: 5856
    },{
      NanogenreId: 361,
      x: 8753,
      y: 5992
    },{
      NanogenreId: 362,
      x: 10719,
      y: 1632
    },{
      NanogenreId: 363,
      x: 4431,
      y: 2749
    },{
      NanogenreId: 364,
      x: 4536,
      y: 5496
    },{
      NanogenreId: 365,
      x: 7889,
      y: 13179
    },{
      NanogenreId: 366,
      x: 8229,
      y: 3779
    },{
      NanogenreId: 367,
      x: 5892,
      y: 12052
    },{
      NanogenreId: 368,
      x: 9810,
      y: 4063
    },{
      NanogenreId: 369,
      x: 10114,
      y: 10878
    },{
      NanogenreId: 370,
      x: 4341,
      y: 11872
    },{
      NanogenreId: 371,
      x: 9868,
      y: 4368
    },{
      NanogenreId: 372,
      x: 4677,
      y: 10981
    },{
      NanogenreId: 373,
      x: 11776,
      y: 11223
    },{
      NanogenreId: 374,
      x: 8025,
      y: 3679
    },{
      NanogenreId: 375,
      x: 11972,
      y: 5163
    },{
      NanogenreId: 376,
      x: 4822,
      y: 5092
    },{
      NanogenreId: 377,
      x: 7285,
      y: 3726
    },{
      NanogenreId: 378,
      x: 8329,
      y: 5056
    },{
      NanogenreId: 379,
      x: 2428,
      y: 8650
    },{
      NanogenreId: 380,
      x: 11214,
      y: 11529
    },{
      NanogenreId: 381,
      x: 5626,
      y: 2658
    },{
      NanogenreId: 382,
      x: 10667,
      y: 5879
    },{
      NanogenreId: 383,
      x: 6240,
      y: 3284
    },{
      NanogenreId: 384,
      x: 5684,
      y: 5358
    },{
      NanogenreId: 385,
      x: 11443,
      y: 10850
    },{
      NanogenreId: 386,
      x: 10998,
      y: 11219
    },{
      NanogenreId: 387,
      x: 10268,
      y: 4202
    },{
      NanogenreId: 388,
      x: 5997,
      y: 5031
    },{
      NanogenreId: 389,
      x: 4737,
      y: 4750
    },{
      NanogenreId: 390,
      x: 4537,
      y: 10108
    },{
      NanogenreId: 391,
      x: 8998,
      y: 7765
    },{
      NanogenreId: 392,
      x: 14161,
      y: 7195
    },{
      NanogenreId: 393,
      x: 5593,
      y: 3298
    },{
      NanogenreId: 394,
      x: 3240,
      y: 11114
    },{
      NanogenreId: 395,
      x: 5889,
      y: 7979
    },{
      NanogenreId: 396,
      x: 13418,
      y: 9337
    },{
      NanogenreId: 397,
      x: 7389,
      y: 11867
    },{
      NanogenreId: 398,
      x: 11045,
      y: 7940
    },{
      NanogenreId: 399,
      x: 7380,
      y: 11324
    },{
      NanogenreId: 400,
      x: 6784,
      y: 4775
    },{
      NanogenreId: 401,
      x: 9814,
      y: 4469
    },{
      NanogenreId: 402,
      x: 4713,
      y: 6041
    },{
      NanogenreId: 403,
      x: 10458,
      y: 8903
    },{
      NanogenreId: 404,
      x: 6725,
      y: 12089
    },{
      NanogenreId: 405,
      x: 11519,
      y: 10522
    },{
      NanogenreId: 406,
      x: 6904,
      y: 12992
    },{
      NanogenreId: 407,
      x: 13694,
      y: 4467
    },{
      NanogenreId: 408,
      x: 3891,
      y: 6796
    },{
      NanogenreId: 409,
      x: 3191,
      y: 10124
    },{
      NanogenreId: 410,
      x: 10985,
      y: 10633
    },{
      NanogenreId: 411,
      x: 10970,
      y: 10032
    },{
      NanogenreId: 412,
      x: 8249,
      y: 2533
    },{
      NanogenreId: 413,
      x: 6817,
      y: 8129
    },{
      NanogenreId: 414,
      x: 3815,
      y: 8625
    },{
      NanogenreId: 415,
      x: 11678,
      y: 6736
    },{
      NanogenreId: 416,
      x: 10923,
      y: 8460
    },{
      NanogenreId: 417,
      x: 7864,
      y: 4245
    },{
      NanogenreId: 418,
      x: 8963,
      y: 10957
    },{
      NanogenreId: 419,
      x: 12685,
      y: 5424
    },{
      NanogenreId: 420,
      x: 1741,
      y: 6632
    },{
      NanogenreId: 421,
      x: 7430,
      y: 11091
    },{
      NanogenreId: 422,
      x: 11711,
      y: 11296
    },{
      NanogenreId: 423,
      x: 7209,
      y: 4686
    },{
      NanogenreId: 424,
      x: 9955,
      y: 4365
    },{
      NanogenreId: 425,
      x: 4533,
      y: 6076
    },{
      NanogenreId: 426,
      x: 10934,
      y: 8064
    },{
      NanogenreId: 427,
      x: 5681,
      y: 11209
    },{
      NanogenreId: 428,
      x: 10185,
      y: 4624
    },{
      NanogenreId: 429,
      x: 10397,
      y: 4808
    },{
      NanogenreId: 430,
      x: 8271,
      y: 9871
    },{
      NanogenreId: 431,
      x: 10073,
      y: 6302
    },{
      NanogenreId: 432,
      x: 5351,
      y: 6366
    },{
      NanogenreId: 433,
      x: 11120,
      y: 12265
    },{
      NanogenreId: 434,
      x: 11690,
      y: 10963
    },{
      NanogenreId: 435,
      x: 4521,
      y: 6156
    },{
      NanogenreId: 436,
      x: 5655,
      y: 9182
    },{
      NanogenreId: 437,
      x: 10683,
      y: 11253
    },{
      NanogenreId: 438,
      x: 11354,
      y: 10130
    },{
      NanogenreId: 439,
      x: 9869,
      y: 4644
    },{
      NanogenreId: 440,
      x: 7431,
      y: 11537
    },{
      NanogenreId: 441,
      x: 9404,
      y: 4606
    },{
      NanogenreId: 442,
      x: 6732,
      y: 12700
    },{
      NanogenreId: 443,
      x: 10256,
      y: 1583
    },{
      NanogenreId: 444,
      x: 11265,
      y: 3812
    },{
      NanogenreId: 445,
      x: 13572,
      y: 7073
    },{
      NanogenreId: 446,
      x: 2980,
      y: 8768
    },{
      NanogenreId: 447,
      x: 4031,
      y: 5371
    },{
      NanogenreId: 448,
      x: 4704,
      y: 5197
    },{
      NanogenreId: 449,
      x: 6974,
      y: 5157
    },{
      NanogenreId: 450,
      x: 9542,
      y: 3692
    },{
      NanogenreId: 451,
      x: 12676,
      y: 6186
    },{
      NanogenreId: 452,
      x: 6369,
      y: 5835
    },{
      NanogenreId: 453,
      x: 11898,
      y: 9711
    },{
      NanogenreId: 454,
      x: 7032,
      y: 5280
    },{
      NanogenreId: 455,
      x: 11390,
      y: 10904
    },{
      NanogenreId: 456,
      x: 6721,
      y: 5398
    },{
      NanogenreId: 457,
      x: 11354,
      y: 11303
    },{
      NanogenreId: 458,
      x: 13896,
      y: 6892
    },{
      NanogenreId: 459,
      x: 3506,
      y: 7675
    },{
      NanogenreId: 460,
      x: 9755,
      y: 4319
    },{
      NanogenreId: 461,
      x: 7429,
      y: 11178
    },{
      NanogenreId: 462,
      x: 3518,
      y: 10725
    },{
      NanogenreId: 463,
      x: 11244,
      y: 10087
    },{
      NanogenreId: 464,
      x: 8816,
      y: 13419
    },{
      NanogenreId: 465,
      x: 5877,
      y: 12693
    },{
      NanogenreId: 466,
      x: 9944,
      y: 4312
    },{
      NanogenreId: 467,
      x: 8703,
      y: 4398
    },{
      NanogenreId: 468,
      x: 6643,
      y: 9976
    },{
      NanogenreId: 469,
      x: 5252,
      y: 5496
    },{
      NanogenreId: 470,
      x: 5659,
      y: 11096
    },{
      NanogenreId: 471,
      x: 7854,
      y: 11972
    },{
      NanogenreId: 472,
      x: 9294,
      y: 10749
    },{
      NanogenreId: 473,
      x: 12096,
      y: 9844
    },{
      NanogenreId: 474,
      x: 10583,
      y: 4379
    },{
      NanogenreId: 475,
      x: 11720,
      y: 9593
    },{
      NanogenreId: 476,
      x: 5002,
      y: 12686
    },{
      NanogenreId: 477,
      x: 4443,
      y: 3098
    },{
      NanogenreId: 478,
      x: 10319,
      y: 4431
    },{
      NanogenreId: 479,
      x: 9307,
      y: 10465
    },{
      NanogenreId: 480,
      x: 7646,
      y: 3805
    },{
      NanogenreId: 481,
      x: 4279,
      y: 12426
    },{
      NanogenreId: 482,
      x: 10622,
      y: 9756
    },{
      NanogenreId: 483,
      x: 7023,
      y: 11671
    },{
      NanogenreId: 484,
      x: 11573,
      y: 10873
    },{
      NanogenreId: 485,
      x: 13798,
      y: 6760
    },{
      NanogenreId: 486,
      x: 4049,
      y: 8974
    },{
      NanogenreId: 487,
      x: 4876,
      y: 6846
    },{
      NanogenreId: 488,
      x: 10859,
      y: 4926
    },{
      NanogenreId: 489,
      x: 10115,
      y: 4646
    },{
      NanogenreId: 490,
      x: 12094,
      y: 9734
    },{
      NanogenreId: 491,
      x: 4393,
      y: 9301
    },{
      NanogenreId: 492,
      x: 8847,
      y: 10508
    },{
      NanogenreId: 493,
      x: 7652,
      y: 2624
    },{
      NanogenreId: 494,
      x: 9216,
      y: 4279
    },{
      NanogenreId: 495,
      x: 7558,
      y: 12712
    },{
      NanogenreId: 496,
      x: 9571,
      y: 13026
    },{
      NanogenreId: 497,
      x: 7145,
      y: 6263
    },{
      NanogenreId: 498,
      x: 6512,
      y: 5889
    },{
      NanogenreId: 499,
      x: 11389,
      y: 9950
    },{
      NanogenreId: 500,
      x: 12799,
      y: 9336
    },{
      NanogenreId: 501,
      x: 12524,
      y: 7809
    },{
      NanogenreId: 502,
      x: 13318,
      y: 7408
    },{
      NanogenreId: 503,
      x: 9979,
      y: 5417
    },{
      NanogenreId: 504,
      x: 11467,
      y: 11468
    },{
      NanogenreId: 505,
      x: 8894,
      y: 4416
    },{
      NanogenreId: 506,
      x: 6360,
      y: 11442
    },{
      NanogenreId: 507,
      x: 10870,
      y: 13520
    },{
      NanogenreId: 508,
      x: 10770,
      y: 11410
    },{
      NanogenreId: 509,
      x: 6919,
      y: 6409
    },{
      NanogenreId: 510,
      x: 12086,
      y: 8871
    },{
      NanogenreId: 511,
      x: 9933,
      y: 4451
    },{
      NanogenreId: 512,
      x: 8550,
      y: 11382
    },{
      NanogenreId: 513,
      x: 11268,
      y: 9146
    },{
      NanogenreId: 514,
      x: 12426,
      y: 10090
    },{
      NanogenreId: 515,
      x: 10509,
      y: 4356
    },{
      NanogenreId: 516,
      x: 8279,
      y: 13071
    },{
      NanogenreId: 517,
      x: 6879,
      y: 12452
    },{
      NanogenreId: 518,
      x: 11071,
      y: 10406
    },{
      NanogenreId: 519,
      x: 10097,
      y: 3691
    },{
      NanogenreId: 520,
      x: 3376,
      y: 10367
    },{
      NanogenreId: 521,
      x: 10169,
      y: 4390
    },{
      NanogenreId: 522,
      x: 7929,
      y: 4916
    },{
      NanogenreId: 523,
      x: 8249,
      y: 10748
    },{
      NanogenreId: 524,
      x: 3633,
      y: 8893
    },{
      NanogenreId: 525,
      x: 11077,
      y: 2767
    },{
      NanogenreId: 526,
      x: 12807,
      y: 4938
    },{
      NanogenreId: 527,
      x: 10997,
      y: 10865
    },{
      NanogenreId: 528,
      x: 2919,
      y: 6975
    },{
      NanogenreId: 529,
      x: 12033,
      y: 4804
    },{
      NanogenreId: 530,
      x: 6639,
      y: 3401
    },{
      NanogenreId: 531,
      x: 11316,
      y: 3098
    },{
      NanogenreId: 532,
      x: 6354,
      y: 7571
    },{
      NanogenreId: 533,
      x: 12082,
      y: 7362
    },{
      NanogenreId: 534,
      x: 10054,
      y: 11779
    },{
      NanogenreId: 535,
      x: 4700,
      y: 8536
    },{
      NanogenreId: 536,
      x: 9998,
      y: 4497
    },{
      NanogenreId: 537,
      x: 5843,
      y: 10933
    },{
      NanogenreId: 538,
      x: 6962,
      y: 5844
    },{
      NanogenreId: 539,
      x: 4075,
      y: 11958
    },{
      NanogenreId: 540,
      x: 9916,
      y: 6452
    },{
      NanogenreId: 541,
      x: 5266,
      y: 6285
    },{
      NanogenreId: 542,
      x: 6489,
      y: 3952
    },{
      NanogenreId: 543,
      x: 13279,
      y: 5111
    },{
      NanogenreId: 544,
      x: 9629,
      y: 5763
    },{
      NanogenreId: 545,
      x: 9310,
      y: 3884
    },{
      NanogenreId: 546,
      x: 11645,
      y: 11042
    },{
      NanogenreId: 547,
      x: 9576,
      y: 4238
    },{
      NanogenreId: 548,
      x: 6505,
      y: 4794
    },{
      NanogenreId: 549,
      x: 9966,
      y: 4532
    },{
      NanogenreId: 550,
      x: 2659,
      y: 7852
    },{
      NanogenreId: 551,
      x: 14178,
      y: 7597
    },{
      NanogenreId: 552,
      x: 13409,
      y: 7907
    },{
      NanogenreId: 553,
      x: 12990,
      y: 9444
    },{
      NanogenreId: 554,
      x: 9694,
      y: 4091
    },{
      NanogenreId: 555,
      x: 3860,
      y: 8006
    },{
      NanogenreId: 556,
      x: 11094,
      y: 9786
    },{
      NanogenreId: 557,
      x: 9425,
      y: 4717
    },{
      NanogenreId: 558,
      x: 6001,
      y: 11940
    },{
      NanogenreId: 559,
      x: 10920,
      y: 11955
    },{
      NanogenreId: 560,
      x: 6329,
      y: 12005
    },{
      NanogenreId: 561,
      x: 7010,
      y: 12532
    },{
      NanogenreId: 562,
      x: 11255,
      y: 10269
    },{
      NanogenreId: 563,
      x: 10329,
      y: 6568
    },{
      NanogenreId: 564,
      x: 3090,
      y: 10462
    },{
      NanogenreId: 565,
      x: 11028,
      y: 9962
    },{
      NanogenreId: 566,
      x: 10860,
      y: 10995
    },{
      NanogenreId: 567,
      x: 4985,
      y: 5148
    },{
      NanogenreId: 568,
      x: 13257,
      y: 9379
    },{
      NanogenreId: 569,
      x: 7077,
      y: 12584
    },{
      NanogenreId: 570,
      x: 6422,
      y: 7848
    },{
      NanogenreId: 571,
      x: 10929,
      y: 10489
    },{
      NanogenreId: 572,
      x: 14030,
      y: 8212
    },{
      NanogenreId: 573,
      x: 8793,
      y: 8281
    },{
      NanogenreId: 574,
      x: 11162,
      y: 10903
    },{
      NanogenreId: 575,
      x: 7134,
      y: 11804
    },{
      NanogenreId: 576,
      x: 5046,
      y: 11826
    },{
      NanogenreId: 577,
      x: 8075,
      y: 9562
    },{
      NanogenreId: 578,
      x: 9297,
      y: 4714
    },{
      NanogenreId: 579,
      x: 5168,
      y: 2459
    },{
      NanogenreId: 580,
      x: 7048,
      y: 12048
    },{
      NanogenreId: 581,
      x: 9978,
      y: 10310
    },{
      NanogenreId: 582,
      x: 11236,
      y: 6718
    },{
      NanogenreId: 583,
      x: 9119,
      y: 4578
    },{
      NanogenreId: 584,
      x: 4626,
      y: 6739
    },{
      NanogenreId: 585,
      x: 7800,
      y: 3042
    },{
      NanogenreId: 586,
      x: 8507,
      y: 4366
    },{
      NanogenreId: 587,
      x: 10012,
      y: 4422
    },{
      NanogenreId: 588,
      x: 10443,
      y: 11934
    },{
      NanogenreId: 589,
      x: 3522,
      y: 8004
    },{
      NanogenreId: 590,
      x: 3872,
      y: 11288
    },{
      NanogenreId: 591,
      x: 8765,
      y: 9828
    },{
      NanogenreId: 592,
      x: 10608,
      y: 11483
    },{
      NanogenreId: 593,
      x: 9734,
      y: 4812
    },{
      NanogenreId: 594,
      x: 10975,
      y: 9535
    },{
      NanogenreId: 595,
      x: 9492,
      y: 5194
    },{
      NanogenreId: 596,
      x: 10879,
      y: 6826
    },{
      NanogenreId: 597,
      x: 5906,
      y: 5987
    },{
      NanogenreId: 598,
      x: 5706,
      y: 4742
    },{
      NanogenreId: 599,
      x: 9433,
      y: 12224
    },{
      NanogenreId: 600,
      x: 7137,
      y: 9501
    },{
      NanogenreId: 601,
      x: 6673,
      y: 8249
    },{
      NanogenreId: 602,
      x: 6498,
      y: 8548
    },{
      NanogenreId: 603,
      x: 11245,
      y: 11375
    },{
      NanogenreId: 604,
      x: 9323,
      y: 12772
    },{
      NanogenreId: 605,
      x: 9521,
      y: 4365
    },{
      NanogenreId: 606,
      x: 6454,
      y: 5651
    },{
      NanogenreId: 607,
      x: 3124,
      y: 8449
    },{
      NanogenreId: 608,
      x: 9147,
      y: 10957
    },{
      NanogenreId: 609,
      x: 10252,
      y: 12436
    },{
      NanogenreId: 610,
      x: 10127,
      y: 3946
    },{
      NanogenreId: 611,
      x: 10852,
      y: 1654
    },{
      NanogenreId: 612,
      x: 10504,
      y: 6418
    },{
      NanogenreId: 613,
      x: 7149,
      y: 10568
    },{
      NanogenreId: 614,
      x: 13319,
      y: 7563
    },{
      NanogenreId: 615,
      x: 5959,
      y: 12004
    },{
      NanogenreId: 616,
      x: 10405,
      y: 11006
    },{
      NanogenreId: 617,
      x: 8716,
      y: 4197
    },{
      NanogenreId: 618,
      x: 10256,
      y: 4434
    },{
      NanogenreId: 619,
      x: 6394,
      y: 9695
    },{
      NanogenreId: 620,
      x: 4554,
      y: 12480
    },{
      NanogenreId: 621,
      x: 5881,
      y: 11089
    },{
      NanogenreId: 622,
      x: 9120,
      y: 3965
    },{
      NanogenreId: 623,
      x: 8514,
      y: 12574
    },{
      NanogenreId: 624,
      x: 7965,
      y: 4241
    },{
      NanogenreId: 625,
      x: 10798,
      y: 10179
    },{
      NanogenreId: 626,
      x: 4819,
      y: 3250
    },{
      NanogenreId: 627,
      x: 6663,
      y: 13450
    },{
      NanogenreId: 628,
      x: 8495,
      y: 14304
    },{
      NanogenreId: 629,
      x: 5562,
      y: 5343
    },{
      NanogenreId: 630,
      x: 12998,
      y: 2783
    },{
      NanogenreId: 631,
      x: 4488,
      y: 7948
    },{
      NanogenreId: 632,
      x: 9728,
      y: 4639
    },{
      NanogenreId: 633,
      x: 3939,
      y: 5403
    },{
      NanogenreId: 634,
      x: 8358,
      y: 3449
    },{
      NanogenreId: 635,
      x: 13373,
      y: 4580
    },{
      NanogenreId: 636,
      x: 8215,
      y: 4780
    },{
      NanogenreId: 637,
      x: 4954,
      y: 8694
    },{
      NanogenreId: 638,
      x: 5188,
      y: 6577
    },{
      NanogenreId: 639,
      x: 12368,
      y: 4524
    },{
      NanogenreId: 640,
      x: 8259,
      y: 10142
    },{
      NanogenreId: 641,
      x: 5618,
      y: 7348
    },{
      NanogenreId: 642,
      x: 8441,
      y: 5156
    },{
      NanogenreId: 643,
      x: 7046,
      y: 8377
    },{
      NanogenreId: 644,
      x: 8664,
      y: 6148
    },{
      NanogenreId: 645,
      x: 10583,
      y: 5426
    },{
      NanogenreId: 646,
      x: 10509,
      y: 4441
    },{
      NanogenreId: 647,
      x: 11457,
      y: 6425
    },{
      NanogenreId: 648,
      x: 7680,
      y: 10464
    },{
      NanogenreId: 649,
      x: 10929,
      y: 11073
    },{
      NanogenreId: 650,
      x: 7631,
      y: 10859
    },{
      NanogenreId: 651,
      x: 8651,
      y: 5317
    },{
      NanogenreId: 652,
      x: 6937,
      y: 11498
    },{
      NanogenreId: 653,
      x: 6750,
      y: 4180
    },{
      NanogenreId: 654,
      x: 10865,
      y: 9542
    },{
      NanogenreId: 655,
      x: 10796,
      y: 6375
    },{
      NanogenreId: 656,
      x: 7533,
      y: 9692
    },{
      NanogenreId: 657,
      x: 10371,
      y: 4870
    },{
      NanogenreId: 658,
      x: 11753,
      y: 11188
    },{
      NanogenreId: 659,
      x: 11368,
      y: 10479
    },{
      NanogenreId: 660,
      x: 10066,
      y: 4504
    },{
      NanogenreId: 661,
      x: 11131,
      y: 10881
    },{
      NanogenreId: 662,
      x: 10938,
      y: 11385
    },{
      NanogenreId: 663,
      x: 8171,
      y: 4685
    },{
      NanogenreId: 664,
      x: 9601,
      y: 11337
    },{
      NanogenreId: 665,
      x: 9970,
      y: 4344
    },{
      NanogenreId: 666,
      x: 9570,
      y: 9802
    },{
      NanogenreId: 667,
      x: 9985,
      y: 4310
    },{
      NanogenreId: 668,
      x: 5214,
      y: 11472
    },{
      NanogenreId: 669,
      x: 10613,
      y: 10991
    },{
      NanogenreId: 670,
      x: 9607,
      y: 9220
    },{
      NanogenreId: 671,
      x: 5477,
      y: 5833
    },{
      NanogenreId: 672,
      x: 6097,
      y: 4950
    },{
      NanogenreId: 673,
      x: 7836,
      y: 4424
    },{
      NanogenreId: 674,
      x: 6353,
      y: 5727
    },{
      NanogenreId: 675,
      x: 5022,
      y: 6050
    },{
      NanogenreId: 676,
      x: 6391,
      y: 3314
    },{
      NanogenreId: 677,
      x: 5894,
      y: 4025
    },{
      NanogenreId: 678,
      x: 7737,
      y: 11785
    },{
      NanogenreId: 679,
      x: 10490,
      y: 9071
    },{
      NanogenreId: 680,
      x: 8096,
      y: 5125
    },{
      NanogenreId: 681,
      x: 3813,
      y: 5251
    },{
      NanogenreId: 682,
      x: 12150,
      y: 7632
    },{
      NanogenreId: 683,
      x: 6949,
      y: 8485
    },{
      NanogenreId: 684,
      x: 8598,
      y: 3184
    },{
      NanogenreId: 685,
      x: 10236,
      y: 4621
    },{
      NanogenreId: 686,
      x: 11541,
      y: 10810
    },{
      NanogenreId: 687,
      x: 10578,
      y: 4471
    },{
      NanogenreId: 688,
      x: 3914,
      y: 4688
    },{
      NanogenreId: 689,
      x: 5912,
      y: 3552
    },{
      NanogenreId: 690,
      x: 12519,
      y: 11652
    },{
      NanogenreId: 691,
      x: 12151,
      y: 7238
    },{
      NanogenreId: 692,
      x: 5607,
      y: 6092
    },{
      NanogenreId: 693,
      x: 7108,
      y: 10992
    },{
      NanogenreId: 694,
      x: 10715,
      y: 10976
    },{
      NanogenreId: 695,
      x: 12160,
      y: 1947
    },{
      NanogenreId: 696,
      x: 6925,
      y: 11863
    },{
      NanogenreId: 697,
      x: 5507,
      y: 10937
    },{
      NanogenreId: 698,
      x: 12793,
      y: 7132
    },{
      NanogenreId: 699,
      x: 9250,
      y: 4502
    },{
      NanogenreId: 700,
      x: 10834,
      y: 10749
    },{
      NanogenreId: 701,
      x: 13108,
      y: 3498
    },{
      NanogenreId: 702,
      x: 10129,
      y: 4218
    },{
      NanogenreId: 703,
      x: 3717,
      y: 6383
    },{
      NanogenreId: 704,
      x: 10445,
      y: 6322
    },{
      NanogenreId: 705,
      x: 9920,
      y: 4669
    },{
      NanogenreId: 706,
      x: 7063,
      y: 11315
    },{
      NanogenreId: 707,
      x: 11407,
      y: 11561
    },{
      NanogenreId: 708,
      x: 9214,
      y: 4424
    },{
      NanogenreId: 709,
      x: 6944,
      y: 11972
    },{
      NanogenreId: 710,
      x: 2968,
      y: 10845
    },{
      NanogenreId: 711,
      x: 4919,
      y: 5329
    },{
      NanogenreId: 712,
      x: 6545,
      y: 11548
    },{
      NanogenreId: 713,
      x: 10211,
      y: 10550
    },{
      NanogenreId: 714,
      x: 6536,
      y: 10694
    },{
      NanogenreId: 715,
      x: 8827,
      y: 4831
    },{
      NanogenreId: 716,
      x: 10895,
      y: 5071
    },{
      NanogenreId: 717,
      x: 9878,
      y: 4469
    },{
      NanogenreId: 718,
      x: 9087,
      y: 4195
    },{
      NanogenreId: 719,
      x: 6884,
      y: 7779
    },{
      NanogenreId: 720,
      x: 4466,
      y: 4167
    },{
      NanogenreId: 721,
      x: 12598,
      y: 7541
    },{
      NanogenreId: 722,
      x: 9395,
      y: 11449
    },{
      NanogenreId: 723,
      x: 8646,
      y: 8182
    },{
      NanogenreId: 724,
      x: 3718,
      y: 6705
    },{
      NanogenreId: 725,
      x: 9697,
      y: 8547
    },{
      NanogenreId: 726,
      x: 9905,
      y: 4387
    },{
      NanogenreId: 727,
      x: 7539,
      y: 3552
    },{
      NanogenreId: 728,
      x: 6567,
      y: 12885
    },{
      NanogenreId: 729,
      x: 5377,
      y: 4688
    },{
      NanogenreId: 730,
      x: 5630,
      y: 5403
    },{
      NanogenreId: 731,
      x: 4397,
      y: 6711
    },{
      NanogenreId: 732,
      x: 10779,
      y: 11121
    },{
      NanogenreId: 733,
      x: 11770,
      y: 6197
    },{
      NanogenreId: 734,
      x: 10659,
      y: 6484
    },{
      NanogenreId: 735,
      x: 10008,
      y: 4469
    },{
      NanogenreId: 736,
      x: 9597,
      y: 5854
    },{
      NanogenreId: 737,
      x: 4109,
      y: 9474
    },{
      NanogenreId: 738,
      x: 7890,
      y: 8330
    },{
      NanogenreId: 739,
      x: 8857,
      y: 12276
    },{
      NanogenreId: 740,
      x: 7280,
      y: 4244
    },{
      NanogenreId: 741,
      x: 7440,
      y: 12084
    },{
      NanogenreId: 742,
      x: 11674,
      y: 10105
    },{
      NanogenreId: 743,
      x: 6046,
      y: 6854
    },{
      NanogenreId: 744,
      x: 9425,
      y: 4716
    },{
      NanogenreId: 745,
      x: 8179,
      y: 3612
    },{
      NanogenreId: 746,
      x: 10788,
      y: 7997
    },{
      NanogenreId: 747,
      x: 11050,
      y: 9842
    },{
      NanogenreId: 748,
      x: 10973,
      y: 4238
    },{
      NanogenreId: 749,
      x: 9966,
      y: 4549
    },{
      NanogenreId: 750,
      x: 7290,
      y: 2684
    },{
      NanogenreId: 751,
      x: 3030,
      y: 9782
    },{
      NanogenreId: 752,
      x: 3433,
      y: 8776
    },{
      NanogenreId: 753,
      x: 11296,
      y: 9408
    },{
      NanogenreId: 754,
      x: 2702,
      y: 8811
    },{
      NanogenreId: 755,
      x: 7126,
      y: 4206
    },{
      NanogenreId: 756,
      x: 11294,
      y: 11029
    },{
      NanogenreId: 757,
      x: 7817,
      y: 3527
    },{
      NanogenreId: 758,
      x: 9869,
      y: 4339
    },{
      NanogenreId: 759,
      x: 9666,
      y: 4638
    },{
      NanogenreId: 760,
      x: 8325,
      y: 4105
    },{
      NanogenreId: 761,
      x: 13826,
      y: 9049
    },{
      NanogenreId: 762,
      x: 11148,
      y: 10572
    },{
      NanogenreId: 763,
      x: 2779,
      y: 9861
    },{
      NanogenreId: 764,
      x: 6003,
      y: 9011
    },{
      NanogenreId: 765,
      x: 4343,
      y: 11291
    },{
      NanogenreId: 766,
      x: 13600,
      y: 5922
    },{
      NanogenreId: 767,
      x: 10947,
      y: 10812
    },{
      NanogenreId: 768,
      x: 9399,
      y: 12032
    },{
      NanogenreId: 769,
      x: 6450,
      y: 10504
    },{
      NanogenreId: 770,
      x: 13834,
      y: 8709
    },{
      NanogenreId: 771,
      x: 7475,
      y: 10644
    },{
      NanogenreId: 772,
      x: 6099,
      y: 12732
    },{
      NanogenreId: 773,
      x: 9655,
      y: 4344
    },{
      NanogenreId: 774,
      x: 8330,
      y: 4491
    },{
      NanogenreId: 775,
      x: 9420,
      y: 4659
    },{
      NanogenreId: 776,
      x: 4143,
      y: 7802
    },{
      NanogenreId: 777,
      x: 8763,
      y: 4790
    },{
      NanogenreId: 778,
      x: 6194,
      y: 7118
    },{
      NanogenreId: 779,
      x: 6981,
      y: 11772
    },{
      NanogenreId: 780,
      x: 5080,
      y: 7478
    },{
      NanogenreId: 781,
      x: 6202,
      y: 10223
    },{
      NanogenreId: 782,
      x: 8701,
      y: 12357
    },{
      NanogenreId: 783,
      x: 9027,
      y: 5076
    },{
      NanogenreId: 784,
      x: 9340,
      y: 10035
    },{
      NanogenreId: 785,
      x: 10223,
      y: 4723
    },{
      NanogenreId: 786,
      x: 5412,
      y: 5283
    },{
      NanogenreId: 787,
      x: 9288,
      y: 4356
    },{
      NanogenreId: 788,
      x: 9522,
      y: 4808
    },{
      NanogenreId: 789,
      x: 5125,
      y: 9232
    },{
      NanogenreId: 790,
      x: 9591,
      y: 4590
    },{
      NanogenreId: 791,
      x: 6751,
      y: 5052
    },{
      NanogenreId: 792,
      x: 10938,
      y: 10711
    },{
      NanogenreId: 793,
      x: 10019,
      y: 4895
    },{
      NanogenreId: 794,
      x: 5225,
      y: 4890
    },{
      NanogenreId: 795,
      x: 10946,
      y: 6489
    },{
      NanogenreId: 796,
      x: 8815,
      y: 6573
    },{
      NanogenreId: 797,
      x: 11251,
      y: 7361
    },{
      NanogenreId: 798,
      x: 9766,
      y: 4424
    },{
      NanogenreId: 799,
      x: 4273,
      y: 11491
    },{
      NanogenreId: 800,
      x: 10968,
      y: 8228
    },{
      NanogenreId: 801,
      x: 11267,
      y: 6997
    },{
      NanogenreId: 802,
      x: 7782,
      y: 10128
    },{
      NanogenreId: 803,
      x: 4353,
      y: 6600
    },{
      NanogenreId: 804,
      x: 5587,
      y: 8889
    },{
      NanogenreId: 805,
      x: 10438,
      y: 11240
    },{
      NanogenreId: 806,
      x: 5079,
      y: 5019
    },{
      NanogenreId: 807,
      x: 7277,
      y: 4003
    },{
      NanogenreId: 808,
      x: 2601,
      y: 10022
    },{
      NanogenreId: 809,
      x: 10072,
      y: 4364
    },{
      NanogenreId: 810,
      x: 7695,
      y: 3940
    },{
      NanogenreId: 811,
      x: 9826,
      y: 9414
    },{
      NanogenreId: 812,
      x: 6400,
      y: 8656
    },{
      NanogenreId: 813,
      x: 8963,
      y: 4237
    },{
      NanogenreId: 814,
      x: 11961,
      y: 7220
    },{
      NanogenreId: 815,
      x: 11274,
      y: 10177
    },{
      NanogenreId: 816,
      x: 10685,
      y: 6622
    },{
      NanogenreId: 817,
      x: 13162,
      y: 3711
    },{
      NanogenreId: 818,
      x: 5924,
      y: 5674
    },{
      NanogenreId: 819,
      x: 10080,
      y: 5167
    },{
      NanogenreId: 820,
      x: 9484,
      y: 11654
    },{
      NanogenreId: 821,
      x: 8931,
      y: 6123
    },{
      NanogenreId: 822,
      x: 6861,
      y: 9319
    },{
      NanogenreId: 823,
      x: 12230,
      y: 8149
    },{
      NanogenreId: 824,
      x: 4957,
      y: 11455
    },{
      NanogenreId: 825,
      x: 10235,
      y: 5041
    },{
      NanogenreId: 826,
      x: 6118,
      y: 7710
    },{
      NanogenreId: 827,
      x: 5479,
      y: 7599
    },{
      NanogenreId: 828,
      x: 11885,
      y: 11030
    },{
      NanogenreId: 829,
      x: 4544,
      y: 5791
    },{
      NanogenreId: 830,
      x: 5813,
      y: 4765
    },{
      NanogenreId: 831,
      x: 8915,
      y: 8011
    },{
      NanogenreId: 832,
      x: 5048,
      y: 4760
    },{
      NanogenreId: 833,
      x: 11023,
      y: 8185
    },{
      NanogenreId: 834,
      x: 4117,
      y: 7961
    },{
      NanogenreId: 835,
      x: 9671,
      y: 4501
    },{
      NanogenreId: 836,
      x: 5247,
      y: 5980
    },{
      NanogenreId: 837,
      x: 3726,
      y: 6520
    },{
      NanogenreId: 838,
      x: 13617,
      y: 11019
    },{
      NanogenreId: 839,
      x: 3133,
      y: 10423
    },{
      NanogenreId: 840,
      x: 9746,
      y: 4156
    },{
      NanogenreId: 841,
      x: 9635,
      y: 4527
    },{
      NanogenreId: 842,
      x: 9041,
      y: 4062
    },{
      NanogenreId: 843,
      x: 9968,
      y: 9438
    },{
      NanogenreId: 844,
      x: 5106,
      y: 5488
    },{
      NanogenreId: 845,
      x: 6185,
      y: 10992
    },{
      NanogenreId: 846,
      x: 7585,
      y: 11074
    },{
      NanogenreId: 847,
      x: 11535,
      y: 5594
    },{
      NanogenreId: 848,
      x: 10726,
      y: 10041
    },{
      NanogenreId: 849,
      x: 8040,
      y: 13033
    },{
      NanogenreId: 850,
      x: 11322,
      y: 10465
    },{
      NanogenreId: 851,
      x: 9861,
      y: 9752
    },{
      NanogenreId: 852,
      x: 8955,
      y: 3386
    },{
      NanogenreId: 853,
      x: 10490,
      y: 5776
    },{
      NanogenreId: 854,
      x: 10545,
      y: 10439
    },{
      NanogenreId: 855,
      x: 10545,
      y: 5344
    },{
      NanogenreId: 856,
      x: 5290,
      y: 12422
    },{
      NanogenreId: 857,
      x: 7369,
      y: 4043
    },{
      NanogenreId: 858,
      x: 9471,
      y: 3682
    },{
      NanogenreId: 859,
      x: 11165,
      y: 10042
    },{
      NanogenreId: 860,
      x: 9357,
      y: 4897
    },{
      NanogenreId: 861,
      x: 10003,
      y: 3131
    },{
      NanogenreId: 862,
      x: 6468,
      y: 9164
    },{
      NanogenreId: 863,
      x: 3485,
      y: 3526
    },{
      NanogenreId: 864,
      x: 3885,
      y: 8440
    },{
      NanogenreId: 865,
      x: 11247,
      y: 10857
    },{
      NanogenreId: 866,
      x: 5369,
      y: 7179
    },{
      NanogenreId: 867,
      x: 12643,
      y: 6733
    },{
      NanogenreId: 868,
      x: 4227,
      y: 5024
    },{
      NanogenreId: 869,
      x: 9073,
      y: 3997
    },{
      NanogenreId: 870,
      x: 5479,
      y: 10200
    },{
      NanogenreId: 871,
      x: 9379,
      y: 5620
    },{
      NanogenreId: 872,
      x: 5290,
      y: 8054
    },{
      NanogenreId: 873,
      x: 5606,
      y: 12279
    },{
      NanogenreId: 874,
      x: 9595,
      y: 4705
    },{
      NanogenreId: 875,
      x: 6405,
      y: 4718
    },{
      NanogenreId: 876,
      x: 5011,
      y: 5320
    },{
      NanogenreId: 877,
      x: 9726,
      y: 4443
    },{
      NanogenreId: 878,
      x: 10139,
      y: 4400
    },{
      NanogenreId: 879,
      x: 13432,
      y: 5873
    },{
      NanogenreId: 880,
      x: 10471,
      y: 9806
    },{
      NanogenreId: 881,
      x: 10071,
      y: 10959
    },{
      NanogenreId: 882,
      x: 11131,
      y: 10088
    },{
      NanogenreId: 883,
      x: 5646,
      y: 6635
    },{
      NanogenreId: 884,
      x: 5778,
      y: 6452
    },{
      NanogenreId: 885,
      x: 10542,
      y: 10264
    },{
      NanogenreId: 886,
      x: 9005,
      y: 4703
    },{
      NanogenreId: 887,
      x: 9972,
      y: 7638
    },{
      NanogenreId: 888,
      x: 8399,
      y: 4877
    },{
      NanogenreId: 889,
      x: 7787,
      y: 7635
    },{
      NanogenreId: 890,
      x: 4450,
      y: 5834
    },{
      NanogenreId: 891,
      x: 6966,
      y: 12327
    },{
      NanogenreId: 892,
      x: 8278,
      y: 13444
    },{
      NanogenreId: 893,
      x: 7607,
      y: 11686
    },{
      NanogenreId: 894,
      x: 9291,
      y: 7738
    },{
      NanogenreId: 895,
      x: 8357,
      y: 4577
    },{
      NanogenreId: 896,
      x: 9011,
      y: 10409
    },{
      NanogenreId: 897,
      x: 11374,
      y: 10350
    },{
      NanogenreId: 898,
      x: 8567,
      y: 4970
    },{
      NanogenreId: 899,
      x: 13657,
      y: 9460
    },{
      NanogenreId: 900,
      x: 6442,
      y: 3577
    },{
      NanogenreId: 901,
      x: 11307,
      y: 9200
    },{
      NanogenreId: 902,
      x: 6055,
      y: 5585
    },{
      NanogenreId: 903,
      x: 7021,
      y: 9271
    },{
      NanogenreId: 904,
      x: 9893,
      y: 4298
    },{
      NanogenreId: 905,
      x: 10012,
      y: 4678
    },{
      NanogenreId: 906,
      x: 7759,
      y: 10793
    },{
      NanogenreId: 907,
      x: 11980,
      y: 10799
    },{
      NanogenreId: 908,
      x: 10569,
      y: 1575
    },{
      NanogenreId: 909,
      x: 3899,
      y: 9363
    },{
      NanogenreId: 910,
      x: 12274,
      y: 4689
    },{
      NanogenreId: 911,
      x: 9984,
      y: 4521
    },{
      NanogenreId: 912,
      x: 8135,
      y: 4808
    },{
      NanogenreId: 913,
      x: 9683,
      y: 4592
    },{
      NanogenreId: 914,
      x: 6126,
      y: 5683
    },{
      NanogenreId: 915,
      x: 10457,
      y: 4952
    },{
      NanogenreId: 916,
      x: 11333,
      y: 10839
    },{
      NanogenreId: 917,
      x: 6216,
      y: 4263
    },{
      NanogenreId: 918,
      x: 9703,
      y: 4483
    },{
      NanogenreId: 919,
      x: 11736,
      y: 10719
    },{
      NanogenreId: 920,
      x: 5287,
      y: 4957
    },{
      NanogenreId: 921,
      x: 8507,
      y: 6361
    },{
      NanogenreId: 922,
      x: 10066,
      y: 4401
    },{
      NanogenreId: 923,
      x: 3089,
      y: 5075
    },{
      NanogenreId: 924,
      x: 9617,
      y: 4336
    },{
      NanogenreId: 925,
      x: 7498,
      y: 6834
    },{
      NanogenreId: 926,
      x: 11029,
      y: 11694
    },{
      NanogenreId: 927,
      x: 9821,
      y: 4514
    },{
      NanogenreId: 928,
      x: 9357,
      y: 4561
    },{
      NanogenreId: 929,
      x: 11737,
      y: 10555
    },{
      NanogenreId: 930,
      x: 8941,
      y: 10136
    },{
      NanogenreId: 931,
      x: 9722,
      y: 9041
    },{
      NanogenreId: 932,
      x: 3326,
      y: 11961
    },{
      NanogenreId: 933,
      x: 8221,
      y: 8259
    },{
      NanogenreId: 934,
      x: 9505,
      y: 12626
    },{
      NanogenreId: 935,
      x: 7114,
      y: 5892
    },{
      NanogenreId: 936,
      x: 4778,
      y: 8329
    },{
      NanogenreId: 937,
      x: 11150,
      y: 10719
    },{
      NanogenreId: 938,
      x: 8613,
      y: 4570
    },{
      NanogenreId: 939,
      x: 7505,
      y: 11121
    },{
      NanogenreId: 940,
      x: 12699,
      y: 7910
    },{
      NanogenreId: 941,
      x: 8900,
      y: 4601
    },{
      NanogenreId: 942,
      x: 10934,
      y: 10194
    },{
      NanogenreId: 943,
      x: 5689,
      y: 5666
    },{
      NanogenreId: 944,
      x: 9602,
      y: 4156
    },{
      NanogenreId: 945,
      x: 6248,
      y: 5826
    },{
      NanogenreId: 946,
      x: 3344,
      y: 9828
    },{
      NanogenreId: 947,
      x: 3315,
      y: 10264
    },{
      NanogenreId: 948,
      x: 9747,
      y: 4552
    },{
      NanogenreId: 949,
      x: 6265,
      y: 9627
    },{
      NanogenreId: 950,
      x: 9711,
      y: 4309
    },{
      NanogenreId: 951,
      x: 7398,
      y: 5358
    },{
      NanogenreId: 952,
      x: 12006,
      y: 6051
    },{
      NanogenreId: 953,
      x: 5894,
      y: 7432
    },{
      NanogenreId: 954,
      x: 3894,
      y: 7208
    },{
      NanogenreId: 955,
      x: 8809,
      y: 5032
    },{
      NanogenreId: 956,
      x: 9818,
      y: 3843
    },{
      NanogenreId: 957,
      x: 10969,
      y: 10318
    },{
      NanogenreId: 958,
      x: 6489,
      y: 7294
    },{
      NanogenreId: 959,
      x: 8951,
      y: 11284
    },{
      NanogenreId: 960,
      x: 7853,
      y: 6322
    },{
      NanogenreId: 961,
      x: 7934,
      y: 4836
    },{
      NanogenreId: 962,
      x: 5613,
      y: 5514
    },{
      NanogenreId: 963,
      x: 9845,
      y: 5793
    },{
      NanogenreId: 964,
      x: 5885,
      y: 6352
    },{
      NanogenreId: 965,
      x: 6113,
      y: 4785
    },{
      NanogenreId: 966,
      x: 4856,
      y: 8298
    },{
      NanogenreId: 967,
      x: 4074,
      y: 11402
    },{
      NanogenreId: 968,
      x: 11506,
      y: 10160
    },{
      NanogenreId: 969,
      x: 10657,
      y: 6931
    },{
      NanogenreId: 970,
      x: 10771,
      y: 8746
    },{
      NanogenreId: 971,
      x: 10282,
      y: 10296
    },{
      NanogenreId: 972,
      x: 6517,
      y: 8702
    },{
      NanogenreId: 973,
      x: 9877,
      y: 4715
    },{
      NanogenreId: 974,
      x: 11087,
      y: 10635
    },{
      NanogenreId: 975,
      x: 10870,
      y: 10458
    },{
      NanogenreId: 976,
      x: 11498,
      y: 10613
    },{
      NanogenreId: 977,
      x: 11055,
      y: 9689
    },{
      NanogenreId: 978,
      x: 8290,
      y: 8494
    },{
      NanogenreId: 979,
      x: 8582,
      y: 13024
    },{
      NanogenreId: 980,
      x: 10044,
      y: 4765
    },{
      NanogenreId: 981,
      x: 9809,
      y: 9310
    },{
      NanogenreId: 982,
      x: 6009,
      y: 8757
    },{
      NanogenreId: 983,
      x: 8850,
      y: 4633
    },{
      NanogenreId: 984,
      x: 6395,
      y: 4474
    },{
      NanogenreId: 985,
      x: 11702,
      y: 7848
    },{
      NanogenreId: 986,
      x: 7965,
      y: 12441
    },{
      NanogenreId: 987,
      x: 11108,
      y: 6820
    },{
      NanogenreId: 988,
      x: 11973,
      y: 2551
    },{
      NanogenreId: 989,
      x: 6375,
      y: 5255
    },{
      NanogenreId: 990,
      x: 6210,
      y: 8933
    },{
      NanogenreId: 991,
      x: 10343,
      y: 8358
    },{
      NanogenreId: 992,
      x: 8140,
      y: 8820
    },{
      NanogenreId: 993,
      x: 12988,
      y: 6089
    },{
      NanogenreId: 994,
      x: 10266,
      y: 10814
    },{
      NanogenreId: 995,
      x: 9580,
      y: 10253
    },{
      NanogenreId: 996,
      x: 3554,
      y: 9284
    },{
      NanogenreId: 997,
      x: 8815,
      y: 4520
    },{
      NanogenreId: 998,
      x: 6670,
      y: 8391
    },{
      NanogenreId: 999,
      x: 3309,
      y: 6770
    },{
      NanogenreId: 1000,
      x: 11173,
      y: 10329
    },{
      NanogenreId: 1001,
      x: 3041,
      y: 6905
    },{
      NanogenreId: 1002,
      x: 9339,
      y: 10705
    },{
      NanogenreId: 1003,
      x: 7102,
      y: 9412
    },{
      NanogenreId: 1004,
      x: 5517,
      y: 10607
    },{
      NanogenreId: 1005,
      x: 6815,
      y: 11803
    },{
      NanogenreId: 1006,
      x: 12161,
      y: 2299
    },{
      NanogenreId: 1007,
      x: 7000,
      y: 6766
    },{
      NanogenreId: 1008,
      x: 4125,
      y: 8380
    },{
      NanogenreId: 1009,
      x: 10079,
      y: 4517
    },{
      NanogenreId: 1010,
      x: 4833,
      y: 6580
    },{
      NanogenreId: 1011,
      x: 5477,
      y: 11154
    },{
      NanogenreId: 1012,
      x: 10730,
      y: 9189
    },{
      NanogenreId: 1013,
      x: 9411,
      y: 5360
    },{
      NanogenreId: 1014,
      x: 5500,
      y: 7945
    },{
      NanogenreId: 1015,
      x: 6302,
      y: 7889
    },{
      NanogenreId: 1016,
      x: 11114,
      y: 11049
    },{
      NanogenreId: 1017,
      x: 5167,
      y: 6377
    },{
      NanogenreId: 1018,
      x: 5705,
      y: 11769
    },{
      NanogenreId: 1019,
      x: 10773,
      y: 11241
    },{
      NanogenreId: 1020,
      x: 10389,
      y: 3294
    },{
      NanogenreId: 1021,
      x: 5495,
      y: 5373
    },{
      NanogenreId: 1022,
      x: 11225,
      y: 10732
    },{
      NanogenreId: 1023,
      x: 6497,
      y: 10841
    },{
      NanogenreId: 1024,
      x: 5350,
      y: 8637
    },{
      NanogenreId: 1025,
      x: 9365,
      y: 8171
    },{
      NanogenreId: 1026,
      x: 5198,
      y: 7271
    },{
      NanogenreId: 1027,
      x: 5378,
      y: 7826
    },{
      NanogenreId: 1028,
      x: 10475,
      y: 11366
    },{
      NanogenreId: 1029,
      x: 8906,
      y: 7920
    },{
      NanogenreId: 1030,
      x: 8373,
      y: 3647
    },{
      NanogenreId: 1031,
      x: 11475,
      y: 10424
    },{
      NanogenreId: 1032,
      x: 9023,
      y: 13208
    },{
      NanogenreId: 1033,
      x: 10217,
      y: 5660
    },{
      NanogenreId: 1034,
      x: 3045,
      y: 8705
    },{
      NanogenreId: 1035,
      x: 9691,
      y: 10086
    },{
      NanogenreId: 1036,
      x: 8857,
      y: 4464
    },{
      NanogenreId: 1037,
      x: 4773,
      y: 4860
    },{
      NanogenreId: 1038,
      x: 8161,
      y: 4516
    },{
      NanogenreId: 1039,
      x: 8078,
      y: 12329
    },{
      NanogenreId: 1040,
      x: 7746,
      y: 10463
    },{
      NanogenreId: 1041,
      x: 8302,
      y: 8055
    },{
      NanogenreId: 1042,
      x: 3414,
      y: 8074
    },{
      NanogenreId: 1043,
      x: 8993,
      y: 7186
    },{
      NanogenreId: 1044,
      x: 9387,
      y: 5550
    },{
      NanogenreId: 1045,
      x: 7469,
      y: 11221
    },{
      NanogenreId: 1046,
      x: 9125,
      y: 5116
    },{
      NanogenreId: 1047,
      x: 8951,
      y: 4719
    },{
      NanogenreId: 1048,
      x: 9885,
      y: 11008
    },{
      NanogenreId: 1049,
      x: 9095,
      y: 4355
    },{
      NanogenreId: 1050,
      x: 11004,
      y: 10399
    },{
      NanogenreId: 1051,
      x: 7455,
      y: 10280
    },{
      NanogenreId: 1052,
      x: 8322,
      y: 10813
    },{
      NanogenreId: 1053,
      x: 5598,
      y: 10747
    },{
      NanogenreId: 1054,
      x: 9705,
      y: 5066
    },{
      NanogenreId: 1055,
      x: 10120,
      y: 6502
    },{
      NanogenreId: 1056,
      x: 9164,
      y: 4127
    },{
      NanogenreId: 1057,
      x: 10893,
      y: 11118
    },{
      NanogenreId: 1058,
      x: 10929,
      y: 9581
    },{
      NanogenreId: 1059,
      x: 9453,
      y: 4120
    },{
      NanogenreId: 1060,
      x: 8424,
      y: 4365
    },{
      NanogenreId: 1061,
      x: 10040,
      y: 4372
    },{
      NanogenreId: 1062,
      x: 8215,
      y: 8536
    },{
      NanogenreId: 1063,
      x: 12433,
      y: 6838
    },{
      NanogenreId: 1064,
      x: 9509,
      y: 4564
    },{
      NanogenreId: 1065,
      x: 11250,
      y: 10651
    },{
      NanogenreId: 1066,
      x: 9074,
      y: 4783
    },{
      NanogenreId: 1067,
      x: 11184,
      y: 8058
    },{
      NanogenreId: 1068,
      x: 7608,
      y: 6009
    },{
      NanogenreId: 1069,
      x: 5873,
      y: 9838
    },{
      NanogenreId: 1070,
      x: 11597,
      y: 5903
    },{
      NanogenreId: 1071,
      x: 5458,
      y: 6132
    },{
      NanogenreId: 1072,
      x: 5878,
      y: 5071
    },{
      NanogenreId: 1073,
      x: 13426,
      y: 6234
    },{
      NanogenreId: 1074,
      x: 9363,
      y: 4818
    },{
      NanogenreId: 1075,
      x: 9459,
      y: 6182
    },{
      NanogenreId: 1076,
      x: 7352,
      y: 11626
    },{
      NanogenreId: 1077,
      x: 9566,
      y: 4724
    },{
      NanogenreId: 1078,
      x: 9150,
      y: 10332
    },{
      NanogenreId: 1079,
      x: 14059,
      y: 8492
    },{
      NanogenreId: 1080,
      x: 8910,
      y: 4550
    },{
      NanogenreId: 1081,
      x: 9421,
      y: 4296
    },{
      NanogenreId: 1082,
      x: 9905,
      y: 10704
    },{
      NanogenreId: 1083,
      x: 12191,
      y: 11565
    },{
      NanogenreId: 1084,
      x: 8048,
      y: 7871
    },{
      NanogenreId: 1085,
      x: 7224,
      y: 11493
    },{
      NanogenreId: 1086,
      x: 11357,
      y: 9684
    },{
      NanogenreId: 1087,
      x: 5484,
      y: 5608
    },{
      NanogenreId: 1088,
      x: 10860,
      y: 9231
    },{
      NanogenreId: 1089,
      x: 8458,
      y: 7062
    },{
      NanogenreId: 1090,
      x: 11158,
      y: 10776
    },{
      NanogenreId: 1091,
      x: 10026,
      y: 4393
    },{
      NanogenreId: 1092,
      x: 5949,
      y: 6376
    },{
      NanogenreId: 1093,
      x: 9350,
      y: 4586
    },{
      NanogenreId: 1094,
      x: 6689,
      y: 4199
    },{
      NanogenreId: 1095,
      x: 6357,
      y: 4603
    },{
      NanogenreId: 1096,
      x: 10958,
      y: 10845
    },{
      NanogenreId: 1097,
      x: 7442,
      y: 5883
    },{
      NanogenreId: 1098,
      x: 4596,
      y: 5617
    },{
      NanogenreId: 1099,
      x: 13088,
      y: 5811
    },{
      NanogenreId: 1100,
      x: 11454,
      y: 11194
    },{
      NanogenreId: 1101,
      x: 6874,
      y: 3200
    },{
      NanogenreId: 1102,
      x: 11053,
      y: 10975
    },{
      NanogenreId: 1103,
      x: 7077,
      y: 4381
    },{
      NanogenreId: 1104,
      x: 9621,
      y: 4581
    },{
      NanogenreId: 1105,
      x: 8302,
      y: 7777
    },{
      NanogenreId: 1106,
      x: 9172,
      y: 4938
    },{
      NanogenreId: 1107,
      x: 11438,
      y: 6738
    },{
      NanogenreId: 1108,
      x: 11230,
      y: 10583
    },{
      NanogenreId: 1109,
      x: 9851,
      y: 4464
    },{
      NanogenreId: 1110,
      x: 12366,
      y: 9392
    },{
      NanogenreId: 1111,
      x: 9312,
      y: 5344
    },{
      NanogenreId: 1112,
      x: 6642,
      y: 5350
    },{
      NanogenreId: 1113,
      x: 7654,
      y: 11208
    },{
      NanogenreId: 1114,
      x: 8113,
      y: 6726
    },{
      NanogenreId: 1115,
      x: 10529,
      y: 10671
    },{
      NanogenreId: 1116,
      x: 10505,
      y: 9406
    },{
      NanogenreId: 1117,
      x: 5645,
      y: 11006
    },{
      NanogenreId: 1118,
      x: 7595,
      y: 4997
    },{
      NanogenreId: 1119,
      x: 6315,
      y: 5106
    },{
      NanogenreId: 1120,
      x: 7739,
      y: 4473
    },{
      NanogenreId: 1121,
      x: 8639,
      y: 12145
    },{
      NanogenreId: 1122,
      x: 8440,
      y: 4997
    },{
      NanogenreId: 1123,
      x: 9898,
      y: 4559
    },{
      NanogenreId: 1124,
      x: 6522,
      y: 6216
    },{
      NanogenreId: 1125,
      x: 8042,
      y: 5816
    },{
      NanogenreId: 1126,
      x: 11562,
      y: 11013
    },{
      NanogenreId: 1127,
      x: 5890,
      y: 5453
    },{
      NanogenreId: 1128,
      x: 9955,
      y: 5850
    },{
      NanogenreId: 1129,
      x: 5157,
      y: 5183
    },{
      NanogenreId: 1130,
      x: 10094,
      y: 4403
    },{
      NanogenreId: 1131,
      x: 7230,
      y: 11582
    },{
      NanogenreId: 1132,
      x: 7440,
      y: 9661
    },{
      NanogenreId: 1133,
      x: 9097,
      y: 8653
    },{
      NanogenreId: 1134,
      x: 9507,
      y: 4524
    },{
      NanogenreId: 1135,
      x: 10511,
      y: 4699
    },{
      NanogenreId: 1136,
      x: 9371,
      y: 11341
    },{
      NanogenreId: 1137,
      x: 11442,
      y: 10803
    },{
      NanogenreId: 1138,
      x: 8072,
      y: 5344
    },{
      NanogenreId: 1139,
      x: 6093,
      y: 9282
    },{
      NanogenreId: 1140,
      x: 7500,
      y: 8550
    },{
      NanogenreId: 1141,
      x: 6254,
      y: 5390
    },{
      NanogenreId: 1142,
      x: 4259,
      y: 3804
    },{
      NanogenreId: 1143,
      x: 7740,
      y: 4711
    },{
      NanogenreId: 1144,
      x: 4318,
      y: 7424
    },{
      NanogenreId: 1145,
      x: 2779,
      y: 7949
    },{
      NanogenreId: 1146,
      x: 5695,
      y: 6883
    },{
      NanogenreId: 1147,
      x: 10877,
      y: 10764
    },{
      NanogenreId: 1148,
      x: 10588,
      y: 1787
    },{
      NanogenreId: 1149,
      x: 7213,
      y: 5160
    },{
      NanogenreId: 1150,
      x: 8642,
      y: 9108
    },{
      NanogenreId: 1151,
      x: 12882,
      y: 5785
    },{
      NanogenreId: 1152,
      x: 4395,
      y: 9822
    },{
      NanogenreId: 1153,
      x: 9380,
      y: 4808
    },{
      NanogenreId: 1154,
      x: 6407,
      y: 3047
    },{
      NanogenreId: 1155,
      x: 9949,
      y: 4931
    },{
      NanogenreId: 1156,
      x: 4388,
      y: 6932
    },{
      NanogenreId: 1157,
      x: 9173,
      y: 4578
    },{
      NanogenreId: 1158,
      x: 9073,
      y: 6130
    },{
      NanogenreId: 1159,
      x: 9110,
      y: 5338
    },{
      NanogenreId: 1160,
      x: 10325,
      y: 4369
    },{
      NanogenreId: 1161,
      x: 11491,
      y: 5875
    },{
      NanogenreId: 1162,
      x: 6626,
      y: 7594
    },{
      NanogenreId: 1163,
      x: 8099,
      y: 11221
    },{
      NanogenreId: 1164,
      x: 5078,
      y: 6558
    },{
      NanogenreId: 1165,
      x: 7944,
      y: 11444
    },{
      NanogenreId: 1166,
      x: 10230,
      y: 4870
    },{
      NanogenreId: 1167,
      x: 10708,
      y: 11093
    },{
      NanogenreId: 1168,
      x: 7108,
      y: 10409
    },{
      NanogenreId: 1169,
      x: 5207,
      y: 5762
    },{
      NanogenreId: 1170,
      x: 9384,
      y: 8925
    },{
      NanogenreId: 1171,
      x: 10553,
      y: 6480
    },{
      NanogenreId: 1172,
      x: 9899,
      y: 4450
    },{
      NanogenreId: 1173,
      x: 9595,
      y: 4271
    },{
      NanogenreId: 1174,
      x: 11481,
      y: 11967
    },{
      NanogenreId: 1175,
      x: 8046,
      y: 8376
    },{
      NanogenreId: 1176,
      x: 9701,
      y: 4671
    },{
      NanogenreId: 1177,
      x: 9825,
      y: 4709
    },{
      NanogenreId: 1178,
      x: 7551,
      y: 11146
    },{
      NanogenreId: 1179,
      x: 6587,
      y: 5888
    },{
      NanogenreId: 1180,
      x: 9803,
      y: 4787
    },{
      NanogenreId: 1181,
      x: 6982,
      y: 6283
    },{
      NanogenreId: 1182,
      x: 3384,
      y: 9394
    },{
      NanogenreId: 1183,
      x: 6291,
      y: 6654
    },{
      NanogenreId: 1184,
      x: 7337,
      y: 4875
    },{
      NanogenreId: 1185,
      x: 7268,
      y: 9115
    },{
      NanogenreId: 1186,
      x: 6488,
      y: 5707
    },{
      NanogenreId: 1187,
      x: 10020,
      y: 9657
    },{
      NanogenreId: 1188,
      x: 8634,
      y: 12867
    },{
      NanogenreId: 1189,
      x: 9249,
      y: 4889
    },{
      NanogenreId: 1190,
      x: 4814,
      y: 6324
    },{
      NanogenreId: 1191,
      x: 5385,
      y: 5865
    },{
      NanogenreId: 1192,
      x: 11880,
      y: 5720
    },{
      NanogenreId: 1193,
      x: 10477,
      y: 5004
    },{
      NanogenreId: 1194,
      x: 11231,
      y: 10799
    },{
      NanogenreId: 1195,
      x: 7591,
      y: 10417
    },{
      NanogenreId: 1196,
      x: 11188,
      y: 10177
    },{
      NanogenreId: 1197,
      x: 9162,
      y: 8074
    },{
      NanogenreId: 1198,
      x: 7546,
      y: 4031
    },{
      NanogenreId: 1199,
      x: 5070,
      y: 5695
    },{
      NanogenreId: 1200,
      x: 11157,
      y: 10645
    },{
      NanogenreId: 1201,
      x: 2874,
      y: 9913
    },{
      NanogenreId: 1202,
      x: 2492,
      y: 5476
    },{
      NanogenreId: 1203,
      x: 7638,
      y: 10629
    },{
      NanogenreId: 1204,
      x: 10727,
      y: 9520
    },{
      NanogenreId: 1205,
      x: 6535,
      y: 5299
    },{
      NanogenreId: 1206,
      x: 11397,
      y: 9887
    },{
      NanogenreId: 1207,
      x: 12877,
      y: 5346
    },{
      NanogenreId: 1208,
      x: 11412,
      y: 6977
    },{
      NanogenreId: 1209,
      x: 7168,
      y: 4265
    },{
      NanogenreId: 1210,
      x: 9481,
      y: 4844
    },{
      NanogenreId: 1211,
      x: 5959,
      y: 6164
    },{
      NanogenreId: 1212,
      x: 10755,
      y: 8432
    },{
      NanogenreId: 1213,
      x: 5868,
      y: 5308
    },{
      NanogenreId: 1214,
      x: 6352,
      y: 9511
    },{
      NanogenreId: 1215,
      x: 12011,
      y: 10181
    },{
      NanogenreId: 1216,
      x: 6799,
      y: 9003
    },{
      NanogenreId: 1217,
      x: 5963,
      y: 8072
    },{
      NanogenreId: 1218,
      x: 4925,
      y: 5761
    },{
      NanogenreId: 1219,
      x: 11287,
      y: 10614
    },{
      NanogenreId: 1220,
      x: 12067,
      y: 11100
    },{
      NanogenreId: 1221,
      x: 6278,
      y: 4637
    },{
      NanogenreId: 1222,
      x: 9067,
      y: 10237
    },{
      NanogenreId: 1223,
      x: 9902,
      y: 11257
    },{
      NanogenreId: 1224,
      x: 8972,
      y: 10069
    },{
      NanogenreId: 1225,
      x: 6096,
      y: 10265
    },{
      NanogenreId: 1226,
      x: 10035,
      y: 4538
    },{
      NanogenreId: 1227,
      x: 7259,
      y: 11860
    },{
      NanogenreId: 1228,
      x: 11626,
      y: 6008
    },{
      NanogenreId: 1229,
      x: 4845,
      y: 6714
    },{
      NanogenreId: 1230,
      x: 9120,
      y: 5238
    },{
      NanogenreId: 1231,
      x: 6535,
      y: 4536
    },{
      NanogenreId: 1232,
      x: 9621,
      y: 5281
    },{
      NanogenreId: 1233,
      x: 5207,
      y: 6117
    },{
      NanogenreId: 1234,
      x: 9311,
      y: 8261
    },{
      NanogenreId: 1235,
      x: 10935,
      y: 10555
    },{
      NanogenreId: 1236,
      x: 10453,
      y: 1713
    },{
      NanogenreId: 1237,
      x: 9933,
      y: 4492
    },{
      NanogenreId: 1238,
      x: 5734,
      y: 8979
    },{
      NanogenreId: 1239,
      x: 6177,
      y: 6083
    },{
      NanogenreId: 1240,
      x: 10120,
      y: 5241
    },{
      NanogenreId: 1241,
      x: 5572,
      y: 3555
    },{
      NanogenreId: 1242,
      x: 8428,
      y: 3721
    },{
      NanogenreId: 1243,
      x: 9417,
      y: 4303
    },{
      NanogenreId: 1244,
      x: 5475,
      y: 8010
    },{
      NanogenreId: 1245,
      x: 10706,
      y: 10645
    },{
      NanogenreId: 1246,
      x: 6354,
      y: 12678
    },{
      NanogenreId: 1247,
      x: 9313,
      y: 4283
    },{
      NanogenreId: 1248,
      x: 8418,
      y: 10809
    },{
      NanogenreId: 1249,
      x: 7016,
      y: 12180
    },{
      NanogenreId: 1250,
      x: 3363,
      y: 8162
    },{
      NanogenreId: 1251,
      x: 7409,
      y: 11737
    },{
      NanogenreId: 1252,
      x: 10326,
      y: 7162
    },{
      NanogenreId: 1253,
      x: 10238,
      y: 11276
    },{
      NanogenreId: 1254,
      x: 10659,
      y: 10796
    },{
      NanogenreId: 1255,
      x: 11371,
      y: 9772
    },{
      NanogenreId: 1256,
      x: 9940,
      y: 5614
    },{
      NanogenreId: 1257,
      x: 7182,
      y: 5250
    },{
      NanogenreId: 1258,
      x: 8424,
      y: 5481
    },{
      NanogenreId: 1259,
      x: 9695,
      y: 10594
    },{
      NanogenreId: 1260,
      x: 7361,
      y: 5874
    },{
      NanogenreId: 1261,
      x: 9668,
      y: 4531
    },{
      NanogenreId: 1262,
      x: 10270,
      y: 6610
    },{
      NanogenreId: 1263,
      x: 7805,
      y: 12380
    },{
      NanogenreId: 1264,
      x: 10141,
      y: 5404
    },{
      NanogenreId: 1265,
      x: 10467,
      y: 11578
    },{
      NanogenreId: 1266,
      x: 10767,
      y: 10742
    },{
      NanogenreId: 1267,
      x: 6451,
      y: 8730
    },{
      NanogenreId: 1268,
      x: 8931,
      y: 4508
    },{
      NanogenreId: 1269,
      x: 9465,
      y: 4209
    },{
      NanogenreId: 1270,
      x: 9771,
      y: 9560
    },{
      NanogenreId: 1271,
      x: 7568,
      y: 5361
    },{
      NanogenreId: 1272,
      x: 11764,
      y: 9119
    },{
      NanogenreId: 1273,
      x: 10885,
      y: 9658
    },{
      NanogenreId: 1274,
      x: 5347,
      y: 6213
    },{
      NanogenreId: 1275,
      x: 10243,
      y: 11060
    },{
      NanogenreId: 1276,
      x: 10252,
      y: 4631
    },{
      NanogenreId: 1277,
      x: 9932,
      y: 4580
    },{
      NanogenreId: 1278,
      x: 8323,
      y: 10387
    },{
      NanogenreId: 1279,
      x: 3891,
      y: 9855
    },{
      NanogenreId: 1280,
      x: 3474,
      y: 10039
    },{
      NanogenreId: 1281,
      x: 11158,
      y: 10511
    },{
      NanogenreId: 1282,
      x: 9971,
      y: 9115
    },{
      NanogenreId: 1283,
      x: 9595,
      y: 4528
    },{
      NanogenreId: 1284,
      x: 11242,
      y: 11117
    },{
      NanogenreId: 1285,
      x: 4550,
      y: 7002
    },{
      NanogenreId: 1286,
      x: 5690,
      y: 10876
    },{
      NanogenreId: 1287,
      x: 7447,
      y: 5699
    },{
      NanogenreId: 1288,
      x: 8585,
      y: 5033
    },{
      NanogenreId: 1289,
      x: 9780,
      y: 4572
    },{
      NanogenreId: 1290,
      x: 7401,
      y: 3436
    },{
      NanogenreId: 1291,
      x: 9738,
      y: 4698
    },{
      NanogenreId: 1292,
      x: 4863,
      y: 6061
    },{
      NanogenreId: 1293,
      x: 6011,
      y: 3726
    },{
      NanogenreId: 1294,
      x: 9979,
      y: 10836
    },{
      NanogenreId: 1295,
      x: 10835,
      y: 9502
    },{
      NanogenreId: 1296,
      x: 5508,
      y: 4772
    },{
      NanogenreId: 1297,
      x: 9119,
      y: 10449
    },{
      NanogenreId: 1298,
      x: 8419,
      y: 11672
    },{
      NanogenreId: 1299,
      x: 6628,
      y: 7023
    },{
      NanogenreId: 1300,
      x: 10087,
      y: 11237
    },{
      NanogenreId: 1301,
      x: 12055,
      y: 7139
    },{
      NanogenreId: 1302,
      x: 6427,
      y: 9798
    },{
      NanogenreId: 1303,
      x: 11122,
      y: 11022
    },{
      NanogenreId: 1304,
      x: 7525,
      y: 7452
    },{
      NanogenreId: 1305,
      x: 7239,
      y: 11737
    },{
      NanogenreId: 1306,
      x: 4563,
      y: 9820
    },{
      NanogenreId: 1307,
      x: 10533,
      y: 11279
    },{
      NanogenreId: 1308,
      x: 11756,
      y: 11854
    },{
      NanogenreId: 1309,
      x: 7766,
      y: 6773
    },{
      NanogenreId: 1310,
      x: 11919,
      y: 10150
    },{
      NanogenreId: 1311,
      x: 6665,
      y: 7440
    },{
      NanogenreId: 1312,
      x: 7825,
      y: 5197
    },{
      NanogenreId: 1313,
      x: 9739,
      y: 4871
    },{
      NanogenreId: 1314,
      x: 5983,
      y: 5201
    },{
      NanogenreId: 1315,
      x: 10950,
      y: 10399
    },{
      NanogenreId: 1316,
      x: 8885,
      y: 4078
    },{
      NanogenreId: 1317,
      x: 10474,
      y: 9621
    },{
      NanogenreId: 1318,
      x: 8411,
      y: 6173
    },{
      NanogenreId: 1319,
      x: 8487,
      y: 12251
    },{
      NanogenreId: 1320,
      x: 8303,
      y: 8599
    },{
      NanogenreId: 1321,
      x: 10463,
      y: 7783
    },{
      NanogenreId: 1322,
      x: 12795,
      y: 8243
    },{
      NanogenreId: 1323,
      x: 7573,
      y: 9748
    },{
      NanogenreId: 1324,
      x: 5871,
      y: 3447
    },{
      NanogenreId: 1325,
      x: 6760,
      y: 10323
    },{
      NanogenreId: 1326,
      x: 6690,
      y: 6097
    },{
      NanogenreId: 1327,
      x: 8738,
      y: 6851
    },{
      NanogenreId: 1328,
      x: 4337,
      y: 8621
    },{
      NanogenreId: 1329,
      x: 5924,
      y: 11164
    },{
      NanogenreId: 1330,
      x: 4750,
      y: 5545
    },{
      NanogenreId: 1331,
      x: 8711,
      y: 5782
    },{
      NanogenreId: 1332,
      x: 11554,
      y: 7123
    },{
      NanogenreId: 1333,
      x: 9174,
      y: 9767
    },{
      NanogenreId: 1334,
      x: 8637,
      y: 12609
    },{
      NanogenreId: 1335,
      x: 4106,
      y: 12420
    },{
      NanogenreId: 1336,
      x: 8771,
      y: 11582
    },{
      NanogenreId: 1337,
      x: 7150,
      y: 5409
    },{
      NanogenreId: 1338,
      x: 7216,
      y: 5422
    },{
      NanogenreId: 1339,
      x: 10873,
      y: 10726
    },{
      NanogenreId: 1340,
      x: 10282,
      y: 10012
    },{
      NanogenreId: 1341,
      x: 10846,
      y: 10184
    },{
      NanogenreId: 1342,
      x: 8432,
      y: 5958
    },{
      NanogenreId: 1343,
      x: 10557,
      y: 5583
    },{
      NanogenreId: 1344,
      x: 10221,
      y: 5357
    },{
      NanogenreId: 1345,
      x: 6943,
      y: 9528
    },{
      NanogenreId: 1346,
      x: 10112,
      y: 10482
    },{
      NanogenreId: 1347,
      x: 10400,
      y: 5521
    },{
      NanogenreId: 1348,
      x: 6715,
      y: 10493
    },{
      NanogenreId: 1349,
      x: 8717,
      y: 8265
    },{
      NanogenreId: 1350,
      x: 11655,
      y: 10756
    },{
      NanogenreId: 1351,
      x: 5515,
      y: 10430
    },{
      NanogenreId: 1352,
      x: 5905,
      y: 5398
    },{
      NanogenreId: 1353,
      x: 6914,
      y: 12733
    },{
      NanogenreId: 1354,
      x: 6921,
      y: 7264
    },{
      NanogenreId: 1355,
      x: 7276,
      y: 10891
    },{
      NanogenreId: 1356,
      x: 11130,
      y: 10820
    },{
      NanogenreId: 1357,
      x: 8149,
      y: 9309
    },{
      NanogenreId: 1358,
      x: 8866,
      y: 9575
    },{
      NanogenreId: 1359,
      x: 4928,
      y: 11686
    },{
      NanogenreId: 1360,
      x: 13538,
      y: 9041
    },{
      NanogenreId: 1361,
      x: 11341,
      y: 10624
    },{
      NanogenreId: 1362,
      x: 6188,
      y: 9965
    },{
      NanogenreId: 1363,
      x: 10556,
      y: 10527
    },{
      NanogenreId: 1364,
      x: 12405,
      y: 2883
    },{
      NanogenreId: 1365,
      x: 8287,
      y: 5497
    },{
      NanogenreId: 1366,
      x: 7527,
      y: 6113
    },{
      NanogenreId: 1367,
      x: 8183,
      y: 9007
    },{
      NanogenreId: 1368,
      x: 10111,
      y: 4851
    },{
      NanogenreId: 1369,
      x: 10960,
      y: 10065
    },{
      NanogenreId: 1370,
      x: 9458,
      y: 7110
    },{
      NanogenreId: 1371,
      x: 6219,
      y: 10611
    },{
      NanogenreId: 1372,
      x: 5605,
      y: 5146
    },{
      NanogenreId: 1373,
      x: 10131,
      y: 4611
    },{
      NanogenreId: 1374,
      x: 7116,
      y: 7067
    },{
      NanogenreId: 1375,
      x: 6361,
      y: 12106
    },{
      NanogenreId: 1376,
      x: 5237,
      y: 8557
    },{
      NanogenreId: 1377,
      x: 7444,
      y: 5816
    },{
      NanogenreId: 1378,
      x: 10287,
      y: 4957
    },{
      NanogenreId: 1379,
      x: 5137,
      y: 5399
    },{
      NanogenreId: 1380,
      x: 9377,
      y: 4336
    },{
      NanogenreId: 1381,
      x: 7080,
      y: 5787
    },{
      NanogenreId: 1382,
      x: 13994,
      y: 8398
    },{
      NanogenreId: 1383,
      x: 9472,
      y: 4563
    },{
      NanogenreId: 1384,
      x: 8252,
      y: 4409
    },{
      NanogenreId: 1385,
      x: 9993,
      y: 4565
    },{
      NanogenreId: 1386,
      x: 5101,
      y: 5934
    },{
      NanogenreId: 1387,
      x: 6567,
      y: 4486
    },{
      NanogenreId: 1388,
      x: 12904,
      y: 6198
    },{
      NanogenreId: 1389,
      x: 8536,
      y: 9636
    },{
      NanogenreId: 1390,
      x: 8301,
      y: 5358
    },{
      NanogenreId: 1391,
      x: 7590,
      y: 5887
    },{
      NanogenreId: 1392,
      x: 3259,
      y: 9156
    },{
      NanogenreId: 1393,
      x: 10000,
      y: 4925
    },{
      NanogenreId: 1394,
      x: 9022,
      y: 5495
    },{
      NanogenreId: 1395,
      x: 10784,
      y: 10870
    },{
      NanogenreId: 1396,
      x: 9126,
      y: 4883
    },{
      NanogenreId: 1397,
      x: 6145,
      y: 11662
    },{
      NanogenreId: 1398,
      x: 2725,
      y: 7341
    },{
      NanogenreId: 1399,
      x: 5412,
      y: 7506
    },{
      NanogenreId: 1400,
      x: 5857,
      y: 6139
    },{
      NanogenreId: 1401,
      x: 10116,
      y: 4532
    },{
      NanogenreId: 1402,
      x: 6159,
      y: 5507
    },{
      NanogenreId: 1403,
      x: 4470,
      y: 7659
    },{
      NanogenreId: 1404,
      x: 10385,
      y: 6825
    },{
      NanogenreId: 1405,
      x: 7361,
      y: 4281
    },{
      NanogenreId: 1406,
      x: 9258,
      y: 4211
    },{
      NanogenreId: 1407,
      x: 8836,
      y: 4911
    },{
      NanogenreId: 1408,
      x: 6613,
      y: 6369
    },{
      NanogenreId: 1409,
      x: 9236,
      y: 4935
    },{
      NanogenreId: 1410,
      x: 4941,
      y: 6155
    },{
      NanogenreId: 1411,
      x: 12620,
      y: 9838
    },{
      NanogenreId: 1412,
      x: 4762,
      y: 7613
    },{
      NanogenreId: 1413,
      x: 9553,
      y: 10109
    },{
      NanogenreId: 1414,
      x: 9693,
      y: 8021
    },{
      NanogenreId: 1415,
      x: 7946,
      y: 4485
    },{
      NanogenreId: 1416,
      x: 8188,
      y: 7244
    },{
      NanogenreId: 1417,
      x: 7505,
      y: 7910
    },{
      NanogenreId: 1418,
      x: 10550,
      y: 10795
    },{
      NanogenreId: 1419,
      x: 7518,
      y: 10254
    },{
      NanogenreId: 1420,
      x: 7290,
      y: 6803
    },{
      NanogenreId: 1421,
      x: 7665,
      y: 5454
    },{
      NanogenreId: 1422,
      x: 11747,
      y: 8836
    },{
      NanogenreId: 1423,
      x: 7340,
      y: 7984
    },{
      NanogenreId: 1424,
      x: 4493,
      y: 6903
    },{
      NanogenreId: 1425,
      x: 5349,
      y: 4842
    },{
      NanogenreId: 1426,
      x: 10199,
      y: 8984
    },{
      NanogenreId: 1427,
      x: 9977,
      y: 4949
    },{
      NanogenreId: 1428,
      x: 9739,
      y: 11720
    },{
      NanogenreId: 1429,
      x: 10501,
      y: 5105
    },{
      NanogenreId: 1430,
      x: 10841,
      y: 10393
    },{
      NanogenreId: 1431,
      x: 10513,
      y: 9976
    },{
      NanogenreId: 1432,
      x: 2453,
      y: 9009
    },{
      NanogenreId: 1433,
      x: 10891,
      y: 10416
    },{
      NanogenreId: 1434,
      x: 9030,
      y: 11263
    },{
      NanogenreId: 1435,
      x: 9492,
      y: 8807
    },{
      NanogenreId: 1436,
      x: 9746,
      y: 6414
    },{
      NanogenreId: 1437,
      x: 9484,
      y: 5674
    },{
      NanogenreId: 1438,
      x: 7351,
      y: 6083
    },{
      NanogenreId: 1439,
      x: 11553,
      y: 10089
    },{
      NanogenreId: 1440,
      x: 9734,
      y: 4755
    },{
      NanogenreId: 1441,
      x: 7823,
      y: 9608
    },{
      NanogenreId: 1442,
      x: 10697,
      y: 4929
    },{
      NanogenreId: 1443,
      x: 8822,
      y: 5620
    },{
      NanogenreId: 1444,
      x: 7704,
      y: 5705
    },{
      NanogenreId: 1445,
      x: 7306,
      y: 5034
    },{
      NanogenreId: 1446,
      x: 10646,
      y: 10947
    },{
      NanogenreId: 1447,
      x: 9614,
      y: 8681
    },{
      NanogenreId: 1448,
      x: 9716,
      y: 4782
    },{
      NanogenreId: 1449,
      x: 6683,
      y: 7160
    },{
      NanogenreId: 1450,
      x: 8489,
      y: 9535
    },{
      NanogenreId: 1451,
      x: 10037,
      y: 4476
    },{
      NanogenreId: 1452,
      x: 6366,
      y: 7389
    },{
      NanogenreId: 1453,
      x: 5161,
      y: 4724
    },{
      NanogenreId: 1454,
      x: 11538,
      y: 7904
    },{
      NanogenreId: 1455,
      x: 6221,
      y: 6679
    },{
      NanogenreId: 1456,
      x: 11167,
      y: 10408
    },{
      NanogenreId: 1457,
      x: 8956,
      y: 4663
    },{
      NanogenreId: 1458,
      x: 8494,
      y: 5137
    },{
      NanogenreId: 1459,
      x: 8457,
      y: 8041
    },{
      NanogenreId: 1460,
      x: 7463,
      y: 8654
    },{
      NanogenreId: 1461,
      x: 9843,
      y: 5060
    },{
      NanogenreId: 1462,
      x: 7311,
      y: 10860
    },{
      NanogenreId: 1463,
      x: 4590,
      y: 10254
    },{
      NanogenreId: 1464,
      x: 8841,
      y: 10659
    },{
      NanogenreId: 1465,
      x: 9666,
      y: 5573
    },{
      NanogenreId: 1466,
      x: 5109,
      y: 10872
    },{
      NanogenreId: 1467,
      x: 8564,
      y: 4839
    },{
      NanogenreId: 1468,
      x: 8835,
      y: 5125
    },{
      NanogenreId: 1469,
      x: 11340,
      y: 10330
    },{
      NanogenreId: 1470,
      x: 6301,
      y: 9041
    },{
      NanogenreId: 1471,
      x: 7868,
      y: 3950
    },{
      NanogenreId: 1472,
      x: 9752,
      y: 7996
    },{
      NanogenreId: 1473,
      x: 6394,
      y: 10032
    },{
      NanogenreId: 1474,
      x: 12712,
      y: 9684
    },{
      NanogenreId: 1475,
      x: 3036,
      y: 8950
    },{
      NanogenreId: 1476,
      x: 9377,
      y: 3168
    },{
      NanogenreId: 1477,
      x: 3262,
      y: 7545
    },{
      NanogenreId: 1478,
      x: 5092,
      y: 8899
    },{
      NanogenreId: 1479,
      x: 7002,
      y: 5081
    },{
      NanogenreId: 1480,
      x: 8270,
      y: 5236
    },{
      NanogenreId: 1481,
      x: 5968,
      y: 6671
    },{
      NanogenreId: 1482,
      x: 9976,
      y: 4967
    },{
      NanogenreId: 1483,
      x: 6484,
      y: 10199
    },{
      NanogenreId: 1484,
      x: 6026,
      y: 11561
    },{
      NanogenreId: 1485,
      x: 5547,
      y: 6114
    },{
      NanogenreId: 1486,
      x: 7539,
      y: 9834
    },{
      NanogenreId: 1487,
      x: 9360,
      y: 9714
    },{
      NanogenreId: 1488,
      x: 9908,
      y: 4570
    },{
      NanogenreId: 1489,
      x: 9419,
      y: 5182
    },{
      NanogenreId: 1490,
      x: 9714,
      y: 10937
    },{
      NanogenreId: 1491,
      x: 10582,
      y: 5690
    },{
      NanogenreId: 1492,
      x: 5569,
      y: 10835
    },{
      NanogenreId: 1493,
      x: 9532,
      y: 6005
    },{
      NanogenreId: 1494,
      x: 8994,
      y: 4276
    },{
      NanogenreId: 1495,
      x: 9419,
      y: 9170
    },{
      NanogenreId: 1496,
      x: 5732,
      y: 7816
    },{
      NanogenreId: 1497,
      x: 8895,
      y: 7510
    },{
      NanogenreId: 1498,
      x: 8704,
      y: 7951
    },{
      NanogenreId: 1499,
      x: 11024,
      y: 2266
    },{
      NanogenreId: 1500,
      x: 6623,
      y: 5937
    },{
      NanogenreId: 1501,
      x: 7924,
      y: 5117
    },{
      NanogenreId: 1502,
      x: 7125,
      y: 10722
    },{
      NanogenreId: 1503,
      x: 7612,
      y: 6797
    },{
      NanogenreId: 1504,
      x: 5716,
      y: 5533
    },{
      NanogenreId: 1505,
      x: 10515,
      y: 10034
    },{
      NanogenreId: 1506,
      x: 9156,
      y: 12263
    },{
      NanogenreId: 1507,
      x: 10294,
      y: 7919
    },{
      NanogenreId: 1508,
      x: 1673,
      y: 6565
    },{
      NanogenreId: 1509,
      x: 4840,
      y: 5365
    },{
      NanogenreId: 1510,
      x: 5253,
      y: 5851
    },{
      NanogenreId: 1511,
      x: 4253,
      y: 4893
    },{
      NanogenreId: 1512,
      x: 9555,
      y: 5781
    },{
      NanogenreId: 1513,
      x: 5999,
      y: 7109
    },{
      NanogenreId: 1514,
      x: 5150,
      y: 6437
    },{
      NanogenreId: 1515,
      x: 7194,
      y: 7051
    },{
      NanogenreId: 1516,
      x: 8156,
      y: 11062
    },{
      NanogenreId: 1517,
      x: 8158,
      y: 10112
    },{
      NanogenreId: 1518,
      x: 4954,
      y: 12104
    },{
      NanogenreId: 1519,
      x: 9191,
      y: 4784
    },{
      NanogenreId: 1520,
      x: 11346,
      y: 11060
    },{
      NanogenreId: 1521,
      x: 9364,
      y: 4662
    },{
      NanogenreId: 1522,
      x: 8753,
      y: 11907
    },{
      NanogenreId: 1523,
      x: 9646,
      y: 4957
    },{
      NanogenreId: 1524,
      x: 6663,
      y: 9260
    },{
      NanogenreId: 1525,
      x: 5418,
      y: 7411
    },{
      NanogenreId: 1526,
      x: 9284,
      y: 4773
    },{
      NanogenreId: 1527,
      x: 10513,
      y: 11044
    },{
      NanogenreId: 1528,
      x: 5719,
      y: 8066
    },{
      NanogenreId: 1529,
      x: 9941,
      y: 8879
    },{
      NanogenreId: 1530,
      x: 9825,
      y: 5754
    },{
      NanogenreId: 1531,
      x: 9058,
      y: 6410
    },{
      NanogenreId: 1532,
      x: 9759,
      y: 4824
    },{
      NanogenreId: 1533,
      x: 6788,
      y: 5193
    },{
      NanogenreId: 1534,
      x: 6680,
      y: 5669
    },{
      NanogenreId: 1535,
      x: 8506,
      y: 6403
    },{
      NanogenreId: 1536,
      x: 9670,
      y: 9446
    },{
      NanogenreId: 1537,
      x: 11153,
      y: 11570
    },{
      NanogenreId: 1538,
      x: 9863,
      y: 4556
    },{
      NanogenreId: 1539,
      x: 9739,
      y: 4467
    },{
      NanogenreId: 1540,
      x: 8577,
      y: 4790
    },{
      NanogenreId: 1541,
      x: 6883,
      y: 8672
    },{
      NanogenreId: 1542,
      x: 10275,
      y: 9217
    },{
      NanogenreId: 1543,
      x: 5521,
      y: 5824
    },{
      NanogenreId: 1544,
      x: 9526,
      y: 10216
    },{
      NanogenreId: 1545,
      x: 7804,
      y: 11314
    },{
      NanogenreId: 1546,
      x: 11156,
      y: 10124
    },{
      NanogenreId: 1547,
      x: 11306,
      y: 9624
    },{
      NanogenreId: 1548,
      x: 8093,
      y: 4500
    },{
      NanogenreId: 1549,
      x: 7483,
      y: 8888
    },{
      NanogenreId: 1550,
      x: 7984,
      y: 7458
    },{
      NanogenreId: 1551,
      x: 5939,
      y: 6127
    },{
      NanogenreId: 1552,
      x: 2687,
      y: 9556
    },{
      NanogenreId: 1553,
      x: 4878,
      y: 4685
    },{
      NanogenreId: 1554,
      x: 4949,
      y: 11246
    },{
      NanogenreId: 1555,
      x: 11679,
      y: 4817
    },{
      NanogenreId: 1556,
      x: 10900,
      y: 9494
    },{
      NanogenreId: 1557,
      x: 10249,
      y: 5118
    },{
      NanogenreId: 1558,
      x: 12665,
      y: 5779
    },{
      NanogenreId: 1559,
      x: 5073,
      y: 7336
    },{
      NanogenreId: 1560,
      x: 5970,
      y: 9889
    },{
      NanogenreId: 1561,
      x: 6096,
      y: 8199
    },{
      NanogenreId: 1562,
      x: 10850,
      y: 10648
    },{
      NanogenreId: 1563,
      x: 5151,
      y: 4094
    },{
      NanogenreId: 1564,
      x: 6875,
      y: 10708
    },{
      NanogenreId: 1565,
      x: 9726,
      y: 8116
    },{
      NanogenreId: 1566,
      x: 7024,
      y: 10602
    },{
      NanogenreId: 1567,
      x: 13469,
      y: 8428
    },{
      NanogenreId: 1568,
      x: 10254,
      y: 5472
    },{
      NanogenreId: 1569,
      x: 11181,
      y: 8937
    },{
      NanogenreId: 1570,
      x: 4998,
      y: 7611
    },{
      NanogenreId: 1571,
      x: 9721,
      y: 4885
    },{
      NanogenreId: 1572,
      x: 11077,
      y: 8727
    },{
      NanogenreId: 1573,
      x: 8285,
      y: 5362
    },{
      NanogenreId: 1574,
      x: 7075,
      y: 6609
    },{
      NanogenreId: 1575,
      x: 8022,
      y: 4586
    },{
      NanogenreId: 1576,
      x: 7687,
      y: 4952
    },{
      NanogenreId: 1577,
      x: 10189,
      y: 4910
    },{
      NanogenreId: 1578,
      x: 9564,
      y: 9867
    },{
      NanogenreId: 1579,
      x: 10880,
      y: 6748
    },{
      NanogenreId: 1580,
      x: 5775,
      y: 5303
    },{
      NanogenreId: 1581,
      x: 9194,
      y: 11099
    },{
      NanogenreId: 1582,
      x: 5439,
      y: 5779
    },{
      NanogenreId: 1583,
      x: 10598,
      y: 11075
    },{
      NanogenreId: 1584,
      x: 6301,
      y: 6832
    },{
      NanogenreId: 1585,
      x: 10669,
      y: 10257
    },{
      NanogenreId: 1586,
      x: 13267,
      y: 3772
    },{
      NanogenreId: 1587,
      x: 5817,
      y: 7502
    },{
      NanogenreId: 1588,
      x: 8768,
      y: 4479
    },{
      NanogenreId: 1589,
      x: 7644,
      y: 5773
    },{
      NanogenreId: 1590,
      x: 11150,
      y: 1946
    },{
      NanogenreId: 1591,
      x: 4476,
      y: 11993
    },{
      NanogenreId: 1592,
      x: 8372,
      y: 4961
    },{
      NanogenreId: 1593,
      x: 7550,
      y: 9519
    },{
      NanogenreId: 1594,
      x: 9724,
      y: 4717
    },{
      NanogenreId: 1595,
      x: 10294,
      y: 9823
    },{
      NanogenreId: 1596,
      x: 10763,
      y: 7490
    },{
      NanogenreId: 1597,
      x: 9551,
      y: 4673
    },{
      NanogenreId: 1598,
      x: 6725,
      y: 4849
    },{
      NanogenreId: 1599,
      x: 5260,
      y: 6680
    },{
      NanogenreId: 1600,
      x: 12776,
      y: 9222
    },{
      NanogenreId: 1601,
      x: 6041,
      y: 8107
    },{
      NanogenreId: 1602,
      x: 8959,
      y: 4954
    },{
      NanogenreId: 1603,
      x: 6277,
      y: 6968
    },{
      NanogenreId: 1604,
      x: 4041,
      y: 10574
    },{
      NanogenreId: 1605,
      x: 5996,
      y: 6742
    },{
      NanogenreId: 1606,
      x: 10673,
      y: 10221
    },{
      NanogenreId: 1607,
      x: 9375,
      y: 9451
    },{
      NanogenreId: 1608,
      x: 9149,
      y: 12620
    },{
      NanogenreId: 1609,
      x: 10554,
      y: 7489
    },{
      NanogenreId: 1610,
      x: 6551,
      y: 9427
    },{
      NanogenreId: 1611,
      x: 9977,
      y: 4651
    },{
      NanogenreId: 1612,
      x: 8991,
      y: 10513
    },{
      NanogenreId: 1613,
      x: 10574,
      y: 7103
    },{
      NanogenreId: 1614,
      x: 11575,
      y: 6193
    },{
      NanogenreId: 1615,
      x: 5016,
      y: 6282
    },{
      NanogenreId: 1616,
      x: 8431,
      y: 7581
    },{
      NanogenreId: 1617,
      x: 10886,
      y: 10800
    },{
      NanogenreId: 1618,
      x: 8300,
      y: 5259
    },{
      NanogenreId: 1619,
      x: 7922,
      y: 1859
    },{
      NanogenreId: 1620,
      x: 8530,
      y: 9977
    },{
      NanogenreId: 1621,
      x: 9471,
      y: 6428
    },{
      NanogenreId: 1622,
      x: 4090,
      y: 11242
    },{
      NanogenreId: 1623,
      x: 3108,
      y: 9741
    },{
      NanogenreId: 1624,
      x: 7061,
      y: 7151
    },{
      NanogenreId: 1625,
      x: 5511,
      y: 4256
    },{
      NanogenreId: 1626,
      x: 9323,
      y: 4886
    },{
      NanogenreId: 1627,
      x: 8804,
      y: 8779
    },{
      NanogenreId: 1628,
      x: 8998,
      y: 4813
    },{
      NanogenreId: 1629,
      x: 4591,
      y: 7922
    },{
      NanogenreId: 1630,
      x: 6364,
      y: 6337
    },{
      NanogenreId: 1631,
      x: 4712,
      y: 5683
    },{
      NanogenreId: 1632,
      x: 5724,
      y: 3802
    },{
      NanogenreId: 1633,
      x: 8627,
      y: 6857
    },{
      NanogenreId: 1634,
      x: 3665,
      y: 8368
    },{
      NanogenreId: 1635,
      x: 7701,
      y: 6010
    },{
      NanogenreId: 1636,
      x: 7210,
      y: 2569
    },{
      NanogenreId: 1637,
      x: 9664,
      y: 4218
    },{
      NanogenreId: 1638,
      x: 9839,
      y: 4349
    },{
      NanogenreId: 1639,
      x: 6837,
      y: 8211
    },{
      NanogenreId: 1640,
      x: 6739,
      y: 6573
    },{
      NanogenreId: 1641,
      x: 10074,
      y: 11331
    },{
      NanogenreId: 1642,
      x: 8096,
      y: 10862
    },{
      NanogenreId: 1643,
      x: 7723,
      y: 12037
    },{
      NanogenreId: 1644,
      x: 5595,
      y: 6986
    },{
      NanogenreId: 1645,
      x: 3797,
      y: 3862
    },{
      NanogenreId: 1646,
      x: 10201,
      y: 10301
    },{
      NanogenreId: 1647,
      x: 9357,
      y: 11637
    },{
      NanogenreId: 1648,
      x: 6100,
      y: 10538
    },{
      NanogenreId: 1649,
      x: 7253,
      y: 10493
    },{
      NanogenreId: 1650,
      x: 9972,
      y: 4879
    },{
      NanogenreId: 1651,
      x: 4556,
      y: 5130
    },{
      NanogenreId: 1652,
      x: 7830,
      y: 12091
    },{
      NanogenreId: 1653,
      x: 12091,
      y: 10549
    },{
      NanogenreId: 1654,
      x: 9301,
      y: 9156
    },{
      NanogenreId: 1655,
      x: 9701,
      y: 10747
    },{
      NanogenreId: 1656,
      x: 9582,
      y: 4684
    },{
      NanogenreId: 1657,
      x: 4499,
      y: 7200
    },{
      NanogenreId: 1658,
      x: 7438,
      y: 6322
    },{
      NanogenreId: 1659,
      x: 6689,
      y: 6355
    },{
      NanogenreId: 1660,
      x: 10066,
      y: 4710
    },{
      NanogenreId: 1661,
      x: 7951,
      y: 6016
    },{
      NanogenreId: 1662,
      x: 10035,
      y: 8476
    },{
      NanogenreId: 1663,
      x: 2908,
      y: 7588
    },{
      NanogenreId: 1664,
      x: 6891,
      y: 5837
    },{
      NanogenreId: 1665,
      x: 6825,
      y: 5318
    },{
      NanogenreId: 1666,
      x: 10144,
      y: 7586
    },{
      NanogenreId: 1667,
      x: 10731,
      y: 10747
    },{
      NanogenreId: 1668,
      x: 3136,
      y: 5713
    },{
      NanogenreId: 1669,
      x: 8609,
      y: 6242
    },{
      NanogenreId: 1670,
      x: 9676,
      y: 4663
    },{
      NanogenreId: 1671,
      x: 5823,
      y: 6026
    },{
      NanogenreId: 1672,
      x: 9097,
      y: 9302
    },{
      NanogenreId: 1673,
      x: 9427,
      y: 4910
    },{
      NanogenreId: 1674,
      x: 3256,
      y: 5582
    },{
      NanogenreId: 1675,
      x: 5108,
      y: 7847
    },{
      NanogenreId: 1676,
      x: 7605,
      y: 9664
    },{
      NanogenreId: 1677,
      x: 7750,
      y: 6298
    },{
      NanogenreId: 1678,
      x: 5910,
      y: 11715
    },{
      NanogenreId: 1679,
      x: 7878,
      y: 4849
    },{
      NanogenreId: 1680,
      x: 10980,
      y: 10904
    },{
      NanogenreId: 1681,
      x: 9453,
      y: 4933
    },{
      NanogenreId: 1682,
      x: 7103,
      y: 9003
    },{
      NanogenreId: 1683,
      x: 7247,
      y: 8730
    },{
      NanogenreId: 1684,
      x: 7029,
      y: 2485
    },{
      NanogenreId: 1685,
      x: 3817,
      y: 10065
    },{
      NanogenreId: 1686,
      x: 5389,
      y: 7312
    },{
      NanogenreId: 1687,
      x: 6279,
      y: 9370
    },{
      NanogenreId: 1688,
      x: 2842,
      y: 6520
    },{
      NanogenreId: 1689,
      x: 10230,
      y: 5311
    },{
      NanogenreId: 1690,
      x: 10427,
      y: 10635
    },{
      NanogenreId: 1691,
      x: 7843,
      y: 12767
    },{
      NanogenreId: 1692,
      x: 5400,
      y: 6738
    },{
      NanogenreId: 1693,
      x: 8375,
      y: 9788
    },{
      NanogenreId: 1694,
      x: 6952,
      y: 4778
    },{
      NanogenreId: 1695,
      x: 9072,
      y: 5022
    },{
      NanogenreId: 1696,
      x: 9571,
      y: 4971
    },{
      NanogenreId: 1697,
      x: 9841,
      y: 10722
    },{
      NanogenreId: 1698,
      x: 8050,
      y: 5876
    },{
      NanogenreId: 1699,
      x: 7829,
      y: 6755
    },{
      NanogenreId: 1700,
      x: 9578,
      y: 4767
    },{
      NanogenreId: 1701,
      x: 5885,
      y: 7815
    },{
      NanogenreId: 1702,
      x: 6518,
      y: 8410
    },{
      NanogenreId: 1703,
      x: 6723,
      y: 11302
    },{
      NanogenreId: 1704,
      x: 9814,
      y: 4687
    },{
      NanogenreId: 1705,
      x: 10529,
      y: 10483
    },{
      NanogenreId: 1706,
      x: 6587,
      y: 11121
    },{
      NanogenreId: 1707,
      x: 7735,
      y: 7562
    },{
      NanogenreId: 1708,
      x: 5349,
      y: 5711
    },{
      NanogenreId: 1709,
      x: 7168,
      y: 7731
    },{
      NanogenreId: 1710,
      x: 9976,
      y: 4625
    },{
      NanogenreId: 1711,
      x: 3901,
      y: 10059
    },{
      NanogenreId: 1712,
      x: 6580,
      y: 7270
    },{
      NanogenreId: 1713,
      x: 10143,
      y: 9244
    },{
      NanogenreId: 1714,
      x: 10043,
      y: 4626
    },{
      NanogenreId: 1715,
      x: 5148,
      y: 5643
    },{
      NanogenreId: 1716,
      x: 9773,
      y: 11151
    },{
      NanogenreId: 1717,
      x: 8496,
      y: 6803
    },{
      NanogenreId: 1718,
      x: 9365,
      y: 6251
    },{
      NanogenreId: 1719,
      x: 11081,
      y: 10557
    },{
      NanogenreId: 1720,
      x: 8102,
      y: 4256
    },{
      NanogenreId: 1721,
      x: 7475,
      y: 5966
    },{
      NanogenreId: 1722,
      x: 10044,
      y: 10371
    },{
      NanogenreId: 1723,
      x: 7718,
      y: 6944
    },{
      NanogenreId: 1724,
      x: 8025,
      y: 5277
    },{
      NanogenreId: 1725,
      x: 6837,
      y: 5067
    },{
      NanogenreId: 1726,
      x: 9655,
      y: 4580
    },{
      NanogenreId: 1727,
      x: 10007,
      y: 5805
    },{
      NanogenreId: 1728,
      x: 11360,
      y: 10416
    },{
      NanogenreId: 1729,
      x: 10939,
      y: 10258
    },{
      NanogenreId: 1730,
      x: 5677,
      y: 8817
    },{
      NanogenreId: 1731,
      x: 8784,
      y: 7860
    },{
      NanogenreId: 1732,
      x: 8674,
      y: 4760
    },{
      NanogenreId: 1733,
      x: 10904,
      y: 9758
    },{
      NanogenreId: 1734,
      x: 11250,
      y: 9483
    },{
      NanogenreId: 1735,
      x: 9866,
      y: 8762
    },{
      NanogenreId: 1736,
      x: 7288,
      y: 4832
    },{
      NanogenreId: 1737,
      x: 6284,
      y: 6576
    },{
      NanogenreId: 1738,
      x: 7906,
      y: 11709
    },{
      NanogenreId: 1739,
      x: 9127,
      y: 6950
    },{
      NanogenreId: 1740,
      x: 8621,
      y: 10048
    },{
      NanogenreId: 1741,
      x: 9025,
      y: 7886
    },{
      NanogenreId: 1742,
      x: 9907,
      y: 4903
    },{
      NanogenreId: 1743,
      x: 9008,
      y: 11106
    },{
      NanogenreId: 1744,
      x: 8340,
      y: 6446
    },{
      NanogenreId: 1745,
      x: 9492,
      y: 5218
    },{
      NanogenreId: 1746,
      x: 7982,
      y: 2750
    },{
      NanogenreId: 1747,
      x: 9133,
      y: 4734
    },{
      NanogenreId: 1748,
      x: 11050,
      y: 10626
    },{
      NanogenreId: 1749,
      x: 7407,
      y: 11610
    },{
      NanogenreId: 1750,
      x: 9198,
      y: 10174
    },{
      NanogenreId: 1751,
      x: 6026,
      y: 4675
    },{
      NanogenreId: 1752,
      x: 9244,
      y: 9995
    },{
      NanogenreId: 1753,
      x: 9959,
      y: 6964
    },{
      NanogenreId: 1754,
      x: 6356,
      y: 6094
    },{
      NanogenreId: 1755,
      x: 8717,
      y: 5094
    },{
      NanogenreId: 1756,
      x: 7095,
      y: 9326
    },{
      NanogenreId: 1757,
      x: 8722,
      y: 5158
    },{
      NanogenreId: 1758,
      x: 6756,
      y: 10470
    },{
      NanogenreId: 1759,
      x: 10871,
      y: 10334
    },{
      NanogenreId: 1760,
      x: 7271,
      y: 6124
    },{
      NanogenreId: 1761,
      x: 9564,
      y: 2975
    },{
      NanogenreId: 1762,
      x: 9852,
      y: 7688
    },{
      NanogenreId: 1763,
      x: 3780,
      y: 9661
    },{
      NanogenreId: 1764,
      x: 9088,
      y: 9470
    },{
      NanogenreId: 1765,
      x: 9471,
      y: 8763
    },{
      NanogenreId: 1766,
      x: 8233,
      y: 5282
    },{
      NanogenreId: 1767,
      x: 11496,
      y: 10066
    },{
      NanogenreId: 1768,
      x: 10393,
      y: 9971
    },{
      NanogenreId: 1769,
      x: 7188,
      y: 6395
    },{
      NanogenreId: 1770,
      x: 8925,
      y: 4898
    },{
      NanogenreId: 1771,
      x: 8343,
      y: 9188
    },{
      NanogenreId: 1772,
      x: 3762,
      y: 9552
    },{
      NanogenreId: 1773,
      x: 6766,
      y: 7561
    },{
      NanogenreId: 1774,
      x: 9200,
      y: 8970
    },{
      NanogenreId: 1775,
      x: 4227,
      y: 10470
    },{
      NanogenreId: 1776,
      x: 11203,
      y: 11093
    },{
      NanogenreId: 1777,
      x: 9999,
      y: 10781
    },{
      NanogenreId: 1778,
      x: 5992,
      y: 7555
    },{
      NanogenreId: 1779,
      x: 5431,
      y: 6577
    },{
      NanogenreId: 1780,
      x: 10126,
      y: 4753
    },{
      NanogenreId: 1781,
      x: 9013,
      y: 6767
    },{
      NanogenreId: 1782,
      x: 6286,
      y: 11655
    },{
      NanogenreId: 1783,
      x: 6986,
      y: 5635
    },{
      NanogenreId: 1784,
      x: 7482,
      y: 5653
    },{
      NanogenreId: 1785,
      x: 8497,
      y: 11952
    },{
      NanogenreId: 1786,
      x: 9085,
      y: 6200
    },{
      NanogenreId: 1787,
      x: 7580,
      y: 8054
    },{
      NanogenreId: 1788,
      x: 7608,
      y: 10935
    },{
      NanogenreId: 1789,
      x: 11283,
      y: 10479
    },{
      NanogenreId: 1790,
      x: 9532,
      y: 8861
    },{
      NanogenreId: 1791,
      x: 10524,
      y: 10328
    },{
      NanogenreId: 1792,
      x: 7363,
      y: 10443
    },{
      NanogenreId: 1793,
      x: 7791,
      y: 5973
    },{
      NanogenreId: 1794,
      x: 7998,
      y: 6232
    },{
      NanogenreId: 1795,
      x: 11111,
      y: 10662
    },{
      NanogenreId: 1796,
      x: 4416,
      y: 3918
    },{
      NanogenreId: 1797,
      x: 7534,
      y: 10100
    },{
      NanogenreId: 1798,
      x: 9889,
      y: 4487
    },{
      NanogenreId: 1799,
      x: 6153,
      y: 9328
    },{
      NanogenreId: 1800,
      x: 9136,
      y: 2284
    },{
      NanogenreId: 1801,
      x: 7235,
      y: 11098
    },{
      NanogenreId: 1802,
      x: 8965,
      y: 4831
    },{
      NanogenreId: 1803,
      x: 7335,
      y: 4724
    },{
      NanogenreId: 1804,
      x: 13050,
      y: 9318
    },{
      NanogenreId: 1805,
      x: 8928,
      y: 8486
    },{
      NanogenreId: 1806,
      x: 6230,
      y: 5599
    },{
      NanogenreId: 1807,
      x: 5971,
      y: 5981
    },{
      NanogenreId: 1808,
      x: 6909,
      y: 3758
    },{
      NanogenreId: 1809,
      x: 8629,
      y: 5420
    },{
      NanogenreId: 1810,
      x: 9454,
      y: 5066
    },{
      NanogenreId: 1811,
      x: 10660,
      y: 11368
    },{
      NanogenreId: 1812,
      x: 8364,
      y: 12274
    },{
      NanogenreId: 1813,
      x: 6883,
      y: 6463
    },{
      NanogenreId: 1814,
      x: 7169,
      y: 7828
    },{
      NanogenreId: 1815,
      x: 10255,
      y: 7445
    },{
      NanogenreId: 1816,
      x: 10408,
      y: 10586
    },{
      NanogenreId: 1817,
      x: 6827,
      y: 12294
    },{
      NanogenreId: 1818,
      x: 7493,
      y: 5868
    },{
      NanogenreId: 1819,
      x: 9062,
      y: 4809
    },{
      NanogenreId: 1820,
      x: 6845,
      y: 7079
    },{
      NanogenreId: 1821,
      x: 6174,
      y: 7329
    },{
      NanogenreId: 1822,
      x: 10100,
      y: 7147
    },{
      NanogenreId: 1823,
      x: 7822,
      y: 5633
    },{
      NanogenreId: 1824,
      x: 6868,
      y: 6705
    },{
      NanogenreId: 1825,
      x: 6122,
      y: 11040
    },{
      NanogenreId: 1826,
      x: 9563,
      y: 5046
    },{
      NanogenreId: 1827,
      x: 9940,
      y: 5233
    },{
      NanogenreId: 1828,
      x: 6640,
      y: 7155
    },{
      NanogenreId: 1829,
      x: 7037,
      y: 11076
    },{
      NanogenreId: 1830,
      x: 9194,
      y: 4997
    },{
      NanogenreId: 1831,
      x: 2381,
      y: 8460
    },{
      NanogenreId: 1832,
      x: 7224,
      y: 6513
    },{
      NanogenreId: 1833,
      x: 5779,
      y: 6110
    },{
      NanogenreId: 1834,
      x: 5715,
      y: 9322
    },{
      NanogenreId: 1835,
      x: 8188,
      y: 7286
    },{
      NanogenreId: 1836,
      x: 8223,
      y: 8377
    },{
      NanogenreId: 1837,
      x: 11608,
      y: 10085
    },{
      NanogenreId: 1838,
      x: 5874,
      y: 4247
    },{
      NanogenreId: 1839,
      x: 7013,
      y: 4843
    },{
      NanogenreId: 1840,
      x: 8394,
      y: 10211
    },{
      NanogenreId: 1841,
      x: 10062,
      y: 9522
    },{
      NanogenreId: 1842,
      x: 5257,
      y: 9327
    },{
      NanogenreId: 1843,
      x: 9825,
      y: 5190
    },{
      NanogenreId: 1844,
      x: 9313,
      y: 4600
    },{
      NanogenreId: 1845,
      x: 7040,
      y: 10308
    },{
      NanogenreId: 1846,
      x: 6782,
      y: 9607
    },{
      NanogenreId: 1847,
      x: 9532,
      y: 8470
    },{
      NanogenreId: 1848,
      x: 9993,
      y: 5238
    },{
      NanogenreId: 1849,
      x: 7844,
      y: 4900
    },{
      NanogenreId: 1850,
      x: 8536,
      y: 9028
    },{
      NanogenreId: 1851,
      x: 10957,
      y: 10945
    },{
      NanogenreId: 1852,
      x: 6011,
      y: 9280
    },{
      NanogenreId: 1853,
      x: 5824,
      y: 8083
    },{
      NanogenreId: 1854,
      x: 8501,
      y: 5928
    },{
      NanogenreId: 1855,
      x: 5745,
      y: 5771
    },{
      NanogenreId: 1856,
      x: 13168,
      y: 6743
    },{
      NanogenreId: 1857,
      x: 9164,
      y: 8826
    },{
      NanogenreId: 1858,
      x: 11781,
      y: 6115
    },{
      NanogenreId: 1859,
      x: 8582,
      y: 9275
    },{
      NanogenreId: 1860,
      x: 11901,
      y: 7751
    },{
      NanogenreId: 1861,
      x: 9994,
      y: 4719
    },{
      NanogenreId: 1862,
      x: 5925,
      y: 11786
    },{
      NanogenreId: 1863,
      x: 10149,
      y: 8924
    },{
      NanogenreId: 1864,
      x: 8769,
      y: 5285
    },{
      NanogenreId: 1865,
      x: 8397,
      y: 2931
    },{
      NanogenreId: 1866,
      x: 6788,
      y: 7169
    },{
      NanogenreId: 1867,
      x: 5958,
      y: 7594
    },{
      NanogenreId: 1868,
      x: 9848,
      y: 8369
    },{
      NanogenreId: 1869,
      x: 8203,
      y: 4888
    },{
      NanogenreId: 1870,
      x: 4875,
      y: 7220
    },{
      NanogenreId: 1871,
      x: 9346,
      y: 9310
    },{
      NanogenreId: 1872,
      x: 3965,
      y: 8552
    },{
      NanogenreId: 1873,
      x: 6940,
      y: 7474
    },{
      NanogenreId: 1874,
      x: 8141,
      y: 5704
    },{
      NanogenreId: 1875,
      x: 8573,
      y: 4071
    },{
      NanogenreId: 1876,
      x: 7582,
      y: 8594
    },{
      NanogenreId: 1877,
      x: 13333,
      y: 3893
    },{
      NanogenreId: 1878,
      x: 8167,
      y: 6379
    },{
      NanogenreId: 1879,
      x: 3268,
      y: 9983
    },{
      NanogenreId: 1880,
      x: 10397,
      y: 10393
    },{
      NanogenreId: 1881,
      x: 9211,
      y: 9575
    },{
      NanogenreId: 1882,
      x: 6908,
      y: 8171
    },{
      NanogenreId: 1883,
      x: 10805,
      y: 10473
    },{
      NanogenreId: 1884,
      x: 9516,
      y: 5847
    },{
      NanogenreId: 1885,
      x: 8079,
      y: 11269
    },{
      NanogenreId: 1886,
      x: 8719,
      y: 5186
    },{
      NanogenreId: 1887,
      x: 8417,
      y: 3983
    },{
      NanogenreId: 1888,
      x: 9224,
      y: 9643
    },{
      NanogenreId: 1889,
      x: 6120,
      y: 6092
    },{
      NanogenreId: 1890,
      x: 8634,
      y: 5260
    },{
      NanogenreId: 1891,
      x: 9957,
      y: 5011
    },{
      NanogenreId: 1892,
      x: 8485,
      y: 4906
    },{
      NanogenreId: 1893,
      x: 8038,
      y: 4430
    },{
      NanogenreId: 1894,
      x: 8797,
      y: 4980
    },{
      NanogenreId: 1895,
      x: 8316,
      y: 9521
    },{
      NanogenreId: 1896,
      x: 6523,
      y: 5510
    },{
      NanogenreId: 1897,
      x: 5710,
      y: 10244
    },{
      NanogenreId: 1898,
      x: 9480,
      y: 4491
    },{
      NanogenreId: 1899,
      x: 9532,
      y: 5303
    },{
      NanogenreId: 1900,
      x: 6888,
      y: 7937
    },{
      NanogenreId: 1901,
      x: 8170,
      y: 7703
    },{
      NanogenreId: 1902,
      x: 6960,
      y: 5483
    },{
      NanogenreId: 1903,
      x: 3264,
      y: 10065
    },{
      NanogenreId: 1904,
      x: 10605,
      y: 11258
    },{
      NanogenreId: 1905,
      x: 3727,
      y: 10039
    },{
      NanogenreId: 1906,
      x: 7787,
      y: 9163
    },{
      NanogenreId: 1907,
      x: 6130,
      y: 5613
    },{
      NanogenreId: 1908,
      x: 10181,
      y: 4355
    },{
      NanogenreId: 1909,
      x: 8999,
      y: 5121
    },{
      NanogenreId: 1910,
      x: 9452,
      y: 6733
    },{
      NanogenreId: 1911,
      x: 7704,
      y: 5150
    },{
      NanogenreId: 1912,
      x: 9455,
      y: 7050
    },{
      NanogenreId: 1913,
      x: 8530,
      y: 6112
    },{
      NanogenreId: 1914,
      x: 4919,
      y: 5948
    },{
      NanogenreId: 1915,
      x: 5536,
      y: 4655
    },{
      NanogenreId: 1916,
      x: 9635,
      y: 7967
    },{
      NanogenreId: 1917,
      x: 9866,
      y: 4545
    },{
      NanogenreId: 1918,
      x: 6238,
      y: 6066
    },{
      NanogenreId: 1919,
      x: 7230,
      y: 7387
    },{
      NanogenreId: 1920,
      x: 7212,
      y: 10376
    },{
      NanogenreId: 1921,
      x: 9414,
      y: 4823
    },{
      NanogenreId: 1922,
      x: 10263,
      y: 10351
    },{
      NanogenreId: 1923,
      x: 8098,
      y: 7974
    },{
      NanogenreId: 1924,
      x: 5612,
      y: 6716
    },{
      NanogenreId: 1925,
      x: 9622,
      y: 5690
    },{
      NanogenreId: 1926,
      x: 10149,
      y: 8433
    },{
      NanogenreId: 1927,
      x: 10263,
      y: 7676
    },{
      NanogenreId: 1928,
      x: 5501,
      y: 4913
    },{
      NanogenreId: 1929,
      x: 8674,
      y: 5621
    },{
      NanogenreId: 1930,
      x: 10270,
      y: 4901
    },{
      NanogenreId: 1931,
      x: 5946,
      y: 5888
    },{
      NanogenreId: 1932,
      x: 13481,
      y: 9136
    },{
      NanogenreId: 1933,
      x: 6104,
      y: 5934
    },{
      NanogenreId: 1934,
      x: 7251,
      y: 6671
    },{
      NanogenreId: 1935,
      x: 9317,
      y: 5311
    },{
      NanogenreId: 1936,
      x: 7760,
      y: 5532
    },{
      NanogenreId: 1937,
      x: 6550,
      y: 7850
    },{
      NanogenreId: 1938,
      x: 11978,
      y: 9997
    },{
      NanogenreId: 1939,
      x: 5805,
      y: 7640
    },{
      NanogenreId: 1940,
      x: 5388,
      y: 9640
    },{
      NanogenreId: 1941,
      x: 8584,
      y: 5415
    },{
      NanogenreId: 1942,
      x: 10142,
      y: 4972
    },{
      NanogenreId: 1943,
      x: 12298,
      y: 9630
    },{
      NanogenreId: 1944,
      x: 8005,
      y: 7103
    },{
      NanogenreId: 1945,
      x: 9803,
      y: 4674
    },{
      NanogenreId: 1946,
      x: 10692,
      y: 10114
    },{
      NanogenreId: 1947,
      x: 9266,
      y: 4921
    },{
      NanogenreId: 1948,
      x: 5411,
      y: 5419
    },{
      NanogenreId: 1949,
      x: 9207,
      y: 10717
    },{
      NanogenreId: 1950,
      x: 4155,
      y: 10896
    },{
      NanogenreId: 1951,
      x: 12037,
      y: 5999
    },{
      NanogenreId: 1952,
      x: 6403,
      y: 11675
    },{
      NanogenreId: 1953,
      x: 9962,
      y: 4509
    },{
      NanogenreId: 1954,
      x: 8043,
      y: 5238
    },{
      NanogenreId: 1955,
      x: 7106,
      y: 10500
    },{
      NanogenreId: 1956,
      x: 6972,
      y: 7583
    },{
      NanogenreId: 1957,
      x: 9408,
      y: 5601
    },{
      NanogenreId: 1958,
      x: 8899,
      y: 11391
    },{
      NanogenreId: 1959,
      x: 9312,
      y: 5691
    },{
      NanogenreId: 1960,
      x: 10988,
      y: 10540
    },{
      NanogenreId: 1961,
      x: 11668,
      y: 5657
    },{
      NanogenreId: 1962,
      x: 9084,
      y: 5220
    },{
      NanogenreId: 1963,
      x: 4631,
      y: 5060
    },{
      NanogenreId: 1964,
      x: 7482,
      y: 7823
    },{
      NanogenreId: 1965,
      x: 2810,
      y: 7040
    },{
      NanogenreId: 1966,
      x: 8777,
      y: 5591
    },{
      NanogenreId: 1967,
      x: 8817,
      y: 5809
    },{
      NanogenreId: 1968,
      x: 7553,
      y: 9409
    },{
      NanogenreId: 1969,
      x: 7565,
      y: 5662
    },{
      NanogenreId: 1970,
      x: 7810,
      y: 8377
    },{
      NanogenreId: 1971,
      x: 8957,
      y: 7272
    },{
      NanogenreId: 1972,
      x: 6562,
      y: 9362
    },{
      NanogenreId: 1973,
      x: 9911,
      y: 4488
    },{
      NanogenreId: 1974,
      x: 9350,
      y: 5073
    },{
      NanogenreId: 1975,
      x: 8756,
      y: 5538
    },{
      NanogenreId: 1976,
      x: 8084,
      y: 9477
    },{
      NanogenreId: 1977,
      x: 7391,
      y: 11032
    },{
      NanogenreId: 1978,
      x: 9622,
      y: 4533
    },{
      NanogenreId: 1979,
      x: 10457,
      y: 10679
    },{
      NanogenreId: 1980,
      x: 6899,
      y: 8515
    },{
      NanogenreId: 1981,
      x: 7353,
      y: 5547
    },{
      NanogenreId: 1982,
      x: 7471,
      y: 6061
    },{
      NanogenreId: 1983,
      x: 5773,
      y: 6180
    },{
      NanogenreId: 1984,
      x: 9017,
      y: 8166
    },{
      NanogenreId: 1985,
      x: 10087,
      y: 7328
    },{
      NanogenreId: 1986,
      x: 7429,
      y: 6038
    },{
      NanogenreId: 1987,
      x: 7260,
      y: 6730
    },{
      NanogenreId: 1988,
      x: 9467,
      y: 7971
    },{
      NanogenreId: 1989,
      x: 3574,
      y: 9886
    },{
      NanogenreId: 1990,
      x: 8801,
      y: 9270
    },{
      NanogenreId: 1991,
      x: 9581,
      y: 10869
    },{
      NanogenreId: 1992,
      x: 4871,
      y: 10161
    },{
      NanogenreId: 1993,
      x: 3904,
      y: 9617
    },{
      NanogenreId: 1994,
      x: 13175,
      y: 6527
    },{
      NanogenreId: 1995,
      x: 7370,
      y: 9975
    },{
      NanogenreId: 1996,
      x: 9420,
      y: 4584
    },{
      NanogenreId: 1997,
      x: 7129,
      y: 7156
    },{
      NanogenreId: 1998,
      x: 8255,
      y: 5652
    },{
      NanogenreId: 1999,
      x: 6006,
      y: 6233
    },{
      NanogenreId: 2000,
      x: 5337,
      y: 7736
    },{
      NanogenreId: 2001,
      x: 7757,
      y: 4355
    },{
      NanogenreId: 2002,
      x: 8880,
      y: 6111
    },{
      NanogenreId: 2003,
      x: 3069,
      y: 7371
    },{
      NanogenreId: 2004,
      x: 9597,
      y: 9109
    },{
      NanogenreId: 2005,
      x: 6348,
      y: 7042
    },{
      NanogenreId: 2006,
      x: 9399,
      y: 7082
    },{
      NanogenreId: 2007,
      x: 9441,
      y: 4483
    },{
      NanogenreId: 2008,
      x: 10637,
      y: 2075
    },{
      NanogenreId: 2009,
      x: 9272,
      y: 6030
    },{
      NanogenreId: 2010,
      x: 8829,
      y: 9688
    },{
      NanogenreId: 2011,
      x: 7375,
      y: 7650
    },{
      NanogenreId: 2012,
      x: 10622,
      y: 7411
    },{
      NanogenreId: 2013,
      x: 6013,
      y: 6894
    },{
      NanogenreId: 2014,
      x: 9819,
      y: 5177
    },{
      NanogenreId: 2015,
      x: 6904,
      y: 4008
    },{
      NanogenreId: 2016,
      x: 9836,
      y: 4780
    },{
      NanogenreId: 2017,
      x: 10103,
      y: 9076
    },{
      NanogenreId: 2018,
      x: 7523,
      y: 8250
    },{
      NanogenreId: 2019,
      x: 9834,
      y: 5381
    },{
      NanogenreId: 2020,
      x: 8795,
      y: 7787
    },{
      NanogenreId: 2021,
      x: 9499,
      y: 4867
    },{
      NanogenreId: 2022,
      x: 7494,
      y: 6606
    },{
      NanogenreId: 2023,
      x: 6164,
      y: 12779
    },{
      NanogenreId: 2024,
      x: 4167,
      y: 7122
    },{
      NanogenreId: 2025,
      x: 8435,
      y: 7884
    },{
      NanogenreId: 2026,
      x: 8058,
      y: 7001
    },{
      NanogenreId: 2027,
      x: 8172,
      y: 11440
    },{
      NanogenreId: 2028,
      x: 7914,
      y: 6131
    },{
      NanogenreId: 2029,
      x: 12321,
      y: 7372
    },{
      NanogenreId: 2030,
      x: 9127,
      y: 4951
    },{
      NanogenreId: 2031,
      x: 4817,
      y: 9179
    },{
      NanogenreId: 2032,
      x: 8693,
      y: 6975
    },{
      NanogenreId: 2033,
      x: 8270,
      y: 6861
    },{
      NanogenreId: 2034,
      x: 11024,
      y: 10559
    },{
      NanogenreId: 2035,
      x: 8068,
      y: 10831
    },{
      NanogenreId: 2036,
      x: 8162,
      y: 6075
    },{
      NanogenreId: 2037,
      x: 8812,
      y: 9991
    },{
      NanogenreId: 2038,
      x: 8893,
      y: 9342
    },{
      NanogenreId: 2039,
      x: 13774,
      y: 6540
    },{
      NanogenreId: 2040,
      x: 9216,
      y: 5079
    },{
      NanogenreId: 2041,
      x: 10989,
      y: 7764
    },{
      NanogenreId: 2042,
      x: 8446,
      y: 8870
    },{
      NanogenreId: 2043,
      x: 12358,
      y: 2321
    },{
      NanogenreId: 2044,
      x: 11593,
      y: 7590
    },{
      NanogenreId: 2045,
      x: 8337,
      y: 5314
    },{
      NanogenreId: 2046,
      x: 5998,
      y: 7069
    },{
      NanogenreId: 2047,
      x: 9369,
      y: 7653
    },{
      NanogenreId: 2048,
      x: 9763,
      y: 5045
    },{
      NanogenreId: 2049,
      x: 10998,
      y: 10458
    },{
      NanogenreId: 2050,
      x: 8530,
      y: 4733
    },{
      NanogenreId: 2051,
      x: 2927,
      y: 8453
    },{
      NanogenreId: 2052,
      x: 10731,
      y: 10364
    },{
      NanogenreId: 2053,
      x: 5590,
      y: 10175
    },{
      NanogenreId: 2054,
      x: 12537,
      y: 5840
    },{
      NanogenreId: 2055,
      x: 8697,
      y: 4560
    },{
      NanogenreId: 2056,
      x: 8994,
      y: 11636
    },{
      NanogenreId: 2057,
      x: 12220,
      y: 8790
    },{
      NanogenreId: 2058,
      x: 10144,
      y: 4803
    },{
      NanogenreId: 2059,
      x: 7042,
      y: 11802
    },{
      NanogenreId: 2060,
      x: 4457,
      y: 9578
    },{
      NanogenreId: 2061,
      x: 4117,
      y: 6966
    },{
      NanogenreId: 2062,
      x: 11299,
      y: 13119
    },{
      NanogenreId: 2063,
      x: 10138,
      y: 5519
    },{
      NanogenreId: 2064,
      x: 13043,
      y: 9585
    },{
      NanogenreId: 2065,
      x: 13392,
      y: 10579
    },{
      NanogenreId: 2066,
      x: 11172,
      y: 13435
    },{
      NanogenreId: 2067,
      x: 7191,
      y: 12355
    },{
      NanogenreId: 2068,
      x: 10407,
      y: 4429
    },{
      NanogenreId: 2069,
      x: 10222,
      y: 4444
    },{
      NanogenreId: 2070,
      x: 13692,
      y: 8023
    },{
      NanogenreId: 2071,
      x: 8295,
      y: 3116
    },{
      NanogenreId: 2072,
      x: 14143,
      y: 6764
    },{
      NanogenreId: 2073,
      x: 5949,
      y: 4144
    },{
      NanogenreId: 2074,
      x: 6520,
      y: 4308
    },{
      NanogenreId: 2075,
      x: 10205,
      y: 6560
    },{
      NanogenreId: 2076,
      x: 10401,
      y: 11133
    },{
      NanogenreId: 2077,
      x: 9955,
      y: 3965
    },{
      NanogenreId: 2078,
      x: 9823,
      y: 4992
    },{
      NanogenreId: 2079,
      x: 10729,
      y: 10286
    },{
      NanogenreId: 2080,
      x: 12438,
      y: 7555
    },{
      NanogenreId: 2081,
      x: 12642,
      y: 10566
    },{
      NanogenreId: 2082,
      x: 8346,
      y: 4341
    },{
      NanogenreId: 2083,
      x: 12993,
      y: 6064
    },{
      NanogenreId: 2084,
      x: 11615,
      y: 9865
    },{
      NanogenreId: 2085,
      x: 12122,
      y: 12083
    },{
      NanogenreId: 2086,
      x: 8509,
      y: 3545
    },{
      NanogenreId: 2087,
      x: 8509,
      y: 3971
    },{
      NanogenreId: 2088,
      x: 11421,
      y: 10702
    },{
      NanogenreId: 2089,
      x: 8584,
      y: 9903
    },{
      NanogenreId: 2090,
      x: 11863,
      y: 6748
    },{
      NanogenreId: 2091,
      x: 10143,
      y: 5166
    },{
      NanogenreId: 2092,
      x: 6974,
      y: 12109
    },{
      NanogenreId: 2093,
      x: 11373,
      y: 10956
    },{
      NanogenreId: 2094,
      x: 9792,
      y: 6084
    },{
      NanogenreId: 2095,
      x: 10578,
      y: 6271
    },{
      NanogenreId: 2096,
      x: 6256,
      y: 3614
    },{
      NanogenreId: 2097,
      x: 13121,
      y: 5991
    },{
      NanogenreId: 2098,
      x: 11465,
      y: 10283
    },{
      NanogenreId: 2099,
      x: 8733,
      y: 3983
    },{
      NanogenreId: 2100,
      x: 6129,
      y: 11423
    },{
      NanogenreId: 2101,
      x: 9770,
      y: 4503
    },{
      NanogenreId: 2102,
      x: 10707,
      y: 11192
    },{
      NanogenreId: 2103,
      x: 5415,
      y: 11753
    },{
      NanogenreId: 2104,
      x: 12104,
      y: 7770
    },{
      NanogenreId: 2105,
      x: 7353,
      y: 4959
    },{
      NanogenreId: 2106,
      x: 11377,
      y: 10735
    },{
      NanogenreId: 2107,
      x: 6492,
      y: 8934
    },{
      NanogenreId: 2108,
      x: 6354,
      y: 3236
    },{
      NanogenreId: 2109,
      x: 10868,
      y: 10086
    },{
      NanogenreId: 2110,
      x: 9791,
      y: 4231
    },{
      NanogenreId: 2111,
      x: 7198,
      y: 10883
    },{
      NanogenreId: 2112,
      x: 4386,
      y: 11679
    },{
      NanogenreId: 2113,
      x: 11350,
      y: 1944
    },{
      NanogenreId: 2114,
      x: 7690,
      y: 10631
    },{
      NanogenreId: 2115,
      x: 12570,
      y: 5465
    },{
      NanogenreId: 2116,
      x: 4656,
      y: 10496
    },{
      NanogenreId: 2117,
      x: 7039,
      y: 9199
    },{
      NanogenreId: 2118,
      x: 9232,
      y: 9913
    },{
      NanogenreId: 2119,
      x: 7379,
      y: 10499
    },{
      NanogenreId: 2120,
      x: 7039,
      y: 11210
    },{
      NanogenreId: 2121,
      x: 4439,
      y: 9097
    },{
      NanogenreId: 2122,
      x: 8728,
      y: 3704
    },{
      NanogenreId: 2123,
      x: 3735,
      y: 10987
    },{
      NanogenreId: 2124,
      x: 3367,
      y: 9057
    },{
      NanogenreId: 2125,
      x: 11004,
      y: 8916
    },{
      NanogenreId: 2126,
      x: 10311,
      y: 5054
    },{
      NanogenreId: 2127,
      x: 7314,
      y: 12399
    },{
      NanogenreId: 2128,
      x: 6922,
      y: 12909
    },{
      NanogenreId: 2129,
      x: 12806,
      y: 2813
    },{
      NanogenreId: 2130,
      x: 11710,
      y: 7071
    },{
      NanogenreId: 2131,
      x: 7858,
      y: 10915
    },{
      NanogenreId: 2132,
      x: 4757,
      y: 7303
    },{
      NanogenreId: 2133,
      x: 11521,
      y: 10237
    },{
      NanogenreId: 2134,
      x: 9106,
      y: 4929
    },{
      NanogenreId: 2135,
      x: 7310,
      y: 3860
    },{
      NanogenreId: 2136,
      x: 9767,
      y: 5559
    },{
      NanogenreId: 2137,
      x: 10545,
      y: 11753
    },{
      NanogenreId: 2138,
      x: 6702,
      y: 6190
    },{
      NanogenreId: 2139,
      x: 3791,
      y: 10231
    },{
      NanogenreId: 2140,
      x: 11056,
      y: 7238
    },{
      NanogenreId: 2141,
      x: 8116,
      y: 4686
    },{
      NanogenreId: 2142,
      x: 6193,
      y: 5132
    },{
      NanogenreId: 2143,
      x: 6572,
      y: 6257
    },{
      NanogenreId: 2144,
      x: 6826,
      y: 10018
    },{
      NanogenreId: 2145,
      x: 9119,
      y: 4582
    },{
      NanogenreId: 2146,
      x: 8672,
      y: 7715
    },{
      NanogenreId: 2147,
      x: 9291,
      y: 4681
    },{
      NanogenreId: 2148,
      x: 8311,
      y: 4656
    },{
      NanogenreId: 2149,
      x: 8220,
      y: 9172
    },{
      NanogenreId: 2150,
      x: 9198,
      y: 10273
    },{
      NanogenreId: 2151,
      x: 5753,
      y: 4860
    },{
      NanogenreId: 2152,
      x: 6696,
      y: 9573
    },{
      NanogenreId: 2153,
      x: 9644,
      y: 5635
    },{
      NanogenreId: 2154,
      x: 6733,
      y: 6061
    },{
      NanogenreId: 2155,
      x: 9630,
      y: 4606
    },{
      NanogenreId: 2156,
      x: 9551,
      y: 4930
    },{
      NanogenreId: 2157,
      x: 7145,
      y: 11738
    },{
      NanogenreId: 2158,
      x: 6905,
      y: 7633
    },{
      NanogenreId: 2159,
      x: 10182,
      y: 5013
    },{
      NanogenreId: 2160,
      x: 4259,
      y: 11084
    },{
      NanogenreId: 2161,
      x: 3739,
      y: 10531
    },{
      NanogenreId: 2162,
      x: 5857,
      y: 7286
    },{
      NanogenreId: 2163,
      x: 11309,
      y: 10774
    },{
      NanogenreId: 2164,
      x: 10598,
      y: 9882
    },{
      NanogenreId: 2165,
      x: 5762,
      y: 2691
    },{
      NanogenreId: 2166,
      x: 9587,
      y: 4856
    },{
      NanogenreId: 2167,
      x: 11798,
      y: 6911
    },{
      NanogenreId: 2168,
      x: 8917,
      y: 9408
    },{
      NanogenreId: 2169,
      x: 6996,
      y: 4303
    },{
      NanogenreId: 2170,
      x: 7220,
      y: 5350
    },{
      NanogenreId: 2171,
      x: 7177,
      y: 3168
    },{
      NanogenreId: 2172,
      x: 4967,
      y: 6889
    },{
      NanogenreId: 2173,
      x: 6498,
      y: 11033
    },{
      NanogenreId: 2174,
      x: 12092,
      y: 8027
    },{
      NanogenreId: 2175,
      x: 11043,
      y: 10355
    },{
      NanogenreId: 2176,
      x: 7318,
      y: 9482
    },{
      NanogenreId: 2177,
      x: 9790,
      y: 10237
    },{
      NanogenreId: 2178,
      x: 11934,
      y: 9884
    },{
      NanogenreId: 2179,
      x: 10827,
      y: 10511
    },{
      NanogenreId: 2180,
      x: 12469,
      y: 8196
    },{
      NanogenreId: 2181,
      x: 9062,
      y: 4540
    },{
      NanogenreId: 2182,
      x: 5549,
      y: 11976
    },{
      NanogenreId: 2183,
      x: 9496,
      y: 10937
    },{
      NanogenreId: 2184,
      x: 10241,
      y: 4829
    },{
      NanogenreId: 2185,
      x: 7769,
      y: 11460
    },{
      NanogenreId: 2186,
      x: 10973,
      y: 2563
    },{
      NanogenreId: 2187,
      x: 9894,
      y: 5375
    },{
      NanogenreId: 2188,
      x: 10475,
      y: 5194
    },{
      NanogenreId: 2189,
      x: 6870,
      y: 11893
    },{
      NanogenreId: 2190,
      x: 8068,
      y: 3784
    },{
      NanogenreId: 2191,
      x: 11572,
      y: 10301
    },{
      NanogenreId: 2192,
      x: 9931,
      y: 4999
    },{
      NanogenreId: 2193,
      x: 9797,
      y: 4528
    },{
      NanogenreId: 2194,
      x: 12112,
      y: 6690
    },{
      NanogenreId: 2195,
      x: 6274,
      y: 13104
    },{
      NanogenreId: 2196,
      x: 6365,
      y: 13002
    },{
      NanogenreId: 2197,
      x: 5212,
      y: 2558
    },{
      NanogenreId: 2198,
      x: 9840,
      y: 6039
    },{
      NanogenreId: 2199,
      x: 11264,
      y: 10435
    },{
      NanogenreId: 2200,
      x: 11302,
      y: 10284
    },{
      NanogenreId: 2201,
      x: 7300,
      y: 6329
    },{
      NanogenreId: 2202,
      x: 6223,
      y: 9840
    },{
      NanogenreId: 2203,
      x: 6781,
      y: 9964
    },{
      NanogenreId: 2204,
      x: 5677,
      y: 5892
    },{
      NanogenreId: 2205,
      x: 11687,
      y: 10037
    },{
      NanogenreId: 2206,
      x: 9574,
      y: 4949
    },{
      NanogenreId: 2207,
      x: 3847,
      y: 9486
    },{
      NanogenreId: 2208,
      x: 8339,
      y: 12696
    },{
      NanogenreId: 2209,
      x: 9538,
      y: 4840
    },{
      NanogenreId: 2210,
      x: 6177,
      y: 10339
    },{
      NanogenreId: 2211,
      x: 11055,
      y: 10790
    },{
      NanogenreId: 2212,
      x: 7205,
      y: 4621
    },{
      NanogenreId: 2213,
      x: 8972,
      y: 3746
    },{
      NanogenreId: 2214,
      x: 6392,
      y: 4997
    },{
      NanogenreId: 2215,
      x: 6619,
      y: 12199
    },{
      NanogenreId: 2216,
      x: 10751,
      y: 7114
    },{
      NanogenreId: 2217,
      x: 11088,
      y: 10727
    },{
      NanogenreId: 2218,
      x: 7661,
      y: 5555
    },{
      NanogenreId: 2219,
      x: 7918,
      y: 4969
    },{
      NanogenreId: 2220,
      x: 8917,
      y: 10852
    },{
      NanogenreId: 2221,
      x: 10021,
      y: 4315
    },{
      NanogenreId: 2222,
      x: 9141,
      y: 4498
    },{
      NanogenreId: 2223,
      x: 6764,
      y: 3758
    },{
      NanogenreId: 2224,
      x: 8124,
      y: 6862
    },{
      NanogenreId: 2225,
      x: 5473,
      y: 3932
    },{
      NanogenreId: 2226,
      x: 10569,
      y: 10949
    },{
      NanogenreId: 2227,
      x: 5448,
      y: 11934
    },{
      NanogenreId: 2228,
      x: 7172,
      y: 11611
    },{
      NanogenreId: 2229,
      x: 8217,
      y: 4588
    },{
      NanogenreId: 2230,
      x: 5811,
      y: 9595
    },{
      NanogenreId: 2231,
      x: 6968,
      y: 11691
    },{
      NanogenreId: 2232,
      x: 4054,
      y: 9594
    },{
      NanogenreId: 2233,
      x: 7169,
      y: 11710
    },{
      NanogenreId: 2234,
      x: 5094,
      y: 2850
    },{
      NanogenreId: 2235,
      x: 6886,
      y: 6305
    },{
      NanogenreId: 2236,
      x: 9345,
      y: 10848
    },{
      NanogenreId: 2237,
      x: 5656,
      y: 5054
    },{
      NanogenreId: 2238,
      x: 9859,
      y: 5210
    },{
      NanogenreId: 2239,
      x: 13229,
      y: 6146
    },{
      NanogenreId: 2240,
      x: 3356,
      y: 9288
    },{
      NanogenreId: 2241,
      x: 6289,
      y: 11361
    },{
      NanogenreId: 2242,
      x: 9425,
      y: 10339
    },{
      NanogenreId: 2243,
      x: 7121,
      y: 11700
    },{
      NanogenreId: 2244,
      x: 5579,
      y: 8564
    },{
      NanogenreId: 2245,
      x: 11272,
      y: 10125
    },{
      NanogenreId: 2246,
      x: 5653,
      y: 5981
    },{
      NanogenreId: 2247,
      x: 10881,
      y: 9874
    },{
      NanogenreId: 2248,
      x: 4299,
      y: 7069
    },{
      NanogenreId: 2249,
      x: 8354,
      y: 11843
    },{
      NanogenreId: 2250,
      x: 7624,
      y: 12070
    },{
      NanogenreId: 2251,
      x: 4926,
      y: 8930
    },{
      NanogenreId: 2252,
      x: 9171,
      y: 10543
    },{
      NanogenreId: 2253,
      x: 5458,
      y: 9919
    },{
      NanogenreId: 2254,
      x: 10575,
      y: 10555
    },{
      NanogenreId: 2255,
      x: 11957,
      y: 7866
    },{
      NanogenreId: 2256,
      x: 3826,
      y: 7775
    },{
      NanogenreId: 2257,
      x: 7493,
      y: 10955
    },{
      NanogenreId: 2258,
      x: 7410,
      y: 3780
    },{
      NanogenreId: 2259,
      x: 13425,
      y: 8155
    },{
      NanogenreId: 2260,
      x: 10530,
      y: 7884
    },{
      NanogenreId: 2261,
      x: 6720,
      y: 3660
    },{
      NanogenreId: 2262,
      x: 10259,
      y: 4707
    },{
      NanogenreId: 2263,
      x: 6918,
      y: 5765
    },{
      NanogenreId: 2264,
      x: 11133,
      y: 6356
    },{
      NanogenreId: 2265,
      x: 6063,
      y: 6552
    },{
      NanogenreId: 2266,
      x: 6608,
      y: 8407
    },{
      NanogenreId: 2267,
      x: 7508,
      y: 10388
    },{
      NanogenreId: 2268,
      x: 11120,
      y: 10761
    },{
      NanogenreId: 2269,
      x: 7449,
      y: 11380
    },{
      NanogenreId: 2270,
      x: 10127,
      y: 4695
    },{
      NanogenreId: 2271,
      x: 9517,
      y: 7356
    },{
      NanogenreId: 2272,
      x: 11293,
      y: 10365
    },{
      NanogenreId: 2273,
      x: 5871,
      y: 6284
    },{
      NanogenreId: 2274,
      x: 7427,
      y: 10844
    },{
      NanogenreId: 2275,
      x: 7628,
      y: 8696
    },{
      NanogenreId: 2276,
      x: 5935,
      y: 5820
    },{
      NanogenreId: 2277,
      x: 9924,
      y: 4617
    },{
      NanogenreId: 2278,
      x: 9364,
      y: 6419
    },{
      NanogenreId: 2279,
      x: 9619,
      y: 7655
    },{
      NanogenreId: 2280,
      x: 10865,
      y: 5868
    },{
      NanogenreId: 2281,
      x: 6633,
      y: 4198
    },{
      NanogenreId: 2282,
      x: 5859,
      y: 10238
    },{
      NanogenreId: 2283,
      x: 3904,
      y: 9996
    },{
      NanogenreId: 2284,
      x: 6830,
      y: 9855
    },{
      NanogenreId: 2285,
      x: 8973,
      y: 4603
    },{
      NanogenreId: 2286,
      x: 8137,
      y: 12072
    },{
      NanogenreId: 2287,
      x: 9637,
      y: 6122
    },{
      NanogenreId: 2288,
      x: 5846,
      y: 8815
    },{
      NanogenreId: 2289,
      x: 7342,
      y: 11378
    },{
      NanogenreId: 2290,
      x: 13058,
      y: 8341
    },{
      NanogenreId: 2291,
      x: 7629,
      y: 12478
    },{
      NanogenreId: 2292,
      x: 11326,
      y: 10176
    },{
      NanogenreId: 2293,
      x: 11255,
      y: 7797
    },{
      NanogenreId: 2294,
      x: 8336,
      y: 5819
    },{
      NanogenreId: 2295,
      x: 10155,
      y: 10670
    },{
      NanogenreId: 2296,
      x: 6509,
      y: 5241
    },{
      NanogenreId: 2297,
      x: 6671,
      y: 3970
    },{
      NanogenreId: 2298,
      x: 10755,
      y: 5777
    },{
      NanogenreId: 2299,
      x: 10576,
      y: 10777
    },{
      NanogenreId: 2300,
      x: 9395,
      y: 4743
    },{
      NanogenreId: 2301,
      x: 7210,
      y: 12033
    },{
      NanogenreId: 2302,
      x: 6247,
      y: 6227
    },{
      NanogenreId: 2303,
      x: 9185,
      y: 10408
    },{
      NanogenreId: 2304,
      x: 7304,
      y: 3567
    },{
      NanogenreId: 2305,
      x: 7030,
      y: 11165
    },{
      NanogenreId: 2306,
      x: 11686,
      y: 11359
    },{
      NanogenreId: 2307,
      x: 6823,
      y: 11160
    },{
      NanogenreId: 2308,
      x: 9048,
      y: 5316
    },{
      NanogenreId: 2309,
      x: 10204,
      y: 8352
    },{
      NanogenreId: 2310,
      x: 9699,
      y: 4620
    },{
      NanogenreId: 2311,
      x: 8090,
      y: 7643
    },{
      NanogenreId: 2312,
      x: 8516,
      y: 10891
    },{
      NanogenreId: 2313,
      x: 5931,
      y: 11273
    },{
      NanogenreId: 2314,
      x: 11235,
      y: 10324
    },{
      NanogenreId: 2315,
      x: 6312,
      y: 5877
    },{
      NanogenreId: 2316,
      x: 6971,
      y: 4903
    },{
      NanogenreId: 2317,
      x: 7283,
      y: 10699
    },{
      NanogenreId: 2318,
      x: 9128,
      y: 5474
    },{
      NanogenreId: 2319,
      x: 11612,
      y: 9593
    },{
      NanogenreId: 2320,
      x: 9809,
      y: 5066
    },{
      NanogenreId: 2321,
      x: 10326,
      y: 4734
    },{
      NanogenreId: 2322,
      x: 12570,
      y: 4904
    },{
      NanogenreId: 2323,
      x: 6885,
      y: 9794
    },{
      NanogenreId: 2324,
      x: 6443,
      y: 6901
    },{
      NanogenreId: 2325,
      x: 9444,
      y: 5658
    },{
      NanogenreId: 2326,
      x: 8532,
      y: 8285
    },{
      NanogenreId: 2327,
      x: 8499,
      y: 4978
    },{
      NanogenreId: 2328,
      x: 7687,
      y: 9993
    },{
      NanogenreId: 2329,
      x: 12406,
      y: 10727
    },{
      NanogenreId: 2330,
      x: 5473,
      y: 6230
    },{
      NanogenreId: 2331,
      x: 11021,
      y: 10194
    },{
      NanogenreId: 2332,
      x: 8576,
      y: 11574
    },{
      NanogenreId: 2333,
      x: 8798,
      y: 4641
    },{
      NanogenreId: 2334,
      x: 7447,
      y: 7958
    },{
      NanogenreId: 2335,
      x: 7807,
      y: 5581
    },{
      NanogenreId: 2336,
      x: 7485,
      y: 10783
    },{
      NanogenreId: 2337,
      x: 10988,
      y: 11041
    },{
      NanogenreId: 2338,
      x: 9020,
      y: 7821
    },{
      NanogenreId: 2339,
      x: 9094,
      y: 7672
    },{
      NanogenreId: 2340,
      x: 10784,
      y: 8651
    },{
      NanogenreId: 2341,
      x: 10871,
      y: 9984
    },{
      NanogenreId: 2342,
      x: 8127,
      y: 6407
    },{
      NanogenreId: 2343,
      x: 8794,
      y: 8601
    },{
      NanogenreId: 2344,
      x: 9283,
      y: 7900
    },{
      NanogenreId: 2345,
      x: 8822,
      y: 4699
    },{
      NanogenreId: 2346,
      x: 9814,
      y: 4645
    },{
      NanogenreId: 2347,
      x: 3489,
      y: 8644
    },{
      NanogenreId: 2348,
      x: 10817,
      y: 9656
    },{
      NanogenreId: 2349,
      x: 4613,
      y: 5320
    },{
      NanogenreId: 2350,
      x: 11143,
      y: 8227
    },{
      NanogenreId: 2351,
      x: 9402,
      y: 1941
    },{
      NanogenreId: 2352,
      x: 9683,
      y: 6393
    },{
      NanogenreId: 2353,
      x: 11446,
      y: 9928
    },{
      NanogenreId: 2354,
      x: 7376,
      y: 6671
    },{
      NanogenreId: 2355,
      x: 9279,
      y: 8616
    },{
      NanogenreId: 2356,
      x: 10254,
      y: 9426
    },{
      NanogenreId: 2357,
      x: 9671,
      y: 4693
    },{
      NanogenreId: 2358,
      x: 8239,
      y: 5063
    },{
      NanogenreId: 2359,
      x: 7026,
      y: 11889
    },{
      NanogenreId: 2360,
      x: 8851,
      y: 11698
    },{
      NanogenreId: 2361,
      x: 9746,
      y: 9908
    },{
      NanogenreId: 2362,
      x: 9925,
      y: 4932
    },{
      NanogenreId: 2363,
      x: 11092,
      y: 10251
    },{
      NanogenreId: 2364,
      x: 7912,
      y: 4685
    },{
      NanogenreId: 2365,
      x: 9963,
      y: 12559
    },{
      NanogenreId: 2366,
      x: 11365,
      y: 8466
    },{
      NanogenreId: 2367,
      x: 8708,
      y: 5389
    },{
      NanogenreId: 2368,
      x: 9188,
      y: 5027
    },{
      NanogenreId: 2369,
      x: 12118,
      y: 9362
    },{
      NanogenreId: 2370,
      x: 5553,
      y: 7690
    },{
      NanogenreId: 2371,
      x: 6187,
      y: 12531
    },{
      NanogenreId: 2372,
      x: 5517,
      y: 5991
    },{
      NanogenreId: 2373,
      x: 8146,
      y: 6557
    },{
      NanogenreId: 2374,
      x: 11428,
      y: 10019
    },{
      NanogenreId: 2375,
      x: 5753,
      y: 4615
    },{
      NanogenreId: 2376,
      x: 4935,
      y: 6983
    },{
      NanogenreId: 2377,
      x: 10808,
      y: 10368
    },{
      NanogenreId: 2378,
      x: 10508,
      y: 10534
    },{
      NanogenreId: 2379,
      x: 7374,
      y: 10906
    },{
      NanogenreId: 2380,
      x: 11637,
      y: 6931
    },{
      NanogenreId: 2381,
      x: 5594,
      y: 10551
    },{
      NanogenreId: 2382,
      x: 7949,
      y: 12074
    },{
      NanogenreId: 2383,
      x: 7984,
      y: 9894
    },{
      NanogenreId: 2384,
      x: 4406,
      y: 8701
    },{
      NanogenreId: 2385,
      x: 6522,
      y: 10117
    },{
      NanogenreId: 2386,
      x: 9802,
      y: 4775
    },{
      NanogenreId: 2387,
      x: 10037,
      y: 5096
    },{
      NanogenreId: 2388,
      x: 5855,
      y: 5541
    },{
      NanogenreId: 2389,
      x: 6524,
      y: 2105
    },{
      NanogenreId: 2390,
      x: 10463,
      y: 10865
    },{
      NanogenreId: 2391,
      x: 7765,
      y: 8316
    },{
      NanogenreId: 2392,
      x: 9151,
      y: 5081
    },{
      NanogenreId: 2393,
      x: 9066,
      y: 5978
    },{
      NanogenreId: 2394,
      x: 7423,
      y: 10372
    },{
      NanogenreId: 2395,
      x: 7013,
      y: 10524
    },{
      NanogenreId: 2396,
      x: 7086,
      y: 8566
    },{
      NanogenreId: 2397,
      x: 9416,
      y: 10128
    },{
      NanogenreId: 2398,
      x: 5329,
      y: 6944
    },{
      NanogenreId: 2399,
      x: 10761,
      y: 5284
    },{
      NanogenreId: 2400,
      x: 9326,
      y: 4685
    },{
      NanogenreId: 2401,
      x: 7033,
      y: 6101
    },{
      NanogenreId: 2402,
      x: 10904,
      y: 10345
    },{
      NanogenreId: 2403,
      x: 11239,
      y: 10499
    },{
      NanogenreId: 2404,
      x: 6571,
      y: 4962
    },{
      NanogenreId: 2405,
      x: 9139,
      y: 4617
    },{
      NanogenreId: 2406,
      x: 9377,
      y: 3586
    },{
      NanogenreId: 2407,
      x: 8078,
      y: 6187
    },{
      NanogenreId: 2408,
      x: 9327,
      y: 10576
    },{
      NanogenreId: 2409,
      x: 5932,
      y: 10784
    },{
      NanogenreId: 2410,
      x: 10959,
      y: 7962
    },{
      NanogenreId: 2411,
      x: 7222,
      y: 6873
    },{
      NanogenreId: 2412,
      x: 4225,
      y: 6177
    },{
      NanogenreId: 2413,
      x: 7874,
      y: 9734
    },{
      NanogenreId: 2414,
      x: 11435,
      y: 9790
    },{
      NanogenreId: 2415,
      x: 13141,
      y: 6186
    },{
      NanogenreId: 2416,
      x: 9587,
      y: 10415
    },{
      NanogenreId: 2417,
      x: 7653,
      y: 7666
    },{
      NanogenreId: 2418,
      x: 3240,
      y: 9939
    },{
      NanogenreId: 2419,
      x: 8001,
      y: 11595
    },{
      NanogenreId: 2420,
      x: 11516,
      y: 10854
    },{
      NanogenreId: 2421,
      x: 2876,
      y: 8313
    },{
      NanogenreId: 2422,
      x: 9281,
      y: 8808
    },{
      NanogenreId: 2423,
      x: 9485,
      y: 5437
    },{
      NanogenreId: 2424,
      x: 9097,
      y: 4995
    },{
      NanogenreId: 2425,
      x: 4448,
      y: 10303
    },{
      NanogenreId: 2426,
      x: 9073,
      y: 12276
    },{
      NanogenreId: 2427,
      x: 7440,
      y: 10713
    },{
      NanogenreId: 2428,
      x: 7733,
      y: 6580
    },{
      NanogenreId: 2429,
      x: 10065,
      y: 6052
    },{
      NanogenreId: 2430,
      x: 7467,
      y: 9776
    },{
      NanogenreId: 2431,
      x: 3716,
      y: 7855
    },{
      NanogenreId: 2432,
      x: 6372,
      y: 6842
    },{
      NanogenreId: 2433,
      x: 11387,
      y: 10274
    },{
      NanogenreId: 2434,
      x: 5659,
      y: 10388
    },{
      NanogenreId: 2435,
      x: 12984,
      y: 6402
    },{
      NanogenreId: 2436,
      x: 6691,
      y: 4616
    },{
      NanogenreId: 2437,
      x: 6041,
      y: 5905
    },{
      NanogenreId: 2438,
      x: 10010,
      y: 4535
    },{
      NanogenreId: 2439,
      x: 12763,
      y: 4174
    },{
      NanogenreId: 2440,
      x: 9137,
      y: 7384
    },{
      NanogenreId: 2441,
      x: 10777,
      y: 10065
    },{
      NanogenreId: 2442,
      x: 7429,
      y: 3933
    },{
      NanogenreId: 2443,
      x: 6686,
      y: 9127
    },{
      NanogenreId: 2444,
      x: 9795,
      y: 5309
    },{
      NanogenreId: 2445,
      x: 10809,
      y: 10303
    },{
      NanogenreId: 2446,
      x: 3258,
      y: 6566
    },{
      NanogenreId: 2447,
      x: 6644,
      y: 10081
    },{
      NanogenreId: 2448,
      x: 10277,
      y: 5705
    },{
      NanogenreId: 2449,
      x: 9906,
      y: 11436
    },{
      NanogenreId: 2450,
      x: 9447,
      y: 9742
    },{
      NanogenreId: 2451,
      x: 7712,
      y: 3292
    },{
      NanogenreId: 2452,
      x: 6380,
      y: 5067
    },{
      NanogenreId: 2453,
      x: 7906,
      y: 6339
    },{
      NanogenreId: 2454,
      x: 10098,
      y: 7968
    },{
      NanogenreId: 2455,
      x: 8961,
      y: 6033
    },{
      NanogenreId: 2456,
      x: 6662,
      y: 9698
    },{
      NanogenreId: 2457,
      x: 6868,
      y: 7226
    },{
      NanogenreId: 2458,
      x: 8775,
      y: 10341
    },{
      NanogenreId: 2459,
      x: 9822,
      y: 4611
    },{
      NanogenreId: 2460,
      x: 3739,
      y: 3428
    },{
      NanogenreId: 2461,
      x: 7558,
      y: 3862
    },{
      NanogenreId: 2462,
      x: 7098,
      y: 5323
    },{
      NanogenreId: 2463,
      x: 8546,
      y: 8773
    },{
      NanogenreId: 2464,
      x: 5368,
      y: 6271
    },{
      NanogenreId: 2465,
      x: 5791,
      y: 11847
    },{
      NanogenreId: 2466,
      x: 7416,
      y: 10158
    },{
      NanogenreId: 2467,
      x: 8823,
      y: 4941
    },{
      NanogenreId: 2468,
      x: 5438,
      y: 13335
    },{
      NanogenreId: 2469,
      x: 6526,
      y: 5821
    },{
      NanogenreId: 2470,
      x: 6189,
      y: 3369
    },{
      NanogenreId: 2471,
      x: 4408,
      y: 11984
    },{
      NanogenreId: 2472,
      x: 8621,
      y: 4754
    },{
      NanogenreId: 2473,
      x: 5303,
      y: 4457
    },{
      NanogenreId: 2474,
      x: 9291,
      y: 5438
    },{
      NanogenreId: 2475,
      x: 8475,
      y: 8535
    },{
      NanogenreId: 2476,
      x: 8462,
      y: 6141
    },{
      NanogenreId: 2477,
      x: 7041,
      y: 7470
    },{
      NanogenreId: 2478,
      x: 7074,
      y: 11158
    },{
      NanogenreId: 2479,
      x: 8486,
      y: 8831
    },{
      NanogenreId: 2480,
      x: 7075,
      y: 7857
    },{
      NanogenreId: 2481,
      x: 9758,
      y: 4560
    },{
      NanogenreId: 2482,
      x: 5536,
      y: 9200
    },{
      NanogenreId: 2483,
      x: 8528,
      y: 2987
    },{
      NanogenreId: 2484,
      x: 11051,
      y: 9922
    },{
      NanogenreId: 2485,
      x: 5149,
      y: 6799
    },{
      NanogenreId: 2486,
      x: 3547,
      y: 7463
    },{
      NanogenreId: 2487,
      x: 6741,
      y: 3558
    },{
      NanogenreId: 2488,
      x: 6534,
      y: 5721
    },{
      NanogenreId: 2489,
      x: 8930,
      y: 11073
    },{
      NanogenreId: 2490,
      x: 10270,
      y: 9165
    },{
      NanogenreId: 2491,
      x: 6941,
      y: 6321
    },{
      NanogenreId: 2492,
      x: 9600,
      y: 4563
    },{
      NanogenreId: 2493,
      x: 10142,
      y: 11426
    },{
      NanogenreId: 2494,
      x: 7114,
      y: 11105
    },{
      NanogenreId: 2495,
      x: 8675,
      y: 5363
    },{
      NanogenreId: 2496,
      x: 9921,
      y: 4632
    },{
      NanogenreId: 2497,
      x: 5857,
      y: 7353
    },{
      NanogenreId: 2498,
      x: 7009,
      y: 10117
    },{
      NanogenreId: 2499,
      x: 10030,
      y: 5617
    },{
      NanogenreId: 2500,
      x: 10079,
      y: 4466
    },{
      NanogenreId: 2501,
      x: 9258,
      y: 7671
    },{
      NanogenreId: 2502,
      x: 9839,
      y: 6871
    },{
      NanogenreId: 2503,
      x: 6720,
      y: 6986
    },{
      NanogenreId: 2504,
      x: 3272,
      y: 9635
    },{
      NanogenreId: 2505,
      x: 10028,
      y: 4685
    },{
      NanogenreId: 2506,
      x: 9968,
      y: 4729
    },{
      NanogenreId: 2507,
      x: 11830,
      y: 9493
    },{
      NanogenreId: 2508,
      x: 7012,
      y: 11314
    },{
      NanogenreId: 2509,
      x: 10511,
      y: 2068
    },{
      NanogenreId: 2510,
      x: 12032,
      y: 9460
    },{
      NanogenreId: 2511,
      x: 6272,
      y: 10000
    },{
      NanogenreId: 2512,
      x: 8217,
      y: 5025
    },{
      NanogenreId: 2513,
      x: 10550,
      y: 10127
    },{
      NanogenreId: 2514,
      x: 8281,
      y: 7852
    },{
      NanogenreId: 2515,
      x: 5657,
      y: 12194
    },{
      NanogenreId: 2516,
      x: 7757,
      y: 8043
    },{
      NanogenreId: 2517,
      x: 6560,
      y: 6534
    },{
      NanogenreId: 2518,
      x: 3755,
      y: 10280
    },{
      NanogenreId: 2519,
      x: 9377,
      y: 4917
    },{
      NanogenreId: 2520,
      x: 8011,
      y: 8169
    },{
      NanogenreId: 2521,
      x: 11083,
      y: 10685
    },{
      NanogenreId: 2522,
      x: 9571,
      y: 5251
    },{
      NanogenreId: 2523,
      x: 7957,
      y: 5438
    },{
      NanogenreId: 2524,
      x: 7674,
      y: 4564
    },{
      NanogenreId: 2525,
      x: 13359,
      y: 6680
    },{
      NanogenreId: 2526,
      x: 5684,
      y: 8551
    },{
      NanogenreId: 2527,
      x: 8778,
      y: 5350
    },{
      NanogenreId: 2528,
      x: 5379,
      y: 12074
    },{
      NanogenreId: 2529,
      x: 10025,
      y: 10241
    },{
      NanogenreId: 2530,
      x: 7722,
      y: 9718
    },{
      NanogenreId: 2531,
      x: 10734,
      y: 9913
    },{
      NanogenreId: 2532,
      x: 8514,
      y: 5041
    },{
      NanogenreId: 2533,
      x: 7747,
      y: 10602
    },{
      NanogenreId: 2534,
      x: 7012,
      y: 8579
    },{
      NanogenreId: 2535,
      x: 10452,
      y: 9982
    },{
      NanogenreId: 2536,
      x: 9819,
      y: 4667
    },{
      NanogenreId: 2537,
      x: 10534,
      y: 11398
    },{
      NanogenreId: 2538,
      x: 6331,
      y: 4931
    },{
      NanogenreId: 2539,
      x: 10534,
      y: 7749
    },{
      NanogenreId: 2540,
      x: 8597,
      y: 7049
    },{
      NanogenreId: 2541,
      x: 5967,
      y: 2292
    },{
      NanogenreId: 2542,
      x: 9562,
      y: 11742
    },{
      NanogenreId: 2543,
      x: 10283,
      y: 4650
    },{
      NanogenreId: 2544,
      x: 6823,
      y: 4467
    },{
      NanogenreId: 2545,
      x: 8631,
      y: 7240
    },{
      NanogenreId: 2546,
      x: 7651,
      y: 4864
    },{
      NanogenreId: 2547,
      x: 8827,
      y: 7431
    },{
      NanogenreId: 2548,
      x: 6453,
      y: 5276
    },{
      NanogenreId: 2549,
      x: 7303,
      y: 5214
    },{
      NanogenreId: 2550,
      x: 8024,
      y: 6029
    },{
      NanogenreId: 2551,
      x: 6207,
      y: 6571
    },{
      NanogenreId: 2552,
      x: 7691,
      y: 7496
    },{
      NanogenreId: 2553,
      x: 6813,
      y: 10872
    },{
      NanogenreId: 2554,
      x: 7675,
      y: 10072
    },{
      NanogenreId: 2555,
      x: 10228,
      y: 10020
    },{
      NanogenreId: 2556,
      x: 4142,
      y: 8230
    },{
      NanogenreId: 2557,
      x: 6412,
      y: 6319
    },{
      NanogenreId: 2558,
      x: 7885,
      y: 6085
    },{
      NanogenreId: 2559,
      x: 4188,
      y: 11589
    },{
      NanogenreId: 2560,
      x: 10894,
      y: 10572
    },{
      NanogenreId: 2561,
      x: 9981,
      y: 9197
    },{
      NanogenreId: 2562,
      x: 8260,
      y: 6319
    },{
      NanogenreId: 2563,
      x: 9544,
      y: 4401
    },{
      NanogenreId: 2564,
      x: 8927,
      y: 10362
    },{
      NanogenreId: 2565,
      x: 7932,
      y: 5950
    },{
      NanogenreId: 2566,
      x: 9880,
      y: 4893
    },{
      NanogenreId: 2567,
      x: 4890,
      y: 9834
    },{
      NanogenreId: 2568,
      x: 6577,
      y: 8192
    },{
      NanogenreId: 2569,
      x: 10688,
      y: 5702
    },{
      NanogenreId: 2570,
      x: 3909,
      y: 7827
    },{
      NanogenreId: 2571,
      x: 10388,
      y: 4931
    },{
      NanogenreId: 2572,
      x: 8796,
      y: 8031
    },{
      NanogenreId: 2573,
      x: 10678,
      y: 10003
    },{
      NanogenreId: 2574,
      x: 11189,
      y: 10631
    },{
      NanogenreId: 2575,
      x: 9793,
      y: 8699
    },{
      NanogenreId: 2576,
      x: 9250,
      y: 8976
    },{
      NanogenreId: 2577,
      x: 8410,
      y: 4719
    },{
      NanogenreId: 2578,
      x: 6024,
      y: 9961
    },{
      NanogenreId: 2579,
      x: 7653,
      y: 7855
    },{
      NanogenreId: 2580,
      x: 8283,
      y: 4960
    },{
      NanogenreId: 2581,
      x: 4496,
      y: 6653
    },{
      NanogenreId: 2582,
      x: 9957,
      y: 11092
    },{
      NanogenreId: 2583,
      x: 7043,
      y: 6900
    },{
      NanogenreId: 2584,
      x: 9909,
      y: 5096
    },{
      NanogenreId: 2585,
      x: 7862,
      y: 8990
    },{
      NanogenreId: 2586,
      x: 11532,
      y: 8301
    },{
      NanogenreId: 2587,
      x: 4370,
      y: 7303
    },{
      NanogenreId: 2588,
      x: 11403,
      y: 10372
    },{
      NanogenreId: 2589,
      x: 8634,
      y: 4676
    },{
      NanogenreId: 2590,
      x: 7936,
      y: 7505
    },{
      NanogenreId: 2591,
      x: 6754,
      y: 10140
    },{
      NanogenreId: 2592,
      x: 7384,
      y: 8107
    },{
      NanogenreId: 2593,
      x: 9553,
      y: 9577
    },{
      NanogenreId: 2594,
      x: 5692,
      y: 7654
    },{
      NanogenreId: 2595,
      x: 4482,
      y: 6454
    },{
      NanogenreId: 2596,
      x: 5049,
      y: 6472
    },{
      NanogenreId: 2597,
      x: 8433,
      y: 6458
    },{
      NanogenreId: 2598,
      x: 10718,
      y: 8121
    },{
      NanogenreId: 2599,
      x: 7131,
      y: 5728
    },{
      NanogenreId: 2600,
      x: 8028,
      y: 7581
    },{
      NanogenreId: 2601,
      x: 8682,
      y: 6009
    },{
      NanogenreId: 2602,
      x: 6538,
      y: 8040
    },{
      NanogenreId: 2603,
      x: 10782,
      y: 10318
    },{
      NanogenreId: 2604,
      x: 7687,
      y: 12634
    },{
      NanogenreId: 2605,
      x: 12760,
      y: 8459
    },{
      NanogenreId: 2606,
      x: 8901,
      y: 5991
    },{
      NanogenreId: 2607,
      x: 9774,
      y: 4897
    },{
      NanogenreId: 2608,
      x: 8175,
      y: 5146
    },{
      NanogenreId: 2609,
      x: 8042,
      y: 6580
    },{
      NanogenreId: 2610,
      x: 9873,
      y: 5073
    },{
      NanogenreId: 2611,
      x: 6979,
      y: 10050
    },{
      NanogenreId: 2612,
      x: 9983,
      y: 10943
    },{
      NanogenreId: 2613,
      x: 13235,
      y: 8166
    },{
      NanogenreId: 2614,
      x: 6236,
      y: 8838
    },{
      NanogenreId: 2615,
      x: 6834,
      y: 6302
    },{
      NanogenreId: 2616,
      x: 8736,
      y: 8092
    },{
      NanogenreId: 2617,
      x: 10530,
      y: 6802
    },{
      NanogenreId: 2618,
      x: 9299,
      y: 4825
    },{
      NanogenreId: 2619,
      x: 6638,
      y: 7681
    },{
      NanogenreId: 2620,
      x: 7372,
      y: 6123
    },{
      NanogenreId: 2621,
      x: 7932,
      y: 7680
    },{
      NanogenreId: 2622,
      x: 9084,
      y: 9695
    },{
      NanogenreId: 2623,
      x: 10574,
      y: 10428
    },{
      NanogenreId: 2624,
      x: 9634,
      y: 5839
    },{
      NanogenreId: 2625,
      x: 9061,
      y: 4973
    },{
      NanogenreId: 2626,
      x: 3675,
      y: 4556
    },{
      NanogenreId: 2627,
      x: 12650,
      y: 7739
    },{
      NanogenreId: 2628,
      x: 8660,
      y: 9612
    },{
      NanogenreId: 2629,
      x: 6574,
      y: 11350
    },{
      NanogenreId: 2630,
      x: 6536,
      y: 6946
    },{
      NanogenreId: 2631,
      x: 9945,
      y: 7919
    },{
      NanogenreId: 2632,
      x: 6705,
      y: 9006
    },{
      NanogenreId: 2633,
      x: 10100,
      y: 5478
    },{
      NanogenreId: 2634,
      x: 9397,
      y: 4657
    },{
      NanogenreId: 2635,
      x: 10484,
      y: 9566
    },{
      NanogenreId: 2636,
      x: 3068,
      y: 8179
    },{
      NanogenreId: 2637,
      x: 9829,
      y: 4435
    },{
      NanogenreId: 2638,
      x: 5817,
      y: 5784
    },{
      NanogenreId: 2639,
      x: 10168,
      y: 6016
    },{
      NanogenreId: 2640,
      x: 9524,
      y: 4758
    },{
      NanogenreId: 2641,
      x: 6448,
      y: 9883
    },{
      NanogenreId: 2642,
      x: 5330,
      y: 6853
    },{
      NanogenreId: 2643,
      x: 8869,
      y: 9951
    },{
      NanogenreId: 2644,
      x: 5784,
      y: 5522
    },{
      NanogenreId: 2645,
      x: 10326,
      y: 4996
    },{
      NanogenreId: 2646,
      x: 4160,
      y: 12082
    },{
      NanogenreId: 2647,
      x: 10328,
      y: 4281
    },{
      NanogenreId: 2648,
      x: 7763,
      y: 8088
    },{
      NanogenreId: 2649,
      x: 5164,
      y: 10243
    },{
      NanogenreId: 2650,
      x: 3964,
      y: 9033
    },{
      NanogenreId: 2651,
      x: 9691,
      y: 6529
    },{
      NanogenreId: 2652,
      x: 9838,
      y: 10518
    },{
      NanogenreId: 2653,
      x: 8598,
      y: 5891
    },{
      NanogenreId: 2654,
      x: 9965,
      y: 5469
    },{
      NanogenreId: 2655,
      x: 8048,
      y: 11178
    },{
      NanogenreId: 2656,
      x: 8550,
      y: 8335
    },{
      NanogenreId: 2657,
      x: 10191,
      y: 9750
    },{
      NanogenreId: 2658,
      x: 10135,
      y: 8777
    },{
      NanogenreId: 2659,
      x: 6120,
      y: 6477
    },{
      NanogenreId: 2660,
      x: 9033,
      y: 9600
    },{
      NanogenreId: 2661,
      x: 9844,
      y: 10971
    },{
      NanogenreId: 2662,
      x: 9814,
      y: 4841
    },{
      NanogenreId: 2663,
      x: 6070,
      y: 10858
    },{
      NanogenreId: 2664,
      x: 7863,
      y: 5004
    },{
      NanogenreId: 2665,
      x: 8743,
      y: 7689
    },{
      NanogenreId: 2666,
      x: 6581,
      y: 5327
    },{
      NanogenreId: 2667,
      x: 9566,
      y: 7093
    },{
      NanogenreId: 2668,
      x: 6630,
      y: 5224
    },{
      NanogenreId: 2669,
      x: 10402,
      y: 9899
    },{
      NanogenreId: 2670,
      x: 9069,
      y: 8860
    },{
      NanogenreId: 2671,
      x: 10564,
      y: 9166
    },{
      NanogenreId: 2672,
      x: 5585,
      y: 5647
    },{
      NanogenreId: 2673,
      x: 7930,
      y: 6598
    },{
      NanogenreId: 2674,
      x: 9877,
      y: 4742
    },{
      NanogenreId: 2675,
      x: 8827,
      y: 6089
    },{
      NanogenreId: 2676,
      x: 7534,
      y: 10536
    },{
      NanogenreId: 2677,
      x: 9959,
      y: 4460
    },{
      NanogenreId: 2678,
      x: 9501,
      y: 9891
    },{
      NanogenreId: 2679,
      x: 5561,
      y: 5931
    },{
      NanogenreId: 2680,
      x: 8244,
      y: 4053
    },{
      NanogenreId: 2681,
      x: 5484,
      y: 6716
    },{
      NanogenreId: 2682,
      x: 9859,
      y: 4944
    },{
      NanogenreId: 2683,
      x: 8735,
      y: 8456
    },{
      NanogenreId: 2684,
      x: 6336,
      y: 5192
    },{
      NanogenreId: 2685,
      x: 7011,
      y: 5439
    },{
      NanogenreId: 2686,
      x: 5197,
      y: 9775
    },{
      NanogenreId: 2687,
      x: 10174,
      y: 4762
    },{
      NanogenreId: 2688,
      x: 7658,
      y: 8171
    },{
      NanogenreId: 2689,
      x: 11660,
      y: 8041
    },{
      NanogenreId: 2690,
      x: 9735,
      y: 4770
    },{
      NanogenreId: 2691,
      x: 6732,
      y: 7975
    },{
      NanogenreId: 2692,
      x: 9453,
      y: 4855
    },{
      NanogenreId: 2693,
      x: 9491,
      y: 4671
    },{
      NanogenreId: 2694,
      x: 9689,
      y: 7592
    },{
      NanogenreId: 2695,
      x: 7384,
      y: 6607
    },{
      NanogenreId: 2696,
      x: 8816,
      y: 5738
    },{
      NanogenreId: 2697,
      x: 9832,
      y: 5008
    },{
      NanogenreId: 2698,
      x: 6953,
      y: 10465
    },{
      NanogenreId: 2699,
      x: 9800,
      y: 10317
    },{
      NanogenreId: 2700,
      x: 10903,
      y: 2184
    },{
      NanogenreId: 2701,
      x: 5750,
      y: 6518
    },{
      NanogenreId: 2702,
      x: 6948,
      y: 9190
    },{
      NanogenreId: 2703,
      x: 3248,
      y: 7860
    },{
      NanogenreId: 2704,
      x: 4715,
      y: 9603
    },{
      NanogenreId: 2705,
      x: 9560,
      y: 4537
    },{
      NanogenreId: 2706,
      x: 7593,
      y: 5612
    },{
      NanogenreId: 2707,
      x: 5936,
      y: 5754
    },{
      NanogenreId: 2708,
      x: 12076,
      y: 6488
    },{
      NanogenreId: 2709,
      x: 12183,
      y: 7417
    },{
      NanogenreId: 2710,
      x: 5434,
      y: 6867
    },{
      NanogenreId: 2711,
      x: 11272,
      y: 10840
    },{
      NanogenreId: 2712,
      x: 7299,
      y: 10262
    },{
      NanogenreId: 2713,
      x: 10931,
      y: 10591
    },{
      NanogenreId: 2714,
      x: 9892,
      y: 4708
    },{
      NanogenreId: 2715,
      x: 10945,
      y: 9756
    },{
      NanogenreId: 2716,
      x: 4833,
      y: 8463
    },{
      NanogenreId: 2717,
      x: 10384,
      y: 10265
    },{
      NanogenreId: 2718,
      x: 9839,
      y: 5274
    },{
      NanogenreId: 2719,
      x: 9302,
      y: 9498
    },{
      NanogenreId: 2720,
      x: 9387,
      y: 8432
    },{
      NanogenreId: 2721,
      x: 7958,
      y: 7804
    },{
      NanogenreId: 2722,
      x: 11117,
      y: 10940
    },{
      NanogenreId: 2723,
      x: 7695,
      y: 7934
    },{
      NanogenreId: 2724,
      x: 4758,
      y: 6843
    },{
      NanogenreId: 2725,
      x: 5852,
      y: 8995
    },{
      NanogenreId: 2726,
      x: 2192,
      y: 6488
    },{
      NanogenreId: 2727,
      x: 9502,
      y: 6643
    },{
      NanogenreId: 2728,
      x: 9924,
      y: 1653
    },{
      NanogenreId: 2729,
      x: 4575,
      y: 6024
    },{
      NanogenreId: 2730,
      x: 7339,
      y: 7102
    },{
      NanogenreId: 2731,
      x: 5630,
      y: 7127
    },{
      NanogenreId: 2732,
      x: 10958,
      y: 9131
    },{
      NanogenreId: 2733,
      x: 7469,
      y: 7174
    },{
      NanogenreId: 2734,
      x: 5027,
      y: 9809
    },{
      NanogenreId: 2735,
      x: 10146,
      y: 4895
    },{
      NanogenreId: 2736,
      x: 8364,
      y: 8856
    },{
      NanogenreId: 2737,
      x: 11104,
      y: 9536
    },{
      NanogenreId: 2738,
      x: 5504,
      y: 6336
    },{
      NanogenreId: 2739,
      x: 10920,
      y: 10924
    },{
      NanogenreId: 2740,
      x: 11882,
      y: 6363
    },{
      NanogenreId: 2741,
      x: 9194,
      y: 8480
    },{
      NanogenreId: 2742,
      x: 10433,
      y: 6677
    },{
      NanogenreId: 2743,
      x: 10292,
      y: 10701
    },{
      NanogenreId: 2744,
      x: 3306,
      y: 10422
    },{
      NanogenreId: 2745,
      x: 6345,
      y: 5519
    },{
      NanogenreId: 2746,
      x: 7864,
      y: 6854
    },{
      NanogenreId: 2747,
      x: 6532,
      y: 7880
    },{
      NanogenreId: 2748,
      x: 5448,
      y: 12574
    },{
      NanogenreId: 2749,
      x: 8052,
      y: 5150
    },{
      NanogenreId: 2750,
      x: 9494,
      y: 4846
    },{
      NanogenreId: 2751,
      x: 8462,
      y: 5417
    },{
      NanogenreId: 2752,
      x: 11174,
      y: 10474
    },{
      NanogenreId: 2753,
      x: 6374,
      y: 10155
    },{
      NanogenreId: 2754,
      x: 8382,
      y: 5071
    },{
      NanogenreId: 2755,
      x: 10947,
      y: 10108
    },{
      NanogenreId: 2756,
      x: 9432,
      y: 5014
    },{
      NanogenreId: 2757,
      x: 10365,
      y: 7878
    },{
      NanogenreId: 2758,
      x: 10373,
      y: 10426
    },{
      NanogenreId: 2759,
      x: 9480,
      y: 8011
    },{
      NanogenreId: 2760,
      x: 9676,
      y: 4563
    },{
      NanogenreId: 2761,
      x: 8032,
      y: 8329
    },{
      NanogenreId: 2762,
      x: 9327,
      y: 7341
    },{
      NanogenreId: 2763,
      x: 6984,
      y: 8622
    },{
      NanogenreId: 2764,
      x: 9660,
      y: 4455
    },{
      NanogenreId: 2765,
      x: 9221,
      y: 8156
    },{
      NanogenreId: 2766,
      x: 8510,
      y: 8911
    },{
      NanogenreId: 2767,
      x: 9133,
      y: 5976
    },{
      NanogenreId: 2768,
      x: 8531,
      y: 5091
    },{
      NanogenreId: 2769,
      x: 8233,
      y: 11250
    },{
      NanogenreId: 2770,
      x: 6976,
      y: 6710
    },{
      NanogenreId: 2771,
      x: 9885,
      y: 4619
    },{
      NanogenreId: 2772,
      x: 10940,
      y: 10529
    },{
      NanogenreId: 2773,
      x: 7501,
      y: 6301
    },{
      NanogenreId: 2774,
      x: 5758,
      y: 7317
    },{
      NanogenreId: 2775,
      x: 7808,
      y: 6446
    },{
      NanogenreId: 2776,
      x: 9062,
      y: 9435
    },{
      NanogenreId: 2777,
      x: 11750,
      y: 2079
    },{
      NanogenreId: 2778,
      x: 11539,
      y: 9473
    },{
      NanogenreId: 2779,
      x: 6924,
      y: 8046
    },{
      NanogenreId: 2780,
      x: 9244,
      y: 5189
    },{
      NanogenreId: 2781,
      x: 7261,
      y: 11179
    },{
      NanogenreId: 2782,
      x: 7916,
      y: 9285
    },{
      NanogenreId: 2783,
      x: 8321,
      y: 10547
    },{
      NanogenreId: 2784,
      x: 4792,
      y: 7446
    },{
      NanogenreId: 2785,
      x: 8498,
      y: 9800
    },{
      NanogenreId: 2786,
      x: 11129,
      y: 9996
    },{
      NanogenreId: 2787,
      x: 5956,
      y: 6882
    },{
      NanogenreId: 2788,
      x: 7768,
      y: 7177
    },{
      NanogenreId: 2789,
      x: 9394,
      y: 4895
    },{
      NanogenreId: 2790,
      x: 10818,
      y: 10613
    },{
      NanogenreId: 2791,
      x: 7326,
      y: 9912
    },{
      NanogenreId: 2792,
      x: 10085,
      y: 10208
    },{
      NanogenreId: 2793,
      x: 9898,
      y: 5232
    },{
      NanogenreId: 2794,
      x: 8993,
      y: 8767
    },{
      NanogenreId: 2795,
      x: 7223,
      y: 6628
    },{
      NanogenreId: 2796,
      x: 7815,
      y: 7290
    },{
      NanogenreId: 2797,
      x: 7409,
      y: 10613
    },{
      NanogenreId: 2798,
      x: 8653,
      y: 9185
    },{
      NanogenreId: 2799,
      x: 9939,
      y: 5361
    },{
      NanogenreId: 2800,
      x: 12012,
      y: 7539
    },{
      NanogenreId: 2801,
      x: 5777,
      y: 5975
    },{
      NanogenreId: 2802,
      x: 13470,
      y: 5379
    },{
      NanogenreId: 2803,
      x: 10756,
      y: 10687
    },{
      NanogenreId: 2804,
      x: 7950,
      y: 7072
    },{
      NanogenreId: 2805,
      x: 8039,
      y: 5596
    },{
      NanogenreId: 2806,
      x: 10508,
      y: 5679
    },{
      NanogenreId: 2807,
      x: 7563,
      y: 6589
    },{
      NanogenreId: 2808,
      x: 10950,
      y: 10633
    },{
      NanogenreId: 2809,
      x: 9357,
      y: 4439
    },{
      NanogenreId: 2810,
      x: 8535,
      y: 6838
    },{
      NanogenreId: 2811,
      x: 10863,
      y: 8799
    },{
      NanogenreId: 2812,
      x: 8787,
      y: 5903
    },{
      NanogenreId: 2813,
      x: 7857,
      y: 6149
    },{
      NanogenreId: 2814,
      x: 9354,
      y: 6832
    },{
      NanogenreId: 2815,
      x: 6381,
      y: 6009
    },{
      NanogenreId: 2816,
      x: 10670,
      y: 10296
    },{
      NanogenreId: 2817,
      x: 9309,
      y: 5791
    },{
      NanogenreId: 2818,
      x: 9021,
      y: 5838
    },{
      NanogenreId: 2819,
      x: 9706,
      y: 5111
    },{
      NanogenreId: 2820,
      x: 3726,
      y: 7788
    },{
      NanogenreId: 2821,
      x: 6255,
      y: 6274
    },{
      NanogenreId: 2822,
      x: 8095,
      y: 4838
    },{
      NanogenreId: 2823,
      x: 9953,
      y: 4808
    },{
      NanogenreId: 2824,
      x: 10216,
      y: 9539
    },{
      NanogenreId: 2825,
      x: 8110,
      y: 8460
    },{
      NanogenreId: 2826,
      x: 7202,
      y: 8696
    },{
      NanogenreId: 2827,
      x: 7246,
      y: 6927
    },{
      NanogenreId: 2828,
      x: 10594,
      y: 10126
    },{
      NanogenreId: 2829,
      x: 6036,
      y: 10983
    },{
      NanogenreId: 2830,
      x: 10681,
      y: 10678
    },{
      NanogenreId: 2831,
      x: 6526,
      y: 9815
    },{
      NanogenreId: 2832,
      x: 9476,
      y: 10785
    },{
      NanogenreId: 2833,
      x: 5248,
      y: 7460
    },{
      NanogenreId: 2834,
      x: 5410,
      y: 4314
    },{
      NanogenreId: 2835,
      x: 7014,
      y: 11510
    },{
      NanogenreId: 2836,
      x: 10953,
      y: 10335
    },{
      NanogenreId: 2837,
      x: 7736,
      y: 4953
    },{
      NanogenreId: 2838,
      x: 9165,
      y: 4375
    },{
      NanogenreId: 2839,
      x: 9774,
      y: 4637
    },{
      NanogenreId: 2840,
      x: 9794,
      y: 10591
    },{
      NanogenreId: 2841,
      x: 9445,
      y: 5280
    },{
      NanogenreId: 2842,
      x: 10887,
      y: 8742
    },{
      NanogenreId: 2843,
      x: 5636,
      y: 6419
    },{
      NanogenreId: 2844,
      x: 8282,
      y: 7039
    },{
      NanogenreId: 2845,
      x: 10399,
      y: 7661
    },{
      NanogenreId: 2846,
      x: 6786,
      y: 7427
    },{
      NanogenreId: 2847,
      x: 10971,
      y: 10149
    },{
      NanogenreId: 2848,
      x: 8605,
      y: 6008
    },{
      NanogenreId: 2849,
      x: 6755,
      y: 6378
    },{
      NanogenreId: 2850,
      x: 6324,
      y: 8088
    },{
      NanogenreId: 2851,
      x: 8256,
      y: 7885
    },{
      NanogenreId: 2852,
      x: 7172,
      y: 5845
    },{
      NanogenreId: 2853,
      x: 6353,
      y: 6930
    },{
      NanogenreId: 2854,
      x: 5440,
      y: 6439
    },{
      NanogenreId: 2855,
      x: 9485,
      y: 9407
    },{
      NanogenreId: 2856,
      x: 9189,
      y: 4530
    },{
      NanogenreId: 2857,
      x: 3548,
      y: 6581
    },{
      NanogenreId: 2858,
      x: 7234,
      y: 8309
    },{
      NanogenreId: 2859,
      x: 9171,
      y: 7971
    },{
      NanogenreId: 2860,
      x: 9530,
      y: 5574
    },{
      NanogenreId: 2861,
      x: 7071,
      y: 7225
    },{
      NanogenreId: 2862,
      x: 7709,
      y: 6047
    },{
      NanogenreId: 2863,
      x: 7925,
      y: 7427
    },{
      NanogenreId: 2864,
      x: 6146,
      y: 11106
    },{
      NanogenreId: 2865,
      x: 6454,
      y: 8108
    },{
      NanogenreId: 2866,
      x: 10387,
      y: 11089
    },{
      NanogenreId: 2867,
      x: 9305,
      y: 9297
    },{
      NanogenreId: 2868,
      x: 9055,
      y: 10051
    },{
      NanogenreId: 2869,
      x: 8660,
      y: 5268
    },{
      NanogenreId: 2870,
      x: 5991,
      y: 8887
    },{
      NanogenreId: 2871,
      x: 7799,
      y: 4725
    },{
      NanogenreId: 2872,
      x: 9856,
      y: 5486
    },{
      NanogenreId: 2873,
      x: 10064,
      y: 4930
    },{
      NanogenreId: 2874,
      x: 8568,
      y: 6028
    },{
      NanogenreId: 2875,
      x: 10227,
      y: 10075
    },{
      NanogenreId: 2876,
      x: 6310,
      y: 7005
    },{
      NanogenreId: 2877,
      x: 6112,
      y: 8433
    },{
      NanogenreId: 2878,
      x: 4843,
      y: 7994
    },{
      NanogenreId: 2879,
      x: 8664,
      y: 7894
    },{
      NanogenreId: 2880,
      x: 6892,
      y: 6260
    },{
      NanogenreId: 2881,
      x: 8750,
      y: 8342
    },{
      NanogenreId: 2882,
      x: 11090,
      y: 10185
    },{
      NanogenreId: 2883,
      x: 6887,
      y: 7379
    },{
      NanogenreId: 2884,
      x: 5791,
      y: 4924
    },{
      NanogenreId: 2885,
      x: 6420,
      y: 4248
    },{
      NanogenreId: 2886,
      x: 12008,
      y: 8901
    },{
      NanogenreId: 2887,
      x: 8530,
      y: 9932
    },{
      NanogenreId: 2888,
      x: 11106,
      y: 10337
    },{
      NanogenreId: 2889,
      x: 8089,
      y: 5654
    },{
      NanogenreId: 2890,
      x: 8488,
      y: 8181
    },{
      NanogenreId: 2891,
      x: 8438,
      y: 4891
    },{
      NanogenreId: 2892,
      x: 7661,
      y: 8811
    },{
      NanogenreId: 2893,
      x: 9047,
      y: 5116
    },{
      NanogenreId: 2894,
      x: 7594,
      y: 5830
    },{
      NanogenreId: 2895,
      x: 8635,
      y: 8083
    },{
      NanogenreId: 2896,
      x: 7154,
      y: 6573
    },{
      NanogenreId: 2897,
      x: 9107,
      y: 4831
    },{
      NanogenreId: 2898,
      x: 7602,
      y: 10264
    },{
      NanogenreId: 2899,
      x: 9392,
      y: 9849
    },{
      NanogenreId: 2900,
      x: 8746,
      y: 5692
    },{
      NanogenreId: 2901,
      x: 11722,
      y: 7647
    },{
      NanogenreId: 2902,
      x: 5557,
      y: 8652
    },{
      NanogenreId: 2903,
      x: 9708,
      y: 4955
    },{
      NanogenreId: 2904,
      x: 5033,
      y: 5947
    },{
      NanogenreId: 2905,
      x: 4087,
      y: 8357
    },{
      NanogenreId: 2906,
      x: 9052,
      y: 10957
    },{
      NanogenreId: 2907,
      x: 9046,
      y: 5064
    },{
      NanogenreId: 2908,
      x: 3406,
      y: 9323
    },{
      NanogenreId: 2909,
      x: 7724,
      y: 7339
    },{
      NanogenreId: 2910,
      x: 8281,
      y: 8886
    },{
      NanogenreId: 2911,
      x: 13578,
      y: 3337
    },{
      NanogenreId: 2912,
      x: 9094,
      y: 10138
    },{
      NanogenreId: 2913,
      x: 10435,
      y: 10298
    },{
      NanogenreId: 2914,
      x: 11202,
      y: 9952
    },{
      NanogenreId: 2915,
      x: 11333,
      y: 7523
    },{
      NanogenreId: 2916,
      x: 7965,
      y: 8217
    },{
      NanogenreId: 2917,
      x: 9479,
      y: 5322
    },{
      NanogenreId: 2918,
      x: 8280,
      y: 7273
    },{
      NanogenreId: 2919,
      x: 6560,
      y: 6789
    },{
      NanogenreId: 2920,
      x: 8126,
      y: 6480
    },{
      NanogenreId: 2921,
      x: 6478,
      y: 6820
    },{
      NanogenreId: 2922,
      x: 8568,
      y: 8076
    },{
      NanogenreId: 2923,
      x: 9092,
      y: 5376
    },{
      NanogenreId: 2924,
      x: 9427,
      y: 9617
    },{
      NanogenreId: 2925,
      x: 9996,
      y: 4609
    },{
      NanogenreId: 2926,
      x: 11544,
      y: 11132
    },{
      NanogenreId: 2927,
      x: 7648,
      y: 7268
    },{
      NanogenreId: 2928,
      x: 5447,
      y: 6052
    },{
      NanogenreId: 2929,
      x: 9035,
      y: 9223
    },{
      NanogenreId: 2930,
      x: 8436,
      y: 5538
    },{
      NanogenreId: 2931,
      x: 10892,
      y: 10882
    },{
      NanogenreId: 2932,
      x: 9986,
      y: 4483
    },{
      NanogenreId: 2933,
      x: 10713,
      y: 10684
    },{
      NanogenreId: 2934,
      x: 9939,
      y: 11504
    },{
      NanogenreId: 2935,
      x: 9847,
      y: 6374
    },{
      NanogenreId: 2936,
      x: 8304,
      y: 5934
    },{
      NanogenreId: 2937,
      x: 8393,
      y: 6634
    },{
      NanogenreId: 2938,
      x: 6704,
      y: 9669
    },{
      NanogenreId: 2939,
      x: 7889,
      y: 5666
    },{
      NanogenreId: 2940,
      x: 8199,
      y: 5622
    },{
      NanogenreId: 2941,
      x: 6923,
      y: 6165
    },{
      NanogenreId: 2942,
      x: 10028,
      y: 4591
    },{
      NanogenreId: 2943,
      x: 9375,
      y: 4963
    },{
      NanogenreId: 2944,
      x: 7305,
      y: 6592
    },{
      NanogenreId: 2945,
      x: 9396,
      y: 4888
    },{
      NanogenreId: 2946,
      x: 5784,
      y: 6388
    },{
      NanogenreId: 2947,
      x: 6117,
      y: 6236
    },{
      NanogenreId: 2948,
      x: 9513,
      y: 9999
    },{
      NanogenreId: 2949,
      x: 7532,
      y: 8097
    },{
      NanogenreId: 2950,
      x: 5412,
      y: 7697
    },{
      NanogenreId: 2951,
      x: 11340,
      y: 6476
    },{
      NanogenreId: 2952,
      x: 7265,
      y: 5748
    },{
      NanogenreId: 2953,
      x: 10400,
      y: 10184
    },{
      NanogenreId: 2954,
      x: 9823,
      y: 4851
    },{
      NanogenreId: 2955,
      x: 9131,
      y: 4404
    },{
      NanogenreId: 2956,
      x: 9227,
      y: 4976
    },{
      NanogenreId: 2957,
      x: 8939,
      y: 9698
    },{
      NanogenreId: 2958,
      x: 9256,
      y: 5006
    },{
      NanogenreId: 2959,
      x: 5772,
      y: 9944
    },{
      NanogenreId: 2960,
      x: 6417,
      y: 7363
    },{
      NanogenreId: 2961,
      x: 10507,
      y: 10181
    },{
      NanogenreId: 2962,
      x: 6005,
      y: 8011
    },{
      NanogenreId: 2963,
      x: 4396,
      y: 6319
    },{
      NanogenreId: 2964,
      x: 9352,
      y: 6104
    },{
      NanogenreId: 2965,
      x: 6412,
      y: 8013
    },{
      NanogenreId: 2966,
      x: 10666,
      y: 9848
    },{
      NanogenreId: 2967,
      x: 9542,
      y: 7307
    },{
      NanogenreId: 2968,
      x: 10168,
      y: 4579
    },{
      NanogenreId: 2969,
      x: 6829,
      y: 5828
    },{
      NanogenreId: 2970,
      x: 8876,
      y: 4810
    },{
      NanogenreId: 2971,
      x: 5415,
      y: 6260
    },{
      NanogenreId: 2972,
      x: 7415,
      y: 7589
    },{
      NanogenreId: 2973,
      x: 7876,
      y: 3472
    },{
      NanogenreId: 2974,
      x: 7541,
      y: 5014
    },{
      NanogenreId: 2975,
      x: 13381,
      y: 9099
    },{
      NanogenreId: 2976,
      x: 7496,
      y: 2831
    },{
      NanogenreId: 2977,
      x: 3731,
      y: 5654
    },{
      NanogenreId: 2978,
      x: 6268,
      y: 6522
    },{
      NanogenreId: 2979,
      x: 5962,
      y: 5575
    },{
      NanogenreId: 2980,
      x: 8304,
      y: 8756
    },{
      NanogenreId: 2981,
      x: 5315,
      y: 8387
    },{
      NanogenreId: 2982,
      x: 8705,
      y: 6202
    },{
      NanogenreId: 2983,
      x: 7435,
      y: 5538
    },{
      NanogenreId: 2984,
      x: 9556,
      y: 5024
    },{
      NanogenreId: 2985,
      x: 6788,
      y: 8556
    },{
      NanogenreId: 2986,
      x: 3986,
      y: 3693
    },{
      NanogenreId: 2987,
      x: 8999,
      y: 9430
    },{
      NanogenreId: 2988,
      x: 11331,
      y: 10061
    },{
      NanogenreId: 2989,
      x: 6994,
      y: 5949
    },{
      NanogenreId: 2990,
      x: 11244,
      y: 5971
    },{
      NanogenreId: 2991,
      x: 8907,
      y: 12327
    },{
      NanogenreId: 2992,
      x: 7458,
      y: 7334
    },{
      NanogenreId: 2993,
      x: 8375,
      y: 9497
    },{
      NanogenreId: 2994,
      x: 8888,
      y: 5550
    },{
      NanogenreId: 2995,
      x: 6443,
      y: 8204
    },{
      NanogenreId: 2996,
      x: 9500,
      y: 4279
    },{
      NanogenreId: 2997,
      x: 6107,
      y: 9421
    },{
      NanogenreId: 2998,
      x: 5215,
      y: 9471
    },{
      NanogenreId: 2999,
      x: 5981,
      y: 9510
    },{
      NanogenreId: 3000,
      x: 6651,
      y: 6228
    },{
      NanogenreId: 3001,
      x: 6913,
      y: 6080
    },{
      NanogenreId: 3002,
      x: 7830,
      y: 4483
    },{
      NanogenreId: 3003,
      x: 8426,
      y: 9237
    },{
      NanogenreId: 3004,
      x: 5857,
      y: 7140
    },{
      NanogenreId: 3005,
      x: 9136,
      y: 4776
    },{
      NanogenreId: 3006,
      x: 9512,
      y: 7605
    },{
      NanogenreId: 3007,
      x: 9224,
      y: 8845
    },{
      NanogenreId: 3008,
      x: 10640,
      y: 9974
    },{
      NanogenreId: 3009,
      x: 4343,
      y: 5711
    },{
      NanogenreId: 3010,
      x: 13634,
      y: 8418
    },{
      NanogenreId: 3011,
      x: 5418,
      y: 9435
    },{
      NanogenreId: 3012,
      x: 7772,
      y: 9202
    },{
      NanogenreId: 3013,
      x: 8408,
      y: 11403
    },{
      NanogenreId: 3014,
      x: 6315,
      y: 10294
    },{
      NanogenreId: 3015,
      x: 7585,
      y: 6362
    },{
      NanogenreId: 3016,
      x: 9890,
      y: 4648
    },{
      NanogenreId: 3017,
      x: 8916,
      y: 5904
    },{
      NanogenreId: 3018,
      x: 6444,
      y: 10891
    },{
      NanogenreId: 3019,
      x: 7816,
      y: 8084
    },{
      NanogenreId: 3020,
      x: 11787,
      y: 8272
    },{
      NanogenreId: 3021,
      x: 7403,
      y: 9914
    },{
      NanogenreId: 3022,
      x: 6671,
      y: 5439
    },{
      NanogenreId: 3023,
      x: 7667,
      y: 5867
    },{
      NanogenreId: 3024,
      x: 9265,
      y: 9535
    },{
      NanogenreId: 3025,
      x: 4241,
      y: 9443
    },{
      NanogenreId: 3026,
      x: 7228,
      y: 5553
    },{
      NanogenreId: 3027,
      x: 8354,
      y: 6503
    },{
      NanogenreId: 3028,
      x: 13756,
      y: 7651
    },{
      NanogenreId: 3029,
      x: 6442,
      y: 6610
    },{
      NanogenreId: 3030,
      x: 5492,
      y: 8613
    },{
      NanogenreId: 3031,
      x: 7838,
      y: 7756
    },{
      NanogenreId: 3032,
      x: 8974,
      y: 7373
    },{
      NanogenreId: 3033,
      x: 8819,
      y: 6275
    },{
      NanogenreId: 3034,
      x: 9917,
      y: 4734
    },{
      NanogenreId: 3035,
      x: 8992,
      y: 5672
    },{
      NanogenreId: 3036,
      x: 6954,
      y: 9683
    },{
      NanogenreId: 3037,
      x: 9078,
      y: 5704
    },{
      NanogenreId: 3038,
      x: 5811,
      y: 6632
    },{
      NanogenreId: 3039,
      x: 7640,
      y: 6067
    },{
      NanogenreId: 3040,
      x: 11251,
      y: 10959
    },{
      NanogenreId: 3041,
      x: 10062,
      y: 4998
    },{
      NanogenreId: 3042,
      x: 7998,
      y: 5700
    },{
      NanogenreId: 3043,
      x: 9607,
      y: 5442
    },{
      NanogenreId: 3044,
      x: 9463,
      y: 7860
    },{
      NanogenreId: 3045,
      x: 6745,
      y: 6254
    },{
      NanogenreId: 3046,
      x: 8231,
      y: 5362
    },{
      NanogenreId: 3047,
      x: 7543,
      y: 5314
    },{
      NanogenreId: 3048,
      x: 9501,
      y: 5403
    },{
      NanogenreId: 3049,
      x: 8074,
      y: 5202
    },{
      NanogenreId: 3050,
      x: 8918,
      y: 7797
    },{
      NanogenreId: 3051,
      x: 9418,
      y: 7713
    },{
      NanogenreId: 3052,
      x: 7455,
      y: 6823
    },{
      NanogenreId: 3053,
      x: 6462,
      y: 5354
    },{
      NanogenreId: 3054,
      x: 7880,
      y: 7557
    },{
      NanogenreId: 3055,
      x: 10415,
      y: 10822
    },{
      NanogenreId: 3056,
      x: 7342,
      y: 9153
    },{
      NanogenreId: 3057,
      x: 6861,
      y: 9096
    },{
      NanogenreId: 3058,
      x: 9230,
      y: 5319
    },{
      NanogenreId: 3059,
      x: 9963,
      y: 4614
    },{
      NanogenreId: 3060,
      x: 6505,
      y: 6151
    },{
      NanogenreId: 3061,
      x: 4954,
      y: 6436
    },{
      NanogenreId: 3062,
      x: 9838,
      y: 9212
    },{
      NanogenreId: 3063,
      x: 7301,
      y: 5998
    },{
      NanogenreId: 3064,
      x: 7117,
      y: 9844
    },{
      NanogenreId: 3065,
      x: 10597,
      y: 10511
    },{
      NanogenreId: 3066,
      x: 6735,
      y: 5171
    },{
      NanogenreId: 3067,
      x: 7802,
      y: 4779
    },{
      NanogenreId: 3068,
      x: 7307,
      y: 6689
    },{
      NanogenreId: 3069,
      x: 8811,
      y: 5509
    },{
      NanogenreId: 3070,
      x: 6221,
      y: 6804
    },{
      NanogenreId: 3071,
      x: 7819,
      y: 5768
    },{
      NanogenreId: 3072,
      x: 7985,
      y: 7624
    },{
      NanogenreId: 3073,
      x: 8638,
      y: 11719
    },{
      NanogenreId: 3074,
      x: 9600,
      y: 4933
    },{
      NanogenreId: 3075,
      x: 10565,
      y: 10485
    },{
      NanogenreId: 3076,
      x: 7261,
      y: 5208
    },{
      NanogenreId: 3077,
      x: 6366,
      y: 6517
    },{
      NanogenreId: 3078,
      x: 7326,
      y: 5809
    },{
      NanogenreId: 3079,
      x: 3889,
      y: 8346
    },{
      NanogenreId: 3080,
      x: 10837,
      y: 8227
    },{
      NanogenreId: 3081,
      x: 8816,
      y: 12110
    },{
      NanogenreId: 3082,
      x: 5455,
      y: 7178
    },{
      NanogenreId: 3083,
      x: 10572,
      y: 7232
    },{
      NanogenreId: 3084,
      x: 11508,
      y: 7519
    },{
      NanogenreId: 3085,
      x: 7701,
      y: 8756
    },{
      NanogenreId: 3086,
      x: 10824,
      y: 10206
    },{
      NanogenreId: 3087,
      x: 5646,
      y: 8103
    },{
      NanogenreId: 3088,
      x: 5554,
      y: 8807
    },{
      NanogenreId: 3089,
      x: 4390,
      y: 6208
    },{
      NanogenreId: 3090,
      x: 7906,
      y: 5436
    },{
      NanogenreId: 3091,
      x: 7492,
      y: 6228
    },{
      NanogenreId: 3092,
      x: 9864,
      y: 4924
    },{
      NanogenreId: 3093,
      x: 9825,
      y: 5902
    },{
      NanogenreId: 3094,
      x: 10323,
      y: 9896
    },{
      NanogenreId: 3095,
      x: 8364,
      y: 6100
    },{
      NanogenreId: 3096,
      x: 9838,
      y: 11126
    },{
      NanogenreId: 3097,
      x: 8859,
      y: 10286
    },{
      NanogenreId: 3098,
      x: 9089,
      y: 6606
    },{
      NanogenreId: 3099,
      x: 6227,
      y: 6842
    },{
      NanogenreId: 3100,
      x: 10092,
      y: 4932
    },{
      NanogenreId: 3101,
      x: 6999,
      y: 5898
    },{
      NanogenreId: 3102,
      x: 9622,
      y: 4648
    },{
      NanogenreId: 3103,
      x: 7790,
      y: 10052
    },{
      NanogenreId: 3104,
      x: 5867,
      y: 8529
    },{
      NanogenreId: 3105,
      x: 3478,
      y: 5237
    },{
      NanogenreId: 3106,
      x: 8839,
      y: 5174
    },{
      NanogenreId: 3107,
      x: 7121,
      y: 6099
    },{
      NanogenreId: 3108,
      x: 8072,
      y: 6794
    },{
      NanogenreId: 3109,
      x: 9167,
      y: 5267
    },{
      NanogenreId: 3110,
      x: 11086,
      y: 10299
    },{
      NanogenreId: 3111,
      x: 6581,
      y: 7899
    },{
      NanogenreId: 3112,
      x: 6344,
      y: 9145
    },{
      NanogenreId: 3113,
      x: 7938,
      y: 9040
    },{
      NanogenreId: 3114,
      x: 8492,
      y: 5263
    },{
      NanogenreId: 3115,
      x: 6667,
      y: 8951
    },{
      NanogenreId: 3116,
      x: 6625,
      y: 6772
    },{
      NanogenreId: 3117,
      x: 5253,
      y: 7649
    },{
      NanogenreId: 3118,
      x: 7393,
      y: 6792
    },{
      NanogenreId: 3119,
      x: 7829,
      y: 10356
    },{
      NanogenreId: 3120,
      x: 10298,
      y: 6255
    },{
      NanogenreId: 3121,
      x: 11883,
      y: 10956
    },{
      NanogenreId: 3122,
      x: 11785,
      y: 4483
    },{
      NanogenreId: 3123,
      x: 7378,
      y: 8892
    },{
      NanogenreId: 3124,
      x: 10925,
      y: 9202
    },{
      NanogenreId: 3125,
      x: 10109,
      y: 4881
    },{
      NanogenreId: 3126,
      x: 6764,
      y: 6442
    },{
      NanogenreId: 3127,
      x: 9030,
      y: 8049
    },{
      NanogenreId: 3128,
      x: 9685,
      y: 10523
    },{
      NanogenreId: 3129,
      x: 7553,
      y: 9975
    },{
      NanogenreId: 3130,
      x: 10243,
      y: 8921
    },{
      NanogenreId: 3131,
      x: 6656,
      y: 6456
    },{
      NanogenreId: 3132,
      x: 5908,
      y: 8485
    },{
      NanogenreId: 3133,
      x: 3908,
      y: 6238
    },{
      NanogenreId: 3134,
      x: 8903,
      y: 5116
    },{
      NanogenreId: 3135,
      x: 6845,
      y: 9664
    },{
      NanogenreId: 3136,
      x: 4509,
      y: 9969
    },{
      NanogenreId: 3137,
      x: 6123,
      y: 6975
    },{
      NanogenreId: 3138,
      x: 8572,
      y: 5649
    },{
      NanogenreId: 3139,
      x: 8253,
      y: 11936
    },{
      NanogenreId: 3140,
      x: 3994,
      y: 7125
    },{
      NanogenreId: 3141,
      x: 8556,
      y: 6215
    },{
      NanogenreId: 3142,
      x: 9742,
      y: 4942
    },{
      NanogenreId: 3143,
      x: 4684,
      y: 10745
    },{
      NanogenreId: 3144,
      x: 12202,
      y: 9117
    },{
      NanogenreId: 3145,
      x: 9461,
      y: 5706
    },{
      NanogenreId: 3146,
      x: 8281,
      y: 8819
    },{
      NanogenreId: 3147,
      x: 7952,
      y: 9926
    },{
      NanogenreId: 3148,
      x: 9621,
      y: 4633
    },{
      NanogenreId: 3149,
      x: 10887,
      y: 10180
    },{
      NanogenreId: 3150,
      x: 10278,
      y: 9682
    },{
      NanogenreId: 3151,
      x: 8440,
      y: 5667
    },{
      NanogenreId: 3152,
      x: 4986,
      y: 10661
    },{
      NanogenreId: 3153,
      x: 7021,
      y: 7859
    },{
      NanogenreId: 3154,
      x: 8386,
      y: 2310
    },{
      NanogenreId: 3155,
      x: 6121,
      y: 6435
    },{
      NanogenreId: 3156,
      x: 8655,
      y: 8128
    },{
      NanogenreId: 3157,
      x: 9362,
      y: 5120
    },{
      NanogenreId: 3158,
      x: 8341,
      y: 8612
    },{
      NanogenreId: 3159,
      x: 9254,
      y: 6840
    },{
      NanogenreId: 3160,
      x: 9871,
      y: 4856
    },{
      NanogenreId: 3161,
      x: 9458,
      y: 6603
    },{
      NanogenreId: 3162,
      x: 7377,
      y: 3615
    },{
      NanogenreId: 3163,
      x: 9123,
      y: 10611
    },{
      NanogenreId: 3164,
      x: 9714,
      y: 9718
    },{
      NanogenreId: 3165,
      x: 6684,
      y: 9200
    },{
      NanogenreId: 3166,
      x: 5880,
      y: 6461
    },{
      NanogenreId: 3167,
      x: 8725,
      y: 6331
    },{
      NanogenreId: 3168,
      x: 8455,
      y: 7635
    },{
      NanogenreId: 3169,
      x: 9861,
      y: 4890
    },{
      NanogenreId: 3170,
      x: 7348,
      y: 7010
    },{
      NanogenreId: 3171,
      x: 9567,
      y: 9250
    },{
      NanogenreId: 3172,
      x: 3832,
      y: 7435
    },{
      NanogenreId: 3173,
      x: 7384,
      y: 6479
    },{
      NanogenreId: 3174,
      x: 6303,
      y: 6162
    },{
      NanogenreId: 3175,
      x: 8121,
      y: 5879
    },{
      NanogenreId: 3176,
      x: 9628,
      y: 12023
    },{
      NanogenreId: 3177,
      x: 10203,
      y: 9628
    },{
      NanogenreId: 3178,
      x: 8830,
      y: 7262
    },{
      NanogenreId: 3179,
      x: 9424,
      y: 5223
    },{
      NanogenreId: 3180,
      x: 9838,
      y: 5663
    },{
      NanogenreId: 3181,
      x: 9236,
      y: 6219
    },{
      NanogenreId: 3182,
      x: 9474,
      y: 4995
    },{
      NanogenreId: 3183,
      x: 6280,
      y: 5501
    },{
      NanogenreId: 3184,
      x: 4481,
      y: 8343
    },{
      NanogenreId: 3185,
      x: 8315,
      y: 4983
    },{
      NanogenreId: 3186,
      x: 9946,
      y: 6802
    },{
      NanogenreId: 3187,
      x: 10074,
      y: 5305
    },{
      NanogenreId: 3188,
      x: 9015,
      y: 9498
    },{
      NanogenreId: 3189,
      x: 9707,
      y: 4591
    },{
      NanogenreId: 3190,
      x: 9204,
      y: 5031
    },{
      NanogenreId: 3191,
      x: 9182,
      y: 9385
    },{
      NanogenreId: 3192,
      x: 9541,
      y: 9779
    },{
      NanogenreId: 3193,
      x: 8515,
      y: 5349
    },{
      NanogenreId: 3194,
      x: 5360,
      y: 9167
    },{
      NanogenreId: 3195,
      x: 4418,
      y: 12315
    },{
      NanogenreId: 3196,
      x: 6495,
      y: 7074
    },{
      NanogenreId: 3197,
      x: 10163,
      y: 9125
    },{
      NanogenreId: 3198,
      x: 7887,
      y: 6421
    },{
      NanogenreId: 3199,
      x: 6608,
      y: 6975
    },{
      NanogenreId: 3200,
      x: 5225,
      y: 6210
    },{
      NanogenreId: 3201,
      x: 7294,
      y: 8550
    },{
      NanogenreId: 3202,
      x: 7794,
      y: 8894
    },{
      NanogenreId: 3203,
      x: 7953,
      y: 4556
    },{
      NanogenreId: 3204,
      x: 6017,
      y: 8513
    },{
      NanogenreId: 3205,
      x: 6256,
      y: 6442
    },{
      NanogenreId: 3206,
      x: 10141,
      y: 9518
    },{
      NanogenreId: 3207,
      x: 7052,
      y: 6543
    },{
      NanogenreId: 3208,
      x: 9336,
      y: 5380
    },{
      NanogenreId: 3209,
      x: 5018,
      y: 11025
    },{
      NanogenreId: 3210,
      x: 5745,
      y: 8615
    },{
      NanogenreId: 3211,
      x: 7171,
      y: 8018
    },{
      NanogenreId: 3212,
      x: 10294,
      y: 8851
    },{
      NanogenreId: 3213,
      x: 7623,
      y: 6219
    },{
      NanogenreId: 3214,
      x: 8663,
      y: 6252
    },{
      NanogenreId: 3215,
      x: 10274,
      y: 10492
    },{
      NanogenreId: 3216,
      x: 6396,
      y: 8803
    },{
      NanogenreId: 3217,
      x: 9042,
      y: 7012
    },{
      NanogenreId: 3218,
      x: 9016,
      y: 5593
    },{
      NanogenreId: 3219,
      x: 8687,
      y: 9047
    },{
      NanogenreId: 3220,
      x: 8798,
      y: 5443
    },{
      NanogenreId: 3221,
      x: 8425,
      y: 8465
    },{
      NanogenreId: 3222,
      x: 7887,
      y: 8597
    },{
      NanogenreId: 3223,
      x: 6305,
      y: 10443
    },{
      NanogenreId: 3224,
      x: 10580,
      y: 10882
    },{
      NanogenreId: 3225,
      x: 5727,
      y: 8882
    },{
      NanogenreId: 3226,
      x: 7791,
      y: 8555
    },{
      NanogenreId: 3227,
      x: 9628,
      y: 4765
    },{
      NanogenreId: 3228,
      x: 5804,
      y: 7737
    },{
      NanogenreId: 3229,
      x: 8680,
      y: 7592
    },{
      NanogenreId: 3230,
      x: 9587,
      y: 7832
    },{
      NanogenreId: 3231,
      x: 6590,
      y: 6456
    },{
      NanogenreId: 3232,
      x: 9924,
      y: 10127
    },{
      NanogenreId: 3233,
      x: 8762,
      y: 9134
    },{
      NanogenreId: 3234,
      x: 5346,
      y: 9297
    },{
      NanogenreId: 3235,
      x: 13632,
      y: 7288
    },{
      NanogenreId: 3236,
      x: 9279,
      y: 9392
    },{
      NanogenreId: 3237,
      x: 8916,
      y: 6443
    },{
      NanogenreId: 3238,
      x: 7946,
      y: 8358
    },{
      NanogenreId: 3239,
      x: 9434,
      y: 6022
    },{
      NanogenreId: 3240,
      x: 6180,
      y: 7976
    },{
      NanogenreId: 3241,
      x: 7145,
      y: 6412
    },{
      NanogenreId: 3242,
      x: 7183,
      y: 7558
    },{
      NanogenreId: 3243,
      x: 10594,
      y: 9962
    },{
      NanogenreId: 3244,
      x: 10077,
      y: 9705
    },{
      NanogenreId: 3245,
      x: 9672,
      y: 5814
    },{
      NanogenreId: 3246,
      x: 8807,
      y: 3569
    },{
      NanogenreId: 3247,
      x: 9361,
      y: 6045
    },{
      NanogenreId: 3248,
      x: 10909,
      y: 10692
    },{
      NanogenreId: 3249,
      x: 9034,
      y: 5181
    },{
      NanogenreId: 3250,
      x: 8007,
      y: 5064
    },{
      NanogenreId: 3251,
      x: 7785,
      y: 9732
    },{
      NanogenreId: 3252,
      x: 8093,
      y: 10056
    },{
      NanogenreId: 3253,
      x: 9404,
      y: 8845
    },{
      NanogenreId: 3254,
      x: 11137,
      y: 3120
    },{
      NanogenreId: 3255,
      x: 7650,
      y: 9499
    },{
      NanogenreId: 3256,
      x: 9022,
      y: 7061
    },{
      NanogenreId: 3257,
      x: 9023,
      y: 8426
    },{
      NanogenreId: 3258,
      x: 8764,
      y: 6964
    },{
      NanogenreId: 3259,
      x: 4830,
      y: 3167
    },{
      NanogenreId: 3260,
      x: 12229,
      y: 8022
    },{
      NanogenreId: 3261,
      x: 11148,
      y: 5625
    },{
      NanogenreId: 3262,
      x: 13046,
      y: 9826
    },{
      NanogenreId: 3263,
      x: 9784,
      y: 4466
    },{
      NanogenreId: 3264,
      x: 9058,
      y: 4857
    },{
      NanogenreId: 3265,
      x: 3814,
      y: 8277
    },{
      NanogenreId: 3266,
      x: 12373,
      y: 8465
    },{
      NanogenreId: 3267,
      x: 4430,
      y: 11767
    },{
      NanogenreId: 3268,
      x: 12379,
      y: 10907
    },{
      NanogenreId: 3269,
      x: 10247,
      y: 10650
    },{
      NanogenreId: 3270,
      x: 7104,
      y: 11578
    },{
      NanogenreId: 3271,
      x: 8216,
      y: 4484
    },{
      NanogenreId: 3272,
      x: 13347,
      y: 10484
    },{
      NanogenreId: 3273,
      x: 3976,
      y: 8201
    },{
      NanogenreId: 3274,
      x: 10088,
      y: 10664
    },{
      NanogenreId: 3275,
      x: 9585,
      y: 4389
    },{
      NanogenreId: 3276,
      x: 6607,
      y: 8617
    },{
      NanogenreId: 3277,
      x: 6872,
      y: 11704
    },{
      NanogenreId: 3278,
      x: 6566,
      y: 7561
    },{
      NanogenreId: 3279,
      x: 6758,
      y: 11654
    },{
      NanogenreId: 3280,
      x: 8120,
      y: 10650
    },{
      NanogenreId: 3281,
      x: 7344,
      y: 5318
    },{
      NanogenreId: 3282,
      x: 12901,
      y: 6305
    },{
      NanogenreId: 3283,
      x: 12830,
      y: 7686
    },{
      NanogenreId: 3284,
      x: 13009,
      y: 7983
    },{
      NanogenreId: 3285,
      x: 2833,
      y: 9467
    },{
      NanogenreId: 3286,
      x: 9693,
      y: 6943
    },{
      NanogenreId: 3287,
      x: 12354,
      y: 10552
    },{
      NanogenreId: 3288,
      x: 10421,
      y: 5671
    },{
      NanogenreId: 3289,
      x: 8145,
      y: 3759
    },{
      NanogenreId: 3290,
      x: 4814,
      y: 3718
    },{
      NanogenreId: 3291,
      x: 7280,
      y: 7222
    },{
      NanogenreId: 3292,
      x: 11437,
      y: 8229
    },{
      NanogenreId: 3293,
      x: 4153,
      y: 9105
    },{
      NanogenreId: 3294,
      x: 3671,
      y: 9044
    },{
      NanogenreId: 3295,
      x: 5398,
      y: 10778
    },{
      NanogenreId: 3296,
      x: 11169,
      y: 9506
    },{
      NanogenreId: 3297,
      x: 3655,
      y: 10278
    },{
      NanogenreId: 3298,
      x: 8087,
      y: 10335
    },{
      NanogenreId: 3299,
      x: 10120,
      y: 4462
    },{
      NanogenreId: 3300,
      x: 5784,
      y: 6305
    },{
      NanogenreId: 3301,
      x: 5905,
      y: 10259
    },{
      NanogenreId: 3302,
      x: 9663,
      y: 4857
    },{
      NanogenreId: 3303,
      x: 8447,
      y: 12141
    },{
      NanogenreId: 3304,
      x: 9741,
      y: 6118
    },{
      NanogenreId: 3305,
      x: 9639,
      y: 4676
    },{
      NanogenreId: 3306,
      x: 10351,
      y: 9949
    },{
      NanogenreId: 3307,
      x: 8631,
      y: 4262
    },{
      NanogenreId: 3308,
      x: 7083,
      y: 11218
    },{
      NanogenreId: 3309,
      x: 8931,
      y: 3256
    },{
      NanogenreId: 3310,
      x: 10915,
      y: 10015
    },{
      NanogenreId: 3311,
      x: 10392,
      y: 10347
    },{
      NanogenreId: 3312,
      x: 10576,
      y: 10066
    },{
      NanogenreId: 3313,
      x: 3840,
      y: 5880
    },{
      NanogenreId: 3314,
      x: 7112,
      y: 11211
    },{
      NanogenreId: 3315,
      x: 11596,
      y: 7795
    },{
      NanogenreId: 3316,
      x: 7182,
      y: 10674
    },{
      NanogenreId: 3317,
      x: 9845,
      y: 8937
    },{
      NanogenreId: 3318,
      x: 8594,
      y: 9177
    },{
      NanogenreId: 3319,
      x: 8569,
      y: 5227
    },{
      NanogenreId: 3320,
      x: 10396,
      y: 7093
    },{
      NanogenreId: 3321,
      x: 6717,
      y: 12796
    },{
      NanogenreId: 3322,
      x: 10016,
      y: 4733
    },{
      NanogenreId: 3323,
      x: 11169,
      y: 11427
    },{
      NanogenreId: 3324,
      x: 5342,
      y: 10146
    },{
      NanogenreId: 3325,
      x: 9310,
      y: 7071
    },{
      NanogenreId: 3326,
      x: 6688,
      y: 11992
    },{
      NanogenreId: 3327,
      x: 3033,
      y: 9245
    },{
      NanogenreId: 3328,
      x: 5614,
      y: 6910
    },{
      NanogenreId: 3329,
      x: 8514,
      y: 2817
    },{
      NanogenreId: 3330,
      x: 7343,
      y: 9577
    },{
      NanogenreId: 3331,
      x: 10910,
      y: 9413
    },{
      NanogenreId: 3332,
      x: 9887,
      y: 5774
    },{
      NanogenreId: 3333,
      x: 8260,
      y: 4479
    },{
      NanogenreId: 3334,
      x: 8556,
      y: 7985
    },{
      NanogenreId: 3335,
      x: 10702,
      y: 9901
    },{
      NanogenreId: 3336,
      x: 6150,
      y: 3887
    },{
      NanogenreId: 3337,
      x: 11256,
      y: 9990
    },{
      NanogenreId: 3338,
      x: 5727,
      y: 11463
    },{
      NanogenreId: 3339,
      x: 9269,
      y: 4891
    },{
      NanogenreId: 3340,
      x: 12505,
      y: 10663
    },{
      NanogenreId: 3341,
      x: 8278,
      y: 10446
    },{
      NanogenreId: 3342,
      x: 6149,
      y: 12689
    },{
      NanogenreId: 3343,
      x: 4367,
      y: 11915
    },{
      NanogenreId: 3344,
      x: 13875,
      y: 9602
    },{
      NanogenreId: 3345,
      x: 7347,
      y: 9669
    },{
      NanogenreId: 3346,
      x: 9089,
      y: 10901
    },{
      NanogenreId: 3347,
      x: 7422,
      y: 6777
    },{
      NanogenreId: 3348,
      x: 10429,
      y: 3472
    },{
      NanogenreId: 3349,
      x: 10877,
      y: 7148
    },{
      NanogenreId: 3350,
      x: 10016,
      y: 10700
    },{
      NanogenreId: 3351,
      x: 10838,
      y: 7648
    },{
      NanogenreId: 3352,
      x: 10863,
      y: 10845
    },{
      NanogenreId: 3353,
      x: 11728,
      y: 2396
    },{
      NanogenreId: 3354,
      x: 6366,
      y: 6611
    },{
      NanogenreId: 3355,
      x: 9798,
      y: 4660
    },{
      NanogenreId: 3356,
      x: 4005,
      y: 9455
    },{
      NanogenreId: 3357,
      x: 9621,
      y: 5281
    },{
      NanogenreId: 3358,
      x: 8028,
      y: 10228
    },{
      NanogenreId: 3359,
      x: 7665,
      y: 10322
    },{
      NanogenreId: 3360,
      x: 3875,
      y: 8997
    },{
      NanogenreId: 3361,
      x: 8359,
      y: 10339
    },{
      NanogenreId: 3362,
      x: 11721,
      y: 9725
    },{
      NanogenreId: 3363,
      x: 12003,
      y: 10400
    },{
      NanogenreId: 3364,
      x: 7646,
      y: 9347
    },{
      NanogenreId: 3365,
      x: 7517,
      y: 5504
    },{
      NanogenreId: 3366,
      x: 8691,
      y: 4803
    },{
      NanogenreId: 3367,
      x: 11058,
      y: 10168
    },{
      NanogenreId: 3368,
      x: 3745,
      y: 9745
    },{
      NanogenreId: 3369,
      x: 5002,
      y: 8407
    },{
      NanogenreId: 3370,
      x: 6883,
      y: 11165
    },{
      NanogenreId: 3371,
      x: 11054,
      y: 10422
    },{
      NanogenreId: 3372,
      x: 8756,
      y: 6721
    },{
      NanogenreId: 3373,
      x: 6448,
      y: 11205
    },{
      NanogenreId: 3374,
      x: 9907,
      y: 4831
    },{
      NanogenreId: 3375,
      x: 5235,
      y: 7050
    },{
      NanogenreId: 3376,
      x: 5269,
      y: 5672
    },{
      NanogenreId: 3377,
      x: 6600,
      y: 4822
    },{
      NanogenreId: 3378,
      x: 13386,
      y: 9268
    },{
      NanogenreId: 3379,
      x: 8673,
      y: 10239
    },{
      NanogenreId: 3380,
      x: 13240,
      y: 6532
    },{
      NanogenreId: 3381,
      x: 6947,
      y: 9045
    },{
      NanogenreId: 3382,
      x: 10689,
      y: 7706
    },{
      NanogenreId: 3383,
      x: 11287,
      y: 10592
    },{
      NanogenreId: 3384,
      x: 5081,
      y: 6792
    },{
      NanogenreId: 3385,
      x: 9522,
      y: 9141
    },{
      NanogenreId: 3386,
      x: 9998,
      y: 8983
    },{
      NanogenreId: 3387,
      x: 10841,
      y: 10478
    },{
      NanogenreId: 3388,
      x: 9951,
      y: 5147
    },{
      NanogenreId: 3389,
      x: 6700,
      y: 6418
    },{
      NanogenreId: 3390,
      x: 6869,
      y: 6906
    },{
      NanogenreId: 3391,
      x: 9688,
      y: 4767
    },{
      NanogenreId: 3392,
      x: 10290,
      y: 10862
    },{
      NanogenreId: 3393,
      x: 6460,
      y: 6708
    },{
      NanogenreId: 3394,
      x: 11562,
      y: 10412
    },{
      NanogenreId: 3395,
      x: 7114,
      y: 11431
    },{
      NanogenreId: 3396,
      x: 8554,
      y: 4037
    },{
      NanogenreId: 3397,
      x: 7467,
      y: 9860
    },{
      NanogenreId: 3398,
      x: 10029,
      y: 4510
    },{
      NanogenreId: 3399,
      x: 11730,
      y: 5410
    },{
      NanogenreId: 3400,
      x: 10598,
      y: 10008
    },{
      NanogenreId: 3401,
      x: 10051,
      y: 10132
    },{
      NanogenreId: 3402,
      x: 9004,
      y: 9198
    },{
      NanogenreId: 3403,
      x: 6938,
      y: 9354
    },{
      NanogenreId: 3404,
      x: 6928,
      y: 5041
    },{
      NanogenreId: 3405,
      x: 10616,
      y: 10353
    },{
      NanogenreId: 3406,
      x: 6357,
      y: 12314
    },{
      NanogenreId: 3407,
      x: 6148,
      y: 5040
    },{
      NanogenreId: 3408,
      x: 10406,
      y: 10940
    },{
      NanogenreId: 3409,
      x: 11191,
      y: 10799
    },{
      NanogenreId: 3410,
      x: 9818,
      y: 8498
    },{
      NanogenreId: 3411,
      x: 13233,
      y: 10728
    },{
      NanogenreId: 3412,
      x: 7736,
      y: 8507
    },{
      NanogenreId: 3413,
      x: 9120,
      y: 5227
    },{
      NanogenreId: 3414,
      x: 8892,
      y: 4840
    },{
      NanogenreId: 3415,
      x: 9143,
      y: 9826
    },{
      NanogenreId: 3416,
      x: 9182,
      y: 8597
    },{
      NanogenreId: 3417,
      x: 7778,
      y: 5326
    },{
      NanogenreId: 3418,
      x: 7665,
      y: 4705
    },{
      NanogenreId: 3419,
      x: 8562,
      y: 4406
    },{
      NanogenreId: 3420,
      x: 7854,
      y: 11123
    },{
      NanogenreId: 3421,
      x: 9840,
      y: 4639
    },{
      NanogenreId: 3422,
      x: 8870,
      y: 6840
    },{
      NanogenreId: 3423,
      x: 11134,
      y: 10444
    },{
      NanogenreId: 3424,
      x: 12239,
      y: 5341
    },{
      NanogenreId: 3425,
      x: 8155,
      y: 8179
    },{
      NanogenreId: 3426,
      x: 7716,
      y: 5586
    },{
      NanogenreId: 3427,
      x: 5668,
      y: 5200
    },{
      NanogenreId: 3428,
      x: 7414,
      y: 4877
    },{
      NanogenreId: 3429,
      x: 8287,
      y: 2853
    },{
      NanogenreId: 3430,
      x: 7090,
      y: 11523
    },{
      NanogenreId: 3431,
      x: 7226,
      y: 3575
    },{
      NanogenreId: 3432,
      x: 5484,
      y: 8391
    },{
      NanogenreId: 3433,
      x: 6725,
      y: 5933
    },{
      NanogenreId: 3434,
      x: 12306,
      y: 6073
    },{
      NanogenreId: 3435,
      x: 10791,
      y: 9725
    },{
      NanogenreId: 3436,
      x: 5449,
      y: 8107
    },{
      NanogenreId: 3437,
      x: 6604,
      y: 8098
    },{
      NanogenreId: 3438,
      x: 9955,
      y: 4614
    },{
      NanogenreId: 3439,
      x: 10351,
      y: 5124
    },{
      NanogenreId: 3440,
      x: 8951,
      y: 9467
    },{
      NanogenreId: 3441,
      x: 9416,
      y: 5480
    },{
      NanogenreId: 3442,
      x: 5067,
      y: 9093
    },{
      NanogenreId: 3443,
      x: 6629,
      y: 8076
    },{
      NanogenreId: 3444,
      x: 3706,
      y: 10443
    },{
      NanogenreId: 3445,
      x: 7096,
      y: 11384
    },{
      NanogenreId: 3446,
      x: 8929,
      y: 5531
    },{
      NanogenreId: 3447,
      x: 8355,
      y: 4839
    },{
      NanogenreId: 3448,
      x: 11182,
      y: 10364
    },{
      NanogenreId: 3449,
      x: 7111,
      y: 3206
    },{
      NanogenreId: 3450,
      x: 7417,
      y: 3538
    },{
      NanogenreId: 3451,
      x: 5866,
      y: 6773
    },{
      NanogenreId: 3452,
      x: 9758,
      y: 5093
    },{
      NanogenreId: 3453,
      x: 3701,
      y: 9118
    },{
      NanogenreId: 3454,
      x: 3421,
      y: 9583
    },{
      NanogenreId: 3455,
      x: 9797,
      y: 4822
    },{
      NanogenreId: 3456,
      x: 9210,
      y: 7387
    },{
      NanogenreId: 3457,
      x: 8040,
      y: 6833
    },{
      NanogenreId: 3458,
      x: 7969,
      y: 8438
    },{
      NanogenreId: 3459,
      x: 9014,
      y: 4923
    },{
      NanogenreId: 3460,
      x: 6776,
      y: 6674
    },{
      NanogenreId: 3461,
      x: 6147,
      y: 6281
    },{
      NanogenreId: 3462,
      x: 8306,
      y: 10105
    },{
      NanogenreId: 3463,
      x: 9338,
      y: 4481
    },{
      NanogenreId: 3464,
      x: 7660,
      y: 11127
    },{
      NanogenreId: 3465,
      x: 7546,
      y: 4639
    },{
      NanogenreId: 3466,
      x: 7098,
      y: 11492
    },{
      NanogenreId: 3467,
      x: 10341,
      y: 10119
    },{
      NanogenreId: 3468,
      x: 9497,
      y: 3976
    },{
      NanogenreId: 3469,
      x: 5225,
      y: 6339
    },{
      NanogenreId: 3470,
      x: 10991,
      y: 10581
    },{
      NanogenreId: 3471,
      x: 12077,
      y: 9936
    },{
      NanogenreId: 3472,
      x: 9453,
      y: 5412
    },{
      NanogenreId: 3473,
      x: 10307,
      y: 11380
    },{
      NanogenreId: 3474,
      x: 10175,
      y: 5415
    },{
      NanogenreId: 3475,
      x: 10685,
      y: 7065
    },{
      NanogenreId: 3476,
      x: 10040,
      y: 6586
    },{
      NanogenreId: 3477,
      x: 8644,
      y: 12292
    },{
      NanogenreId: 3478,
      x: 7630,
      y: 10116
    },{
      NanogenreId: 3479,
      x: 10150,
      y: 7858
    },{
      NanogenreId: 3480,
      x: 6861,
      y: 9248
    },{
      NanogenreId: 3481,
      x: 4201,
      y: 6476
    },{
      NanogenreId: 3482,
      x: 7138,
      y: 4619
    },{
      NanogenreId: 3483,
      x: 4581,
      y: 8023
    },{
      NanogenreId: 3484,
      x: 8196,
      y: 3833
    },{
      NanogenreId: 3485,
      x: 9122,
      y: 5416
    },{
      NanogenreId: 3486,
      x: 7991,
      y: 10708
    },{
      NanogenreId: 3487,
      x: 11014,
      y: 10519
    },{
      NanogenreId: 3488,
      x: 10551,
      y: 10632
    },{
      NanogenreId: 3489,
      x: 8620,
      y: 5141
    },{
      NanogenreId: 3490,
      x: 8667,
      y: 5550
    },{
      NanogenreId: 3491,
      x: 7890,
      y: 9109
    },{
      NanogenreId: 3492,
      x: 12455,
      y: 9038
    },{
      NanogenreId: 3493,
      x: 6077,
      y: 5047
    },{
      NanogenreId: 3494,
      x: 5680,
      y: 6770
    },{
      NanogenreId: 3495,
      x: 10163,
      y: 10146
    },{
      NanogenreId: 3496,
      x: 9133,
      y: 9319
    },{
      NanogenreId: 3497,
      x: 6380,
      y: 9000
    },{
      NanogenreId: 3498,
      x: 9404,
      y: 4982
    },{
      NanogenreId: 3499,
      x: 7886,
      y: 9891
    },{
      NanogenreId: 3500,
      x: 8780,
      y: 10083
    },{
      NanogenreId: 3501,
      x: 7515,
      y: 6390
    },{
      NanogenreId: 3502,
      x: 9993,
      y: 4892
    },{
      NanogenreId: 3503,
      x: 9310,
      y: 5875
    },{
      NanogenreId: 3504,
      x: 9973,
      y: 9903
    },{
      NanogenreId: 3505,
      x: 9597,
      y: 9679
    },{
      NanogenreId: 3506,
      x: 10996,
      y: 1914
    },{
      NanogenreId: 3507,
      x: 8455,
      y: 2086
    },{
      NanogenreId: 3508,
      x: 4741,
      y: 7061
    },{
      NanogenreId: 3509,
      x: 7366,
      y: 10695
    },{
      NanogenreId: 3510,
      x: 8371,
      y: 4264
    },{
      NanogenreId: 3511,
      x: 7036,
      y: 9974
    },{
      NanogenreId: 3512,
      x: 6860,
      y: 5850
    },{
      NanogenreId: 3513,
      x: 9720,
      y: 4685
    },{
      NanogenreId: 3514,
      x: 6693,
      y: 4748
    },{
      NanogenreId: 3515,
      x: 3358,
      y: 9666
    },{
      NanogenreId: 3516,
      x: 6439,
      y: 9613
    },{
      NanogenreId: 3517,
      x: 12042,
      y: 8559
    },{
      NanogenreId: 3518,
      x: 11008,
      y: 11003
    },{
      NanogenreId: 3519,
      x: 8585,
      y: 8712
    },{
      NanogenreId: 3520,
      x: 9057,
      y: 7219
    },{
      NanogenreId: 3521,
      x: 10185,
      y: 10056
    },{
      NanogenreId: 3522,
      x: 5529,
      y: 11254
    },{
      NanogenreId: 3523,
      x: 9379,
      y: 9957
    },{
      NanogenreId: 3524,
      x: 8450,
      y: 11257
    },{
      NanogenreId: 3525,
      x: 5236,
      y: 7391
    },{
      NanogenreId: 3526,
      x: 8080,
      y: 9647
    },{
      NanogenreId: 3527,
      x: 5679,
      y: 11449
    },{
      NanogenreId: 3528,
      x: 10871,
      y: 10124
    },{
      NanogenreId: 3529,
      x: 9794,
      y: 9781
    },{
      NanogenreId: 3530,
      x: 8286,
      y: 4389
    },{
      NanogenreId: 3531,
      x: 6112,
      y: 10062
    },{
      NanogenreId: 3532,
      x: 7830,
      y: 7083
    },{
      NanogenreId: 3533,
      x: 7240,
      y: 2816
    },{
      NanogenreId: 3534,
      x: 6252,
      y: 7766
    },{
      NanogenreId: 3535,
      x: 11752,
      y: 9910
    },{
      NanogenreId: 3536,
      x: 7336,
      y: 7855
    },{
      NanogenreId: 3537,
      x: 9580,
      y: 5520
    },{
      NanogenreId: 3538,
      x: 7306,
      y: 11031
    },{
      NanogenreId: 3539,
      x: 6314,
      y: 8540
    },{
      NanogenreId: 3540,
      x: 8171,
      y: 5982
    },{
      NanogenreId: 3541,
      x: 7620,
      y: 7017
    },{
      NanogenreId: 3542,
      x: 9346,
      y: 7409
    },{
      NanogenreId: 3543,
      x: 10921,
      y: 10095
    },{
      NanogenreId: 3544,
      x: 7191,
      y: 10754
    },{
      NanogenreId: 3545,
      x: 12174,
      y: 5421
    },{
      NanogenreId: 3546,
      x: 6188,
      y: 6475
    },{
      NanogenreId: 3547,
      x: 4887,
      y: 10456
    },{
      NanogenreId: 3548,
      x: 5661,
      y: 3479
    },{
      NanogenreId: 3549,
      x: 9408,
      y: 5409
    },{
      NanogenreId: 3550,
      x: 4483,
      y: 8057
    },{
      NanogenreId: 3551,
      x: 10103,
      y: 7706
    },{
      NanogenreId: 3552,
      x: 8988,
      y: 6583
    },{
      NanogenreId: 3553,
      x: 6291,
      y: 4984
    },{
      NanogenreId: 3554,
      x: 4684,
      y: 8812
    },{
      NanogenreId: 3555,
      x: 9788,
      y: 5037
    },{
      NanogenreId: 3556,
      x: 7246,
      y: 10724
    },{
      NanogenreId: 3557,
      x: 4116,
      y: 10060
    },{
      NanogenreId: 3558,
      x: 8998,
      y: 10912
    },{
      NanogenreId: 3559,
      x: 9310,
      y: 4799
    },{
      NanogenreId: 3560,
      x: 6742,
      y: 11393
    },{
      NanogenreId: 3561,
      x: 8287,
      y: 8996
    },{
      NanogenreId: 3562,
      x: 7532,
      y: 7512
    },{
      NanogenreId: 3563,
      x: 9973,
      y: 7443
    },{
      NanogenreId: 3564,
      x: 9631,
      y: 5019
    },{
      NanogenreId: 3565,
      x: 7583,
      y: 5750
    },{
      NanogenreId: 3566,
      x: 6966,
      y: 7376
    },{
      NanogenreId: 3567,
      x: 9485,
      y: 11112
    },{
      NanogenreId: 3568,
      x: 8057,
      y: 9414
    },{
      NanogenreId: 3569,
      x: 5571,
      y: 3981
    },{
      NanogenreId: 3570,
      x: 9815,
      y: 4763
    },{
      NanogenreId: 3571,
      x: 13706,
      y: 8522
    },{
      NanogenreId: 3572,
      x: 8175,
      y: 10057
    },{
      NanogenreId: 3573,
      x: 8303,
      y: 5603
    },{
      NanogenreId: 3574,
      x: 9086,
      y: 4705
    },{
      NanogenreId: 3575,
      x: 11076,
      y: 10495
    },{
      NanogenreId: 3576,
      x: 8674,
      y: 6896
    },{
      NanogenreId: 3577,
      x: 6705,
      y: 8790
    },{
      NanogenreId: 3578,
      x: 7284,
      y: 9969
    },{
      NanogenreId: 3579,
      x: 6575,
      y: 11926
    },{
      NanogenreId: 3580,
      x: 7273,
      y: 7318
    },{
      NanogenreId: 3581,
      x: 8743,
      y: 8953
    },{
      NanogenreId: 3582,
      x: 7607,
      y: 10534
    },{
      NanogenreId: 3583,
      x: 9109,
      y: 5170
    },{
      NanogenreId: 3584,
      x: 6309,
      y: 9767
    },{
      NanogenreId: 3585,
      x: 7436,
      y: 5991
    },{
      NanogenreId: 3586,
      x: 8047,
      y: 5467
    },{
      NanogenreId: 3587,
      x: 8844,
      y: 2313
    },{
      NanogenreId: 3588,
      x: 10347,
      y: 9591
    },{
      NanogenreId: 3589,
      x: 9896,
      y: 4939
    },{
      NanogenreId: 3590,
      x: 9968,
      y: 9750
    },{
      NanogenreId: 3591,
      x: 9810,
      y: 7927
    },{
      NanogenreId: 3592,
      x: 11188,
      y: 7718
    },{
      NanogenreId: 3593,
      x: 9319,
      y: 10118
    },{
      NanogenreId: 3594,
      x: 7302,
      y: 11200
    },{
      NanogenreId: 3595,
      x: 10663,
      y: 9808
    },{
      NanogenreId: 3596,
      x: 5068,
      y: 9358
    },{
      NanogenreId: 3597,
      x: 7176,
      y: 10191
    },{
      NanogenreId: 3598,
      x: 7318,
      y: 10313
    },{
      NanogenreId: 3599,
      x: 8179,
      y: 11695
    },{
      NanogenreId: 3600,
      x: 11475,
      y: 9945
    },{
      NanogenreId: 3601,
      x: 9970,
      y: 4801
    },{
      NanogenreId: 3602,
      x: 9937,
      y: 4712
    },{
      NanogenreId: 3603,
      x: 9693,
      y: 5236
    },{
      NanogenreId: 3604,
      x: 9546,
      y: 11441
    },{
      NanogenreId: 3605,
      x: 2978,
      y: 8391
    },{
      NanogenreId: 3606,
      x: 11384,
      y: 8645
    },{
      NanogenreId: 3607,
      x: 9414,
      y: 5243
    },{
      NanogenreId: 3608,
      x: 6474,
      y: 6951
    },{
      NanogenreId: 3609,
      x: 5790,
      y: 10055
    },{
      NanogenreId: 3610,
      x: 9918,
      y: 4345
    },{
      NanogenreId: 3611,
      x: 9740,
      y: 4923
    },{
      NanogenreId: 3612,
      x: 12169,
      y: 8681
    },{
      NanogenreId: 3613,
      x: 9191,
      y: 6557
    },{
      NanogenreId: 3614,
      x: 9895,
      y: 7389
    },{
      NanogenreId: 3615,
      x: 7058,
      y: 10728
    },{
      NanogenreId: 3616,
      x: 9720,
      y: 9615
    },{
      NanogenreId: 3617,
      x: 9724,
      y: 5262
    },{
      NanogenreId: 3618,
      x: 6783,
      y: 8242
    },{
      NanogenreId: 3619,
      x: 8412,
      y: 7506
    },{
      NanogenreId: 3620,
      x: 7020,
      y: 9404
    },{
      NanogenreId: 3621,
      x: 9802,
      y: 7977
    },{
      NanogenreId: 3622,
      x: 3849,
      y: 8083
    },{
      NanogenreId: 3623,
      x: 5192,
      y: 7683
    },{
      NanogenreId: 3624,
      x: 9599,
      y: 5074
    },{
      NanogenreId: 3625,
      x: 12957,
      y: 7612
    },{
      NanogenreId: 3626,
      x: 7662,
      y: 4773
    },{
      NanogenreId: 3627,
      x: 6424,
      y: 7263
    },{
      NanogenreId: 3628,
      x: 6259,
      y: 4461
    },{
      NanogenreId: 3629,
      x: 7731,
      y: 11109
    },{
      NanogenreId: 3630,
      x: 8490,
      y: 10029
    },{
      NanogenreId: 3631,
      x: 6374,
      y: 9583
    },{
      NanogenreId: 3632,
      x: 10811,
      y: 9614
    },{
      NanogenreId: 3633,
      x: 8281,
      y: 9133
    },{
      NanogenreId: 3634,
      x: 5781,
      y: 7247
    },{
      NanogenreId: 3635,
      x: 9948,
      y: 4441
    },{
      NanogenreId: 3636,
      x: 10346,
      y: 8203
    },{
      NanogenreId: 3637,
      x: 6063,
      y: 7545
    },{
      NanogenreId: 3638,
      x: 8035,
      y: 5185
    },{
      NanogenreId: 3639,
      x: 10162,
      y: 9028
    },{
      NanogenreId: 3640,
      x: 6376,
      y: 10598
    },{
      NanogenreId: 3641,
      x: 8409,
      y: 8701
    },{
      NanogenreId: 3642,
      x: 6302,
      y: 11235
    },{
      NanogenreId: 3643,
      x: 9249,
      y: 5127
    },{
      NanogenreId: 3644,
      x: 7341,
      y: 6480
    },{
      NanogenreId: 3645,
      x: 12248,
      y: 6780
    },{
      NanogenreId: 3646,
      x: 9856,
      y: 10122
    },{
      NanogenreId: 3647,
      x: 7471,
      y: 6722
    },{
      NanogenreId: 3648,
      x: 9739,
      y: 5403
    },{
      NanogenreId: 3649,
      x: 9754,
      y: 6357
    },{
      NanogenreId: 3650,
      x: 7715,
      y: 3540
    },{
      NanogenreId: 3651,
      x: 6188,
      y: 8757
    },{
      NanogenreId: 3652,
      x: 8100,
      y: 11371
    },{
      NanogenreId: 3653,
      x: 8318,
      y: 9250
    },{
      NanogenreId: 3654,
      x: 10055,
      y: 4955
    },{
      NanogenreId: 3655,
      x: 8610,
      y: 9029
    },{
      NanogenreId: 3656,
      x: 9721,
      y: 5452
    },{
      NanogenreId: 3657,
      x: 3521,
      y: 6208
    },{
      NanogenreId: 3658,
      x: 8265,
      y: 4791
    },{
      NanogenreId: 3659,
      x: 7087,
      y: 4455
    },{
      NanogenreId: 3660,
      x: 6799,
      y: 5963
    },{
      NanogenreId: 3661,
      x: 9392,
      y: 4985
    },{
      NanogenreId: 3662,
      x: 7912,
      y: 6232
    },{
      NanogenreId: 3663,
      x: 9915,
      y: 5046
    },{
      NanogenreId: 3664,
      x: 7169,
      y: 9757
    },{
      NanogenreId: 3665,
      x: 10820,
      y: 10270
    },{
      NanogenreId: 3666,
      x: 5996,
      y: 10797
    },{
      NanogenreId: 3667,
      x: 4376,
      y: 9135
    },{
      NanogenreId: 3668,
      x: 6742,
      y: 5249
    },{
      NanogenreId: 3669,
      x: 8984,
      y: 7652
    },{
      NanogenreId: 3670,
      x: 8047,
      y: 5552
    },{
      NanogenreId: 3671,
      x: 11017,
      y: 10676
    },{
      NanogenreId: 3672,
      x: 8890,
      y: 9860
    },{
      NanogenreId: 3673,
      x: 9345,
      y: 7913
    },{
      NanogenreId: 3674,
      x: 7600,
      y: 5287
    },{
      NanogenreId: 3675,
      x: 8395,
      y: 9640
    },{
      NanogenreId: 3676,
      x: 8171,
      y: 11854
    },{
      NanogenreId: 3677,
      x: 9133,
      y: 4908
    },{
      NanogenreId: 3678,
      x: 4401,
      y: 10158
    },{
      NanogenreId: 3679,
      x: 11730,
      y: 7364
    },{
      NanogenreId: 3680,
      x: 10920,
      y: 7537
    },{
      NanogenreId: 3681,
      x: 6843,
      y: 6130
    },{
      NanogenreId: 3682,
      x: 8377,
      y: 5759
    },{
      NanogenreId: 3683,
      x: 9537,
      y: 4911
    },{
      NanogenreId: 3684,
      x: 7564,
      y: 5069
    },{
      NanogenreId: 3685,
      x: 10369,
      y: 10312
    },{
      NanogenreId: 3686,
      x: 3951,
      y: 10875
    },{
      NanogenreId: 3687,
      x: 5983,
      y: 5925
    },{
      NanogenreId: 3688,
      x: 9954,
      y: 9542
    },{
      NanogenreId: 3689,
      x: 6424,
      y: 6656
    },{
      NanogenreId: 3690,
      x: 8971,
      y: 11860
    },{
      NanogenreId: 3691,
      x: 6916,
      y: 5895
    },{
      NanogenreId: 3692,
      x: 8763,
      y: 4594
    },{
      NanogenreId: 3693,
      x: 8843,
      y: 4789
    },{
      NanogenreId: 3694,
      x: 9519,
      y: 6229
    },{
      NanogenreId: 3695,
      x: 8392,
      y: 11169
    },{
      NanogenreId: 3696,
      x: 8053,
      y: 9019
    },{
      NanogenreId: 3697,
      x: 11638,
      y: 8586
    },{
      NanogenreId: 3698,
      x: 6725,
      y: 11149
    },{
      NanogenreId: 3699,
      x: 9234,
      y: 10061
    },{
      NanogenreId: 3700,
      x: 10994,
      y: 9735
    },{
      NanogenreId: 3701,
      x: 5432,
      y: 6646
    },{
      NanogenreId: 3702,
      x: 10653,
      y: 9593
    },{
      NanogenreId: 3703,
      x: 7293,
      y: 8924
    },{
      NanogenreId: 3704,
      x: 10759,
      y: 10191
    },{
      NanogenreId: 3705,
      x: 7821,
      y: 6210
    },{
      NanogenreId: 3706,
      x: 9369,
      y: 4626
    },{
      NanogenreId: 3707,
      x: 9042,
      y: 4956
    },{
      NanogenreId: 3708,
      x: 7941,
      y: 8112
    },{
      NanogenreId: 3709,
      x: 10148,
      y: 10789
    },{
      NanogenreId: 3710,
      x: 5775,
      y: 10402
    },{
      NanogenreId: 3711,
      x: 4704,
      y: 9928
    },{
      NanogenreId: 3712,
      x: 9237,
      y: 6353
    },{
      NanogenreId: 3713,
      x: 4282,
      y: 8415
    },{
      NanogenreId: 3714,
      x: 8919,
      y: 4853
    },{
      NanogenreId: 3715,
      x: 8157,
      y: 9474
    },{
      NanogenreId: 3716,
      x: 9276,
      y: 11271
    },{
      NanogenreId: 3717,
      x: 10167,
      y: 7217
    },{
      NanogenreId: 3718,
      x: 11041,
      y: 10823
    },{
      NanogenreId: 3719,
      x: 9059,
      y: 4688
    },{
      NanogenreId: 3720,
      x: 9155,
      y: 4795
    },{
      NanogenreId: 3721,
      x: 10068,
      y: 9458
    },{
      NanogenreId: 3722,
      x: 10536,
      y: 5437
    },{
      NanogenreId: 3723,
      x: 9096,
      y: 10069
    },{
      NanogenreId: 3724,
      x: 6853,
      y: 10661
    },{
      NanogenreId: 3725,
      x: 5338,
      y: 7626
    },{
      NanogenreId: 3726,
      x: 9688,
      y: 9835
    },{
      NanogenreId: 3727,
      x: 5341,
      y: 6445
    },{
      NanogenreId: 3728,
      x: 7755,
      y: 8399
    },{
      NanogenreId: 3729,
      x: 8329,
      y: 6804
    },{
      NanogenreId: 3730,
      x: 9114,
      y: 12013
    },{
      NanogenreId: 3731,
      x: 11604,
      y: 10736
    },{
      NanogenreId: 3732,
      x: 9348,
      y: 5006
    },{
      NanogenreId: 3733,
      x: 8341,
      y: 8160
    },{
      NanogenreId: 3734,
      x: 10026,
      y: 8739
    },{
      NanogenreId: 3735,
      x: 5547,
      y: 4404
    },{
      NanogenreId: 3736,
      x: 9732,
      y: 5115
    },{
      NanogenreId: 3737,
      x: 7862,
      y: 10525
    },{
      NanogenreId: 3738,
      x: 7723,
      y: 7393
    },{
      NanogenreId: 3739,
      x: 7467,
      y: 4519
    },{
      NanogenreId: 3740,
      x: 12370,
      y: 8222
    },{
      NanogenreId: 3741,
      x: 9966,
      y: 10609
    },{
      NanogenreId: 3742,
      x: 7784,
      y: 4570
    },{
      NanogenreId: 3743,
      x: 9365,
      y: 6721
    },{
      NanogenreId: 3744,
      x: 9228,
      y: 11559
    },{
      NanogenreId: 3745,
      x: 9055,
      y: 4922
    },{
      NanogenreId: 3746,
      x: 7682,
      y: 5104
    },{
      NanogenreId: 3747,
      x: 9039,
      y: 9725
    },{
      NanogenreId: 3748,
      x: 9716,
      y: 6479
    },{
      NanogenreId: 3749,
      x: 7573,
      y: 7860
    },{
      NanogenreId: 3750,
      x: 7797,
      y: 7932
    },{
      NanogenreId: 3751,
      x: 9752,
      y: 7831
    },{
      NanogenreId: 3752,
      x: 11516,
      y: 8424
    },{
      NanogenreId: 3753,
      x: 10131,
      y: 9866
    },{
      NanogenreId: 3754,
      x: 7331,
      y: 10012
    },{
      NanogenreId: 3755,
      x: 9638,
      y: 4479
    },{
      NanogenreId: 3756,
      x: 11082,
      y: 9289
    },{
      NanogenreId: 3757,
      x: 8242,
      y: 5446
    },{
      NanogenreId: 3758,
      x: 7884,
      y: 5372
    },{
      NanogenreId: 3759,
      x: 9978,
      y: 4680
    },{
      NanogenreId: 3760,
      x: 8053,
      y: 9729
    },{
      NanogenreId: 3761,
      x: 7770,
      y: 5713
    },{
      NanogenreId: 3762,
      x: 7192,
      y: 10010
    },{
      NanogenreId: 3763,
      x: 6468,
      y: 6437
    },{
      NanogenreId: 3764,
      x: 10004,
      y: 4636
    },{
      NanogenreId: 3765,
      x: 7121,
      y: 9960
    },{
      NanogenreId: 3766,
      x: 6360,
      y: 7200
    },{
      NanogenreId: 3767,
      x: 6085,
      y: 11168
    },{
      NanogenreId: 3768,
      x: 10084,
      y: 4760
    },{
      NanogenreId: 3769,
      x: 9567,
      y: 10646
    },{
      NanogenreId: 3770,
      x: 10419,
      y: 9839
    },{
      NanogenreId: 3771,
      x: 11288,
      y: 10972
    },{
      NanogenreId: 3772,
      x: 10334,
      y: 10485
    },{
      NanogenreId: 3773,
      x: 9980,
      y: 5147
    },{
      NanogenreId: 3774,
      x: 8944,
      y: 5165
    },{
      NanogenreId: 3775,
      x: 7092,
      y: 5640
    },{
      NanogenreId: 3776,
      x: 9824,
      y: 4728
    },{
      NanogenreId: 3777,
      x: 9912,
      y: 4798
    },{
      NanogenreId: 3778,
      x: 4886,
      y: 6554
    },{
      NanogenreId: 3779,
      x: 9283,
      y: 5351
    },{
      NanogenreId: 3780,
      x: 3483,
      y: 6753
    },{
      NanogenreId: 3781,
      x: 8185,
      y: 7827
    },{
      NanogenreId: 3782,
      x: 9633,
      y: 8236
    },{
      NanogenreId: 3783,
      x: 12170,
      y: 6257
    },{
      NanogenreId: 3784,
      x: 9788,
      y: 5440
    },{
      NanogenreId: 3785,
      x: 7091,
      y: 7450
    },{
      NanogenreId: 3786,
      x: 10599,
      y: 4756
    },{
      NanogenreId: 3787,
      x: 4718,
      y: 8212
    },{
      NanogenreId: 3788,
      x: 9551,
      y: 4851
    },{
      NanogenreId: 3789,
      x: 9766,
      y: 4669
    },{
      NanogenreId: 3790,
      x: 5998,
      y: 6046
    },{
      NanogenreId: 3791,
      x: 9333,
      y: 5182
    },{
      NanogenreId: 3792,
      x: 10099,
      y: 4608
    },{
      NanogenreId: 3793,
      x: 10660,
      y: 10191
    },{
      NanogenreId: 3794,
      x: 7951,
      y: 6829
    },{
      NanogenreId: 3795,
      x: 9735,
      y: 7191
    },{
      NanogenreId: 3796,
      x: 4013,
      y: 10042
    },{
      NanogenreId: 3797,
      x: 6749,
      y: 6303
    },{
      NanogenreId: 3798,
      x: 9136,
      y: 6656
    },{
      NanogenreId: 3799,
      x: 7823,
      y: 6528
    },{
      NanogenreId: 3800,
      x: 10790,
      y: 10674
    },{
      NanogenreId: 3801,
      x: 10775,
      y: 10598
    },{
      NanogenreId: 3802,
      x: 9630,
      y: 4835
    },{
      NanogenreId: 3803,
      x: 6039,
      y: 6146
    },{
      NanogenreId: 3804,
      x: 8813,
      y: 3130
    },{
      NanogenreId: 3805,
      x: 10755,
      y: 10560
    },{
      NanogenreId: 3806,
      x: 3217,
      y: 9725
    },{
      NanogenreId: 3807,
      x: 9677,
      y: 8268
    },{
      NanogenreId: 3808,
      x: 4205,
      y: 11672
    },{
      NanogenreId: 3809,
      x: 10675,
      y: 5421
    },{
      NanogenreId: 3810,
      x: 9168,
      y: 8162
    },{
      NanogenreId: 3811,
      x: 7226,
      y: 6236
    },{
      NanogenreId: 3812,
      x: 8524,
      y: 6077
    },{
      NanogenreId: 3813,
      x: 7035,
      y: 8862
    },{
      NanogenreId: 3814,
      x: 10049,
      y: 5188
    },{
      NanogenreId: 3815,
      x: 8830,
      y: 5329
    },{
      NanogenreId: 3816,
      x: 6758,
      y: 10648
    },{
      NanogenreId: 3817,
      x: 9140,
      y: 11171
    },{
      NanogenreId: 3818,
      x: 9494,
      y: 5541
    },{
      NanogenreId: 3819,
      x: 8780,
      y: 10803
    },{
      NanogenreId: 3820,
      x: 7921,
      y: 10554
    },{
      NanogenreId: 3821,
      x: 6248,
      y: 5693
    },{
      NanogenreId: 3822,
      x: 5598,
      y: 12109
    },{
      NanogenreId: 3823,
      x: 6951,
      y: 8256
    },{
      NanogenreId: 3824,
      x: 10587,
      y: 10335
    },{
      NanogenreId: 3825,
      x: 7862,
      y: 7647
    },{
      NanogenreId: 3826,
      x: 8181,
      y: 5927
    },{
      NanogenreId: 3827,
      x: 10774,
      y: 6300
    },{
      NanogenreId: 3828,
      x: 5875,
      y: 6510
    },{
      NanogenreId: 3829,
      x: 10125,
      y: 9929
    },{
      NanogenreId: 3830,
      x: 5686,
      y: 10518
    },{
      NanogenreId: 3831,
      x: 10467,
      y: 9331
    },{
      NanogenreId: 3832,
      x: 9866,
      y: 4726
    },{
      NanogenreId: 3833,
      x: 3682,
      y: 7994
    },{
      NanogenreId: 3834,
      x: 7812,
      y: 5912
    },{
      NanogenreId: 3835,
      x: 9698,
      y: 9098
    },{
      NanogenreId: 3836,
      x: 8652,
      y: 7415
    },{
      NanogenreId: 3837,
      x: 8584,
      y: 3858
    },{
      NanogenreId: 3838,
      x: 5948,
      y: 10505
    },{
      NanogenreId: 3839,
      x: 9377,
      y: 9121
    },{
      NanogenreId: 3840,
      x: 11766,
      y: 9993
    },{
      NanogenreId: 3841,
      x: 9197,
      y: 5341
    },{
      NanogenreId: 3842,
      x: 10489,
      y: 10276
    },{
      NanogenreId: 3843,
      x: 5902,
      y: 7489
    },{
      NanogenreId: 3844,
      x: 6705,
      y: 7612
    },{
      NanogenreId: 3845,
      x: 6767,
      y: 8629
    },{
      NanogenreId: 3846,
      x: 7840,
      y: 8693
    },{
      NanogenreId: 3847,
      x: 6856,
      y: 10063
    },{
      NanogenreId: 3848,
      x: 6841,
      y: 3298
    },{
      NanogenreId: 3849,
      x: 9970,
      y: 10514
    },{
      NanogenreId: 3850,
      x: 10680,
      y: 9233
    },{
      NanogenreId: 3851,
      x: 7604,
      y: 3720
    },{
      NanogenreId: 3852,
      x: 9327,
      y: 4722
    },{
      NanogenreId: 3853,
      x: 10816,
      y: 10803
    },{
      NanogenreId: 3854,
      x: 9976,
      y: 4568
    },{
      NanogenreId: 3855,
      x: 10028,
      y: 7575
    },{
      NanogenreId: 3856,
      x: 8260,
      y: 5313
    },{
      NanogenreId: 3857,
      x: 9889,
      y: 5567
    },{
      NanogenreId: 3858,
      x: 9898,
      y: 11588
    },{
      NanogenreId: 3859,
      x: 9670,
      y: 5308
    },{
      NanogenreId: 3860,
      x: 7307,
      y: 9345
    },{
      NanogenreId: 3861,
      x: 10070,
      y: 6648
    },{
      NanogenreId: 3862,
      x: 7181,
      y: 6164
    },{
      NanogenreId: 3863,
      x: 10273,
      y: 10181
    },{
      NanogenreId: 3864,
      x: 8633,
      y: 5692
    },{
      NanogenreId: 3865,
      x: 9662,
      y: 5219
    },{
      NanogenreId: 3866,
      x: 10755,
      y: 10834
    },{
      NanogenreId: 3867,
      x: 6858,
      y: 3096
    },{
      NanogenreId: 3868,
      x: 3517,
      y: 9472
    },{
      NanogenreId: 3869,
      x: 13601,
      y: 3536
    },{
      NanogenreId: 3870,
      x: 10700,
      y: 10314
    },{
      NanogenreId: 3871,
      x: 10020,
      y: 9776
    },{
      NanogenreId: 3872,
      x: 8105,
      y: 4776
    },{
      NanogenreId: 3873,
      x: 11116,
      y: 10368
    },{
      NanogenreId: 3874,
      x: 7190,
      y: 8210
    },{
      NanogenreId: 3875,
      x: 8779,
      y: 7508
    },{
      NanogenreId: 3876,
      x: 7659,
      y: 9632
    },{
      NanogenreId: 3877,
      x: 7413,
      y: 4896
    },{
      NanogenreId: 3878,
      x: 8619,
      y: 4498
    },{
      NanogenreId: 3879,
      x: 7575,
      y: 10507
    },{
      NanogenreId: 3880,
      x: 8237,
      y: 6595
    },{
      NanogenreId: 3881,
      x: 8072,
      y: 5284
    },{
      NanogenreId: 3882,
      x: 5911,
      y: 5630
    },{
      NanogenreId: 3883,
      x: 11827,
      y: 7239
    },{
      NanogenreId: 3884,
      x: 8961,
      y: 5417
    },{
      NanogenreId: 3885,
      x: 8927,
      y: 2517
    },{
      NanogenreId: 3886,
      x: 8730,
      y: 7235
    },{
      NanogenreId: 3887,
      x: 6977,
      y: 6870
    },{
      NanogenreId: 3888,
      x: 10439,
      y: 10076
    },{
      NanogenreId: 3889,
      x: 7608,
      y: 6273
    },{
      NanogenreId: 3890,
      x: 8767,
      y: 4861
    },{
      NanogenreId: 3891,
      x: 6986,
      y: 6079
    },{
      NanogenreId: 3892,
      x: 6271,
      y: 11557
    },{
      NanogenreId: 3893,
      x: 6722,
      y: 6638
    },{
      NanogenreId: 3894,
      x: 9687,
      y: 9922
    },{
      NanogenreId: 3895,
      x: 6904,
      y: 4346
    },{
      NanogenreId: 3896,
      x: 12255,
      y: 7032
    },{
      NanogenreId: 3897,
      x: 8425,
      y: 5902
    },{
      NanogenreId: 3898,
      x: 5787,
      y: 5696
    },{
      NanogenreId: 3899,
      x: 9778,
      y: 4539
    },{
      NanogenreId: 3900,
      x: 12647,
      y: 2641
    },{
      NanogenreId: 3901,
      x: 6639,
      y: 6040
    },{
      NanogenreId: 3902,
      x: 11249,
      y: 10534
    },{
      NanogenreId: 3903,
      x: 10911,
      y: 8952
    },{
      NanogenreId: 3904,
      x: 7078,
      y: 9571
    },{
      NanogenreId: 3905,
      x: 7018,
      y: 9491
    },{
      NanogenreId: 3906,
      x: 6721,
      y: 6718
    },{
      NanogenreId: 3907,
      x: 4644,
      y: 9261
    },{
      NanogenreId: 3908,
      x: 6069,
      y: 5407
    },{
      NanogenreId: 3909,
      x: 8496,
      y: 6020
    },{
      NanogenreId: 3910,
      x: 10334,
      y: 10058
    },{
      NanogenreId: 3911,
      x: 10644,
      y: 10527
    },{
      NanogenreId: 3912,
      x: 5271,
      y: 7952
    },{
      NanogenreId: 3913,
      x: 7594,
      y: 8819
    },{
      NanogenreId: 3914,
      x: 7552,
      y: 5118
    },{
      NanogenreId: 3915,
      x: 7924,
      y: 5010
    },{
      NanogenreId: 3916,
      x: 8409,
      y: 9812
    },{
      NanogenreId: 3917,
      x: 7795,
      y: 6963
    },{
      NanogenreId: 3918,
      x: 9267,
      y: 4657
    },{
      NanogenreId: 3919,
      x: 5359,
      y: 6098
    },{
      NanogenreId: 3920,
      x: 6061,
      y: 4505
    },{
      NanogenreId: 3921,
      x: 8249,
      y: 7479
    },{
      NanogenreId: 3922,
      x: 7894,
      y: 9388
    },{
      NanogenreId: 3923,
      x: 11636,
      y: 10359
    },{
      NanogenreId: 3924,
      x: 5599,
      y: 10059
    },{
      NanogenreId: 3925,
      x: 6298,
      y: 8944
    },{
      NanogenreId: 3926,
      x: 6402,
      y: 7743
    },{
      NanogenreId: 3927,
      x: 6272,
      y: 7803
    },{
      NanogenreId: 3928,
      x: 4972,
      y: 6757
    },{
      NanogenreId: 3929,
      x: 6211,
      y: 7427
    },{
      NanogenreId: 3930,
      x: 8915,
      y: 4966
    },{
      NanogenreId: 3931,
      x: 8006,
      y: 7722
    },{
      NanogenreId: 3932,
      x: 7648,
      y: 6453
    },{
      NanogenreId: 3933,
      x: 8908,
      y: 8792
    },{
      NanogenreId: 3934,
      x: 6616,
      y: 5839
    },{
      NanogenreId: 3935,
      x: 10988,
      y: 5452
    },{
      NanogenreId: 3936,
      x: 6560,
      y: 8465
    },{
      NanogenreId: 3937,
      x: 8175,
      y: 7138
    },{
      NanogenreId: 3938,
      x: 8182,
      y: 5557
    },{
      NanogenreId: 3939,
      x: 8809,
      y: 5252
    },{
      NanogenreId: 3940,
      x: 10914,
      y: 10444
    },{
      NanogenreId: 3941,
      x: 7021,
      y: 10442
    },{
      NanogenreId: 3942,
      x: 10119,
      y: 10382
    },{
      NanogenreId: 3943,
      x: 6910,
      y: 11607
    },{
      NanogenreId: 3944,
      x: 11841,
      y: 7364
    },{
      NanogenreId: 3945,
      x: 9299,
      y: 4938
    },{
      NanogenreId: 3946,
      x: 8875,
      y: 8960
    },{
      NanogenreId: 3947,
      x: 7921,
      y: 4437
    },{
      NanogenreId: 3948,
      x: 5634,
      y: 6025
    },{
      NanogenreId: 3949,
      x: 6639,
      y: 7939
    },{
      NanogenreId: 3950,
      x: 9750,
      y: 5113
    },{
      NanogenreId: 3951,
      x: 9552,
      y: 4885
    },{
      NanogenreId: 3952,
      x: 6339,
      y: 8334
    },{
      NanogenreId: 3953,
      x: 11595,
      y: 8116
    },{
      NanogenreId: 3954,
      x: 6784,
      y: 7612
    },{
      NanogenreId: 3955,
      x: 7777,
      y: 9276
    },{
      NanogenreId: 3956,
      x: 7206,
      y: 5963
    },{
      NanogenreId: 3957,
      x: 7654,
      y: 10219
    },{
      NanogenreId: 3958,
      x: 7709,
      y: 7853
    },{
      NanogenreId: 3959,
      x: 7482,
      y: 4782
    },{
      NanogenreId: 3960,
      x: 4146,
      y: 9767
    },{
      NanogenreId: 3961,
      x: 9470,
      y: 5274
    },{
      NanogenreId: 3962,
      x: 9848,
      y: 4797
    },{
      NanogenreId: 3963,
      x: 8888,
      y: 8710
    },{
      NanogenreId: 3964,
      x: 8877,
      y: 4884
    },{
      NanogenreId: 3965,
      x: 11357,
      y: 4889
    },{
      NanogenreId: 3966,
      x: 7129,
      y: 9262
    },{
      NanogenreId: 3967,
      x: 7878,
      y: 7124
    },{
      NanogenreId: 3968,
      x: 13040,
      y: 6571
    },{
      NanogenreId: 3969,
      x: 7215,
      y: 9299
    },{
      NanogenreId: 3970,
      x: 11198,
      y: 10989
    },{
      NanogenreId: 3971,
      x: 6474,
      y: 3390
    },{
      NanogenreId: 3972,
      x: 10401,
      y: 6093
    },{
      NanogenreId: 3973,
      x: 8393,
      y: 10015
    },{
      NanogenreId: 3974,
      x: 8611,
      y: 7297
    },{
      NanogenreId: 3975,
      x: 7622,
      y: 6624
    },{
      NanogenreId: 3976,
      x: 9898,
      y: 9569
    },{
      NanogenreId: 3977,
      x: 5869,
      y: 8342
    },{
      NanogenreId: 3978,
      x: 10105,
      y: 5069
    },{
      NanogenreId: 3979,
      x: 4818,
      y: 9693
    },{
      NanogenreId: 3980,
      x: 7563,
      y: 7721
    },{
      NanogenreId: 3981,
      x: 4707,
      y: 10255
    },{
      NanogenreId: 3982,
      x: 5286,
      y: 6085
    },{
      NanogenreId: 3983,
      x: 7026,
      y: 8323
    },{
      NanogenreId: 3984,
      x: 10749,
      y: 9020
    },{
      NanogenreId: 3985,
      x: 7699,
      y: 8886
    },{
      NanogenreId: 3986,
      x: 8071,
      y: 8131
    },{
      NanogenreId: 3987,
      x: 7494,
      y: 9090
    },{
      NanogenreId: 3988,
      x: 7748,
      y: 6652
    },{
      NanogenreId: 3989,
      x: 10389,
      y: 9814
    },{
      NanogenreId: 3990,
      x: 8994,
      y: 9126
    },{
      NanogenreId: 3991,
      x: 9373,
      y: 4869
    },{
      NanogenreId: 3992,
      x: 4889,
      y: 8520
    },{
      NanogenreId: 3993,
      x: 9787,
      y: 6481
    },{
      NanogenreId: 3994,
      x: 9008,
      y: 5388
    },{
      NanogenreId: 3995,
      x: 6684,
      y: 11719
    },{
      NanogenreId: 3996,
      x: 10360,
      y: 10871
    },{
      NanogenreId: 3997,
      x: 9204,
      y: 4864
    },{
      NanogenreId: 3998,
      x: 11699,
      y: 6888
    },{
      NanogenreId: 3999,
      x: 7572,
      y: 6279
    },{
      NanogenreId: 4000,
      x: 3652,
      y: 8591
    },{
      NanogenreId: 4001,
      x: 10168,
      y: 9432
    },{
      NanogenreId: 4002,
      x: 6999,
      y: 11447
    },{
      NanogenreId: 4003,
      x: 4589,
      y: 7147
    },{
      NanogenreId: 4004,
      x: 8900,
      y: 8296
    },{
      NanogenreId: 4005,
      x: 8859,
      y: 5857
    },{
      NanogenreId: 4006,
      x: 8179,
      y: 10423
    },{
      NanogenreId: 4007,
      x: 7405,
      y: 5686
    },{
      NanogenreId: 4008,
      x: 5471,
      y: 6766
    },{
      NanogenreId: 4009,
      x: 11931,
      y: 10291
    },{
      NanogenreId: 4010,
      x: 6438,
      y: 8390
    },{
      NanogenreId: 4011,
      x: 9262,
      y: 11333
    },{
      NanogenreId: 4012,
      x: 10145,
      y: 6937
    },{
      NanogenreId: 4013,
      x: 5938,
      y: 7037
    },{
      NanogenreId: 4014,
      x: 9026,
      y: 8643
    },{
      NanogenreId: 4015,
      x: 5874,
      y: 9212
    },{
      NanogenreId: 4016,
      x: 3725,
      y: 9521
    },{
      NanogenreId: 4017,
      x: 4511,
      y: 4853
    },{
      NanogenreId: 4018,
      x: 8955,
      y: 6181
    },{
      NanogenreId: 4019,
      x: 7647,
      y: 5251
    },{
      NanogenreId: 4020,
      x: 8743,
      y: 5487
    },{
      NanogenreId: 4021,
      x: 8501,
      y: 4732
    },{
      NanogenreId: 4022,
      x: 11366,
      y: 6811
    },{
      NanogenreId: 4023,
      x: 9088,
      y: 9082
    },{
      NanogenreId: 4024,
      x: 3736,
      y: 9267
    },{
      NanogenreId: 4025,
      x: 13415,
      y: 3727
    },{
      NanogenreId: 4026,
      x: 8799,
      y: 9196
    },{
      NanogenreId: 4027,
      x: 8433,
      y: 7829
    },{
      NanogenreId: 4028,
      x: 9765,
      y: 5130
    },{
      NanogenreId: 4029,
      x: 7514,
      y: 6964
    },{
      NanogenreId: 4030,
      x: 11063,
      y: 10933
    },{
      NanogenreId: 4031,
      x: 7133,
      y: 7912
    },{
      NanogenreId: 4032,
      x: 7739,
      y: 8262
    },{
      NanogenreId: 4033,
      x: 8380,
      y: 5847
    },{
      NanogenreId: 4034,
      x: 8107,
      y: 6043
    },{
      NanogenreId: 4035,
      x: 6999,
      y: 8230
    },{
      NanogenreId: 4036,
      x: 3365,
      y: 8905
    },{
      NanogenreId: 4037,
      x: 3990,
      y: 6956
    },{
      NanogenreId: 4038,
      x: 9561,
      y: 5299
    },{
      NanogenreId: 4039,
      x: 8769,
      y: 5232
    },{
      NanogenreId: 4040,
      x: 9204,
      y: 9881
    },{
      NanogenreId: 4041,
      x: 11592,
      y: 9708
    },{
      NanogenreId: 4042,
      x: 5802,
      y: 7100
    },{
      NanogenreId: 4043,
      x: 9208,
      y: 8063
    },{
      NanogenreId: 4044,
      x: 8627,
      y: 5008
    },{
      NanogenreId: 4045,
      x: 6138,
      y: 7866
    },{
      NanogenreId: 4046,
      x: 7892,
      y: 6756
    },{
      NanogenreId: 4047,
      x: 9039,
      y: 4138
    },{
      NanogenreId: 4048,
      x: 7058,
      y: 5839
    },{
      NanogenreId: 4049,
      x: 10446,
      y: 10934
    },{
      NanogenreId: 4050,
      x: 6249,
      y: 6011
    },{
      NanogenreId: 4051,
      x: 8309,
      y: 5171
    },{
      NanogenreId: 4052,
      x: 6010,
      y: 6344
    },{
      NanogenreId: 4053,
      x: 9907,
      y: 4847
    },{
      NanogenreId: 4054,
      x: 5121,
      y: 7564
    },{
      NanogenreId: 4055,
      x: 5178,
      y: 5959
    },{
      NanogenreId: 4056,
      x: 7457,
      y: 7903
    },{
      NanogenreId: 4057,
      x: 7859,
      y: 10804
    },{
      NanogenreId: 4058,
      x: 7608,
      y: 7822
    },{
      NanogenreId: 4059,
      x: 5564,
      y: 8446
    },{
      NanogenreId: 4060,
      x: 10448,
      y: 10791
    },{
      NanogenreId: 4061,
      x: 7396,
      y: 7446
    },{
      NanogenreId: 4062,
      x: 9616,
      y: 4776
    },{
      NanogenreId: 4063,
      x: 7943,
      y: 6176
    },{
      NanogenreId: 4064,
      x: 7503,
      y: 7580
    },{
      NanogenreId: 4065,
      x: 9809,
      y: 10082
    },{
      NanogenreId: 4066,
      x: 6641,
      y: 5085
    },{
      NanogenreId: 4067,
      x: 9772,
      y: 4745
    },{
      NanogenreId: 4068,
      x: 6786,
      y: 7506
    },{
      NanogenreId: 4069,
      x: 4295,
      y: 8718
    },{
      NanogenreId: 4070,
      x: 9677,
      y: 4725
    },{
      NanogenreId: 4071,
      x: 9480,
      y: 4587
    },{
      NanogenreId: 4072,
      x: 9128,
      y: 6722
    },{
      NanogenreId: 4073,
      x: 8091,
      y: 5457
    },{
      NanogenreId: 4074,
      x: 9228,
      y: 5816
    },{
      NanogenreId: 4075,
      x: 11830,
      y: 5970
    },{
      NanogenreId: 4076,
      x: 10664,
      y: 9496
    },{
      NanogenreId: 4077,
      x: 5966,
      y: 8426
    },{
      NanogenreId: 4078,
      x: 6443,
      y: 6371
    },{
      NanogenreId: 4079,
      x: 7104,
      y: 5562
    },{
      NanogenreId: 4080,
      x: 7941,
      y: 6704
    },{
      NanogenreId: 4081,
      x: 6727,
      y: 8092
    },{
      NanogenreId: 4082,
      x: 7173,
      y: 7506
    },{
      NanogenreId: 4083,
      x: 7817,
      y: 10993
    },{
      NanogenreId: 4084,
      x: 7572,
      y: 7321
    },{
      NanogenreId: 4085,
      x: 5734,
      y: 5425
    },{
      NanogenreId: 4086,
      x: 8106,
      y: 11863
    },{
      NanogenreId: 4087,
      x: 10117,
      y: 5747
    },{
      NanogenreId: 4088,
      x: 11110,
      y: 6108
    },{
      NanogenreId: 4089,
      x: 7384,
      y: 7497
    },{
      NanogenreId: 4090,
      x: 6445,
      y: 6526
    },{
      NanogenreId: 4091,
      x: 9433,
      y: 4922
    },{
      NanogenreId: 4092,
      x: 9629,
      y: 5177
    },{
      NanogenreId: 4093,
      x: 8682,
      y: 5688
    },{
      NanogenreId: 4094,
      x: 6484,
      y: 6775
    },{
      NanogenreId: 4095,
      x: 13520,
      y: 8198
    },{
      NanogenreId: 4096,
      x: 5828,
      y: 9766
    },{
      NanogenreId: 4097,
      x: 6042,
      y: 6478
    },{
      NanogenreId: 4098,
      x: 8327,
      y: 6595
    },{
      NanogenreId: 4099,
      x: 6010,
      y: 7857
    },{
      NanogenreId: 4100,
      x: 10748,
      y: 9462
    },{
      NanogenreId: 4101,
      x: 10533,
      y: 9806
    },{
      NanogenreId: 4102,
      x: 9061,
      y: 9638
    },{
      NanogenreId: 4103,
      x: 8391,
      y: 6260
    },{
      NanogenreId: 4104,
      x: 9553,
      y: 4763
    },{
      NanogenreId: 4105,
      x: 9617,
      y: 4971
    },{
      NanogenreId: 4106,
      x: 6611,
      y: 6240
    },{
      NanogenreId: 4107,
      x: 10046,
      y: 9832
    },{
      NanogenreId: 4108,
      x: 7953,
      y: 10017
    },{
      NanogenreId: 4109,
      x: 8476,
      y: 5496
    },{
      NanogenreId: 4110,
      x: 10062,
      y: 7905
    },{
      NanogenreId: 4111,
      x: 7725,
      y: 6431
    },{
      NanogenreId: 4112,
      x: 8229,
      y: 11121
    },{
      NanogenreId: 4113,
      x: 5664,
      y: 6205
    },{
      NanogenreId: 4114,
      x: 8482,
      y: 6492
    },{
      NanogenreId: 4115,
      x: 7003,
      y: 7469
    },{
      NanogenreId: 4116,
      x: 9928,
      y: 6099
    },{
      NanogenreId: 4117,
      x: 7586,
      y: 6415
    },{
      NanogenreId: 4118,
      x: 7126,
      y: 8522
    },{
      NanogenreId: 4119,
      x: 8941,
      y: 9078
    },{
      NanogenreId: 4120,
      x: 6556,
      y: 6340
    },{
      NanogenreId: 4121,
      x: 8495,
      y: 10517
    },{
      NanogenreId: 4122,
      x: 9227,
      y: 5197
    },{
      NanogenreId: 4123,
      x: 7743,
      y: 9889
    },{
      NanogenreId: 4124,
      x: 6685,
      y: 6803
    },{
      NanogenreId: 4125,
      x: 5883,
      y: 5722
    },{
      NanogenreId: 4126,
      x: 8175,
      y: 6152
    },{
      NanogenreId: 4127,
      x: 9022,
      y: 6855
    },{
      NanogenreId: 4128,
      x: 11084,
      y: 9101
    },{
      NanogenreId: 4129,
      x: 9662,
      y: 4734
    },{
      NanogenreId: 4130,
      x: 7717,
      y: 10887
    },{
      NanogenreId: 4131,
      x: 9221,
      y: 5153
    },{
      NanogenreId: 4132,
      x: 10259,
      y: 1998
    },{
      NanogenreId: 4133,
      x: 8917,
      y: 8889
    },{
      NanogenreId: 4134,
      x: 10533,
      y: 9952
    },{
      NanogenreId: 4135,
      x: 9567,
      y: 4848
    },{
      NanogenreId: 4136,
      x: 9349,
      y: 8056
    },{
      NanogenreId: 4137,
      x: 6311,
      y: 8709
    },{
      NanogenreId: 4138,
      x: 6575,
      y: 8156
    },{
      NanogenreId: 4139,
      x: 5821,
      y: 8555
    },{
      NanogenreId: 4140,
      x: 4334,
      y: 7685
    },{
      NanogenreId: 4141,
      x: 4722,
      y: 8717
    },{
      NanogenreId: 4142,
      x: 7802,
      y: 5114
    },{
      NanogenreId: 4143,
      x: 10058,
      y: 4658
    },{
      NanogenreId: 4144,
      x: 7832,
      y: 7248
    },{
      NanogenreId: 4145,
      x: 5935,
      y: 7850
    },{
      NanogenreId: 4146,
      x: 8107,
      y: 6318
    },{
      NanogenreId: 4147,
      x: 9752,
      y: 5075
    },{
      NanogenreId: 4148,
      x: 11197,
      y: 10502
    },{
      NanogenreId: 4149,
      x: 8147,
      y: 5600
    },{
      NanogenreId: 4150,
      x: 7250,
      y: 11404
    },{
      NanogenreId: 4151,
      x: 7072,
      y: 8180
    },{
      NanogenreId: 4152,
      x: 11052,
      y: 10712
    },{
      NanogenreId: 4153,
      x: 6334,
      y: 10368
    },{
      NanogenreId: 4154,
      x: 11440,
      y: 8529
    },{
      NanogenreId: 4155,
      x: 9593,
      y: 8304
    },{
      NanogenreId: 4156,
      x: 10908,
      y: 9830
    },{
      NanogenreId: 4157,
      x: 7919,
      y: 8647
    },{
      NanogenreId: 4158,
      x: 9848,
      y: 10586
    },{
      NanogenreId: 4159,
      x: 4691,
      y: 11457
    },{
      NanogenreId: 4160,
      x: 6475,
      y: 7536
    },{
      NanogenreId: 4161,
      x: 8671,
      y: 8333
    },{
      NanogenreId: 4162,
      x: 8137,
      y: 7789
    },{
      NanogenreId: 4163,
      x: 3903,
      y: 9439
    },{
      NanogenreId: 4164,
      x: 6431,
      y: 9014
    },{
      NanogenreId: 4165,
      x: 6660,
      y: 5868
    },{
      NanogenreId: 4166,
      x: 11905,
      y: 5925
    },{
      NanogenreId: 4167,
      x: 8286,
      y: 6011
    },{
      NanogenreId: 4168,
      x: 6294,
      y: 4886
    },{
      NanogenreId: 4169,
      x: 7586,
      y: 7529
    },{
      NanogenreId: 4170,
      x: 5203,
      y: 8798
    },{
      NanogenreId: 4171,
      x: 8332,
      y: 5425
    },{
      NanogenreId: 4172,
      x: 6810,
      y: 7114
    },{
      NanogenreId: 4173,
      x: 8859,
      y: 8398
    },{
      NanogenreId: 4174,
      x: 9475,
      y: 5158
    },{
      NanogenreId: 4175,
      x: 6709,
      y: 10153
    },{
      NanogenreId: 4176,
      x: 7071,
      y: 5870
    },{
      NanogenreId: 4177,
      x: 6067,
      y: 6002
    },{
      NanogenreId: 4178,
      x: 6623,
      y: 9843
    },{
      NanogenreId: 4179,
      x: 3590,
      y: 8949
    },{
      NanogenreId: 4180,
      x: 5098,
      y: 8022
    },{
      NanogenreId: 4181,
      x: 10118,
      y: 2525
    },{
      NanogenreId: 4182,
      x: 6927,
      y: 4950
    },{
      NanogenreId: 4183,
      x: 9016,
      y: 5274
    },{
      NanogenreId: 4184,
      x: 8644,
      y: 9450
    },{
      NanogenreId: 4185,
      x: 8610,
      y: 7710
    },{
      NanogenreId: 4186,
      x: 8053,
      y: 5685
    },{
      NanogenreId: 4187,
      x: 9291,
      y: 5967
    },{
      NanogenreId: 4188,
      x: 10825,
      y: 10002
    },{
      NanogenreId: 4189,
      x: 6963,
      y: 8780
    },{
      NanogenreId: 4190,
      x: 6279,
      y: 7592
    },{
      NanogenreId: 4191,
      x: 8388,
      y: 8425
    },{
      NanogenreId: 4192,
      x: 8993,
      y: 4995
    },{
      NanogenreId: 4193,
      x: 7617,
      y: 5947
    },{
      NanogenreId: 4194,
      x: 4835,
      y: 5475
    },{
      NanogenreId: 4195,
      x: 6750,
      y: 10251
    },{
      NanogenreId: 4196,
      x: 9255,
      y: 6676
    },{
      NanogenreId: 4197,
      x: 6730,
      y: 4471
    },{
      NanogenreId: 4198,
      x: 9870,
      y: 4770
    },{
      NanogenreId: 4199,
      x: 10192,
      y: 10219
    },{
      NanogenreId: 4200,
      x: 8981,
      y: 5359
    },{
      NanogenreId: 4201,
      x: 7423,
      y: 5611
    },{
      NanogenreId: 4202,
      x: 9954,
      y: 8675
    },{
      NanogenreId: 4203,
      x: 6599,
      y: 7482
    },{
      NanogenreId: 4204,
      x: 10695,
      y: 6820
    },{
      NanogenreId: 4205,
      x: 11795,
      y: 3425
    },{
      NanogenreId: 4206,
      x: 5026,
      y: 6684
    },{
      NanogenreId: 4207,
      x: 8889,
      y: 5328
    },{
      NanogenreId: 4208,
      x: 6254,
      y: 8582
    },{
      NanogenreId: 4209,
      x: 10623,
      y: 10291
    },{
      NanogenreId: 4210,
      x: 5283,
      y: 9768
    },{
      NanogenreId: 4211,
      x: 6302,
      y: 11942
    },{
      NanogenreId: 4212,
      x: 6376,
      y: 4013
    },{
      NanogenreId: 4213,
      x: 9946,
      y: 5044
    },{
      NanogenreId: 4214,
      x: 12197,
      y: 9349
    },{
      NanogenreId: 4215,
      x: 9366,
      y: 9662
    },{
      NanogenreId: 4216,
      x: 9602,
      y: 5709
    },{
      NanogenreId: 4217,
      x: 6517,
      y: 9259
    },{
      NanogenreId: 4218,
      x: 7670,
      y: 5648
    },{
      NanogenreId: 4219,
      x: 5872,
      y: 10422
    },{
      NanogenreId: 4220,
      x: 4475,
      y: 8991
    },{
      NanogenreId: 4221,
      x: 7476,
      y: 5470
    },{
      NanogenreId: 4222,
      x: 6189,
      y: 6199
    },{
      NanogenreId: 4223,
      x: 8348,
      y: 7974
    },{
      NanogenreId: 4224,
      x: 4295,
      y: 5207
    },{
      NanogenreId: 4225,
      x: 8604,
      y: 6427
    },{
      NanogenreId: 4226,
      x: 9229,
      y: 4679
    },{
      NanogenreId: 4227,
      x: 5514,
      y: 7453
    },{
      NanogenreId: 4228,
      x: 6793,
      y: 11518
    },{
      NanogenreId: 4229,
      x: 6597,
      y: 10884
    },{
      NanogenreId: 4230,
      x: 9473,
      y: 5133
    },{
      NanogenreId: 4231,
      x: 11174,
      y: 7586
    },{
      NanogenreId: 4232,
      x: 7894,
      y: 6955
    },{
      NanogenreId: 4233,
      x: 5074,
      y: 9901
    },{
      NanogenreId: 4234,
      x: 7420,
      y: 6819
    },{
      NanogenreId: 4235,
      x: 7915,
      y: 10119
    },{
      NanogenreId: 4236,
      x: 10278,
      y: 9722
    },{
      NanogenreId: 4237,
      x: 4479,
      y: 7544
    },{
      NanogenreId: 4238,
      x: 6925,
      y: 9277
    },{
      NanogenreId: 4239,
      x: 6240,
      y: 7257
    },{
      NanogenreId: 4240,
      x: 11026,
      y: 10007
    },{
      NanogenreId: 4241,
      x: 9803,
      y: 4700
    },{
      NanogenreId: 4242,
      x: 10664,
      y: 10699
    },{
      NanogenreId: 4243,
      x: 9289,
      y: 6273
    },{
      NanogenreId: 4244,
      x: 4266,
      y: 8076
    },{
      NanogenreId: 4245,
      x: 9111,
      y: 8294
    },{
      NanogenreId: 4246,
      x: 6894,
      y: 9140
    },{
      NanogenreId: 4247,
      x: 7687,
      y: 6288
    },{
      NanogenreId: 4248,
      x: 5054,
      y: 8147
    },{
      NanogenreId: 4249,
      x: 7858,
      y: 6620
    },{
      NanogenreId: 4250,
      x: 6976,
      y: 6165
    },{
      NanogenreId: 4251,
      x: 8584,
      y: 5460
    },{
      NanogenreId: 4252,
      x: 13368,
      y: 8689
    },{
      NanogenreId: 4253,
      x: 8488,
      y: 9389
    },{
      NanogenreId: 4254,
      x: 9972,
      y: 4842
    },{
      NanogenreId: 4255,
      x: 8307,
      y: 8285
    },{
      NanogenreId: 4256,
      x: 7563,
      y: 9583
    },{
      NanogenreId: 4257,
      x: 7826,
      y: 9292
    },{
      NanogenreId: 4258,
      x: 9239,
      y: 4799
    },{
      NanogenreId: 4259,
      x: 9662,
      y: 4782
    },{
      NanogenreId: 4260,
      x: 9830,
      y: 4780
    },{
      NanogenreId: 4261,
      x: 7191,
      y: 6102
    },{
      NanogenreId: 4262,
      x: 8154,
      y: 5798
    },{
      NanogenreId: 4263,
      x: 4567,
      y: 9213
    },{
      NanogenreId: 4264,
      x: 10726,
      y: 2479
    },{
      NanogenreId: 4265,
      x: 6123,
      y: 7988
    },{
      NanogenreId: 4266,
      x: 6292,
      y: 9896
    },{
      NanogenreId: 4267,
      x: 8171,
      y: 6896
    },{
      NanogenreId: 4268,
      x: 9368,
      y: 4720
    },{
      NanogenreId: 4269,
      x: 6356,
      y: 8227
    },{
      NanogenreId: 4270,
      x: 9352,
      y: 6304
    },{
      NanogenreId: 4271,
      x: 9412,
      y: 9519
    },{
      NanogenreId: 4272,
      x: 9994,
      y: 9856
    },{
      NanogenreId: 4273,
      x: 8666,
      y: 5910
    },{
      NanogenreId: 4274,
      x: 9168,
      y: 7615
    },{
      NanogenreId: 4275,
      x: 8207,
      y: 6289
    },{
      NanogenreId: 4276,
      x: 6500,
      y: 9457
    },{
      NanogenreId: 4277,
      x: 5806,
      y: 7422
    },{
      NanogenreId: 4278,
      x: 6475,
      y: 5989
    },{
      NanogenreId: 4279,
      x: 8387,
      y: 8560
    },{
      NanogenreId: 4280,
      x: 8252,
      y: 4927
    },{
      NanogenreId: 4281,
      x: 8895,
      y: 5708
    },{
      NanogenreId: 4282,
      x: 5891,
      y: 6574
    },{
      NanogenreId: 4283,
      x: 7026,
      y: 6309
    },{
      NanogenreId: 4284,
      x: 7508,
      y: 3081
    },{
      NanogenreId: 4285,
      x: 9283,
      y: 4566
    },{
      NanogenreId: 4286,
      x: 7183,
      y: 10295
    },{
      NanogenreId: 4287,
      x: 5848,
      y: 7698
    },{
      NanogenreId: 4288,
      x: 8293,
      y: 8423
    },{
      NanogenreId: 4289,
      x: 6489,
      y: 6885
    },{
      NanogenreId: 4290,
      x: 9609,
      y: 6654
    },{
      NanogenreId: 4291,
      x: 6028,
      y: 9379
    },{
      NanogenreId: 4292,
      x: 9164,
      y: 6820
    },{
      NanogenreId: 4293,
      x: 6316,
      y: 6755
    },{
      NanogenreId: 4294,
      x: 9405,
      y: 7809
    },{
      NanogenreId: 4295,
      x: 7273,
      y: 7835
    },{
      NanogenreId: 4296,
      x: 8034,
      y: 6708
    },{
      NanogenreId: 4297,
      x: 7389,
      y: 5155
    },{
      NanogenreId: 4298,
      x: 12196,
      y: 3434
    },{
      NanogenreId: 4299,
      x: 8881,
      y: 5807
    },{
      NanogenreId: 4300,
      x: 7292,
      y: 9809
    },{
      NanogenreId: 4301,
      x: 9804,
      y: 4820
    },{
      NanogenreId: 4302,
      x: 5939,
      y: 7752
    },{
      NanogenreId: 4303,
      x: 7007,
      y: 8137
    },{
      NanogenreId: 4304,
      x: 9153,
      y: 5042
    },{
      NanogenreId: 4305,
      x: 10887,
      y: 5173
    },{
      NanogenreId: 4306,
      x: 5519,
      y: 9461
    },{
      NanogenreId: 4307,
      x: 5755,
      y: 9185
    },{
      NanogenreId: 4308,
      x: 6671,
      y: 5012
    },{
      NanogenreId: 4309,
      x: 9380,
      y: 10500
    },{
      NanogenreId: 4310,
      x: 4314,
      y: 6422
    },{
      NanogenreId: 4311,
      x: 8689,
      y: 6538
    },{
      NanogenreId: 4312,
      x: 9535,
      y: 9613
    },{
      NanogenreId: 4313,
      x: 8433,
      y: 10537
    },{
      NanogenreId: 4314,
      x: 5863,
      y: 9489
    },{
      NanogenreId: 4315,
      x: 6122,
      y: 11227
    },{
      NanogenreId: 4316,
      x: 10061,
      y: 8371
    },{
      NanogenreId: 4317,
      x: 7234,
      y: 10988
    },{
      NanogenreId: 4318,
      x: 10672,
      y: 7506
    },{
      NanogenreId: 4319,
      x: 6182,
      y: 12300
    },{
      NanogenreId: 4320,
      x: 9449,
      y: 5192
    },{
      NanogenreId: 4321,
      x: 6947,
      y: 6193
    },{
      NanogenreId: 4322,
      x: 9053,
      y: 6698
    },{
      NanogenreId: 4323,
      x: 9085,
      y: 8047
    },{
      NanogenreId: 4324,
      x: 9121,
      y: 6550
    },{
      NanogenreId: 4325,
      x: 11985,
      y: 9092
    },{
      NanogenreId: 4326,
      x: 10587,
      y: 8113
    },{
      NanogenreId: 4327,
      x: 9611,
      y: 4814
    },{
      NanogenreId: 4328,
      x: 9163,
      y: 9473
    },{
      NanogenreId: 4329,
      x: 6429,
      y: 8485
    },{
      NanogenreId: 4330,
      x: 5459,
      y: 7105
    },{
      NanogenreId: 4331,
      x: 8748,
      y: 9574
    },{
      NanogenreId: 4332,
      x: 3134,
      y: 7658
    },{
      NanogenreId: 4333,
      x: 3618,
      y: 6796
    },{
      NanogenreId: 4334,
      x: 4406,
      y: 8335
    },{
      NanogenreId: 4335,
      x: 6062,
      y: 6370
    },{
      NanogenreId: 4336,
      x: 9790,
      y: 9181
    },{
      NanogenreId: 4337,
      x: 7674,
      y: 8267
    },{
      NanogenreId: 4338,
      x: 5985,
      y: 6498
    },{
      NanogenreId: 4339,
      x: 7185,
      y: 7120
    },{
      NanogenreId: 4340,
      x: 8783,
      y: 4716
    },{
      NanogenreId: 4341,
      x: 7295,
      y: 8129
    },{
      NanogenreId: 4342,
      x: 4304,
      y: 8198
    },{
      NanogenreId: 4343,
      x: 6743,
      y: 7731
    },{
      NanogenreId: 4344,
      x: 9717,
      y: 8414
    },{
      NanogenreId: 4345,
      x: 10255,
      y: 10402
    },{
      NanogenreId: 4346,
      x: 8959,
      y: 5898
    },{
      NanogenreId: 4347,
      x: 8668,
      y: 5116
    },{
      NanogenreId: 4348,
      x: 7661,
      y: 8506
    },{
      NanogenreId: 4349,
      x: 9534,
      y: 5052
    },{
      NanogenreId: 4350,
      x: 3126,
      y: 4291
    },{
      NanogenreId: 4351,
      x: 10826,
      y: 9832
    },{
      NanogenreId: 4352,
      x: 10292,
      y: 9616
    },{
      NanogenreId: 4353,
      x: 9722,
      y: 6267
    },{
      NanogenreId: 4354,
      x: 8360,
      y: 5486
    },{
      NanogenreId: 4355,
      x: 9546,
      y: 7686
    },{
      NanogenreId: 4356,
      x: 10128,
      y: 8632
    },{
      NanogenreId: 4357,
      x: 7229,
      y: 8780
    },{
      NanogenreId: 4358,
      x: 6474,
      y: 10745
    },{
      NanogenreId: 4359,
      x: 6169,
      y: 8136
    },{
      NanogenreId: 4360,
      x: 8278,
      y: 6270
    },{
      NanogenreId: 4361,
      x: 8850,
      y: 6329
    },{
      NanogenreId: 4362,
      x: 8506,
      y: 10413
    },{
      NanogenreId: 4363,
      x: 8075,
      y: 6137
    },{
      NanogenreId: 4364,
      x: 7327,
      y: 7356
    },{
      NanogenreId: 4365,
      x: 8739,
      y: 4082
    },{
      NanogenreId: 4366,
      x: 6779,
      y: 10577
    },{
      NanogenreId: 4367,
      x: 7661,
      y: 7592
    },{
      NanogenreId: 4368,
      x: 10564,
      y: 10213
    },{
      NanogenreId: 4369,
      x: 7747,
      y: 6059
    },{
      NanogenreId: 4370,
      x: 9011,
      y: 9806
    },{
      NanogenreId: 4371,
      x: 8057,
      y: 7129
    },{
      NanogenreId: 4372,
      x: 10022,
      y: 9450
    },{
      NanogenreId: 4373,
      x: 7694,
      y: 6652
    },{
      NanogenreId: 4374,
      x: 8316,
      y: 5650
    },{
      NanogenreId: 4375,
      x: 5135,
      y: 3326
    },{
      NanogenreId: 4376,
      x: 7300,
      y: 11319
    },{
      NanogenreId: 4377,
      x: 8807,
      y: 9342
    },{
      NanogenreId: 4378,
      x: 9602,
      y: 6055
    },{
      NanogenreId: 4379,
      x: 7143,
      y: 11626
    },{
      NanogenreId: 4380,
      x: 3417,
      y: 10426
    },{
      NanogenreId: 4381,
      x: 10362,
      y: 11192
    },{
      NanogenreId: 4382,
      x: 11327,
      y: 1978
    },{
      NanogenreId: 4383,
      x: 8026,
      y: 4905
    },{
      NanogenreId: 4384,
      x: 7504,
      y: 9198
    },{
      NanogenreId: 4385,
      x: 5046,
      y: 8288
    },{
      NanogenreId: 4386,
      x: 13163,
      y: 10250
    },{
      NanogenreId: 4387,
      x: 11326,
      y: 10720
    },{
      NanogenreId: 4388,
      x: 9994,
      y: 6554
    },{
      NanogenreId: 4389,
      x: 6896,
      y: 12058
    },{
      NanogenreId: 4390,
      x: 5824,
      y: 3490
    },{
      NanogenreId: 4391,
      x: 12533,
      y: 10362
    },{
      NanogenreId: 4392,
      x: 9414,
      y: 4381
    },{
      NanogenreId: 4393,
      x: 7035,
      y: 9867
    },{
      NanogenreId: 4394,
      x: 5054,
      y: 5778
    },{
      NanogenreId: 4395,
      x: 11316,
      y: 9817
    },{
      NanogenreId: 4396,
      x: 9780,
      y: 6167
    },{
      NanogenreId: 4397,
      x: 11171,
      y: 10849
    },{
      NanogenreId: 4398,
      x: 6638,
      y: 10572
    },{
      NanogenreId: 4399,
      x: 9513,
      y: 1909
    },{
      NanogenreId: 4400,
      x: 11648,
      y: 10205
    },{
      NanogenreId: 4401,
      x: 3809,
      y: 9446
    },{
      NanogenreId: 4402,
      x: 12579,
      y: 6028
    },{
      NanogenreId: 4403,
      x: 9717,
      y: 5428
    },{
      NanogenreId: 4404,
      x: 2831,
      y: 9396
    },{
      NanogenreId: 4405,
      x: 10658,
      y: 9716
    },{
      NanogenreId: 4406,
      x: 10334,
      y: 7333
    },{
      NanogenreId: 4407,
      x: 12266,
      y: 7644
    },{
      NanogenreId: 4408,
      x: 6900,
      y: 12243
    },{
      NanogenreId: 4409,
      x: 4630,
      y: 3804
    },{
      NanogenreId: 4410,
      x: 6290,
      y: 11769
    },{
      NanogenreId: 4411,
      x: 10744,
      y: 10225
    },{
      NanogenreId: 4412,
      x: 8851,
      y: 4302
    },{
      NanogenreId: 4413,
      x: 7339,
      y: 10954
    },{
      NanogenreId: 4414,
      x: 11371,
      y: 10065
    },{
      NanogenreId: 4415,
      x: 7154,
      y: 10363
    },{
      NanogenreId: 4416,
      x: 7953,
      y: 6775
    },{
      NanogenreId: 4417,
      x: 6988,
      y: 4714
    },{
      NanogenreId: 4418,
      x: 9297,
      y: 4534
    },{
      NanogenreId: 4419,
      x: 9919,
      y: 5881
    },{
      NanogenreId: 4420,
      x: 10991,
      y: 10212
    },{
      NanogenreId: 4421,
      x: 7529,
      y: 11286
    },{
      NanogenreId: 4422,
      x: 11059,
      y: 10663
    },{
      NanogenreId: 4423,
      x: 7363,
      y: 9753
    },{
      NanogenreId: 4424,
      x: 7385,
      y: 10088
    },{
      NanogenreId: 4425,
      x: 10666,
      y: 10146
    },{
      NanogenreId: 4426,
      x: 6778,
      y: 11292
    },{
      NanogenreId: 4427,
      x: 9170,
      y: 4829
    },{
      NanogenreId: 4428,
      x: 6925,
      y: 11761
    },{
      NanogenreId: 4429,
      x: 8744,
      y: 9903
    },{
      NanogenreId: 4430,
      x: 9285,
      y: 4458
    },{
      NanogenreId: 4431,
      x: 5189,
      y: 9706
    },{
      NanogenreId: 4432,
      x: 8778,
      y: 10180
    },{
      NanogenreId: 4433,
      x: 8397,
      y: 9574
    },{
      NanogenreId: 4434,
      x: 4210,
      y: 9675
    },{
      NanogenreId: 4435,
      x: 9022,
      y: 4972
    },{
      NanogenreId: 4436,
      x: 9245,
      y: 5930
    },{
      NanogenreId: 4437,
      x: 8457,
      y: 7955
    },{
      NanogenreId: 4438,
      x: 10557,
      y: 10287
    },{
      NanogenreId: 4439,
      x: 7515,
      y: 8927
    },{
      NanogenreId: 4440,
      x: 12754,
      y: 8565
    },{
      NanogenreId: 4441,
      x: 9152,
      y: 7011
    },{
      NanogenreId: 4442,
      x: 11886,
      y: 7039
    },{
      NanogenreId: 4443,
      x: 8726,
      y: 5054
    },{
      NanogenreId: 4444,
      x: 9122,
      y: 11381
    },{
      NanogenreId: 4445,
      x: 11094,
      y: 10213
    },{
      NanogenreId: 4446,
      x: 10502,
      y: 8782
    },{
      NanogenreId: 4447,
      x: 9714,
      y: 4922
    },{
      NanogenreId: 4448,
      x: 6619,
      y: 12075
    },{
      NanogenreId: 4449,
      x: 9487,
      y: 4449
    },{
      NanogenreId: 4450,
      x: 7711,
      y: 4858
    },{
      NanogenreId: 4451,
      x: 10854,
      y: 10534
    },{
      NanogenreId: 4452,
      x: 11118,
      y: 10420
    },{
      NanogenreId: 4453,
      x: 5557,
      y: 11672
    },{
      NanogenreId: 4454,
      x: 6973,
      y: 12438
    },{
      NanogenreId: 4455,
      x: 7415,
      y: 11336
    },{
      NanogenreId: 4456,
      x: 8473,
      y: 5679
    },{
      NanogenreId: 4457,
      x: 6995,
      y: 12004
    },{
      NanogenreId: 4458,
      x: 11790,
      y: 9257
    },{
      NanogenreId: 4459,
      x: 9876,
      y: 9881
    },{
      NanogenreId: 4460,
      x: 6808,
      y: 10996
    },{
      NanogenreId: 4461,
      x: 9195,
      y: 2049
    },{
      NanogenreId: 4462,
      x: 7034,
      y: 11023
    },{
      NanogenreId: 4463,
      x: 8013,
      y: 8686
    },{
      NanogenreId: 4464,
      x: 8445,
      y: 10291
    },{
      NanogenreId: 4465,
      x: 7552,
      y: 11393
    },{
      NanogenreId: 4466,
      x: 9469,
      y: 6037
    },{
      NanogenreId: 4467,
      x: 13023,
      y: 7313
    },{
      NanogenreId: 4468,
      x: 10449,
      y: 10369
    },{
      NanogenreId: 4469,
      x: 8453,
      y: 9913
    },{
      NanogenreId: 4470,
      x: 9077,
      y: 5447
    },{
      NanogenreId: 4471,
      x: 10149,
      y: 11688
    },{
      NanogenreId: 4472,
      x: 5690,
      y: 9682
    },{
      NanogenreId: 4473,
      x: 7018,
      y: 10822
    },{
      NanogenreId: 4474,
      x: 6889,
      y: 5151
    },{
      NanogenreId: 4475,
      x: 8111,
      y: 9781
    },{
      NanogenreId: 4476,
      x: 10613,
      y: 10228
    },{
      NanogenreId: 4477,
      x: 5817,
      y: 6234
    },{
      NanogenreId: 4478,
      x: 10674,
      y: 8864
    },{
      NanogenreId: 4479,
      x: 7017,
      y: 7339
    },{
      NanogenreId: 4480,
      x: 12596,
      y: 6531
    },{
      NanogenreId: 4481,
      x: 7239,
      y: 7677
    },{
      NanogenreId: 4482,
      x: 10782,
      y: 9870
    },{
      NanogenreId: 4483,
      x: 9827,
      y: 7848
    },{
      NanogenreId: 4484,
      x: 7036,
      y: 2863
    },{
      NanogenreId: 4485,
      x: 7907,
      y: 4054
    },{
      NanogenreId: 4486,
      x: 8682,
      y: 10295
    },{
      NanogenreId: 4487,
      x: 7000,
      y: 6446
    },{
      NanogenreId: 4488,
      x: 9891,
      y: 4686
    },{
      NanogenreId: 4489,
      x: 6274,
      y: 7177
    },{
      NanogenreId: 4490,
      x: 9340,
      y: 8121
    },{
      NanogenreId: 4491,
      x: 9998,
      y: 4858
    },{
      NanogenreId: 4492,
      x: 9447,
      y: 6919
    },{
      NanogenreId: 4493,
      x: 10155,
      y: 5624
    },{
      NanogenreId: 4494,
      x: 11435,
      y: 6097
    },{
      NanogenreId: 4495,
      x: 8127,
      y: 5563
    },{
      NanogenreId: 4496,
      x: 9213,
      y: 5243
    },{
      NanogenreId: 4497,
      x: 13069,
      y: 6051
    },{
      NanogenreId: 4498,
      x: 11902,
      y: 6226
    },{
      NanogenreId: 4499,
      x: 8131,
      y: 5763
    },{
      NanogenreId: 4500,
      x: 10427,
      y: 4658
    },{
      NanogenreId: 4501,
      x: 6958,
      y: 11322
    },{
      NanogenreId: 4502,
      x: 7941,
      y: 11358
    },{
      NanogenreId: 4503,
      x: 7909,
      y: 9660
    },{
      NanogenreId: 4504,
      x: 7910,
      y: 4758
    },{
      NanogenreId: 4505,
      x: 9644,
      y: 5920
    },{
      NanogenreId: 4506,
      x: 9644,
      y: 6039
    },{
      NanogenreId: 4507,
      x: 7729,
      y: 11567
    },{
      NanogenreId: 4508,
      x: 9701,
      y: 10310
    },{
      NanogenreId: 4509,
      x: 10075,
      y: 6737
    },{
      NanogenreId: 4510,
      x: 8038,
      y: 10369
    },{
      NanogenreId: 4511,
      x: 9867,
      y: 4563
    },{
      NanogenreId: 4512,
      x: 10039,
      y: 4789
    },{
      NanogenreId: 4513,
      x: 7808,
      y: 4635
    },{
      NanogenreId: 4514,
      x: 6812,
      y: 8894
    },{
      NanogenreId: 4515,
      x: 5964,
      y: 10985
    },{
      NanogenreId: 4516,
      x: 8185,
      y: 8080
    },{
      NanogenreId: 4517,
      x: 7509,
      y: 6545
    },{
      NanogenreId: 4518,
      x: 9820,
      y: 8881
    },{
      NanogenreId: 4519,
      x: 7951,
      y: 10428
    },{
      NanogenreId: 4520,
      x: 9463,
      y: 4937
    },{
      NanogenreId: 4521,
      x: 11117,
      y: 6274
    },{
      NanogenreId: 4522,
      x: 10069,
      y: 10632
    },{
      NanogenreId: 4523,
      x: 11834,
      y: 5568
    },{
      NanogenreId: 4524,
      x: 8482,
      y: 4800
    },{
      NanogenreId: 4525,
      x: 7531,
      y: 4592
    },{
      NanogenreId: 4526,
      x: 8694,
      y: 4969
    },{
      NanogenreId: 4527,
      x: 6822,
      y: 9744
    },{
      NanogenreId: 4528,
      x: 5754,
      y: 5606
    },{
      NanogenreId: 4529,
      x: 9577,
      y: 7407
    },{
      NanogenreId: 4530,
      x: 10199,
      y: 8637
    },{
      NanogenreId: 4531,
      x: 11535,
      y: 6589
    },{
      NanogenreId: 4532,
      x: 6996,
      y: 4446
    },{
      NanogenreId: 4533,
      x: 8002,
      y: 8783
    },{
      NanogenreId: 4534,
      x: 9536,
      y: 4831
    },{
      NanogenreId: 4535,
      x: 9256,
      y: 2111
    },{
      NanogenreId: 4536,
      x: 5500,
      y: 5056
    },{
      NanogenreId: 4537,
      x: 6949,
      y: 7519
    },{
      NanogenreId: 4538,
      x: 6512,
      y: 10027
    },{
      NanogenreId: 4539,
      x: 8328,
      y: 6157
    },{
      NanogenreId: 4540,
      x: 11078,
      y: 10851
    },{
      NanogenreId: 4541,
      x: 5636,
      y: 7597
    },{
      NanogenreId: 4542,
      x: 7595,
      y: 7282
    },{
      NanogenreId: 4543,
      x: 9347,
      y: 7085
    },{
      NanogenreId: 4544,
      x: 4339,
      y: 9869
    },{
      NanogenreId: 4545,
      x: 9520,
      y: 8180
    },{
      NanogenreId: 4546,
      x: 10054,
      y: 4555
    },{
      NanogenreId: 4547,
      x: 13536,
      y: 10514
    },{
      NanogenreId: 4548,
      x: 7251,
      y: 10932
    },{
      NanogenreId: 4549,
      x: 10606,
      y: 10845
    },{
      NanogenreId: 4550,
      x: 6932,
      y: 6014
    },{
      NanogenreId: 4551,
      x: 8037,
      y: 10046
    },{
      NanogenreId: 4552,
      x: 10770,
      y: 10898
    },{
      NanogenreId: 4553,
      x: 8488,
      y: 5870
    },{
      NanogenreId: 4554,
      x: 7810,
      y: 7028
    },{
      NanogenreId: 4555,
      x: 10434,
      y: 7534
    },{
      NanogenreId: 4556,
      x: 7317,
      y: 11094
    },{
      NanogenreId: 4557,
      x: 10457,
      y: 10541
    },{
      NanogenreId: 4558,
      x: 8001,
      y: 5632
    },{
      NanogenreId: 4559,
      x: 7696,
      y: 6159
    },{
      NanogenreId: 4560,
      x: 10895,
      y: 10306
    },{
      NanogenreId: 4561,
      x: 9116,
      y: 9721
    },{
      NanogenreId: 4562,
      x: 7048,
      y: 9697
    },{
      NanogenreId: 4563,
      x: 3931,
      y: 9126
    },{
      NanogenreId: 4564,
      x: 9241,
      y: 8708
    },{
      NanogenreId: 4565,
      x: 6663,
      y: 10951
    },{
      NanogenreId: 4566,
      x: 9532,
      y: 8257
    },{
      NanogenreId: 4567,
      x: 8819,
      y: 11361
    },{
      NanogenreId: 4568,
      x: 9947,
      y: 9054
    },{
      NanogenreId: 4569,
      x: 6952,
      y: 8407
    },{
      NanogenreId: 4570,
      x: 8582,
      y: 6480
    },{
      NanogenreId: 4571,
      x: 13324,
      y: 8996
    },{
      NanogenreId: 4572,
      x: 7916,
      y: 7244
    },{
      NanogenreId: 4573,
      x: 7493,
      y: 8987
    },{
      NanogenreId: 4574,
      x: 3553,
      y: 10221
    },{
      NanogenreId: 4575,
      x: 9710,
      y: 5655
    },{
      NanogenreId: 4576,
      x: 7472,
      y: 7115
    },{
      NanogenreId: 4577,
      x: 9118,
      y: 5685
    },{
      NanogenreId: 4578,
      x: 9921,
      y: 5159
    },{
      NanogenreId: 4579,
      x: 9959,
      y: 6320
    },{
      NanogenreId: 4580,
      x: 7044,
      y: 11269
    },{
      NanogenreId: 4581,
      x: 6086,
      y: 8590
    },{
      NanogenreId: 4582,
      x: 10482,
      y: 4837
    },{
      NanogenreId: 4583,
      x: 10870,
      y: 6915
    },{
      NanogenreId: 4584,
      x: 10672,
      y: 10642
    },{
      NanogenreId: 4585,
      x: 3183,
      y: 8842
    },{
      NanogenreId: 4586,
      x: 8867,
      y: 5240
    },{
      NanogenreId: 4587,
      x: 9772,
      y: 3830
    },{
      NanogenreId: 4588,
      x: 13104,
      y: 8971
    },{
      NanogenreId: 4589,
      x: 11781,
      y: 8495
    },{
      NanogenreId: 4590,
      x: 8981,
      y: 9927
    },{
      NanogenreId: 4591,
      x: 13181,
      y: 8033
    },{
      NanogenreId: 4592,
      x: 8362,
      y: 10665
    },{
      NanogenreId: 4593,
      x: 7552,
      y: 10862
    },{
      NanogenreId: 4594,
      x: 10600,
      y: 9068
    },{
      NanogenreId: 4595,
      x: 8149,
      y: 3996
    },{
      NanogenreId: 4596,
      x: 8527,
      y: 5531
    },{
      NanogenreId: 4597,
      x: 9318,
      y: 5519
    },{
      NanogenreId: 4598,
      x: 6728,
      y: 7442
    },{
      NanogenreId: 4599,
      x: 10013,
      y: 5021
    },{
      NanogenreId: 4600,
      x: 6170,
      y: 5740
    },{
      NanogenreId: 4601,
      x: 8429,
      y: 8217
    },{
      NanogenreId: 4602,
      x: 7501,
      y: 7339
    },{
      NanogenreId: 4603,
      x: 7615,
      y: 8941
    },{
      NanogenreId: 4604,
      x: 7835,
      y: 5303
    },{
      NanogenreId: 4605,
      x: 5988,
      y: 7387
    },{
      NanogenreId: 4606,
      x: 9531,
      y: 9666
    },{
      NanogenreId: 4607,
      x: 10954,
      y: 10684
    },{
      NanogenreId: 4608,
      x: 7633,
      y: 9204
    },{
      NanogenreId: 4609,
      x: 6537,
      y: 12227
    },{
      NanogenreId: 4610,
      x: 6960,
      y: 10742
    },{
      NanogenreId: 4611,
      x: 5978,
      y: 8657
    },{
      NanogenreId: 4612,
      x: 6631,
      y: 6580
    },{
      NanogenreId: 4613,
      x: 7918,
      y: 9770
    },{
      NanogenreId: 4614,
      x: 9684,
      y: 5394
    },{
      NanogenreId: 4615,
      x: 9454,
      y: 2690
    },{
      NanogenreId: 4616,
      x: 9506,
      y: 6328
    },{
      NanogenreId: 4617,
      x: 7082,
      y: 6318
    },{
      NanogenreId: 4618,
      x: 9986,
      y: 9344
    },{
      NanogenreId: 4619,
      x: 10151,
      y: 4547
    },{
      NanogenreId: 4620,
      x: 7092,
      y: 8704
    },{
      NanogenreId: 4621,
      x: 8701,
      y: 4852
    },{
      NanogenreId: 4622,
      x: 7099,
      y: 10848
    },{
      NanogenreId: 4623,
      x: 6727,
      y: 7909
    },{
      NanogenreId: 4624,
      x: 8130,
      y: 9102
    },{
      NanogenreId: 4625,
      x: 8341,
      y: 5404
    },{
      NanogenreId: 4626,
      x: 8161,
      y: 4723
    },{
      NanogenreId: 4627,
      x: 9060,
      y: 5639
    },{
      NanogenreId: 4628,
      x: 9296,
      y: 4960
    },{
      NanogenreId: 4629,
      x: 7236,
      y: 5609
    },{
      NanogenreId: 4630,
      x: 7664,
      y: 7905
    },{
      NanogenreId: 4631,
      x: 9708,
      y: 7294
    },{
      NanogenreId: 4632,
      x: 9695,
      y: 4841
    },{
      NanogenreId: 4633,
      x: 6678,
      y: 6950
    },{
      NanogenreId: 4634,
      x: 9294,
      y: 6977
    },{
      NanogenreId: 4635,
      x: 9650,
      y: 11165
    },{
      NanogenreId: 4636,
      x: 6554,
      y: 7094
    },{
      NanogenreId: 4637,
      x: 10811,
      y: 9930
    },{
      NanogenreId: 4638,
      x: 5812,
      y: 8302
    },{
      NanogenreId: 4639,
      x: 8261,
      y: 9998
    },{
      NanogenreId: 4640,
      x: 7690,
      y: 4521
    },{
      NanogenreId: 4641,
      x: 9928,
      y: 4884
    },{
      NanogenreId: 4642,
      x: 9903,
      y: 4879
    },{
      NanogenreId: 4643,
      x: 7655,
      y: 8341
    },{
      NanogenreId: 4644,
      x: 7093,
      y: 5477
    },{
      NanogenreId: 4645,
      x: 9712,
      y: 6017
    },{
      NanogenreId: 4646,
      x: 3264,
      y: 9022
    },{
      NanogenreId: 4647,
      x: 10147,
      y: 5216
    },{
      NanogenreId: 4648,
      x: 11438,
      y: 8127
    },{
      NanogenreId: 4649,
      x: 6951,
      y: 4200
    },{
      NanogenreId: 4650,
      x: 9816,
      y: 5254
    },{
      NanogenreId: 4651,
      x: 7836,
      y: 10196
    },{
      NanogenreId: 4652,
      x: 7775,
      y: 11053
    },{
      NanogenreId: 4653,
      x: 8014,
      y: 10460
    },{
      NanogenreId: 4654,
      x: 12512,
      y: 8309
    },{
      NanogenreId: 4655,
      x: 8374,
      y: 9218
    },{
      NanogenreId: 4656,
      x: 10084,
      y: 4686
    },{
      NanogenreId: 4657,
      x: 6791,
      y: 5529
    },{
      NanogenreId: 4658,
      x: 5922,
      y: 6828
    },{
      NanogenreId: 4659,
      x: 13357,
      y: 8848
    },{
      NanogenreId: 4660,
      x: 7614,
      y: 10000
    },{
      NanogenreId: 4661,
      x: 11099,
      y: 9364
    },{
      NanogenreId: 4662,
      x: 6778,
      y: 6732
    },{
      NanogenreId: 4663,
      x: 7398,
      y: 5587
    },{
      NanogenreId: 4664,
      x: 3966,
      y: 8870
    },{
      NanogenreId: 4665,
      x: 9785,
      y: 7345
    },{
      NanogenreId: 4666,
      x: 7582,
      y: 9915
    },{
      NanogenreId: 4667,
      x: 10966,
      y: 10250
    },{
      NanogenreId: 4668,
      x: 8675,
      y: 9268
    },{
      NanogenreId: 4669,
      x: 8556,
      y: 5066
    },{
      NanogenreId: 4670,
      x: 7751,
      y: 9028
    },{
      NanogenreId: 4671,
      x: 8940,
      y: 5128
    },{
      NanogenreId: 4672,
      x: 3510,
      y: 9036
    },{
      NanogenreId: 4673,
      x: 8250,
      y: 10295
    },{
      NanogenreId: 4674,
      x: 7542,
      y: 5798
    },{
      NanogenreId: 4675,
      x: 8684,
      y: 8045
    },{
      NanogenreId: 4676,
      x: 12003,
      y: 6609
    },{
      NanogenreId: 4677,
      x: 10492,
      y: 10823
    },{
      NanogenreId: 4678,
      x: 7932,
      y: 8743
    },{
      NanogenreId: 4679,
      x: 9790,
      y: 4850
    },{
      NanogenreId: 4680,
      x: 6178,
      y: 6718
    },{
      NanogenreId: 4681,
      x: 9174,
      y: 11550
    },{
      NanogenreId: 4682,
      x: 8803,
      y: 6697
    },{
      NanogenreId: 4683,
      x: 7603,
      y: 9594
    },{
      NanogenreId: 4684,
      x: 6620,
      y: 9079
    },{
      NanogenreId: 4685,
      x: 8648,
      y: 5093
    },{
      NanogenreId: 4686,
      x: 11925,
      y: 8071
    },{
      NanogenreId: 4687,
      x: 7703,
      y: 8685
    },{
      NanogenreId: 4688,
      x: 5719,
      y: 6048
    },{
      NanogenreId: 4689,
      x: 6088,
      y: 8298
    },{
      NanogenreId: 4690,
      x: 8176,
      y: 10814
    },{
      NanogenreId: 4691,
      x: 6827,
      y: 3913
    },{
      NanogenreId: 4692,
      x: 7479,
      y: 10103
    },{
      NanogenreId: 4693,
      x: 6110,
      y: 4617
    },{
      NanogenreId: 4694,
      x: 9885,
      y: 9093
    },{
      NanogenreId: 4695,
      x: 13506,
      y: 9188
    },{
      NanogenreId: 4696,
      x: 7058,
      y: 10836
    },{
      NanogenreId: 4697,
      x: 6425,
      y: 6568
    },{
      NanogenreId: 4698,
      x: 11179,
      y: 10674
    },{
      NanogenreId: 4699,
      x: 9705,
      y: 6326
    },{
      NanogenreId: 4700,
      x: 9701,
      y: 4868
    },{
      NanogenreId: 4701,
      x: 8849,
      y: 4979
    },{
      NanogenreId: 4702,
      x: 9579,
      y: 6487
    },{
      NanogenreId: 4703,
      x: 9866,
      y: 10011
    },{
      NanogenreId: 4704,
      x: 8679,
      y: 6710
    },{
      NanogenreId: 4705,
      x: 8134,
      y: 5516
    },{
      NanogenreId: 4706,
      x: 4230,
      y: 7908
    },{
      NanogenreId: 4707,
      x: 8778,
      y: 4939
    },{
      NanogenreId: 4708,
      x: 9727,
      y: 7782
    },{
      NanogenreId: 4709,
      x: 7286,
      y: 11239
    },{
      NanogenreId: 4710,
      x: 10357,
      y: 6165
    },{
      NanogenreId: 4711,
      x: 11018,
      y: 9603
    },{
      NanogenreId: 4712,
      x: 7415,
      y: 7724
    },{
      NanogenreId: 4713,
      x: 10325,
      y: 8636
    },{
      NanogenreId: 4714,
      x: 10631,
      y: 10438
    },{
      NanogenreId: 4715,
      x: 6816,
      y: 7900
    },{
      NanogenreId: 4716,
      x: 10225,
      y: 10812
    },{
      NanogenreId: 4717,
      x: 5698,
      y: 9416
    },{
      NanogenreId: 4718,
      x: 9531,
      y: 6573
    },{
      NanogenreId: 4719,
      x: 7251,
      y: 12227
    },{
      NanogenreId: 4720,
      x: 5055,
      y: 9630
    },{
      NanogenreId: 4721,
      x: 10131,
      y: 10237
    },{
      NanogenreId: 4722,
      x: 11018,
      y: 9678
    },{
      NanogenreId: 4723,
      x: 7719,
      y: 10078
    },{
      NanogenreId: 4724,
      x: 9385,
      y: 4686
    },{
      NanogenreId: 4725,
      x: 9493,
      y: 5518
    },{
      NanogenreId: 4726,
      x: 6632,
      y: 7534
    },{
      NanogenreId: 4727,
      x: 6720,
      y: 7114
    },{
      NanogenreId: 4728,
      x: 7576,
      y: 9308
    },{
      NanogenreId: 4729,
      x: 9626,
      y: 4738
    },{
      NanogenreId: 4730,
      x: 10012,
      y: 10531
    },{
      NanogenreId: 4731,
      x: 6444,
      y: 4450
    },{
      NanogenreId: 4732,
      x: 9225,
      y: 5739
    },{
      NanogenreId: 4733,
      x: 8725,
      y: 5360
    },{
      NanogenreId: 4734,
      x: 4801,
      y: 11021
    },{
      NanogenreId: 4735,
      x: 8500,
      y: 10794
    },{
      NanogenreId: 4736,
      x: 9109,
      y: 8967
    },{
      NanogenreId: 4737,
      x: 4759,
      y: 10564
    },{
      NanogenreId: 4738,
      x: 6245,
      y: 6628
    },{
      NanogenreId: 4739,
      x: 13285,
      y: 8662
    },{
      NanogenreId: 4740,
      x: 7499,
      y: 7041
    },{
      NanogenreId: 4741,
      x: 10065,
      y: 5596
    },{
      NanogenreId: 4742,
      x: 11949,
      y: 8776
    },{
      NanogenreId: 4743,
      x: 9097,
      y: 7660
    },{
      NanogenreId: 4744,
      x: 8780,
      y: 6092
    },{
      NanogenreId: 4745,
      x: 7673,
      y: 8589
    },{
      NanogenreId: 4746,
      x: 10186,
      y: 9356
    },{
      NanogenreId: 4747,
      x: 7890,
      y: 5897
    },{
      NanogenreId: 4748,
      x: 4717,
      y: 8266
    },{
      NanogenreId: 4749,
      x: 9104,
      y: 5462
    },{
      NanogenreId: 4750,
      x: 9792,
      y: 10525
    },{
      NanogenreId: 4751,
      x: 10134,
      y: 10182
    },{
      NanogenreId: 4752,
      x: 12336,
      y: 7924
    },{
      NanogenreId: 4753,
      x: 10364,
      y: 8866
    },{
      NanogenreId: 4754,
      x: 5094,
      y: 8547
    },{
      NanogenreId: 4755,
      x: 7416,
      y: 7118
    },{
      NanogenreId: 4756,
      x: 7488,
      y: 6507
    },{
      NanogenreId: 4757,
      x: 5883,
      y: 9398
    },{
      NanogenreId: 4758,
      x: 7923,
      y: 8215
    },{
      NanogenreId: 4759,
      x: 4979,
      y: 9169
    },{
      NanogenreId: 4760,
      x: 10158,
      y: 5054
    },{
      NanogenreId: 4761,
      x: 6808,
      y: 5474
    },{
      NanogenreId: 4762,
      x: 7715,
      y: 5244
    },{
      NanogenreId: 4763,
      x: 8726,
      y: 3506
    },{
      NanogenreId: 4764,
      x: 7491,
      y: 9319
    },{
      NanogenreId: 4765,
      x: 7742,
      y: 7608
    },{
      NanogenreId: 4766,
      x: 8110,
      y: 5415
    },{
      NanogenreId: 4767,
      x: 10801,
      y: 7365
    },{
      NanogenreId: 4768,
      x: 9487,
      y: 5096
    },{
      NanogenreId: 4769,
      x: 9403,
      y: 9308
    },{
      NanogenreId: 4770,
      x: 10183,
      y: 6823
    },{
      NanogenreId: 4771,
      x: 7493,
      y: 8834
    },{
      NanogenreId: 4772,
      x: 8596,
      y: 6618
    },{
      NanogenreId: 4773,
      x: 6323,
      y: 7310
    },{
      NanogenreId: 4774,
      x: 9924,
      y: 10449
    },{
      NanogenreId: 4775,
      x: 8584,
      y: 6679
    },{
      NanogenreId: 4776,
      x: 11377,
      y: 4500
    },{
      NanogenreId: 4777,
      x: 10405,
      y: 10481
    },{
      NanogenreId: 4778,
      x: 5981,
      y: 10592
    },{
      NanogenreId: 4779,
      x: 6519,
      y: 6705
    },{
      NanogenreId: 4780,
      x: 8494,
      y: 7516
    },{
      NanogenreId: 4781,
      x: 11320,
      y: 9928
    },{
      NanogenreId: 4782,
      x: 12920,
      y: 8506
    },{
      NanogenreId: 4783,
      x: 5665,
      y: 8725
    },{
      NanogenreId: 4784,
      x: 7008,
      y: 6938
    },{
      NanogenreId: 4785,
      x: 6833,
      y: 8814
    },{
      NanogenreId: 4786,
      x: 10393,
      y: 8164
    },{
      NanogenreId: 4787,
      x: 9902,
      y: 4970
    },{
      NanogenreId: 4788,
      x: 5072,
      y: 8688
    },{
      NanogenreId: 4789,
      x: 5210,
      y: 6980
    },{
      NanogenreId: 4790,
      x: 11010,
      y: 10309
    },{
      NanogenreId: 4791,
      x: 5855,
      y: 5843
    },{
      NanogenreId: 4792,
      x: 3762,
      y: 9935
    },{
      NanogenreId: 4793,
      x: 9842,
      y: 4923
    },{
      NanogenreId: 4794,
      x: 11473,
      y: 9214
    },{
      NanogenreId: 4795,
      x: 9564,
      y: 5367
    },{
      NanogenreId: 4796,
      x: 10514,
      y: 9486
    },{
      NanogenreId: 4797,
      x: 8885,
      y: 8104
    },{
      NanogenreId: 4798,
      x: 7184,
      y: 3974
    },{
      NanogenreId: 4799,
      x: 9558,
      y: 5632
    },{
      NanogenreId: 4800,
      x: 10873,
      y: 11043
    },{
      NanogenreId: 4801,
      x: 9837,
      y: 5109
    },{
      NanogenreId: 4802,
      x: 9784,
      y: 6258
    },{
      NanogenreId: 4803,
      x: 9452,
      y: 7653
    },{
      NanogenreId: 4804,
      x: 9055,
      y: 7477
    },{
      NanogenreId: 4805,
      x: 9500,
      y: 4974
    },{
      NanogenreId: 4806,
      x: 3835,
      y: 9784
    },{
      NanogenreId: 4807,
      x: 10297,
      y: 10380
    },{
      NanogenreId: 4808,
      x: 9324,
      y: 4775
    },{
      NanogenreId: 4809,
      x: 10181,
      y: 9926
    },{
      NanogenreId: 4810,
      x: 11308,
      y: 8760
    },{
      NanogenreId: 4811,
      x: 8215,
      y: 7756
    },{
      NanogenreId: 4812,
      x: 9254,
      y: 9655
    },{
      NanogenreId: 4813,
      x: 8929,
      y: 5263
    },{
      NanogenreId: 4814,
      x: 7805,
      y: 8897
    },{
      NanogenreId: 4815,
      x: 10592,
      y: 10709
    },{
      NanogenreId: 4816,
      x: 7184,
      y: 11084
    },{
      NanogenreId: 4817,
      x: 8620,
      y: 7775
    },{
      NanogenreId: 4818,
      x: 11052,
      y: 9455
    },{
      NanogenreId: 4819,
      x: 8255,
      y: 4105
    },{
      NanogenreId: 4820,
      x: 8822,
      y: 4470
    },{
      NanogenreId: 4821,
      x: 11010,
      y: 9775
    },{
      NanogenreId: 4822,
      x: 9771,
      y: 5481
    },{
      NanogenreId: 4823,
      x: 8988,
      y: 7723
    },{
      NanogenreId: 4824,
      x: 7426,
      y: 6393
    },{
      NanogenreId: 4825,
      x: 7286,
      y: 7625
    },{
      NanogenreId: 4826,
      x: 7325,
      y: 8047
    },{
      NanogenreId: 4827,
      x: 6801,
      y: 7673
    },{
      NanogenreId: 4828,
      x: 9910,
      y: 4520
    },{
      NanogenreId: 4829,
      x: 9963,
      y: 4769
    },{
      NanogenreId: 4830,
      x: 8491,
      y: 5096
    },{
      NanogenreId: 4831,
      x: 5407,
      y: 8829
    },{
      NanogenreId: 4832,
      x: 6717,
      y: 7316
    },{
      NanogenreId: 4833,
      x: 9297,
      y: 6599
    },{
      NanogenreId: 4834,
      x: 10168,
      y: 7051
    },{
      NanogenreId: 4835,
      x: 10961,
      y: 1841
    },{
      NanogenreId: 4836,
      x: 6934,
      y: 5711
    },{
      NanogenreId: 4837,
      x: 6977,
      y: 4058
    },{
      NanogenreId: 4838,
      x: 8806,
      y: 10963
    },{
      NanogenreId: 4839,
      x: 9755,
      y: 9239
    },{
      NanogenreId: 4840,
      x: 7896,
      y: 5859
    },{
      NanogenreId: 4841,
      x: 9879,
      y: 4803
    },{
      NanogenreId: 4842,
      x: 9398,
      y: 8030
    },{
      NanogenreId: 4843,
      x: 6604,
      y: 12390
    },{
      NanogenreId: 4844,
      x: 7884,
      y: 7478
    },{
      NanogenreId: 4845,
      x: 7177,
      y: 10842
    },{
      NanogenreId: 4846,
      x: 7946,
      y: 7742
    },{
      NanogenreId: 4847,
      x: 7237,
      y: 6703
    },{
      NanogenreId: 4848,
      x: 8523,
      y: 11251
    },{
      NanogenreId: 4849,
      x: 6114,
      y: 8745
    },{
      NanogenreId: 4850,
      x: 7850,
      y: 10057
    },{
      NanogenreId: 4851,
      x: 7647,
      y: 3648
    },{
      NanogenreId: 4852,
      x: 9026,
      y: 5452
    },{
      NanogenreId: 4853,
      x: 6760,
      y: 7261
    },{
      NanogenreId: 4854,
      x: 9686,
      y: 4953
    },{
      NanogenreId: 4855,
      x: 3943,
      y: 9730
    },{
      NanogenreId: 4856,
      x: 7740,
      y: 8620
    },{
      NanogenreId: 4857,
      x: 8058,
      y: 11852
    },{
      NanogenreId: 4858,
      x: 9802,
      y: 10175
    },{
      NanogenreId: 4859,
      x: 6301,
      y: 11139
    },{
      NanogenreId: 4860,
      x: 8643,
      y: 8938
    },{
      NanogenreId: 4861,
      x: 6073,
      y: 9676
    },{
      NanogenreId: 4862,
      x: 9872,
      y: 4989
    },{
      NanogenreId: 4863,
      x: 9523,
      y: 4579
    },{
      NanogenreId: 4864,
      x: 9340,
      y: 6476
    },{
      NanogenreId: 4865,
      x: 7910,
      y: 10220
    },{
      NanogenreId: 4866,
      x: 8070,
      y: 8646
    },{
      NanogenreId: 4867,
      x: 9969,
      y: 8251
    },{
      NanogenreId: 4868,
      x: 7703,
      y: 7996
    },{
      NanogenreId: 4869,
      x: 9189,
      y: 4680
    },{
      NanogenreId: 4870,
      x: 6632,
      y: 6920
    },{
      NanogenreId: 4871,
      x: 9849,
      y: 5951
    },{
      NanogenreId: 4872,
      x: 9337,
      y: 9520
    },{
      NanogenreId: 4873,
      x: 6287,
      y: 5671
    },{
      NanogenreId: 4874,
      x: 8808,
      y: 5206
    },{
      NanogenreId: 4875,
      x: 7652,
      y: 5513
    },{
      NanogenreId: 4876,
      x: 9070,
      y: 4438
    },{
      NanogenreId: 4877,
      x: 7518,
      y: 7258
    },{
      NanogenreId: 4878,
      x: 10345,
      y: 4811
    },{
      NanogenreId: 4879,
      x: 6631,
      y: 7360
    },{
      NanogenreId: 4880,
      x: 7731,
      y: 6244
    },{
      NanogenreId: 4881,
      x: 8215,
      y: 9760
    },{
      NanogenreId: 4882,
      x: 9845,
      y: 6611
    },{
      NanogenreId: 4883,
      x: 11026,
      y: 10607
    },{
      NanogenreId: 4884,
      x: 11613,
      y: 8943
    },{
      NanogenreId: 4885,
      x: 8909,
      y: 12160
    },{
      NanogenreId: 4886,
      x: 9555,
      y: 12093
    },{
      NanogenreId: 4887,
      x: 7606,
      y: 8867
    },{
      NanogenreId: 4888,
      x: 6768,
      y: 9369
    },{
      NanogenreId: 4889,
      x: 9272,
      y: 5385
    },{
      NanogenreId: 4890,
      x: 7459,
      y: 4582
    },{
      NanogenreId: 4891,
      x: 9565,
      y: 7576
    },{
      NanogenreId: 4892,
      x: 3266,
      y: 9759
    },{
      NanogenreId: 4893,
      x: 12504,
      y: 9430
    },{
      NanogenreId: 4894,
      x: 9424,
      y: 10259
    },{
      NanogenreId: 4895,
      x: 9458,
      y: 5588
    },{
      NanogenreId: 4896,
      x: 10542,
      y: 5892
    },{
      NanogenreId: 4897,
      x: 7971,
      y: 5193
    },{
      NanogenreId: 4898,
      x: 4788,
      y: 6399
    },{
      NanogenreId: 4899,
      x: 9595,
      y: 5940
    },{
      NanogenreId: 4900,
      x: 7879,
      y: 5065
    },{
      NanogenreId: 4901,
      x: 9849,
      y: 5139
    },{
      NanogenreId: 4902,
      x: 6979,
      y: 9793
    },{
      NanogenreId: 4903,
      x: 4876,
      y: 9483
    },{
      NanogenreId: 4904,
      x: 10203,
      y: 10176
    },{
      NanogenreId: 4905,
      x: 9150,
      y: 5532
    },{
      NanogenreId: 4906,
      x: 7577,
      y: 10605
    },{
      NanogenreId: 4907,
      x: 6339,
      y: 8767
    },{
      NanogenreId: 4908,
      x: 9985,
      y: 5539
    },{
      NanogenreId: 4909,
      x: 8131,
      y: 7867
    },{
      NanogenreId: 4910,
      x: 10680,
      y: 10499
    },{
      NanogenreId: 4911,
      x: 5603,
      y: 5250
    },{
      NanogenreId: 4912,
      x: 8226,
      y: 12561
    },{
      NanogenreId: 4913,
      x: 6388,
      y: 9138
    },{
      NanogenreId: 4914,
      x: 7087,
      y: 6738
    },{
      NanogenreId: 4915,
      x: 7624,
      y: 7460
    },{
      NanogenreId: 4916,
      x: 8413,
      y: 8814
    },{
      NanogenreId: 4917,
      x: 8245,
      y: 5786
    },{
      NanogenreId: 4918,
      x: 7429,
      y: 5217
    },{
      NanogenreId: 4919,
      x: 8877,
      y: 7176
    },{
      NanogenreId: 4920,
      x: 8695,
      y: 5439
    },{
      NanogenreId: 4921,
      x: 8013,
      y: 8616
    },{
      NanogenreId: 4922,
      x: 5285,
      y: 10540
    },{
      NanogenreId: 4923,
      x: 6941,
      y: 6982
    },{
      NanogenreId: 4924,
      x: 5696,
      y: 6535
    },{
      NanogenreId: 4925,
      x: 7267,
      y: 9713
    },{
      NanogenreId: 4926,
      x: 7864,
      y: 4570
    },{
      NanogenreId: 4927,
      x: 9702,
      y: 4623
    },{
      NanogenreId: 4928,
      x: 8041,
      y: 4970
    },{
      NanogenreId: 4929,
      x: 6337,
      y: 12171
    },{
      NanogenreId: 4930,
      x: 4118,
      y: 6224
    },{
      NanogenreId: 4931,
      x: 6556,
      y: 7619
    },{
      NanogenreId: 4932,
      x: 10704,
      y: 9953
    },{
      NanogenreId: 4933,
      x: 9195,
      y: 9316
    },{
      NanogenreId: 4934,
      x: 9248,
      y: 4457
    },{
      NanogenreId: 4935,
      x: 6611,
      y: 6669
    },{
      NanogenreId: 4936,
      x: 10204,
      y: 9982
    },{
      NanogenreId: 4937,
      x: 4102,
      y: 7466
    },{
      NanogenreId: 4938,
      x: 7304,
      y: 7503
    },{
      NanogenreId: 4939,
      x: 8930,
      y: 5035
    },{
      NanogenreId: 4940,
      x: 9618,
      y: 10736
    },{
      NanogenreId: 4941,
      x: 4660,
      y: 9107
    },{
      NanogenreId: 4942,
      x: 10133,
      y: 5069
    },{
      NanogenreId: 4943,
      x: 10054,
      y: 2175
    },{
      NanogenreId: 4944,
      x: 5426,
      y: 11565
    },{
      NanogenreId: 4945,
      x: 7011,
      y: 6231
    },{
      NanogenreId: 4946,
      x: 4521,
      y: 9763
    },{
      NanogenreId: 4947,
      x: 10916,
      y: 10274
    },{
      NanogenreId: 4948,
      x: 7723,
      y: 7675
    },{
      NanogenreId: 4949,
      x: 11495,
      y: 8611
    },{
      NanogenreId: 4950,
      x: 9760,
      y: 4873
    },{
      NanogenreId: 4951,
      x: 10122,
      y: 10061
    },{
      NanogenreId: 4952,
      x: 5477,
      y: 6298
    },{
      NanogenreId: 4953,
      x: 9070,
      y: 5100
    },{
      NanogenreId: 4954,
      x: 7979,
      y: 8855
    },{
      NanogenreId: 4955,
      x: 7714,
      y: 6901
    },{
      NanogenreId: 4956,
      x: 9226,
      y: 11436
    },{
      NanogenreId: 4957,
      x: 5199,
      y: 11307
    },{
      NanogenreId: 4958,
      x: 9596,
      y: 5244
    },{
      NanogenreId: 4959,
      x: 10078,
      y: 9033
    },{
      NanogenreId: 4960,
      x: 10117,
      y: 10277
    },{
      NanogenreId: 4961,
      x: 4455,
      y: 7407
    },{
      NanogenreId: 4962,
      x: 10312,
      y: 4495
    },{
      NanogenreId: 4963,
      x: 6988,
      y: 6540
    },{
      NanogenreId: 4964,
      x: 8827,
      y: 5652
    },{
      NanogenreId: 4965,
      x: 7628,
      y: 7096
    },{
      NanogenreId: 4966,
      x: 9334,
      y: 3185
    },{
      NanogenreId: 4967,
      x: 7718,
      y: 8150
    },{
      NanogenreId: 4968,
      x: 12152,
      y: 10117
    },{
      NanogenreId: 4969,
      x: 9333,
      y: 4397
    },{
      NanogenreId: 4970,
      x: 11224,
      y: 10628
    },{
      NanogenreId: 4971,
      x: 8351,
      y: 5121
    },{
      NanogenreId: 4972,
      x: 6585,
      y: 8992
    },{
      NanogenreId: 4973,
      x: 8877,
      y: 7020
    },{
      NanogenreId: 4974,
      x: 7165,
      y: 7207
    },{
      NanogenreId: 4975,
      x: 9344,
      y: 4919
    },{
      NanogenreId: 4976,
      x: 7476,
      y: 5303
    },{
      NanogenreId: 4977,
      x: 7984,
      y: 9296
    },{
      NanogenreId: 4978,
      x: 8723,
      y: 10596
    },{
      NanogenreId: 4979,
      x: 9815,
      y: 4727
    },{
      NanogenreId: 4980,
      x: 7463,
      y: 9490
    },{
      NanogenreId: 4981,
      x: 8000,
      y: 11125
    },{
      NanogenreId: 4982,
      x: 9629,
      y: 6966
    },{
      NanogenreId: 4983,
      x: 4904,
      y: 9561
    },{
      NanogenreId: 4984,
      x: 8808,
      y: 5409
    },{
      NanogenreId: 4985,
      x: 9915,
      y: 4595
    },{
      NanogenreId: 4986,
      x: 7280,
      y: 8809
    },{
      NanogenreId: 4987,
      x: 6446,
      y: 7160
    },{
      NanogenreId: 4988,
      x: 7328,
      y: 9085
    },{
      NanogenreId: 4989,
      x: 9176,
      y: 9636
    },{
      NanogenreId: 4990,
      x: 9292,
      y: 4995
    },{
      NanogenreId: 4991,
      x: 5593,
      y: 9516
    },{
      NanogenreId: 4992,
      x: 7930,
      y: 8497
    },{
      NanogenreId: 4993,
      x: 6221,
      y: 10430
    },{
      NanogenreId: 4994,
      x: 10961,
      y: 9363
    },{
      NanogenreId: 4995,
      x: 6930,
      y: 6485
    },{
      NanogenreId: 4996,
      x: 7049,
      y: 7610
    },{
      NanogenreId: 4997,
      x: 7626,
      y: 10812
    },{
      NanogenreId: 4998,
      x: 8559,
      y: 7230
    },{
      NanogenreId: 4999,
      x: 5403,
      y: 10979
    },{
      NanogenreId: 5000,
      x: 8525,
      y: 9139
    },{
      NanogenreId: 5001,
      x: 10319,
      y: 9247
    },{
      NanogenreId: 5002,
      x: 6820,
      y: 7219
    },{
      NanogenreId: 5003,
      x: 7013,
      y: 7670
    },{
      NanogenreId: 5004,
      x: 9229,
      y: 5489
    },{
      NanogenreId: 5005,
      x: 6545,
      y: 6018
    },{
      NanogenreId: 5006,
      x: 7889,
      y: 5531
    },{
      NanogenreId: 5007,
      x: 7587,
      y: 5451
    },{
      NanogenreId: 5008,
      x: 11437,
      y: 8430
    },{
      NanogenreId: 5009,
      x: 5456,
      y: 9749
    },{
      NanogenreId: 5010,
      x: 9339,
      y: 8922
    },{
      NanogenreId: 5011,
      x: 7250,
      y: 7735
    },{
      NanogenreId: 5012,
      x: 9758,
      y: 4596
    },{
      NanogenreId: 5013,
      x: 5278,
      y: 4310
    },{
      NanogenreId: 5014,
      x: 9679,
      y: 4803
    },{
      NanogenreId: 5015,
      x: 9630,
      y: 5044
    },{
      NanogenreId: 5016,
      x: 10074,
      y: 9177
    },{
      NanogenreId: 5017,
      x: 7098,
      y: 3090
    },{
      NanogenreId: 5018,
      x: 8157,
      y: 8555
    },{
      NanogenreId: 5019,
      x: 4925,
      y: 4533
    },{
      NanogenreId: 5020,
      x: 6713,
      y: 5582
    },{
      NanogenreId: 5021,
      x: 9352,
      y: 6614
    },{
      NanogenreId: 5022,
      x: 10466,
      y: 10495
    },{
      NanogenreId: 5023,
      x: 9744,
      y: 5816
    },{
      NanogenreId: 5024,
      x: 9653,
      y: 5337
    },{
      NanogenreId: 5025,
      x: 7865,
      y: 9219
    },{
      NanogenreId: 5026,
      x: 8505,
      y: 6639
    },{
      NanogenreId: 5027,
      x: 7836,
      y: 5699
    },{
      NanogenreId: 5028,
      x: 9624,
      y: 6365
    },{
      NanogenreId: 5029,
      x: 4125,
      y: 8821
    },{
      NanogenreId: 5030,
      x: 5122,
      y: 6954
    },{
      NanogenreId: 5031,
      x: 9639,
      y: 4806
    },{
      NanogenreId: 5032,
      x: 9238,
      y: 4544
    },{
      NanogenreId: 5033,
      x: 7449,
      y: 7516
    },{
      NanogenreId: 5034,
      x: 6898,
      y: 7046
    },{
      NanogenreId: 5035,
      x: 11061,
      y: 6724
    },{
      NanogenreId: 5036,
      x: 5386,
      y: 4190
    },{
      NanogenreId: 5037,
      x: 8274,
      y: 7173
    },{
      NanogenreId: 5038,
      x: 9565,
      y: 9328
    },{
      NanogenreId: 5039,
      x: 9408,
      y: 4563
    },{
      NanogenreId: 5040,
      x: 8114,
      y: 4575
    },{
      NanogenreId: 5041,
      x: 7703,
      y: 7237
    },{
      NanogenreId: 5042,
      x: 6720,
      y: 6144
    },{
      NanogenreId: 5043,
      x: 6882,
      y: 5679
    },{
      NanogenreId: 5044,
      x: 8324,
      y: 9805
    },{
      NanogenreId: 5045,
      x: 10015,
      y: 4826
    },{
      NanogenreId: 5046,
      x: 7667,
      y: 10391
    },{
      NanogenreId: 5047,
      x: 6204,
      y: 8632
    },{
      NanogenreId: 5048,
      x: 9014,
      y: 10577
    },{
      NanogenreId: 5049,
      x: 10068,
      y: 5117
    },{
      NanogenreId: 5050,
      x: 7095,
      y: 10106
    },{
      NanogenreId: 5051,
      x: 7630,
      y: 6690
    },{
      NanogenreId: 5052,
      x: 6625,
      y: 11601
    },{
      NanogenreId: 5053,
      x: 4517,
      y: 8787
    },{
      NanogenreId: 5054,
      x: 8802,
      y: 8114
    },{
      NanogenreId: 5055,
      x: 10561,
      y: 6194
    },{
      NanogenreId: 5056,
      x: 4890,
      y: 8591
    },{
      NanogenreId: 5057,
      x: 9538,
      y: 4930
    },{
      NanogenreId: 5058,
      x: 6307,
      y: 5973
    },{
      NanogenreId: 5059,
      x: 6217,
      y: 9459
    },{
      NanogenreId: 5060,
      x: 8813,
      y: 8440
    },{
      NanogenreId: 5061,
      x: 10218,
      y: 10709
    },{
      NanogenreId: 5062,
      x: 8396,
      y: 5187
    },{
      NanogenreId: 5063,
      x: 9189,
      y: 3751
    },{
      NanogenreId: 5064,
      x: 4058,
      y: 9361
    },{
      NanogenreId: 5065,
      x: 4720,
      y: 6560
    },{
      NanogenreId: 5066,
      x: 4663,
      y: 6328
    },{
      NanogenreId: 5067,
      x: 6146,
      y: 6924
    },{
      NanogenreId: 5068,
      x: 10283,
      y: 10623
    },{
      NanogenreId: 5069,
      x: 5881,
      y: 8638
    },{
      NanogenreId: 5070,
      x: 10808,
      y: 10555
    },{
      NanogenreId: 5071,
      x: 6248,
      y: 7659
    },{
      NanogenreId: 5072,
      x: 8915,
      y: 5205
    },{
      NanogenreId: 5073,
      x: 7206,
      y: 6974
    },{
      NanogenreId: 5074,
      x: 8221,
      y: 6134
    },{
      NanogenreId: 5075,
      x: 9551,
      y: 8652
    },{
      NanogenreId: 5076,
      x: 13578,
      y: 7185
    },{
      NanogenreId: 5077,
      x: 6363,
      y: 13037
    },{
      NanogenreId: 5078,
      x: 10413,
      y: 10043
    },{
      NanogenreId: 5079,
      x: 9636,
      y: 4904
    },{
      NanogenreId: 5080,
      x: 12868,
      y: 5668
    },{
      NanogenreId: 5081,
      x: 9662,
      y: 6718
    },{
      NanogenreId: 5082,
      x: 9506,
      y: 5028
    },{
      NanogenreId: 5083,
      x: 7585,
      y: 5567
    },{
      NanogenreId: 5084,
      x: 5513,
      y: 6083
    },{
      NanogenreId: 5085,
      x: 9634,
      y: 9271
    },{
      NanogenreId: 5086,
      x: 7328,
      y: 8719
    },{
      NanogenreId: 5087,
      x: 7967,
      y: 8275
    },{
      NanogenreId: 5088,
      x: 5594,
      y: 9903
    },{
      NanogenreId: 5089,
      x: 10337,
      y: 10388
    },{
      NanogenreId: 5090,
      x: 7110,
      y: 7974
    },{
      NanogenreId: 5091,
      x: 9741,
      y: 6792
    },{
      NanogenreId: 5092,
      x: 10007,
      y: 10158
    },{
      NanogenreId: 5093,
      x: 8833,
      y: 8981
    },{
      NanogenreId: 5094,
      x: 9641,
      y: 9895
    },{
      NanogenreId: 5095,
      x: 13136,
      y: 3091
    },{
      NanogenreId: 5096,
      x: 7551,
      y: 7788
    },{
      NanogenreId: 5097,
      x: 5447,
      y: 5892
    },{
      NanogenreId: 5098,
      x: 9528,
      y: 5339
    },{
      NanogenreId: 5099,
      x: 9566,
      y: 7153
    },{
      NanogenreId: 5100,
      x: 5453,
      y: 7397
    },{
      NanogenreId: 5101,
      x: 10702,
      y: 9774
    },{
      NanogenreId: 5102,
      x: 9211,
      y: 4743
    },{
      NanogenreId: 5103,
      x: 8544,
      y: 6965
    },{
      NanogenreId: 5104,
      x: 7889,
      y: 5742
    },{
      NanogenreId: 5105,
      x: 9761,
      y: 4733
    },{
      NanogenreId: 5106,
      x: 9868,
      y: 6157
    },{
      NanogenreId: 5107,
      x: 9346,
      y: 5775
    },{
      NanogenreId: 5108,
      x: 12630,
      y: 8867
    },{
      NanogenreId: 5109,
      x: 6532,
      y: 9101
    },{
      NanogenreId: 5110,
      x: 6297,
      y: 10541
    },{
      NanogenreId: 5111,
      x: 7175,
      y: 6009
    },{
      NanogenreId: 5112,
      x: 9665,
      y: 4982
    },{
      NanogenreId: 5113,
      x: 10613,
      y: 8534
    },{
      NanogenreId: 5114,
      x: 8237,
      y: 4718
    },{
      NanogenreId: 5115,
      x: 6888,
      y: 5607
    },{
      NanogenreId: 5116,
      x: 7030,
      y: 7062
    },{
      NanogenreId: 5117,
      x: 6911,
      y: 4433
    },{
      NanogenreId: 5118,
      x: 10745,
      y: 10633
    },{
      NanogenreId: 5119,
      x: 7741,
      y: 6363
    },{
      NanogenreId: 5120,
      x: 9991,
      y: 10398
    },{
      NanogenreId: 5121,
      x: 9825,
      y: 9635
    },{
      NanogenreId: 5122,
      x: 7762,
      y: 7763
    },{
      NanogenreId: 5123,
      x: 8037,
      y: 9365
    },{
      NanogenreId: 5124,
      x: 10045,
      y: 10507
    },{
      NanogenreId: 5125,
      x: 6941,
      y: 10353
    },{
      NanogenreId: 5126,
      x: 10917,
      y: 10230
    },{
      NanogenreId: 5127,
      x: 8788,
      y: 11464
    },{
      NanogenreId: 5128,
      x: 7509,
      y: 8491
    },{
      NanogenreId: 5129,
      x: 7905,
      y: 8032
    },{
      NanogenreId: 5130,
      x: 7858,
      y: 8471
    },{
      NanogenreId: 5131,
      x: 6149,
      y: 6183
    },{
      NanogenreId: 5132,
      x: 7434,
      y: 8336
    },{
      NanogenreId: 5133,
      x: 9827,
      y: 6707
    },{
      NanogenreId: 5134,
      x: 7935,
      y: 5481
    },{
      NanogenreId: 5135,
      x: 10969,
      y: 6420
    },{
      NanogenreId: 5136,
      x: 6583,
      y: 7722
    },{
      NanogenreId: 5137,
      x: 7749,
      y: 6101
    },{
      NanogenreId: 5138,
      x: 6692,
      y: 6843
    },{
      NanogenreId: 5139,
      x: 5515,
      y: 6397
    },{
      NanogenreId: 5140,
      x: 8365,
      y: 3896
    },{
      NanogenreId: 5141,
      x: 6856,
      y: 7599
    },{
      NanogenreId: 5142,
      x: 8165,
      y: 7487
    },{
      NanogenreId: 5143,
      x: 10364,
      y: 6503
    },{
      NanogenreId: 5144,
      x: 7848,
      y: 8756
    },{
      NanogenreId: 5145,
      x: 7105,
      y: 7608
    },{
      NanogenreId: 5146,
      x: 9345,
      y: 7559
    },{
      NanogenreId: 5147,
      x: 8430,
      y: 8275
    },{
      NanogenreId: 5148,
      x: 12687,
      y: 8238
    },{
      NanogenreId: 5149,
      x: 7408,
      y: 9012
    },{
      NanogenreId: 5150,
      x: 6207,
      y: 7546
    },{
      NanogenreId: 5151,
      x: 9536,
      y: 4545
    },{
      NanogenreId: 5152,
      x: 8195,
      y: 7904
    },{
      NanogenreId: 5153,
      x: 7955,
      y: 6950
    },{
      NanogenreId: 5154,
      x: 9764,
      y: 5228
    },{
      NanogenreId: 5155,
      x: 7531,
      y: 6888
    },{
      NanogenreId: 5156,
      x: 8646,
      y: 4962
    },{
      NanogenreId: 5157,
      x: 8523,
      y: 5025
    },{
      NanogenreId: 5158,
      x: 8629,
      y: 5503
    },{
      NanogenreId: 5159,
      x: 8571,
      y: 10286
    },{
      NanogenreId: 5160,
      x: 9727,
      y: 10199
    },{
      NanogenreId: 5161,
      x: 11290,
      y: 10560
    },{
      NanogenreId: 5162,
      x: 8257,
      y: 3877
    },{
      NanogenreId: 5163,
      x: 8169,
      y: 8629
    },{
      NanogenreId: 5164,
      x: 6684,
      y: 10883
    },{
      NanogenreId: 5165,
      x: 12656,
      y: 8742
    },{
      NanogenreId: 5166,
      x: 12305,
      y: 4307
    },{
      NanogenreId: 5167,
      x: 7567,
      y: 9243
    },{
      NanogenreId: 5168,
      x: 8578,
      y: 5706
    },{
      NanogenreId: 5169,
      x: 9255,
      y: 5233
    },{
      NanogenreId: 5170,
      x: 7940,
      y: 7376
    },{
      NanogenreId: 5171,
      x: 8684,
      y: 10481
    },{
      NanogenreId: 5172,
      x: 4962,
      y: 7879
    },{
      NanogenreId: 5173,
      x: 9509,
      y: 9564
    },{
      NanogenreId: 5174,
      x: 11189,
      y: 10589
    },{
      NanogenreId: 5175,
      x: 12971,
      y: 5086
    },{
      NanogenreId: 5176,
      x: 8365,
      y: 10461
    },{
      NanogenreId: 5177,
      x: 7203,
      y: 7227
    },{
      NanogenreId: 5178,
      x: 6066,
      y: 7280
    },{
      NanogenreId: 5179,
      x: 7325,
      y: 6872
    },{
      NanogenreId: 5180,
      x: 7898,
      y: 5252
    },{
      NanogenreId: 5181,
      x: 9320,
      y: 4656
    },{
      NanogenreId: 5182,
      x: 7147,
      y: 8086
    },{
      NanogenreId: 5183,
      x: 5660,
      y: 9257
    },{
      NanogenreId: 5184,
      x: 9660,
      y: 4763
    },{
      NanogenreId: 5185,
      x: 10998,
      y: 5332
    },{
      NanogenreId: 5186,
      x: 4048,
      y: 7391
    },{
      NanogenreId: 5187,
      x: 7351,
      y: 8056
    },{
      NanogenreId: 5188,
      x: 7572,
      y: 5206
    },{
      NanogenreId: 5189,
      x: 6060,
      y: 6051
    },{
      NanogenreId: 5190,
      x: 9256,
      y: 10965
    },{
      NanogenreId: 5191,
      x: 8074,
      y: 7705
    },{
      NanogenreId: 5192,
      x: 7358,
      y: 6238
    },{
      NanogenreId: 5193,
      x: 8280,
      y: 6051
    },{
      NanogenreId: 5194,
      x: 7442,
      y: 6231
    },{
      NanogenreId: 5195,
      x: 9661,
      y: 7751
    },{
      NanogenreId: 5196,
      x: 7262,
      y: 5676
    },{
      NanogenreId: 5197,
      x: 3522,
      y: 9822
    },{
      NanogenreId: 5198,
      x: 7095,
      y: 6685
    },{
      NanogenreId: 5199,
      x: 9165,
      y: 4888
    },{
      NanogenreId: 5200,
      x: 9313,
      y: 5251
    },{
      NanogenreId: 5201,
      x: 10806,
      y: 9364
    },{
      NanogenreId: 5202,
      x: 3766,
      y: 7418
    },{
      NanogenreId: 5203,
      x: 8781,
      y: 5088
    },{
      NanogenreId: 5204,
      x: 7543,
      y: 7930
    },{
      NanogenreId: 5205,
      x: 9205,
      y: 7723
    },{
      NanogenreId: 5206,
      x: 9664,
      y: 5082
    },{
      NanogenreId: 5207,
      x: 6723,
      y: 5484
    },{
      NanogenreId: 5208,
      x: 9564,
      y: 5174
    },{
      NanogenreId: 5209,
      x: 8419,
      y: 8098
    },{
      NanogenreId: 5210,
      x: 8172,
      y: 10227
    },{
      NanogenreId: 5211,
      x: 10011,
      y: 4990
    },{
      NanogenreId: 5212,
      x: 5869,
      y: 6951
    },{
      NanogenreId: 5213,
      x: 9908,
      y: 9989
    },{
      NanogenreId: 5214,
      x: 3547,
      y: 9782
    },{
      NanogenreId: 5215,
      x: 7230,
      y: 6180
    },{
      NanogenreId: 5216,
      x: 8348,
      y: 8687
    },{
      NanogenreId: 5217,
      x: 8935,
      y: 10007
    },{
      NanogenreId: 5218,
      x: 8133,
      y: 6816
    },{
      NanogenreId: 5219,
      x: 7201,
      y: 10633
    },{
      NanogenreId: 5220,
      x: 10112,
      y: 9804
    },{
      NanogenreId: 5221,
      x: 9355,
      y: 5820
    },{
      NanogenreId: 5222,
      x: 7244,
      y: 8529
    },{
      NanogenreId: 5223,
      x: 9819,
      y: 11543
    },{
      NanogenreId: 5224,
      x: 7012,
      y: 5558
    },{
      NanogenreId: 5225,
      x: 8936,
      y: 9643
    },{
      NanogenreId: 5226,
      x: 8251,
      y: 5619
    },{
      NanogenreId: 5227,
      x: 8712,
      y: 5512
    },{
      NanogenreId: 5228,
      x: 6911,
      y: 11454
    },{
      NanogenreId: 5229,
      x: 5142,
      y: 10189
    },{
      NanogenreId: 5230,
      x: 8625,
      y: 6151
    },{
      NanogenreId: 5231,
      x: 6499,
      y: 5444
    },{
      NanogenreId: 5232,
      x: 10700,
      y: 10074
    },{
      NanogenreId: 5233,
      x: 6694,
      y: 9888
    },{
      NanogenreId: 5234,
      x: 9700,
      y: 5001
    },{
      NanogenreId: 5235,
      x: 8988,
      y: 8824
    },{
      NanogenreId: 5236,
      x: 6040,
      y: 7467
    },{
      NanogenreId: 5237,
      x: 9448,
      y: 9156
    },{
      NanogenreId: 5238,
      x: 8183,
      y: 9981
    },{
      NanogenreId: 5239,
      x: 6600,
      y: 7780
    },{
      NanogenreId: 5240,
      x: 9099,
      y: 4637
    },{
      NanogenreId: 5241,
      x: 5250,
      y: 8294
    },{
      NanogenreId: 5242,
      x: 8074,
      y: 5762
    },{
      NanogenreId: 5243,
      x: 9017,
      y: 5940
    },{
      NanogenreId: 5244,
      x: 5714,
      y: 7001
    },{
      NanogenreId: 5245,
      x: 8405,
      y: 10930
    },{
      NanogenreId: 5246,
      x: 9115,
      y: 8895
    },{
      NanogenreId: 5247,
      x: 5267,
      y: 8162
    },{
      NanogenreId: 5248,
      x: 9725,
      y: 5204
    },{
      NanogenreId: 5249,
      x: 6356,
      y: 7837
    },{
      NanogenreId: 5250,
      x: 6775,
      y: 6519
    },{
      NanogenreId: 5251,
      x: 5667,
      y: 7926
    },{
      NanogenreId: 5252,
      x: 5355,
      y: 5019
    },{
      NanogenreId: 5253,
      x: 4932,
      y: 5483
    },{
      NanogenreId: 5254,
      x: 8575,
      y: 7101
    },{
      NanogenreId: 5255,
      x: 5984,
      y: 7273
    },{
      NanogenreId: 5256,
      x: 4859,
      y: 7670
    },{
      NanogenreId: 5257,
      x: 7490,
      y: 8113
    },{
      NanogenreId: 5258,
      x: 7733,
      y: 11373
    },{
      NanogenreId: 5259,
      x: 6779,
      y: 4647
    },{
      NanogenreId: 5260,
      x: 6709,
      y: 10050
    },{
      NanogenreId: 5261,
      x: 7322,
      y: 6395
    },{
      NanogenreId: 5262,
      x: 4985,
      y: 7801
    },{
      NanogenreId: 5263,
      x: 7196,
      y: 9355
    },{
      NanogenreId: 5264,
      x: 6223,
      y: 12139
    },{
      NanogenreId: 5265,
      x: 6657,
      y: 6514
    },{
      NanogenreId: 5266,
      x: 10784,
      y: 10519
    },{
      NanogenreId: 5267,
      x: 9229,
      y: 5071
    },{
      NanogenreId: 5268,
      x: 9389,
      y: 5736
    },{
      NanogenreId: 5269,
      x: 4528,
      y: 9400
    },{
      NanogenreId: 5270,
      x: 9302,
      y: 5123
    },{
      NanogenreId: 5271,
      x: 4070,
      y: 9691
    },{
      NanogenreId: 5272,
      x: 8729,
      y: 6619
    },{
      NanogenreId: 5273,
      x: 9318,
      y: 4975
    },{
      NanogenreId: 5274,
      x: 10344,
      y: 6778
    },{
      NanogenreId: 5275,
      x: 9775,
      y: 4766
    },{
      NanogenreId: 5276,
      x: 13334,
      y: 7773
    },{
      NanogenreId: 5277,
      x: 11028,
      y: 8472
    },{
      NanogenreId: 5278,
      x: 5122,
      y: 7939
    },{
      NanogenreId: 5279,
      x: 9606,
      y: 4889
    },{
      NanogenreId: 5280,
      x: 5399,
      y: 8381
    },{
      NanogenreId: 5281,
      x: 8444,
      y: 7709
    },{
      NanogenreId: 5282,
      x: 8629,
      y: 5382
    },{
      NanogenreId: 5283,
      x: 6221,
      y: 8282
    },{
      NanogenreId: 5284,
      x: 8877,
      y: 5740
    },{
      NanogenreId: 5285,
      x: 7054,
      y: 10198
    },{
      NanogenreId: 5286,
      x: 8933,
      y: 5318
    },{
      NanogenreId: 5287,
      x: 9035,
      y: 6595
    },{
      NanogenreId: 5288,
      x: 9239,
      y: 5372
    },{
      NanogenreId: 5289,
      x: 9467,
      y: 5775
    },{
      NanogenreId: 5290,
      x: 9387,
      y: 6612
    },{
      NanogenreId: 5291,
      x: 8319,
      y: 6996
    },{
      NanogenreId: 5292,
      x: 11051,
      y: 6461
    },{
      NanogenreId: 5293,
      x: 10039,
      y: 10092
    },{
      NanogenreId: 5294,
      x: 5981,
      y: 6310
    },{
      NanogenreId: 5295,
      x: 5896,
      y: 5221
    },{
      NanogenreId: 5296,
      x: 8138,
      y: 5303
    },{
      NanogenreId: 5297,
      x: 7577,
      y: 6704
    },{
      NanogenreId: 5298,
      x: 9355,
      y: 5255
    },{
      NanogenreId: 5299,
      x: 7001,
      y: 7908
    },{
      NanogenreId: 5300,
      x: 8315,
      y: 5555
    },{
      NanogenreId: 5301,
      x: 7714,
      y: 5887
    },{
      NanogenreId: 5302,
      x: 9991,
      y: 6644
    },{
      NanogenreId: 5303,
      x: 9704,
      y: 5815
    },{
      NanogenreId: 5304,
      x: 7738,
      y: 5768
    },{
      NanogenreId: 5305,
      x: 8631,
      y: 10840
    },{
      NanogenreId: 5306,
      x: 9689,
      y: 5970
    },{
      NanogenreId: 5307,
      x: 4994,
      y: 10303
    },{
      NanogenreId: 5308,
      x: 10161,
      y: 11023
    },{
      NanogenreId: 5309,
      x: 6911,
      y: 8891
    },{
      NanogenreId: 5310,
      x: 12478,
      y: 8666
    },{
      NanogenreId: 5311,
      x: 6618,
      y: 7996
    },{
      NanogenreId: 5312,
      x: 9005,
      y: 5211
    },{
      NanogenreId: 5313,
      x: 9486,
      y: 9805
    },{
      NanogenreId: 5314,
      x: 5987,
      y: 6409
    },{
      NanogenreId: 5315,
      x: 7347,
      y: 10823
    },{
      NanogenreId: 5316,
      x: 7497,
      y: 10583
    },{
      NanogenreId: 5317,
      x: 9997,
      y: 9949
    },{
      NanogenreId: 5318,
      x: 5091,
      y: 7096
    },{
      NanogenreId: 5319,
      x: 10659,
      y: 10092
    },{
      NanogenreId: 5320,
      x: 10835,
      y: 10350
    },{
      NanogenreId: 5321,
      x: 9229,
      y: 6414
    },{
      NanogenreId: 5322,
      x: 8380,
      y: 10106
    },{
      NanogenreId: 5323,
      x: 7531,
      y: 5968
    },{
      NanogenreId: 5324,
      x: 11603,
      y: 10629
    },{
      NanogenreId: 5325,
      x: 9322,
      y: 5128
    },{
      NanogenreId: 5326,
      x: 4997,
      y: 7242
    },{
      NanogenreId: 5327,
      x: 5441,
      y: 6371
    },{
      NanogenreId: 5328,
      x: 7720,
      y: 9106
    },{
      NanogenreId: 5329,
      x: 9789,
      y: 4795
    },{
      NanogenreId: 5330,
      x: 7963,
      y: 5401
    },{
      NanogenreId: 5331,
      x: 11130,
      y: 10248
    },{
      NanogenreId: 5332,
      x: 6929,
      y: 8964
    },{
      NanogenreId: 5333,
      x: 9754,
      y: 5036
    },{
      NanogenreId: 5334,
      x: 6911,
      y: 6835
    },{
      NanogenreId: 5335,
      x: 6886,
      y: 5719
    },{
      NanogenreId: 5336,
      x: 9001,
      y: 6498
    },{
      NanogenreId: 5337,
      x: 7566,
      y: 3977
    },{
      NanogenreId: 5338,
      x: 7328,
      y: 8607
    },{
      NanogenreId: 5339,
      x: 6555,
      y: 4001
    },{
      NanogenreId: 5340,
      x: 9651,
      y: 8982
    },{
      NanogenreId: 5341,
      x: 8612,
      y: 5189
    },{
      NanogenreId: 5342,
      x: 9717,
      y: 3317
    },{
      NanogenreId: 5343,
      x: 5720,
      y: 6300
    },{
      NanogenreId: 5344,
      x: 7204,
      y: 7315
    },{
      NanogenreId: 5345,
      x: 7366,
      y: 8547
    },{
      NanogenreId: 5346,
      x: 6970,
      y: 9401
    },{
      NanogenreId: 5347,
      x: 9833,
      y: 6992
    },{
      NanogenreId: 5348,
      x: 11620,
      y: 2654
    },{
      NanogenreId: 5349,
      x: 6396,
      y: 6164
    },{
      NanogenreId: 5350,
      x: 8983,
      y: 9303
    },{
      NanogenreId: 5351,
      x: 10723,
      y: 10608
    },{
      NanogenreId: 5352,
      x: 4873,
      y: 4482
    },{
      NanogenreId: 5353,
      x: 9261,
      y: 11049
    },{
      NanogenreId: 5354,
      x: 9847,
      y: 8297
    },{
      NanogenreId: 5355,
      x: 7243,
      y: 7176
    },{
      NanogenreId: 5356,
      x: 8281,
      y: 6698
    },{
      NanogenreId: 5357,
      x: 7905,
      y: 5797
    },{
      NanogenreId: 5358,
      x: 8619,
      y: 5782
    },{
      NanogenreId: 5359,
      x: 9617,
      y: 5213
    },{
      NanogenreId: 5360,
      x: 6545,
      y: 6276
    },{
      NanogenreId: 5361,
      x: 7784,
      y: 6903
    },{
      NanogenreId: 5362,
      x: 7843,
      y: 6276
    },{
      NanogenreId: 5363,
      x: 6232,
      y: 7003
    },{
      NanogenreId: 5364,
      x: 5603,
      y: 8056
    },{
      NanogenreId: 5365,
      x: 7477,
      y: 4915
    },{
      NanogenreId: 5366,
      x: 11461,
      y: 9649
    },{
      NanogenreId: 5367,
      x: 9429,
      y: 7456
    },{
      NanogenreId: 5368,
      x: 6662,
      y: 6311
    },{
      NanogenreId: 5369,
      x: 7166,
      y: 8947
    },{
      NanogenreId: 5370,
      x: 8658,
      y: 7183
    },{
      NanogenreId: 5371,
      x: 7352,
      y: 10756
    },{
      NanogenreId: 5372,
      x: 6861,
      y: 6796
    },{
      NanogenreId: 5373,
      x: 9819,
      y: 5565
    },{
      NanogenreId: 5374,
      x: 8130,
      y: 7389
    },{
      NanogenreId: 5375,
      x: 9362,
      y: 4539
    },{
      NanogenreId: 5376,
      x: 8176,
      y: 9258
    },{
      NanogenreId: 5377,
      x: 10011,
      y: 5496
    },{
      NanogenreId: 5378,
      x: 8566,
      y: 5258
    },{
      NanogenreId: 5379,
      x: 9029,
      y: 4445
    },{
      NanogenreId: 5380,
      x: 6247,
      y: 7335
    },{
      NanogenreId: 5381,
      x: 6490,
      y: 7603
    },{
      NanogenreId: 5382,
      x: 5469,
      y: 9281
    },{
      NanogenreId: 5383,
      x: 9764,
      y: 7473
    },{
      NanogenreId: 5384,
      x: 5134,
      y: 4518
    },{
      NanogenreId: 5385,
      x: 8016,
      y: 9234
    },{
      NanogenreId: 5386,
      x: 5668,
      y: 9853
    },{
      NanogenreId: 5387,
      x: 8092,
      y: 7170
    },{
      NanogenreId: 5388,
      x: 8407,
      y: 9869
    },{
      NanogenreId: 5389,
      x: 10195,
      y: 7713
    },{
      NanogenreId: 5390,
      x: 6405,
      y: 5593
    },{
      NanogenreId: 5391,
      x: 10743,
      y: 9861
    },{
      NanogenreId: 5392,
      x: 9514,
      y: 9475
    },{
      NanogenreId: 5393,
      x: 8033,
      y: 5373
    },{
      NanogenreId: 5394,
      x: 7323,
      y: 6954
    },{
      NanogenreId: 5395,
      x: 10242,
      y: 10226
    },{
      NanogenreId: 5396,
      x: 6379,
      y: 5399
    },{
      NanogenreId: 5397,
      x: 6525,
      y: 7789
    },{
      NanogenreId: 5398,
      x: 9333,
      y: 4857
    },{
      NanogenreId: 5399,
      x: 7009,
      y: 8307
    },{
      NanogenreId: 5400,
      x: 11299,
      y: 8865
    },{
      NanogenreId: 5401,
      x: 7277,
      y: 9611
    },{
      NanogenreId: 5402,
      x: 6514,
      y: 9930
    },{
      NanogenreId: 5403,
      x: 10304,
      y: 10979
    },{
      NanogenreId: 5404,
      x: 8074,
      y: 5837
    },{
      NanogenreId: 5405,
      x: 9914,
      y: 7122
    },{
      NanogenreId: 5406,
      x: 8112,
      y: 8070
    },{
      NanogenreId: 5407,
      x: 9725,
      y: 2192
    },{
      NanogenreId: 5408,
      x: 5203,
      y: 7887
    },{
      NanogenreId: 5409,
      x: 9442,
      y: 5142
    },{
      NanogenreId: 5410,
      x: 8105,
      y: 6273
    },{
      NanogenreId: 5411,
      x: 10646,
      y: 7284
    },{
      NanogenreId: 5412,
      x: 4248,
      y: 9179
    },{
      NanogenreId: 5413,
      x: 7753,
      y: 7471
    },{
      NanogenreId: 5414,
      x: 6899,
      y: 6996
    },{
      NanogenreId: 5415,
      x: 9377,
      y: 7193
    },{
      NanogenreId: 5416,
      x: 6289,
      y: 6881
    },{
      NanogenreId: 5417,
      x: 9589,
      y: 5021
    },{
      NanogenreId: 5418,
      x: 7222,
      y: 10588
    },{
      NanogenreId: 5419,
      x: 8009,
      y: 9808
    },{
      NanogenreId: 5420,
      x: 5081,
      y: 7796
    },{
      NanogenreId: 5421,
      x: 6405,
      y: 7585
    },{
      NanogenreId: 5422,
      x: 7118,
      y: 4528
    },{
      NanogenreId: 5423,
      x: 8355,
      y: 8265
    },{
      NanogenreId: 5424,
      x: 8130,
      y: 5932
    },{
      NanogenreId: 5425,
      x: 5482,
      y: 9573
    },{
      NanogenreId: 5426,
      x: 5771,
      y: 7479
    },{
      NanogenreId: 5427,
      x: 7982,
      y: 6349
    },{
      NanogenreId: 5428,
      x: 4741,
      y: 10118
    },{
      NanogenreId: 5429,
      x: 8872,
      y: 8893
    },{
      NanogenreId: 5430,
      x: 7819,
      y: 7532
    },{
      NanogenreId: 5431,
      x: 9400,
      y: 6577
    },{
      NanogenreId: 5432,
      x: 5311,
      y: 8860
    },{
      NanogenreId: 5433,
      x: 7011,
      y: 8744
    },{
      NanogenreId: 5434,
      x: 7331,
      y: 7193
    },{
      NanogenreId: 5435,
      x: 7391,
      y: 8270
    },{
      NanogenreId: 5436,
      x: 7733,
      y: 5401
    },{
      NanogenreId: 5437,
      x: 8356,
      y: 9712
    },{
      NanogenreId: 5438,
      x: 8841,
      y: 7053
    },{
      NanogenreId: 5439,
      x: 8586,
      y: 5941
    },{
      NanogenreId: 5440,
      x: 8619,
      y: 5718
    },{
      NanogenreId: 5441,
      x: 10149,
      y: 10101
    },{
      NanogenreId: 5442,
      x: 10876,
      y: 10024
    },{
      NanogenreId: 5443,
      x: 8440,
      y: 5310
    },{
      NanogenreId: 5444,
      x: 7247,
      y: 5929
    },{
      NanogenreId: 5445,
      x: 9423,
      y: 9226
    },{
      NanogenreId: 5446,
      x: 8937,
      y: 6757
    },{
      NanogenreId: 5447,
      x: 9720,
      y: 5312
    },{
      NanogenreId: 5448,
      x: 6660,
      y: 6656
    },{
      NanogenreId: 5449,
      x: 9479,
      y: 5381
    },{
      NanogenreId: 5450,
      x: 11345,
      y: 7449
    },{
      NanogenreId: 5451,
      x: 6080,
      y: 5782
    },{
      NanogenreId: 5452,
      x: 8103,
      y: 5992
    },{
      NanogenreId: 5453,
      x: 9784,
      y: 9707
    },{
      NanogenreId: 5454,
      x: 8085,
      y: 3378
    },{
      NanogenreId: 5455,
      x: 9546,
      y: 5379
    },{
      NanogenreId: 5456,
      x: 8209,
      y: 6793
    },{
      NanogenreId: 5457,
      x: 10207,
      y: 10431
    },{
      NanogenreId: 5458,
      x: 8822,
      y: 7712
    },{
      NanogenreId: 5459,
      x: 12758,
      y: 6272
    },{
      NanogenreId: 5460,
      x: 9323,
      y: 9245
    },{
      NanogenreId: 5461,
      x: 10125,
      y: 9562
    },{
      NanogenreId: 5462,
      x: 6730,
      y: 8277
    },{
      NanogenreId: 5463,
      x: 6034,
      y: 10243
    },{
      NanogenreId: 5464,
      x: 9246,
      y: 10237
    },{
      NanogenreId: 5465,
      x: 8573,
      y: 10638
    },{
      NanogenreId: 5466,
      x: 5251,
      y: 7151
    },{
      NanogenreId: 5467,
      x: 9119,
      y: 5044
    },{
      NanogenreId: 5468,
      x: 10058,
      y: 5774
    },{
      NanogenreId: 5469,
      x: 6943,
      y: 5386
    },{
      NanogenreId: 5470,
      x: 9460,
      y: 5084
    },{
      NanogenreId: 5471,
      x: 7505,
      y: 7146
    },{
      NanogenreId: 5472,
      x: 10597,
      y: 10413
    },{
      NanogenreId: 5473,
      x: 4627,
      y: 8745
    },{
      NanogenreId: 5474,
      x: 5537,
      y: 6907
    },{
      NanogenreId: 5475,
      x: 9918,
      y: 9475
    },{
      NanogenreId: 5476,
      x: 6325,
      y: 8428
    },{
      NanogenreId: 5477,
      x: 7545,
      y: 8366
    },{
      NanogenreId: 5478,
      x: 5606,
      y: 5910
    },{
      NanogenreId: 5479,
      x: 8543,
      y: 11861
    },{
      NanogenreId: 5480,
      x: 7131,
      y: 7356
    },{
      NanogenreId: 5481,
      x: 7412,
      y: 5316
    },{
      NanogenreId: 5482,
      x: 2650,
      y: 7703
    },{
      NanogenreId: 5483,
      x: 9891,
      y: 9172
    },{
      NanogenreId: 5484,
      x: 10768,
      y: 10369
    },{
      NanogenreId: 5485,
      x: 6740,
      y: 9794
    },{
      NanogenreId: 5486,
      x: 7343,
      y: 8458
    },{
      NanogenreId: 5487,
      x: 9899,
      y: 5216
    },{
      NanogenreId: 5488,
      x: 6842,
      y: 5971
    },{
      NanogenreId: 5489,
      x: 7543,
      y: 5245
    },{
      NanogenreId: 5490,
      x: 9209,
      y: 5474
    },{
      NanogenreId: 5491,
      x: 7262,
      y: 8214
    },{
      NanogenreId: 5492,
      x: 8694,
      y: 8961
    },{
      NanogenreId: 5493,
      x: 9048,
      y: 6259
    },{
      NanogenreId: 5494,
      x: 8844,
      y: 9761
    },{
      NanogenreId: 5495,
      x: 9370,
      y: 4205
    },{
      NanogenreId: 5496,
      x: 8108,
      y: 9295
    },{
      NanogenreId: 5497,
      x: 5919,
      y: 9106
    },{
      NanogenreId: 5498,
      x: 8884,
      y: 4767
    },{
      NanogenreId: 5499,
      x: 9754,
      y: 9767
    },{
      NanogenreId: 5500,
      x: 12005,
      y: 9562
    },{
      NanogenreId: 5501,
      x: 7888,
      y: 7920
    },{
      NanogenreId: 5502,
      x: 12059,
      y: 4576
    },{
      NanogenreId: 5503,
      x: 7389,
      y: 7082
    },{
      NanogenreId: 5504,
      x: 8262,
      y: 8001
    },{
      NanogenreId: 5505,
      x: 7814,
      y: 9350
    },{
      NanogenreId: 5506,
      x: 6020,
      y: 7768
    },{
      NanogenreId: 5507,
      x: 6618,
      y: 6139
    },{
      NanogenreId: 5508,
      x: 12010,
      y: 8319
    },{
      NanogenreId: 5509,
      x: 9767,
      y: 6855
    },{
      NanogenreId: 5510,
      x: 8138,
      y: 8918
    },{
      NanogenreId: 5511,
      x: 9552,
      y: 6430
    },{
      NanogenreId: 5512,
      x: 9792,
      y: 10857
    },{
      NanogenreId: 5513,
      x: 10267,
      y: 10082
    },{
      NanogenreId: 5514,
      x: 9496,
      y: 9957
    },{
      NanogenreId: 5515,
      x: 12375,
      y: 8110
    },{
      NanogenreId: 5516,
      x: 8991,
      y: 8680
    },{
      NanogenreId: 5517,
      x: 9972,
      y: 10053
    },{
      NanogenreId: 5518,
      x: 8411,
      y: 6406
    },{
      NanogenreId: 5519,
      x: 8251,
      y: 8176
    },{
      NanogenreId: 5520,
      x: 9540,
      y: 9365
    },{
      NanogenreId: 5521,
      x: 5358,
      y: 8312
    },{
      NanogenreId: 5522,
      x: 8443,
      y: 6689
    },{
      NanogenreId: 5523,
      x: 7139,
      y: 8799
    },{
      NanogenreId: 5524,
      x: 5839,
      y: 10859
    },{
      NanogenreId: 5525,
      x: 13237,
      y: 3270
    },{
      NanogenreId: 5526,
      x: 8540,
      y: 5145
    },{
      NanogenreId: 5527,
      x: 7375,
      y: 6347
    },{
      NanogenreId: 5528,
      x: 6135,
      y: 3469
    },{
      NanogenreId: 5529,
      x: 7178,
      y: 9182
    },{
      NanogenreId: 5530,
      x: 7011,
      y: 8907
    },{
      NanogenreId: 5531,
      x: 9314,
      y: 5022
    },{
      NanogenreId: 5532,
      x: 6005,
      y: 9664
    },{
      NanogenreId: 5533,
      x: 8277,
      y: 5716
    },{
      NanogenreId: 5534,
      x: 3839,
      y: 9869
    },{
      NanogenreId: 5535,
      x: 7004,
      y: 8527
    },{
      NanogenreId: 5536,
      x: 9222,
      y: 5676
    },{
      NanogenreId: 5537,
      x: 11786,
      y: 8967
    },{
      NanogenreId: 5538,
      x: 9668,
      y: 11415
    },{
      NanogenreId: 5539,
      x: 9235,
      y: 7895
    },{
      NanogenreId: 5540,
      x: 3626,
      y: 10040
    },{
      NanogenreId: 5541,
      x: 9797,
      y: 4442
    },{
      NanogenreId: 5542,
      x: 5462,
      y: 7857
    },{
      NanogenreId: 5543,
      x: 9359,
      y: 1944
    },{
      NanogenreId: 5544,
      x: 10173,
      y: 7501
    },{
      NanogenreId: 5545,
      x: 11427,
      y: 7259
    },{
      NanogenreId: 5546,
      x: 9574,
      y: 4804
    },{
      NanogenreId: 5547,
      x: 7163,
      y: 10929
    },{
      NanogenreId: 5548,
      x: 8161,
      y: 6507
    },{
      NanogenreId: 5549,
      x: 5982,
      y: 6543
    },{
      NanogenreId: 5550,
      x: 11909,
      y: 10413
    },{
      NanogenreId: 5551,
      x: 11139,
      y: 10386
    },{
      NanogenreId: 5552,
      x: 5742,
      y: 4214
    },{
      NanogenreId: 5553,
      x: 3509,
      y: 6288
    },{
      NanogenreId: 5554,
      x: 10248,
      y: 5876
    },{
      NanogenreId: 5555,
      x: 7188,
      y: 10112
    },{
      NanogenreId: 5556,
      x: 4191,
      y: 9999
    },{
      NanogenreId: 5557,
      x: 6940,
      y: 7139
    },{
      NanogenreId: 5558,
      x: 7275,
      y: 10087
    },{
      NanogenreId: 5559,
      x: 6745,
      y: 12748
    },{
      NanogenreId: 5560,
      x: 13004,
      y: 3097
    },{
      NanogenreId: 5561,
      x: 9732,
      y: 4592
    },{
      NanogenreId: 5562,
      x: 10535,
      y: 10090
    },{
      NanogenreId: 5563,
      x: 8597,
      y: 10902
    },{
      NanogenreId: 5564,
      x: 8725,
      y: 1886
    },{
      NanogenreId: 5565,
      x: 6803,
      y: 6423
    },{
      NanogenreId: 5566,
      x: 10761,
      y: 1733
    },{
      NanogenreId: 5567,
      x: 7395,
      y: 4578
    },{
      NanogenreId: 5568,
      x: 10650,
      y: 5568
    },{
      NanogenreId: 5569,
      x: 6930,
      y: 7927
    },{
      NanogenreId: 5570,
      x: 8469,
      y: 10223
    },{
      NanogenreId: 5571,
      x: 12122,
      y: 6016
    },{
      NanogenreId: 5572,
      x: 8300,
      y: 11815
    },{
      NanogenreId: 5573,
      x: 8203,
      y: 8749
    },{
      NanogenreId: 5574,
      x: 10529,
      y: 8318
    },{
      NanogenreId: 5575,
      x: 10646,
      y: 9558
    },{
      NanogenreId: 5576,
      x: 7713,
      y: 11003
    },{
      NanogenreId: 5577,
      x: 11166,
      y: 9610
    },{
      NanogenreId: 5578,
      x: 7073,
      y: 10359
    },{
      NanogenreId: 5579,
      x: 7099,
      y: 6463
    },{
      NanogenreId: 5580,
      x: 7824,
      y: 9664
    },{
      NanogenreId: 5581,
      x: 3958,
      y: 10095
    },{
      NanogenreId: 5582,
      x: 6122,
      y: 7476
    },{
      NanogenreId: 5583,
      x: 7494,
      y: 10340
    },{
      NanogenreId: 5584,
      x: 7405,
      y: 10684
    },{
      NanogenreId: 5585,
      x: 6131,
      y: 9648
    },{
      NanogenreId: 5586,
      x: 4862,
      y: 9399
    },{
      NanogenreId: 5587,
      x: 5400,
      y: 9544
    },{
      NanogenreId: 5588,
      x: 11814,
      y: 10155
    },{
      NanogenreId: 5589,
      x: 6510,
      y: 4406
    },{
      NanogenreId: 5590,
      x: 7485,
      y: 12983
    },{
      NanogenreId: 5591,
      x: 11496,
      y: 7324
    },{
      NanogenreId: 5592,
      x: 8823,
      y: 6916
    },{
      NanogenreId: 5593,
      x: 8024,
      y: 6887
    },{
      NanogenreId: 5594,
      x: 10835,
      y: 6129
    },{
      NanogenreId: 5595,
      x: 11611,
      y: 4109
    },{
      NanogenreId: 5596,
      x: 10340,
      y: 6382
    },{
      NanogenreId: 5597,
      x: 5200,
      y: 6872
    },{
      NanogenreId: 5598,
      x: 9943,
      y: 5202
    },{
      NanogenreId: 5599,
      x: 10073,
      y: 4790
    },{
      NanogenreId: 5600,
      x: 10763,
      y: 10499
    },{
      NanogenreId: 5601,
      x: 6866,
      y: 10432
    },{
      NanogenreId: 5602,
      x: 10558,
      y: 6651
    },{
      NanogenreId: 5603,
      x: 8113,
      y: 4983
    },{
      NanogenreId: 5604,
      x: 10573,
      y: 10581
    },{
      NanogenreId: 5605,
      x: 6467,
      y: 10351
    },{
      NanogenreId: 5606,
      x: 6010,
      y: 5622
    },{
      NanogenreId: 5607,
      x: 10520,
      y: 10230
    },{
      NanogenreId: 5608,
      x: 12634,
      y: 5237
    },{
      NanogenreId: 5609,
      x: 8289,
      y: 9733
    },{
      NanogenreId: 5610,
      x: 10157,
      y: 5675
    },{
      NanogenreId: 5611,
      x: 8298,
      y: 10259
    },{
      NanogenreId: 5612,
      x: 9333,
      y: 8355
    },{
      NanogenreId: 5613,
      x: 8031,
      y: 5750
    },{
      NanogenreId: 5614,
      x: 7658,
      y: 6492
    },{
      NanogenreId: 5615,
      x: 8817,
      y: 9066
    },{
      NanogenreId: 5616,
      x: 9768,
      y: 4364
    },{
      NanogenreId: 5617,
      x: 7197,
      y: 9840
    },{
      NanogenreId: 5618,
      x: 9721,
      y: 8985
    },{
      NanogenreId: 5619,
      x: 7499,
      y: 3272
    },{
      NanogenreId: 5620,
      x: 7453,
      y: 10410
    },{
      NanogenreId: 5621,
      x: 6889,
      y: 6398
    },{
      NanogenreId: 5622,
      x: 5525,
      y: 5455
    },{
      NanogenreId: 5623,
      x: 9879,
      y: 2358
    },{
      NanogenreId: 5624,
      x: 9385,
      y: 5347
    },{
      NanogenreId: 5625,
      x: 9912,
      y: 4720
    },{
      NanogenreId: 5626,
      x: 6308,
      y: 6109
    },{
      NanogenreId: 5627,
      x: 10304,
      y: 9486
    },{
      NanogenreId: 5628,
      x: 6535,
      y: 11450
    },{
      NanogenreId: 5629,
      x: 11543,
      y: 9897
    },{
      NanogenreId: 5630,
      x: 7329,
      y: 9837
    },{
      NanogenreId: 5631,
      x: 9613,
      y: 10192
    },{
      NanogenreId: 5632,
      x: 7486,
      y: 5908
    },{
      NanogenreId: 5633,
      x: 6397,
      y: 11042
    },{
      NanogenreId: 5634,
      x: 8133,
      y: 2635
    },{
      NanogenreId: 5635,
      x: 10275,
      y: 9770
    },{
      NanogenreId: 5636,
      x: 7400,
      y: 5480
    },{
      NanogenreId: 5637,
      x: 7831,
      y: 5091
    },{
      NanogenreId: 5638,
      x: 8508,
      y: 11029
    },{
      NanogenreId: 5639,
      x: 10861,
      y: 10416
    },{
      NanogenreId: 5640,
      x: 5103,
      y: 8441
    },{
      NanogenreId: 5641,
      x: 11979,
      y: 9790
    },{
      NanogenreId: 5642,
      x: 8356,
      y: 5931
    },{
      NanogenreId: 5643,
      x: 8358,
      y: 9854
    },{
      NanogenreId: 5644,
      x: 11061,
      y: 10069
    },{
      NanogenreId: 5645,
      x: 10439,
      y: 10178
    },{
      NanogenreId: 5646,
      x: 11278,
      y: 7152
    },{
      NanogenreId: 5647,
      x: 10896,
      y: 10064
    },{
      NanogenreId: 5648,
      x: 9999,
      y: 9576
    },{
      NanogenreId: 5649,
      x: 9828,
      y: 7190
    },{
      NanogenreId: 5650,
      x: 9508,
      y: 10451
    },{
      NanogenreId: 5651,
      x: 9680,
      y: 4935
    },{
      NanogenreId: 5652,
      x: 8018,
      y: 9527
    },{
      NanogenreId: 5653,
      x: 7443,
      y: 9571
    },{
      NanogenreId: 5654,
      x: 7466,
      y: 8995
    },{
      NanogenreId: 5655,
      x: 8172,
      y: 9680
    },{
      NanogenreId: 5656,
      x: 9603,
      y: 5628
    },{
      NanogenreId: 5657,
      x: 12135,
      y: 5751
    },{
      NanogenreId: 5658,
      x: 9415,
      y: 8608
    },{
      NanogenreId: 5659,
      x: 10830,
      y: 10148
    },{
      NanogenreId: 5660,
      x: 3651,
      y: 8813
    },{
      NanogenreId: 5661,
      x: 7096,
      y: 6848
    },{
      NanogenreId: 5662,
      x: 7926,
      y: 10656
    },{
      NanogenreId: 5663,
      x: 9258,
      y: 9606
    },{
      NanogenreId: 5664,
      x: 7357,
      y: 4799
    },{
      NanogenreId: 5665,
      x: 6957,
      y: 10934
    },{
      NanogenreId: 5666,
      x: 6109,
      y: 6567
    },{
      NanogenreId: 5667,
      x: 9412,
      y: 10013
    },{
      NanogenreId: 5668,
      x: 9714,
      y: 8342
    },{
      NanogenreId: 5669,
      x: 6410,
      y: 7451
    },{
      NanogenreId: 5670,
      x: 5135,
      y: 6119
    },{
      NanogenreId: 5671,
      x: 6278,
      y: 5743
    },{
      NanogenreId: 5672,
      x: 7975,
      y: 12817
    },{
      NanogenreId: 5673,
      x: 7412,
      y: 10545
    },{
      NanogenreId: 5674,
      x: 6785,
      y: 9272
    },{
      NanogenreId: 5675,
      x: 7262,
      y: 9847
    },{
      NanogenreId: 5676,
      x: 10041,
      y: 4906
    },{
      NanogenreId: 5677,
      x: 6059,
      y: 11706
    },{
      NanogenreId: 5678,
      x: 9496,
      y: 4931
    },{
      NanogenreId: 5679,
      x: 7118,
      y: 10945
    },{
      NanogenreId: 5680,
      x: 7071,
      y: 7396
    },{
      NanogenreId: 5681,
      x: 6109,
      y: 6345
    },{
      NanogenreId: 5682,
      x: 7922,
      y: 4304
    },{
      NanogenreId: 5683,
      x: 11714,
      y: 4348
    },{
      NanogenreId: 5684,
      x: 8027,
      y: 10154
    },{
      NanogenreId: 5685,
      x: 9306,
      y: 1986
    },{
      NanogenreId: 5686,
      x: 3683,
      y: 9809
    },{
      NanogenreId: 5687,
      x: 9166,
      y: 5616
    },{
      NanogenreId: 5688,
      x: 8330,
      y: 8992
    },{
      NanogenreId: 5689,
      x: 6595,
      y: 5752
    },{
      NanogenreId: 5690,
      x: 9082,
      y: 4899
    },{
      NanogenreId: 5691,
      x: 10795,
      y: 5429
    },{
      NanogenreId: 5692,
      x: 7076,
      y: 5124
    },{
      NanogenreId: 5693,
      x: 10794,
      y: 10642
    },{
      NanogenreId: 5694,
      x: 9519,
      y: 5641
    },{
      NanogenreId: 5695,
      x: 6914,
      y: 10878
    },{
      NanogenreId: 5696,
      x: 10210,
      y: 4678
    },{
      NanogenreId: 5697,
      x: 9184,
      y: 5655
    },{
      NanogenreId: 5698,
      x: 8543,
      y: 4638
    },{
      NanogenreId: 5699,
      x: 11501,
      y: 9130
    },{
      NanogenreId: 5700,
      x: 9451,
      y: 5171
    },{
      NanogenreId: 5701,
      x: 8865,
      y: 7547
    },{
      NanogenreId: 5702,
      x: 6750,
      y: 4089
    },{
      NanogenreId: 5703,
      x: 8881,
      y: 6045
    },{
      NanogenreId: 5704,
      x: 4558,
      y: 8454
    },{
      NanogenreId: 5705,
      x: 9422,
      y: 4937
    },{
      NanogenreId: 5706,
      x: 11061,
      y: 10307
    },{
      NanogenreId: 5707,
      x: 6130,
      y: 5255
    },{
      NanogenreId: 5708,
      x: 5043,
      y: 10062
    },{
      NanogenreId: 5709,
      x: 6641,
      y: 5809
    },{
      NanogenreId: 5710,
      x: 11412,
      y: 8894
    },{
      NanogenreId: 5711,
      x: 7945,
      y: 8959
    },{
      NanogenreId: 5712,
      x: 10259,
      y: 8115
    },{
      NanogenreId: 5713,
      x: 7951,
      y: 6513
    },{
      NanogenreId: 5714,
      x: 10498,
      y: 10147
    },{
      NanogenreId: 5715,
      x: 7213,
      y: 9712
    },{
      NanogenreId: 5716,
      x: 6969,
      y: 7272
    },{
      NanogenreId: 5717,
      x: 8608,
      y: 9737
    },{
      NanogenreId: 5718,
      x: 9075,
      y: 6844
    },{
      NanogenreId: 5719,
      x: 8254,
      y: 10669
    },{
      NanogenreId: 5720,
      x: 10241,
      y: 10152
    },{
      NanogenreId: 5721,
      x: 11463,
      y: 6614
    },{
      NanogenreId: 5722,
      x: 7596,
      y: 7665
    },{
      NanogenreId: 5723,
      x: 9010,
      y: 6119
    },{
      NanogenreId: 5724,
      x: 6387,
      y: 5642
    },{
      NanogenreId: 5725,
      x: 7414,
      y: 6728
    },{
      NanogenreId: 5726,
      x: 7836,
      y: 9997
    },{
      NanogenreId: 5727,
      x: 5937,
      y: 12304
    },{
      NanogenreId: 5728,
      x: 9039,
      y: 5642
    },{
      NanogenreId: 5729,
      x: 9501,
      y: 5276
    },{
      NanogenreId: 5730,
      x: 5544,
      y: 6606
    },{
      NanogenreId: 5731,
      x: 7444,
      y: 9939
    },{
      NanogenreId: 5732,
      x: 8554,
      y: 4679
    },{
      NanogenreId: 5733,
      x: 9231,
      y: 9072
    },{
      NanogenreId: 5734,
      x: 9152,
      y: 9532
    },{
      NanogenreId: 5735,
      x: 9142,
      y: 8250
    },{
      NanogenreId: 5736,
      x: 9145,
      y: 9635
    },{
      NanogenreId: 5737,
      x: 7855,
      y: 6233
    },{
      NanogenreId: 5738,
      x: 11935,
      y: 6970
    },{
      NanogenreId: 5739,
      x: 13235,
      y: 6117
    },{
      NanogenreId: 5740,
      x: 6454,
      y: 5927
    },{
      NanogenreId: 5741,
      x: 7778,
      y: 5231
    },{
      NanogenreId: 5742,
      x: 10694,
      y: 10845
    },{
      NanogenreId: 5743,
      x: 7738,
      y: 3920
    },{
      NanogenreId: 5744,
      x: 8682,
      y: 11013
    },{
      NanogenreId: 5745,
      x: 8031,
      y: 9882
    },{
      NanogenreId: 5746,
      x: 9540,
      y: 5905
    },{
      NanogenreId: 5747,
      x: 7114,
      y: 5008
    },{
      NanogenreId: 5748,
      x: 7204,
      y: 6048
    },{
      NanogenreId: 5749,
      x: 6511,
      y: 7010
    },{
      NanogenreId: 5750,
      x: 11396,
      y: 4220
    },{
      NanogenreId: 5751,
      x: 7303,
      y: 6053
    },{
      NanogenreId: 5752,
      x: 12755,
      y: 8141
    },{
      NanogenreId: 5753,
      x: 6818,
      y: 6161
    },{
      NanogenreId: 5754,
      x: 7431,
      y: 7775
    },{
      NanogenreId: 5755,
      x: 6981,
      y: 8867
    },{
      NanogenreId: 5756,
      x: 4473,
      y: 5071
    },{
      NanogenreId: 5757,
      x: 8096,
      y: 5499
    },{
      NanogenreId: 5758,
      x: 7713,
      y: 9820
    },{
      NanogenreId: 5759,
      x: 9367,
      y: 7489
    },{
      NanogenreId: 5760,
      x: 10647,
      y: 9653
    },{
      NanogenreId: 5761,
      x: 8450,
      y: 3883
    },{
      NanogenreId: 5762,
      x: 8465,
      y: 5200
    },{
      NanogenreId: 5763,
      x: 11389,
      y: 10220
    },{
      NanogenreId: 5764,
      x: 7044,
      y: 12273
    },{
      NanogenreId: 5765,
      x: 12263,
      y: 9208
    },{
      NanogenreId: 5766,
      x: 5393,
      y: 6174
    },{
      NanogenreId: 5767,
      x: 6556,
      y: 5140
    },{
      NanogenreId: 5768,
      x: 7416,
      y: 7003
    },{
      NanogenreId: 5769,
      x: 7023,
      y: 7731
    },{
      NanogenreId: 5770,
      x: 10357,
      y: 9684
    },{
      NanogenreId: 5771,
      x: 8667,
      y: 9556
    },{
      NanogenreId: 5772,
      x: 6665,
      y: 7624
    },{
      NanogenreId: 5773,
      x: 10490,
      y: 9838
    },{
      NanogenreId: 5774,
      x: 8679,
      y: 5830
    },{
      NanogenreId: 5775,
      x: 3127,
      y: 9913
    },{
      NanogenreId: 5776,
      x: 3709,
      y: 7649
    },{
      NanogenreId: 5777,
      x: 9395,
      y: 5290
    },{
      NanogenreId: 5778,
      x: 8588,
      y: 9104
    },{
      NanogenreId: 5779,
      x: 5223,
      y: 9200
    },{
      NanogenreId: 5780,
      x: 8696,
      y: 4770
    },{
      NanogenreId: 5781,
      x: 11008,
      y: 10331
    },{
      NanogenreId: 5782,
      x: 7348,
      y: 8790
    },{
      NanogenreId: 5783,
      x: 7711,
      y: 11279
    },{
      NanogenreId: 5784,
      x: 3935,
      y: 10226
    },{
      NanogenreId: 5785,
      x: 7983,
      y: 7900
    },{
      NanogenreId: 5786,
      x: 12328,
      y: 5949
    },{
      NanogenreId: 5787,
      x: 9701,
      y: 10648
    },{
      NanogenreId: 5788,
      x: 6551,
      y: 8325
    },{
      NanogenreId: 5789,
      x: 8389,
      y: 6133
    },{
      NanogenreId: 5790,
      x: 6131,
      y: 6747
    },{
      NanogenreId: 5791,
      x: 3320,
      y: 5999
    },{
      NanogenreId: 5792,
      x: 4142,
      y: 9607
    },{
      NanogenreId: 5793,
      x: 9642,
      y: 9719
    },{
      NanogenreId: 5794,
      x: 10072,
      y: 5704
    },{
      NanogenreId: 5795,
      x: 6365,
      y: 10816
    },{
      NanogenreId: 5796,
      x: 9263,
      y: 7144
    },{
      NanogenreId: 5797,
      x: 8701,
      y: 5959
    },{
      NanogenreId: 5798,
      x: 9999,
      y: 11493
    },{
      NanogenreId: 5799,
      x: 9578,
      y: 6316
    },{
      NanogenreId: 5800,
      x: 9671,
      y: 7538
    },{
      NanogenreId: 5801,
      x: 7139,
      y: 7477
    },{
      NanogenreId: 5802,
      x: 8251,
      y: 5585
    },{
      NanogenreId: 5803,
      x: 7496,
      y: 5148
    },{
      NanogenreId: 5804,
      x: 7584,
      y: 10718
    },{
      NanogenreId: 5805,
      x: 6779,
      y: 6848
    },{
      NanogenreId: 5806,
      x: 12214,
      y: 8621
    },{
      NanogenreId: 5807,
      x: 8042,
      y: 6078
    },{
      NanogenreId: 5808,
      x: 9851,
      y: 4664
    },{
      NanogenreId: 5809,
      x: 9721,
      y: 5355
    },{
      NanogenreId: 5810,
      x: 10203,
      y: 9224
    },{
      NanogenreId: 5811,
      x: 5416,
      y: 7045
    },{
      NanogenreId: 5812,
      x: 4873,
      y: 6238
    },{
      NanogenreId: 5813,
      x: 6083,
      y: 7345
    },{
      NanogenreId: 5814,
      x: 7209,
      y: 6556
    },{
      NanogenreId: 5815,
      x: 5648,
      y: 12647
    },{
      NanogenreId: 5816,
      x: 5458,
      y: 8796
    },{
      NanogenreId: 5817,
      x: 7921,
      y: 10333
    },{
      NanogenreId: 5818,
      x: 12130,
      y: 9272
    },{
      NanogenreId: 5819,
      x: 8944,
      y: 9187
    },{
      NanogenreId: 5820,
      x: 8898,
      y: 10686
    },{
      NanogenreId: 5821,
      x: 9117,
      y: 9906
    },{
      NanogenreId: 5822,
      x: 8886,
      y: 6535
    },{
      NanogenreId: 5823,
      x: 7426,
      y: 6144
    },{
      NanogenreId: 5824,
      x: 7771,
      y: 9933
    },{
      NanogenreId: 5825,
      x: 6780,
      y: 5734
    },{
      NanogenreId: 5826,
      x: 9820,
      y: 10271
    },{
      NanogenreId: 5827,
      x: 7448,
      y: 9414
    },{
      NanogenreId: 5828,
      x: 9959,
      y: 9239
    },{
      NanogenreId: 5829,
      x: 7291,
      y: 4341
    },{
      NanogenreId: 5830,
      x: 10958,
      y: 10728
    },{
      NanogenreId: 5831,
      x: 10044,
      y: 7039
    },{
      NanogenreId: 5832,
      x: 10849,
      y: 5570
    },{
      NanogenreId: 5833,
      x: 6173,
      y: 9479
    },{
      NanogenreId: 5834,
      x: 8802,
      y: 5984
    },{
      NanogenreId: 5835,
      x: 9814,
      y: 4946
    },{
      NanogenreId: 5836,
      x: 7206,
      y: 5793
    },{
      NanogenreId: 5837,
      x: 9682,
      y: 4379
    },{
      NanogenreId: 5838,
      x: 9529,
      y: 5109
    },{
      NanogenreId: 5839,
      x: 7743,
      y: 9501
    },{
      NanogenreId: 5840,
      x: 11388,
      y: 9342
    },{
      NanogenreId: 5841,
      x: 7983,
      y: 10554
    },{
      NanogenreId: 5842,
      x: 7521,
      y: 11472
    },{
      NanogenreId: 5843,
      x: 9242,
      y: 6061
    },{
      NanogenreId: 5844,
      x: 4799,
      y: 11201
    },{
      NanogenreId: 5845,
      x: 10645,
      y: 10321
    },{
      NanogenreId: 5846,
      x: 10549,
      y: 9549
    },{
      NanogenreId: 5847,
      x: 9582,
      y: 6919
    },{
      NanogenreId: 5848,
      x: 10519,
      y: 10939
    },{
      NanogenreId: 5849,
      x: 7185,
      y: 2982
    },{
      NanogenreId: 5850,
      x: 8348,
      y: 6364
    },{
      NanogenreId: 5851,
      x: 8561,
      y: 4327
    },{
      NanogenreId: 5852,
      x: 8674,
      y: 9852
    },{
      NanogenreId: 5853,
      x: 5344,
      y: 11443
    },{
      NanogenreId: 5854,
      x: 10589,
      y: 8846
    },{
      NanogenreId: 5855,
      x: 4834,
      y: 9628
    },{
      NanogenreId: 5856,
      x: 9904,
      y: 5277
    },{
      NanogenreId: 5857,
      x: 8704,
      y: 6420
    },{
      NanogenreId: 5858,
      x: 9782,
      y: 4621
    },{
      NanogenreId: 5859,
      x: 7059,
      y: 6447
    },{
      NanogenreId: 5860,
      x: 9639,
      y: 4635
    },{
      NanogenreId: 5861,
      x: 6171,
      y: 10836
    },{
      NanogenreId: 5862,
      x: 9284,
      y: 9873
    },{
      NanogenreId: 5863,
      x: 11517,
      y: 10377
    },{
      NanogenreId: 5864,
      x: 10587,
      y: 10676
    },{
      NanogenreId: 5865,
      x: 11595,
      y: 9943
    },{
      NanogenreId: 5866,
      x: 6247,
      y: 3836
    },{
      NanogenreId: 5867,
      x: 10284,
      y: 11181
    },{
      NanogenreId: 5868,
      x: 7618,
      y: 4913
    },{
      NanogenreId: 5869,
      x: 7195,
      y: 8470
    },{
      NanogenreId: 5870,
      x: 9565,
      y: 4469
    },{
      NanogenreId: 5871,
      x: 6944,
      y: 7820
    },{
      NanogenreId: 5872,
      x: 6506,
      y: 4951
    },{
      NanogenreId: 5873,
      x: 9323,
      y: 6341
    },{
      NanogenreId: 5874,
      x: 7232,
      y: 7116
    },{
      NanogenreId: 5875,
      x: 8516,
      y: 11133
    },{
      NanogenreId: 5876,
      x: 9926,
      y: 9947
    },{
      NanogenreId: 5877,
      x: 8284,
      y: 4695
    },{
      NanogenreId: 5878,
      x: 6636,
      y: 4990
    },{
      NanogenreId: 5879,
      x: 6308,
      y: 6030
    },{
      NanogenreId: 5880,
      x: 10815,
      y: 2018
    },{
      NanogenreId: 5881,
      x: 4219,
      y: 7208
    },{
      NanogenreId: 5882,
      x: 9584,
      y: 5876
    },{
      NanogenreId: 5883,
      x: 6651,
      y: 5765
    },{
      NanogenreId: 5884,
      x: 10641,
      y: 10665
    },{
      NanogenreId: 5885,
      x: 6655,
      y: 8550
    },{
      NanogenreId: 5886,
      x: 9093,
      y: 8733
    },{
      NanogenreId: 5887,
      x: 6493,
      y: 6374
    },{
      NanogenreId: 5888,
      x: 7418,
      y: 10033
    },{
      NanogenreId: 5889,
      x: 7650,
      y: 7183
    },{
      NanogenreId: 5890,
      x: 10634,
      y: 7128
    },{
      NanogenreId: 5891,
      x: 8293,
      y: 6102
    },{
      NanogenreId: 5892,
      x: 6565,
      y: 10452
    },{
      NanogenreId: 5893,
      x: 6527,
      y: 6599
    },{
      NanogenreId: 5894,
      x: 6724,
      y: 11000
    },{
      NanogenreId: 5895,
      x: 6678,
      y: 7248
    },{
      NanogenreId: 5896,
      x: 4735,
      y: 5933
    },{
      NanogenreId: 5897,
      x: 6154,
      y: 9563
    },{
      NanogenreId: 5898,
      x: 9592,
      y: 5161
    },{
      NanogenreId: 5899,
      x: 7362,
      y: 11190
    },{
      NanogenreId: 5900,
      x: 9318,
      y: 5075
    },{
      NanogenreId: 5901,
      x: 7475,
      y: 6541
    },{
      NanogenreId: 5902,
      x: 7282,
      y: 9452
    },{
      NanogenreId: 5903,
      x: 7384,
      y: 9713
    },{
      NanogenreId: 5904,
      x: 5230,
      y: 11000
    },{
      NanogenreId: 5905,
      x: 5105,
      y: 9484
    },{
      NanogenreId: 5906,
      x: 8983,
      y: 10148
    },{
      NanogenreId: 5907,
      x: 9368,
      y: 5195
    },{
      NanogenreId: 5908,
      x: 11843,
      y: 9061
    },{
      NanogenreId: 5909,
      x: 4478,
      y: 9143
    },{
      NanogenreId: 5910,
      x: 8171,
      y: 9431
    },{
      NanogenreId: 5911,
      x: 7657,
      y: 9864
    },{
      NanogenreId: 5912,
      x: 9627,
      y: 10032
    },{
      NanogenreId: 5913,
      x: 9426,
      y: 8402
    },{
      NanogenreId: 5914,
      x: 3799,
      y: 8717
    },{
      NanogenreId: 5915,
      x: 11468,
      y: 7726
    },{
      NanogenreId: 5916,
      x: 7953,
      y: 4640
    },{
      NanogenreId: 5917,
      x: 10576,
      y: 9021
    },{
      NanogenreId: 5918,
      x: 9708,
      y: 4531
    },{
      NanogenreId: 5919,
      x: 10136,
      y: 10611
    },{
      NanogenreId: 5920,
      x: 9156,
      y: 4516
    },{
      NanogenreId: 5921,
      x: 13169,
      y: 10428
    },{
      NanogenreId: 5922,
      x: 4234,
      y: 6013
    },{
      NanogenreId: 5923,
      x: 8037,
      y: 8942
    },{
      NanogenreId: 5924,
      x: 11097,
      y: 10040
    },{
      NanogenreId: 5925,
      x: 5559,
      y: 5700
    },{
      NanogenreId: 5926,
      x: 9319,
      y: 2566
    },{
      NanogenreId: 5927,
      x: 6824,
      y: 7276
    },{
      NanogenreId: 5928,
      x: 6576,
      y: 6732
    },{
      NanogenreId: 5929,
      x: 8512,
      y: 5637
    },{
      NanogenreId: 5930,
      x: 7645,
      y: 5176
    },{
      NanogenreId: 5931,
      x: 6804,
      y: 5876
    },{
      NanogenreId: 5932,
      x: 10164,
      y: 9783
    },{
      NanogenreId: 5933,
      x: 10263,
      y: 10550
    },{
      NanogenreId: 5934,
      x: 9281,
      y: 5722
    },{
      NanogenreId: 5935,
      x: 7169,
      y: 9587
    },{
      NanogenreId: 5936,
      x: 7767,
      y: 7103
    },{
      NanogenreId: 5937,
      x: 6475,
      y: 9513
    },{
      NanogenreId: 5938,
      x: 10731,
      y: 9708
    },{
      NanogenreId: 5939,
      x: 3441,
      y: 8236
    },{
      NanogenreId: 5940,
      x: 7303,
      y: 10933
    },{
      NanogenreId: 5941,
      x: 5336,
      y: 6663
    },{
      NanogenreId: 5942,
      x: 3957,
      y: 8786
    },{
      NanogenreId: 5943,
      x: 9373,
      y: 5994
    },{
      NanogenreId: 5944,
      x: 6381,
      y: 10469
    },{
      NanogenreId: 5945,
      x: 8650,
      y: 5446
    },{
      NanogenreId: 5946,
      x: 9883,
      y: 5021
    },{
      NanogenreId: 5947,
      x: 8112,
      y: 9473
    },{
      NanogenreId: 5948,
      x: 9709,
      y: 5622
    },{
      NanogenreId: 5949,
      x: 10224,
      y: 7963
    },{
      NanogenreId: 5950,
      x: 8610,
      y: 7501
    },{
      NanogenreId: 5951,
      x: 7941,
      y: 10477
    },{
      NanogenreId: 5952,
      x: 7995,
      y: 6169
    },{
      NanogenreId: 5953,
      x: 8876,
      y: 6285
    },{
      NanogenreId: 5954,
      x: 3633,
      y: 9271
    },{
      NanogenreId: 5955,
      x: 10613,
      y: 10478
    },{
      NanogenreId: 5956,
      x: 8071,
      y: 5037
    },{
      NanogenreId: 5957,
      x: 5495,
      y: 9665
    },{
      NanogenreId: 5958,
      x: 9648,
      y: 4876
    },{
      NanogenreId: 5959,
      x: 11126,
      y: 10481
    },{
      NanogenreId: 5960,
      x: 7180,
      y: 10436
    },{
      NanogenreId: 5961,
      x: 9745,
      y: 5915
    },{
      NanogenreId: 5962,
      x: 7743,
      y: 5812
    },{
      NanogenreId: 5963,
      x: 9848,
      y: 4522
    },{
      NanogenreId: 5964,
      x: 5155,
      y: 7167
    },{
      NanogenreId: 5965,
      x: 7308,
      y: 4481
    },{
      NanogenreId: 5966,
      x: 6115,
      y: 9897
    },{
      NanogenreId: 5967,
      x: 8737,
      y: 9978
    },{
      NanogenreId: 5968,
      x: 9128,
      y: 5005
    },{
      NanogenreId: 5969,
      x: 7978,
      y: 5512
    },{
      NanogenreId: 5970,
      x: 9709,
      y: 5685
    },{
      NanogenreId: 5971,
      x: 9254,
      y: 5852
    },{
      NanogenreId: 5972,
      x: 10592,
      y: 9634
    },{
      NanogenreId: 5973,
      x: 9623,
      y: 5584
    },{
      NanogenreId: 5974,
      x: 8555,
      y: 5321
    },{
      NanogenreId: 5975,
      x: 10738,
      y: 10140
    },{
      NanogenreId: 5976,
      x: 7444,
      y: 6505
    },{
      NanogenreId: 5977,
      x: 6842,
      y: 6965
    },{
      NanogenreId: 5978,
      x: 6931,
      y: 6672
    },{
      NanogenreId: 5979,
      x: 6332,
      y: 7706
    },{
      NanogenreId: 5980,
      x: 9694,
      y: 5032
    },{
      NanogenreId: 5981,
      x: 8975,
      y: 4755
    },{
      NanogenreId: 5982,
      x: 9847,
      y: 4527
    },{
      NanogenreId: 5983,
      x: 8541,
      y: 7350
    },{
      NanogenreId: 5984,
      x: 8770,
      y: 8691
    },{
      NanogenreId: 5985,
      x: 6931,
      y: 6924
    },{
      NanogenreId: 5986,
      x: 9889,
      y: 10359
    },{
      NanogenreId: 5987,
      x: 7182,
      y: 8229
    },{
      NanogenreId: 5988,
      x: 10272,
      y: 9918
    },{
      NanogenreId: 5989,
      x: 7213,
      y: 9466
    },{
      NanogenreId: 5990,
      x: 10037,
      y: 5051
    },{
      NanogenreId: 5991,
      x: 6482,
      y: 7395
    },{
      NanogenreId: 5992,
      x: 11250,
      y: 9791
    },{
      NanogenreId: 5993,
      x: 7133,
      y: 11140
    },{
      NanogenreId: 5994,
      x: 9969,
      y: 4906
    },{
      NanogenreId: 5995,
      x: 4736,
      y: 11774
    },{
      NanogenreId: 5996,
      x: 6566,
      y: 6391
    },{
      NanogenreId: 5997,
      x: 6613,
      y: 8690
    },{
      NanogenreId: 5998,
      x: 8931,
      y: 9252
    },{
      NanogenreId: 5999,
      x: 7951,
      y: 5612
    },{
      NanogenreId: 6000,
      x: 8726,
      y: 5569
    },{
      NanogenreId: 6001,
      x: 9365,
      y: 7861
    },{
      NanogenreId: 6002,
      x: 7549,
      y: 10774
    },{
      NanogenreId: 6003,
      x: 9088,
      y: 5825
    },{
      NanogenreId: 6004,
      x: 9346,
      y: 9020
    },{
      NanogenreId: 6005,
      x: 9440,
      y: 10604
    },{
      NanogenreId: 6006,
      x: 8814,
      y: 6051
    },{
      NanogenreId: 6007,
      x: 8118,
      y: 8987
    },{
      NanogenreId: 6008,
      x: 9166,
      y: 8738
    },{
      NanogenreId: 6009,
      x: 8469,
      y: 4505
    },{
      NanogenreId: 6010,
      x: 9581,
      y: 6810
    },{
      NanogenreId: 6011,
      x: 9036,
      y: 4572
    },{
      NanogenreId: 6012,
      x: 8156,
      y: 10710
    },{
      NanogenreId: 6013,
      x: 9804,
      y: 5467
    },{
      NanogenreId: 6014,
      x: 4570,
      y: 9591
    },{
      NanogenreId: 6015,
      x: 10934,
      y: 9947
    },{
      NanogenreId: 6016,
      x: 7260,
      y: 6605
    },{
      NanogenreId: 6017,
      x: 9561,
      y: 5088
    },{
      NanogenreId: 6018,
      x: 6657,
      y: 7315
    },{
      NanogenreId: 6019,
      x: 6635,
      y: 4125
    },{
      NanogenreId: 6020,
      x: 5777,
      y: 6906
    },{
      NanogenreId: 6021,
      x: 7684,
      y: 10522
    },{
      NanogenreId: 6022,
      x: 9176,
      y: 6210
    },{
      NanogenreId: 6023,
      x: 10338,
      y: 9104
    },{
      NanogenreId: 6024,
      x: 12524,
      y: 9003
    },{
      NanogenreId: 6025,
      x: 9062,
      y: 5902
    },{
      NanogenreId: 6026,
      x: 5998,
      y: 5844
    },{
      NanogenreId: 6027,
      x: 10101,
      y: 5427
    },{
      NanogenreId: 6028,
      x: 4369,
      y: 10095
    },{
      NanogenreId: 6029,
      x: 7104,
      y: 10638
    },{
      NanogenreId: 6030,
      x: 8198,
      y: 9351
    },{
      NanogenreId: 6031,
      x: 6814,
      y: 6026
    },{
      NanogenreId: 6032,
      x: 9373,
      y: 4771
    },{
      NanogenreId: 6033,
      x: 9813,
      y: 10012
    },{
      NanogenreId: 6034,
      x: 6783,
      y: 5659
    },{
      NanogenreId: 6035,
      x: 6247,
      y: 9237
    },{
      NanogenreId: 6036,
      x: 9140,
      y: 5938
    },{
      NanogenreId: 6037,
      x: 10648,
      y: 9914
    },{
      NanogenreId: 6038,
      x: 8362,
      y: 9478
    },{
      NanogenreId: 6039,
      x: 3985,
      y: 9316
    },{
      NanogenreId: 6040,
      x: 5931,
      y: 6325
    },{
      NanogenreId: 6041,
      x: 9862,
      y: 5321
    },{
      NanogenreId: 6042,
      x: 10451,
      y: 10437
    },{
      NanogenreId: 6043,
      x: 6217,
      y: 5953
    },{
      NanogenreId: 6044,
      x: 6029,
      y: 10173
    },{
      NanogenreId: 6045,
      x: 7522,
      y: 8302
    },{
      NanogenreId: 6046,
      x: 9260,
      y: 8035
    },{
      NanogenreId: 6047,
      x: 7315,
      y: 7957
    },{
      NanogenreId: 6048,
      x: 9933,
      y: 10205
    },{
      NanogenreId: 6049,
      x: 11170,
      y: 10734
    },{
      NanogenreId: 6050,
      x: 7540,
      y: 7646
    },{
      NanogenreId: 6051,
      x: 7449,
      y: 7435
    },{
      NanogenreId: 6052,
      x: 4883,
      y: 8851
    },{
      NanogenreId: 6053,
      x: 4977,
      y: 9677
    },{
      NanogenreId: 6054,
      x: 8409,
      y: 6986
    },{
      NanogenreId: 6055,
      x: 9753,
      y: 8949
    },{
      NanogenreId: 6056,
      x: 8167,
      y: 11974
    },{
      NanogenreId: 6057,
      x: 7146,
      y: 5489
    },{
      NanogenreId: 6058,
      x: 8739,
      y: 8531
    },{
      NanogenreId: 6059,
      x: 6271,
      y: 5308
    },{
      NanogenreId: 6060,
      x: 8534,
      y: 10085
    },{
      NanogenreId: 6061,
      x: 9005,
      y: 6186
    },{
      NanogenreId: 6062,
      x: 12575,
      y: 8923
    },{
      NanogenreId: 6063,
      x: 4476,
      y: 7875
    },{
      NanogenreId: 6064,
      x: 7308,
      y: 7405
    },{
      NanogenreId: 6065,
      x: 10731,
      y: 10481
    },{
      NanogenreId: 6066,
      x: 9870,
      y: 8025
    },{
      NanogenreId: 6067,
      x: 6224,
      y: 7175
    },{
      NanogenreId: 6068,
      x: 9574,
      y: 5219
    },{
      NanogenreId: 6069,
      x: 12232,
      y: 10000
    },{
      NanogenreId: 6070,
      x: 7389,
      y: 7167
    },{
      NanogenreId: 6071,
      x: 7540,
      y: 6007
    },{
      NanogenreId: 6072,
      x: 5651,
      y: 6298
    },{
      NanogenreId: 6073,
      x: 9603,
      y: 10137
    },{
      NanogenreId: 6074,
      x: 7561,
      y: 5922
    },{
      NanogenreId: 6075,
      x: 3212,
      y: 8549
    },{
      NanogenreId: 6076,
      x: 5902,
      y: 7602
    },{
      NanogenreId: 6077,
      x: 7479,
      y: 7748
    },{
      NanogenreId: 6078,
      x: 8046,
      y: 7452
    },{
      NanogenreId: 6079,
      x: 5211,
      y: 8952
    },{
      NanogenreId: 6080,
      x: 6586,
      y: 8839
    },{
      NanogenreId: 6081,
      x: 7591,
      y: 10066
    },{
      NanogenreId: 6082,
      x: 4792,
      y: 8207
    },{
      NanogenreId: 6083,
      x: 3783,
      y: 9171
    },{
      NanogenreId: 6084,
      x: 8614,
      y: 4890
    },{
      NanogenreId: 6085,
      x: 9715,
      y: 4814
    },{
      NanogenreId: 6086,
      x: 8314,
      y: 9305
    },{
      NanogenreId: 6087,
      x: 8963,
      y: 6264
    },{
      NanogenreId: 6088,
      x: 4291,
      y: 8966
    },{
      NanogenreId: 6089,
      x: 8867,
      y: 10585
    },{
      NanogenreId: 6090,
      x: 7360,
      y: 7893
    },{
      NanogenreId: 6091,
      x: 9815,
      y: 4898
    },{
      NanogenreId: 6092,
      x: 8389,
      y: 9134
    },{
      NanogenreId: 6093,
      x: 9731,
      y: 3576
    },{
      NanogenreId: 6094,
      x: 6883,
      y: 8312
    },{
      NanogenreId: 6095,
      x: 9536,
      y: 4711
    },{
      NanogenreId: 6096,
      x: 12600,
      y: 3763
    },{
      NanogenreId: 6097,
      x: 10612,
      y: 10078
    },{
      NanogenreId: 6098,
      x: 9770,
      y: 6508
    },{
      NanogenreId: 6099,
      x: 6385,
      y: 6962
    },{
      NanogenreId: 6100,
      x: 9270,
      y: 4811
    },{
      NanogenreId: 6101,
      x: 7426,
      y: 8723
    },{
      NanogenreId: 6102,
      x: 7437,
      y: 10771
    },{
      NanogenreId: 6103,
      x: 9516,
      y: 7268
    },{
      NanogenreId: 6104,
      x: 4612,
      y: 9083
    },{
      NanogenreId: 6105,
      x: 9787,
      y: 7027
    },{
      NanogenreId: 6106,
      x: 6375,
      y: 6375
    },{
      NanogenreId: 6107,
      x: 8323,
      y: 7440
    },{
      NanogenreId: 6108,
      x: 10592,
      y: 10177
    },{
      NanogenreId: 6109,
      x: 9424,
      y: 11052
    },{
      NanogenreId: 6110,
      x: 5708,
      y: 6454
    },{
      NanogenreId: 6111,
      x: 10992,
      y: 9255
    },{
      NanogenreId: 6112,
      x: 9288,
      y: 5090
    },{
      NanogenreId: 6113,
      x: 8663,
      y: 5741
    },{
      NanogenreId: 6114,
      x: 7248,
      y: 10012
    },{
      NanogenreId: 6115,
      x: 9609,
      y: 4500
    },{
      NanogenreId: 6116,
      x: 8705,
      y: 5749
    },{
      NanogenreId: 6117,
      x: 9024,
      y: 7957
    },{
      NanogenreId: 6118,
      x: 9331,
      y: 10350
    },{
      NanogenreId: 6119,
      x: 6833,
      y: 8446
    },{
      NanogenreId: 6120,
      x: 9738,
      y: 5498
    },{
      NanogenreId: 6121,
      x: 8373,
      y: 6731
    },{
      NanogenreId: 6122,
      x: 8734,
      y: 4987
    },{
      NanogenreId: 6123,
      x: 9753,
      y: 4972
    },{
      NanogenreId: 6124,
      x: 8079,
      y: 8898
    },{
      NanogenreId: 6125,
      x: 7359,
      y: 7386
    },{
      NanogenreId: 6126,
      x: 11937,
      y: 5341
    },{
      NanogenreId: 6127,
      x: 9279,
      y: 4846
    },{
      NanogenreId: 6128,
      x: 9025,
      y: 5016
    },{
      NanogenreId: 6129,
      x: 10387,
      y: 10140
    },{
      NanogenreId: 6130,
      x: 8214,
      y: 10489
    },{
      NanogenreId: 6131,
      x: 7378,
      y: 10967
    },{
      NanogenreId: 6132,
      x: 8639,
      y: 5599
    },{
      NanogenreId: 6133,
      x: 9454,
      y: 4949
    },{
      NanogenreId: 6134,
      x: 6992,
      y: 10363
    },{
      NanogenreId: 6135,
      x: 9655,
      y: 4714
    },{
      NanogenreId: 6136,
      x: 6429,
      y: 8317
    },{
      NanogenreId: 6137,
      x: 6859,
      y: 7940
    },{
      NanogenreId: 6138,
      x: 9953,
      y: 5508
    },{
      NanogenreId: 6139,
      x: 9063,
      y: 4739
    },{
      NanogenreId: 6140,
      x: 10996,
      y: 10080
    },{
      NanogenreId: 6141,
      x: 8553,
      y: 6537
    },{
      NanogenreId: 6142,
      x: 6023,
      y: 6745
    },{
      NanogenreId: 6143,
      x: 10397,
      y: 9375
    },{
      NanogenreId: 6144,
      x: 9202,
      y: 5175
    },{
      NanogenreId: 6145,
      x: 8767,
      y: 5758
    },{
      NanogenreId: 6146,
      x: 4707,
      y: 9700
    },{
      NanogenreId: 6147,
      x: 6092,
      y: 8950
    },{
      NanogenreId: 6148,
      x: 10486,
      y: 10594
    },{
      NanogenreId: 6149,
      x: 9071,
      y: 8350
    },{
      NanogenreId: 6150,
      x: 9658,
      y: 5465
    },{
      NanogenreId: 6151,
      x: 5909,
      y: 6690
    },{
      NanogenreId: 6152,
      x: 5633,
      y: 8990
    },{
      NanogenreId: 6153,
      x: 6767,
      y: 7895
    },{
      NanogenreId: 6154,
      x: 7676,
      y: 2567
    },{
      NanogenreId: 6155,
      x: 9534,
      y: 5804
    },{
      NanogenreId: 6156,
      x: 7545,
      y: 7987
    },{
      NanogenreId: 6157,
      x: 7912,
      y: 9867
    },{
      NanogenreId: 6158,
      x: 7932,
      y: 5353
    },{
      NanogenreId: 6159,
      x: 8862,
      y: 5508
    },{
      NanogenreId: 6160,
      x: 6836,
      y: 7358
    },{
      NanogenreId: 6161,
      x: 7596,
      y: 6119
    },{
      NanogenreId: 6162,
      x: 10969,
      y: 9836
    },{
      NanogenreId: 6163,
      x: 7388,
      y: 7274
    },{
      NanogenreId: 6164,
      x: 7467,
      y: 6936
    },{
      NanogenreId: 6165,
      x: 9987,
      y: 8817
    },{
      NanogenreId: 6166,
      x: 6293,
      y: 7488
    },{
      NanogenreId: 6167,
      x: 7894,
      y: 5979
    },{
      NanogenreId: 6168,
      x: 10024,
      y: 5372
    },{
      NanogenreId: 6169,
      x: 10446,
      y: 9901
    },{
      NanogenreId: 6170,
      x: 8925,
      y: 8652
    },{
      NanogenreId: 6171,
      x: 6889,
      y: 7163
    },{
      NanogenreId: 6172,
      x: 5975,
      y: 9203
    },{
      NanogenreId: 6173,
      x: 8006,
      y: 9737
    },{
      NanogenreId: 6174,
      x: 7349,
      y: 5208
    },{
      NanogenreId: 6175,
      x: 9226,
      y: 6002
    },{
      NanogenreId: 6176,
      x: 9535,
      y: 10331
    },{
      NanogenreId: 6177,
      x: 8661,
      y: 10380
    },{
      NanogenreId: 6178,
      x: 9733,
      y: 9949
    },{
      NanogenreId: 6179,
      x: 7694,
      y: 5367
    },{
      NanogenreId: 6180,
      x: 6982,
      y: 5284
    },{
      NanogenreId: 6181,
      x: 9684,
      y: 6809
    },{
      NanogenreId: 6182,
      x: 8168,
      y: 5754
    },{
      NanogenreId: 6183,
      x: 11263,
      y: 8448
    },{
      NanogenreId: 6184,
      x: 9104,
      y: 7897
    },{
      NanogenreId: 6185,
      x: 9168,
      y: 8404
    },{
      NanogenreId: 6186,
      x: 9405,
      y: 5821
    },{
      NanogenreId: 6187,
      x: 8919,
      y: 5664
    },{
      NanogenreId: 6188,
      x: 10308,
      y: 9324
    },{
      NanogenreId: 6189,
      x: 7151,
      y: 7414
    },{
      NanogenreId: 6190,
      x: 10119,
      y: 9133
    },{
      NanogenreId: 6191,
      x: 7820,
      y: 8787
    },{
      NanogenreId: 6192,
      x: 6938,
      y: 10635
    },{
      NanogenreId: 6193,
      x: 9757,
      y: 4615
    },{
      NanogenreId: 6194,
      x: 6654,
      y: 9363
    },{
      NanogenreId: 6195,
      x: 8453,
      y: 8364
    },{
      NanogenreId: 6196,
      x: 5329,
      y: 6564
    },{
      NanogenreId: 6197,
      x: 7205,
      y: 5909
    },{
      NanogenreId: 6198,
      x: 6169,
      y: 9134
    },{
      NanogenreId: 6199,
      x: 10056,
      y: 8585
    },{
      NanogenreId: 6200,
      x: 6383,
      y: 6719
    },{
      NanogenreId: 6201,
      x: 10306,
      y: 9547
    },{
      NanogenreId: 6202,
      x: 5826,
      y: 12350
    },{
      NanogenreId: 6203,
      x: 7378,
      y: 5969
    },{
      NanogenreId: 6204,
      x: 10789,
      y: 8888
    },{
      NanogenreId: 6205,
      x: 8141,
      y: 5650
    },{
      NanogenreId: 6206,
      x: 8272,
      y: 9601
    },{
      NanogenreId: 6207,
      x: 12528,
      y: 7985
    },{
      NanogenreId: 6208,
      x: 9694,
      y: 2203
    },{
      NanogenreId: 6209,
      x: 9632,
      y: 9806
    },{
      NanogenreId: 6210,
      x: 8944,
      y: 5051
    },{
      NanogenreId: 6211,
      x: 5360,
      y: 9480
    },{
      NanogenreId: 6212,
      x: 10820,
      y: 10052
    },{
      NanogenreId: 6213,
      x: 8802,
      y: 7589
    },{
      NanogenreId: 6214,
      x: 8333,
      y: 10009
    },{
      NanogenreId: 6215,
      x: 7815,
      y: 7208
    },{
      NanogenreId: 6216,
      x: 9553,
      y: 6617
    },{
      NanogenreId: 6217,
      x: 8549,
      y: 7591
    },{
      NanogenreId: 6218,
      x: 7033,
      y: 6690
    },{
      NanogenreId: 6219,
      x: 8889,
      y: 10077
    },{
      NanogenreId: 6220,
      x: 9401,
      y: 6126
    },{
      NanogenreId: 6221,
      x: 7031,
      y: 7515
    },{
      NanogenreId: 6222,
      x: 9070,
      y: 10647
    },{
      NanogenreId: 6223,
      x: 10744,
      y: 9972
    },{
      NanogenreId: 6224,
      x: 10471,
      y: 9507
    },{
      NanogenreId: 6225,
      x: 8429,
      y: 6266
    },{
      NanogenreId: 6226,
      x: 11604,
      y: 3472
    },{
      NanogenreId: 6227,
      x: 7987,
      y: 5685
    },{
      NanogenreId: 6228,
      x: 7312,
      y: 5134
    },{
      NanogenreId: 6229,
      x: 9969,
      y: 5713
    },{
      NanogenreId: 6230,
      x: 6367,
      y: 8419
    },{
      NanogenreId: 6231,
      x: 6670,
      y: 6704
    },{
      NanogenreId: 6232,
      x: 8718,
      y: 5272
    },{
      NanogenreId: 6233,
      x: 7826,
      y: 8010
    },{
      NanogenreId: 6234,
      x: 10785,
      y: 10224
    },{
      NanogenreId: 6235,
      x: 8943,
      y: 4195
    },{
      NanogenreId: 6236,
      x: 6670,
      y: 10744
    },{
      NanogenreId: 6237,
      x: 8537,
      y: 8122
    },{
      NanogenreId: 6238,
      x: 6773,
      y: 6897
    },{
      NanogenreId: 6239,
      x: 6060,
      y: 7684
    },{
      NanogenreId: 6240,
      x: 11059,
      y: 6366
    },{
      NanogenreId: 6241,
      x: 7389,
      y: 9424
    },{
      NanogenreId: 6242,
      x: 8030,
      y: 11457
    },{
      NanogenreId: 6243,
      x: 10304,
      y: 6462
    },{
      NanogenreId: 6244,
      x: 10245,
      y: 5265
    },{
      NanogenreId: 6245,
      x: 8353,
      y: 6901
    },{
      NanogenreId: 6246,
      x: 8501,
      y: 5818
    },{
      NanogenreId: 6247,
      x: 6909,
      y: 6572
    },{
      NanogenreId: 6248,
      x: 3616,
      y: 7784
    },{
      NanogenreId: 6249,
      x: 8928,
      y: 5074
    },{
      NanogenreId: 6250,
      x: 8811,
      y: 7181
    },{
      NanogenreId: 6251,
      x: 7595,
      y: 5720
    },{
      NanogenreId: 6252,
      x: 5768,
      y: 9484
    },{
      NanogenreId: 6253,
      x: 9711,
      y: 10160
    },{
      NanogenreId: 6254,
      x: 7108,
      y: 9757
    },{
      NanogenreId: 6255,
      x: 7717,
      y: 5902
    },{
      NanogenreId: 6256,
      x: 7048,
      y: 6958
    },{
      NanogenreId: 6257,
      x: 10431,
      y: 5940
    },{
      NanogenreId: 6258,
      x: 9386,
      y: 5147
    },{
      NanogenreId: 6259,
      x: 9811,
      y: 4552
    },{
      NanogenreId: 6260,
      x: 7546,
      y: 6063
    },{
      NanogenreId: 6261,
      x: 6972,
      y: 7692
    },{
      NanogenreId: 6262,
      x: 7819,
      y: 8289
    },{
      NanogenreId: 6263,
      x: 9279,
      y: 5493
    },{
      NanogenreId: 6264,
      x: 5772,
      y: 9109
    },{
      NanogenreId: 6265,
      x: 8878,
      y: 5548
    },{
      NanogenreId: 6266,
      x: 6145,
      y: 6313
    },{
      NanogenreId: 6267,
      x: 7132,
      y: 9646
    },{
      NanogenreId: 6268,
      x: 8135,
      y: 7055
    },{
      NanogenreId: 6269,
      x: 6864,
      y: 5449
    },{
      NanogenreId: 6270,
      x: 6597,
      y: 10370
    },{
      NanogenreId: 6271,
      x: 9644,
      y: 5108
    },{
      NanogenreId: 6272,
      x: 9091,
      y: 6748
    },{
      NanogenreId: 6273,
      x: 7465,
      y: 7250
    },{
      NanogenreId: 6274,
      x: 9228,
      y: 7528
    },{
      NanogenreId: 6275,
      x: 8681,
      y: 8390
    },{
      NanogenreId: 6276,
      x: 5363,
      y: 9031
    },{
      NanogenreId: 6277,
      x: 9886,
      y: 4885
    },{
      NanogenreId: 6278,
      x: 10460,
      y: 10242
    },{
      NanogenreId: 6279,
      x: 7108,
      y: 8902
    },{
      NanogenreId: 6280,
      x: 9274,
      y: 9101
    },{
      NanogenreId: 6281,
      x: 5508,
      y: 8202
    },{
      NanogenreId: 6282,
      x: 6901,
      y: 8603
    },{
      NanogenreId: 6283,
      x: 6591,
      y: 6072
    },{
      NanogenreId: 6284,
      x: 8800,
      y: 9617
    },{
      NanogenreId: 6285,
      x: 8648,
      y: 10586
    },{
      NanogenreId: 6286,
      x: 5180,
      y: 8232
    },{
      NanogenreId: 6287,
      x: 5295,
      y: 9838
    },{
      NanogenreId: 6288,
      x: 8508,
      y: 9315
    },{
      NanogenreId: 6289,
      x: 9722,
      y: 5144
    },{
      NanogenreId: 6290,
      x: 6436,
      y: 5794
    },{
      NanogenreId: 6291,
      x: 9576,
      y: 5103
    },{
      NanogenreId: 6292,
      x: 9134,
      y: 9964
    },{
      NanogenreId: 6293,
      x: 8890,
      y: 7264
    },{
      NanogenreId: 6294,
      x: 6275,
      y: 7378
    },{
      NanogenreId: 6295,
      x: 6793,
      y: 6135
    },{
      NanogenreId: 6296,
      x: 8188,
      y: 6696
    },{
      NanogenreId: 6297,
      x: 6634,
      y: 5714
    },{
      NanogenreId: 6298,
      x: 10622,
      y: 10172
    },{
      NanogenreId: 6299,
      x: 7747,
      y: 5586
    },{
      NanogenreId: 6300,
      x: 8642,
      y: 8888
    },{
      NanogenreId: 6301,
      x: 7305,
      y: 6234
    },{
      NanogenreId: 6302,
      x: 8257,
      y: 7416
    },{
      NanogenreId: 6303,
      x: 6238,
      y: 9318
    },{
      NanogenreId: 6304,
      x: 7094,
      y: 10780
    },{
      NanogenreId: 6305,
      x: 9518,
      y: 6088
    },{
      NanogenreId: 6306,
      x: 4653,
      y: 7719
    },{
      NanogenreId: 6307,
      x: 8736,
      y: 8618
    },{
      NanogenreId: 6308,
      x: 7655,
      y: 6377
    },{
      NanogenreId: 6309,
      x: 8378,
      y: 7050
    },{
      NanogenreId: 6310,
      x: 10002,
      y: 7169
    },{
      NanogenreId: 6311,
      x: 4867,
      y: 7848
    },{
      NanogenreId: 6312,
      x: 6422,
      y: 4906
    },{
      NanogenreId: 6313,
      x: 9820,
      y: 6219
    },{
      NanogenreId: 6314,
      x: 6300,
      y: 6285
    },{
      NanogenreId: 6315,
      x: 9957,
      y: 6182
    },{
      NanogenreId: 6316,
      x: 7623,
      y: 4037
    },{
      NanogenreId: 6317,
      x: 10621,
      y: 10596
    },{
      NanogenreId: 6318,
      x: 7058,
      y: 8055
    },{
      NanogenreId: 6319,
      x: 6515,
      y: 7728
    },{
      NanogenreId: 6320,
      x: 9403,
      y: 5098
    },{
      NanogenreId: 6321,
      x: 9658,
      y: 8729
    },{
      NanogenreId: 6322,
      x: 6895,
      y: 6626
    },{
      NanogenreId: 6323,
      x: 8740,
      y: 9073
    },{
      NanogenreId: 6324,
      x: 5645,
      y: 4785
    },{
      NanogenreId: 6325,
      x: 8230,
      y: 5121
    },{
      NanogenreId: 6326,
      x: 10561,
      y: 10349
    },{
      NanogenreId: 6327,
      x: 9181,
      y: 6159
    },{
      NanogenreId: 6328,
      x: 6717,
      y: 10650
    },{
      NanogenreId: 6329,
      x: 9203,
      y: 7075
    },{
      NanogenreId: 6330,
      x: 9400,
      y: 5879
    },{
      NanogenreId: 6331,
      x: 7369,
      y: 11246
    },{
      NanogenreId: 6332,
      x: 6921,
      y: 7338
    },{
      NanogenreId: 6333,
      x: 7560,
      y: 7076
    },{
      NanogenreId: 6334,
      x: 8433,
      y: 5864
    },{
      NanogenreId: 6335,
      x: 5875,
      y: 5911
    },{
      NanogenreId: 6336,
      x: 6360,
      y: 6777
    },{
      NanogenreId: 6337,
      x: 5314,
      y: 10029
    },{
      NanogenreId: 6338,
      x: 8106,
      y: 8186
    },{
      NanogenreId: 6339,
      x: 13160,
      y: 6345
    },{
      NanogenreId: 6340,
      x: 7129,
      y: 5134
    },{
      NanogenreId: 6341,
      x: 6858,
      y: 6364
    },{
      NanogenreId: 6342,
      x: 5288,
      y: 5296
    },{
      NanogenreId: 6343,
      x: 9898,
      y: 7716
    },{
      NanogenreId: 6344,
      x: 8655,
      y: 11270
    },{
      NanogenreId: 6345,
      x: 9596,
      y: 5257
    },{
      NanogenreId: 6346,
      x: 7710,
      y: 10337
    },{
      NanogenreId: 6347,
      x: 8960,
      y: 4902
    },{
      NanogenreId: 6348,
      x: 7051,
      y: 6752
    },{
      NanogenreId: 6349,
      x: 7623,
      y: 9092
    },{
      NanogenreId: 6350,
      x: 6499,
      y: 7993
    },{
      NanogenreId: 6351,
      x: 9449,
      y: 5351
    },{
      NanogenreId: 6352,
      x: 9500,
      y: 10157
    },{
      NanogenreId: 6353,
      x: 9217,
      y: 4830
    },{
      NanogenreId: 6354,
      x: 8178,
      y: 8810
    },{
      NanogenreId: 6355,
      x: 8753,
      y: 5409
    },{
      NanogenreId: 6356,
      x: 9243,
      y: 7453
    },{
      NanogenreId: 6357,
      x: 9394,
      y: 5793
    },{
      NanogenreId: 6358,
      x: 6403,
      y: 9399
    },{
      NanogenreId: 6359,
      x: 7789,
      y: 7328
    },{
      NanogenreId: 6360,
      x: 4670,
      y: 8973
    },{
      NanogenreId: 6361,
      x: 10473,
      y: 10104
    },{
      NanogenreId: 6362,
      x: 8746,
      y: 5853
    },{
      NanogenreId: 6363,
      x: 12577,
      y: 6426
    },{
      NanogenreId: 6364,
      x: 7985,
      y: 5788
    },{
      NanogenreId: 6365,
      x: 9880,
      y: 5004
    },{
      NanogenreId: 6366,
      x: 4737,
      y: 7909
    },{
      NanogenreId: 6367,
      x: 8366,
      y: 5318
    },{
      NanogenreId: 6368,
      x: 7401,
      y: 4689
    },{
      NanogenreId: 6369,
      x: 9920,
      y: 10059
    },{
      NanogenreId: 6370,
      x: 5789,
      y: 7056
    },{
      NanogenreId: 6371,
      x: 8249,
      y: 6649
    },{
      NanogenreId: 6372,
      x: 7028,
      y: 6818
    },{
      NanogenreId: 6373,
      x: 9164,
      y: 10060
    },{
      NanogenreId: 6374,
      x: 8423,
      y: 5273
    },{
      NanogenreId: 6375,
      x: 11893,
      y: 8351
    },{
      NanogenreId: 6376,
      x: 7690,
      y: 5936
    },{
      NanogenreId: 6377,
      x: 7212,
      y: 7630
    },{
      NanogenreId: 6378,
      x: 9488,
      y: 6718
    },{
      NanogenreId: 6379,
      x: 6844,
      y: 6531
    },{
      NanogenreId: 6380,
      x: 10347,
      y: 9982
    },{
      NanogenreId: 6381,
      x: 6747,
      y: 8746
    },{
      NanogenreId: 6382,
      x: 6550,
      y: 5624
    },{
      NanogenreId: 6383,
      x: 10041,
      y: 4712
    },{
      NanogenreId: 6384,
      x: 6881,
      y: 3975
    },{
      NanogenreId: 6385,
      x: 9468,
      y: 4790
    },{
      NanogenreId: 6386,
      x: 9627,
      y: 7545
    },{
      NanogenreId: 6387,
      x: 8600,
      y: 6314
    },{
      NanogenreId: 6388,
      x: 11160,
      y: 8319
    },{
      NanogenreId: 6389,
      x: 9491,
      y: 5022
    },{
      NanogenreId: 6390,
      x: 9576,
      y: 8974
    },{
      NanogenreId: 6391,
      x: 7959,
      y: 6287
    },{
      NanogenreId: 6392,
      x: 9987,
      y: 5048
    },{
      NanogenreId: 6393,
      x: 7503,
      y: 8593
    },{
      NanogenreId: 6394,
      x: 6595,
      y: 9514
    },{
      NanogenreId: 6395,
      x: 4208,
      y: 10245
    },{
      NanogenreId: 6396,
      x: 10715,
      y: 10578
    },{
      NanogenreId: 6397,
      x: 7074,
      y: 7281
    },{
      NanogenreId: 6398,
      x: 9397,
      y: 5028
    },{
      NanogenreId: 6399,
      x: 7975,
      y: 4743
    },{
      NanogenreId: 6400,
      x: 4116,
      y: 8098
    },{
      NanogenreId: 6401,
      x: 8037,
      y: 7281
    },{
      NanogenreId: 6402,
      x: 5578,
      y: 7067
    },{
      NanogenreId: 6403,
      x: 9134,
      y: 5740
    },{
      NanogenreId: 6404,
      x: 6631,
      y: 6730
    },{
      NanogenreId: 6405,
      x: 6778,
      y: 5803
    },{
      NanogenreId: 6406,
      x: 9057,
      y: 5561
    },{
      NanogenreId: 6407,
      x: 9805,
      y: 5950
    },{
      NanogenreId: 6408,
      x: 5080,
      y: 10647
    },{
      NanogenreId: 6409,
      x: 7326,
      y: 5922
    },{
      NanogenreId: 6410,
      x: 9289,
      y: 5235
    },{
      NanogenreId: 6411,
      x: 6031,
      y: 7616
    },{
      NanogenreId: 6412,
      x: 4107,
      y: 6574
    },{
      NanogenreId: 6413,
      x: 7665,
      y: 5823
    },{
      NanogenreId: 6414,
      x: 6363,
      y: 10930
    },{
      NanogenreId: 6415,
      x: 4623,
      y: 9376
    },{
      NanogenreId: 6416,
      x: 7693,
      y: 10298
    },{
      NanogenreId: 6417,
      x: 9900,
      y: 5855
    },{
      NanogenreId: 6418,
      x: 8771,
      y: 10388
    },{
      NanogenreId: 6419,
      x: 9479,
      y: 6112
    },{
      NanogenreId: 6420,
      x: 8134,
      y: 6986
    },{
      NanogenreId: 6421,
      x: 7043,
      y: 11394
    },{
      NanogenreId: 6422,
      x: 9957,
      y: 9670
    },{
      NanogenreId: 6423,
      x: 7649,
      y: 9432
    },{
      NanogenreId: 6424,
      x: 7651,
      y: 6848
    },{
      NanogenreId: 6425,
      x: 6542,
      y: 10541
    },{
      NanogenreId: 6426,
      x: 7409,
      y: 9513
    },{
      NanogenreId: 6427,
      x: 5937,
      y: 6785
    },{
      NanogenreId: 6428,
      x: 9556,
      y: 8111
    },{
      NanogenreId: 6429,
      x: 9544,
      y: 5252
    },{
      NanogenreId: 6430,
      x: 3988,
      y: 9924
    },{
      NanogenreId: 6431,
      x: 9514,
      y: 4639
    },{
      NanogenreId: 6432,
      x: 5600,
      y: 6501
    },{
      NanogenreId: 6433,
      x: 7804,
      y: 5836
    },{
      NanogenreId: 6434,
      x: 7861,
      y: 8075
    },{
      NanogenreId: 6435,
      x: 9822,
      y: 10418
    },{
      NanogenreId: 6436,
      x: 5577,
      y: 9045
    },{
      NanogenreId: 6437,
      x: 10561,
      y: 8972
    },{
      NanogenreId: 6438,
      x: 7194,
      y: 4533
    },{
      NanogenreId: 6439,
      x: 6765,
      y: 9057
    },{
      NanogenreId: 6440,
      x: 8964,
      y: 8844
    },{
      NanogenreId: 6441,
      x: 12094,
      y: 7468
    },{
      NanogenreId: 6442,
      x: 9354,
      y: 5496
    },{
      NanogenreId: 6443,
      x: 6845,
      y: 7134
    },{
      NanogenreId: 6444,
      x: 7108,
      y: 6031
    },{
      NanogenreId: 6445,
      x: 5585,
      y: 8137
    },{
      NanogenreId: 6446,
      x: 6462,
      y: 7787
    },{
      NanogenreId: 6447,
      x: 7092,
      y: 8465
    },{
      NanogenreId: 6448,
      x: 7311,
      y: 6438
    },{
      NanogenreId: 6449,
      x: 6436,
      y: 7926
    },{
      NanogenreId: 6450,
      x: 7748,
      y: 6991
    },{
      NanogenreId: 6451,
      x: 11492,
      y: 8882
    },{
      NanogenreId: 6452,
      x: 6572,
      y: 12303
    },{
      NanogenreId: 6453,
      x: 9779,
      y: 9875
    },{
      NanogenreId: 6454,
      x: 6249,
      y: 4087
    },{
      NanogenreId: 6455,
      x: 9421,
      y: 9407
    },{
      NanogenreId: 6456,
      x: 9662,
      y: 5438
    },{
      NanogenreId: 6457,
      x: 8300,
      y: 4847
    },{
      NanogenreId: 6458,
      x: 8489,
      y: 5453
    },{
      NanogenreId: 6459,
      x: 8850,
      y: 9266
    },{
      NanogenreId: 6460,
      x: 11124,
      y: 10047
    },{
      NanogenreId: 6461,
      x: 8476,
      y: 5721
    },{
      NanogenreId: 6462,
      x: 8419,
      y: 10675
    },{
      NanogenreId: 6463,
      x: 8843,
      y: 6465
    },{
      NanogenreId: 6464,
      x: 7737,
      y: 4990
    },{
      NanogenreId: 6465,
      x: 7888,
      y: 8416
    },{
      NanogenreId: 6466,
      x: 8890,
      y: 11953
    },{
      NanogenreId: 6467,
      x: 7295,
      y: 5304
    },{
      NanogenreId: 6468,
      x: 7996,
      y: 3476
    },{
      NanogenreId: 6469,
      x: 9741,
      y: 9463
    },{
      NanogenreId: 6470,
      x: 9639,
      y: 5401
    },{
      NanogenreId: 6471,
      x: 6661,
      y: 7085
    },{
      NanogenreId: 6472,
      x: 5143,
      y: 8976
    },{
      NanogenreId: 6473,
      x: 7567,
      y: 7420
    },{
      NanogenreId: 6474,
      x: 6349,
      y: 6670
    },{
      NanogenreId: 6475,
      x: 8179,
      y: 5682
    },{
      NanogenreId: 6476,
      x: 9283,
      y: 5660
    },{
      NanogenreId: 6477,
      x: 7947,
      y: 5536
    },{
      NanogenreId: 6478,
      x: 8200,
      y: 8127
    },{
      NanogenreId: 6479,
      x: 8032,
      y: 6380
    },{
      NanogenreId: 6480,
      x: 9346,
      y: 5215
    },{
      NanogenreId: 6481,
      x: 10608,
      y: 10742
    },{
      NanogenreId: 6482,
      x: 7985,
      y: 6418
    },{
      NanogenreId: 6483,
      x: 9201,
      y: 5340
    },{
      NanogenreId: 6484,
      x: 7578,
      y: 12256
    },{
      NanogenreId: 6485,
      x: 6051,
      y: 6658
    },{
      NanogenreId: 6486,
      x: 9547,
      y: 5212
    },{
      NanogenreId: 6487,
      x: 6579,
      y: 7200
    },{
      NanogenreId: 6488,
      x: 6293,
      y: 7230
    },{
      NanogenreId: 6489,
      x: 6817,
      y: 5628
    },{
      NanogenreId: 6490,
      x: 9828,
      y: 7493
    },{
      NanogenreId: 6491,
      x: 9957,
      y: 5579
    },{
      NanogenreId: 6492,
      x: 7268,
      y: 7560
    },{
      NanogenreId: 6493,
      x: 6073,
      y: 6187
    },{
      NanogenreId: 6494,
      x: 5540,
      y: 7879
    },{
      NanogenreId: 6495,
      x: 8878,
      y: 6684
    },{
      NanogenreId: 6496,
      x: 8125,
      y: 5221
    },{
      NanogenreId: 6497,
      x: 8913,
      y: 5863
    },{
      NanogenreId: 6498,
      x: 7292,
      y: 5558
    },{
      NanogenreId: 6499,
      x: 8656,
      y: 6816
    },{
      NanogenreId: 6500,
      x: 8413,
      y: 6306
    },{
      NanogenreId: 6501,
      x: 8958,
      y: 4873
    },{
      NanogenreId: 6502,
      x: 9238,
      y: 6994
    },{
      NanogenreId: 6503,
      x: 9641,
      y: 5374
    },{
      NanogenreId: 6504,
      x: 6708,
      y: 7047
    },{
      NanogenreId: 6505,
      x: 7580,
      y: 6944
    },{
      NanogenreId: 6506,
      x: 7764,
      y: 5361
    },{
      NanogenreId: 6507,
      x: 9153,
      y: 7856
    },{
      NanogenreId: 6508,
      x: 7236,
      y: 10135
    },{
      NanogenreId: 6509,
      x: 7497,
      y: 10693
    },{
      NanogenreId: 6510,
      x: 7994,
      y: 5847
    },{
      NanogenreId: 6511,
      x: 9463,
      y: 4696
    },{
      NanogenreId: 6512,
      x: 5232,
      y: 7570
    },{
      NanogenreId: 6513,
      x: 9783,
      y: 5221
    },{
      NanogenreId: 6514,
      x: 8184,
      y: 5875
    },{
      NanogenreId: 6515,
      x: 7842,
      y: 6947
    },{
      NanogenreId: 6516,
      x: 6562,
      y: 9193
    },{
      NanogenreId: 6517,
      x: 8249,
      y: 7231
    },{
      NanogenreId: 6518,
      x: 11442,
      y: 9159
    },{
      NanogenreId: 6519,
      x: 8571,
      y: 5832
    },{
      NanogenreId: 6520,
      x: 7370,
      y: 7772
    },{
      NanogenreId: 6521,
      x: 6793,
      y: 6611
    },{
      NanogenreId: 6522,
      x: 12488,
      y: 9107
    },{
      NanogenreId: 6523,
      x: 9479,
      y: 5937
    },{
      NanogenreId: 6524,
      x: 9979,
      y: 7997
    },{
      NanogenreId: 6525,
      x: 6273,
      y: 8299
    },{
      NanogenreId: 6526,
      x: 11019,
      y: 5800
    },{
      NanogenreId: 6527,
      x: 9024,
      y: 8922
    },{
      NanogenreId: 6528,
      x: 8477,
      y: 9273
    },{
      NanogenreId: 6529,
      x: 7048,
      y: 7775
    },{
      NanogenreId: 6530,
      x: 8576,
      y: 7400
    },{
      NanogenreId: 6531,
      x: 5491,
      y: 6967
    },{
      NanogenreId: 6532,
      x: 8279,
      y: 9423
    },{
      NanogenreId: 6533,
      x: 5338,
      y: 8762
    },{
      NanogenreId: 6534,
      x: 10369,
      y: 10201
    },{
      NanogenreId: 6535,
      x: 5271,
      y: 4691
    },{
      NanogenreId: 6536,
      x: 7755,
      y: 10186
    },{
      NanogenreId: 6537,
      x: 9477,
      y: 8363
    },{
      NanogenreId: 6538,
      x: 8140,
      y: 6095
    },{
      NanogenreId: 6539,
      x: 7155,
      y: 7085
    },{
      NanogenreId: 6540,
      x: 9453,
      y: 10434
    },{
      NanogenreId: 6541,
      x: 7889,
      y: 7029
    },{
      NanogenreId: 6542,
      x: 9123,
      y: 9163
    },{
      NanogenreId: 6543,
      x: 8195,
      y: 7040
    },{
      NanogenreId: 6544,
      x: 9679,
      y: 8124
    },{
      NanogenreId: 6545,
      x: 7157,
      y: 5674
    },{
      NanogenreId: 6546,
      x: 6891,
      y: 11729
    },{
      NanogenreId: 6547,
      x: 7074,
      y: 7004
    },{
      NanogenreId: 6548,
      x: 9537,
      y: 10561
    },{
      NanogenreId: 6549,
      x: 6380,
      y: 9211
    },{
      NanogenreId: 6550,
      x: 6136,
      y: 7079
    },{
      NanogenreId: 6551,
      x: 8969,
      y: 5632
    },{
      NanogenreId: 6552,
      x: 8887,
      y: 8603
    },{
      NanogenreId: 6553,
      x: 8695,
      y: 9716
    },{
      NanogenreId: 6554,
      x: 5583,
      y: 6218
    },{
      NanogenreId: 6555,
      x: 8404,
      y: 2746
    },{
      NanogenreId: 6556,
      x: 7113,
      y: 5961
    },{
      NanogenreId: 6557,
      x: 5634,
      y: 7829
    },{
      NanogenreId: 6558,
      x: 7716,
      y: 7109
    },{
      NanogenreId: 6559,
      x: 6964,
      y: 10195
    },{
      NanogenreId: 6560,
      x: 7147,
      y: 8303
    },{
      NanogenreId: 6561,
      x: 6895,
      y: 5517
    },{
      NanogenreId: 6562,
      x: 7164,
      y: 6648
    },{
      NanogenreId: 6563,
      x: 6890,
      y: 6734
    },{
      NanogenreId: 6564,
      x: 6795,
      y: 7019
    },{
      NanogenreId: 6565,
      x: 9370,
      y: 5331
    },{
      NanogenreId: 6566,
      x: 7103,
      y: 6515
    },{
      NanogenreId: 6567,
      x: 8881,
      y: 5374
    },{
      NanogenreId: 6568,
      x: 9170,
      y: 9011
    },{
      NanogenreId: 6569,
      x: 3125,
      y: 6268
    },{
      NanogenreId: 6570,
      x: 10020,
      y: 6152
    },{
      NanogenreId: 6571,
      x: 8899,
      y: 10925
    },{
      NanogenreId: 6572,
      x: 10048,
      y: 9284
    },{
      NanogenreId: 6573,
      x: 6505,
      y: 9050
    },{
      NanogenreId: 6574,
      x: 8814,
      y: 5468
    },{
      NanogenreId: 6575,
      x: 6955,
      y: 7424
    },{
      NanogenreId: 6576,
      x: 7171,
      y: 8159
    },{
      NanogenreId: 6577,
      x: 10148,
      y: 5336
    },{
      NanogenreId: 6578,
      x: 9492,
      y: 4843
    },{
      NanogenreId: 6579,
      x: 7549,
      y: 10348
    },{
      NanogenreId: 6580,
      x: 7211,
      y: 6319
    },{
      NanogenreId: 6581,
      x: 5187,
      y: 9418
    },{
      NanogenreId: 6582,
      x: 8970,
      y: 7092
    },{
      NanogenreId: 6583,
      x: 8605,
      y: 5741
    },{
      NanogenreId: 6584,
      x: 6223,
      y: 9050
    },{
      NanogenreId: 6585,
      x: 9854,
      y: 10331
    },{
      NanogenreId: 6586,
      x: 9055,
      y: 10768
    },{
      NanogenreId: 6587,
      x: 4787,
      y: 7135
    },{
      NanogenreId: 6588,
      x: 7540,
      y: 5699
    },{
      NanogenreId: 6589,
      x: 8194,
      y: 4327
    },{
      NanogenreId: 6590,
      x: 7283,
      y: 6869
    },{
      NanogenreId: 6591,
      x: 6752,
      y: 6018
    },{
      NanogenreId: 6592,
      x: 11520,
      y: 6870
    },{
      NanogenreId: 6593,
      x: 9365,
      y: 4966
    },{
      NanogenreId: 6594,
      x: 8550,
      y: 6770
    },{
      NanogenreId: 6595,
      x: 7360,
      y: 10371
    },{
      NanogenreId: 6596,
      x: 11118,
      y: 7515
    },{
      NanogenreId: 6597,
      x: 10691,
      y: 10387
    },{
      NanogenreId: 6598,
      x: 7323,
      y: 7672
    },{
      NanogenreId: 6599,
      x: 9430,
      y: 4837
    },{
      NanogenreId: 6600,
      x: 7833,
      y: 8651
    },{
      NanogenreId: 6601,
      x: 10148,
      y: 11788
    },{
      NanogenreId: 6602,
      x: 7132,
      y: 9361
    },{
      NanogenreId: 6603,
      x: 6406,
      y: 6260
    },{
      NanogenreId: 6604,
      x: 8347,
      y: 5241
    },{
      NanogenreId: 6605,
      x: 8413,
      y: 7140
    },{
      NanogenreId: 6606,
      x: 5915,
      y: 8608
    },{
      NanogenreId: 6607,
      x: 8596,
      y: 2971
    },{
      NanogenreId: 6608,
      x: 8219,
      y: 5222
    },{
      NanogenreId: 6609,
      x: 8401,
      y: 9332
    },{
      NanogenreId: 6610,
      x: 6822,
      y: 6078
    },{
      NanogenreId: 6611,
      x: 9929,
      y: 9297
    },{
      NanogenreId: 6612,
      x: 8765,
      y: 7143
    },{
      NanogenreId: 6613,
      x: 8794,
      y: 5707
    },{
      NanogenreId: 6614,
      x: 9867,
      y: 4687
    },{
      NanogenreId: 6615,
      x: 9198,
      y: 5124
    },{
      NanogenreId: 6616,
      x: 10298,
      y: 9885
    },{
      NanogenreId: 6617,
      x: 10841,
      y: 9936
    },{
      NanogenreId: 6618,
      x: 9629,
      y: 7187
    },{
      NanogenreId: 6619,
      x: 8837,
      y: 7837
    },{
      NanogenreId: 6620,
      x: 8862,
      y: 4672
    },{
      NanogenreId: 6621,
      x: 9761,
      y: 9138
    },{
      NanogenreId: 6622,
      x: 8149,
      y: 9621
    },{
      NanogenreId: 6623,
      x: 5700,
      y: 8440
    },{
      NanogenreId: 6624,
      x: 8339,
      y: 5989
    },{
      NanogenreId: 6625,
      x: 7274,
      y: 6975
    },{
      NanogenreId: 6626,
      x: 9747,
      y: 5023
    },{
      NanogenreId: 6627,
      x: 9451,
      y: 7002
    },{
      NanogenreId: 6628,
      x: 7347,
      y: 7282
    },{
      NanogenreId: 6629,
      x: 8448,
      y: 5603
    },{
      NanogenreId: 6630,
      x: 6778,
      y: 9184
    },{
      NanogenreId: 6631,
      x: 7231,
      y: 8439
    },{
      NanogenreId: 6632,
      x: 3653,
      y: 5572
    },{
      NanogenreId: 6633,
      x: 4616,
      y: 7573
    },{
      NanogenreId: 6634,
      x: 6947,
      y: 5925
    },{
      NanogenreId: 6635,
      x: 7456,
      y: 8177
    },{
      NanogenreId: 6636,
      x: 7247,
      y: 10202
    },{
      NanogenreId: 6637,
      x: 9159,
      y: 5680
    },{
      NanogenreId: 6638,
      x: 5776,
      y: 8938
    },{
      NanogenreId: 6639,
      x: 9823,
      y: 8439
    },{
      NanogenreId: 6640,
      x: 7996,
      y: 9608
    },{
      NanogenreId: 6641,
      x: 10162,
      y: 10529
    },{
      NanogenreId: 6642,
      x: 8584,
      y: 8873
    },{
      NanogenreId: 6643,
      x: 8511,
      y: 6259
    },{
      NanogenreId: 6644,
      x: 10227,
      y: 10124
    },{
      NanogenreId: 6645,
      x: 9756,
      y: 8630
    },{
      NanogenreId: 6646,
      x: 5617,
      y: 6769
    },{
      NanogenreId: 6647,
      x: 9308,
      y: 8466
    },{
      NanogenreId: 6648,
      x: 7973,
      y: 11052
    },{
      NanogenreId: 6649,
      x: 8423,
      y: 8605
    },{
      NanogenreId: 6650,
      x: 8738,
      y: 6372
    },{
      NanogenreId: 6651,
      x: 9406,
      y: 5944
    },{
      NanogenreId: 6652,
      x: 3730,
      y: 5984
    },{
      NanogenreId: 6653,
      x: 4016,
      y: 8664
    },{
      NanogenreId: 6654,
      x: 8599,
      y: 6097
    },{
      NanogenreId: 6655,
      x: 10782,
      y: 10932
    },{
      NanogenreId: 6656,
      x: 5265,
      y: 9159
    },{
      NanogenreId: 6657,
      x: 3148,
      y: 9970
    },{
      NanogenreId: 6658,
      x: 10589,
      y: 7640
    },{
      NanogenreId: 6659,
      x: 9190,
      y: 5236
    },{
      NanogenreId: 6660,
      x: 4594,
      y: 7419
    },{
      NanogenreId: 6661,
      x: 5070,
      y: 6337
    },{
      NanogenreId: 6662,
      x: 6228,
      y: 5250
    },{
      NanogenreId: 6663,
      x: 8990,
      y: 6925
    },{
      NanogenreId: 6664,
      x: 5385,
      y: 5488
    },{
      NanogenreId: 6665,
      x: 10104,
      y: 4790
    },{
      NanogenreId: 6666,
      x: 7160,
      y: 11534
    },{
      NanogenreId: 6667,
      x: 6051,
      y: 9155
    },{
      NanogenreId: 6668,
      x: 12815,
      y: 3786
    },{
      NanogenreId: 6669,
      x: 6937,
      y: 11348
    },{
      NanogenreId: 6670,
      x: 6157,
      y: 6543
    },{
      NanogenreId: 6671,
      x: 8980,
      y: 4040
    },{
      NanogenreId: 6672,
      x: 12345,
      y: 6800
    },{
      NanogenreId: 6673,
      x: 8716,
      y: 5893
    },{
      NanogenreId: 6674,
      x: 11537,
      y: 9848
    },{
      NanogenreId: 6675,
      x: 7215,
      y: 10831
    },{
      NanogenreId: 6676,
      x: 10304,
      y: 7402
    },{
      NanogenreId: 6677,
      x: 10218,
      y: 5736
    },{
      NanogenreId: 6678,
      x: 6781,
      y: 8402
    },{
      NanogenreId: 6679,
      x: 7395,
      y: 4401
    },{
      NanogenreId: 6680,
      x: 3976,
      y: 10255
    },{
      NanogenreId: 6681,
      x: 7313,
      y: 7748
    },{
      NanogenreId: 6682,
      x: 9232,
      y: 5437
    },{
      NanogenreId: 6683,
      x: 11816,
      y: 9327
    },{
      NanogenreId: 6684,
      x: 12509,
      y: 6288
    },{
      NanogenreId: 6685,
      x: 6211,
      y: 4387
    },{
      NanogenreId: 6686,
      x: 9038,
      y: 8128
    },{
      NanogenreId: 6687,
      x: 7580,
      y: 10995
    },{
      NanogenreId: 6688,
      x: 7682,
      y: 5034
    },{
      NanogenreId: 6689,
      x: 5519,
      y: 9815
    },{
      NanogenreId: 6690,
      x: 4263,
      y: 9878
    },{
      NanogenreId: 6691,
      x: 8832,
      y: 3417
    },{
      NanogenreId: 6692,
      x: 8967,
      y: 5733
    },{
      NanogenreId: 6693,
      x: 7699,
      y: 9272
    },{
      NanogenreId: 6694,
      x: 10127,
      y: 8185
    },{
      NanogenreId: 6695,
      x: 5251,
      y: 9608
    },{
      NanogenreId: 6696,
      x: 9358,
      y: 5554
    },{
      NanogenreId: 6697,
      x: 10246,
      y: 4792
    },{
      NanogenreId: 6698,
      x: 6969,
      y: 11244
    },{
      NanogenreId: 6699,
      x: 11861,
      y: 7625
    },{
      NanogenreId: 6700,
      x: 6689,
      y: 8452
    },{
      NanogenreId: 6701,
      x: 10031,
      y: 6402
    },{
      NanogenreId: 6702,
      x: 9504,
      y: 5362
    },{
      NanogenreId: 6703,
      x: 10866,
      y: 10575
    },{
      NanogenreId: 6704,
      x: 7182,
      y: 11890
    },{
      NanogenreId: 6705,
      x: 10545,
      y: 10383
    },{
      NanogenreId: 6706,
      x: 10530,
      y: 10598
    },{
      NanogenreId: 6707,
      x: 8467,
      y: 5354
    },{
      NanogenreId: 6708,
      x: 10941,
      y: 10440
    },{
      NanogenreId: 6709,
      x: 7654,
      y: 11008
    },{
      NanogenreId: 6710,
      x: 8106,
      y: 9900
    },{
      NanogenreId: 6711,
      x: 5514,
      y: 10083
    },{
      NanogenreId: 6712,
      x: 11774,
      y: 4750
    },{
      NanogenreId: 6713,
      x: 9149,
      y: 5307
    },{
      NanogenreId: 6714,
      x: 7603,
      y: 9809
    },{
      NanogenreId: 6715,
      x: 7305,
      y: 6168
    },{
      NanogenreId: 6716,
      x: 8674,
      y: 9493
    },{
      NanogenreId: 6717,
      x: 8561,
      y: 10444
    },{
      NanogenreId: 6718,
      x: 6526,
      y: 7469
    },{
      NanogenreId: 6719,
      x: 8499,
      y: 4418
    },{
      NanogenreId: 6720,
      x: 10148,
      y: 7398
    },{
      NanogenreId: 6721,
      x: 8258,
      y: 9453
    },{
      NanogenreId: 6722,
      x: 11024,
      y: 4864
    },{
      NanogenreId: 6723,
      x: 8306,
      y: 5744
    },{
      NanogenreId: 6724,
      x: 13126,
      y: 8817
    },{
      NanogenreId: 6725,
      x: 10372,
      y: 9013
    },{
      NanogenreId: 6726,
      x: 7288,
      y: 6510
    },{
      NanogenreId: 6727,
      x: 9807,
      y: 10120
    },{
      NanogenreId: 6728,
      x: 10689,
      y: 7226
    },{
      NanogenreId: 6729,
      x: 9920,
      y: 8778
    },{
      NanogenreId: 6730,
      x: 10928,
      y: 10754
    },{
      NanogenreId: 6731,
      x: 10056,
      y: 4866
    },{
      NanogenreId: 6732,
      x: 11199,
      y: 6626
    },{
      NanogenreId: 6733,
      x: 9957,
      y: 5074
    },{
      NanogenreId: 6734,
      x: 9952,
      y: 10372
    },{
      NanogenreId: 6735,
      x: 7906,
      y: 11262
    },{
      NanogenreId: 6736,
      x: 8331,
      y: 10971
    },{
      NanogenreId: 6737,
      x: 7894,
      y: 7182
    },{
      NanogenreId: 6738,
      x: 11219,
      y: 10227
    },{
      NanogenreId: 6739,
      x: 7194,
      y: 6358
    },{
      NanogenreId: 6740,
      x: 9700,
      y: 8810
    },{
      NanogenreId: 6741,
      x: 7515,
      y: 6439
    },{
      NanogenreId: 6742,
      x: 7947,
      y: 6466
    },{
      NanogenreId: 6743,
      x: 8865,
      y: 8139
    },{
      NanogenreId: 6744,
      x: 8019,
      y: 6319
    },{
      NanogenreId: 6745,
      x: 6592,
      y: 6199
    },{
      NanogenreId: 6746,
      x: 10399,
      y: 9730
    },{
      NanogenreId: 6747,
      x: 7635,
      y: 10742
    },{
      NanogenreId: 6748,
      x: 10486,
      y: 10390
    },{
      NanogenreId: 6749,
      x: 8712,
      y: 4713
    },{
      NanogenreId: 6750,
      x: 9880,
      y: 9368
    },{
      NanogenreId: 6751,
      x: 10203,
      y: 10848
    },{
      NanogenreId: 6752,
      x: 11057,
      y: 10130
    },{
      NanogenreId: 6753,
      x: 6453,
      y: 9318
    },{
      NanogenreId: 6754,
      x: 7931,
      y: 9389
    },{
      NanogenreId: 6755,
      x: 8826,
      y: 5071
    },{
      NanogenreId: 6756,
      x: 6944,
      y: 9867
    },{
      NanogenreId: 6757,
      x: 9702,
      y: 5162
    },{
      NanogenreId: 6758,
      x: 5315,
      y: 7276
    },{
      NanogenreId: 6759,
      x: 8671,
      y: 10066
    },{
      NanogenreId: 6760,
      x: 8260,
      y: 9813
    },{
      NanogenreId: 6761,
      x: 9283,
      y: 9775
    },{
      NanogenreId: 6762,
      x: 8705,
      y: 8882
    },{
      NanogenreId: 6763,
      x: 9078,
      y: 9559
    },{
      NanogenreId: 6764,
      x: 9071,
      y: 9006
    },{
      NanogenreId: 6765,
      x: 7329,
      y: 10098
    },{
      NanogenreId: 6766,
      x: 6043,
      y: 11394
    },{
      NanogenreId: 6767,
      x: 7710,
      y: 9412
    },{
      NanogenreId: 6768,
      x: 7207,
      y: 11266
    },{
      NanogenreId: 6769,
      x: 7357,
      y: 5049
    },{
      NanogenreId: 6770,
      x: 11887,
      y: 9505
    },{
      NanogenreId: 6771,
      x: 10518,
      y: 7102
    },{
      NanogenreId: 6772,
      x: 8745,
      y: 11155
    },{
      NanogenreId: 6773,
      x: 10702,
      y: 9630
    },{
      NanogenreId: 6774,
      x: 8297,
      y: 7503
    },{
      NanogenreId: 6775,
      x: 11536,
      y: 2665
    },{
      NanogenreId: 6776,
      x: 7380,
      y: 9325
    },{
      NanogenreId: 6777,
      x: 9220,
      y: 5560
    },{
      NanogenreId: 6778,
      x: 9882,
      y: 9814
    },{
      NanogenreId: 6779,
      x: 8954,
      y: 8210
    },{
      NanogenreId: 6780,
      x: 6831,
      y: 10157
    },{
      NanogenreId: 6781,
      x: 10094,
      y: 5013
    },{
      NanogenreId: 6782,
      x: 10183,
      y: 6426
    },{
      NanogenreId: 6783,
      x: 9462,
      y: 4753
    },{
      NanogenreId: 6784,
      x: 7838,
      y: 5457
    },{
      NanogenreId: 6785,
      x: 9417,
      y: 5154
    },{
      NanogenreId: 6786,
      x: 5908,
      y: 11568
    },{
      NanogenreId: 6787,
      x: 11403,
      y: 10596
    },{
      NanogenreId: 6788,
      x: 7042,
      y: 5748
    },{
      NanogenreId: 6789,
      x: 8573,
      y: 10755
    },{
      NanogenreId: 6790,
      x: 6580,
      y: 5972
    },{
      NanogenreId: 6791,
      x: 6599,
      y: 9685
    },{
      NanogenreId: 6792,
      x: 10756,
      y: 7749
    },{
      NanogenreId: 6793,
      x: 11017,
      y: 10271
    },{
      NanogenreId: 6794,
      x: 10415,
      y: 8628
    },{
      NanogenreId: 6795,
      x: 9010,
      y: 9054
    },{
      NanogenreId: 6796,
      x: 6202,
      y: 8336
    },{
      NanogenreId: 6797,
      x: 6595,
      y: 7073
    },{
      NanogenreId: 6798,
      x: 7059,
      y: 6019
    },{
      NanogenreId: 6799,
      x: 8735,
      y: 7885
    },{
      NanogenreId: 6800,
      x: 6588,
      y: 10183
    },{
      NanogenreId: 6801,
      x: 11876,
      y: 9232
    },{
      NanogenreId: 6802,
      x: 9703,
      y: 4711
    },{
      NanogenreId: 6803,
      x: 7625,
      y: 8398
    },{
      NanogenreId: 6804,
      x: 8984,
      y: 5176
    },{
      NanogenreId: 6805,
      x: 7471,
      y: 8033
    },{
      NanogenreId: 6806,
      x: 7961,
      y: 13199
    },{
      NanogenreId: 6807,
      x: 9666,
      y: 5046
    },{
      NanogenreId: 6808,
      x: 11042,
      y: 7063
    },{
      NanogenreId: 6809,
      x: 10513,
      y: 10716
    },{
      NanogenreId: 6810,
      x: 8294,
      y: 5410
    },{
      NanogenreId: 6811,
      x: 6628,
      y: 8496
    },{
      NanogenreId: 6812,
      x: 11113,
      y: 7136
    },{
      NanogenreId: 6813,
      x: 9177,
      y: 5425
    },{
      NanogenreId: 6814,
      x: 8202,
      y: 6449
    },{
      NanogenreId: 6815,
      x: 8936,
      y: 5590
    },{
      NanogenreId: 6816,
      x: 7779,
      y: 10302
    },{
      NanogenreId: 6817,
      x: 9173,
      y: 5100
    },{
      NanogenreId: 6818,
      x: 5826,
      y: 7204
    },{
      NanogenreId: 6819,
      x: 5489,
      y: 9061
    },{
      NanogenreId: 6820,
      x: 8419,
      y: 5765
    },{
      NanogenreId: 6821,
      x: 7027,
      y: 7180
    },{
      NanogenreId: 6822,
      x: 9041,
      y: 9946
    },{
      NanogenreId: 6823,
      x: 11061,
      y: 7627
    },{
      NanogenreId: 6824,
      x: 9850,
      y: 2133
    },{
      NanogenreId: 6825,
      x: 8420,
      y: 9075
    },{
      NanogenreId: 6826,
      x: 4756,
      y: 3523
    },{
      NanogenreId: 6827,
      x: 11113,
      y: 10536
    },{
      NanogenreId: 6828,
      x: 6106,
      y: 10613
    },{
      NanogenreId: 6829,
      x: 8382,
      y: 5208
    },{
      NanogenreId: 6830,
      x: 5952,
      y: 7133
    },{
      NanogenreId: 6831,
      x: 10740,
      y: 9587
    },{
      NanogenreId: 6832,
      x: 10348,
      y: 5957
    },{
      NanogenreId: 6833,
      x: 10263,
      y: 6400
    },{
      NanogenreId: 6834,
      x: 7970,
      y: 5954
    },{
      NanogenreId: 6835,
      x: 7073,
      y: 4685
    },{
      NanogenreId: 6836,
      x: 6602,
      y: 6527
    },{
      NanogenreId: 6837,
      x: 7828,
      y: 7872
    },{
      NanogenreId: 6838,
      x: 10655,
      y: 10575
    },{
      NanogenreId: 6839,
      x: 9028,
      y: 5750
    },{
      NanogenreId: 6840,
      x: 9180,
      y: 5780
    },{
      NanogenreId: 6841,
      x: 5557,
      y: 7813
    },{
      NanogenreId: 6842,
      x: 8290,
      y: 9361
    },{
      NanogenreId: 6843,
      x: 8405,
      y: 5600
    },{
      NanogenreId: 6844,
      x: 8025,
      y: 6658
    },{
      NanogenreId: 6845,
      x: 5791,
      y: 4570
    },{
      NanogenreId: 6846,
      x: 10326,
      y: 10553
    },{
      NanogenreId: 6847,
      x: 8184,
      y: 4422
    },{
      NanogenreId: 6848,
      x: 10012,
      y: 4779
    },{
      NanogenreId: 6849,
      x: 3466,
      y: 5662
    },{
      NanogenreId: 6850,
      x: 10206,
      y: 4534
    },{
      NanogenreId: 6851,
      x: 6741,
      y: 8334
    },{
      NanogenreId: 6852,
      x: 9905,
      y: 10623
    },{
      NanogenreId: 6853,
      x: 8429,
      y: 6826
    },{
      NanogenreId: 6854,
      x: 8490,
      y: 8700
    },{
      NanogenreId: 6855,
      x: 10100,
      y: 9287
    },{
      NanogenreId: 6856,
      x: 8901,
      y: 8250
    },{
      NanogenreId: 6857,
      x: 8011,
      y: 5327
    },{
      NanogenreId: 6858,
      x: 7012,
      y: 10927
    },{
      NanogenreId: 6859,
      x: 5017,
      y: 6996
    },{
      NanogenreId: 6860,
      x: 8929,
      y: 7687
    },{
      NanogenreId: 6861,
      x: 8722,
      y: 7057
    },{
      NanogenreId: 6862,
      x: 7830,
      y: 7418
    },{
      NanogenreId: 6863,
      x: 7414,
      y: 9853
    },{
      NanogenreId: 6864,
      x: 9954,
      y: 7287
    },{
      NanogenreId: 6865,
      x: 9420,
      y: 8962
    },{
      NanogenreId: 6866,
      x: 9131,
      y: 7630
    },{
      NanogenreId: 6867,
      x: 12734,
      y: 6967
    },{
      NanogenreId: 6868,
      x: 9392,
      y: 10654
    },{
      NanogenreId: 6869,
      x: 7416,
      y: 6903
    },{
      NanogenreId: 6870,
      x: 7763,
      y: 7248
    },{
      NanogenreId: 6871,
      x: 9177,
      y: 5208
    },{
      NanogenreId: 6872,
      x: 7970,
      y: 10219
    },{
      NanogenreId: 6873,
      x: 8387,
      y: 6940
    },{
      NanogenreId: 6874,
      x: 9091,
      y: 5304
    },{
      NanogenreId: 6875,
      x: 4278,
      y: 8836
    },{
      NanogenreId: 6876,
      x: 10635,
      y: 8476
    },{
      NanogenreId: 6877,
      x: 4897,
      y: 9749
    },{
      NanogenreId: 6878,
      x: 7117,
      y: 6345
    },{
      NanogenreId: 6879,
      x: 8060,
      y: 5073
    },{
      NanogenreId: 6880,
      x: 9627,
      y: 10367
    },{
      NanogenreId: 6881,
      x: 8708,
      y: 10531
    },{
      NanogenreId: 6882,
      x: 7755,
      y: 9853
    },{
      NanogenreId: 6883,
      x: 10270,
      y: 7065
    },{
      NanogenreId: 6884,
      x: 10712,
      y: 10214
    },{
      NanogenreId: 6885,
      x: 6535,
      y: 5781
    },{
      NanogenreId: 6886,
      x: 7971,
      y: 8896
    },{
      NanogenreId: 6887,
      x: 8885,
      y: 9137
    },{
      NanogenreId: 6888,
      x: 5166,
      y: 7252
    },{
      NanogenreId: 6889,
      x: 5292,
      y: 11109
    },{
      NanogenreId: 6890,
      x: 9183,
      y: 6371
    },{
      NanogenreId: 6891,
      x: 10708,
      y: 10535
    },{
      NanogenreId: 6892,
      x: 7964,
      y: 10098
    },{
      NanogenreId: 6893,
      x: 12094,
      y: 8373
    },{
      NanogenreId: 6894,
      x: 7248,
      y: 10552
    },{
      NanogenreId: 6895,
      x: 9683,
      y: 10390
    },{
      NanogenreId: 6896,
      x: 6245,
      y: 6724
    },{
      NanogenreId: 6897,
      x: 9955,
      y: 8363
    },{
      NanogenreId: 6898,
      x: 10495,
      y: 8545
    },{
      NanogenreId: 6899,
      x: 10279,
      y: 4787
    },{
      NanogenreId: 6900,
      x: 8157,
      y: 8281
    },{
      NanogenreId: 6901,
      x: 7932,
      y: 10804
    },{
      NanogenreId: 6902,
      x: 4484,
      y: 8211
    },{
      NanogenreId: 6903,
      x: 9179,
      y: 5841
    },{
      NanogenreId: 6904,
      x: 8581,
      y: 8946
    },{
      NanogenreId: 6905,
      x: 8385,
      y: 10741
    },{
      NanogenreId: 6906,
      x: 9442,
      y: 4765
    },{
      NanogenreId: 6907,
      x: 8502,
      y: 9745
    },{
      NanogenreId: 6908,
      x: 8566,
      y: 8186
    },{
      NanogenreId: 6909,
      x: 7574,
      y: 11229
    },{
      NanogenreId: 6910,
      x: 6026,
      y: 4841
    },{
      NanogenreId: 6911,
      x: 7652,
      y: 9930
    },{
      NanogenreId: 6912,
      x: 10056,
      y: 10460
    },{
      NanogenreId: 6913,
      x: 8960,
      y: 9594
    },{
      NanogenreId: 6914,
      x: 9440,
      y: 9288
    },{
      NanogenreId: 6915,
      x: 6005,
      y: 8308
    },{
      NanogenreId: 6916,
      x: 7253,
      y: 4933
    },{
      NanogenreId: 6917,
      x: 10637,
      y: 10027
    },{
      NanogenreId: 6918,
      x: 5206,
      y: 12036
    },{
      NanogenreId: 6919,
      x: 10789,
      y: 10417
    },{
      NanogenreId: 6920,
      x: 6614,
      y: 10740
    },{
      NanogenreId: 6921,
      x: 6208,
      y: 11523
    },{
      NanogenreId: 6922,
      x: 8736,
      y: 7786
    },{
      NanogenreId: 6923,
      x: 9415,
      y: 10953
    },{
      NanogenreId: 6924,
      x: 7222,
      y: 9025
    },{
      NanogenreId: 6925,
      x: 10166,
      y: 10407
    },{
      NanogenreId: 6926,
      x: 6304,
      y: 6346
    },{
      NanogenreId: 6927,
      x: 9249,
      y: 8286
    },{
      NanogenreId: 6928,
      x: 7920,
      y: 6854
    },{
      NanogenreId: 6929,
      x: 11547,
      y: 8024
    },{
      NanogenreId: 6930,
      x: 7475,
      y: 9139
    },{
      NanogenreId: 6931,
      x: 8639,
      y: 10750
    },{
      NanogenreId: 6932,
      x: 12167,
      y: 9006
    },{
      NanogenreId: 6933,
      x: 8585,
      y: 5129
    },{
      NanogenreId: 6934,
      x: 11071,
      y: 9594
    },{
      NanogenreId: 6935,
      x: 10870,
      y: 10217
    },{
      NanogenreId: 6936,
      x: 5588,
      y: 7199
    },{
      NanogenreId: 6937,
      x: 10521,
      y: 9753
    },{
      NanogenreId: 6938,
      x: 8779,
      y: 10447
    },{
      NanogenreId: 6939,
      x: 4536,
      y: 8870
    },{
      NanogenreId: 6940,
      x: 9861,
      y: 4824
    },{
      NanogenreId: 6941,
      x: 7309,
      y: 10186
    },{
      NanogenreId: 6942,
      x: 8615,
      y: 6742
    },{
      NanogenreId: 6943,
      x: 7869,
      y: 10453
    },{
      NanogenreId: 6944,
      x: 4681,
      y: 8450
    },{
      NanogenreId: 6945,
      x: 6203,
      y: 5803
    },{
      NanogenreId: 6946,
      x: 6757,
      y: 10772
    },{
      NanogenreId: 6947,
      x: 9855,
      y: 4904
    },{
      NanogenreId: 6948,
      x: 8025,
      y: 10112
    },{
      NanogenreId: 6949,
      x: 9427,
      y: 6377
    },{
      NanogenreId: 6950,
      x: 10658,
      y: 6789
    },{
      NanogenreId: 6951,
      x: 9437,
      y: 9686
    },{
      NanogenreId: 6952,
      x: 12323,
      y: 6171
    },{
      NanogenreId: 6953,
      x: 9647,
      y: 11497
    },{
      NanogenreId: 6954,
      x: 9743,
      y: 5349
    },{
      NanogenreId: 6955,
      x: 7576,
      y: 7243
    },{
      NanogenreId: 6956,
      x: 8851,
      y: 8012
    },{
      NanogenreId: 6957,
      x: 8889,
      y: 9206
    },{
      NanogenreId: 6958,
      x: 7128,
      y: 4875
    },{
      NanogenreId: 6959,
      x: 9580,
      y: 4676
    },{
      NanogenreId: 6960,
      x: 12754,
      y: 8996
    },{
      NanogenreId: 6961,
      x: 10624,
      y: 7836
    },{
      NanogenreId: 6962,
      x: 4417,
      y: 9482
    },{
      NanogenreId: 6963,
      x: 10743,
      y: 9356
    },{
      NanogenreId: 6964,
      x: 5942,
      y: 7219
    },{
      NanogenreId: 6965,
      x: 9121,
      y: 9364
    },{
      NanogenreId: 6966,
      x: 8625,
      y: 8787
    },{
      NanogenreId: 6967,
      x: 10782,
      y: 9981
    },{
      NanogenreId: 6968,
      x: 9438,
      y: 7537
    },{
      NanogenreId: 6969,
      x: 10130,
      y: 9477
    },{
      NanogenreId: 6970,
      x: 8896,
      y: 6961
    },{
      NanogenreId: 6971,
      x: 12830,
      y: 8721
    },{
      NanogenreId: 6972,
      x: 11235,
      y: 9891
    },{
      NanogenreId: 6973,
      x: 10112,
      y: 5404
    },{
      NanogenreId: 6974,
      x: 8215,
      y: 9570
    },{
      NanogenreId: 6975,
      x: 6749,
      y: 5877
    },{
      NanogenreId: 6976,
      x: 9861,
      y: 10770
    },{
      NanogenreId: 6977,
      x: 9104,
      y: 5060
    },{
      NanogenreId: 6978,
      x: 10108,
      y: 5917
    },{
      NanogenreId: 6979,
      x: 10733,
      y: 9405
    },{
      NanogenreId: 6980,
      x: 8192,
      y: 8949
    },{
      NanogenreId: 6981,
      x: 8719,
      y: 9435
    },{
      NanogenreId: 6982,
      x: 6916,
      y: 9457
    },{
      NanogenreId: 6983,
      x: 8370,
      y: 10907
    },{
      NanogenreId: 6984,
      x: 8691,
      y: 8672
    },{
      NanogenreId: 6985,
      x: 10023,
      y: 5913
    },{
      NanogenreId: 6986,
      x: 8461,
      y: 6349
    },{
      NanogenreId: 6987,
      x: 11916,
      y: 10029
    },{
      NanogenreId: 6988,
      x: 8442,
      y: 10111
    },{
      NanogenreId: 6989,
      x: 8266,
      y: 7572
    },{
      NanogenreId: 6990,
      x: 8058,
      y: 7066
    },{
      NanogenreId: 6991,
      x: 7519,
      y: 6884
    },{
      NanogenreId: 6992,
      x: 8929,
      y: 9780
    },{
      NanogenreId: 6993,
      x: 10293,
      y: 10138
    },{
      NanogenreId: 6994,
      x: 4233,
      y: 12103
    },{
      NanogenreId: 6995,
      x: 6393,
      y: 7295
    },{
      NanogenreId: 6996,
      x: 8930,
      y: 5142
    },{
      NanogenreId: 6997,
      x: 5001,
      y: 9855
    },{
      NanogenreId: 6998,
      x: 7759,
      y: 5196
    },{
      NanogenreId: 6999,
      x: 8854,
      y: 9449
    },{
      NanogenreId: 7000,
      x: 8008,
      y: 4118
    },{
      NanogenreId: 7001,
      x: 8015,
      y: 8009
    },{
      NanogenreId: 7002,
      x: 9173,
      y: 4726
    },{
      NanogenreId: 7003,
      x: 4576,
      y: 9302
    },{
      NanogenreId: 7004,
      x: 8341,
      y: 5888
    },{
      NanogenreId: 7005,
      x: 9442,
      y: 8473
    },{
      NanogenreId: 7006,
      x: 7998,
      y: 6963
    },{
      NanogenreId: 7007,
      x: 8184,
      y: 5007
    },{
      NanogenreId: 7008,
      x: 9243,
      y: 5097
    },{
      NanogenreId: 7009,
      x: 10024,
      y: 10581
    },{
      NanogenreId: 7010,
      x: 8294,
      y: 11425
    },{
      NanogenreId: 7011,
      x: 11408,
      y: 7930
    },{
      NanogenreId: 7012,
      x: 5338,
      y: 8978
    },{
      NanogenreId: 7013,
      x: 9797,
      y: 10646
    },{
      NanogenreId: 7014,
      x: 7843,
      y: 9436
    },{
      NanogenreId: 7015,
      x: 7179,
      y: 6783
    },{
      NanogenreId: 7016,
      x: 5256,
      y: 9563
    },{
      NanogenreId: 7017,
      x: 6756,
      y: 8042
    },{
      NanogenreId: 7018,
      x: 10201,
      y: 4806
    },{
      NanogenreId: 7019,
      x: 9451,
      y: 8177
    },{
      NanogenreId: 7020,
      x: 7476,
      y: 9735
    },{
      NanogenreId: 7021,
      x: 7761,
      y: 9640
    },{
      NanogenreId: 7022,
      x: 4450,
      y: 9343
    },{
      NanogenreId: 7023,
      x: 3311,
      y: 10053
    },{
      NanogenreId: 7024,
      x: 9812,
      y: 5415
    },{
      NanogenreId: 7025,
      x: 6486,
      y: 6083
    },{
      NanogenreId: 7026,
      x: 9268,
      y: 5286
    },{
      NanogenreId: 7027,
      x: 8094,
      y: 10128
    },{
      NanogenreId: 7028,
      x: 8957,
      y: 8572
    },{
      NanogenreId: 7029,
      x: 7470,
      y: 10500
    },{
      NanogenreId: 7030,
      x: 7157,
      y: 6757
    },{
      NanogenreId: 7031,
      x: 10211,
      y: 10486
    },{
      NanogenreId: 7032,
      x: 9812,
      y: 4558
    },{
      NanogenreId: 7033,
      x: 4964,
      y: 10896
    },{
      NanogenreId: 7034,
      x: 10740,
      y: 10396
    },{
      NanogenreId: 7035,
      x: 6952,
      y: 5309
    },{
      NanogenreId: 7036,
      x: 10238,
      y: 9339
    },{
      NanogenreId: 7037,
      x: 6732,
      y: 4368
    },{
      NanogenreId: 7038,
      x: 7029,
      y: 7005
    },{
      NanogenreId: 7039,
      x: 8108,
      y: 7503
    },{
      NanogenreId: 7040,
      x: 9453,
      y: 4695
    },{
      NanogenreId: 7041,
      x: 8894,
      y: 4634
    },{
      NanogenreId: 7042,
      x: 3448,
      y: 6267
    },{
      NanogenreId: 7043,
      x: 8218,
      y: 11325
    },{
      NanogenreId: 7044,
      x: 8003,
      y: 9991
    },{
      NanogenreId: 7045,
      x: 9019,
      y: 4405
    },{
      NanogenreId: 7046,
      x: 9984,
      y: 6050
    },{
      NanogenreId: 7047,
      x: 9590,
      y: 9474
    },{
      NanogenreId: 7048,
      x: 8335,
      y: 5377
    },{
      NanogenreId: 7049,
      x: 9722,
      y: 4740
    },{
      NanogenreId: 7050,
      x: 8137,
      y: 10174
    },{
      NanogenreId: 7051,
      x: 8306,
      y: 7661
    },{
      NanogenreId: 7052,
      x: 9983,
      y: 11258
    },{
      NanogenreId: 7053,
      x: 8696,
      y: 4643
    },{
      NanogenreId: 7054,
      x: 9321,
      y: 9380
    },{
      NanogenreId: 7055,
      x: 9229,
      y: 7851
    },{
      NanogenreId: 7056,
      x: 10876,
      y: 10269
    },{
      NanogenreId: 7057,
      x: 6153,
      y: 7256
    },{
      NanogenreId: 7058,
      x: 4909,
      y: 8795
    },{
      NanogenreId: 7059,
      x: 7270,
      y: 5811
    },{
      NanogenreId: 7060,
      x: 6751,
      y: 9462
    },{
      NanogenreId: 7061,
      x: 8616,
      y: 7931
    },{
      NanogenreId: 7062,
      x: 6986,
      y: 6489
    },{
      NanogenreId: 7063,
      x: 7168,
      y: 5624
    },{
      NanogenreId: 7064,
      x: 9935,
      y: 4531
    },{
      NanogenreId: 7065,
      x: 9916,
      y: 5305
    },{
      NanogenreId: 7066,
      x: 8666,
      y: 5214
    },{
      NanogenreId: 7067,
      x: 10004,
      y: 5073
    },{
      NanogenreId: 7068,
      x: 8075,
      y: 10512
    },{
      NanogenreId: 7069,
      x: 7853,
      y: 5941
    },{
      NanogenreId: 7070,
      x: 9776,
      y: 9396
    },{
      NanogenreId: 7071,
      x: 6730,
      y: 8552
    },{
      NanogenreId: 7072,
      x: 7213,
      y: 8897
    },{
      NanogenreId: 7073,
      x: 9656,
      y: 6610
    },{
      NanogenreId: 7074,
      x: 5881,
      y: 10162
    },{
      NanogenreId: 7075,
      x: 7337,
      y: 7595
    },{
      NanogenreId: 7076,
      x: 6934,
      y: 7661
    },{
      NanogenreId: 7077,
      x: 9739,
      y: 5282
    },{
      NanogenreId: 7078,
      x: 9697,
      y: 5132
    },{
      NanogenreId: 7079,
      x: 11404,
      y: 10156
    },{
      NanogenreId: 7080,
      x: 8218,
      y: 5170
    },{
      NanogenreId: 7081,
      x: 7072,
      y: 5228
    },{
      NanogenreId: 7082,
      x: 10935,
      y: 9672
    },{
      NanogenreId: 7083,
      x: 11937,
      y: 8161
    },{
      NanogenreId: 7084,
      x: 7724,
      y: 6746
    },{
      NanogenreId: 7085,
      x: 7557,
      y: 4700
    },{
      NanogenreId: 7086,
      x: 8811,
      y: 9886
    },{
      NanogenreId: 7087,
      x: 9625,
      y: 5089
    },{
      NanogenreId: 7088,
      x: 10678,
      y: 8472
    },{
      NanogenreId: 7089,
      x: 5318,
      y: 7460
    },{
      NanogenreId: 7090,
      x: 6314,
      y: 8152
    },{
      NanogenreId: 7091,
      x: 7925,
      y: 5782
    },{
      NanogenreId: 7092,
      x: 5776,
      y: 9287
    },{
      NanogenreId: 7093,
      x: 7324,
      y: 6295
    },{
      NanogenreId: 7094,
      x: 6685,
      y: 9769
    },{
      NanogenreId: 7095,
      x: 6952,
      y: 6669
    },{
      NanogenreId: 7096,
      x: 9038,
      y: 4857
    },{
      NanogenreId: 7097,
      x: 9853,
      y: 9538
    },{
      NanogenreId: 7098,
      x: 7344,
      y: 10605
    },{
      NanogenreId: 7099,
      x: 6856,
      y: 8732
    },{
      NanogenreId: 7100,
      x: 7381,
      y: 6226
    },{
      NanogenreId: 7101,
      x: 6570,
      y: 8740
    },{
      NanogenreId: 7102,
      x: 9837,
      y: 4607
    },{
      NanogenreId: 7103,
      x: 7389,
      y: 5102
    },{
      NanogenreId: 7104,
      x: 7439,
      y: 10904
    },{
      NanogenreId: 7105,
      x: 9621,
      y: 8456
    },{
      NanogenreId: 7106,
      x: 9547,
      y: 4608
    },{
      NanogenreId: 7107,
      x: 8159,
      y: 6269
    },{
      NanogenreId: 7108,
      x: 6593,
      y: 10054
    },{
      NanogenreId: 7109,
      x: 9322,
      y: 2009
    },{
      NanogenreId: 7110,
      x: 9609,
      y: 9984
    },{
      NanogenreId: 7111,
      x: 10869,
      y: 6523
    },{
      NanogenreId: 7112,
      x: 9782,
      y: 5347
    },{
      NanogenreId: 7113,
      x: 10233,
      y: 9094
    },{
      NanogenreId: 7114,
      x: 4252,
      y: 11893
    },{
      NanogenreId: 7115,
      x: 9823,
      y: 9728
    },{
      NanogenreId: 7116,
      x: 9608,
      y: 6180
    },{
      NanogenreId: 7117,
      x: 9381,
      y: 5234
    },{
      NanogenreId: 7118,
      x: 7391,
      y: 9368
    },{
      NanogenreId: 7119,
      x: 6877,
      y: 7710
    },{
      NanogenreId: 7120,
      x: 12226,
      y: 7144
    },{
      NanogenreId: 7121,
      x: 6378,
      y: 8526
    },{
      NanogenreId: 7122,
      x: 6324,
      y: 6484
    },{
      NanogenreId: 7123,
      x: 11278,
      y: 7890
    },{
      NanogenreId: 7124,
      x: 8811,
      y: 10119
    },{
      NanogenreId: 7125,
      x: 7156,
      y: 6852
    },{
      NanogenreId: 7126,
      x: 10122,
      y: 9648
    },{
      NanogenreId: 7127,
      x: 9133,
      y: 6429
    },{
      NanogenreId: 7128,
      x: 7426,
      y: 7832
    },{
      NanogenreId: 7129,
      x: 6430,
      y: 6044
    },{
      NanogenreId: 7130,
      x: 10646,
      y: 9425
    },{
      NanogenreId: 7131,
      x: 6694,
      y: 8696
    },{
      NanogenreId: 7132,
      x: 9109,
      y: 7815
    },{
      NanogenreId: 7133,
      x: 8144,
      y: 4846
    },{
      NanogenreId: 7134,
      x: 7222,
      y: 7815
    },{
      NanogenreId: 7135,
      x: 7469,
      y: 10541
    },{
      NanogenreId: 7136,
      x: 11180,
      y: 7426
    },{
      NanogenreId: 7137,
      x: 9935,
      y: 5273
    },{
      NanogenreId: 7138,
      x: 6680,
      y: 7771
    },{
      NanogenreId: 7139,
      x: 7112,
      y: 7732
    },{
      NanogenreId: 7140,
      x: 8410,
      y: 9736
    },{
      NanogenreId: 7141,
      x: 8744,
      y: 6456
    },{
      NanogenreId: 7142,
      x: 7690,
      y: 9177
    },{
      NanogenreId: 7143,
      x: 9646,
      y: 4847
    },{
      NanogenreId: 7144,
      x: 6205,
      y: 6283
    },{
      NanogenreId: 7145,
      x: 10499,
      y: 10357
    },{
      NanogenreId: 7146,
      x: 7167,
      y: 7278
    },{
      NanogenreId: 7147,
      x: 7589,
      y: 8340
    },{
      NanogenreId: 7148,
      x: 7497,
      y: 3532
    },{
      NanogenreId: 7149,
      x: 4684,
      y: 9399
    },{
      NanogenreId: 7150,
      x: 9099,
      y: 7200
    },{
      NanogenreId: 7151,
      x: 8441,
      y: 5809
    },{
      NanogenreId: 7152,
      x: 9915,
      y: 6900
    },{
      NanogenreId: 7153,
      x: 10019,
      y: 4655
    },{
      NanogenreId: 7154,
      x: 10067,
      y: 2368
    },{
      NanogenreId: 7155,
      x: 6977,
      y: 6371
    },{
      NanogenreId: 7156,
      x: 6989,
      y: 7131
    },{
      NanogenreId: 7157,
      x: 9971,
      y: 5211
    },{
      NanogenreId: 7158,
      x: 6718,
      y: 6493
    },{
      NanogenreId: 7159,
      x: 10786,
      y: 10995
    },{
      NanogenreId: 7160,
      x: 7996,
      y: 8496
    },{
      NanogenreId: 7161,
      x: 3672,
      y: 9617
    },{
      NanogenreId: 7162,
      x: 8600,
      y: 8733
    },{
      NanogenreId: 7163,
      x: 6849,
      y: 7513
    },{
      NanogenreId: 7164,
      x: 8202,
      y: 5813
    },{
      NanogenreId: 7165,
      x: 6066,
      y: 7819
    },{
      NanogenreId: 7166,
      x: 7213,
      y: 7946
    },{
      NanogenreId: 7167,
      x: 7661,
      y: 8980
    },{
      NanogenreId: 7168,
      x: 5757,
      y: 6833
    },{
      NanogenreId: 7169,
      x: 6788,
      y: 6222
    },{
      NanogenreId: 7170,
      x: 8938,
      y: 5339
    },{
      NanogenreId: 7171,
      x: 9489,
      y: 4774
    },{
      NanogenreId: 7172,
      x: 10548,
      y: 10000
    },{
      NanogenreId: 7173,
      x: 8769,
      y: 5037
    },{
      NanogenreId: 7174,
      x: 10066,
      y: 1972
    },{
      NanogenreId: 7175,
      x: 7614,
      y: 10185
    },{
      NanogenreId: 7176,
      x: 9754,
      y: 11039
    },{
      NanogenreId: 7177,
      x: 9171,
      y: 11728
    },{
      NanogenreId: 7178,
      x: 4853,
      y: 10043
    },{
      NanogenreId: 7179,
      x: 7958,
      y: 9096
    },{
      NanogenreId: 7180,
      x: 5498,
      y: 6514
    },{
      NanogenreId: 7181,
      x: 10083,
      y: 10552
    },{
      NanogenreId: 7182,
      x: 5849,
      y: 8722
    },{
      NanogenreId: 7183,
      x: 5604,
      y: 8286
    },{
      NanogenreId: 7184,
      x: 6454,
      y: 7663
    },{
      NanogenreId: 7185,
      x: 9636,
      y: 5144
    },{
      NanogenreId: 7186,
      x: 11784,
      y: 10343
    },{
      NanogenreId: 7187,
      x: 9288,
      y: 5568
    },{
      NanogenreId: 7188,
      x: 9468,
      y: 4984
    },{
      NanogenreId: 7189,
      x: 8385,
      y: 6042
    },{
      NanogenreId: 7190,
      x: 7389,
      y: 6083
    },{
      NanogenreId: 7191,
      x: 8474,
      y: 5983
    },{
      NanogenreId: 7192,
      x: 8639,
      y: 9376
    },{
      NanogenreId: 7193,
      x: 9271,
      y: 9355
    },{
      NanogenreId: 7194,
      x: 8700,
      y: 7477
    },{
      NanogenreId: 7195,
      x: 11997,
      y: 9613
    },{
      NanogenreId: 7196,
      x: 7410,
      y: 7999
    },{
      NanogenreId: 7197,
      x: 7823,
      y: 10646
    },{
      NanogenreId: 7198,
      x: 9649,
      y: 4998
    },{
      NanogenreId: 7199,
      x: 7391,
      y: 8384
    },{
      NanogenreId: 7200,
      x: 12593,
      y: 6979
    },{
      NanogenreId: 7201,
      x: 9370,
      y: 6936
    },{
      NanogenreId: 7202,
      x: 11166,
      y: 9306
    },{
      NanogenreId: 7203,
      x: 9729,
      y: 10825
    },{
      NanogenreId: 7204,
      x: 8027,
      y: 9654
    },{
      NanogenreId: 7205,
      x: 10167,
      y: 6618
    },{
      NanogenreId: 7206,
      x: 6162,
      y: 7592
    },{
      NanogenreId: 7207,
      x: 9318,
      y: 7973
    },{
      NanogenreId: 7208,
      x: 6145,
      y: 6631
    },{
      NanogenreId: 7209,
      x: 7643,
      y: 6314
    },{
      NanogenreId: 7210,
      x: 10021,
      y: 4949
    },{
      NanogenreId: 7211,
      x: 5902,
      y: 9243
    },{
      NanogenreId: 7212,
      x: 9741,
      y: 11434
    },{
      NanogenreId: 7213,
      x: 7439,
      y: 8461
    },{
      NanogenreId: 7214,
      x: 9292,
      y: 5057
    },{
      NanogenreId: 7215,
      x: 10472,
      y: 9213
    },{
      NanogenreId: 7216,
      x: 9146,
      y: 7462
    },{
      NanogenreId: 7217,
      x: 9664,
      y: 5163
    },{
      NanogenreId: 7218,
      x: 4770,
      y: 8052
    },{
      NanogenreId: 7219,
      x: 7308,
      y: 9264
    },{
      NanogenreId: 7220,
      x: 7668,
      y: 8065
    },{
      NanogenreId: 7221,
      x: 9887,
      y: 5658
    },{
      NanogenreId: 7222,
      x: 9206,
      y: 8790
    },{
      NanogenreId: 7223,
      x: 9270,
      y: 6459
    },{
      NanogenreId: 7224,
      x: 5354,
      y: 9381
    },{
      NanogenreId: 7225,
      x: 9541,
      y: 7458
    },{
      NanogenreId: 7226,
      x: 6094,
      y: 10673
    },{
      NanogenreId: 7227,
      x: 5814,
      y: 6430
    },{
      NanogenreId: 7228,
      x: 11390,
      y: 10532
    },{
      NanogenreId: 7229,
      x: 9214,
      y: 9248
    },{
      NanogenreId: 7230,
      x: 7945,
      y: 5892
    },{
      NanogenreId: 7231,
      x: 8001,
      y: 5562
    },{
      NanogenreId: 7232,
      x: 8334,
      y: 6219
    },{
      NanogenreId: 7233,
      x: 5339,
      y: 5949
    },{
      NanogenreId: 7234,
      x: 8861,
      y: 5688
    },{
      NanogenreId: 7235,
      x: 9454,
      y: 5247
    },{
      NanogenreId: 7236,
      x: 6597,
      y: 7401
    },{
      NanogenreId: 7237,
      x: 5822,
      y: 7031
    },{
      NanogenreId: 7238,
      x: 8929,
      y: 8123
    },{
      NanogenreId: 7239,
      x: 9804,
      y: 9452
    },{
      NanogenreId: 7240,
      x: 8455,
      y: 5040
    },{
      NanogenreId: 7241,
      x: 9762,
      y: 8302
    },{
      NanogenreId: 7242,
      x: 6600,
      y: 4627
    },{
      NanogenreId: 7243,
      x: 7365,
      y: 6404
    },{
      NanogenreId: 7244,
      x: 8095,
      y: 8273
    },{
      NanogenreId: 7245,
      x: 8206,
      y: 8692
    },{
      NanogenreId: 7246,
      x: 9167,
      y: 7709
    },{
      NanogenreId: 7247,
      x: 7055,
      y: 6241
    },{
      NanogenreId: 7248,
      x: 6827,
      y: 7559
    },{
      NanogenreId: 7249,
      x: 8847,
      y: 5572
    },{
      NanogenreId: 7250,
      x: 8244,
      y: 9512
    },{
      NanogenreId: 7251,
      x: 10460,
      y: 9867
    },{
      NanogenreId: 7252,
      x: 8078,
      y: 9256
    },{
      NanogenreId: 7253,
      x: 9231,
      y: 9467
    },{
      NanogenreId: 7254,
      x: 6510,
      y: 6498
    },{
      NanogenreId: 7255,
      x: 5808,
      y: 6579
    },{
      NanogenreId: 7256,
      x: 7433,
      y: 4273
    },{
      NanogenreId: 7257,
      x: 8470,
      y: 6752
    },{
      NanogenreId: 7258,
      x: 9235,
      y: 4862
    },{
      NanogenreId: 7259,
      x: 8516,
      y: 5581
    },{
      NanogenreId: 7260,
      x: 9330,
      y: 8722
    },{
      NanogenreId: 7261,
      x: 5683,
      y: 6103
    },{
      NanogenreId: 7262,
      x: 5802,
      y: 11692
    },{
      NanogenreId: 7263,
      x: 6567,
      y: 11645
    },{
      NanogenreId: 7264,
      x: 3255,
      y: 10481
    },{
      NanogenreId: 7265,
      x: 7079,
      y: 6185
    },{
      NanogenreId: 7266,
      x: 6636,
      y: 6862
    },{
      NanogenreId: 7267,
      x: 4739,
      y: 7805
    },{
      NanogenreId: 7268,
      x: 7956,
      y: 9171
    },{
      NanogenreId: 7269,
      x: 10817,
      y: 10442
    },{
      NanogenreId: 7270,
      x: 9820,
      y: 4865
    },{
      NanogenreId: 7271,
      x: 10272,
      y: 10757
    },{
      NanogenreId: 7272,
      x: 9174,
      y: 7182
    },{
      NanogenreId: 7273,
      x: 6172,
      y: 9193
    },{
      NanogenreId: 7274,
      x: 5957,
      y: 6934
    },{
      NanogenreId: 7275,
      x: 7290,
      y: 8479
    },{
      NanogenreId: 7276,
      x: 5209,
      y: 5438
    },{
      NanogenreId: 7277,
      x: 5353,
      y: 10686
    },{
      NanogenreId: 7278,
      x: 7850,
      y: 6047
    },{
      NanogenreId: 7279,
      x: 10471,
      y: 8104
    },{
      NanogenreId: 7280,
      x: 9492,
      y: 10320
    },{
      NanogenreId: 7281,
      x: 8474,
      y: 8988
    },{
      NanogenreId: 7282,
      x: 11431,
      y: 9566
    },{
      NanogenreId: 7283,
      x: 5547,
      y: 9683
    },{
      NanogenreId: 7284,
      x: 10488,
      y: 9697
    },{
      NanogenreId: 7285,
      x: 7109,
      y: 9118
    },{
      NanogenreId: 7286,
      x: 8047,
      y: 7845
    },{
      NanogenreId: 7287,
      x: 7249,
      y: 6020
    },{
      NanogenreId: 7288,
      x: 9381,
      y: 5440
    },{
      NanogenreId: 7289,
      x: 6880,
      y: 7835
    },{
      NanogenreId: 7290,
      x: 6542,
      y: 6895
    },{
      NanogenreId: 7291,
      x: 8553,
      y: 9558
    },{
      NanogenreId: 7292,
      x: 9346,
      y: 5403
    },{
      NanogenreId: 7293,
      x: 7552,
      y: 8684
    },{
      NanogenreId: 7294,
      x: 11235,
      y: 2550
    },{
      NanogenreId: 7295,
      x: 5216,
      y: 8068
    },{
      NanogenreId: 7296,
      x: 8235,
      y: 9665
    },{
      NanogenreId: 7297,
      x: 7287,
      y: 8301
    },{
      NanogenreId: 7298,
      x: 9370,
      y: 6350
    },{
      NanogenreId: 7299,
      x: 11071,
      y: 10459
    },{
      NanogenreId: 7300,
      x: 8791,
      y: 2434
    },{
      NanogenreId: 7301,
      x: 7803,
      y: 6691
    },{
      NanogenreId: 7302,
      x: 9876,
      y: 5409
    },{
      NanogenreId: 7303,
      x: 8469,
      y: 9633
    },{
      NanogenreId: 7304,
      x: 9773,
      y: 5255
    },{
      NanogenreId: 7305,
      x: 9109,
      y: 4282
    },{
      NanogenreId: 7306,
      x: 5963,
      y: 10082
    },{
      NanogenreId: 7307,
      x: 9330,
      y: 9947
    },{
      NanogenreId: 7308,
      x: 7860,
      y: 8928
    },{
      NanogenreId: 7309,
      x: 8213,
      y: 5505
    },{
      NanogenreId: 7310,
      x: 10183,
      y: 8118
    },{
      NanogenreId: 7311,
      x: 5021,
      y: 8012
    },{
      NanogenreId: 7312,
      x: 9870,
      y: 5719
    },{
      NanogenreId: 7313,
      x: 9185,
      y: 4099
    },{
      NanogenreId: 7314,
      x: 4786,
      y: 8732
    },{
      NanogenreId: 7315,
      x: 3362,
      y: 9917
    },{
      NanogenreId: 7316,
      x: 9162,
      y: 5482
    },{
      NanogenreId: 7317,
      x: 10082,
      y: 9360
    },{
      NanogenreId: 7318,
      x: 7917,
      y: 9566
    },{
      NanogenreId: 7319,
      x: 8309,
      y: 8354
    },{
      NanogenreId: 7320,
      x: 9569,
      y: 10052
    },{
      NanogenreId: 7321,
      x: 8447,
      y: 6920
    },{
      NanogenreId: 7322,
      x: 9697,
      y: 4988
    },{
      NanogenreId: 7323,
      x: 4817,
      y: 8816
    },{
      NanogenreId: 7324,
      x: 7896,
      y: 5706
    },{
      NanogenreId: 7325,
      x: 9774,
      y: 4943
    },{
      NanogenreId: 7326,
      x: 10314,
      y: 6705
    },{
      NanogenreId: 7327,
      x: 4224,
      y: 7727
    },{
      NanogenreId: 7328,
      x: 9820,
      y: 8610
    },{
      NanogenreId: 7329,
      x: 8764,
      y: 5167
    },{
      NanogenreId: 7330,
      x: 4187,
      y: 9552
    },{
      NanogenreId: 7331,
      x: 8378,
      y: 8946
    },{
      NanogenreId: 7332,
      x: 9577,
      y: 6190
    },{
      NanogenreId: 7333,
      x: 8277,
      y: 10826
    },{
      NanogenreId: 7334,
      x: 5901,
      y: 4409
    },{
      NanogenreId: 7335,
      x: 9769,
      y: 4996
    },{
      NanogenreId: 7336,
      x: 7029,
      y: 5684
    },{
      NanogenreId: 7337,
      x: 6131,
      y: 9038
    },{
      NanogenreId: 7338,
      x: 5722,
      y: 7949
    },{
      NanogenreId: 7339,
      x: 8900,
      y: 9024
    },{
      NanogenreId: 7340,
      x: 6547,
      y: 7379
    },{
      NanogenreId: 7341,
      x: 10557,
      y: 9288
    },{
      NanogenreId: 7342,
      x: 8190,
      y: 5475
    },{
      NanogenreId: 7343,
      x: 8630,
      y: 5828
    },{
      NanogenreId: 7344,
      x: 8063,
      y: 8733
    },{
      NanogenreId: 7345,
      x: 3580,
      y: 10133
    },{
      NanogenreId: 7346,
      x: 5117,
      y: 6685
    },{
      NanogenreId: 7347,
      x: 7336,
      y: 6773
    },{
      NanogenreId: 7348,
      x: 8999,
      y: 6339
    },{
      NanogenreId: 7349,
      x: 7493,
      y: 7695
    },{
      NanogenreId: 7350,
      x: 4171,
      y: 8863
    },{
      NanogenreId: 7351,
      x: 9630,
      y: 9645
    },{
      NanogenreId: 7352,
      x: 8358,
      y: 7346
    },{
      NanogenreId: 7353,
      x: 6604,
      y: 6288
    },{
      NanogenreId: 7354,
      x: 7012,
      y: 4624
    },{
      NanogenreId: 7355,
      x: 9158,
      y: 4974
    },{
      NanogenreId: 7356,
      x: 6214,
      y: 7642
    },{
      NanogenreId: 7357,
      x: 9145,
      y: 9056
    },{
      NanogenreId: 7358,
      x: 9178,
      y: 4650
    },{
      NanogenreId: 7359,
      x: 8246,
      y: 6180
    },{
      NanogenreId: 7360,
      x: 7113,
      y: 6922
    },{
      NanogenreId: 7361,
      x: 8735,
      y: 9306
    },{
      NanogenreId: 7362,
      x: 9685,
      y: 4887
    },{
      NanogenreId: 7363,
      x: 9258,
      y: 4633
    },{
      NanogenreId: 7364,
      x: 7262,
      y: 8391
    },{
      NanogenreId: 7365,
      x: 4259,
      y: 8560
    },{
      NanogenreId: 7366,
      x: 5942,
      y: 10636
    },{
      NanogenreId: 7367,
      x: 6049,
      y: 8387
    },{
      NanogenreId: 7368,
      x: 7422,
      y: 8814
    },{
      NanogenreId: 7369,
      x: 8233,
      y: 6843
    },{
      NanogenreId: 7370,
      x: 6214,
      y: 6374
    },{
      NanogenreId: 7371,
      x: 10263,
      y: 7135
    },{
      NanogenreId: 7372,
      x: 7787,
      y: 6395
    },{
      NanogenreId: 7373,
      x: 11015,
      y: 10480
    },{
      NanogenreId: 7374,
      x: 10253,
      y: 3136
    },{
      NanogenreId: 7375,
      x: 7633,
      y: 9748
    },{
      NanogenreId: 7376,
      x: 8578,
      y: 8418
    },{
      NanogenreId: 7377,
      x: 7406,
      y: 7327
    },{
      NanogenreId: 7378,
      x: 6760,
      y: 7803
    },{
      NanogenreId: 7379,
      x: 11456,
      y: 10343
    },{
      NanogenreId: 7380,
      x: 10596,
      y: 10625
    },{
      NanogenreId: 7381,
      x: 6602,
      y: 5512
    },{
      NanogenreId: 7382,
      x: 5894,
      y: 8920
    },{
      NanogenreId: 7383,
      x: 8902,
      y: 5495
    },{
      NanogenreId: 7384,
      x: 8453,
      y: 7178
    },{
      NanogenreId: 7385,
      x: 8482,
      y: 6994
    },{
      NanogenreId: 7386,
      x: 8235,
      y: 5723
    },{
      NanogenreId: 7387,
      x: 9313,
      y: 6883
    },{
      NanogenreId: 7388,
      x: 7526,
      y: 8750
    },{
      NanogenreId: 7389,
      x: 8069,
      y: 5599
    },{
      NanogenreId: 7390,
      x: 8060,
      y: 7412
    },{
      NanogenreId: 7391,
      x: 6773,
      y: 6792
    },{
      NanogenreId: 7392,
      x: 6144,
      y: 8253
    },{
      NanogenreId: 7393,
      x: 8945,
      y: 5392
    },{
      NanogenreId: 7394,
      x: 6876,
      y: 10592
    },{
      NanogenreId: 7395,
      x: 9161,
      y: 5172
    },{
      NanogenreId: 7396,
      x: 6392,
      y: 7209
    },{
      NanogenreId: 7397,
      x: 4459,
      y: 9661
    },{
      NanogenreId: 7398,
      x: 8434,
      y: 6089
    },{
      NanogenreId: 7399,
      x: 8731,
      y: 5265
    },{
      NanogenreId: 7400,
      x: 8540,
      y: 5928
    },{
      NanogenreId: 7401,
      x: 4443,
      y: 9861
    },{
      NanogenreId: 7402,
      x: 8891,
      y: 6759
    },{
      NanogenreId: 7403,
      x: 6121,
      y: 7660
    },{
      NanogenreId: 7404,
      x: 8802,
      y: 4238
    },{
      NanogenreId: 7405,
      x: 10121,
      y: 10325
    },{
      NanogenreId: 7406,
      x: 8872,
      y: 5052
    },{
      NanogenreId: 7407,
      x: 7819,
      y: 5406
    },{
      NanogenreId: 7408,
      x: 7225,
      y: 7477
    },{
      NanogenreId: 7409,
      x: 8749,
      y: 5111
    },{
      NanogenreId: 7410,
      x: 6501,
      y: 9713
    },{
      NanogenreId: 7411,
      x: 8135,
      y: 6629
    },{
      NanogenreId: 7412,
      x: 9974,
      y: 7712
    },{
      NanogenreId: 7413,
      x: 9375,
      y: 5028
    },{
      NanogenreId: 7414,
      x: 9256,
      y: 5795
    },{
      NanogenreId: 7415,
      x: 9881,
      y: 6656
    },{
      NanogenreId: 7416,
      x: 9057,
      y: 5004
    },{
      NanogenreId: 7417,
      x: 6154,
      y: 10459
    },{
      NanogenreId: 7418,
      x: 7523,
      y: 10485
    },{
      NanogenreId: 7419,
      x: 2550,
      y: 8728
    },{
      NanogenreId: 7420,
      x: 6251,
      y: 8406
    },{
      NanogenreId: 7421,
      x: 6676,
      y: 6573
    },{
      NanogenreId: 7422,
      x: 6723,
      y: 7399
    },{
      NanogenreId: 7423,
      x: 7106,
      y: 7532
    },{
      NanogenreId: 7424,
      x: 11045,
      y: 9532
    },{
      NanogenreId: 7425,
      x: 8335,
      y: 7133
    },{
      NanogenreId: 7426,
      x: 6721,
      y: 9325
    },{
      NanogenreId: 7427,
      x: 9685,
      y: 9637
    },{
      NanogenreId: 7428,
      x: 10043,
      y: 7225
    },{
      NanogenreId: 7429,
      x: 8216,
      y: 6039
    },{
      NanogenreId: 7430,
      x: 5699,
      y: 7420
    },{
      NanogenreId: 7431,
      x: 10716,
      y: 10422
    },{
      NanogenreId: 7432,
      x: 10480,
      y: 7366
    },{
      NanogenreId: 7433,
      x: 8476,
      y: 6209
    },{
      NanogenreId: 7434,
      x: 9747,
      y: 9355
    },{
      NanogenreId: 7435,
      x: 7151,
      y: 10237
    },{
      NanogenreId: 7436,
      x: 6257,
      y: 7936
    },{
      NanogenreId: 7437,
      x: 9374,
      y: 5646
    },{
      NanogenreId: 7438,
      x: 8968,
      y: 8473
    },{
      NanogenreId: 7439,
      x: 8232,
      y: 7672
    },{
      NanogenreId: 7440,
      x: 12247,
      y: 10255
    },{
      NanogenreId: 7441,
      x: 5677,
      y: 7753
    },{
      NanogenreId: 7442,
      x: 5252,
      y: 9030
    },{
      NanogenreId: 7443,
      x: 6564,
      y: 6632
    },{
      NanogenreId: 7444,
      x: 8150,
      y: 9044
    },{
      NanogenreId: 7445,
      x: 8349,
      y: 8326
    },{
      NanogenreId: 7446,
      x: 10091,
      y: 10021
    },{
      NanogenreId: 7447,
      x: 9560,
      y: 5569
    },{
      NanogenreId: 7448,
      x: 8908,
      y: 6356
    },{
      NanogenreId: 7449,
      x: 3129,
      y: 7339
    },{
      NanogenreId: 7450,
      x: 8191,
      y: 6655
    },{
      NanogenreId: 7451,
      x: 9258,
      y: 5332
    },{
      NanogenreId: 7452,
      x: 5316,
      y: 7042
    },{
      NanogenreId: 7453,
      x: 8353,
      y: 4711
    },{
      NanogenreId: 7454,
      x: 6461,
      y: 5492
    },{
      NanogenreId: 7455,
      x: 7509,
      y: 9948
    },{
      NanogenreId: 7456,
      x: 6442,
      y: 6481
    },{
      NanogenreId: 7457,
      x: 8901,
      y: 7380
    },{
      NanogenreId: 7458,
      x: 7112,
      y: 8101
    },{
      NanogenreId: 7459,
      x: 6230,
      y: 7062
    },{
      NanogenreId: 7460,
      x: 9181,
      y: 5299
    },{
      NanogenreId: 7461,
      x: 6116,
      y: 7417
    },{
      NanogenreId: 7462,
      x: 8569,
      y: 6405
    },{
      NanogenreId: 7463,
      x: 7872,
      y: 9056
    },{
      NanogenreId: 7464,
      x: 9607,
      y: 5387
    },{
      NanogenreId: 7465,
      x: 8529,
      y: 8390
    },{
      NanogenreId: 7466,
      x: 7818,
      y: 6104
    },{
      NanogenreId: 7467,
      x: 9904,
      y: 4899
    },{
      NanogenreId: 7468,
      x: 10496,
      y: 10068
    },{
      NanogenreId: 7469,
      x: 7217,
      y: 4772
    },{
      NanogenreId: 7470,
      x: 9977,
      y: 1915
    },{
      NanogenreId: 7471,
      x: 5465,
      y: 7768
    },{
      NanogenreId: 7472,
      x: 5441,
      y: 10012
    },{
      NanogenreId: 7473,
      x: 6152,
      y: 12110
    },{
      NanogenreId: 7474,
      x: 5964,
      y: 7454
    },{
      NanogenreId: 7475,
      x: 9521,
      y: 4716
    },{
      NanogenreId: 7476,
      x: 8217,
      y: 6362
    },{
      NanogenreId: 7477,
      x: 6841,
      y: 6623
    },{
      NanogenreId: 7478,
      x: 8582,
      y: 4596
    },{
      NanogenreId: 7479,
      x: 3836,
      y: 9107
    },{
      NanogenreId: 7480,
      x: 6356,
      y: 5960
    },{
      NanogenreId: 7481,
      x: 7986,
      y: 9011
    },{
      NanogenreId: 7482,
      x: 5631,
      y: 8381
    },{
      NanogenreId: 7483,
      x: 5640,
      y: 9552
    },{
      NanogenreId: 7484,
      x: 7696,
      y: 9903
    },{
      NanogenreId: 7485,
      x: 10749,
      y: 10449
    },{
      NanogenreId: 7486,
      x: 8376,
      y: 7242
    },{
      NanogenreId: 7487,
      x: 7889,
      y: 7835
    },{
      NanogenreId: 7488,
      x: 8262,
      y: 4005
    },{
      NanogenreId: 7489,
      x: 9763,
      y: 5712
    },{
      NanogenreId: 7490,
      x: 4895,
      y: 5638
    },{
      NanogenreId: 7491,
      x: 9237,
      y: 6152
    },{
      NanogenreId: 7492,
      x: 6677,
      y: 5539
    },{
      NanogenreId: 7493,
      x: 8935,
      y: 6639
    },{
      NanogenreId: 7494,
      x: 10176,
      y: 5114
    },{
      NanogenreId: 7495,
      x: 6360,
      y: 6266
    },{
      NanogenreId: 7496,
      x: 9932,
      y: 4838
    },{
      NanogenreId: 7497,
      x: 8364,
      y: 5658
    },{
      NanogenreId: 7498,
      x: 5922,
      y: 6226
    },{
      NanogenreId: 7499,
      x: 6380,
      y: 7488
    },{
      NanogenreId: 7500,
      x: 6374,
      y: 5362
    },{
      NanogenreId: 7501,
      x: 9279,
      y: 7294
    },{
      NanogenreId: 7502,
      x: 10942,
      y: 9890
    },{
      NanogenreId: 7503,
      x: 7534,
      y: 6163
    },{
      NanogenreId: 7504,
      x: 9295,
      y: 5208
    },{
      NanogenreId: 7505,
      x: 9769,
      y: 8505
    },{
      NanogenreId: 7506,
      x: 8585,
      y: 5352
    },{
      NanogenreId: 7507,
      x: 4164,
      y: 9820
    },{
      NanogenreId: 7508,
      x: 7872,
      y: 8287
    },{
      NanogenreId: 7509,
      x: 7361,
      y: 9206
    },{
      NanogenreId: 7510,
      x: 8069,
      y: 7552
    },{
      NanogenreId: 7511,
      x: 5616,
      y: 7526
    },{
      NanogenreId: 7512,
      x: 8001,
      y: 9561
    },{
      NanogenreId: 7513,
      x: 8582,
      y: 5391
    },{
      NanogenreId: 7514,
      x: 9662,
      y: 9317
    },{
      NanogenreId: 7515,
      x: 7370,
      y: 8186
    },{
      NanogenreId: 7516,
      x: 8562,
      y: 4927
    },{
      NanogenreId: 7517,
      x: 3707,
      y: 8822
    },{
      NanogenreId: 7518,
      x: 8510,
      y: 9459
    },{
      NanogenreId: 7519,
      x: 6083,
      y: 6954
    },{
      NanogenreId: 7520,
      x: 5750,
      y: 5906
    },{
      NanogenreId: 7521,
      x: 7366,
      y: 5432
    },{
      NanogenreId: 7522,
      x: 9528,
      y: 5141
    },{
      NanogenreId: 7523,
      x: 7491,
      y: 10032
    },{
      NanogenreId: 7524,
      x: 9735,
      y: 6939
    },{
      NanogenreId: 7525,
      x: 8340,
      y: 6662
    },{
      NanogenreId: 7526,
      x: 9603,
      y: 5114
    },{
      NanogenreId: 7527,
      x: 10213,
      y: 10350
    },{
      NanogenreId: 7528,
      x: 8245,
      y: 6971
    },{
      NanogenreId: 7529,
      x: 6492,
      y: 5603
    },{
      NanogenreId: 7530,
      x: 5422,
      y: 6528
    },{
      NanogenreId: 7531,
      x: 8790,
      y: 6808
    },{
      NanogenreId: 7532,
      x: 8765,
      y: 7405
    },{
      NanogenreId: 7533,
      x: 9872,
      y: 4789
    },{
      NanogenreId: 7534,
      x: 8170,
      y: 9727
    },{
      NanogenreId: 7535,
      x: 11000,
      y: 6159
    },{
      NanogenreId: 7536,
      x: 9714,
      y: 5730
    },{
      NanogenreId: 7537,
      x: 5315,
      y: 8459
    },{
      NanogenreId: 7538,
      x: 5863,
      y: 8238
    },{
      NanogenreId: 7539,
      x: 7649,
      y: 6908
    },{
      NanogenreId: 7540,
      x: 10434,
      y: 9546
    },{
      NanogenreId: 7541,
      x: 5925,
      y: 7326
    },{
      NanogenreId: 7542,
      x: 7213,
      y: 9241
    },{
      NanogenreId: 7543,
      x: 8159,
      y: 10575
    },{
      NanogenreId: 7544,
      x: 8748,
      y: 6262
    },{
      NanogenreId: 7545,
      x: 5591,
      y: 7036
    },{
      NanogenreId: 7546,
      x: 9821,
      y: 7296
    },{
      NanogenreId: 7547,
      x: 8346,
      y: 7718
    },{
      NanogenreId: 7548,
      x: 7963,
      y: 6637
    },{
      NanogenreId: 7549,
      x: 9915,
      y: 10893
    },{
      NanogenreId: 7550,
      x: 7394,
      y: 6276
    },{
      NanogenreId: 7551,
      x: 8266,
      y: 6422
    },{
      NanogenreId: 7552,
      x: 8075,
      y: 9993
    },{
      NanogenreId: 7553,
      x: 8481,
      y: 4132
    },{
      NanogenreId: 7554,
      x: 9308,
      y: 7475
    },{
      NanogenreId: 7555,
      x: 7989,
      y: 7179
    },{
      NanogenreId: 7556,
      x: 8199,
      y: 7585
    },{
      NanogenreId: 7557,
      x: 5241,
      y: 6805
    },{
      NanogenreId: 7558,
      x: 9168,
      y: 6080
    },{
      NanogenreId: 7559,
      x: 4927,
      y: 7528
    },{
      NanogenreId: 7560,
      x: 9660,
      y: 9979
    },{
      NanogenreId: 7561,
      x: 8190,
      y: 8479
    },{
      NanogenreId: 7562,
      x: 6374,
      y: 11621
    },{
      NanogenreId: 7563,
      x: 6312,
      y: 10078
    },{
      NanogenreId: 7564,
      x: 7266,
      y: 7031
    },{
      NanogenreId: 7565,
      x: 8561,
      y: 9807
    },{
      NanogenreId: 7566,
      x: 6322,
      y: 6424
    },{
      NanogenreId: 7567,
      x: 7259,
      y: 7898
    },{
      NanogenreId: 7568,
      x: 9901,
      y: 4780
    },{
      NanogenreId: 7569,
      x: 10975,
      y: 5972
    },{
      NanogenreId: 7570,
      x: 7616,
      y: 8239
    },{
      NanogenreId: 7571,
      x: 8861,
      y: 5414
    },{
      NanogenreId: 7572,
      x: 8923,
      y: 5587
    },{
      NanogenreId: 7573,
      x: 6877,
      y: 6029
    },{
      NanogenreId: 7574,
      x: 6895,
      y: 8099
    },{
      NanogenreId: 7575,
      x: 7035,
      y: 6164
    },{
      NanogenreId: 7576,
      x: 8858,
      y: 5468
    },{
      NanogenreId: 7577,
      x: 8919,
      y: 6574
    },{
      NanogenreId: 7578,
      x: 6717,
      y: 5829
    },{
      NanogenreId: 7579,
      x: 5622,
      y: 7438
    },{
      NanogenreId: 7580,
      x: 9336,
      y: 6191
    },{
      NanogenreId: 7581,
      x: 6952,
      y: 6620
    },{
      NanogenreId: 7582,
      x: 8011,
      y: 5978
    },{
      NanogenreId: 7583,
      x: 9900,
      y: 5514
    },{
      NanogenreId: 7584,
      x: 7137,
      y: 8431
    },{
      NanogenreId: 7585,
      x: 9571,
      y: 5431
    },{
      NanogenreId: 7586,
      x: 6718,
      y: 10373
    },{
      NanogenreId: 7587,
      x: 6196,
      y: 6945
    },{
      NanogenreId: 7588,
      x: 5749,
      y: 6715
    },{
      NanogenreId: 7589,
      x: 7534,
      y: 6767
    },{
      NanogenreId: 7590,
      x: 5464,
      y: 8950
    },{
      NanogenreId: 7591,
      x: 7559,
      y: 6959
    },{
      NanogenreId: 7592,
      x: 7928,
      y: 9704
    },{
      NanogenreId: 7593,
      x: 6285,
      y: 8003
    },{
      NanogenreId: 7594,
      x: 9171,
      y: 9121
    },{
      NanogenreId: 7595,
      x: 6668,
      y: 6003
    },{
      NanogenreId: 7596,
      x: 4223,
      y: 6711
    },{
      NanogenreId: 7597,
      x: 9733,
      y: 7671
    },{
      NanogenreId: 7598,
      x: 7884,
      y: 5610
    },{
      NanogenreId: 7599,
      x: 8195,
      y: 9211
    },{
      NanogenreId: 7600,
      x: 6902,
      y: 8405
    },{
      NanogenreId: 7601,
      x: 9789,
      y: 5088
    },{
      NanogenreId: 7602,
      x: 7380,
      y: 5752
    },{
      NanogenreId: 7603,
      x: 9554,
      y: 5686
    },{
      NanogenreId: 7604,
      x: 4902,
      y: 8146
    },{
      NanogenreId: 7605,
      x: 9449,
      y: 5474
    },{
      NanogenreId: 7606,
      x: 8241,
      y: 6491
    },{
      NanogenreId: 7607,
      x: 6790,
      y: 6284
    },{
      NanogenreId: 7608,
      x: 8731,
      y: 9485
    },{
      NanogenreId: 7609,
      x: 10851,
      y: 9718
    },{
      NanogenreId: 7610,
      x: 8634,
      y: 7136
    },{
      NanogenreId: 7611,
      x: 9007,
      y: 4742
    },{
      NanogenreId: 7612,
      x: 6411,
      y: 5891
    },{
      NanogenreId: 7613,
      x: 9888,
      y: 8222
    },{
      NanogenreId: 7614,
      x: 9757,
      y: 5190
    },{
      NanogenreId: 7615,
      x: 8195,
      y: 9906
    },{
      NanogenreId: 7616,
      x: 7469,
      y: 5736
    },{
      NanogenreId: 7617,
      x: 9121,
      y: 6308
    },{
      NanogenreId: 7618,
      x: 6385,
      y: 7009
    },{
      NanogenreId: 7619,
      x: 8882,
      y: 8496
    },{
      NanogenreId: 7620,
      x: 8889,
      y: 5595
    },{
      NanogenreId: 7621,
      x: 5688,
      y: 7219
    },{
      NanogenreId: 7622,
      x: 11366,
      y: 8187
    },{
      NanogenreId: 7623,
      x: 7267,
      y: 8653
    },{
      NanogenreId: 7624,
      x: 5725,
      y: 6649
    },{
      NanogenreId: 7625,
      x: 6239,
      y: 8071
    },{
      NanogenreId: 7626,
      x: 7332,
      y: 7146
    },{
      NanogenreId: 7627,
      x: 10411,
      y: 9673
    },{
      NanogenreId: 7628,
      x: 6558,
      y: 7163
    },{
      NanogenreId: 7629,
      x: 6938,
      y: 7980
    },{
      NanogenreId: 7630,
      x: 8585,
      y: 5624
    },{
      NanogenreId: 7631,
      x: 6135,
      y: 6869
    },{
      NanogenreId: 7632,
      x: 5745,
      y: 7550
    },{
      NanogenreId: 7633,
      x: 9003,
      y: 5880
    },{
      NanogenreId: 7634,
      x: 9864,
      y: 10240
    },{
      NanogenreId: 7635,
      x: 9578,
      y: 4787
    },{
      NanogenreId: 7636,
      x: 8153,
      y: 4158
    },{
      NanogenreId: 7637,
      x: 9684,
      y: 5893
    },{
      NanogenreId: 7638,
      x: 6171,
      y: 9714
    },{
      NanogenreId: 7639,
      x: 7063,
      y: 6846
    },{
      NanogenreId: 7640,
      x: 8135,
      y: 5352
    },{
      NanogenreId: 7641,
      x: 4842,
      y: 7341
    },{
      NanogenreId: 7642,
      x: 7720,
      y: 8992
    },{
      NanogenreId: 7643,
      x: 5294,
      y: 10344
    },{
      NanogenreId: 7644,
      x: 11037,
      y: 6170
    },{
      NanogenreId: 7645,
      x: 8359,
      y: 6834
    },{
      NanogenreId: 7646,
      x: 8696,
      y: 9342
    },{
      NanogenreId: 7647,
      x: 9634,
      y: 10471
    },{
      NanogenreId: 7648,
      x: 4802,
      y: 9324
    },{
      NanogenreId: 7649,
      x: 8420,
      y: 7411
    },{
      NanogenreId: 7650,
      x: 9884,
      y: 9626
    },{
      NanogenreId: 7651,
      x: 8287,
      y: 5861
    },{
      NanogenreId: 7652,
      x: 5270,
      y: 8667
    },{
      NanogenreId: 7653,
      x: 8968,
      y: 10321
    },{
      NanogenreId: 7654,
      x: 5445,
      y: 8525
    },{
      NanogenreId: 7655,
      x: 7488,
      y: 8316
    },{
      NanogenreId: 7656,
      x: 11331,
      y: 7154
    },{
      NanogenreId: 7657,
      x: 6429,
      y: 6787
    },{
      NanogenreId: 7658,
      x: 6097,
      y: 7186
    },{
      NanogenreId: 7659,
      x: 5497,
      y: 6839
    },{
      NanogenreId: 7660,
      x: 9110,
      y: 7104
    },{
      NanogenreId: 7661,
      x: 8599,
      y: 5555
    },{
      NanogenreId: 7662,
      x: 7844,
      y: 9524
    },{
      NanogenreId: 7663,
      x: 6405,
      y: 10400
    },{
      NanogenreId: 7664,
      x: 6384,
      y: 7091
    },{
      NanogenreId: 7665,
      x: 8947,
      y: 5000
    },{
      NanogenreId: 7666,
      x: 6866,
      y: 7279
    },{
      NanogenreId: 7667,
      x: 7416,
      y: 8528
    },{
      NanogenreId: 7668,
      x: 6825,
      y: 6852
    },{
      NanogenreId: 7669,
      x: 8716,
      y: 10156
    },{
      NanogenreId: 7670,
      x: 7795,
      y: 8166
    },{
      NanogenreId: 7671,
      x: 7756,
      y: 6485
    },{
      NanogenreId: 7672,
      x: 4777,
      y: 6215
    },{
      NanogenreId: 7673,
      x: 7153,
      y: 9091
    },{
      NanogenreId: 7674,
      x: 9609,
      y: 4923
    },{
      NanogenreId: 7675,
      x: 8968,
      y: 5820
    },{
      NanogenreId: 7676,
      x: 7099,
      y: 6271
    },{
      NanogenreId: 7677,
      x: 4664,
      y: 8655
    },{
      NanogenreId: 7678,
      x: 6881,
      y: 3878
    },{
      NanogenreId: 7679,
      x: 5792,
      y: 10661
    },{
      NanogenreId: 7680,
      x: 7297,
      y: 10545
    },{
      NanogenreId: 7681,
      x: 9380,
      y: 8572
    },{
      NanogenreId: 7682,
      x: 8076,
      y: 2856
    },{
      NanogenreId: 7683,
      x: 7890,
      y: 8821
    },{
      NanogenreId: 7684,
      x: 8592,
      y: 8652
    },{
      NanogenreId: 7685,
      x: 9587,
      y: 5326
    },{
      NanogenreId: 7686,
      x: 9519,
      y: 4883
    },{
      NanogenreId: 7687,
      x: 9469,
      y: 10084
    },{
      NanogenreId: 7688,
      x: 7674,
      y: 4255
    },{
      NanogenreId: 7689,
      x: 8035,
      y: 8099
    },{
      NanogenreId: 7690,
      x: 7410,
      y: 9244
    },{
      NanogenreId: 7691,
      x: 10417,
      y: 8029
    },{
      NanogenreId: 7692,
      x: 7388,
      y: 6931
    },{
      NanogenreId: 7693,
      x: 10346,
      y: 10273
    },{
      NanogenreId: 7694,
      x: 8048,
      y: 9083
    },{
      NanogenreId: 7695,
      x: 9624,
      y: 9381
    },{
      NanogenreId: 7696,
      x: 10262,
      y: 8467
    },{
      NanogenreId: 7697,
      x: 5986,
      y: 9715
    },{
      NanogenreId: 7698,
      x: 6350,
      y: 7450
    },{
      NanogenreId: 7699,
      x: 7603,
      y: 7974
    },{
      NanogenreId: 7700,
      x: 10070,
      y: 5360
    },{
      NanogenreId: 7701,
      x: 6086,
      y: 7105
    },{
      NanogenreId: 7702,
      x: 8571,
      y: 8508
    },{
      NanogenreId: 7703,
      x: 5756,
      y: 8490
    },{
      NanogenreId: 7704,
      x: 6843,
      y: 8021
    },{
      NanogenreId: 7705,
      x: 6447,
      y: 6194
    },{
      NanogenreId: 7706,
      x: 9899,
      y: 7525
    },{
      NanogenreId: 7707,
      x: 10922,
      y: 10371
    },{
      NanogenreId: 7708,
      x: 7886,
      y: 7295
    },{
      NanogenreId: 7709,
      x: 6496,
      y: 8075
    },{
      NanogenreId: 7710,
      x: 7666,
      y: 7334
    },{
      NanogenreId: 7711,
      x: 9206,
      y: 6323
    },{
      NanogenreId: 7712,
      x: 9444,
      y: 5004
    },{
      NanogenreId: 7713,
      x: 7076,
      y: 8264
    },{
      NanogenreId: 7714,
      x: 9811,
      y: 6796
    },{
      NanogenreId: 7715,
      x: 7183,
      y: 8572
    },{
      NanogenreId: 7716,
      x: 7655,
      y: 11455
    },{
      NanogenreId: 7717,
      x: 11216,
      y: 5607
    },{
      NanogenreId: 7718,
      x: 9246,
      y: 7251
    },{
      NanogenreId: 7719,
      x: 8305,
      y: 5107
    },{
      NanogenreId: 7720,
      x: 6799,
      y: 7331
    },{
      NanogenreId: 7721,
      x: 8953,
      y: 9872
    },{
      NanogenreId: 7722,
      x: 9434,
      y: 5557
    },{
      NanogenreId: 7723,
      x: 8490,
      y: 9225
    },{
      NanogenreId: 7724,
      x: 11002,
      y: 7570
    },{
      NanogenreId: 7725,
      x: 7668,
      y: 6580
    },{
      NanogenreId: 7726,
      x: 10091,
      y: 7400
    },{
      NanogenreId: 7727,
      x: 10250,
      y: 9965
    },{
      NanogenreId: 7728,
      x: 8854,
      y: 6158
    },{
      NanogenreId: 7729,
      x: 9325,
      y: 5616
    },{
      NanogenreId: 7730,
      x: 9422,
      y: 5924
    },{
      NanogenreId: 7731,
      x: 12464,
      y: 9178
    },{
      NanogenreId: 7732,
      x: 5883,
      y: 6069
    },{
      NanogenreId: 7733,
      x: 10047,
      y: 8022
    },{
      NanogenreId: 7734,
      x: 5161,
      y: 8348
    },{
      NanogenreId: 7735,
      x: 9071,
      y: 5326
    },{
      NanogenreId: 7736,
      x: 8310,
      y: 4555
    },{
      NanogenreId: 7737,
      x: 7689,
      y: 7069
    },{
      NanogenreId: 7738,
      x: 5936,
      y: 7668
    },{
      NanogenreId: 7739,
      x: 6820,
      y: 7788
    },{
      NanogenreId: 7740,
      x: 10347,
      y: 9411
    },{
      NanogenreId: 7741,
      x: 9447,
      y: 6240
    },{
      NanogenreId: 7742,
      x: 8242,
      y: 5935
    },{
      NanogenreId: 7743,
      x: 9788,
      y: 10461
    },{
      NanogenreId: 7744,
      x: 9449,
      y: 5843
    },{
      NanogenreId: 7745,
      x: 8365,
      y: 5703
    },{
      NanogenreId: 7746,
      x: 9986,
      y: 5625
    },{
      NanogenreId: 7747,
      x: 10650,
      y: 8759
    },{
      NanogenreId: 7748,
      x: 6533,
      y: 6083
    },{
      NanogenreId: 7749,
      x: 8963,
      y: 3943
    },{
      NanogenreId: 7750,
      x: 5692,
      y: 8250
    },{
      NanogenreId: 7751,
      x: 8338,
      y: 9047
    },{
      NanogenreId: 7752,
      x: 9932,
      y: 5988
    },{
      NanogenreId: 7753,
      x: 8880,
      y: 9509
    },{
      NanogenreId: 7754,
      x: 9014,
      y: 8290
    },{
      NanogenreId: 7755,
      x: 8287,
      y: 6385
    },{
      NanogenreId: 7756,
      x: 6764,
      y: 8087
    },{
      NanogenreId: 7757,
      x: 8613,
      y: 7991
    },{
      NanogenreId: 7758,
      x: 6061,
      y: 6278
    },{
      NanogenreId: 7759,
      x: 9922,
      y: 5551
    },{
      NanogenreId: 7760,
      x: 6097,
      y: 6685
    },{
      NanogenreId: 7761,
      x: 9347,
      y: 8866
    },{
      NanogenreId: 7762,
      x: 3260,
      y: 8350
    },{
      NanogenreId: 7763,
      x: 7363,
      y: 6570
    },{
      NanogenreId: 7764,
      x: 10324,
      y: 9780
    },{
      NanogenreId: 7765,
      x: 6173,
      y: 7116
    },{
      NanogenreId: 7766,
      x: 7436,
      y: 8572
    },{
      NanogenreId: 7767,
      x: 9188,
      y: 5946
    },{
      NanogenreId: 7768,
      x: 7463,
      y: 10222
    },{
      NanogenreId: 7769,
      x: 4814,
      y: 10754
    },{
      NanogenreId: 7770,
      x: 6162,
      y: 8586
    },{
      NanogenreId: 7771,
      x: 6435,
      y: 7129
    }
  ]
}
