export default {
  namespaced: true,
  state: {
    radius: 30,
    blur: 22,
    opacity: 0.72,
    weight: 0,
    zoomLevel: 1,
    simplified: false,
  },
  getters: {
    getRadius: (state) => () => {
      return state.radius
    },
    getBlur: (state) => () => {
      return state.blur
    },
    getWeight: (state) => () => {
      return state.weight
    },
    getOpacity: (state) => () => {
      return state.opacity
    },
    getZoomLevel: (state) => () => {
      return state.zoomLevel
    },
    getSimplifiedMode: (state) => () => {
      return state.simplified
    }
  },
  mutations: {
    SET_RADIUS(state, data) {
      state.radius = data
    },
    SET_BLUR(state, data) {
      state.blur = data
    },
    SET_WEIGHT(state, data) {
      state.weight = data
    },
    SET_OPACITY(state, data) {
      state.opacity = data
    },
    SET_ZOOM_LEVEL(state, data) {
      state.zoomLevel = data
    }, 
    SET_SIMPLIFIED(state) {
      state.simplified = !state.simplified
      if (state.simplified) {
        state.blur = 0
        state.radius = 10 
        state.opacity = 1
      } else {
        state.blur = 22
        state.radius = 30
        state.opacity = 0.72
      }
    }
  },
  actions: {
    setRadius({commit}, radius) {
      commit('SET_RADIUS', radius)
    },
    setBlur({commit}, blur) {
      commit('SET_BLUR', blur)
    },
    setWeight({commit}, weight) {
      commit('SET_WEIGHT', weight)
    },
    setOpacity({commit}, opacity) {
      commit('SET_OPACITY', opacity)
    },
    setZoomLevel({ commit }, zoom) {
      commit('SET_ZOOM_LEVEL', zoom)
    },
    toggleSimplifiedMode({ commit }) {
      commit('SET_SIMPLIFIED')
    }
  },
}
