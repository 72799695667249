<template>
  <v-card 
    tile
    outlined
    elevation="0"
    class="padding-top-65"
  >
    <div
      id="title-heatmap" 
      class="map"
      @set-gradient="setGradient"
      :style="{height: getViewportHeight, width: getViewportWidth}"
    >
    </div>
  </v-card>
</template>

<script>
import 'ol/ol.css'
import * as proj from 'ol/proj'
import * as extent from 'ol/extent'
// import * as tilegrid from 'ol/tilegrid'

import Map from 'ol/Map'
import View from 'ol/View'
import Tile from 'ol/layer/Tile'
import TileImage from 'ol/source/TileImage'
import TileGrid from 'ol/tilegrid/TileGrid'

// import { Vector as VectorSource } from 'ol/source'
// import { Vector } from 'ol/layer'

import Feature from 'ol/Feature'
import { Point } from 'ol/geom'
// import { fromLonLat } from 'ol/proj'

import { Heatmap as HeatmapLayer } from 'ol/layer'
// import { Heatmap as HeatmapLayer } from '../lib/Heatmap.js'
// import Heatmap from '../plugins/Heatmap.js'

import VectorSource from 'ol/source/Vector'
// import GeoJSON from 'ol/format/GeoJSON'
import Projection from "ol/proj/Projection"
import { ZoomSlider, FullScreen, defaults as defaultControls } from 'ol/control'
// import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style"
// import MultiPoint from 'ol/geom/MultiPoint'

import { defaults as defaultInteractions } from 'ol/interaction'


const interactions = defaultInteractions({
  mouseWheelZoom: false,
  zoomSlider: false, // disable zoom slider - we use our own. this doesn't work. look at the scoped css for .ol-zoomslider
})

export default {
  components: {},
  data: () => ({
    projExtent: null,
    startResolution: null,
    resolutions: new Array(7),
    tileGrid: null,
    map: null,
    heatmap: null,
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight
  }),
  methods: {
    setViewportDimensions() {
      this.viewportWidth = window.innerWidth
      this.viewportHeight = window.innerHeight
    },
    addHeatmapLayer() {
      const startTime = performance.now();
      const vm = this
      // const styleFunction = (feature, resolution) => {
      //   return [
      //     new Style({
      //       stroke: new Stroke({
      //         color: "blue",
      //         width: 3
      //       }),
      //       fill: new Fill({
      //         color: "rgba(0, 0, 255, 0.1)"
      //       })
      //     }),
      //     new Style({
      //       image: new CircleStyle({
      //         radius: 500000 / resolution,
      //         fill: new Fill({
      //           color: "orange"
      //         })
      //       }),
      //       geometry: function(feature) {
      //         // return the coordinates of the first ring of the polygon
      //         var coordinates = feature.getGeometry().getCoordinates()[0];
      //         return new MultiPoint(coordinates);
      //       }
      //     })
      //   ];
      // };

      if (vm.heatmap !== null) {
        // console.log(`vm.map.removeLayer(vm.heatmap)`)
        vm.map.removeLayer(vm.heatmap)
      }
      console.log(`Features`, vm.getHeatmapData())

      const vectorSource = new VectorSource 

      const heatmapData = vm.getHeatmapData()

      for (let x = 0; x < heatmapData.features.length; x++) {
        const coordinate = [
          (parseInt(heatmapData.features[x][0])), // x coordinate
          (parseInt(heatmapData.features[x][1]))  // y coordinate
        ]

        const heatmapPoint = new Feature({
          geometry: new Point(coordinate),
          weight: heatmapData.features[x][2]
        })
        vectorSource.addFeature(heatmapPoint)
      }

      // const endTime = performance.now();
      // const elapsedTime = endTime - startTime;
      // console.log(`Elapsed time: ${elapsedTime} milliseconds`);

      // get the heatmap coordinates
      // const heatmapCoordinates = vm.getHeatmapCoordinates()

      // // find each coordinate in heatmapCoordinates
      // const dancingRomanceColorful = heatmapCoordinates.find((item) => item.nanogenreId === 908)
      // const giantScientificAttacks = heatmapCoordinates.find((item) => item.nanogenreId === 32)
      // const armyRussiaConflict = heatmapCoordinates.find((item) => item.nanogenreId === 222)
      // const biblicalSpiritualHoly = heatmapCoordinates.find((item) => item.nanogenreId === 80)
      // const spaceshipNasaTechnology = heatmapCoordinates.find((item) => item.nanogenreId === 212)
      // const bizarreThoughtProvokingIntellectual = heatmapCoordinates.find((item) => item.nanogenreId === 7001)
      // const ninetiesRawIntense = heatmapCoordinates.find((item) => item.nanogenreId === 7487)
      
      // // Define your heatmap data as an array of Feature objects
      // const heatmapData = {
      //   features: [
      //     // [0, 0], // center
      //     [bizarreThoughtProvokingIntellectual.x, bizarreThoughtProvokingIntellectual.y], // ngId: 7001, description: 'bizarre\nthought-provoking\nintellectual'
      //     [dancingRomanceColorful.x, dancingRomanceColorful.y], // ngId: 908, description: 'dancing\nromance\ncolorful'
      //     [giantScientificAttacks.x, giantScientificAttacks.y], // ngId: 32, description: 'giant\nscientific\nattacks'
      //     [armyRussiaConflict.x, armyRussiaConflict.y], // ngId: 222, description: 'army\nrussia\nconflict'
      //     [biblicalSpiritualHoly.x, biblicalSpiritualHoly.y], // ngId: 80, description: 'biblical\nspiritual\nholy'
      //     [spaceshipNasaTechnology.x, spaceshipNasaTechnology.y], // ngId: 212, description: 'spaceship\nnasa\ntechnology'
      //     [ninetiesRawIntense.x, ninetiesRawIntense.y], // ngId: 7487, description: 'nineties\nraw\nintense'
      //   ]
      // }
      
      // const data = []

      // for (let x = 0; x < heatmapData.features.length; x++) {
      //   let coordinate = heatmapData.features[x]

      //   const feature = new Feature({
      //     geometry: new Point(coordinate),
      //     weight: 1,
      //   })
      //   data.push(feature)
      // }

      // // Create a vector source and add your data to it
      // const vectorSource = new VectorSource({
      //   features: data,
      // });

      vm.heatmap = new HeatmapLayer({
        title: 'Heatmap',
        source: vectorSource,
        constrainOnlyCenter: true,
        // extent: vm.projExtent,
      })

      vm.heatmap.set('name', 'nanogenre_pointsLayer')
      // vm.heatmap.setStyle(styleFunction)

      vm.setGradient()
      vm.setRadius()

      vm.map.addLayer(vm.heatmap)

      vm.map.getView().on('change:resolution', function() {
        vm.$store.dispatch('settings/setZoomLevel', vm.map.getView().getZoom())
        vm.setRadius()
      })
      const endTime = performance.now();
      const elapsedTime = endTime - startTime;
      this.$store.dispatch('heatmapProgressIndicator/hide')
      console.log(`Elapsed time: ${elapsedTime} milliseconds`);
    },
    setRadius() {
      const zoomLevel = this.map.getView().getZoom()
      const zoomRadiusMultiplier = this.$store.getters['server/getZoomRadiusMultiplier']()
      const zoomBlurMultiplier = this.$store.getters['server/getZoomBlurMultiplier']()
      const zoomOpacityMultiplier = this.$store.getters['server/getZoomOpacityMultiplier']()

      const radius = parseFloat(this.$store.getters['settings/getRadius']())
      const blur = parseFloat(this.$store.getters['settings/getBlur']())
      const opacity = parseFloat(this.$store.getters['settings/getOpacity']())

      if (zoomLevel === 0) {
        this.heatmap.setRadius( radius / (2.5 * zoomRadiusMultiplier ))
        this.heatmap.setBlur( blur / (1.2857 * zoomBlurMultiplier) )
        this.heatmap.set('opacity', opacity / zoomOpacityMultiplier)
      } else if (zoomLevel === 1){
        this.heatmap.setRadius(radius)
        this.heatmap.setBlur(blur)
        this.heatmap.set('opacity', opacity)
      } else {
        this.heatmap.setRadius(zoomLevel * zoomRadiusMultiplier * radius)
        this.heatmap.setBlur(zoomLevel * zoomBlurMultiplier * blur)
        this.heatmap.set('opacity', zoomLevel * zoomOpacityMultiplier * opacity)
      }
    },
    setBlur() {
      // this.heatmap.setBlur(parseInt(this.$store.getters['settings/getBlur']()))
      this.setRadius()
    },
    setOpacity() {
      // this.heatmap.set('opacity', parseFloat(this.$store.getters['settings/getOpacity']()))
      this.setRadius()
    },
    setGradient() {
      // eslint-disable-next-line no-debugger
      const colorstopList = this.$store.getters['server/getColorstopList']()
      if (colorstopList.length > 1) {
        this.heatmap.setGradient(colorstopList)
      }
    },
    getHeatmapData() {
      const result = this.$store.getters['server/getDisplayCoordinates']()
      return result
    },
    getHeatmapCoordinates() {
      const result = this.$store.getters['server/getHeatmapCoordinates']()
      return result
    },
  },
  computed: {
    getViewportHeight() {
      return this.viewportHeight
    },
    getViewportWidth() {
      return this.viewportWidth - 150
    },
    getCoordinatesChanged() {
      const result = this.$store.getters['server/getCoordinatesChanged']()
      return result
    },
    getRadius() {
      const radius = this.$store.getters['settings/getRadius']()
      return radius
    },
    getBlur() {
      const blur = this.$store.getters['settings/getBlur']()
      return blur
    },
    getOpacity() {
      const opacity = this.$store.getters['settings/getOpacity']()
      return opacity
    },
    getGradient() {
      const toggledFlag = this.$store.getters['server/getColorToggledFlag']()
      return toggledFlag
    },
    getZoomRadiusMultiplier() {
      return this.$store.getters['server/getZoomRadiusMultiplier']()
    },
    getZoomBlurMultiplier() {
      return this.$store.getters['server/getZoomBlurMultiplier']()
    },
    getZoomOpacityMultiplier() {
      return this.$store.getters['server/getZoomOpacityMultiplier']()
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    getCoordinatesChanged(newValue, oldValue) {
      this.addHeatmapLayer()
    },
    // eslint-disable-next-line no-unused-vars
    getRadius(newValue, oldValue) {
      this.$emit('set-radius', newValue)
    },
    // eslint-disable-next-line no-unused-vars
    getBlur(newValue, oldValue) {
      this.$emit('set-blur', newValue)
    },
    // eslint-disable-next-line no-unused-vars
    getOpacity(newValue, oldValue) {
      this.$emit('set-opacity', newValue)
    },
    // eslint-disable-next-line no-unused-vars
    getGradient(newValue, oldValue) {
      this.$emit('set-gradient', newValue)
    },
    // eslint-disable-next-line no-unused-vars
    getZoomRadiusMultiplier(newValue, oldValue) {
      console.log('NanogenreHeatmap.watch.getZoomRadiusMultiplier')
      this.setRadius()
    },
    // eslint-disable-next-line no-unused-vars
    getZoomBlurMultiplier(newValue, oldValue) {
      console.log('NanogenreHeatmap.watch.getZoomBlurMultiplier')
      this.setRadius()
    },
    // eslint-disable-next-line no-unused-vars
    getZoomOpacityMultiplier(newValue, oldValue) {
      console.log('NanogenreHeatmap.watch.getZoomOpacityMultiplier')
      this.setRadius()
    },
  },
  async mounted() {
    // the following code is used to adjust the heatmap coordinates from the original coordinates 
    // where the original coordinates are values starting at 0, 0 (top, left). The adjusted coordinates
    // are values starting at the center of the map, and are used to display the heatmap.
    // const originalCoordinates = this.$store.getters['server/getOriginalCoordinates']()
    // await this.$store.dispatch('server/adjustCoordinates', originalCoordinates)

    this.setViewportDimensions()
    window.addEventListener('resize', this.setViewportDimensions)
    // window.onresize = this.setViewportDimensions

    this.projExtent = proj.get('EPSG:3857').getExtent()

    const projection = new Projection({
      // code: "EPSG:3857",
      code: "EPSG:4326",
      extent: extent
    })

    this.startResolution = extent.getWidth(this.projExtent) / 256
    for (let x = 0, xx = this.resolutions.length; x < xx; x++) {
      this.resolutions[x] = this.startResolution / Math.pow(2, x)
    }
    this.tileGrid = new TileGrid({
      resolutions: this.resolutions,
      extent: this.projExtent,
    })

    const view = new View({
      projection: projection,
      resolutions: this.resolutions,
      center: [10200, 10200],
      minZoom: 1,
      maxZoom: 6,
      zoom: 1,
      constrainOnlyCenter: true,
      extent: this.projExtent,
    })

    const tileLayer = new Tile({
      source: new TileImage({
        // extent: this.projExtent,
        tileGrid: this.tileGrid,
        url: `https://nccdn.sfo3.cdn.digitaloceanspaces.com/openlayers/maptiles/{z}/{x}/{-y}.png`,
      })
    })

    this.map = new Map({
      interactions,
      controls: defaultControls().extend( [ new FullScreen ] ),
      view,
      layers: [ tileLayer ],
      target: 'title-heatmap',
      keyboardEventTarget: document,
    })

    const zoomSlider = new ZoomSlider()
    this.map.addControl(zoomSlider)

    this.map.on('click', function(e) {
      console.log('clicked map coordinate: ', e.coordinate)
    })
    this.addHeatmapLayer()
    this.$on('set-radius', this.setRadius)
    this.$on('set-blur', this.setBlur)
    this.$on('set-opacity', this.setOpacity)
    this.$on('add-heatmap-layer', this.addHeatmapLayer)
    this.$on('set-gradient', this.setGradient)
  }
}
</script>

<style>
.map {
  height: 90vh;
  width: 75vw;
  background: white;
}

.ol-zoomslider {
  display: none;
}

.padding-top-65 {
  padding-top: 65px;
}
</style>